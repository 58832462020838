import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import format from "date-fns/format";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
        },
    },
});

const ItemDataLogLeads = ({ isFetchingDataLog, dataLog, page, rowsPerPage }) => {
    const classes = styles();
    const index = page * rowsPerPage;

    return (
        <React.Fragment>
            {
                isFetchingDataLog === true
                    ? <TableRow>
                        <TableCell colSpan={3}>
                            <div  style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress/>
                            </div>
                        </TableCell>
                    </TableRow>
                    : dataLog.slice(index, index + rowsPerPage).map((item, idx) => (
                        <TableRow key={idx} className={classes.row}>
                            <TableCell className={classes.tableCellBody}>
                                {`${format(new Date(item.createdAt), "dd/MM/yyyy, HH:mm")} WIB`}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {`${item.PanelaUser.firstName} ${item.PanelaUser.lastName}`}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.description}
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
};

const mapState = ({ leads }) => ({
    isFetchingDataLog: leads.isFetchingDataLog,
    dataLog: leads.dataLog,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(ItemDataLogLeads);
