import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import format from "date-fns/format";
import NumberFormat from "react-number-format";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
});

const BerlanggananItem = ({ page, rowsPerPage, isFetchingSubscribedFaskesList, subscribedFaskesList, handleChangeChecked }) => {
    const classes = styles();
    const index = page * rowsPerPage;

    function handleRender(data, flag) {
        const { KConfig } = data;
        return (
            <ul>
                {KConfig.activeBilling.Billing.payment.map((item, idx) => (
                    <li key={idx}>
                        {
                            flag === 'dueDate'
                                ? format(new Date(item.dueDatePayment), "dd/MM/yyyy")
                                : <NumberFormat
                                    displayType="text"
                                    value={item.value}
                                    prefix="Rp. "
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale={2}
                                />
                        }
                    </li>
                ))}
            </ul>
        )
    }

    return (
        <React.Fragment>
            {
                isFetchingSubscribedFaskesList === true
                    ? <TableRow>
                        <TableCell colSpan={9}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        </TableCell>
                    </TableRow>
                    : subscribedFaskesList.slice(index, index + rowsPerPage).map((data, idx) => (
                        <TableRow key={idx} className={classes.row}>
                            <TableCell className={classes.tableCellBody}>
                                <Checkbox
                                    checked={ data.isChecked || false }
                                    onChange={(e)=> handleChangeChecked(e, idx)}
                                    color="primary"
                                />
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {data.nama}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {data.KConfig.activeBilling.mainProductName}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {`${data.KConfig.activeBilling.Billing.duration} Bulan`}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {format(new Date(data.KConfig.createdAt), "dd/MM/yyyy")}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {format(new Date(data.KConfig.activeBilling.Billing.startSubs), "dd/MM/yyyy")}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {format(new Date(data.KConfig.activeBilling.Billing.endSubs), "dd/MM/yyyy")}
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                {handleRender(data, 'dueDate')}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {handleRender(data, 'invoice')}
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
};

const mapState = ({ client }) => ({
    isFetchingSubscribedFaskesList: client.isFetchingSubscribedFaskesList,
    subscribedFaskesList: client.subscribedFaskesList,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(BerlanggananItem);
