import agent from "../../api/agent";
import { dispatch } from '../../store';
import history from "../../history";

function populateFaskesPayload(payload, isNew = true) {
    const newPayload = {
        name : payload.name,
        completeness :payload.completeness,
        type :payload.type,
        operationalStatus: payload.operationalStatus,
        leadsInfo :payload.leadsInfo,
        email :payload.email,
        phoneNumber :payload.phoneNumber,
        event :payload.event,
        year :payload.year,
        PIC : payload.PIC,
        assistPIC : payload.assistPIC,
        picId: payload.picId,
        address : {
            street :payload.street,
            province :payload.province,
            city :payload.city,
            district :payload.district,
            postCode :payload.postCode,
        },
        links : {
            googleMap :payload.googleMap,
            website :payload.website,
            instagram :payload.instagram,
            facebook :payload.facebook,
        },
        isBPJS : payload.isBPJS,
        generalDoctor : payload.generalDoctor,
        dentist : payload.dentist,
        isPharmacyExist : payload.isPharmacyExist,
        expectation :payload.expectation,
        problem :payload.problem,
        systemBeforeAssist: payload.systemBeforeAssist,
        experienceBeforeAssist :payload.experienceBeforeAssist,
        note :payload.note,
        status :payload.status,
        assistHospitalId: payload.assistHospitalId,
        assistHospitalName: payload.assistHospitalName,
        customerTarget: payload.customerTarget,
        mproduct: payload.mproduct,
        url: payload.url,
        referral: payload.referral,
        statusData: payload.statusData,
        numberOfVisits: payload.numberOfVisits,
        isVerified: payload.isVerified,
        isTrial: payload.trial,
        isBlocked: payload.blocked,
        // trial: payload.trial,
        // blocked: payload.blocked,
    }

    if (isNew) {
        newPayload.createdAt = new Date();
        newPayload.createdId = payload.createdId;
    } else {
        newPayload.updatedAt = new Date();
        newPayload.updatedId = payload.updatedId;
    }
    return newPayload
}

export const faskes = {
    state : [],
    reducers : {
        loadState(state, payload){
            return [...payload]
        },
    },
    effects : {
        async loadData(payload, rootState){
            try {
                const params = {
                    filter: {
                        include: ['healthFacilityPICs']
                    }
                };
                const res = await agent.get("/HealthFacilities/getFaskesList", {
                    params,
                });
                this.loadState(res.data);
            } catch (err) {
                console.error(err);
            }
        },
        async loadDataSingle(payload, rootState){
            // console.log(payload)
            try {
                const res = await agent.get("/HealthFacilities/"+payload)
                if(res.status === 200){
                    return res.data
                }
            } catch (err) {
                console.error(err);
                dispatch.snackbar.showError('Faskes tidak ditemukan')
            }
        },
        async countData(payload, rootState){
            const res = await agent.get("/HealthFacilities/count")

            if(res.status === 200){
                const data = await res.json()
                return data
            }
        },
        async addFaskes(payload){
            try {
                const f = populateFaskesPayload(payload)
                if(payload.prospectId){
                    f.prospectId = payload.prospectId;
                }
                await agent.post('/HealthFacilities', f)

                await dispatch.faskes.loadData()
                dispatch.snackbar.showSuccess('Berhasil menambahkan faskes')
                history.goBack()
            } catch (err) {
                console.error(err);
                const { response } = err;
                if (response && response.status === 412) {
                    const { data } = response;
                    if (data) {
                        const { error } = data;
                        if (error && error.message === 'Name already used') {
                            dispatch.snackbar.showError('Gagal menambahkan faskes, karena nama telah digunakan');
                        }
                    }
                } else {
                    dispatch.snackbar.showError('Gagal menambahkan faskes');
                }
            }
        },
        async deleteFaskes(payload){
            try {
                const res = await agent.delete(`/HealthFacilities/${payload}/deleteData`);

                if(res.data.count){
                    await dispatch.faskes.loadData()
                    dispatch.snackbar.showSuccess('Berhasil menghapus faskes')
                    // history.goBack()
                } else {
                    dispatch.snackbar.showError('Gagal menghapus faskes')
                }
                return true;
            } catch (err) {
                console.error(err)
                dispatch.snackbar.showError('Gagal menghapus faskes')
                return false;
            }
        },
        async editFaskes(payload){
            try {
                const f = populateFaskesPayload(payload, false)

                await agent.patch(`/HealthFacilities/${payload.id}`, f)

                await dispatch.faskes.loadData()
                dispatch.snackbar.showSuccess('Berhasil mengupdate faskes')
                // history.goBack()
            } catch (err) {
                console.error(err)
                dispatch.snackbar.showError('Gagal mengupdate faskes')
            }
        },
        async addRiwayatPenawaran(payload){
            try {
                const data = {
                    healthFacilityId: payload.healthFacilityId,
                    date: payload.date,
                    notes: payload.notes,
                    picFaskes: payload.picFaskes,
                    picAssist: payload.picAssist,
                }

                await agent.post('HealthFacilityTimelines', data)
                dispatch.snackbar.showSuccess("Berhasil Menambah Touchpoint History");
                history.goBack();
            } catch (err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal Menambah Touchpoint History");
            }
        }
    }

}
