import React, {useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import ItemDataLogLeads from "./ItemDataLogLeads";

const styles = makeStyles({
    tableWrapper: {
        overflow: 'hidden',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
        textAlign: 'center',
    },
});

const TableDataLogLeads = ({ fetchDataLog, dataLogCounter, filterData, setFilterData }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        if(typeof filterData.healthFacilityId !== 'undefined') {
            fetchDataLog(filterData);
        }
    }, [fetchDataLog, filterData]);

    const handleChangePage = (event, newPage) => {
        filterData.skip = newPage * rowsPerPage;
        filterData.limit = rowsPerPage;
        setFilterData(prevState => ({
            ...prevState, skip: filterData.skip, limit: filterData.limit,
        }));
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        const newRowsPerPage = parseInt(e.target.value, 10);
        filterData.skip = 0;
        filterData.limit = newRowsPerPage;
        setFilterData(prevState => ({
            ...prevState, skip: filterData.skip, limit: filterData.limit,
        }));
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    return (
        <div>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader}>
                                Tanggal
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                PIC Assist.id
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Perubahan
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataLogCounter > 0
                                ? <ItemDataLogLeads page={page} rowsPerPage={rowsPerPage}/>
                                : <TableRow>
                                    <TableCell colSpan={3} className={classes.tableCellBody}>
                                        <span>Tidak Ada Data</span>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dataLogCounter}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}/>
            </div>
        </div>
    );
};

const mapState = ({ leads }) => ({
    dataLogCounter: leads.dataLogCounter,
});

const mapDispatch = ({ leads: { fetchDataLog } }) => ({
    fetchDataLog,
});

export default connect(mapState, mapDispatch)(TableDataLogLeads);
