export const snackbar = {
    state : {
        open: false,
        message: '',
        type: 'success'
    },
    reducers : {
        showSuccess(state, payload) {
            return {
                open: true,
                message: payload,
                type: 'success'
            }
        },
        showWarning(state, payload) {
            return {
                open: true,
                message: payload,
                type: 'warning'
            }
        },
        showError(state, payload) {
            return {
                open: true,
                message: payload,
                type: 'error'
            }
        },
        showInfo(state, payload) {
            return {
                open: true,
                message: payload,
                type: 'info'
            }
        },
        close(state) {
            return {
                ...state,
                open: false,
                message: '',
            }
        }
    }
}
