import React from "react";
import Typography from "@material-ui/core/Typography";

import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    Tooltip,
} from "recharts";
import {makeStyles} from "@material-ui/core";

const styles = makeStyles({
    tooltip: {
        backgroundColor: 'white',
        padding: 8,
        borderRadius: 8,
        border: '1px solid black',
    },
});

export default function ({ data, dataKeyVal, colors }) {
    const classes = styles();
    const data2 =[
        { name: 'border', value: 100 },
    ];

    const renderCustomTooltip = ({ active, payload }) => {
        if(active) {
            if(payload[0].name !== 'border') {
                return (
                    <div className={classes.tooltip}>
                        <Typography>
                            {payload[0].payload._id === null
                                ? 'Null'
                                : payload[0].payload._id === true
                                    ? 'True'
                                    : payload[0].payload._id === false
                                        ? 'False'
                                        : payload[0].payload._id
                            }
                        </Typography>
                        <Typography style={{ textAlign: 'center' }}>
                            {`${payload[0].payload.percentage}%`}
                        </Typography>
                    </div>
                );
            }
        }
    };

    return (
        <ResponsiveContainer width='100%' height={250}>
            <PieChart>
                <Tooltip content={renderCustomTooltip}/>
                <Pie
                    data={data}
                    cx={"50%"}
                    cy={"50%"}
                    labelLine={false}
                    outerRadius={100}
                    innerRadius={8}
                    fill="#8884d8"
                    dataKey={dataKeyVal}
                    stroke="none"
                    cursor="pointer"
                >
                    {
                        data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
                    }
                </Pie>
                <Pie
                    data={data2}
                    cx={'50%'}
                    cy={'50%'}
                    outerRadius={110}
                    innerRadius={100}
                    dataKey="value"
                    fill="#DCDCDC"
                    stroke="none"
                />
            </PieChart>
        </ResponsiveContainer>
    );
}
