import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";

import TrialItem from "./TrialItem";

const styles = makeStyles({
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    }
});

const TrialTable =
    ({ fetchTrialList,
         setIsOpenAddNew,
         trialListCounter,
         isFilter,
         filterData,
         setFilterData, pageFilter, fetchTrialListFilter }) => {
    const classes = styles();
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(5);

    useEffect(() => {
        if(isFilter === true){
            setPage(pageFilter);
            setRowsPerPage(5);
            handleRenderTrialFilter();
        } else {
            setPage(pageFilter);
            setRowsPerPage(5);
            fetchTrialList();
        }
    }, [fetchTrialList, trialListCounter, fetchTrialListFilter, isFilter, pageFilter]);

    const handleRenderTrialFilter = () => {
        setFilterData(filterData => ({
            ...filterData, condition: false
        }));
        fetchTrialListFilter(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            isTrial: 1,
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        if(isFilter === true){
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit
            }));
            fetchTrialListFilter(filterData);
        } else {
            fetchTrialList(skipLimit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        const newRowsPerpage = parseInt(event.target.value, 10);
        const skipLimit = {
            isTrial: 1,
            skip: 0,
            limit: newRowsPerpage,
        };
        if(isFilter === true){
            filterData.skip = 0;
            filterData.limit = newRowsPerpage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit
            }));
            fetchTrialListFilter(filterData);
        } else{
            fetchTrialList(skipLimit);
        }
        setRowsPerPage(newRowsPerpage);
        setPage(0);
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableCellHeader}>
                        <span>Nama Klinik</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Tanggal Mulai</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Tanggal Akhir</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader} style={{ textAlign: 'center' }}>
                        <span>Status</span>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                <TrialItem setIsOpenAddNew={setIsOpenAddNew} fetchTrialList={fetchTrialList} page={page} rowsPerPage={rowsPerPage} />
            </TableBody>

            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        colSpan={4}
                        count={trialListCounter}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage} />
                </TableRow>
            </TableFooter>
        </Table>
    );
};

const mapState = ({ activity }) => ({
    trialListCounter: activity.trialListCounter,
});

const mapDispatch = ({ activity: { fetchTrialList, fetchTrialListFilter } }) => ({
    fetchTrialList,
    fetchTrialListFilter,
});

export default connect(mapState, mapDispatch)(TrialTable);
