import React, { useState } from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faCheckCircle} from "@fortawesome/free-solid-svg-icons";

import filter from './../../../assets/img/filter-icon.png';
import arrowDown from './../../../assets/img/arrow-down-icon.png';

import PInput from "../../share/PInput";
import TrialTable from "./TrialTable";
import TrialFilter from "./TrialFilter";

const styles = makeStyles({
    chip: {
        marginRight: 10,
    },
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
});

const Trial = ({ updateSelectedRejection, fetchTrialListFilter, selectedTrialFilter, updateSelectedTrialFilter }) => {
    const classes = styles();
    const [isOpenAddNew, setIsOpenAddNew] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [pageFilter, setPageFilter] = useState(0);
    const [filterData, setFilterData] = useState({
        namaKlinik: '',
        tanggalAwalTrialStart: null,
        tanggalAwalTrialEnd: null,
        tanggalAkhirTrialStart: null,
        tanggalAkhirTrialEnd: null,
        isTrial: 1,
        skip: 0,
        limit: 5,
    });
    const [isOpenFilterForm, setIsOpenFilterForm] = useState(false);

    function handleCloseDialog() {
        setIsOpenAddNew(false);
        updateSelectedRejection({});
    }

    const handleFilter = () => {
        setIsFilter(true);
        setPageFilter(0);
        fetchTrialListFilter(filterData);
        setIsOpenFilterForm(false);
        updateSelectedTrialFilter(JSON.parse(JSON.stringify(filterData)));
    };

    const handleChangeDropDown = (e) => {
        filterData.isTrial = e.target.value;
        setFilterData({...filterData, isTrial: filterData.isTrial});
        setIsFilter(true);
        fetchTrialListFilter(filterData);
        updateSelectedTrialFilter(JSON.parse(JSON.stringify(filterData)));
    };

    const handleClearFilter = () => {
        setFilterData({
            namaKlinik: '',
            tanggalAwalTrialStart: null,
            tanggalAwalTrialEnd: null,
            tanggalAkhirTrialStart: null,
            tanggalAkhirTrialEnd: null,
            isTrial: 1,
            skip: 0,
            limit: 5,
        });
        setPageFilter(0);
        setIsFilter(false);
        updateSelectedTrialFilter({});
    };

    const handleChangeSearch = (e) => {
        filterData.namaKlinik = e.target.value;
        setFilterData({...filterData, namaKlinik: filterData.namaKlinik});
        setIsFilter(true);
        fetchTrialListFilter(filterData);
    };

    return (
        <Grid container>
            <Dialog open={isOpenAddNew} onClose={() => handleCloseDialog()}>
                <DialogContent>
                    {
                        // isOpenAddNew && <PenolakanForm onClose={() => handleCloseDialog()} />
                    }
                </DialogContent>
            </Dialog>

            <Grid container justify="flex-end" item xs={12} style={{marginTop: '44px'}}>
                <Grid item xs={6}>
                    <div className="filter" style={{marginTop: '-8px'}}>
                        <img alt="filter" src={filter} className="img-filter" />
                        <Typography>Filter by</Typography>
                        <img
                            alt="arrow"
                            src={arrowDown}
                            className="arrow-filter"
                            onClick={()=> setIsOpenFilterForm(true)}
                        />
                        <Dialog open={isOpenFilterForm} maxWidth="xs" onClose={()=> setIsOpenFilterForm(false)} fullWidth>
                            <DialogContent>
                                <TrialFilter
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    startFilter={handleFilter}
                                    clearFilter={handleClearFilter}
                                />
                            </DialogContent>
                        </Dialog>
                    </div>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 10 }}>
                <Grid item xs={4}>
                    <Typography style={{ visibility: 'hidden' }}>Cari Klinik</Typography>
                    <PInput
                        style={{
                            marginRight: 32,
                            flex: 0.718,
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch} />
                            </InputAdornment>
                        }}
                        placeholder="Cari klinik disini"
                        onChange={handleChangeSearch}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={5}>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Status Trial</Typography>
                    <PInput
                        value={filterData.isTrial}
                        onChange={handleChangeDropDown}
                        select
                        fullWidth
                    >
                        <MenuItem value={1}>Trial</MenuItem>
                        <MenuItem value={2}>Trial Habis</MenuItem>
                    </PInput>
                </Grid>
            </Grid>
            {
                isFilter && <Grid item xs={12} style={{ marginTop: 30, dispaly: 'flex' }}>
                    {
                        selectedTrialFilter.tanggalAwalTrialStart && selectedTrialFilter.tanggalAwalTrialStart !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Awal Trial Dari Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedTrialFilter.tanggalAwalTrialEnd && selectedTrialFilter.tanggalAwalTrialEnd !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Awal Trial Sampai Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedTrialFilter.tanggalAkhirTrialStart && selectedTrialFilter.tanggalAkhirTrialStart !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Akhir Trial Dari Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedTrialFilter.tanggalAkhirTrialEnd && selectedTrialFilter.tanggalAkhirTrialEnd !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Akhir Trial Sampai Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedTrialFilter.namaKlinik && selectedTrialFilter.namaKlinik !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Nama Klinik"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedTrialFilter.isTrial && selectedTrialFilter.isTrial !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Status Trial"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                </Grid>
            }
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <TrialTable
                    setIsOpenAddNew={setIsOpenAddNew}
                    isFilter={isFilter}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    pageFilter={pageFilter}
                />
            </Grid>
        </Grid>
    );
};

const mapState = ({ activity }) => ({
    selectedTrialFilter: activity.selectedTrialFilter,
});

const mapDispatch = ({ activity: { updateSelectedRejection, fetchTrialListFilter, updateSelectedTrialFilter } }) => ({
    updateSelectedRejection,
    fetchTrialListFilter,
    updateSelectedTrialFilter,
});

export default connect(mapState, mapDispatch)(Trial);
