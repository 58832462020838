import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {dispatch} from "../../../store";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import {updateFreeze} from "../../../api/freeze";

const styles = makeStyles({
    label: {
        color: "black",
        fontWeight: "bold",
        marginBottom: 10,
    },
});

const UnfreezeForm = ({ onClose, selectedFreeze, currentUser, fetchFreeze }) => {
    const classes = styles();
    const [reasonUnfreeze, setReasonUnfreeze] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    async function handleRequestUnfreeze() {
        const { snackbar } = dispatch;
        try {
            const payload = {...selectedFreeze};
            payload.updatedAt = new Date();
            payload.updatedId = currentUser.id;
            payload.isRequestUnfreeze = true;
            payload.reasonUnfreeze = reasonUnfreeze;
            setIsSubmitting(true);
            await updateFreeze(selectedFreeze.id, payload);
            snackbar.showSuccess("Berhasil request unfreeze");
            setIsSubmitting(false);
            onClose();
            fetchFreeze();
        } catch (e) {
            console.error(e);
            snackbar.showError("Gagal request unfreeze");
            setIsSubmitting(false);
        }
    }

    return(
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>Alasan Unfreeze *</Typography>
                            <PInput
                                value={reasonUnfreeze}
                                onChange={(e)=> setReasonUnfreeze(e.target.value)}
                                rows={4}
                                multiline
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={onClose}
                    disabled={isSubmitting}
                >
                    Batal
                </PBtn>
                <PBtn
                    onClick={()=> handleRequestUnfreeze()}
                    disabled={reasonUnfreeze === "" || isSubmitting}
                >
                    {!isSubmitting ? "Request" : <FontAwesomeIcon icon={faSpinner} spin/> }
                </PBtn>
            </Grid>
        </Grid>
    )
};

const mapState = ({ freeze, user }) => ({
    selectedFreeze: freeze.selectedFreeze,
    currentUser: user.currentUser,
});

const mapDispatch = ({ freeze: { fetchFreeze } }) => ({
    fetchFreeze,
});

export default connect(mapState, mapDispatch)(UnfreezeForm);
