import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PDatePicker from "../../share/PDatePicker";
import PBtn from "../../share/PBtn";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
});

const ReferralFilter = ({ filterData, setFilterData, onSubmit, onClear }) => {
    const classes = styles();

    const handleChange = (e) => {
        setFilterData({...filterData, [e.target.name]: e.target.value});
    };

    const handleChangeTanggal = (date) => {
        setFilterData(prevState => ({
            ...prevState, tanggal: date,
        }));
    };

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Tanggal</Typography>
                        <PDatePicker
                            value={filterData.tanggal}
                            onChange={handleChangeTanggal}
                            format='dd/MM/yyyy'
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Yang Mereferralkan</Typography>
                        <PInput
                            name='namaMereferral'
                            value={filterData.namaMereferral}
                            onChange={handleChange}
                            fullWidth
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Instansi Yang Mereferralkan</Typography>
                        <PInput
                            name='instansiMereferral'
                            value={filterData.instansiMereferral}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Yang Direferralkan</Typography>
                        <PInput
                            name='namaDireferral'
                            value={filterData.namaDireferral}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Instansi Yang Direferralkan</Typography>
                        <PInput
                            name='instansiDireferral'
                            value={filterData.instansiDireferral}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify='flex-end' item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{
                        marginRight: 16,
                    }}
                    onClick={onClear}
                >
                    Clear
                </PBtn>
                <PBtn
                    onClick={onSubmit}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default ReferralFilter;
