import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faTrash, faBookmark, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import format from "date-fns/format";
import {dispatch} from "../../../store";

import StatusFreezeChanger from "./StatusFreezeChanger";
import DeleteConfirmation from "../../share/DeleteConfirmation";
import {deleteFreeze} from "../../../api/freeze";
import UnfreezeForm from "./UnfreezeForm";
import FreezeRequestForm from "./FreezeRequestForm";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
});

const FreezeItem = ({ page, rowsPerPage, freezeList, isFetchingFreezeList, updateSelectedFreeze, setIsOpenFreezeForm, fetchFreeze, currentUser }) => {
    const classes = styles();
    const index = page * rowsPerPage;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedFreezeId, setSelectedFreezeId] = useState(undefined);
    const { UserRole } = currentUser;
    const isManajemen = UserRole && UserRole.role === 'manajemen';
    const [isOpenUnfreezeForm, setIsOpenUnfreezeForm] = useState(false);
    const [isOpenFreezeRequestForm, setIsOpenFreezeRequestForm] = useState(false);

    function handleOpenUnfreezeForm(freeze) {
        setIsOpenUnfreezeForm(true);
        updateSelectedFreeze(JSON.parse(JSON.stringify(freeze)));
    }

    function handleCloseUnfreezeForm() {
        setIsOpenUnfreezeForm(false);
        updateSelectedFreeze({});
    }

    function handleOpenFreezeRequestForm(freeze) {
        setIsOpenFreezeRequestForm(true);
        updateSelectedFreeze(JSON.parse(JSON.stringify(freeze)));
    }

    function handleCloseFreezeRequestForm() {
        setIsOpenFreezeRequestForm(false);
        updateSelectedFreeze({});
    }

    function handleClickEdit(freeze) {
        setIsOpenFreezeForm(true);
        updateSelectedFreeze(JSON.parse(JSON.stringify(freeze)));
    }

    async function handleDelete() {
        const { snackbar } = dispatch;
        try {
            setIsDeleting(true);
            await deleteFreeze(selectedFreezeId);
            snackbar.showSuccess("Berhasil menghapus freeze");
            setIsDeleting(false);
            setShowDeleteConfirmation(false);
            fetchFreeze();
        } catch (e) {
            console.error(e);
            snackbar.showError("Gagal menghapus freeze");
            setIsDeleting(false);
            setShowDeleteConfirmation(false);
            fetchFreeze();
        }
    }

    return(
        <React.Fragment>
            <DeleteConfirmation
                contentText={"Apakah anda yakin akan menghapus freeze ini?"}
                open={showDeleteConfirmation}
                onClose={()=> {
                    setShowDeleteConfirmation(false);
                    setSelectedFreezeId(undefined);
                }}
                onSubmit={()=> handleDelete()}
            />
            <Dialog open={isOpenUnfreezeForm} onClose={()=> handleCloseUnfreezeForm()} maxWidth="xs" fullWidth>
                <DialogContent>
                    <UnfreezeForm onClose={()=> handleCloseUnfreezeForm()} />
                </DialogContent>
            </Dialog>
            <Dialog open={isOpenFreezeRequestForm} onClose={()=> handleCloseFreezeRequestForm()} maxWidth="xs" fullWidth>
                <DialogContent>
                    <FreezeRequestForm onClose={()=> handleCloseFreezeRequestForm()} />
                </DialogContent>
            </Dialog>
            {
                isFetchingFreezeList === true ? (
                    <TableRow>
                        <TableCell colSpan={4}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        </TableCell>
                    </TableRow>
                ) : freezeList.slice(index, index + rowsPerPage).map((freeze, idx) => (
                    <TableRow
                        key={idx}
                        className={classes.row}
                        onClick={()=> handleClickEdit(freeze)}
                    >
                        <TableCell className={classes.tableCellBody}>
                            <span>{format(new Date(freeze.freezeDate), "dd/MM/yyyy")}</span>
                            </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            <span>{freeze.HealthFacility.name}</span>
                        </TableCell>
                        <TableCell style={{ position: "relative" }} className={classes.tableCellBody}>
                            <span style={{ display: "block", marginBottom: 10 }}>{freeze.reason}</span>
                            {
                                isManajemen && freeze.isRequestUnfreeze
                                    ? <span style={{ fontStyle: "italic" }}>Alasan Unfreeze:<br/>{freeze.reasonUnfreeze}</span> : null
                            }
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            <div>
                                <span>{freeze.status === 0 ? "Undecided" : freeze.status === 1 ? "Freeze" : "Unfreeze"}</span>
                                {
                                    isManajemen && <StatusFreezeChanger freeze={freeze}/>
                                }
                            </div>
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            {
                                !isManajemen && freeze.status === 1 && freeze.isRequestUnfreeze === false
                                    ? <Tooltip title="Unfreeze Request">
                                        <IconButton
                                            disabled={isDeleting}
                                            onClick={(e)=> {
                                                e.stopPropagation();
                                                handleOpenUnfreezeForm(freeze);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPaperPlane} style={{ color: "green", width: 18, height: 18 }} />
                                        </IconButton>
                                    </Tooltip>
                                    : !isManajemen && freeze.status === 2 && freeze.isRequestFreeze === false
                                    ? <Tooltip title="Freeze Request">
                                        <IconButton
                                            disabled={isDeleting}
                                            onClick={(e)=> {
                                                e.stopPropagation();
                                                handleOpenFreezeRequestForm(freeze);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPaperPlane} style={{ color: "green", width: 18, height: 18 }} />
                                        </IconButton>
                                    </Tooltip>
                                    : !isManajemen && freeze.status === 1 && freeze.isRequestUnfreeze === true
                                    ? <Tooltip title="Unfreeze Requested">
                                        <IconButton onClick={(e)=> e.stopPropagation()}>
                                            <FontAwesomeIcon icon={faBookmark} style={{ color: "blue", width: 18, height: 18 }} />
                                        </IconButton>
                                    </Tooltip>
                                    : !isManajemen && freeze.status === 2 && freeze.isRequestFreeze === true
                                    ? <Tooltip title="Freeze Requested">
                                        <IconButton onClick={(e)=> e.stopPropagation()}>
                                            <FontAwesomeIcon icon={faBookmark} style={{ color: "blue", width: 18, height: 18}} />
                                        </IconButton>
                                    </Tooltip>
                                    : isManajemen && freeze.status === 1 && freeze.isRequestUnfreeze === true
                                    ? <Tooltip title="Unfreeze Request">
                                        <IconButton onClick={(e)=> e.stopPropagation()}>
                                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "orange", width: 18, height: 18 }} />
                                        </IconButton>
                                    </Tooltip>
                                    : isManajemen && freeze.status === 2 && freeze.isRequestFreeze === true
                                    ? <Tooltip title="Freeze Request">
                                        <IconButton onClick={(e)=> e.stopPropagation()}>
                                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "orange", width: 18, height: 18 }}/>
                                        </IconButton>
                                    </Tooltip>
                                    :null
                            }
                            <Tooltip title="Delete">
                                <IconButton
                                    disabled={isDeleting}
                                    onClick={(e)=> {
                                        e.stopPropagation();
                                        setSelectedFreezeId(freeze.id);
                                        setShowDeleteConfirmation(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} style={{ color: "red", width: 18, height: 18 }} />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))
            }
        </React.Fragment>
    )
};

const mapState = ({ freeze, user }) => ({
    freezeList: freeze.freezeList,
    isFetchingFreezeList: freeze.isFetchingFreezeList,
    currentUser: user.currentUser,
});

const mapDispatch = ({ freeze: { updateSelectedFreeze, fetchFreeze } }) => ({
    updateSelectedFreeze,
    fetchFreeze,
});

export default connect(mapState, mapDispatch)(FreezeItem);
