import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import {makeStyles} from "@material-ui/core";

import PieChartReport from "./PieChartReport";

const styles = makeStyles({
    container: {
        boxShadow: '0 40px 40px -40px #777',
        backgroundColor: 'white',
        borderRadius: 8,
        marginBottom: 60,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 30,
        paddingBottom: 30,
    },
    tableWrapper: {
        overflow: 'auto',
        width: '100%',
        maxHeight: 230,
    },
    label: {
        fontWeight: 'bold',
        fontSize: '20px',
        fontFamily: 'Nunito',
        textAlign: 'center',
        marginBottom: 10,
        color: '#2b64af',
    },
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
});

export default function ({ label, dataPie, dataTable, dataKeyVal, colors }) {
    const classes = styles();

    return (
        <Grid container className={classes.container}>
            <Grid container item xs={12}>
                <Grid item xs={4}>
                    <Typography className={classes.label}>
                        {`Berdasarkan ${label}`}
                    </Typography>
                    <PieChartReport
                        data={dataPie}
                        dataKeyVal={dataKeyVal}
                        colors={colors}
                    />
                </Grid>
                <Grid item xs={8} style={{ paddingTop: 30 }}>
                    <div className={classes.tableWrapper}>
                        <Table>
                            <TableBody>
                                {
                                    dataTable.map((item, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell className={classes.tableCellBody}>
                                                {item._id === null
                                                    ? 'Null'
                                                    : item._id === true
                                                        ? 'True'
                                                        : item._id === false
                                                            ? 'False'
                                                            : item._id
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody}>
                                                {`${item.count} Leads`}
                                            </TableCell>
                                            {typeof item.countLeads !== "undefined" && <TableCell className={classes.tableCellBody}>
                                                {`Total: ${item.countLeads} Leads`}
                                            </TableCell>}
                                            <TableCell className={classes.tableCellBody}>
                                                {`${item.percentage}%`}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}
