import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import {makeStyles} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
});

const PenolakanFilter = ({ filterData, setFilterData, startFilter, clearFilter }) => {
    const classes = styles();

    function handleChangeFilter(e) {
        setFilterData({...filterData, [e.target.name]: e.target.value});
    }

    const handleChangeStartRejectionDate = (date) => {
        filterData.tanggalPenolakanAwal = date;
        setFilterData(prevState => ({
            ...prevState, tanggalPenolakanAwal: filterData.tanggalPenolakanAwal
        }));
    };

    const handleChangeEndRejectionDate = (date) => {
        filterData.tanggalPenolakanSelesai = date;
        setFilterData(prevState => ({
            ...prevState, tanggalPenolakanSelesai: filterData.tanggalPenolakanSelesai
        }));
    };

    return(
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Dari Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalPenolakanAwal}
                            onChange={handleChangeStartRejectionDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Sampai Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalPenolakanSelesai}
                            onChange={handleChangeEndRejectionDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Klinik</Typography>
                        <PInput
                            name="nama"
                            value={filterData.nama}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>PIC Klinik</Typography>
                        <PInput
                            name="picKlinik"
                            value={filterData.picKlinik}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Kategori Penolakan</Typography>
                        <PInput
                            name="kategoriPenolakan"
                            value={filterData.kategoriPenolakan}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Penjelasan Detail</Typography>
                        <PInput
                            name="penjelasanDetail"
                            value={filterData.penjelasanDetail}
                            onChange={(e)=> handleChangeFilter(e)}
                            multiline
                            rows={2}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={clearFilter}
                >
                    Reset
                </PBtn>
                <PBtn
                    onClick={startFilter}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default PenolakanFilter;


