import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {connect} from 'react-redux'
import history from "../../../../history";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBell
} from '@fortawesome/free-solid-svg-icons';
import {
    Typography,
    Grid,
    Badge,
    IconButton,
    Avatar,
    Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NoImage from './../../../../assets/img/no-image-available.png';
import './header.css';

const style = makeStyles(theme => ({
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    container: {
        padding: '25px 20px',
    },
    wrap: {
        display: 'inline-flex',
        float: 'right',
    },
    notif: {
        marginRight: 20,
    },
    user: {
        cursor: 'pointer',
    },
}));

function Header(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = style();

    return(
        <div className={`layout-header ${props.inverted ? 'layout-header-inverted' : ''}`} style={props.isViewedByMobile ? {height: '5%'} : {height: '15%'}}>
            <Container className={classes.container}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography>{props.title}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.wrap}>
                            <IconButton aria-label="notifications" className={classes.notif}>
                                <Badge variant="dot" color="secondary">
                                    <FontAwesomeIcon icon={faBell} />
                                </Badge>
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => {
                                    history.replace('/account');
                                    handleClose();
                                }}>Profile</MenuItem>
                                <MenuItem onClick={props.handleLogout}>Logout</MenuItem>
                            </Menu>

                            <div className={classes.user}>
                                <Avatar onClick={handleClick} alt="user-alt" src={props.currentUser.profilePhoto === '' ? NoImage : props.currentUser.profilePhoto} className={classes.large} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

const mapState = ({ user, app }) => ({
    currentUser: user.currentUser,
    isViewedByMobile: app.isViewedByMobile,
});

const mapDispatch = ({ auth: { handleLogout } }) => ({
    handleLogout,
});

export default connect(mapState, mapDispatch)(Header);
