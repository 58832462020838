import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import {makeStyles} from "@material-ui/core/styles";

import PenolakanItem from "./PenolakanItem";

const styles = makeStyles({
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    }
});

const PenolakanTable =
    ({ fetchRejections,
         setIsOpenAddNew,
         fetchRejectionsFilter,
         rejectionListCounter,
         isFilter,
         filterData, setFilterData, pageFilter }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        if(isFilter === true){
            setPage(pageFilter);
            handleRenderRejectionsFilter();
        } else {
            setPage(pageFilter);
            fetchRejections();
        }
    }, [fetchRejections, fetchRejectionsFilter, rejectionListCounter, isFilter, pageFilter]);

    const handleRenderRejectionsFilter = () => {
        setFilterData(filterData => ({
            ...filterData, condition: false
        }));
        fetchRejectionsFilter(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        if(isFilter === true){
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit
            }));
            fetchRejectionsFilter(filterData);
        } else {
            fetchRejections(skipLimit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        const newRowsPerpage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerpage,
        };
        if(isFilter === true){
            filterData.skip = 0;
            filterData.limit = newRowsPerpage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit
            }));
            fetchRejectionsFilter(filterData);
        } else{
            fetchRejections(skipLimit);
        }
        setRowsPerPage(newRowsPerpage);
        setPage(0);
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableCellHeader}>
                        <span>Tanggal Penolakan</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Nama Klinik</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>PIC Klinik</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Kategori Penolakan</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Penjelasan Detail</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Action</span>
                    </TableCell>
                </TableRow>
            </TableHead>

            <PenolakanItem
                setIsOpenAddNew={setIsOpenAddNew} page={page} rowsPerPage={rowsPerPage}
            />

            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={6}
                        count={rejectionListCounter}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
};

const mapState = ({ activity }) => ({
    rejectionListCounter: activity.rejectionListCounter,
});

const mapDispatch = ({ activity: { fetchRejections, fetchRejectionsFilter } }) => ({
    fetchRejections,
    fetchRejectionsFilter,
});

export default connect(mapState, mapDispatch)(PenolakanTable);
