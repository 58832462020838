import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { connect } from 'react-redux';

import PInput from "../share/PInput";
import PBtn from "../share/PBtn";

const AddNew =  function ({ currentUser, handler, placeholder, buttonLabel }) {
    const [categoryName, setCategoryName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { value } = e.target;
        setCategoryName(value);
    };

    const handleSubmit = () => {
        handler(categoryName, currentUser, setIsSubmitting, setCategoryName);
    };

    return (
        <Grid item xs={12} style={{ marginTop: 44 }}>
            <PInput
                style={{
                    marginRight: 32,
                    width: '33%',
                }}
                placeholder={placeholder}
                value={categoryName}
                onChange={handleChange}
            />
            <PBtn disabled={isSubmitting} onClick={handleSubmit}>{buttonLabel}</PBtn>
        </Grid>
    )
};

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(AddNew);
