import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import {connect} from "react-redux";

import PBtn from "../../share/PBtn";

import IklanTable from "./IklanTable";
import IklanForm from "./IklanForm";

const Iklan = ({ updateSelectedIklan }) => {
    const [isOpenDialog, setIsOpenDialog] = useState(false);

    function handleCloseDialog() {
        updateSelectedIklan({});
        setIsOpenDialog(false);
    }

    return (
        <Grid container>
            <Dialog open={isOpenDialog} maxWidth="xs" onClose={()=> handleCloseDialog()} fullWidth>
                <DialogContent>
                    <IklanForm onClose={()=> handleCloseDialog()}/>
                </DialogContent>
            </Dialog>
            <Grid item xs={12} style={{ marginTop: 44 }}>
                <PBtn onClick={()=> setIsOpenDialog(true)}>Tambah Iklan</PBtn>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 30 }}>
                <IklanTable setIsOpenDialog={setIsOpenDialog}/>
            </Grid>
        </Grid>
    );
};

const mapState = null;

const mapDispatch = ({ iklan: { updateSelectedIklan } }) => ({
    updateSelectedIklan,
});

export default connect(mapState, mapDispatch)(Iklan);
