import { getMedias } from '../../api/media';

export const media = {
    state: {
        isFetchingMediaList: false,
        mediaList: [],
    },
    reducers: {
        updateState(state, payload) {
            const { stateName, value } = payload;
            return {
                ...state,
                [stateName]: value,
            };
        },
    },
    effects: dispatch => ({
        async fetchMedias() {
            try {
                this.updateState({
                    stateName: 'isFetchingMediaList',
                    value: true,
                });

                const result = await getMedias();
                this.updateState({
                    stateName: 'mediaList',
                    value: result.data,
                });
                this.updateState({
                    stateName: 'isFetchingMediaList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar media");
                this.updateState({
                    stateName: 'isFetchingMediaList',
                    value: false,
                });
            }
        },
    }),
};
