import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCalendarAlt, faFrownOpen, faGrin, faGrinStars} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import NumberFormat from "react-number-format";
import format from "date-fns/format";
import idLocale from "date-fns/locale/id";
import history from "../../../history";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import DeleteConfirmation from "../../share/DeleteConfirmation";

import CycleLeads from "./CycleLeads";
import TableLastActivityLeads from "./TableLastActivityLeads";
import TableDetailPICLeads from "./TableDetailPICLeads";
import FormPICLeads from "./FormPICLeads";
import DataLogLeads from "./DataLogLeads";

import {getActivityLatestLeads} from "../../../api/activity";

const styles = makeStyles({
    content: {
        width: '100%',
    },
    body: {
        width: '100%',
        height: '85%',
        position: 'absolute',
        marginTop: '7%',
        bottom: 0,
        backgroundColor: '#f5f6f8',
        overflowY: 'auto',
        '@media only screen and (max-width:994px)': {
            height: '90%',
            width: '1440px',
        },
    },
    container: {
        padding: '25px 20px',
        '@media only screen and (max-width:994px)': {
            padding: '4% 20px',
        },
        '@media only screen and (max-width:400px)': {
            padding: '3% 20px',
        },
    },
    labelDetailWrap: {
        marginTop: 0,
        display: 'inline-flex',
    },
    labelDetail: {
        fontWeight: 'bold',
        fontSize: '23px',
        fontFamily: 'Nunito',
        marginBottom: 40,
        color: '#15162d',
    },
    labelStep: {
        fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: 'Nunito',
        marginBottom: 20,
        color: '#2b64af',
    },
    labelField: {
        color: '#15162d',
        fontSize: '15px',
        fontFamily: 'Nunito',
        marginBottom: 5,
    },
    arrowBack: {
        cursor: 'pointer',
        marginRight: '20px',
        color: 'black',
        marginTop: 10,
    },
    formLeft: {
        marginRight: 20,
    },
    formRight: {
        marginLeft: 20,
    },
    fieldWrap: {
        marginBottom: 15,
    },
    gridWrap: {
        marginBottom: 20,
    },
    hotnessLevelWrap: {
        display: 'inline-flex',
    },
    labelHotnessLevel: {
        paddingTop: 15,
        marginRight: 5,
    },
    iconInterested: {
        color: '#008000',
    },
    iconNormal: {
        color: '#FFA500',
    },
    iconUninterested: {
        color: '#FF4500',
    },
    labelChip: {
        width: 110,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block',
    },
    labelLastActivity: {
        fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: 'Nunito',
        marginTop: 40,
        marginBottom: -20,
        color: '#2b64af',
    },
    grid: {
        marginTop: 40,
    },
});

function numberFormatCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            displayType='text'
            prefix="Rp. "
            decimalSeparator=","
            thousandSeparator="."
        />
    );
}

const FormDetailLeadsV1 = ({ singleFaskesList, isFetchingSingleFaskesList, currentUser, editLeads, removeLeads, fetchFaskesList, isViewedByMobile }) => {
    const isDetail = typeof singleFaskesList.id !== 'undefined';
    const classes = styles();
    const tableHeaderPIC = [
        {label: 'Nama Lengkap', minWidth: 120},
        {label: 'Nama Panggilan', minWidth: 100},
        {label: 'Jabatan', minWidth: 100},
        {label: 'Whatsapp', minWidth: 100},
        {label: 'Email', minWidth: 100},
        {label: 'Alamat', minWidth: 100},
        {label: 'Tanggal Lahir', minWidth: 100},
        {label: 'Keterangan', minWidth: 100},
    ];
    const tableHeaderLastActivity = [
        {label: 'Tanggal Aktifitas', minWidth: 80},
        {label: 'PIC Klinik', minWidth: 60},
        {label: 'PIC Assist.id', minWidth: 60},
        {label: 'Media', minWidth: 60},
        {label: 'Status', minWidth: 60},
        {label: 'Detail Activity', minWidth: 150},
    ];
    const hotnessLevel = [
        {
            icon: faGrinStars,
            label: 'Interested',
            style: classes.iconInterested,
        },
        {
            icon: faGrin,
            label: 'Normal',
            style: classes.iconNormal,
        },
        {
            icon: faFrownOpen,
            label: 'Uninterested',
            style: classes.iconUninterested,
        },
    ];
    const [leads, setLeads] = useState({
        picId: '',
        assistPIC: '',
        contactStatus: '',
        leadsContact: '',
        leadsInfo: '',
        event: '',
        isReferred: '',
        referredName: '',
        name: '',
        type: '',
        email: '',
        phoneNumber: '',
        googleMap: '',
        facebook: '',
        instagram: '',
        website: '',
        street: '',
        province : '',
        city : '',
        district : '',
        postCode : '',
        operationalStatus: '',
        MCUAndLabServices: '',
        isJejaring: '',
        numberOfBranches: '',
        isCampaignRunning: '',
        campaignName: [],
        isCompetitorPotential: '',
        competitorName: '',
        competitorLevel: '',
        url: '',
        isUnknown: '',
        isAlreadyUsingSystem: '',
        usedSystems: [],
        isOwnServerExist: '',
        isBPJS: '',
        isPharmacyExist: '',
        neededFeature: '',
        hotButton: '',
        reasonToUsingSystem: '',
        systemImplementationPlan: '',
        note: '',
        hotnessLevel: '',
        closingProductTypePotential: '',
        closingProductDurationPotential: '',
        closingProductValuePotential: '',
        isCustom: '',
        dataCustom: '',
        numberOfVisits: '',
        capitationBPJS: '',
        isSubscribed: '',
        recontact: '',
        useSystemNote: '',
        useSystemDuration: '',
        useSystemStartDate: null,
        useSystemEndDate: null,
        isAnyAnomaly: '',
        resubscribeCounter: '',
        status: '',
        rejectionCategory: '',
        lastContactDate: null,
        cycle: [{
            startDate: null,
            endDate: null,
            assistPIC: '',
            lastStatus: '',
            note: '',
            status: '',
        }],
    });
    const [isOpenFormPICLeads, setIsOpenFormPICLeads] = useState(false);
    const [flagPIC, setFlagPIC] = useState({
        PIC: [{
            namepic : '',
            nickname : '',
            position : '',
            emailpic : '',
            whatsapp : '',
            address : '',
            birthday : null,
            note : '',
        }],
    });
    const [isCustom, setIsCustom] = useState(false);
    const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(false);

    useEffect(()=> {
        if(isDetail) {
            const load = {
                id: singleFaskesList.id,
                picId: singleFaskesList.picId || '',
                assistPIC: singleFaskesList.assistPIC || '',
                contactStatus: singleFaskesList.contactStatus || '',
                leadsContact: singleFaskesList.leadsContact || '',
                leadsInfo: singleFaskesList.leadsInfo || '',
                event: singleFaskesList.event || '',
                isReferred: typeof singleFaskesList.isReferred !== 'undefined'
                    ? singleFaskesList.isReferred
                    : '',
                referredName: singleFaskesList.referredName || '',
                PIC: singleFaskesList.PIC,
                name: singleFaskesList.name || '',
                type: singleFaskesList.type || '',
                email: singleFaskesList.email || '',
                phoneNumber: singleFaskesList.phoneNumber || '',
                googleMap: singleFaskesList.links.googleMap || '',
                facebook: singleFaskesList.links.facebook || '',
                instagram: singleFaskesList.links.instagram || '',
                website: singleFaskesList.links.website || '',
                street: singleFaskesList.address.street || '',
                province: singleFaskesList.address.province || '',
                city: singleFaskesList.address.city || '',
                district: singleFaskesList.address.district || '',
                postCode: singleFaskesList.address.postCode || '',
                operationalStatus: singleFaskesList.operationalStatus || '',
                MCUAndLabServices: singleFaskesList.MCUAndLabServices || '',
                isJejaring: singleFaskesList.isJejaring || '',
                numberOfBranches: singleFaskesList.numberOfBranches || '',
                isCampaignRunning: typeof singleFaskesList.isCampaignRunning !== 'undefined'
                    ? singleFaskesList.isCampaignRunning
                    : '',
                campaignName: singleFaskesList.campaignName || [],
                isCompetitorPotential: typeof singleFaskesList.isCompetitorPotential !== 'undefined'
                    ? singleFaskesList.isCompetitorPotential
                    : '',
                competitorName: singleFaskesList.competitorName || '',
                competitorLevel: singleFaskesList.competitorLevel || '',
                url: singleFaskesList.url || '',
                isUnknown: typeof singleFaskesList.isUnknown !== 'undefined'
                    ? singleFaskesList.isUnknown
                    : '',
                isAlreadyUsingSystem: typeof singleFaskesList.isAlreadyUsingSystem !== 'undefined'
                    ? singleFaskesList.isAlreadyUsingSystem
                    : '',
                usedSystems: singleFaskesList.usedSystems || [],
                isOwnServerExist: typeof singleFaskesList.isOwnServerExist !== 'undefined'
                    ? singleFaskesList.isOwnServerExist
                    : '',
                isBPJS: singleFaskesList.isBPJS || '',
                isPharmacyExist: singleFaskesList.isPharmacyExist || '',
                neededFeature: singleFaskesList.neededFeature || '',
                hotButton: singleFaskesList.hotButton || '',
                reasonToUsingSystem: singleFaskesList.reasonToUsingSystem || '',
                systemImplementationPlan: singleFaskesList.systemImplementationPlan || '',
                note: singleFaskesList.note || '',
                hotnessLevel: singleFaskesList.hotnessLevel || '',
                closingProductTypePotential: singleFaskesList.closingProductTypePotential || '',
                closingProductDurationPotential: singleFaskesList.closingProductDurationPotential || '',
                closingProductValuePotential: singleFaskesList.closingProductValuePotential || '',
                isCustom: typeof singleFaskesList.isCustom !== 'undefined'
                    ? singleFaskesList.isCustom
                    : '',
                dataCustom: singleFaskesList.dataCustom || '',
                numberOfVisits: singleFaskesList.numberOfVisits || '',
                capitationBPJS: singleFaskesList.capitationBPJS || '',
                isSubscribed: typeof singleFaskesList.isSubscribed !== 'undefined'
                    ? singleFaskesList.isSubscribed
                    : '',
                recontact: singleFaskesList.recontact || '',
                useSystemNote: singleFaskesList.useSystemNote || '',
                useSystemDuration: singleFaskesList.useSystemDuration || '',
                useSystemStartDate: singleFaskesList.useSystemStartDate || null,
                useSystemEndDate: singleFaskesList.useSystemEndDate || null,
                isAnyAnomaly: typeof singleFaskesList.isAnyAnomaly !== 'undefined'
                    ? singleFaskesList.isAnyAnomaly
                    : '',
                resubscribeCounter: typeof singleFaskesList.resubscribeCounter !== 'undefined'
                    ? singleFaskesList.resubscribeCounter
                    : '',
                status: singleFaskesList.status || '',
                rejectionCategory: singleFaskesList.rejectionCategory || '',
                lastContactDate: singleFaskesList.lastContactDate || new Date(),
                cycle: singleFaskesList.cycle || [{
                    startDate: null,
                    endDate: null,
                    assistPIC: '',
                    lastStatus: '',
                    note: '',
                    status: '',
                }],
            };
            (async function () {
                const result = await getActivityLatestLeads(singleFaskesList.id);
                if(result.data.length > 0) {
                    load.lastContactDate = result.data[0].activityDate;
                }
            })().then(()=> setLeads(load));
            setFlagPIC({
                PIC: singleFaskesList.PIC,
            });
        }
    }, [isDetail, singleFaskesList]);

    const handleOpenFormPICLeads = () => {
        setFlagPIC(prevState => ({
            ...prevState, PIC: [...flagPIC.PIC, { namepic: '', nickname: '', position: '', emailpic: '', whatsapp: '', address: '', birthday: null, note: '' }]
        }));
        setIsOpenFormPICLeads(true);
    };

    const handleCloseFormPICLeads = () => {
        const idx = flagPIC.PIC.length - 1;
        flagPIC.PIC[idx] = {...flagPIC.PIC[idx]};
        flagPIC.PIC.slice(idx, 1);
        setFlagPIC(prevState => ({
            ...prevState, PIC: flagPIC.PIC,
        }));
        setIsOpenFormPICLeads(false);
        setIsCustom(false);
    };

    const handleSavePIC = (e) => {
        e.preventDefault();
        leads.PIC = flagPIC.PIC;
        setLeads(prevState => ({
            ...prevState, PIC: leads.PIC,
        }));
        const flag = {...leads};
        flag.updatedId = currentUser.id;
        editLeads(flag).then(()=> window.location.reload());
        setIsOpenFormPICLeads(false);
        setIsCustom(false);
    };

    const handleDelete = () => {
        removeLeads(leads.id).then(()=> fetchFaskesList());
        setIsShowDeleteConfirmation(false);
        history.push('/leads');
    };

    return (
        <div className={classes.content}>
            <div className={classes.body} style={isViewedByMobile ? {height: '95%'} : {height: '85%'}}>
                <Container className={classes.container}>
                    <DeleteConfirmation
                        contentText='Apakah anda yakin akan menghapus leads ini?'
                        open={isShowDeleteConfirmation}
                        onClose={()=> setIsShowDeleteConfirmation(false)}
                        onSubmit={handleDelete}
                    />
                    <Dialog open={isOpenFormPICLeads} maxWidth='md' onClose={handleCloseFormPICLeads} fullWidth>
                        <FormPICLeads
                            onClose={handleCloseFormPICLeads}
                            flagPIC={flagPIC}
                            setFlagPIC={setFlagPIC}
                            onSubmit={handleSavePIC}
                            isCustom={isCustom}
                            setIsCustom={setIsCustom}
                        />
                    </Dialog>
                    <Grid container>
                        <Grid item xs={12}>
                            {!isFetchingSingleFaskesList && <div className={classes.labelDetailWrap}>
                                <Link to={'/leads'}>
                                    <FontAwesomeIcon icon={faArrowLeft} className={classes.arrowBack}/>
                                </Link>
                                <Typography className={classes.labelDetail}>
                                    Detail
                                </Typography>
                            </div>}
                            {isFetchingSingleFaskesList && <div style={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress/>
                            </div>}
                            {!isFetchingSingleFaskesList && <CycleLeads cycle={leads.cycle}/>}
                            {!isFetchingSingleFaskesList && <form>
                                <Typography className={classes.labelStep}>Data Background</Typography>
                                <Grid container className={classes.gridWrap}>
                                    <Grid item xs={6}>
                                        <div className={classes.formLeft}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>PIC Assist.id</Typography>
                                                <PInput
                                                    value={leads.assistPIC}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Datang Sendiri / Kita Menghubungi?</Typography>
                                                <PInput
                                                    value={
                                                        leads.contactStatus === 'datang sendiri'
                                                            ? 'Datang Sendiri'
                                                            : leads.contactStatus === 'dihubungi'
                                                            ? 'Dihubungi'
                                                            : leads.contactStatus
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>
                                                    {
                                                        leads.contactStatus === 'datang sendiri'
                                                            ? 'Menghubungi Assist Menggunakan?'
                                                            : 'Menghubungi Customer Menggunakan?'
                                                    }
                                                </Typography>
                                                <PInput
                                                    value={leads.leadsContact}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>
                                                    {
                                                        leads.contactStatus === 'datang sendiri'
                                                            ? 'Mengetahui Assist Dari? (Jangan Asumsi)'
                                                            : 'Mendapatkan Info Customer Dari?'
                                                    }
                                                </Typography>
                                                <PInput
                                                    value={leads.leadsInfo}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {(leads.leadsInfo === 'Event' || leads.leadsContact === 'Event') && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Nama Event</Typography>
                                                <PInput
                                                    value={leads.event}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Ada Yang Mereferralkan?</Typography>
                                                <PInput
                                                    value={
                                                        leads.isReferred === ''
                                                            ? '' : leads.isReferred === true
                                                            ? 'Ada' : 'Tidak'
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {leads.isReferred === true && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Siapa Yang
                                                    Mereferralkan?</Typography>
                                                <PInput
                                                    value={leads.referredName}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Nama Faskes</Typography>
                                                <PInput
                                                    value={leads.name}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Jenis Faskes</Typography>
                                                <PInput
                                                    value={leads.type}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} style={{ marginBottom: 5 }}>
                                                    <Typography className={classes.labelField}>Email Faskes</Typography>
                                                    <PInput
                                                        value={leads.email}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginBottom: 5 }}>
                                                    <Typography className={classes.labelField}>No. Telepon Faskes</Typography>
                                                    <PInput
                                                        value={leads.phoneNumber}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginBottom: 5 }}>
                                                    <Typography className={classes.labelField}>Google Map</Typography>
                                                    <PInput
                                                        value={leads.googleMap}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginBottom: 5 }}>
                                                    <Typography className={classes.labelField}>Instagram</Typography>
                                                    <PInput
                                                        value={leads.instagram}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginBottom: 5 }}>
                                                    <Typography className={classes.labelField}>Website</Typography>
                                                    <PInput
                                                        value={leads.website}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginBottom: 5 }}>
                                                    <Typography className={classes.labelField}>Facebook</Typography>
                                                    <PInput
                                                        value={leads.facebook}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.formRight}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Alamat Faskes</Typography>
                                                <PInput
                                                    value={leads.street}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} style={{ marginBottom: 5 }}>
                                                    <Typography className={classes.labelField}>Provinsi</Typography>
                                                    <PInput
                                                        value={leads.province}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginBottom: 5 }}>
                                                    <Typography className={classes.labelField}>Kabupaten/Kota</Typography>
                                                    <PInput
                                                        value={leads.city}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginBottom: 15 }}>
                                                    <Typography className={classes.labelField}>Kecamatan</Typography>
                                                    <PInput
                                                        value={leads.district}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginBottom: 15 }}>
                                                    <Typography className={classes.labelField}>Kode Pos</Typography>
                                                    <PInput
                                                        value={leads.postCode}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Faskes Sudah Beroperasional?</Typography>
                                                <PInput
                                                    value={leads.operationalStatus}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Memiliki Layanan MCU dan Lab?</Typography>
                                                <PInput
                                                    value={leads.MCUAndLabServices}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Klinik Jejaring?</Typography>
                                                <PInput
                                                    value={leads.isJejaring}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {leads.isJejaring === 'Iya' && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Berapa Jumlah Cabangnya?</Typography>
                                                <PInput
                                                    value={leads.numberOfBranches}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Sedang Ada Iklan?</Typography>
                                                <PInput
                                                    value={
                                                        leads.isCampaignRunning === ''
                                                            ? '' : leads.isCampaignRunning === true
                                                            ? 'Iya' : 'Tidak'
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {leads.isCampaignRunning === true && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Nama Iklan</Typography>
                                                <PInput
                                                    value={
                                                        leads.campaignName.map(item => item || '').join(', ')
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>}
                                            {leads.campaignName.length > 0 && <Grid container>
                                                {leads.campaignName.map((item, idx) => (
                                                    <Grid key={idx} item xs={4} style={{ marginBottom: 5}}>
                                                        <Chip
                                                            label={
                                                                <span className={classes.labelChip}>{item}</span>
                                                            }
                                                            color='primary'
                                                            onDelete={()=> {}}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Potensi Kompetitor</Typography>
                                                <PInput
                                                    value={leads.isCompetitorPotential}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {leads.isCompetitorPotential === 'Iya' && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Nama Kompetitor</Typography>
                                                <PInput
                                                    value={leads.competitorName}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>}
                                            {leads.isCompetitorPotential === 'Iya' && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Level Kompetitor</Typography>
                                                <PInput
                                                    value={leads.competitorLevel}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Leads Tidak Jelas?</Typography>
                                                <PInput
                                                    value={
                                                        leads.isUnknown === ''
                                                            ? '' : leads.isUnknown === true
                                                            ? 'Tidak Jelas' : 'Jelas'
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Preferensi</Typography>
                                                <PInput
                                                    value={leads.url}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.labelStep}>Data Fitur</Typography>
                                <Grid container spacing={1} className={classes.gridWrap}>
                                    <Grid item xs={6}>
                                        <div className={classes.formLeft}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Sudah Pakai Sistem Sebelumnya?</Typography>
                                                <PInput
                                                    value={
                                                        leads.isAlreadyUsingSystem === ''
                                                            ? '' : leads.isAlreadyUsingSystem === true
                                                            ? 'Sudah' : 'Belum'
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {leads.isAlreadyUsingSystem === true && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Sistem Apa Saja?</Typography>
                                                <PInput
                                                    value={
                                                        leads.usedSystems.map(item => item || '').join(', ')
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>}
                                            {leads.usedSystems.length > 0 && <Grid container>
                                                {leads.usedSystems.map((item, idx) => (
                                                    <Grid key={idx} item xs={4} style={{ marginBottom: 5 }}>
                                                        <Chip
                                                            label={
                                                                <span className={classes.labelChip}>{item}</span>
                                                            }
                                                            color='primary'
                                                            onDelete={()=> {}}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah di Faskes Ada Server Sendiri?</Typography>
                                                <PInput
                                                    value={
                                                        leads.isOwnServerExist === ''
                                                            ? '' : leads.isOwnServerExist === true
                                                            ? 'Ada' : 'Tidak'
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth

                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Bekerjasama Dengan BPJS?</Typography>
                                                <PInput
                                                    value={leads.isBPJS}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Tersedia Apotek?</Typography>
                                                <PInput
                                                    value={leads.isPharmacyExist}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Fitur Yang Dibutuhkan?</Typography>
                                                <PInput
                                                    value={leads.neededFeature}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    rows={3}
                                                    multiline
                                                    fullWidth
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.formRight}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Hot Button</Typography>
                                                <PInput
                                                    value={leads.hotButton}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    rows={3}
                                                    multiline
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Kenapa Ingin Mengganti/Menggunakan Sistem?</Typography>
                                                <PInput
                                                    value={leads.reasonToUsingSystem}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    rows={3}
                                                    multiline
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Kapan Rencana Faskes Implementasi Sistem? (Tanyakan Waktunya)</Typography>
                                                <PInput
                                                    value={leads.systemImplementationPlan}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Keterangan</Typography>
                                                <PInput
                                                    value={leads.note}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    rows={4}
                                                    multiline
                                                    fullWidth
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.labelStep}>Data Pay</Typography>
                                <Grid container spacing={1} className={classes.gridWrap}>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} style={{ marginBottom: 25 }}>
                                            <Typography className={classes.labelField}>Bagaimana Perasaan Setelah Menggunakan Sistem?</Typography>
                                            <div className={classes.hotnessLevelWrap}>
                                                {hotnessLevel.map((item, idx) => (
                                                    <React.Fragment key={idx}>
                                                        <IconButton>
                                                            <FontAwesomeIcon
                                                                icon={item.icon}
                                                                className={item.style}
                                                                style={
                                                                    leads.hotnessLevel === item.label
                                                                        ? {opacity: 1} : {opacity: 0.25}
                                                                }
                                                            />
                                                        </IconButton>
                                                        <Typography
                                                            className={classes.labelHotnessLevel}
                                                            style={
                                                                leads.hotnessLevel === item.label
                                                                    ? {opacity: 1} : {opacity: 0.25}
                                                            }
                                                        >
                                                            {item.label}
                                                        </Typography>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.formLeft}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Potensi Jenis Produk Closing</Typography>
                                                <PInput
                                                    value={leads.closingProductTypePotential}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Potensi Durasi Produk Closing</Typography>
                                                <PInput
                                                    value={
                                                        leads.closingProductDurationPotential !== ''
                                                            ? `${leads.closingProductDurationPotential} Bulan` : ''
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Potensi Nilai Produk Closing</Typography>
                                                <PInput
                                                    value={leads.closingProductValuePotential}
                                                    InputProps={{
                                                        readOnly: true,
                                                        inputComponent: numberFormatCustom,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Ada Custom?</Typography>
                                                <PInput
                                                    value={
                                                        leads.isCustom === ''
                                                            ? '' : leads.isCustom === true
                                                            ? 'Ada' : 'Tidak'
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {leads.isCustom === true && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Data Custom</Typography>
                                                <PInput
                                                    value={leads.dataCustom}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Jumlah Kunjungan Bulanan</Typography>
                                                <PInput
                                                    value={leads.numberOfVisits}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.formRight}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Jumlah Kapitasi BPJS</Typography>
                                                <PInput
                                                    value={leads.capitationBPJS}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Status Berlangganan</Typography>
                                                <PInput
                                                    value={
                                                        leads.isSubscribed === ''
                                                            ? '' : leads.isSubscribed === true
                                                            ? 'Iya' : 'Tidak'
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Hubungi Lagi</Typography>
                                                <PInput
                                                    value={leads.recontact}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    rows={11}
                                                    multiline
                                                    fullWidth
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.labelStep}>Data Other</Typography>
                                <Grid container spacing={1} className={classes.gridWrap}>
                                    <Grid item xs={6}>
                                        <div className={classes.formLeft}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Catatan Penggunaan</Typography>
                                                <PInput
                                                    value={leads.useSystemNote}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    rows={6}
                                                    multiline
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Durasi Berlangganan</Typography>
                                                <PInput
                                                    value={
                                                        leads.useSystemDuration === ''
                                                            ? '' : `${leads.useSystemDuration} Bulan`
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} style={{ marginBottom: 5 }}>
                                                    <Typography className={classes.labelField}>Tanggal Mulai Berlangganan</Typography>
                                                    <PInput
                                                        value={
                                                            leads.useSystemStartDate === null
                                                                ? '' : format(new Date(leads.useSystemStartDate), "dd MMMM yyyy", {locale: idLocale})
                                                        }
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: <InputAdornment position='end'>
                                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                                            </InputAdornment>
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ marginBottom: 5 }}>
                                                    <Typography className={classes.labelField}>Tanggal Selesai Berlangganan</Typography>
                                                    <PInput
                                                        value={
                                                            leads.useSystemEndDate === null
                                                                ? '' : format(new Date(leads.useSystemEndDate), "dd MMMM yyyy", {locale: idLocale})
                                                        }
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: <InputAdornment position='end'>
                                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                                            </InputAdornment>
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.formRight}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Ada Anomali Penggunaan?</Typography>
                                                <PInput
                                                    value={
                                                        leads.isAnyAnomaly === ''
                                                            ? '' : leads.isAnyAnomaly === true
                                                            ? 'Ada' : 'Tidak'
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Berapa Kali Berlangganan Ulang?</Typography>
                                                <PInput
                                                    value={leads.resubscribeCounter}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Status Saat Ini</Typography>
                                                <PInput
                                                    value={leads.status}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {leads.status === 'Rejected' && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Kategori Penolakan</Typography>
                                                <PInput
                                                    value={leads.rejectionCategory}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Tanggal Terakhir Dihubungi</Typography>
                                                <PInput
                                                    value={
                                                        leads.lastContactDate === null
                                                            ? '' : format(new Date(leads.lastContactDate), "dd MMMM yyyy", {locale: idLocale})
                                                    }
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: <InputAdornment position='end'>
                                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                                        </InputAdornment>
                                                    }}
                                                    disabled
                                                    fullWidth
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} className={classes.grid}>
                                    <Typography className={classes.labelStep}>Data Log</Typography>
                                    <DataLogLeads healthFacilityId={singleFaskesList.id}/>
                                </Grid>
                                <Grid container className={classes.grid}>
                                    <Grid container justify='flex-start' item xs={12}>
                                        <Typography className={classes.labelStep} style={{ marginTop: 5 }}>Informasi PIC Faskes</Typography>
                                        <PBtn
                                            style={{ marginLeft: 36 }}
                                            onClick={handleOpenFormPICLeads}
                                        >Tambah</PBtn>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableDetailPICLeads header={tableHeaderPIC} pic={leads.PIC && leads.PIC} faskes={leads}/>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.labelLastActivity}>Last Activity</Typography>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TableLastActivityLeads header={tableHeaderLastActivity} faskesId={singleFaskesList.id}/>
                                    </Grid>
                                </Grid>
                                <Grid container justify='flex-end' item xs={12} style={{ marginTop: 50, marginBottom: 20 }}>
                                    <PBtn
                                        inverted
                                        dropShadow
                                        style={{ marginRight: 16 }}
                                        onClick={()=> setIsShowDeleteConfirmation(true)}
                                    >Hapus Data</PBtn>
                                    <Link to={`../edit/${leads.id}`} style={{ textDecoration: 'none' }}>
                                        <PBtn>
                                            Edit Data
                                        </PBtn>
                                    </Link>
                                </Grid>
                            </form>}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

const mapState = ({ leads, user, app }) => ({
    isFetchingSingleFaskesList: leads.isFetchingSingleFaskesList,
    singleFaskesList: leads.singleFaskesList,
    currentUser: user.currentUser,
    isViewedByMobile: app.isViewedByMobile,
});

const mapDispatch = ({ leads: { editLeads, removeLeads, fetchFaskesList } }) => ({
    editLeads,
    removeLeads,
    fetchFaskesList,
});

export default connect(mapState, mapDispatch)(FormDetailLeadsV1);
