import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
// import MenuItem from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

// import {getUserList} from "../../../api/panela-user";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
});

const TrialFilter = ({ filterData, setFilterData, startFilter, clearFilter }) => {
    const classes = styles();
    // const [panelaUserList, setPanelaUserList] = useState([]);

    // useEffect(()=> {
    //     (async function () {
    //         const result = await getUserList();
    //         setPanelaUserList(result.data);
    //     })();
    // }, []);

    const handleChangeTanggalAwalTrialStart = (date) => {
        filterData.tanggalAwalTrialStart = date;
        setFilterData(prevState => ({
            ...prevState, tanggalAwalTrialStart: filterData.tanggalAwalTrialStart,
        }));
    };

    const handleChangeTanggalAwalTrialEnd = (date) => {
        filterData.tanggalAwalTrialEnd = date;
        setFilterData(prevState => ({
            ...prevState, tanggalAwalTrialEnd: filterData.tanggalAwalTrialEnd,
        }));
    };

    const handleChangeTanggalAkhirTrialStart = (date) => {
        filterData.tanggalAkhirTrialStart = date;
        setFilterData(prevState => ({
            ...prevState, tanggalAkhirTrialStart: filterData.tanggalAkhirTrialStart,
        }));
    };

    const handleChangeTanggalAkhirTrialEnd = (date) => {
        filterData.tanggalAkhirTrialEnd = date;
        setFilterData(prevState => ({
            ...prevState, tanggalAkhirTrialEnd: filterData.tanggalAkhirTrialEnd,
        }));
    };

    function handleChangeFilter(e) {
        setFilterData({...filterData, [e.target.name]: e.target.value});
    }

    return(
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Awal Trial Dari Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalAwalTrialStart}
                            onChange={handleChangeTanggalAwalTrialStart}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Sampai Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalAwalTrialEnd}
                            onChange={handleChangeTanggalAwalTrialEnd}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Akhir Trial Dari Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalAkhirTrialStart}
                            onChange={handleChangeTanggalAkhirTrialStart}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Sampai Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalAkhirTrialEnd}
                            onChange={handleChangeTanggalAkhirTrialEnd}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Klinik</Typography>
                        <PInput
                            name="namaKlinik"
                            value={filterData.namaKlinik}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <Typography className={classes.label}>PIC Klinik</Typography>*/}
                    {/*    <PInput*/}
                    {/*        name="picKlinik"*/}
                    {/*        value={filterData.picKlinik}*/}
                    {/*        onChange={(e)=> handleChangeFilter(e)}*/}
                    {/*        fullWidth*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <Typography className={classes.label}>PIC Assist.id</Typography>*/}
                    {/*    <PInput*/}
                    {/*        name="picAssist"*/}
                    {/*        value={filterData.picAssist}*/}
                    {/*        onChange={(e)=> handleChangeFilter(e)}*/}
                    {/*        select*/}
                    {/*        fullWidth*/}
                    {/*    >*/}
                    {/*        {*/}
                    {/*            panelaUserList.map(user => (*/}
                    {/*                <MenuItem key={user.id} value={`${user.firstName} ${user.lastName}`}>*/}
                    {/*                    {`${user.firstName} ${user.lastName}`}*/}
                    {/*                </MenuItem>*/}
                    {/*            ))*/}
                    {/*        }*/}
                    {/*    </PInput>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <Typography className={classes.label}>Status</Typography>*/}
                    {/*    <PInput*/}
                    {/*        name="statusTrial"*/}
                    {/*        value={filterData.statusTrial}*/}
                    {/*        onChange={(e)=> handleChangeFilter(e)}*/}
                    {/*        select*/}
                    {/*        fullWidth*/}
                    {/*    >*/}
                    {/*        <MenuItem value={true}>Enabled</MenuItem>*/}
                    {/*        <MenuItem value={false}>Disabled</MenuItem>*/}
                    {/*    </PInput>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={clearFilter}
                >
                    Reset
                </PBtn>
                <PBtn
                    onClick={startFilter}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default TrialFilter;
