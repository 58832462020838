import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import format from "date-fns/format";
import localeId from "date-fns/locale/id";
import NumberFormat from "react-number-format";

import logo from "./../../../assets/img/logo-main.png";

const styles = makeStyles({
    logo: {
        width: '100%',
    },
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
    paper: {
        textAlign: 'center',
        paddingTop: 7,
        paddingBottom: 7,
    },
    stempel: {
        width: '25%',
    },
});

const BuktiBayarPrintContent = ({ selectedReceiptsList, terminList, idxTermin, pembayaran }) => {
    const classes = styles();
    const isTermin = terminList.length > 1 && idxTermin !== null;
    const isLastTermin = (idxTermin+1) === terminList.length;

    return (
        <Grid container id="pdf-container" style={{ paddingLeft: 56, paddingRight: 56 }}>
            <Grid item xs={12} style={{ borderBottom: '2px solid black' }}>
                <Grid container style={{ paddingBottom: 10 }}>
                    <Grid item xs={4}>
                        <img src={logo} alt="Logo Assist.id" className={classes.logo}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography style={{ fontSize: 17, textAlign: 'right' }}>
                            <strong>BUKTI PEMBELIAN (RECEIPT)</strong>
                        </Typography>
                        <Typography style={{ fontSize: 17, textAlign: 'right' }}>
                            {`Invoice: ${selectedReceiptsList.KConfig.activeBilling.Billing.invoiceNo}`}
                        </Typography>
                        <Typography style={{ fontSize: 17, textAlign: 'right' }}>
                            {`Tanggal: ${format(new Date(), "dd MMMM yyyy, H:mm", {locale: localeId})} WIB`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 15 }}>
                <Grid container>
                    <Grid item xs={7}>
                        <Typography>{selectedReceiptsList.nama}</Typography>
                        <Typography>{selectedReceiptsList.alamat.jalan}</Typography>
                        <Typography>{`${selectedReceiptsList.alamat.district}, ${selectedReceiptsList.alamat.city}, ${selectedReceiptsList.alamat.region}`}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography><strong>Detail Pembayaran</strong></Typography>
                        <Typography style={{ display: 'flex' }}>
                            <span style={{ display: 'inline-block', width: 100 }}>Metode</span>
                            <span style={{ marginRight: 8 }}>:</span>
                            <span style={{ flex: 1 }}>{selectedReceiptsList.KConfig.activeBilling.Billing.payment.paymentMethodType}</span>
                        </Typography>
                        <Typography style={{ display: 'flex' }}>
                            <span style={{ display: 'inline-block', width: 100 }}>Pembayaran</span>
                            <span style={{ marginRight: 8 }}>:</span>
                            {
                                isLastTermin
                                    ? <span style={{ flex: 1, color: 'green', fontWeight: 'bold' }}>LUNAS</span>
                                    : <span style={{ flex: 1, color: 'red', fontWeight: 'bold' }}>BELUM LUNAS</span>
                            }

                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 10 }}>
                <Grid container>
                    <div>
                        <Typography style={{ display: 'flex' }}>
                            <span style={{ display: 'inline-block', width: 100 }}>Nama</span>
                            <span style={{ marginRight: 8 }}>:</span>
                            <span style={{ flex: 1 }}>{selectedReceiptsList.KConfig.activeBilling.Billing.createdName}</span>
                        </Typography>
                        <Typography style={{ display: 'flex' }}>
                            <span style={{ display: 'inline-block', width: 100 }}>Email</span>
                            <span style={{ marginRight: 8 }}>:</span>
                            <span style={{ flex: 1 }}>{selectedReceiptsList.KConfig.activeBilling.Billing.createdEmail}</span>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 32 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader}>Produk</TableCell>
                            <TableCell className={classes.tableCellHeader}>Lama Berlangganan</TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ textAlign: 'right' }}>Harga (Rp)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.tableCellBody}>
                                {selectedReceiptsList.KConfig.activeBilling.mainProductName}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {`${selectedReceiptsList.KConfig.activeBilling.Billing.duration} Bulan`}
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <NumberFormat
                                    displayType='text'
                                    decimalSeparator=','
                                    thousandSeparator='.'
                                    prefix='Rp. '
                                    value={selectedReceiptsList.KConfig.activeBilling.Billing.totalFee}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <strong>Total</strong>
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <NumberFormat
                                    displayType='text'
                                    decimalSeparator=','
                                    thousandSeparator='.'
                                    prefix='Rp. '
                                    value={selectedReceiptsList.KConfig.activeBilling.Billing.totalFee}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                {
                                    isTermin
                                        ? <strong>{`Pembayaran Termin ${idxTermin+1}`}</strong>
                                        : <strong>Pembayaran</strong>
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <NumberFormat
                                    displayType='text'
                                    decimalSeparator=','
                                    thousandSeparator='.'
                                    prefix='Rp. '
                                    value={pembayaran.length > 0 ? pembayaran[0].value : 0}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <strong>Sisa Pembayaran</strong>
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <NumberFormat
                                    displayType='text'
                                    decimalSeparator=','
                                    thousandSeparator='.'
                                    prefix='Rp. '
                                    value={
                                        pembayaran.length > 0
                                            ? selectedReceiptsList.KConfig.activeBilling.Billing.totalFee - parseInt(pembayaran[0].value, 10)
                                            : 0
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 15 }}>
                <Grid container justify="center">
                    <Typography>Informasi Cicilan</Typography>
                    {
                        terminList.length > 0
                            ? terminList.map((item, idx) => (
                                <Grid container justify="center" key={idx}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <Typography>{`${format(new Date(item.dueDatePayment), "dd/MM/yyyy")} -`}&nbsp;</Typography>
                                        <NumberFormat
                                            displayType='text'
                                            decimalSeparator=','
                                            thousandSeparator='.'
                                            prefix='Rp. '
                                            value={item.value}
                                        />
                                    </div>
                                </Grid>
                            ))
                            : ''
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 20 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography style={{ paddingTop: 15 }}>
                            Demikian detail biaya yang kami sampaikan, Terimakasih atas perhatiannya.
                        </Typography>
                        <Typography style={{ paddingTop: 30 }}>
                            <span>Best Regards,</span>
                        </Typography>
                        <Typography>
                            Assist.id
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="flex-end">
                    <img src="https://s3-ap-southeast-1.amazonaws.com/public-medicaboo/Paid-receipt-stempel.png" alt="Stempel Assist.id" className={classes.stempel}/>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 25 }}>
                <Paper className={classes.paper} elevation={0}>
                    <Typography>Silahkan hubungi kami untuk informasi lebih lanjut melalui email atikah@assist.id</Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapState = ({ client }) => ({
    selectedReceiptsList: client.selectedReceiptsList,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(BuktiBayarPrintContent);
