import agent from './agent';
import format from 'date-fns/format';

export const getProspect = (skipLimit) => {
    let params;
    if(skipLimit){
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 6,
        };
    }
    return agent.get('Prospects/getProspects', {
        params,
    });
};

export const countProspect = () => {
    return agent.get('Prospects/count');
};

export const addProspect = (payload) => {
    return agent.post('Prospects', payload);
};

export const updateProspect = (prospectId, payload) => {
    return agent.patch(`Prospects/${prospectId}`, payload);
};

export const getProspectByFilter = (payload) => {
    const params = {
        tanggalMulai: payload.tanggalMulai === null ? '' : format(new Date(payload.tanggalMulai), 'yyyy-MM-dd'),
        tanggalSelesai: payload.tanggalSelesai === null ? '' : format(new Date(payload.tanggalSelesai), 'yyyy-MM-dd'),
        order: payload.order,
        namaFaskes: payload.faskesName,
        namaPIC: payload.PICFaskes,
        jabatan: payload.position,
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Prospects/getProspects', {
        params,
    });
};

export const countProspectByFilter = (payload) => {
    const params = {
        tanggalMulai: payload.tanggalMulai === null ? '' : format(new Date(payload.tanggalMulai), 'yyyy-MM-dd'),
        tanggalSelesai: payload.tanggalSelesai === null ? '' : format(new Date(payload.tanggalSelesai), 'yyyy-MM-dd'),
        order: payload.order,
        namaFaskes: payload.faskesName,
        namaPIC: payload.PICFaskes,
        jabatan: payload.position,
    };

    return agent.get('Prospects/countProspects', {
        params,
    });
};

export const getReferralList = (payload) => {
    let params;
    if(payload) {
        params = {
            tanggal: payload.tanggal === null ? '' : format(new Date(payload.tanggal), 'yyyy-MM-dd'),
            namaMereferral: payload.namaMereferral,
            instansiMereferral: payload.instansiMereferral,
            namaDireferral: payload.namaDireferral,
            instansiDireferral: payload.instansiDireferral,
            skip: payload.skip,
            limit: payload.limit,
        };

        if(payload.omnisearch) {
            params.omnisearch = payload.omnisearch;
        }
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }

    return agent.get('PReferrals/getReferralList', {
        params,
    });
};

export const countReferralList = (payload) => {
    let params;
    if(payload) {
        params = {
            tanggal: payload.tanggal === null ? '' : format(new Date(payload.tanggal), 'yyyy-MM-dd'),
            namaMereferral: payload.namaMereferral,
            instansiMereferral: payload.instansiMereferral,
            namaDireferral: payload.namaDireferral,
            instansiDireferral: payload.instansiDireferral,
        };

        if(payload.omnisearch) {
            params.omnisearch = payload.omnisearch;
        }
    }

    return agent.get('PReferrals/countReferralList', {
        params,
    });
};

export const addReferral = (data) => {
    return agent.post('PReferrals', data);
};

export const updateReferral = (referralId, data) => {
    return agent.patch(`PReferrals/${referralId}`, data);
};
