import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Chip from "@material-ui/core/Chip";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import filter from './../../../assets/img/filter-icon.png';
import arrowDown from './../../../assets/img/arrow-down-icon.png';

import PInput from "../../share/PInput";

import KlinikTable from "./KlinikTable";
import KlinikFilter from "./KlinikFilter";

import debounce from "lodash/debounce";

const styles = makeStyles({
    search: {
        flex: 0.9,
    },
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
    filter: {
        float: 'right',
        display: 'inline-flex',
        padding: '15px 5px',
        marginTop: '-8px',
    },
    labelFilter: {
        fontWeight: 'bold',
        fontSize: '16px',
        fontFamily: 'Nunito',
        marginTop: '3px',
        marginRight: '10px',
    },
    imgFilter: {
        height: '40%',
        marginRight: '15px',
    },
    arrowFilter: {
        height: '40%',
        marginTop: '10px',
        cursor: 'pointer',
    },
    chip: {
        marginRight: 10,
    },
});

const Klinik = ({ fetchKlinikListFilter, selectedKlinikFilter, updateSelectedKlinikFilter }) => {
    const classes = styles();
    const [isFilter, setIsFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        tanggalTerdaftarMulai: null,
        tanggalTerdaftarSelesai: null,
        namaKlinik: '',
        namaProduk: '',
        isActive: '',
        isSubscriber: 'Seluruhnya',
        isTrial: 'Semua Akun',
        isOnlineAppointment: 'Seluruhnya',
        skip: 0,
        limit: 5,
    });
    const [isOpenFilterForm, setIsOpenFilterForm] = useState(false);
    const [search, setSearch] = useState('');

    const handleFilter = () => {
        setIsFilter(true);
        fetchKlinikListFilter(filterData);
        setIsOpenFilterForm(false);
        updateSelectedKlinikFilter(JSON.parse(JSON.stringify(filterData)));
    };

    const handleClearFilter = () => {
        setFilterData({
            tanggalTerdaftarMulai: null,
            tanggalTerdaftarSelesai: null,
            namaKlinik: '',
            namaProduk: '',
            isActive: '',
            isSubscriber: 'Seluruhnya',
            isTrial: 'Semua Akun',
            isOnlineAppointment: 'Seluruhnya',
            skip: 0,
            limit: 5,
        });
        setIsFilter(false);
        updateSelectedKlinikFilter({});
    };

    const getSuggestion = debounce(async (value) => {
        filterData.namaKlinik = value;
        setFilterData({...filterData, namaKlinik: filterData.namaKlinik});
        setIsFilter(true);
        await fetchKlinikListFilter(filterData);
    }, 500);

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        if(value.length > 2) {
            setSearch(value);
            getSuggestion(value);
        } else if(value.length === 0) {
            setSearch(value);
            setIsFilter(false);
        } else {
            setSearch(value);
        }
    };

    const handleChangeFilterIsTrial = (e) => {
        setIsFilter(true);
        filterData.isTrial = e.target.value;
        setFilterData(prevState => ({
            ...prevState, isTrial: filterData.isTrial,
        }));
        fetchKlinikListFilter(filterData);
    };

    const handleChangeFilterIsSubscriber = (e) => {
        setIsFilter(true);
        filterData.isSubscriber = e.target.value;
        setFilterData({...filterData, isSubscriber: filterData.isSubscriber});
        fetchKlinikListFilter(filterData);
    };

    const handleChangeFilterIsOnlineAppointment = (e) => {
        setIsFilter(true);
        filterData.isOnlineAppointment = e.target.value;
        setFilterData({...filterData, isOnlineAppointment: filterData.isOnlineAppointment});
        fetchKlinikListFilter(filterData);
    };

    return (
        <Grid container>
            <Grid container spacing={1} justify="flex-end" item xs={12} style={{ marginTop: '44px' }}>
                <Grid item xs={6}>
                    <div className={classes.filter}>
                        <img alt='filter' src={filter} className={classes.imgFilter}/>
                        <Typography className={classes.labelFilter}>Filter by</Typography>
                        <img
                            alt='arrow'
                            src={arrowDown}
                            className={classes.arrowFilter}
                            onClick={()=> setIsOpenFilterForm(true)}
                        />
                        <Dialog open={isOpenFilterForm} maxWidth="xs" onClose={()=> setIsOpenFilterForm(false)} fullWidth>
                            <DialogContent>
                                <KlinikFilter
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    startFilter={handleFilter}
                                    clearFilter={handleClearFilter}
                                />
                            </DialogContent>
                        </Dialog>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={1} item xs={12} style={{ marginTop: 10 }}>
                <Grid item xs={3}>
                    <Typography className={classes.label} style={{ visibility: 'hidden' }}>Cari Klinik</Typography>
                    <PInput
                        placeholder="Cari klinik disini"
                        value={search}
                        onChange={handleChangeSearch}
                        className={classes.search}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Berlangganan</Typography>
                    <PInput
                        name="isSubscriber"
                        value={filterData.isSubscriber}
                        onChange={handleChangeFilterIsSubscriber}
                        select
                        fullWidth
                    >
                        <MenuItem value="Seluruhnya">Seluruhnya</MenuItem>
                        <MenuItem value="Berlangganan">Berlangganan</MenuItem>
                        <MenuItem value="Tidak Berlangganan">Tidak Berlangganan</MenuItem>
                        <MenuItem value="Belum Berlangganan">Belum Berlangganan</MenuItem>
                        <MenuItem value="Produk Gratis">Produk Gratis</MenuItem>
                    </PInput>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Akun Trial</Typography>
                    <PInput
                        name="isTrial"
                        value={filterData.isTrial}
                        onChange={handleChangeFilterIsTrial}
                        select
                        fullWidth
                    >
                        <MenuItem value="Semua Akun">Semua Akun</MenuItem>
                        <MenuItem value="Trial">Trial</MenuItem>
                        <MenuItem value="Tidak Trial">Tidak Trial</MenuItem>
                    </PInput>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Online Appointment</Typography>
                    <PInput
                        name="isOnlineAppointment"
                        value={filterData.isOnlineAppointment}
                        onChange={handleChangeFilterIsOnlineAppointment}
                        select
                        fullWidth
                    >
                        <MenuItem value="Seluruhnya">Seluruhnya</MenuItem>
                        <MenuItem value="Aktif">Aktif</MenuItem>
                        <MenuItem value="Tidak Aktif">Tidak Aktif</MenuItem>
                    </PInput>
                </Grid>
            </Grid>
            {
                isFilter && <Grid item xs={12} style={{ marginTop: 30, dispaly: 'flex' }}>
                    {
                        selectedKlinikFilter.tanggalTerdaftarMulai && selectedKlinikFilter.tanggalTerdaftarMulai !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Tanggal Mulai"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedKlinikFilter.tanggalTerdaftarSelesai && selectedKlinikFilter.tanggalTerdaftarSelesai !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Tanggal Selesai"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedKlinikFilter.namaKlinik && selectedKlinikFilter.namaKlinik !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Nama Klinik"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedKlinikFilter.namaProduk && selectedKlinikFilter.namaProduk !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Nama Produk"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedKlinikFilter.isActive && selectedKlinikFilter.isActive !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Akun Aktif"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                </Grid>
            }
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <KlinikTable
                    isFilter={isFilter}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </Grid>
        </Grid>
    );
};

const mapState = ({ client }) => ({
    selectedKlinikFilter: client.selectedKlinikFilter,
});

const mapDispatch = ({ client: { fetchKlinikListFilter, updateSelectedKlinikFilter } }) => ({
    fetchKlinikListFilter,
    updateSelectedKlinikFilter,
});

export default connect(mapState, mapDispatch)(Klinik);



