import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";

import {DatePicker} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
});

const KlinikFilter = ({ filterData, setFilterData, startFilter, clearFilter }) => {
    const classes = styles();

    const handleChangeStartDate = (date) => {
        filterData.tanggalTerdaftarMulai = date;
        setFilterData(prevState => ({
            ...prevState, tanggalTerdaftarMulai: filterData.tanggalTerdaftarMulai,
        }));
    };

    const handleChangeEndDate = (date) => {
        filterData.tanggalTerdaftarSelesai = date;
        setFilterData(prevState => ({
            ...prevState, tanggalTerdaftarSelesai: filterData.tanggalTerdaftarSelesai,
        }));
    };

    function handleChangeFilter(e) {
        setFilterData({...filterData, [e.target.name]: e.target.value});
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Dari Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalTerdaftarMulai}
                            onChange={handleChangeStartDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Sampai Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalTerdaftarSelesai}
                            onChange={handleChangeEndDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Klinik</Typography>
                        <PInput
                            name={"namaKlinik"}
                            value={filterData.namaKlinik}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Produk</Typography>
                        <PInput
                            name={"namaProduk"}
                            value={filterData.namaProduk}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Akun Aktif</Typography>
                        <PInput
                            name={"isActive"}
                            value={filterData.isActive}
                            onChange={(e)=> handleChangeFilter(e)}
                            select
                            fullWidth
                        >
                            <MenuItem value={0}>Tidak</MenuItem>
                            <MenuItem value={1}>Iya</MenuItem>
                        </PInput>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={clearFilter}
                >
                    Reset
                </PBtn>
                <PBtn
                    onClick={startFilter}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default KlinikFilter;
