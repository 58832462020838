import React from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";

import KlinikFreeTable from "./KlinikFreeTable";

const styles = makeStyles({
    search: {
        flex: 0.9,
    },
});

const KlinikFree = () => {
    const classes = styles();

    return (
        <Grid container>
            <Grid container item xs={12} style={{ marginTop: '44px' }}>
                <Grid item xs={4}>
                    <PInput
                        placeholder="Cari klinik disini"
                        className={classes.search}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <KlinikFreeTable/>
            </Grid>
        </Grid>
    );
};

export default KlinikFree;
