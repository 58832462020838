import agent from "./agent";

export const addNewEventName = (name, userId) => {
    const payload = {
        type: 'eventName',
        name: name,
        createdAt: new Date(),
        createdId: userId,
    };

    return agent.post('Dictionaries', payload);
};

export const getEventNames = () => {
    const filter = {
        where: {
            type: 'eventName',
        },
    };

    return agent.get('Dictionaries', {
        params: {
            filter,
        },
    })
};
