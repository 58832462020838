import React, {useState, useEffect, Fragment} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    CircularProgress,
    IconButton,
} from "@material-ui/core";

import {
    faClock,
    faLock,
    faSortAmountDown,
    faSortAmountUp,
    faGrinStars,
    faGrin,
    faFrownOpen,
} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from '@material-ui/core/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {connect} from 'react-redux';

import history from "../../../history";
import format from "date-fns/format";
import localeId from "date-fns/locale/id";

import './tableLeads.css'

const style = makeStyles({
    tableWrapper: {
        overflow: 'auto',
    },
    iconTrial: {
        color: '#e88500',
        transition: '.3s',
    },
    iconBlock: {
        color: 'rgb(167, 64, 64)',
        transition: '.3s',
    },
    sortImg: {
        marginLeft: 7,
        opacity: 0.1,
        '&:hover': {
            opacity: 1,
            cursor: 'pointer',
        },
    },
    iconInterested: {
        color: '#008000',
    },
    iconNormal: {
        color: '#FFA500',
    },
    iconUninterested: {
        color: '#FF4500',
    },
});

function TableLeads({ filterData, setFilterData, isFilter, faskesList, isFetchingFaskesList, fetchFaskesList, fetchFaskesListFilter, faskesListCounter, setIsFilter }) {
    const classes = style();
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(6);
    const [sortType, setSortType] = useState('ASC');
    const hotnessLevel = [
        {
            icon: faGrinStars,
            label: 'Interested',
            style: classes.iconInterested,
        },
        {
            icon: faGrin,
            label: 'Normal',
            style: classes.iconNormal,
        },
        {
            icon: faFrownOpen,
            label: 'Uninterested',
            style: classes.iconUninterested,
        },
    ];

    useEffect(()=> {
        if(isFilter === true) {
            setPage(0);
            handleRenderFaskesListFilter();
        } else {
            setPage(0);
            setRowsPerPage(6);
            fetchFaskesList();
        }
    }, [isFilter, fetchFaskesList]);

    const handleRenderFaskesListFilter = () => {
        setFilterData(prevState => ({
            ...prevState, condition: false,
        }));
        fetchFaskesListFilter(filterData);
    };

    // useEffect(()=>{
    //     let unmounted = false;
    //     function searchData() {
    //         if(props.search !== ""){
    //             const newArr = [];
    //             list.map(data => {
    //                 if(data.name.toUpperCase().includes(props.search.toUpperCase()) ||
    //                     data.address.city.toUpperCase().includes(props.search.toUpperCase()) ||
    //                     data.type.toUpperCase().includes(props.search.toUpperCase())){
    //                     newArr.push(data);
    //                 }
    //                 return newArr;
    //             });
    //             if(newArr){
    //                 setList(newArr);
    //             }
    //         }
    //     }
    //
    //     if(sortBy === ""){
    //         if(isFilter === false){
    //             setList(props.list);
    //             if(!unmounted) searchData();
    //         } else{
    //             setList(listNew);
    //             if(!unmounted) searchData();
    //         }
    //     }
    //
    //     if(isFilter === true && sortBy !== ""){
    //         setList(listNew);
    //         if(!unmounted) searchData();
    //     }
    //
    //     return () => {
    //         unmounted = true;
    //     }
    //
    // }, [props, list, sortBy, isFilter, listNew]);

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        if(isFilter === true) {
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchFaskesListFilter(filterData);
        } else {
            fetchFaskesList(skipLimit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        const newRowsPerpage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerpage,
        };
        if(isFilter === true) {
            filterData.skip = 0;
            filterData.limit = newRowsPerpage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchFaskesListFilter(filterData);
        } else {
            fetchFaskesList(skipLimit);
        }
        setRowsPerPage(newRowsPerpage);
        setPage(0);
    };

    function OnClickFaskes(event, obj) {
        const { type } = event;

        if(type === 'click') {
            history.push('leads/detail/'+obj);
        } else if(type === 'contextmenu') {
            event.preventDefault();
            window.open(`leads/detail/${obj}`, '_blank');
        }
    }

    function handleSort(type, column) {
        setSortType(type);
        setIsFilter(true);
        filterData.order = column;
        setFilterData(prevState => ({
            ...prevState, order: filterData.order,
        }));
        fetchFaskesListFilter(filterData);
    }

    return(
        <div className="table-leads">
            <div className={classes.tableWrapper}>
                <Table aria-label="sticky label">
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-cell-header" style={{ minWidth: 150 }}>
                                Nama Klinik
                                <FontAwesomeIcon
                                    icon={sortType === "ASC" ? faSortAmountUp : faSortAmountDown}
                                    className={classes.sortImg}
                                    onClick={sortType === "ASC"
                                        ? ()=> handleSort("DESC", "name DESC")
                                        : ()=> handleSort("ASC", "name ASC")}
                                />
                            </TableCell>
                            <TableCell className="table-cell-header" style={{ minWidth: 150 }}>
                                Kabupaten/Kota
                                <FontAwesomeIcon
                                    icon={sortType === "ASC" ? faSortAmountUp : faSortAmountDown}
                                    className={classes.sortImg}
                                    onClick={sortType === "ASC"
                                        ? ()=> handleSort("DESC", "address.city DESC")
                                        : ()=> handleSort("ASC", "address.city ASC")}
                                />
                            </TableCell>
                            <TableCell className="table-cell-header" style={{ minWidth: 150 }}>
                                Jenis Faskes
                                <FontAwesomeIcon
                                    icon={sortType === "ASC" ? faSortAmountUp : faSortAmountDown}
                                    className={classes.sortImg}
                                    onClick={sortType === "ASC"
                                        ? ()=> handleSort("DESC", "type DESC")
                                        : ()=> handleSort("ASC", "type ASC")}
                                />
                            </TableCell>
                            <TableCell className="table-cell-header" style={{ minWidth: 100 }}>
                                PIC Klinik
                            </TableCell>
                            <TableCell className="table-cell-header">
                                Terakhir Dihubungi
                            </TableCell>
                            <TableCell className="table-cell-header" style={{ minWidth: 150 }}>
                                Status Terakhir
                                <FontAwesomeIcon
                                    icon={sortType === "ASC" ? faSortAmountUp : faSortAmountDown}
                                    className={classes.sortImg}
                                    onClick={sortType === "ASC"
                                        ? ()=> handleSort("DESC", "status DESC")
                                        : ()=> handleSort("ASC", "status ASC")}
                                />
                            </TableCell>
                            <TableCell className="table-cell-header">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isFetchingFaskesList === true
                                ? <TableRow>
                                    <TableCell colSpan={6}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <CircularProgress />
                                        </div>
                                    </TableCell>
                                </TableRow>
                                : faskesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                                    <TableRow className="table-row-body" key={index} onClick={(e) => OnClickFaskes(e, data.id)} onContextMenu={(e) => OnClickFaskes(e, data.id)}>
                                        <TableCell className="table-cell-body">{`${data.name} `}
                                            {
                                                data.isTrial === true ?
                                                    (<Fragment>
                                                        <Tooltip title={"Trial"}>
                                                            <span><FontAwesomeIcon icon={faClock} className={classes.iconTrial}/></span>
                                                        </Tooltip>
                                                    </Fragment>)
                                                    : ''
                                            }
                                            {' '}
                                            {
                                                data.isBlocked === true ?
                                                    (<Fragment>
                                                        <Tooltip title={"Blocked"}>
                                                            <span><FontAwesomeIcon icon={faLock} className={classes.iconBlock}/></span>
                                                        </Tooltip>
                                                    </Fragment>)
                                                    : ''
                                            }
                                        </TableCell>
                                        <TableCell className="table-cell-body">{data.address.city}</TableCell>
                                        <TableCell className="table-cell-body">{data.type}</TableCell>
                                        <TableCell className="table-cell-body">{data.PIC[0].namepic}</TableCell>
                                        {/*<TableCell className="table-cell-body">{data.status}</TableCell>*/}
                                        <TableCell className="table-cell-body">
                                            {
                                                typeof data.lastContactDate !== 'undefined'
                                                    ? format(new Date(data.lastContactDate), "dd/MM/yyyy", {locale: localeId})
                                                    : '-'
                                            }
                                        </TableCell>
                                        <TableCell className="table-cell-body">{data.status}</TableCell>
                                        <TableCell className="table-cell-body">
                                            {
                                                typeof data.hotnessLevel !== 'undefined'
                                                    ? data.hotnessLevel !== ''
                                                    ? hotnessLevel.filter(item => item.label === data.hotnessLevel).map(($val, idx) => (
                                                        <Tooltip title={$val.label} key={idx}>
                                                            <IconButton onClick={(e)=> e.stopPropagation()}>
                                                                <FontAwesomeIcon icon={$val.icon} className={$val.style}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    ))
                                                    : ''
                                                    : ''
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[6, 10, 25, 100]}
                component="div"
                count={faskesListCounter}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage} />
        </div>
    )
}

const mapState = ({ leads }) => ({
    faskesList: leads.faskesList,
    isFetchingFaskesList: leads.isFetchingFaskesList,
    faskesListCounter: leads.faskesListCounter,
});

const mapDispatch = ({ leads: { fetchFaskesList, fetchFaskesListFilter} }) => ({
    fetchFaskesList,
    fetchFaskesListFilter,
});

export default connect(mapState, mapDispatch)(TableLeads);
