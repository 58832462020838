import {getFreezes, countFreeze, getFreezesByFilter, countFreezesListByFilter} from "../../api/freeze";

export const freeze = {
    state: {
        isFetchingFreezeList: false,
        freezeList: [],
        freezeListCounter: 0,
        selectedFreeze: {},
        selectedFreezeFilter: {},
    },
    reducers: {
        updateState(state, payload){
            const { statName, value } = payload;
            return {
                ...state,
                [statName]: value,
            };
        },
        updateFreezeList(state, payload){
            const { value, skipLimit } = payload;

            if(!skipLimit){
                return {
                    ...state,
                    freezeList: value,
                };
            }

            const { freezeList } = state;
            freezeList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                freezeList: [...freezeList],
            };
        },
        updateSelectedFreeze(state, payload){
            return {
                ...state,
                selectedFreeze: payload,
            };
        },
        updateSelectedFreezeFilter(state, payload){
            return {
                ...state,
                selectedFreezeFilter: payload,
            };
        },
        updateFreezeListFilter(state, payload){
            const { value, payloads } = payload;

            if(payloads.condition) {
                return {
                    ...state,
                    freezeList: value,
                };
            }

            const { freezeList } = state;
            freezeList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                freezeList: [...freezeList],
            };
        },
    },
    effects: dispatch => ({
        async fetchFreeze(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingFreezeList',
                    value: true,
                });

                const result = await getFreezes(skipLimit);
                const resultCounter = await countFreeze();

                this.updateFreezeList({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'isFetchingFreezeList',
                    value: false,
                });

                this.updateState({
                    statName: 'freezeListCounter',
                    value: resultCounter.data.count,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar freeze");
                this.updateState({
                    statName: 'isFetchingFreezeList',
                    value: false,
                });
            }
        },
        async fetchFreezeFilter(payloads) {
            try {
                this.updateState({
                    statName: 'isFetchingFreezeList',
                    value: true,
                });

                const result = await getFreezesByFilter(payloads);
                const resultCounter = await countFreezesListByFilter(payloads);

                this.updateFreezeListFilter({
                    value: result.data,
                    payloads,
                });

                this.updateState({
                    statName: 'freezeListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingFreezeList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal filter data freeze");
                this.updateState({
                    statName: 'isFetchingFreezeList',
                    value: false,
                });
            }
        },
    }),
};
