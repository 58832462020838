import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import {makeStyles} from "@material-ui/core";

import {getProdukPalingDiminati, getTopReasonRejection} from "../../api/dashboard";

const styles = makeStyles({
    wrapBestSellingProduct: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        minHeight: 400,
        maxHeight: 400,
    },
    wrapReasonRejection: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        minHeight: 270,
        maxHeight: 270,
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#15162d',
    },
    borderTitle: {
        width: 40,
        float: 'left',
        background: '#2b64af',
        border: '2px solid #2b64af',
    },
    tableWrapperBestSellingProduct: {
        overflowY: 'auto',
        height: 360,
        width: '100%',
    },
    tableWrapperReasonRejection: {
        overflowY: 'auto',
        height: 230,
        width: '100%',
    },
    tableCellBody: {
        paddingLeft: 0,
        fontFamily: 'Nunito',
        fontSize: '16px',
        borderBottom: 'none',
    },
    labelLeft: {
        color: '#6e7d91',
        fontWeight: 'bold',
    },
    labelRight: {
        color: 'black',
        fontWeight: 'bold',
    },
});

const BestSellingProductAndReasonRejection = () => {
    const classes = styles();
    const [produkPalingDiminati, setProdukPalingDiminati] = useState([]);
    const [topReasonRejection, setTopReasonRejection] = useState([]);

    useEffect(() => {
        (async function () {
            const resultProdukPalingDiminati = await getProdukPalingDiminati();
            setProdukPalingDiminati(resultProdukPalingDiminati.data);
            const resultTopReasonRejection = await getTopReasonRejection();
            setTopReasonRejection(resultTopReasonRejection.data);
        })();
    }, []);

    return (
        <Grid item xs={4}>
            <div className={classes.wrapBestSellingProduct} style={{ marginBottom: 10 }}>
                <Typography className={classes.title}>Produk Terlaris - Landing Page</Typography>
                <hr className={classes.borderTitle}/>
                <div className={classes.tableWrapperBestSellingProduct}>
                    <Table>
                        <TableBody>
                            {
                                produkPalingDiminati.length === 0
                                    ? <TableRow>
                                        <TableCell colSpan={2} className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                            <Typography className={classes.labelLeft}>Tidak Ada</Typography>
                                        </TableCell>
                                    </TableRow>
                                    : produkPalingDiminati.map((item, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell className={classes.tableCellBody}>
                                                <Typography className={classes.labelLeft}>{item._id}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                                <Typography className={classes.labelRight}>{item.count}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div className={classes.wrapReasonRejection}>
                <Typography className={classes.title}>Top 3 Alasan Penolakan</Typography>
                <hr className={classes.borderTitle}/>
                <div className={classes.tableWrapperReasonRejection}>
                    <Table>
                        <TableBody>
                            {
                                topReasonRejection.length === 0
                                    ? <TableRow>
                                        <TableCell colSpan={2} className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                            <Typography className={classes.labelLeft}>Tidak Ada</Typography>
                                        </TableCell>
                                    </TableRow>
                                    : topReasonRejection.map((item, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell className={classes.tableCellBody}>
                                                <Typography className={classes.labelLeft}>{item._id}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                                <Typography className={classes.labelRight}>{item.count}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                </div>
            </div>
        </Grid>
    );
};

export default BestSellingProductAndReasonRejection;
