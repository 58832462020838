import agent from "./agent";
import format from "date-fns/format";

export const getAnomaliPenggunaan = (payload) => {
    const params = {
        tanggalMulai: payload.tanggalMulai === null ? '' : format(new Date(payload.tanggalMulai), "yyyy-MM-dd"),
        tanggalSelesai: payload.tanggalSelesai === null ? '' : format(new Date(payload.tanggalSelesai), "yyyy-MM-dd"),
        skip: payload.skip,
        limit: payload.limit,
    }

    return agent.get('Laporans/anomaliPenggunaan', {
        params,
    });
};

export const countAnomaliPenggunaan = (payload) => {
    const params = {
        tanggalMulai: payload.tanggalMulai === null ? '' : format(new Date(payload.tanggalMulai), "yyyy-MM-dd"),
        tanggalSelesai: payload.tanggalSelesai === null ? '' : format(new Date(payload.tanggalSelesai), "yyyy-MM-dd"),
    };

    return agent.get('Laporans/countAnomaliPenggunaan', {
        params,
    });
};

export const getRetensionFreeProduct = (payload) => {
    const params = {
        dateStart: payload.dateStart === null ? '' : format(new Date(payload.dateStart), "yyyy-MM-dd"),
        dateEnd: payload.dateEnd === null ? '' : format(new Date(payload.dateEnd), "yyyy-MM-dd"),
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Laporans/laporanRetentionPenggunaanFree', {
        params,
    });
};

export const countRetensionFreeProduct = (payload) => {
    const params = {
        dateStart: payload.dateStart === null ? '' : format(new Date(payload.dateStart), "yyyy-MM-dd"),
        dateEnd: payload.dateEnd === null ? '' : format(new Date(payload.dateEnd), "yyyy-MM-dd"),
    };

    return agent.get('Laporans/countLaporanRetentionPenggunaanFree', {
        params,
    });
};

export const getRetensionPaidProduct = (payload) => {
    const params = {
        dateStart: payload.dateStart === null ? '' : format(new Date(payload.dateStart), "yyyy-MM-dd"),
        dateEnd: payload.dateEnd === null ? '' : format(new Date(payload.dateEnd), "yyyy-MM-dd"),
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Laporans/laporanRetentionPenggunaanBerbayar', {
        params,
    });
};

export const countRetensionPaidProduct = (payload) => {
    const params = {
        dateStart: payload.dateStart === null ? '' : format(new Date(payload.dateStart), "yyyy-MM-dd"),
        dateEnd: payload.dateEnd === null ? '' : format(new Date(payload.dateEnd), "yyyy-MM-dd"),
    };

    return agent.get('Laporans/countLaporanRetentionPenggunaanBerbayar', {
        params,
    });
};

export const getRetensionTrialProduct = (payload) => {
    const params = {
        dateStart: payload.dateStart === null ? '' : format(new Date(payload.dateStart), "yyyy-MM-dd"),
        dateEnd: payload.dateEnd === null ? '' : format(new Date(payload.dateEnd), "yyyy-MM-dd"),
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Laporans/laporanRetentionPenggunaanTrial', {
        params,
    });
};

export const countRetensionTrialProduct = (payload) => {
    const params = {
        dateStart: payload.dateStart === null ? '' : format(new Date(payload.dateStart), "yyyy-MM-dd"),
        dateEnd: payload.dateEnd === null ? '' : format(new Date(payload.dateEnd), "yyyy-MM-dd"),
    };

    return agent.get('Laporans/countLaporanRetentionPenggunaanTrial', {
        params,
    });
};

export const getAcquisition = (payload, key) => {
    const params = {
        tanggalMulai: payload.tanggalMulai === null ? '' : format(new Date(payload.tanggalMulai), "yyyy-MM-dd"),
        tanggalSelesai: payload.tanggalSelesai === null ? '' : format(new Date(payload.tanggalSelesai), "yyyy-MM-dd"),
        kontakStatus: payload.kontakStatus,
        direferalkan: payload.direferalkan,
    };

    if(key === 'Ada Anomali') {
        return agent.get('Laporans/persenByAdaAnomali', {
            params,
        });
    } else if(key === 'Hotness Level') {
        return agent.get('Laporans/persenByHotnessLevel', {
            params,
        });
    } else if(key === 'Bekerjasama Dengan BPJS') {
        return agent.get('Laporans/persenByIsBPJS', {
            params,
        });
    } else if(key === 'Ada Yang Mereferralkan') {
        return agent.get('Laporans/persenByIsReferred', {
            params,
        });
    } else if(key === 'Kategori Reject') {
        return agent.get('Laporans/persenByKategoriReject', {
            params,
        });
    } else if(key === 'Leads Info') {
        return agent.get('Laporans/persenByLeadsInfo', {
            params,
        });
    } else if(key === 'Nama Kompetitor') {
        return agent.get('Laporans/persenByNamaKompetitor', {
            params,
        });
    } else if(key === 'PIC Assist.id') {
        return agent.get('Laporans/persenByPICAssist', {
            params,
        });
    } else if(key === 'Potensi Jenis Produk Closing') {
        return agent.get('Laporans/persenByPotensiJenisProdukClosing', {
            params,
        });
    } else if(key === 'Sedang Ada Iklan') {
        return agent.get('Laporans/persenBySedangIklan', {
            params,
        });
    } else if(key === 'Status Berlangganan') {
        return agent.get('Laporans/persenByStatusBerlangganan', {
            params,
        });
    } else if(key === 'Ada Custom') {
        return agent.get('Laporans/persenByAdaCustom', {
            params,
        });
    } else if(key === 'Sudah Menggunakan Sistem Sebelumnya') {
        return agent.get('Laporans/persenByIsAlreadyUsingSystem', {
            params,
        });
    } else if(key === 'Ada Apotek') {
        return agent.get('Laporans/persenByIsPharmacyExists', {
            params,
        });
    } else if(key === 'Jenis Faskes') {
        return agent.get('Laporans/persenByJenisFaskes', {
            params,
        });
    } else if(key === 'Kontak Status') {
        return agent.get('Laporans/persenByKontakStatus', {
            params,
        });
    } else if(key === 'Level Kompetitor') {
        return agent.get('Laporans/persenByLevelKompetitor', {
            params,
        });
    } else if(key === 'Operational Status') {
        return agent.get('Laporans/persenByOperationalStatus', {
            params,
        });
    } else if(key === 'Potensi Durasi Produk Closing') {
        return agent.get('Laporans/persenByPotensiDurasiProdukClosing', {
            params,
        });
    } else if(key === 'Potensi Kompetitor') {
        return agent.get('Laporans/persenByPotensiKompetitor', {
            params,
        });
    } else if(key === 'Sistem Yang Pernah Digunakan') {
        return agent.get('Laporans/persenBySistemApaSaja', {
            params,
        });
    } else if(key === 'Status Saat Ini') {
        return agent.get('Laporans/persenByStatusSaatIni', {
            params,
        });
    }
};

export const getConversion = (payload, key) => {
    const params = {
        tanggalMulai: payload.tanggalMulai === null ? '' : format(new Date(payload.tanggalMulai), "yyyy-MM-dd"),
        tanggalSelesai: payload.tanggalSelesai === null ? '' : format(new Date(payload.tanggalSelesai), "yyyy-MM-dd"),
        kontakStatus: payload.kontakStatus,
        direferalkan: payload.direferalkan,
    };

    if(key === 'Ada Custom') {
        return agent.get('Laporans/convByAdaCustom', {
            params,
        });
    } else if(key === 'Ada Iklan') {
        return agent.get('Laporans/convByAdaIklan', {
            params,
        });
    } else if(key === 'Cycle') {
        return agent.get('Laporans/convByCycle', {
            params,
        });
    } else if(key === 'Durasi Produk') {
        return agent.get('Laporans/convByDurasiProduk', {
            params,
        });
    } else if(key === 'Hotness Level') {
        return agent.get('Laporans/convByHotnessLevel', {
            params,
        });
    } else if(key === 'Jenis Faskes') {
        return agent.get('Laporans/convByJenisFaskes', {
            params,
        });
    } else if(key === 'Jenis Produk') {
        return agent.get('Laporans/convByJenisProduk', {
            params,
        });
    } else if(key === 'Kategori Reject') {
        return agent.get('Laporans/convByKategoriReject', {
            params,
        });
    } else if(key === 'Bekerjasama Dengan BPJS') {
        return agent.get('Laporans/convByKerjasamaDenganBPJS', {
            params,
        });
    } else if(key === 'Kota') {
        return agent.get('Laporans/convByKota', {
            params,
        });
    } else if(key === 'Provinsi') {
        return agent.get('Laporans/convByProvinsi', {
            params,
        });
    } else if(key === 'Range Kapitasi') {
        return agent.get('Laporans/convByRangeKapitasi', {
            params,
        });
    } else if(key === 'Range Kunjungan Bulanan') {
        return agent.get('Laporans/convByRangeKunjunganBulanan', {
            params,
        });
    } else if(key === 'Referral') {
        return agent.get('Laporans/convByReferral', {
            params,
        });
    } else if(key === 'Status Operational') {
        return agent.get('Laporans/convByStatusOperasional', {
            params,
        });
    } else if(key === 'Sudah Menggunakan Sistem Sebelumnya') {
        return agent.get('Laporans/convBySudahPakaiSistem', {
            params,
        });
    } else if(key === 'Tersedia Apotek') {
        return agent.get('Laporans/convByTersediaApotek', {
            params,
        });
    } else if(key === 'Kontak Status') {
        return agent.get('Laporans/convByContactStatus', {
            params,
        });
    }
};

export const getAllRetensionPaidProduct = (params) => {
    return agent.get('Laporans/laporanRetentionPenggunaanBerbayar', {
        params,
    });
};

export const getAllRetensionTrialProduct = (params) => {
    return agent.get('Laporans/laporanRetentionPenggunaanTrial', {
        params,
    });
};

export const getAllRetensionFreeProduct = (params) => {
    return agent.get('Laporans/laporanRetentionPenggunaanFree', {
        params,
    });
};

export const getTicketReport = (payload, key) => {
    const params = {
        tanggalMulai: payload.tanggalMulai === null ? '' : format(new Date(payload.tanggalMulai), 'yyyy-MM-dd'),
        tanggalSelesai: payload.tanggalSelesai === null ? '' : format(new Date(payload.tanggalSelesai), 'yyyy-MM-dd'),
        status: payload.status,
        namaKlinik: payload.namaKlinik,
        pic: payload.pic,
    };

    if(key === 'Kategori Ticket') {
        return agent.get('Laporans/persenTicketingByKategori', {
            params,
        });
    } else if(key === 'Modul Yang Sering Dikomunikasikan') {
        return agent.get('Laporans/persenTicketingByModule', {
            params,
        });
    } else if(key === 'Status Ticket') {
        return agent.get('Laporans/persenTicketingByStatus', {
            params,
        });
    }
};

export const getKlinikTerbanyakTicket = (payload) => {
    const params = {
        tanggalMulai: payload.tanggalMulai === null ? '' : format(new Date(payload.tanggalMulai), 'yyyy-MM-dd'),
        tanggalSelesai: payload.tanggalSelesai === null ? '' : format(new Date(payload.tanggalSelesai), 'yyyy-MM-dd'),
        status: payload.status,
        namaKlinik: payload.namaKlinik,
        pic: payload.pic,
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Laporans/klinikTerbanyakTicketing', {
        params,
    });
};

export const countKlinikTerbanyakTicket = (payload) => {
    const params = {
        tanggalMulai: payload.tanggalMulai === null ? '' : format(new Date(payload.tanggalMulai), 'yyyy-MM-dd'),
        tanggalSelesai: payload.tanggalSelesai === null ? '' : format(new Date(payload.tanggalSelesai), 'yyyy-MM-dd'),
        status: payload.status,
        namaKlinik: payload.namaKlinik,
        pic: payload.pic,
    };

    return agent.get('Laporans/klinikTerbanyakTicketing', {
        params,
    });
};

export const getWaktuDurasiSolvedTicket = (payload) => {
    const params = {
        tanggalMulai: payload.tanggalMulai === null ? '' : format(new Date(payload.tanggalMulai), 'yyyy-MM-dd'),
        tanggalSelesai: payload.tanggalSelesai === null ? '' : format(new Date(payload.tanggalSelesai), 'yyyy-MM-dd'),
        namaKlinik: payload.namaKlinik,
        pic: payload.pic,
    };

    return agent.get('Laporans/waktuDurasiSolvedTicketing', {
        params,
    });
};
