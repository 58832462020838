import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {dispatch} from "../../../store";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import {updateStatusInvoice} from "../../../api/client";

const styles = makeStyles({
    labelTitle: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: 20,
    },
    labelSubTitle: {
        color: 'black',
        fontSize: 18,
    },
    label: {
        color: '#A9A9A9',
        textAlign: 'center',
    },
});

const InvoiceStatus = ({ onClose, selectedInvoicesList, fetchInvoicesList }) => {
    const classes = styles();
    const [status, setStatus] = useState(selectedInvoicesList._id
        ? selectedInvoicesList.KConfig.activeBilling.Billing.payment
        : []);
    const [isSubmitting, setIsSubmitting] = useState(false);

    function handleChangeStatus(e, idx) {
        status[idx].isPayed = e.target.value;
        setStatus([...status]);
    }

    async function handleUpdateStatus() {
        const { snackbar } = dispatch;
        try {
            const payload = {
                payment: status.map(item => {
                    const flag = {...item};
                    if(flag.isPayed === true && !flag.buktiBayar) {
                        flag.buktiBayar = [];
                        return flag;
                    } else {
                        return flag;
                    }
                }),
            };
            setIsSubmitting(true);
            await updateStatusInvoice(selectedInvoicesList.KConfig.activeBilling.Billing._id, payload);
            snackbar.showSuccess("Berhasil update status invoice");
            setIsSubmitting(false);
            onClose();
            fetchInvoicesList();
        } catch (e) {
            console.error(e);
            snackbar.showError("Gagal update status invoice");
            setIsSubmitting(false);
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container justify="center" item xs={12}>
                    <Typography className={classes.labelTitle}>Set Status</Typography>
                </Grid>
                <Grid container justify="center" item xs={12}>
                    <Typography className={classes.labelSubTitle}>Atur status sesuai termin</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 30 }}>
                {
                    status.map((data, idx) => (
                        <Grid container spacing={2} key={idx}>
                            <Grid item md={6}>
                                <Typography className={classes.label}>Termin/Total</Typography>
                                <PInput
                                    value={`${idx+1}/${status.length}`}
                                    inputProps={{style: {
                                        textAlign: "center",
                                    }}}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Typography className={classes.label}>Status</Typography>
                                <PInput
                                    value={data.isPayed}
                                    onChange={(e)=> handleChangeStatus(e, idx)}
                                    select
                                    fullWidth
                                >
                                    <MenuItem value={true}>Dibayar</MenuItem>
                                    <MenuItem value={false}>Menunggu</MenuItem>
                                </PInput>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={onClose}
                >
                    Batalkan
                </PBtn>
                <PBtn
                    onClick={()=> handleUpdateStatus()}
                    disabled={isSubmitting}
                >
                    {!isSubmitting ? "Simpan" : <FontAwesomeIcon icon={faSpinner} spin/>}
                </PBtn>
            </Grid>
        </Grid>
    );
};

const mapState = ({ client }) => ({
    selectedInvoicesList: client.selectedInvoicesList,
});

const mapDispatch = ({ client: { fetchInvoicesList } }) => ({
    fetchInvoicesList,
});

export default connect(mapState, mapDispatch)(InvoiceStatus);
