import React, {useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import DurasiSolvedItem from "./DurasiSolvedItem";

const styles = makeStyles({
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
    tableWrapper: {
        overflow: 'auto',
    },
});

const DurasiSolvedTable = ({ filterData, waktuDurasiSolvedTicketCounter, fetchWaktuDurasiSolvedTicket }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        fetchWaktuDurasiSolvedTicket(filterData);
    }, [fetchWaktuDurasiSolvedTicket, filterData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        const newRowsPerPage = parseInt(e.target.value, 10);

        setPage(0);
        setRowsPerPage(newRowsPerPage);
    };

    return (
        <div>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader}>
                                No.
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                PIC Assist.id
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Rata - Rata Waktu
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <DurasiSolvedItem page={page} rowsPerPage={rowsPerPage}/>
                    </TableBody>
                </Table>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={waktuDurasiSolvedTicketCounter}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
}

const mapState = ({ laporan }) => ({
    waktuDurasiSolvedTicketCounter: laporan.waktuDurasiSolvedTicketCounter,
});

const mapDispatch = ({ laporan: { fetchWaktuDurasiSolvedTicket } }) => ({
    fetchWaktuDurasiSolvedTicket,
});

export default connect(mapState, mapDispatch)(DurasiSolvedTable);
