import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AddNew from "../AddNew";
import ListKategoriPenolakan from "./ListKategoriPenolakan";
import { connect } from 'react-redux';
import {dispatch} from "../../../store";
import {addNewRejectionCategory} from "../../../api/rejection-category";

const KategoriPenolakan = ({ fetchRejectionCategories }) => {
    useEffect(() => {
        fetchRejectionCategories();
    });

    const handleSubmit = async (categoryName, currentUser, setIsSubmitting, setCategoryName) => {
        const { snackbar } = dispatch;
        const trimmedValue = categoryName.trim();

        if (trimmedValue === '') {
            snackbar.showError('Kategori penolakan harus diisi');
            return;
        }

        try {
            setIsSubmitting(true);
            await addNewRejectionCategory(categoryName, currentUser.id);
            setCategoryName('');
            setIsSubmitting(false);
            snackbar.showSuccess('Berhasil menambahkan kategori penolakan');

            await fetchRejectionCategories();
        } catch (err) {
            console.error(err);
            setIsSubmitting(false);
            snackbar.showError('Gagal menambahkan kategori penolakan');
        }
    };

    return (
        <Grid container>
            <AddNew handler={handleSubmit} placeholder={"Tambah kategori disini"} buttonLabel={"Tambah Kategori"} />

            <ListKategoriPenolakan />
        </Grid>
    );
};

const mapState = null;

const mapDispatch = ({ rejectionCategory: { fetchRejectionCategories } }) => ({
    fetchRejectionCategories,
});

export default connect(mapState, mapDispatch)(KategoriPenolakan);
