import React, { useState } from 'react';
import {connect} from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

import format from 'date-fns/format';
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import DeleteConfirmation from "../../share/DeleteConfirmation";
import {deletePenolakan} from "../../../api/activity";
import {dispatch} from "../../../store";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    row: {
        '&:hover': {
            // background: '#fafafa',
            // cursor: 'pointer',
        },
    },
});

const PenolakanItem = (
    {
        rejectionList, setIsOpenAddNew, updateSelectedRejection, fetchRejections, page, rowsPerPage, isFetchingRejectionList
    }) => {
    const classes = useStyles();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedRejection, setSelectedRejection] = useState(undefined);
    const [isDeleting, setIsDeleting] = useState(false);

    const index = page * rowsPerPage;

    function handleEdit(rejection) {
        setIsOpenAddNew(true);
        updateSelectedRejection({...rejection});
    }

    async function handleDelete() {
        const { snackbar } = dispatch;
        try {
            setIsDeleting(true);
            await deletePenolakan(selectedRejection.id);
            setIsDeleting(false);
            snackbar.showSuccess('Berhasil menghapus penolakan');
            fetchRejections();
            setShowDeleteConfirmation(false);
        } catch (err) {
            console.error(err);
            setIsDeleting(false);
            setShowDeleteConfirmation(false);
            snackbar.showError('Gagal menghapus penolakan');
        }
    }

    return (
        <React.Fragment>
            <DeleteConfirmation
                contentText={"Apakah anda yakin akan menghapus penolakan ini?"}
                open={showDeleteConfirmation}
                onClose={() => {
                    setShowDeleteConfirmation(false);
                    setSelectedRejection(undefined);
                }}
                onSubmit={() => handleDelete()}
            />

            <TableBody>
                {
                    isFetchingRejectionList === true
                        ? <TableRow>
                            <TableCell colSpan={6}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress />
                                </div>
                            </TableCell>
                        </TableRow>
                    : rejectionList.slice(index, index + rowsPerPage).map(rejection => (
                        <TableRow
                            className={classes.row}
                            key={rejection.id}
                        >
                            <TableCell>
                                <span>{format(new Date(rejection.rejectionDate), 'dd/MM/yyyy')}</span>
                            </TableCell>

                            <TableCell>
                                <span>{rejection.HealthFacility.name}</span>
                            </TableCell>

                            <TableCell>
                                <span>{rejection.healthFacilityPic.namepic}</span>
                            </TableCell>

                            <TableCell>
                                <span>{rejection.rejectionName || '-'}</span>
                            </TableCell>

                            <TableCell>
                                <span>{rejection.rejectionDetail || '-'}</span>
                            </TableCell>

                            <TableCell>
                                <IconButton disabled={isDeleting} onClick={() => handleEdit(rejection)}>
                                    <FontAwesomeIcon icon={faEdit} style={{ color: 'green', width: 18, height: 18 }} />
                                </IconButton>

                                <IconButton
                                    disabled={isDeleting}
                                    onClick={() => {
                                        setShowDeleteConfirmation(true);
                                        setSelectedRejection(rejection);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} style={{ color: 'red', width: 18, height: 18 }} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </React.Fragment>
    );
};

const mapState = ({ activity }) => ({
    rejectionList: activity.rejectionList,
    isFetchingRejectionList: activity.isFetchingRejectionList,
});

const mapDispatch = ({ activity: { updateSelectedRejection, fetchRejections } }) => ({
    updateSelectedRejection,
    fetchRejections,
});

export default connect(mapState, mapDispatch)(PenolakanItem);
