import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import InvoiceTable from "./InvoiceTable";
import InvoiceForm from "./InvoiceForm";
import InvoiceFilter from "./InvoiceFilter";

import filter from "./../../../assets/img/filter-icon.png";
import arrowDown from "./../../../assets/img/arrow-down-icon.png";

import debounce from "lodash/debounce";

const styles = makeStyles({
    search: {
        flex: 0.9,
    },
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
    filter: {
        float: 'right',
        display: 'inline-flex',
        padding: '15px 5px',
        marginTop: '-8px',
    },
    labelFilter: {
        fontWeight: 'bold',
        fontSize: '16px',
        fontFamily: 'Nunito',
        marginTop: '3px',
        marginRight: '10px',
    },
    imgFilter: {
        height: '40%',
        marginRight: '15px',
    },
    arrowFilter: {
        height: '40%',
        marginTop: '10px',
        cursor: 'pointer',
    },
});

const Invoice = ({ updatePageInvoices, pageInvoices, fetchInvoicesListFilter, setSubMenu }) => {
    const classes = styles();
    const [isFilter, setIsFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        namaKlinik: '',
        jatuhTempoStart: null,
        jatuhTempoEnd: null,
        status: 0,
        skip: 0,
        limit: 5,
    });
    const [search, setSearch] = useState('');
    const [isOpenFilterForm, setIsOpenFilterForm] = useState(false);

    const handleFilter = () => {
        setIsFilter(true);
        fetchInvoicesListFilter(filterData);
        setIsOpenFilterForm(false);
    };

    const handleClearFilter = () => {
        setFilterData({
            namaKlinik: '',
            jatuhTempoStart: null,
            jatuhTempoEnd: null,
            status: 0,
            skip: 0,
            limit: 5,
        });
        setIsFilter(false);
    };

    const getSuggestion = debounce(async (value) => {
        filterData.namaKlinik = value;
        setFilterData({...filterData, namaKlinik: filterData.namaKlinik});
        setIsFilter(true);
        await fetchInvoicesListFilter(filterData);
    }, 500);

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        if(value.length > 2) {
            setSearch(value);
            getSuggestion(value);
        } else if(value.length === 0) {
            setSearch(value);
            setIsFilter(false);
        } else {
            setSearch(value);
        }
    };

    function handleChangeFilter(e) {
        setIsFilter(true);
        filterData.status = e.target.value;
        setFilterData({...filterData, status: filterData.status});
        fetchInvoicesListFilter(filterData);
    }

    function handleChangePageInvoices() {
        updatePageInvoices(1);
    }

    return (
        pageInvoices === 0
            ? <Grid container>
                <Grid container item xs={12} style={{ marginTop: '44px' }}>
                    <Grid item xs={4}>
                        <PInput
                            placeholder="Cari klinik disini"
                            value={search}
                            onChange={handleChangeSearch}
                            className={classes.search}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid container justify="center" item xs={2}>
                        <PBtn onClick={()=> handleChangePageInvoices()}>Tambah Invoice Baru</PBtn>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.filter}>
                            <img alt="filter" src={filter} className={classes.imgFilter}/>
                            <Typography className={classes.labelFilter}>Filter by</Typography>
                            <img
                                alt="arrow"
                                src={arrowDown}
                                className={classes.arrowFilter}
                                onClick={()=> setIsOpenFilterForm(true)}
                            />
                            <Dialog open={isOpenFilterForm} maxWidth="xs" onClose={()=> setIsOpenFilterForm(false)} fullWidth>
                                <DialogTitle>
                                    Jatuh Tempo
                                </DialogTitle>
                                <DialogContent>
                                    <InvoiceFilter
                                        filterData={filterData}
                                        setFilterData={setFilterData}
                                        startFilter={handleFilter}
                                        clearFilter={handleClearFilter}
                                    />
                                </DialogContent>
                            </Dialog>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.label}>Status</Typography>
                        <PInput
                            value={filterData.status}
                            onChange={(e)=> handleChangeFilter(e)}
                            select
                            fullWidth
                        >
                            <MenuItem value={0}>Seluruhnya</MenuItem>
                            <MenuItem value={1}>Dibayar</MenuItem>
                            <MenuItem value={2}>Menunggu</MenuItem>
                        </PInput>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 34 }}>
                        <InvoiceTable
                            isFilter={isFilter}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            setSubMenu={setSubMenu}
                        />
                    </Grid>
                </Grid>
            </Grid>
            : <InvoiceForm/>
    );
};

const mapState = ({ client }) => ({
    pageInvoices: client.pageInvoices,
});

const mapDispatch = ({ client: { updatePageInvoices, fetchInvoicesListFilter } }) => ({
    updatePageInvoices,
    fetchInvoicesListFilter,
});

export default connect(mapState, mapDispatch)(Invoice);
