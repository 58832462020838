// export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL = "https://reqaid.com:8989/api";
// export const API_URL = "http://localhost:8080/api";

export const offerStatuses = [
    { label: 'Initiating', value: 'initiating' },
    { label: 'Discuss', value: 'discuss' },
    { label: 'Delivered', value: 'delivered' },
    { label: 'Closed', value: 'Closed' },
    { label: 'Revised', value: 'revised' },
    { label: 'Rejected', value: 'rejected' },
];

export const statusPaymentPenawaran = [
    { label: 'Closed', value: 'closed' },
    { label: 'Waiting', value: 'waiting' },
    { label: 'Fail', value: 'fail' },
];

export const statPenawaran = [
    { label: 'Waiting', value: 'waiting' },
    { label: 'Revised', value: 'revised' },
    { label: 'Closing', value: 'closing' },
    { label: 'Rejected', value: 'rejected' },
];

export const ticketCategory = [
    { label: 'Komplain', value: 'Komplain' },
    { label: 'Pertanyaan', value: 'Pertanyaan' },
    { label: 'Saran', value: 'Saran' },
    { label: 'Custom', value: 'Custom' },
    { label: 'Testimonial', value: 'Testimonial' },
];

export const retensionTableHeader = [
    'Nama Klinik',
    'Kota',
    'Total Hari',
    'Mulai',
    'Selesai',
    'Produk',
    'Jumlah Tenaga Medis',
    'Jumlah Akun',
    'Jumlah Data Obat',
    'Jumlah Data Tindakan',
    'Jumlah Janji',
    'Jumlah Transaksi Pasien',
    'Jumlah Transaksi Restock',
    'Jumlah Traksaksi Office',
    'Jumlah EMR AKHP',
    'Jumlah EMR Awal Pemeriksaan Kehamilan',
    'Jumlah EMR Diagnosa Bidan',
    'Jumlah EMR Diagnosa',
    'Jumlah EMR Catatan Dokter',
    'Jumlah EMR File',
    'Jumlah EMR Hasil Lab',
    'Jumlah EMR Imunisasi',
    'Jumlah EMR Kegiatan Kelompok',
    'Jumlah EMR Kesimpulan',
    'Jumlah EMR MCU Plan',
    'Jumlah EMR Kamar Tindakan',
    'Jumlah EMR Catatan Bidan',
    'Jumlah EMR Obgyn',
    'Jumlah EMR Odontogram',
    'Jumlah EMR Order',
    'Jumlah EMR Order Assesment',
    'Jumlah EMR Catatan Lainnya',
    'Jumlah EMR Resep',
    'Jumlah EMR Riwayat Kelahiran',
    'Jumlah EMR Rujukan Luar Assist',
    'Jumlah EMR Scour Prescription',
    'Jumlah EMR Vital Sign',
];

export const assistModule = [
    { label: 'Modul Dashboard', value: 'Modul Dashboard' },
    { label: 'Modul Rawat Jalan', value: 'Modul Rawat Jalan' },
    { label: 'Modul Registration', value: 'Modul Registration' },
    { label: 'Modul EMR', value: 'Modul EMR' },
    { label: 'Modul Apotek', value: 'Modul Apotek' },
    { label: 'Modul Kasir', value: 'Modul Kasir' },
    { label: 'Modul Laporan', value: 'Modul Laporan' },
    { label: 'Modul Profile', value: 'Modul Profile' },
    { label: 'Modul Settings', value: 'Modul Settings' },
    { label: 'Modul Akreditasi', value: 'Modul Akreditasi' },
    { label: 'Lainnya', value: 'Lainnya' },
];
