import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight} from "@fortawesome/free-solid-svg-icons";

const styles = makeStyles({
    container: {
        boxShadow: '0 40px 40px -40px #777',
        backgroundColor: 'white',
        borderRadius: 8,
        marginBottom: 40,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 10,
        paddingBottom: 20,
        width: '100%',
        height: 100,
    },
    wrap: {
        borderLeft: '3px solid #2b64af',
        paddingLeft: 10,
    },
    label: {
        fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: 'Nunito',
        marginBottom: 5,
        color: '#2b64af',
    },
    subLabel: {
        fontWeight: 'bold',
        fontSize: '15px',
        fontFamily: 'Nunito',
        marginBottom: 5,
        color: '#6e7d91',
    },
    wrapIcon: {
        color: '#C0C0C0',
        float: 'right',
    },
});

export default function ({ label, subLabel, handleClick }) {
    const classes = styles();

    return (
        <Grid container className={classes.container}>
            <Grid item xs={10} className={classes.wrap}>
                <Typography noWrap className={classes.label}>
                    {label}
                </Typography>
                <Typography noWrap className={classes.subLabel}>
                    {subLabel}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <IconButton
                    className={classes.wrapIcon}
                    onClick={handleClick}
                >
                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{ width: 30, height: 30 }}/>
                </IconButton>
            </Grid>
        </Grid>
    );
}
