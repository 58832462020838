import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import PDatePicker from "../../share/PDatePicker";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
});

const FilterData = ({ payloadFilter, setPayloadFilter, startFilter, clearFilter }) => {
    const classes = styles();

    function handleChangeFilter(e) {
        setPayloadFilter({...payloadFilter, [e.target.name]: e.target.value});
    }

    const handleChangeTanggalMulai = (date) => {
        setPayloadFilter({...payloadFilter, tanggalMulai: date});
    };

    const handleChangeTanggalSelesai = (date) => {
        setPayloadFilter({...payloadFilter, tanggalSelesai: date});
    };

    return(
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>Dari Tanggal</Typography>
                                <PDatePicker
                                    value={payloadFilter.tanggalMulai}
                                    onChange={handleChangeTanggalMulai}
                                    format='dd MMMM yyyy'
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </InputAdornment>
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.label}>Sampai Tanggal</Typography>
                                <PDatePicker
                                    value={payloadFilter.tanggalSelesai}
                                    onChange={handleChangeTanggalSelesai}
                                    format='dd MMMM yyyy'
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </InputAdornment>
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Klinik</Typography>
                        <PInput
                            name="faskesName"
                            value={payloadFilter.faskesName}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>PIC Klinik</Typography>
                        <PInput
                            name="PICFaskes"
                            value={payloadFilter.PICFaskes}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Jabatan</Typography>
                        <PInput
                            name="position"
                            value={payloadFilter.position}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={clearFilter}
                >
                    Reset
                </PBtn>
                <PBtn
                    onClick={startFilter}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default FilterData;
