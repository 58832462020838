import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SquareSwitchBlue from "../../share/SquareSwitchBlue";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import {dispatch} from "../../../store";
import {addNewDictionary} from "../../../api/dictionary";

const styles = makeStyles({
    header: {
        display: 'inline-flex',
    },
    wrapTitle: {
        marginRight: 99,
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '25px',
        color: '#000000',
    },
    subTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#6e7d91',
    },
    titleDialog: {
        fontFamily: "Nunito",
        fontSize: "20px",
        color: "#15162d",
        fontWeight: "600",
    },
    action: {
        marginRight: '13px',
        marginTop: '15px',
    }
});

const AddNewProduk =  function
    ({
         buttonLabel, currentUser, fetchProducts, fetchGeneralSetting, updateIsProductActive, isProductActive, productId, updateSelectedProduct,
    }) {
    const classes = styles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [namaProduk, setNamaProduk] = useState('');
    const [fiturList, setFiturList] = useState([]);
    // const [lamaBerlangganan, setLamaBerlangganan] = useState(0);
    // const [lamaBerlanggananType, setLamaBerlanggananType] = useState('monthly');
    const [harga, setHarga] = useState(0);

    useEffect(()=> {
        fetchGeneralSetting();
    }, [fetchGeneralSetting]);

    function handleChangeActive(e) {
        updateSelectedProduct(e.target.checked);
        const payload = {
            isProductActive: e.target.checked,
        };
        updateIsProductActive(productId, payload);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
        resetState();
    };

    const resetState = () => {
        setNamaProduk('');
        setFiturList([]);
        // setLamaBerlangganan(0);
        // setLamaBerlanggananType('monthly');
        setHarga(0);
    };

    const handleChangeFitur = (idx, e) => {
        const { value } = e.target;
        fiturList[idx] = value;
        setFiturList([...fiturList]);
    };

    const handleTambahFitur = () => {
        fiturList.push('');
        setFiturList([...fiturList]);
    };

    // const handleChangeBerlanggananType = (e) => {
    //     const { value } = e.target;
    //     setLamaBerlanggananType(value);
    // };

    const handleDeleteFitur = (idx) => {
        fiturList.splice(idx, 1);
        setFiturList([...fiturList]);
    };

    const handleSimpan = async () => {
        try {
            const payload = {
                name: namaProduk,
                features: fiturList,
                // quantity: lamaBerlangganan,
                // quantityType: lamaBerlanggananType,
                price: harga,
                type: 'product',
                subscription: '',
                discount: '',
                total: '',
                grandTotal: '',
                createdAt: new Date(),
                createdId: currentUser.id,
            };

            setIsSubmitting(true);
            await addNewDictionary(payload);
            setIsSubmitting(false);
            resetState();
            setShowDialog(false);
            dispatch.snackbar.showSuccess('Berhasil menambahkan produk');
            fetchProducts();
        } catch (err) {
            console.error(err);
            dispatch.snackbar.showError('Gagal menambahkan produk');
            setIsSubmitting(false);
        }
    };

    return (
        <Grid item xs={12} style={{ marginTop: 44 }}>
            <Dialog maxWidth="md" open={showDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    <Typography className={classes.titleDialog}>Tambah Produk</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <PInput
                                        label="Nama Produk"
                                        value={namaProduk}
                                        onChange={(e) => setNamaProduk(e.target.value)}
                                    />
                                    <div style={{ marginTop: 16 }}>
                                        {
                                            fiturList.map(($fitur, $idx) => (
                                                <Grid key={$idx} item md={12}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <PInput
                                                            label={`Fitur-${$idx + 1}`}
                                                            value={$fitur}
                                                            onChange={(e) => handleChangeFitur($idx, e)}
                                                        />
                                                        <div>
                                                            <IconButton onClick={() => handleDeleteFitur($idx)}>
                                                                <FontAwesomeIcon icon={faTimesCircle} />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            ))
                                        }

                                        <Grid item xs={12} style={{ marginTop: 16 }}>
                                            <PBtn onClick={handleTambahFitur}>Tambah Fitur</PBtn>
                                        </Grid>
                                    </div>
                                </Grid>

                                {/*<Grid item xs={4}>*/}
                                {/*    <PInput*/}
                                {/*        label="Lama Berlangganan"*/}
                                {/*        value={lamaBerlangganan}*/}
                                {/*        onChange={(e) => setLamaBerlangganan(e.target.value)}*/}
                                {/*    />*/}
                                {/*    <div>*/}
                                {/*        <RadioGroup name="produkFitur" value={lamaBerlanggananType} onChange={handleChangeBerlanggananType}>*/}
                                {/*            <FormControlLabel value="monthly" control={<Radio />} label="Bulan" />*/}
                                {/*            <FormControlLabel value="yearly" control={<Radio />} label="Tahun" />*/}
                                {/*        </RadioGroup>*/}
                                {/*    </div>*/}
                                {/*</Grid>*/}

                                <Grid item md={6}>
                                    <PInput
                                        label="Harga per Bulan"
                                        value={harga}
                                        onChange={(e) => setHarga(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <PBtn disabled={isSubmitting} onClick={handleSimpan} style={{marginRight: '13px', marginTop: '15px', marginBottom: '10px'}}>Simpan</PBtn>
                </DialogActions>
            </Dialog>

            <div className={classes.header}>
                <div className={classes.wrapTitle}>
                    <Typography className={classes.title}>
                        Semua Produk
                    </Typography>
                    <Typography className={classes.subTitle}>
                        Lakukan penyetelan produk Assist.id disini
                    </Typography>
                </div>

                <FormGroup>
                    <FormControlLabel
                        control={
                            <SquareSwitchBlue
                                checked={isProductActive}
                                onChange={(e)=> handleChangeActive(e)}
                            />
                        }
                        label={isProductActive ? 'Enabled' : 'Disabled'}
                    />
                </FormGroup>

                <PBtn disabled={isSubmitting} onClick={() => {
                    setShowDialog(true);
                }}>{buttonLabel}</PBtn>
            </div>
        </Grid>
    )
};

const mapState = ({ user, generalSetting }) => ({
    currentUser: user.currentUser,
    isProductActive: generalSetting.isProductActive,
    productId: generalSetting.productId,
});

const mapDispatch = ({ product: { fetchProducts }, generalSetting: { fetchGeneralSetting, updateIsProductActive, updateSelectedProduct, } }) => ({
    fetchProducts,
    fetchGeneralSetting,
    updateIsProductActive,
    updateSelectedProduct,
});

export default connect(mapState, mapDispatch)(AddNewProduk);
