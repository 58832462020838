import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import {DatePicker} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
});

const BerlanggananFilter = ({ filterData, setFilterData, startFilter, clearFilter }) => {
    const classes = styles();

    const handleChangeMasaAktifStart = (date) => {
        filterData.masaAktifStart = date;
        setFilterData(prevState => ({
            ...prevState, masaAktifStart: filterData.masaAktifStart,
        }));
    };

    const handleChangeMasaAktifEnd = (date) => {
        filterData.masaAktifEnd = date;
        setFilterData(prevState => ({
            ...prevState, masaAktifEnd: filterData.masaAktifEnd,
        }));
    };

    const handleChangeJatuhTempoStart = (date) => {
        filterData.jatuhTempoStart = date;
        setFilterData(prevState => ({
            ...prevState, jatuhTempoStart: filterData.jatuhTempoStart,
        }));
    };

    const handleChangeJatuhTempoEnd = (date) => {
        filterData.jatuhTempoEnd = date;
        setFilterData(prevState => ({
            ...prevState, jatuhTempoEnd: filterData.jatuhTempoEnd,
        }));
    };

    function handleChangeFilter(e) {
        setFilterData({...filterData, [e.target.name]: e.target.value});
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Masa Aktif</Typography>
                        <DatePicker
                            value={filterData.masaAktifStart}
                            onChange={handleChangeMasaAktifStart}
                            label="Dari Tanggal"
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label} style={{ visibility: 'hidden' }}>Masa Aktif</Typography>
                        <DatePicker
                            value={filterData.masaAktifEnd}
                            onChange={handleChangeMasaAktifEnd}
                            label="Sampai Tanggal"
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Jatuh Tempo</Typography>
                        <DatePicker
                            value={filterData.jatuhTempoStart}
                            onChange={handleChangeJatuhTempoStart}
                            label="Dari Tanggal"
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label} style={{ visibility: 'hidden' }}>Jatuh Tempo</Typography>
                        <DatePicker
                            value={filterData.jatuhTempoEnd}
                            onChange={handleChangeJatuhTempoEnd}
                            label="Sampai Tanggal"
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Klinik</Typography>
                        <PInput
                            name={"namaKlinik"}
                            value={filterData.namaKlinik}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Produk</Typography>
                        <PInput
                            name={"namaProduk"}
                            value={filterData.namaProduk}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={clearFilter}
                >
                    Reset
                </PBtn>
                <PBtn
                    onClick={startFilter}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default BerlanggananFilter;
