export const offerDetail = {
    state: {
        selectedHealthFacility: {},
        selectedHealthFacilityPIC: {},
    },
    reducers: {
        resetState() {
            return {
                selectedHealthFacility: {},
                selectedHealthFacilityPIC: {},
            }
        },
        updateSelectedHealthFacility(state, payload) {
            return {
                ...state,
                selectedHealthFacility: payload,
            }
        },
        updateSelectedHealthFacilityPIC(state, payload) {
            return {
                ...state,
                selectedHealthFacilityPIC: payload,
            }
        },
    },
};
