import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import Header from "./../faskes/templateFaskes/header/header";
import ChartRevenue from "./ChartRevenue";
import Total from "./Total";
import Birthday from "./Birthday";
import RegisteredFaskes from "./RegisteredFaskes";
import ChartRegisteredLeads from "./ChartRegisteredLeads";
import BestSellingProductAndReasonRejection from "./BestSellingProductAndReasonRejection";

import {
    getMostActivePIC,
    getMostActivePICOffer,
    getTotalLeads,
    getTotalLeadsBulanIni,
    getTotalKompetitor,
    getTotalPindahDariKompetitor,
    getMostClosedLeads,
} from "../../api/dashboard";

import TotalLeadsIcon from "../../assets/img/total-leads-icon.svg";
import TotalKompetitorIcon from "../../assets/img/total-kompetitor-icon.svg";
import PindahKompetitorIcon from "../../assets/img/pindah-kompetitor-icon.svg";

const styles = makeStyles({
    content: {
        width: '100%',
    },
    body: {
        width: '100%',
        height: '85%',
        position: 'absolute',
        marginTop: '7%',
        bottom: 0,
        backgroundColor: '#f5f6f8',
        overflowY: 'auto',
        '@media only screen and (max-width:994px)': {
            height: '90%',
            width: '1440px',
        },
    },
    container: {
        padding: '25px 20px',
        '@media only screen and (max-width:994px)': {
            padding: '4% 20px',
        },
        '@media only screen and (max-width:400px)': {
            padding: '4% 20px',
        },
    },
    paperTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '20px',
    },
    paperHeadingSubTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '16px',
        marginTop: 15,
    },
    paperSubTitle: {
        fontFamily: 'Nunito',
        fontSize: '16px',
    },
    paperInteraction: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        color: 'white',
        background: 'linear-gradient(to right, #2b64af 7%, #5dcdf8 106%)',
        borderRadius: 8,
    },
    paperOffer: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        color: 'white',
        background: 'linear-gradient(to right, #4fa592 7%, #aced91 107%)',
        borderRadius: 8,
    },
    paperClosing: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        color: 'white',
        background: 'linear-gradient(to right, #ee847c 2%, #f6bc69 87%)',
        borderRadius: 8,
    },
    paperStandard: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        marginBottom: 20,
        borderRadius: 8,
    },
    paperTotalLeads: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        color: '#6e7d91',
        background: '#2b64af',
        borderRadius: 8,
    },
    paperMoveFromCompetitor: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        color: '#6e7d91',
        background: '#54a651',
        borderRadius: 8,
    },
    paperSubTitlev1: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#6e7d91',
        fontWeight: 'bold',
    },
    paperSubTitlev2: {
        fontFamily: 'Nunito',
        fontSize: '27px',
        color: 'black',
        fontWeight: 'bold',
    },
    tableCellBody: {
        paddingLeft: 0,
        borderBottom: 'none',
    },
    icon: {
        width: '70%',
        height: '70%',
    },
});

const Dashboard = ({ isViewedByMobile }) => {
    const classes = styles();
    const [mostActivePIC, setMostActivePIC] = useState({});
    const [mostActivePICOffer, setMostActivePICOffer] = useState({});
    const [mostClosedLeads, setMostClosedLeads] = useState({});
    const [totalLeads, setTotalLeads] = useState(0);
    const [totalLeadsBulanIni, setTotalLeadsBulanIni] = useState(0);
    const [totalKompetitor, setTotalKompetitor] = useState(0);
    const [totalPindahDariKompetitor, setTotalPindahDariKompetitor] = useState(0);

    useEffect(()=> {
        (async function () {
            const resultMostActivePIC = await getMostActivePIC();
            const resultMostActivePICOffer = await getMostActivePICOffer();
            const resultMostClosedLeads = await getMostClosedLeads();
            setMostActivePIC(resultMostActivePIC.data);
            setMostActivePICOffer(resultMostActivePICOffer.data);
            setMostClosedLeads(resultMostClosedLeads.data);
            const resultTotalLeads = await getTotalLeads();
            const resultTotalLeadsBulanIni = await getTotalLeadsBulanIni();
            setTotalLeads(resultTotalLeads.data);
            setTotalLeadsBulanIni(resultTotalLeadsBulanIni.data.bulanIni);
            const resultTotalKompetitor = await getTotalKompetitor();
            const resultTotalPindahDariKompetitor = await getTotalPindahDariKompetitor();
            setTotalKompetitor(resultTotalKompetitor.data);
            setTotalPindahDariKompetitor(resultTotalPindahDariKompetitor.data);
        })();
    }, []);

    return (
        <div className={classes.content}>
            <Header title={"Dashboard"}/>
            <div className={classes.body} style={isViewedByMobile ? {height: '95%'} : {height: '85%'}}>
                <Container className={classes.container}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <Paper elevation={3} className={classes.paperInteraction}>
                                <Typography className={classes.paperTitle}>
                                    PIC PALING AKTIF
                                </Typography>
                                <Typography className={classes.paperTitle}>
                                    INTERAKSI
                                </Typography>
                                <Typography className={classes.paperHeadingSubTitle}>
                                    {mostActivePIC.name || '-'}
                                </Typography>
                                <Typography className={classes.paperSubTitle}>
                                    {mostActivePIC.position || '-'}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={3} className={classes.paperOffer}>
                                <Typography className={classes.paperTitle}>
                                    PIC PALING AKTIF
                                </Typography>
                                <Typography className={classes.paperTitle}>
                                    PENAWARAN
                                </Typography>
                                <Typography className={classes.paperHeadingSubTitle}>
                                    {mostActivePICOffer.name || '-'}
                                </Typography>
                                <Typography className={classes.paperSubTitle}>
                                    {mostActivePICOffer.position || '-'}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={3} className={classes.paperClosing}>
                                <Typography className={classes.paperTitle}>
                                    PIC PALING BANYAK
                                </Typography>
                                <Typography className={classes.paperTitle}>
                                    CLOSING
                                </Typography>
                                <Typography className={classes.paperHeadingSubTitle}>
                                    {mostClosedLeads.name || '-'}
                                </Typography>
                                <Typography className={classes.paperSubTitle}>
                                    {mostClosedLeads.position || '-'}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <ChartRevenue/>
                    </Grid>
                    <Total/>
                    <Grid container spacing={4} style={{ marginTop: 10 }}>
                        <Grid item xs={4}>
                            <Paper elevation={3} className={classes.paperStandard}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.tableCellBody}>
                                                <Typography className={classes.paperSubTitlev1}>
                                                    Total Leads
                                                </Typography>
                                                <Typography className={classes.paperSubTitlev2}>
                                                    {totalLeads}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                                <img alt="total-leads-icon" src={TotalLeadsIcon} className={classes.icon}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                            <Paper elevation={3} className={classes.paperTotalLeads}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.tableCellBody}>
                                                <Typography className={classes.paperSubTitlev1} style={{ color: 'white' }}>
                                                    Leads Bulan Ini
                                                </Typography>
                                                <Typography className={classes.paperSubTitlev2} style={{ color: 'white' }}>
                                                    {totalLeadsBulanIni}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                                <img alt="leads-bulan-ini-icon" src={PindahKompetitorIcon} className={classes.icon}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={4} style={{ maxHeight: 360 }}>
                            <Paper elevation={3} className={classes.paperStandard}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.tableCellBody}>
                                                <Typography className={classes.paperSubTitlev1}>
                                                    Total Kompetitor
                                                </Typography>
                                                <Typography className={classes.paperSubTitlev2}>
                                                    {totalKompetitor}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                                <img alt="total-kompetitor-icon" src={TotalKompetitorIcon} className={classes.icon}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                            <Paper elevation={3} className={classes.paperMoveFromCompetitor}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.tableCellBody}>
                                                <Typography className={classes.paperSubTitlev1} style={{ color: 'white' }}>
                                                    Pindah Dari Kompetitor
                                                </Typography>
                                                <Typography className={classes.paperSubTitlev2} style={{ color: 'white' }}>
                                                    {totalPindahDariKompetitor}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                                <img alt="pindah-dari-kompetitor" src={PindahKompetitorIcon} className={classes.icon}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <RegisteredFaskes/>
                    </Grid>
                    <Grid container spacing={4} style={{ marginTop: 10 }}>
                        <ChartRegisteredLeads/>
                        <BestSellingProductAndReasonRejection/>
                    </Grid>
                    <Birthday/>
                </Container>
            </div>
        </div>
    );
};

const mapState = ({ app }) => ({
    isViewedByMobile: app.isViewedByMobile,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(Dashboard);
