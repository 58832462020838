export const user = {
    state: {
        isAuthenticated: false,
        loginResponse: undefined,
        currentUser: undefined,
    },
    reducers: {
        updateLoginState(state, payload) {
            return {
                ...state,
                isAuthenticated: payload.isAuthenticated,
                loginResponse: payload.loginResponse,
                currentUser: payload.currentUser,
            }
        }
    }
};
