import React from "react";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {makeStyles} from "@material-ui/core";

import PBtn from "./PBtn";

const styles = makeStyles({
    label: {
        fontFamily: "Nunito",
        fontSize: "18px",
        color: "#15162d",
        fontWeight: "600",
    },
    wrapAction: {
        marginTop: "10px",
        marginRight: "15px",
    },
});

export default function ({ contentText, open, onClose, onSubmit, textButtonConfirmation }) {
    const classes = styles();

    return(
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Typography className={classes.label}>{contentText}</Typography>
            </DialogContent>
            <DialogActions className={classes.wrapAction}>
                <PBtn dropShadow inverted onClick={onClose}>
                    Batal
                </PBtn>
                <PBtn onClick={onSubmit}>
                    {textButtonConfirmation}
                </PBtn>
            </DialogActions>
        </Dialog>
    );
}
