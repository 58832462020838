import agent from "./agent";

export const addNewKompetitor = (name, userId) => {
    const payload = {
        name: name,
        createdAt: new Date(),
        createdId: userId,
    };

    return agent.post('Kompetitors', payload);
};

export const getKompetitors = () => {
    return agent.get('Kompetitors');
};

export const updateKompetitor = (kompetitorId, name, userId) => {
    const payload = {
        name: name,
        updatedAt: new Date(),
        updatedId: userId,
    };

    return agent.patch(`Kompetitors/${kompetitorId}`, payload);
};

export const deleteKompetitor = (kompetitorId) => {
    return agent.delete(`Kompetitors/${kompetitorId}`);
};
