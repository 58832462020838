import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import {makeStyles} from "@material-ui/core";

import {getRegisteredFaskes} from "../../api/dashboard";

const styles = makeStyles({
    wrap: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        maxHeight: 360,
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#15162d',
    },
    borderTitle: {
        width: 40,
        float: 'left',
        background: '#2b64af',
        border: '2px solid #2b64af',
    },
    tableWrapper: {
        overflowY: 'auto',
        height: 190,
        width: '100%',
    },
    tableCellBody: {
        paddingLeft: 1,
        fontFamily: 'Nunito',
        fontSize: '16px',
        borderBottom: 'none',
    },
    paper: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 8,
    },
});

const RegisteredFaskes = () => {
    const classes = styles();
    const [registeredFaskesList, setRegisteredFaskesList] = useState([]);

    useEffect(() => {
        (async function () {
            const result = await getRegisteredFaskes();
            setRegisteredFaskesList(result.data);
        })();
    }, []);

    return (
        <Grid item xs={4} className={classes.wrap}>
            <Typography className={classes.title}>
                Faskes Terdaftar
            </Typography>
            <hr className={classes.borderTitle}/>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableBody>
                        {
                            registeredFaskesList.length === 0
                                ? <TableRow>
                                    <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                        <Typography>Tidak Ada</Typography>
                                    </TableCell>
                                </TableRow>
                                : registeredFaskesList.map((item, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell className={classes.tableCellBody}>
                                            <Paper className={classes.paper}>
                                                <Typography style={{ display: 'flex', fontWeight: 'bold' }}>
                                                    <span style={{ width: '50%' }}>{item._id}</span>
                                                    <span style={{ width: '50%', textAlign: 'right' }}>{item.count}</span>
                                                </Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </div>
        </Grid>
    );
};

export default RegisteredFaskes;
