import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import BuktiBayarTable from "./BuktiBayarTable";
import BuktiBayarForm from "./BuktiBayarForm";

import debounce from "lodash/debounce";

const styles = makeStyles({
    search: {
        flex: 0.9,
    },
});

const BuktiBayar = ({ pageReceipts, updatePageReceipts, fetchReceiptsListFilter }) => {
    const classes = styles();
    const [isFilter, setIsFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        namaKlinik: '',
        skip: 0,
        limit: 5,
    });
    const [search, setSearch] = useState('');

    const getSuggestion = debounce(async (value) => {
        filterData.namaKlinik = value;
        setFilterData({...filterData, namaKlinik: filterData.namaKlinik});
        setIsFilter(true);
        await fetchReceiptsListFilter(filterData);
    }, 500);

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        if(value.length > 2) {
            setSearch(value);
            getSuggestion(value);
        } else if(value.length === 0) {
            setSearch(value);
            setIsFilter(false);
        } else {
            setSearch(value);
        }
    };

    function handleChangePageReceipts() {
        updatePageReceipts(1);
    }

    return (
        pageReceipts === 0
            ? <Grid container>
                <Grid container item xs={12} style={{ marginTop: '44px' }}>
                    <Grid item xs={4}>
                        <PInput
                            placeholder="Cari klinik disini"
                            value={search}
                            onChange={handleChangeSearch}
                            className={classes.search}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid container justify="center" item xs={2}>
                        <PBtn onClick={()=> handleChangePageReceipts()}>Tambah Bukti Bayar</PBtn>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 34 }}>
                    <BuktiBayarTable
                        isFilter={isFilter}
                        filterData={filterData}
                        setFilterData={setFilterData}
                    />
                </Grid>
            </Grid>
            : <BuktiBayarForm/>
    );
};

const mapState = ({ client }) => ({
    pageReceipts: client.pageReceipts,
});

const mapDispatch = ({ client: { updatePageReceipts, fetchReceiptsListFilter } }) => ({
    updatePageReceipts,
    fetchReceiptsListFilter,
});

export default connect(mapState, mapDispatch)(BuktiBayar);
