import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faArrowCircleLeft, faPrint} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import PDatePicker from "../../../share/PDatePicker";
import PInput from "../../../share/PInput";
import PBtn from "../../../share/PBtn";

import format from "date-fns/format";

import exportXL from "../../../utils/exportXL";

import {getAllRetensionPaidProduct} from "../../../../api/laporan";

import ProdukBerbayarTable from "./ProdukBerbayarTable";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
});

const ProdukBerbayar = ({ setIsPage, retensionPaidProductCounter }) => {
    const classes = styles();
    const [filterData, setFilterData] = useState({
        dateStart: null,
        dateEnd: null,
        skip: 0,
        limit: 5,
    });
    const [allRetensionPaidProduct, setAllRetensionPaidProduct] = useState([]);

    useEffect(()=> {
        if(retensionPaidProductCounter > 0 && allRetensionPaidProduct.length === 0) {
            (async function () {
                try {
                    const skipLimit = {
                        skip: 0,
                        limit: retensionPaidProductCounter,
                    };
                    const result = await getAllRetensionPaidProduct(skipLimit);
                    setAllRetensionPaidProduct(result.data);
                } catch (e) {
                    console.error(e);
                }
            })();
        }
    }, [retensionPaidProductCounter, allRetensionPaidProduct]);

    const handleChangeStartDate = (date) => {
        setFilterData(prevState => ({
            ...prevState, dateStart: date,
        }));
    };

    const handleChangeEndDate = (date) => {
        setFilterData(prevState => ({
            ...prevState, dateEnd: date,
        }));
    };

    const handleExportToExcel = () => {
        try {
            const hasil = allRetensionPaidProduct.map(item => ({
                'Nama Klinik': item.nama,
                'Kota': item.alamat.city,
                'Total Hari': `${item.durasi} ${item.satuanDurasi}`,
                'Mulai': format(new Date(item.tanggalMulai), "dd/MM/yyyy"),
                'Selesai': format(new Date(item.tanggalSelesai), "dd/MM/yyyy"),
                'Produk': item.namaProduk,
                'Jumlah Tenaga Medis': item.counterPraktek,
                'Jumlah Akun': item.counterStaff,
                'Jumlah Data Obat': item.counterMedicine,
                'Jumlah Data Tindakan': item.counterProcedure,
                'Jumlah Janji': item.counterJanji,
                'Jumlah Transaksi Pasien': item.counterTransaksiPasien,
                'Jumlah Transaksi Restock': item.counterTransaksiApotek,
                'Jumlah Traksaksi Office': item.counterTransaksiOffice,
                'Jumlah EMR AKHP': item.EMR.counterAkhp,
                'Jumlah EMR Awal Pemeriksaan Kehamilan': item.EMR.counterAwalPemeriksaanKehamilan,
                'Jumlah EMR Diagnosa Bidan': item.EMR.counterDiagnosaBidan,
                'Jumlah EMR Diagnosa': item.EMR.counterDiagnosa,
                'Jumlah EMR Catatan Dokter': item.EMR.counterDoctorNote,
                'Jumlah EMR File': item.EMR.counterFile,
                'Jumlah EMR Hasil Lab': item.EMR.counterHasilLab,
                'Jumlah EMR Imunisasi': item.EMR.counterImunisasi,
                'Jumlah EMR Kegiatan Kelompok': item.EMR.counterKegiatanKelompok,
                'Jumlah EMR Kesimpulan': item.EMR.counterKesimpulan,
                'Jumlah EMR MCU Plan': item.EMR.counterMcuPlan,
                'Jumlah EMR Kamar Tindakan': item.EMR.counterKmrProcedure,
                'Jumlah EMR Catatan Bidan': item.EMR.counterNurseNote,
                'Jumlah EMR Obgyn': item.EMR.counterObgyn,
                'Jumlah EMR Odontogram': item.EMR.counterOdontogram,
                'Jumlah EMR Order': item.EMR.counterOrder,
                'Jumlah EMR Order Assesment': item.EMR.counterOrderAssesment,
                'Jumlah EMR Catatan Lainnya': item.EMR.counterOtherNote,
                'Jumlah EMR Resep': item.EMR.counterPrescription,
                'Jumlah EMR Riwayat Kelahiran': item.EMR.counterRiwayatKelahiran,
                'Jumlah EMR Rujukan Luar Assist': item.EMR.counterRujukEksternalAssist,
                'Jumlah EMR Scour Prescription': item.EMR.counterScourPrescription,
                'Jumlah EMR Vital Sign': item.EMR.counterVitalSign,
            }));
            exportXL('Data Pengguna Produk Berbayar', hasil);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Grid container>
            <Grid container spacing={1} item xs={12} style={{ marginTop: '30px' }}>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Penggunaan Produk</Typography>
                    <PInput
                        value='Produk Berbayar'
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography style={{ visibility: 'hidden' }}>Export Data</Typography>
                    <PBtn
                        style={{
                            marginTop: 8,
                        }}
                        onClick={handleExportToExcel}
                    >
                        <FontAwesomeIcon icon={faPrint} style={{ marginRight: 5, marginBottom: 2 }}/>Export
                    </PBtn>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Filter Tanggal Mulai Dari</Typography>
                    <PDatePicker
                        value={filterData.dateStart}
                        onChange={handleChangeStartDate}
                        format='dd/MM/yyyy'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Sampai Tanggal</Typography>
                    <PDatePicker
                        value={filterData.dateEnd}
                        onChange={handleChangeEndDate}
                        format='dd/MM/yyyy'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <ProdukBerbayarTable
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </Grid>
            <Grid container justify='flex-end' item xs={12} style={{ marginTop: 20 }}>
                <PBtn
                    inverted
                    dropShadow
                    onClick={()=> setIsPage(0)}
                >
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{ marginRight: 3 }}/>Kembali
                </PBtn>
            </Grid>
        </Grid>
    );
};

const mapState = ({ laporan }) => ({
    retensionPaidProductCounter: laporan.retensionPaidProductCounter,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(ProdukBerbayar);
