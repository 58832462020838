import React from 'react';

import {DatePicker} from "@material-ui/pickers";

export default function ({ ...props }) {
    return (
        <DatePicker
            margin="dense"
            inputVariant="outlined"
            {...props}
        />
    );
}
