import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {dispatch} from "../../../store";

import {setFreeze} from "../../../api/client";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
});

const OverdueFreezeForm = ({ onClose, selectedOverdueList, fetchOverdueList, selectedKlinikFreeList, fetchKlinikFreeList }) => {
    const classes = styles();
    const isKlinikFree = selectedKlinikFreeList._id !== 'undefined';
    const faskesName = isKlinikFree ? selectedKlinikFreeList.nama : selectedOverdueList.nama;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [emailPIC, setEmailPIC] = useState(
        selectedOverdueList._id
            ? selectedOverdueList.Accounts[0].Confidentials.email
            : selectedKlinikFreeList._id
            ? selectedKlinikFreeList.Accounts.email
            : ''
    );
    const reason = selectedKlinikFreeList._id ? 'Masa Penggunaan Produk Clinica Free Sudah Habis' : 'Overdue';
    const [isError, setIsError] = useState(false);
    const validateEmail = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    function handleRegex(e) {
        if(e.target.value === '' || validateEmail.test(e.target.value)) {
            setIsError(false);
            setEmailPIC(e.target.value);
        } else {
            setIsError(true);
            setEmailPIC(e.target.value);
        }
    }

    async function handleSetFreeze() {
        try {
            const payload = {
                hospitalId: isKlinikFree ? selectedKlinikFreeList.KConfig.hospitalId : selectedOverdueList.KConfig.hospitalId,
                emailPICKlinik: emailPIC,
                alasan: reason,
            };
            setIsSubmitting(true);
            await setFreeze(payload);
            dispatch.snackbar.showSuccess("Berhasil freeze akun");
            setIsSubmitting(false);
            onClose();
            if(isKlinikFree) {
                fetchKlinikFreeList();
            } else {
                fetchOverdueList();
            }
        } catch (e) {
            console.error(e);
            dispatch.snackbar.showError("Gagal freeze akun");
            setIsSubmitting(false);
        }
    }

    return (
        <Grid container>
            <Grid container justify="center" item xs={12} style={{ marginBottom: 30 }}>
                <Typography className={classes.label}>{`Freeze akun ${faskesName}`}</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container>
                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <PInput
                            label="Email PIC Klinik"
                            value={emailPIC}
                            onChange={(e)=> handleRegex(e)}
                            helperText={isError ? "Email is not valid!" : null}
                            error={isError}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PInput
                            label="Alasan"
                            value={reason}
                            disabled
                            multiline
                            rows={6}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={onClose}
                >
                    Batalkan
                </PBtn>
                <PBtn
                    disabled={emailPIC === '' || isSubmitting || isError}
                    onClick={()=> handleSetFreeze()}
                >
                    {!isSubmitting ? 'Freeze' : <FontAwesomeIcon icon={faSpinner}/>}
                </PBtn>
            </Grid>
        </Grid>
    );
};

const mapState = ({ client }) => ({
    selectedOverdueList: client.selectedOverdueList,
    selectedKlinikFreeList: client.selectedKlinikFreeList,
});

const mapDispatch = ({ client: { fetchOverdueList, fetchKlinikFreeList } }) => ({
    fetchOverdueList,
    fetchKlinikFreeList,
});

export default connect(mapState, mapDispatch)(OverdueFreezeForm);
