import {searchFaskesByName, searchFaskesByNamev2, searchAssistFaskesByName} from "../api/faskes";
import {searchFaskesByKeyword, searchFaskesInvoicesByKeyword} from "../api/client";

export async function faskesFetcherByNamev2(keyword) {
    try {
        const result = await searchFaskesByNamev2(keyword);
        return result.data.map($item => ({
            label: $item.name,
            value: $item,
            highlighter: 'name',
            data: $item,
        }));
    } catch (err) {
        console.error(err);
        return [];
    }
}

export async function assistFaskesFetcherByName(keyword) {
    try {
        const result = await searchAssistFaskesByName(keyword);
        return result.data.map(item => ({
            label: item.nama,
            value: item,
            highlighter: 'nama',
            data: item,
        }));
    } catch (e) {
        console.error(e);
        return [];
    }
}

export default async function faskesFetcherByName(keyword) {
    try {
        const result = await searchFaskesByName(keyword);
        return result.data.map($item => ({
            label: $item.name,
            value: $item,
            highlighter: 'name',
            data: $item,
        }));
    } catch (err) {
        console.error(err);
        return [];
    }
}

export async function faskesFetcherByKeyword(keyword) {
    try {
        const result = await searchFaskesByKeyword(keyword);
        return result.data.map($item => ({
            label: $item.nama,
            value: $item,
            highlighter: 'nama',
            data: $item,
        }));
    } catch (e) {
        console.error(e);
        return [];
    }
}

export async function faskesInvoicesFetcherByKeyword(keyword) {
    try {
        const result = await searchFaskesInvoicesByKeyword(keyword);
        return result.data.map($item => ({
            label: $item.nama,
            value: $item,
            highlighter: 'nama',
            data: $item,
        }));
    } catch (e) {
        console.error(e);
        return [];
    }
}
