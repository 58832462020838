import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";

import BlockedItem from "./BlockedItem";

const styles = makeStyles({
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    }
});

const BlockedTable =
    ({ fetchBlockedList,
         setIsOpenAddNew,
         blockedListCounter,
         fetchBlockedListFilter,
         isFilter,
         filterData,
         setFilterData,
         pageFilter,
     }) => {
    const classes = styles();
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(5);

    useEffect(() => {
        if(isFilter === true){
            setPage(pageFilter);
            handleRenderBlockedFilter();
        } else {
            setPage(pageFilter);
            fetchBlockedList();
        }
    }, [fetchBlockedList, blockedListCounter, fetchBlockedListFilter, isFilter, pageFilter]);

    const handleRenderBlockedFilter = () => {
        setFilterData(filterData => ({
            ...filterData, condition: false
        }));
        fetchBlockedListFilter(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        if(isFilter === true){
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit
            }));
            fetchBlockedListFilter(filterData);
        } else {
            fetchBlockedList(skipLimit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        const newRowsPerpage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerpage,
        };
        if(isFilter === true){
            filterData.skip = 0;
            filterData.limit = newRowsPerpage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit
            }));
            fetchBlockedListFilter(filterData);
        } else{
            fetchBlockedList(skipLimit);
        }
        setRowsPerPage(newRowsPerpage);
        setPage(0);
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableCellHeader}>
                        <span>Tanggal Block</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Nama Klinik</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>PIC Klinik</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Keterangan</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Status</span>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                <BlockedItem setIsOpenAddNew={setIsOpenAddNew} fetchBlockedList={fetchBlockedList} page={page} rowsPerPage={rowsPerPage} />
            </TableBody>

            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        colSpan={6}
                        count={blockedListCounter}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage} />
                </TableRow>
            </TableFooter>
        </Table>
    );
};

const mapState = ({ activity }) => ({
    blockedListCounter: activity.blockedListCounter,
});

const mapDispatch = ({ activity: { fetchBlockedList, fetchBlockedListFilter } }) => ({
    fetchBlockedList,
    fetchBlockedListFilter,
});

export default connect(mapState, mapDispatch)(BlockedTable);
