import React, {useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import BuktiBayarItem from "./BuktiBayarItem";

const styles = makeStyles({
    tableWrapper: {
        overflow: 'auto',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
});

const BuktiBayarTable = ({ receiptsListCounter, fetchReceiptsList, fetchReceiptsListFilter, isFilter, filterData, setFilterData }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        if(isFilter === true) {
            setPage(0);
            handleRenderReceiptsListFilter();
        } else {
            setPage(0);
            setRowsPerPage(5);
            fetchReceiptsList();
        }
    }, [fetchReceiptsList, fetchReceiptsListFilter, isFilter]);

    const handleRenderReceiptsListFilter = () => {
        setFilterData(prevState => ({
            ...prevState, condition: false,
        }));
        fetchReceiptsListFilter(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        if(isFilter === true) {
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchReceiptsListFilter(filterData);
        } else {
            fetchReceiptsList(skipLimit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerPage,
        };
        if(isFilter === true) {
            filterData.skip = 0;
            filterData.limit = newRowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchReceiptsListFilter(filterData);
        } else {
            fetchReceiptsList(skipLimit);
        }
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    return (
        <div>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 90 }}>
                                No. Bukti Bayar
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Nama Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Tanggal Transfer
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Metode Pembayaran
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 90 }}>
                                Transfer Atas Nama
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Jumlah
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                PIC Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                No. Handphone
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 69 }}>
                                Email PIC Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 90 }}>
                                File Bukti Transfer
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 90 }}>
                                Link Detail Invoice
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <BuktiBayarItem page={page} rowsPerPage={rowsPerPage}/>
                    </TableBody>
                </Table>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={receiptsListCounter}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

const mapState = ({ client }) => ({
    receiptsListCounter: client.receiptsListCounter,
});

const mapDispatch = ({ client: { fetchReceiptsList, fetchReceiptsListFilter } }) => ({
    fetchReceiptsList,
    fetchReceiptsListFilter,
});

export default connect(mapState, mapDispatch)(BuktiBayarTable);

