import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import NumberFormat from "react-number-format";

import {makeStyles} from "@material-ui/core";

import {getNearestBill} from "../../api/dashboard";

const styles = makeStyles({
    wrap: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        maxHeight: 360,
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#15162d',
    },
    borderTitle: {
        width: 40,
        float: 'left',
        background: '#2b64af',
        border: '2px solid #2b64af',
    },
    tableWrapper: {
        overflowY: 'auto',
        height: 275,
        width: '100%',
    },
    tableCellBody: {
        paddingLeft: 0,
        fontFamily: 'Nunito',
        fontSize: '16px',
    },
    labelFaskes: {
        fontWeight: 'bold',
        color: '#15162d',
    },
    labelPIC: {
        color: '#6e7d91',
    },
    labelKeterangan: {
        color: '#2b64af',
    },
    labelJumlah: {
        color: '#e94d62',
    },
});

const NearestBill = () => {
    const classes = styles();
    const [tagihanTerdekat, setTagihanTerdekat] = useState([]);

    useEffect(()=> {
        (async function () {
            const result = await getNearestBill();
            setTagihanTerdekat(result.data);
        })();
    }, []);

    return (
        <Grid item xs={4} className={classes.wrap}>
            <Typography className={classes.title}>
                Tagihan Terdekat
            </Typography>
            <hr className={classes.borderTitle}/>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableBody>
                        {
                            tagihanTerdekat.length === 0
                                ? <TableRow>
                                    <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                        <Typography>Tidak Ada</Typography>
                                    </TableCell>
                                </TableRow>
                                : tagihanTerdekat.map((item, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell className={classes.tableCellBody}>
                                            <Typography className={classes.labelFaskes}>{item.faskesName}</Typography>
                                            <Typography className={classes.labelPIC}>{item.picKlinik}</Typography>
                                            <Typography className={classes.labelKeterangan}>{`Cicilan ${item.termin} dengan nomor pembayaran ${item.invoiceNo}`}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody} style={{ minWidth: 120 }}>
                                            <Typography className={classes.labelJumlah}>
                                                <NumberFormat
                                                    displayType="text"
                                                    value={item.value}
                                                    prefix="Rp. "
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </div>
        </Grid>
    );
};

export default NearestBill;
