import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";

import {connect} from "react-redux";
import {dispatch} from "../../../store";

import {addNewKompetitor} from "../../../api/kompetitor";

import AddNew from "../AddNew";
import KompetitorItem from "./KompetitorItem";

const Kompetitor = ({ fetchKompetitors }) => {

    useEffect(() => {
        fetchKompetitors();
    });

    const handleSubmit = async (kompetitorName, currentUser, setIsSubmitting, setKompetitorName) => {
        const trimmedValue = kompetitorName.trim();

        if(trimmedValue === '') {
            dispatch.snackbar.showError("Nama kompetitor harus diisi!");
            return;
        }

        try {
            setIsSubmitting(true);
            await addNewKompetitor(trimmedValue, currentUser.id);
            setKompetitorName('');
            setIsSubmitting(false);
            dispatch.snackbar.showSuccess("Berhasil menambahkan kompetitor");
            fetchKompetitors();
        } catch (e) {
            console.error(e);
            setIsSubmitting(false);
            dispatch.snackbar.showError("Gagal menambahkan kompetitor");
        }
    };

    return (
        <Grid container>
            <AddNew handler={handleSubmit} placeholder={"Tambah kompetitor disini"} buttonLabel={"Tambah Kompetitor"}/>

            <KompetitorItem/>
        </Grid>
    );
};

const mapState = null;

const mapDispatch = ({ kompetitor: { fetchKompetitors } }) => ({
    fetchKompetitors,
});

export default connect(mapState, mapDispatch)(Kompetitor);
