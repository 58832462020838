import React from 'react';
import {connect} from 'react-redux';
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";

import BlockedRow from './BlockedRow';
import TableCell from "@material-ui/core/TableCell";

const BlockedItem = ({ blockedList, setIsOpenAddNew, fetchBlockedList, isFetchingBlockedList, page, rowsPerPage }) => {

    return (
        <React.Fragment>
            {
                isFetchingBlockedList === true
                    ? <TableRow>
                        <TableCell colSpan={6}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        </TableCell>
                    </TableRow>
                    : <React.Fragment>
                        {
                            blockedList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(blocked => (
                                <BlockedRow key={blocked.id} blocked={{...blocked}} fetchBlockedList={fetchBlockedList} />
                            ))
                        }
                    </React.Fragment>
            }
        </React.Fragment>
    );
};

const mapState = ({ activity }) => ({
    blockedList: activity.blockedList,
    isFetchingBlockedList: activity.isFetchingBlockedList,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(BlockedItem);
