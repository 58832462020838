import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Grid, makeStyles, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import { dispatch } from "../../store";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import logo from './../../assets/img/Logo.png';
// import history from "../../history";

import './login.css'

const useStyles = makeStyles(theme =>({
    root : {
        width: 500,
        backgroundColor: '#fff',
        padding: theme.spacing(3)
    }
}));

function Login({ handleLogin }) {
    const classes = useStyles();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
        const validatedUsername = username.trim();
        const validatedPassword = password.trim();

        if (validatedUsername === "") {
            dispatch.snackbar.showError("Username harus diisi");
            return;
        }
        if (validatedPassword === "") {
            dispatch.snackbar.showError("Password harus diisi");
            return;
        }

        try {
            setIsLoggingIn(true);
            const payload = {
                username: validatedUsername,
                password: validatedPassword
            };
            await handleLogin(payload);
        } catch (err) {
            console.dir(err);
            dispatch.snackbar.showError("Login gagal, periksa kombinasi username dan password anda");
            setIsLoggingIn(false);
        }
    }

    function handleChange(handler) {
        return (e) => {
            const { value } = e.target;
            handler(value);
        }
    }
    const [isPasswordShow, setIsPasswordShow] = useState(false);
    function togglePasswordVisibility() {
        setIsPasswordShow(!isPasswordShow);
    }

    return(
        <div className="login">
            <Grid container className={classes.root} >

                <Grid item xs={12}>
                    <Grid container justify="center">
                        <div className="login-header">
                            <Typography variant="h5">Login to Panela</Typography>
                            <img alt="logo-assist" src={logo} className="logo" />
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        style={{ width: '100%' }}
                                        label="Username"
                                        type="text"
                                        onChange={handleChange(setUsername)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        style={{ width: '100%' }}
                                        label="Password"
                                        type={(isPasswordShow) ? "text" : "password"}
                                        onChange={handleChange(setPassword)}
                                    />
                                    <FontAwesomeIcon
                                        icon={(isPasswordShow) ? faEyeSlash : faEye}
                                        className="toggle-icon-eye"
                                        onClick={togglePasswordVisibility}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        disabled={isLoggingIn}
                                        type="submit"
                                        className="btn-login"
                                        fullWidth
                                    >
                                        {
                                            isLoggingIn
                                                ? <CircularProgress
                                                    style={{ color: 'white' }}
                                                    size={24}
                                                />
                                                : "Login"
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
                {/*<Grid item xs={12} md={6}>*/}
                {/*    <Grid container justify="center">*/}
                {/*        <div className="img">*/}

                {/*        </div>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Grid>
        </div>
    )
}

const mapState = null;

const mapDispatch = ({ auth: { handleLogin } }) => ({
    handleLogin,
});

export default connect(mapState, mapDispatch)(Login)
