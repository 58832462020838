import {
    getFaskesList,
    countFaskesList,
    getFaskesListByFilter,
    countFaskesListByFilter,
    getSingleFaskesList,
    addNewLeads,
    updateLeads,
    deleteLeads,
    getDataLog,
    countDataLog,
} from "../../api/faskes";

import {
    addNewActivity,
    addNewPenolakan,
} from "../../api/activity";

function populateLeadsPayload(data, isNew = true) {
    const payload = {
        picId: data.picId,
        assistPIC: data.assistPIC,
        contactStatus: data.contactStatus,
        leadsContact: data.leadsContact,
        leadsInfo: data.leadsInfo,
        event: data.event,
        isReferred: data.isReferred,
        referredName: data.referredName,
        PIC: data.PIC,
        name: data.name,
        type: data.type,
        email: data.email,
        phoneNumber: data.phoneNumber,
        links: {
            googleMap: data.googleMap,
            facebook: data.facebook,
            instagram: data.instagram,
            website: data.website,
        },
        address: {
            street: data.street,
            province: data.province,
            city: data.city,
            district: data.district,
            postCode: data.postCode,
        },
        operationalStatus: data.operationalStatus,
        MCUAndLabServices: data.MCUAndLabServices,
        isJejaring: data.isJejaring,
        numberOfBranches: data.numberOfBranches,
        isCampaignRunning: data.isCampaignRunning,
        campaignName: data.campaignName,
        isCompetitorPotential: data.isCompetitorPotential,
        competitorName: data.competitorName,
        competitorLevel: data.competitorLevel,
        url: data.url,
        isUnknown: data.isUnknown,
        isAlreadyUsingSystem: data.isAlreadyUsingSystem,
        usedSystems: data.usedSystems,
        isOwnServerExist: data.isOwnServerExist,
        isBPJS: data.isBPJS,
        isPharmacyExist: data.isPharmacyExist,
        neededFeature: data.neededFeature,
        hotButton: data.hotButton,
        reasonToUsingSystem: data.reasonToUsingSystem,
        systemImplementationPlan: data.systemImplementationPlan,
        note: data.note,
        hotnessLevel: data.hotnessLevel,
        closingProductTypePotential: data.closingProductTypePotential,
        closingProductDurationPotential: data.closingProductDurationPotential,
        closingProductValuePotential: data.closingProductValuePotential,
        isCustom: data.isCustom,
        dataCustom: data.dataCustom,
        numberOfVisits: data.numberOfVisits,
        capitationBPJS: data.capitationBPJS,
        isSubscribed: data.isSubscribed,
        recontact: data.recontact,
        useSystemNote: data.useSystemNote,
        useSystemDuration: data.useSystemDuration,
        useSystemStartDate: data.useSystemStartDate,
        useSystemEndDate: data.useSystemEndDate,
        isAnyAnomaly: data.isAnyAnomaly,
        resubscribeCounter: data.resubscribeCounter,
        status: data.status,
        rejectionCategory: data.rejectionCategory,
        lastContactDate: data.lastContactDate,
        cycle: data.cycle,
        dataActivity: data.dataActivity,
        rejectList: data.rejectList,
    };

    if(isNew) {
        payload.createdAt = new Date();
        payload.createdId = data.createdId;
    } else {
        payload.updatedAt = new Date();
        payload.updatedId = data.updatedId;
    }
    return payload;
}

export const leads = {
    state: {
        isFetchingFaskesList: false,
        isFetchingSingleFaskesList: false,
        isFetchingDataLog: false,
        faskesList: [],
        dataLog: [],
        singleFaskesList: {},
        faskesListCounter: 0,
        dataLogCounter: 0,
        selectedFaskesFilter: {},
    },
    reducers: {
        updateState(state, payload){
            const { statName, value } = payload;
            return {
                ...state,
                [statName]: value,
            };
        },
        updateFaskesList(state, payload){
            const { value, skipLimit } = payload;

            if(!skipLimit){
                return {
                    ...state,
                    faskesList: value,
                };
            }

            const { faskesList } = state;
            faskesList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                faskesList: [...faskesList],
            };
        },
        updateDataLog(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    dataLog: value,
                };
            }

            const { dataLog } = state;
            dataLog.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                dataLog: [...dataLog],
            };
        },
        updateFaskesListFilter(state, payload){
            const { value, payloads } = payload;

            if(payloads.condition) {
                return {
                    ...state,
                    faskesList: value,
                };
            }

            const { faskesList } = state;
            faskesList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                faskesList: [...faskesList],
            };
        },
        updateSelectedFaskesFilter(state, payload) {
            return {
                ...state,
                selectedFaskesFilter: payload,
            };
        },
        updateSingleFaskesList(state, payload) {
            return {
                ...state,
                singleFaskesList: payload,
            };
        },
    },
    effects: dispatch => ({
        async addLeads(payload) {
            try {
                const data = populateLeadsPayload(payload);

                if(payload.prospectId) {
                    data.prospectId = payload.prospectId;
                }

                const result = await addNewLeads(data);

                dispatch.snackbar.showSuccess('Berhasil menambahkan data leads');

                return result.data.id;
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal menambahkan data leads');
            }
        },
        async editLeads(payload) {
            try {
                const data = populateLeadsPayload(payload, false);

                await updateLeads(payload.id, data);

                dispatch.snackbar.showSuccess('Berhasil memperbaharui data leads');
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal memperbaharui data leads');
            }
        },
        async removeLeads(leadsId) {
            try {
                const result = await deleteLeads(leadsId);

                if(result.data.count) {
                    dispatch.snackbar.showSuccess('Berhasil menghapus leads');
                } else {
                    dispatch.snackbar.showError('Gagal menghapus data leads');
                }
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal menghapus data leads');
            }
        },
        async addActivity(payload) {
            try {
                await addNewActivity(payload);

                dispatch.snackbar.showSuccess('Berhasil menambahkan aktivitas baru');
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal menambahkan aktivitas baru');
            }
        },
        async addActivityRejection(payload) {
            try {
                await addNewPenolakan(payload);

                dispatch.snackbar.showSuccess('Berhasil menambahkan aktivitas penolakan');
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal menambahkan aktivitas penolakan');
            }
        },
        async fetchFaskesList(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingFaskesList',
                    value: true,
                });

                const result = await getFaskesList(skipLimit);
                const resultCounter = await countFaskesList();

                this.updateFaskesList({
                    value: result.data,
                    skipLimit,
                });
                this.updateState({
                    statName: 'isFetchingFaskesList',
                    value: false,
                });
                this.updateState({
                    statName: 'faskesListCounter',
                    value: resultCounter.data.count,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar leads");
                this.updateState({
                    statName: 'isFetchingFaskesList',
                    value: false,
                });
            }
        },
        async fetchFaskesListFilter(payloads) {
            try {
                this.updateState({
                    statName: 'isFetchingFaskesList',
                    value: true,
                });

                const result = await getFaskesListByFilter(payloads);
                const resultCounter = await countFaskesListByFilter(payloads);

                this.updateFaskesListFilter({
                    value: result.data,
                    payloads,
                });
                this.updateState({
                    statName: 'isFetchingFaskesList',
                    value: false,
                });
                this.updateState({
                    statName: 'faskesListCounter',
                    value: resultCounter.data,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar leads");
                this.updateState({
                    statName: 'isFetchingFaskesList',
                    value: false,
                });
            }
        },
        async fetchSingleFaskesList(faskesId) {
            try {
                this.updateState({
                    statName: 'isFetchingSingleFaskesList',
                    value: true,
                });

                const result = await getSingleFaskesList(faskesId);

                this.updateSingleFaskesList(result.data);

                this.updateState({
                    statName: 'isFetchingSingleFaskesList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan detail leads');
                this.updateState({
                    statName: 'isFetchingSingleFaskesList',
                    value: false,
                });
            }
        },
        async fetchDataLog(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingDataLog',
                    value: true,
                });

                const result = await getDataLog(skipLimit);
                const resultCounter = await countDataLog(skipLimit);

                this.updateDataLog({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'dataLogCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingDataLog',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan data log');
                this.updateState({
                    statName: 'isFetchingDataLog',
                    value: false,
                });
            }
        },
    }),
};
