import {
    getProspect,
    countProspect,
    getProspectByFilter,
    countProspectByFilter,
    getReferralList,
    countReferralList,
} from "../../api/prospect";

export const prospect = {
    state: {
        isFetchingProspectList: false,
        isFetchingReferralList: false,
        prospectList: [],
        referralList: [],
        prospectListCounter: 0,
        referralListCounter: 0,
        selectedProspect: {},
        selectedProspectFilter: {},
        selectedReferral: {},
        selectedReferralFilter: {},
    },
    reducers: {
        updateState(state, payload) {
            const { statName, value } = payload;
            return {
                ...state,
                [statName]: value,
            };
        },
        updateProspectList(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    prospectList: value,
                };
            }

            const { prospectList } = state;
            prospectList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                prospectList: [...prospectList],
            };
        },
        updateReferralList(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    referralList: value,
                };
            }

            const { referralList } = state;
            referralList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                referralList: [...referralList],
            };
        },
        updateSelectedProspect(state, payload) {
            return {
                ...state,
                selectedProspect: payload,
            };
        },
        updateProspectListFilter(state, payload) {
            const { value, payloads } = payload;

            if(payloads.condition){
                return{
                    ...state,
                    prospectList: value,
                };
            }

            const { prospectList } = state;
            prospectList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                prospectList: [...prospectList],
            };
        },
        updateSelectedProspectFilter(state, payload) {
            return {
                ...state,
                selectedProspectFilter: payload,
            };
        },
        updateSelectedReferral(state, payload) {
            return {
                ...state,
                selectedReferral: payload,
            };
        },
        updateSelectedReferralFilter(state, payload) {
            return {
                ...state,
                selectedReferralFilter: payload,
            };
        },
    },
    effects: dispatch => ({
        async fetchProspect(skipLimit) {
            try{
                this.updateState({
                    statName: 'isFetchingProspectList',
                    value: true,
                });

                const result = await getProspect(skipLimit);
                const resultCounter = await countProspect();

                this.updateProspectList({
                    value: result.data,
                    skipLimit,
                });
                this.updateState({
                    statName: 'isFetchingProspectList',
                    value: false,
                });
                this.updateState({
                    statName: 'prospectListCounter',
                    value: resultCounter.data.count,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan daftar data');
                this.updateState({
                    statName: 'isFetchingProspectList',
                    value: false,
                });
            }
        },
        async fetchProspectByFilter(payloads) {
            try{
                this.updateState({
                    statName: 'isFetchingProspectList',
                    value: true,
                });

                const result = await getProspectByFilter(payloads);
                const resultCounter = await countProspectByFilter(payloads);

                this.updateProspectListFilter({
                    value: result.data,
                    payloads,
                });
                this.updateState({
                    statName: 'prospectListCounter',
                    value: resultCounter.data,
                });
                this.updateState({
                    statName: 'isFetchingProspectList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar data");
                this.updateState({
                    statName: 'isFetchingProspectList',
                    value: false,
                });
            }
        },
        async fetchReferral(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingReferralList',
                    value: true,
                });

                const result = await getReferralList(skipLimit);
                const resultCounter = await countReferralList(skipLimit);

                this.updateReferralList({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'referralListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingReferralList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan daftar referral');
                this.updateState({
                    statName: 'isFetchingReferralList',
                    value: false,
                });
            }
        }
    }),
};
