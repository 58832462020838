import React from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import idLocale from 'date-fns/locale/id';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {makeStyles} from "@material-ui/core/styles";
import logo from './../../../assets/img/logo-main.png';

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
    logo: {
        width: '100%',
    }
});

const titleWidth = 100;

const PrintContent = ({ selectedOffer, showTermins }) => {
    const tanggalPenawaran = new Date(selectedOffer.offerDate);
    const classes = styles();

    return (
        <Grid container id="pdf-container" style={{ paddingLeft: 56, paddingRight: 56 }}>
            <Grid item xs={12} style={{ borderBottom: '2px solid black' }}>
                <Grid container style={{ paddingBottom: 10 }}>
                    <Grid item xs={3}>
                        <img src={logo} alt="Logo Assist.id" className={classes.logo} />
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container justify="flex-end">
                            <Typography style={{ fontSize: 15 }}>
                                <strong>Assist Indonesia</strong> PT. Teknologi Media Medika
                            </Typography>
                            <Typography style={{ fontSize: 15 }}>
                                Jl. Palaraya No. 325, Kec. Marpoyan Damai, Pekanbaru
                            </Typography>
                            <Typography style={{ fontSize: 15 }}>
                                WA: 0813-1000-0949 | Website: https://assist.id
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 10 }}>
                <Grid container>
                    <Grid item xs={6}>
                        <div>
                            <Typography style={{ display: 'flex' }}>
                                <span style={{ display: 'inline-block', width: titleWidth }}>Nomor</span>
                                <span style={{ marginRight: 8 }}>:</span>
                                <span style={{ flex: 1 }}>{selectedOffer.letterNumber}</span>
                            </Typography>
                            <Typography style={{ display: 'flex' }}>
                                <span style={{ display: 'inline-block', width: titleWidth }}>Perihal</span>
                                <span style={{ marginRight: 8 }}>:</span>
                                <span style={{ flex: 1 }}>Proposal Penawaran</span>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end">
                            <Typography>
                                Pekanbaru, {format(tanggalPenawaran, 'd MMMM yyyy', { locale: idLocale })}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: 40 }}>
                <Typography>
                    Yth. {selectedOffer.healthFacilityPic.namepic}
                </Typography>

                <Typography>
                    {selectedOffer.HealthFacility.name}
                </Typography>

                <Typography>
                    {`${selectedOffer.HealthFacility.address.street}, ${selectedOffer.HealthFacility.address.city}`}
                </Typography>

                <Typography style={{ paddingTop: 24 }}>
                    Dengan Hormat,
                </Typography>

                <Typography>
                    Berikut kami sampaikan proposal sistem informasi Assist.id
                </Typography>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: 32 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader}>Produk</TableCell>
                            <TableCell className={classes.tableCellHeader}>Lama Berlangganan</TableCell>
                            <TableCell className={classes.tableCellHeader}>Harga (Rp)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            selectedOffer.offerProductDetail.products.map((produk, idx) => (
                                <React.Fragment key={idx}>
                                    <TableRow>
                                        <TableCell className={classes.tableCellBody}>
                                            <p>{produk.name}</p>
                                            {
                                                produk.features.length > 0
                                                    ? <ul style={{ listStyle: 'none', paddingLeft: 32 }}>
                                                        {
                                                            produk.features.map((fitur, i) => (
                                                                <li style={{ paddingBottom: 8, paddingLeft: 0, listStyleType: 'none'}} key={i}>
                                                                    <span style={{ marginRight: 16 }}>-</span>{fitur}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul> : null
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                            {produk.subscription}
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody} style={{ textAlign: 'right '}}>
                                            <NumberFormat
                                                displayType="text"
                                                value={produk.cost}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {
                                        produk.discount > 0 ?
                                            <TableRow>
                                                <TableCell colSpan={2} style={{ textAlign: 'right' }} className={classes.tableCellBody}>
                                                    <strong>Diskon</strong>
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }} className={classes.tableCellBody}>
                                                    <NumberFormat
                                                        displayType="text"
                                                        value={produk.discount}
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            : null
                                    }
                                </React.Fragment>
                            ))
                        }
                        <TableRow>
                            <TableCell colSpan={2} style={{ textAlign: 'right' }} className={classes.tableCellBody}>
                                <strong>Grand Total</strong>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }} className={classes.tableCellBody}>
                                <strong>
                                    <NumberFormat
                                        displayType="text"
                                        value={selectedOffer.offerProductDetail.grandTotal}
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                </strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Typography style={{ fontWeight: 'bold', paddingTop: 14, fontStyle: 'italic' }}>
                {`*Harga berlaku untuk pembayaran hingga ${format(new Date(selectedOffer.paymentDate), 'dd MMMM yyyy', { locale: idLocale})}.`}
            </Typography>

            <Grid style={{ paddingLeft: 64, paddingRight: 64, paddingTop: 32 }} item xs={12}>
                {
                    showTermins
                        ? selectedOffer.terminList.length === 0
                            ? <p style={{ textAlign: 'center' }}></p>
                            : <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCellHeader}>Termin</TableCell>
                                        <TableCell className={classes.tableCellHeader}>Tanggal</TableCell>
                                        <TableCell className={classes.tableCellHeader}>Jumlah (Rp)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        selectedOffer.terminList.map(($termin, $idxt) => (
                                            <TableRow key={`idxt-${$idxt}`}>
                                                <TableCell className={classes.tableCellBody}>{`Termin ${$idxt + 1} ${$idxt === 0 ? 'DP' : ''}`}</TableCell>
                                                <TableCell className={classes.tableCellBody}>
                                                    {format(new Date($termin.tanggal), 'dd MMMM yyyy', { locale: idLocale })}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right' }} className={classes.tableCellBody}>
                                                    <NumberFormat
                                                        displayType="text"
                                                        value={$termin.jumlah}
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        : null
                }
            </Grid>

            <Grid container style={{ marginTop: 38 }} item xs={12}>
                <div>
                    <p>Untuk pembayaran dapat ditransfer melalui rekening :</p>
                    <div>
                        <p style={{ display: 'flex' }}>
                            <span style={{ width: 200 }}>Nama Rekening</span>
                            <span>: <strong>PT. Teknologi Media Medika</strong></span>
                        </p>
                        <p style={{ display: 'flex' }}>
                            <span style={{ width: 200 }}>No Rekening</span>
                            <span>: <strong>108-00-1624193-8</strong></span>
                        </p>
                        <p style={{ display: 'flex' }}>
                            <span style={{ width: 200 }}>Nama Bank</span>
                            <span>: <strong>Bank Mandiri</strong></span>
                        </p>
                        <p style={{ display: 'flex' }}>
                            <span style={{ width: 200 }}>Konfirmasi</span>
                            <span>: <strong>WA 0813-1000-0949 / Atikah@assist.id</strong></span>
                        </p>
                        <p style={{ paddingTop: 15 }}>
                            Demikian detail biaya yang kami sampaikan, terimakasih atas perhatiannya.
                        </p>
                        <p style={{ paddingTop: 30 }}>
                            <span>Best Regards,</span>
                        </p>
                        <p>
                            <span>Assist.id</span>
                        </p>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
};

export default PrintContent;
