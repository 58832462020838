import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import filter from './../../../assets/img/filter-icon.png';
// import arrowUp from './../../../assets/img/arrow-up-icon.png';
import arrowDown from './../../../assets/img/arrow-down-icon.png';

import FreezeTable from "./FreezeTable";
import FreezeForm from "./FreezeForm";
import FreezeFilter from "./FreezeFilter";

const styles = makeStyles({
    search: {
        marginRight: 32,
        flex: 0.9,
    },
    filter: {
        float: 'right',
        display: 'inline-flex',
        padding: '15px 5px',
        marginTop: '-8px',
    },
    labelFilter: {
        fontWeight: 'bold',
        fontSize: '16px',
        fontFamily: 'Nunito',
        marginTop: '3px',
        marginRight: '10px',
    },
    imgFilter: {
        height: '40%',
        marginRight: '15px',
    },
    arrowFilter: {
        height: '40%',
        marginTop: '10px',
        cursor: 'pointer',
    },
    chip: {
        marginRight: 10,
    }
});

const Freeze = ({ updateSelectedFreeze, selectedFreezeFilter, updateSelectedFreezeFilter, fetchFreezeFilter }) => {
    const classes = styles();
    const [isOpenFreezeForm, setIsOpenFreezeForm] = useState(false);
    const [isOpenFilterForm, setIsOpenFilterForm] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        tanggalFreezeMulai: null,
        tanggalFreezeSelesai: null,
        nama: '',
        alasan: '',
        status: '',
        skip: 0,
        limit: 5,
    });
    const [pageFilter, setPageFilter] = useState(0);

    function handleCloseDialog(){
        setIsOpenFreezeForm(false);
        updateSelectedFreeze({});
    }

    const handleFilter = () => {
        setIsFilter(true);
        setPageFilter(0);
        fetchFreezeFilter(filterData);
        setIsOpenFilterForm(false);
        updateSelectedFreezeFilter(JSON.parse(JSON.stringify(filterData)));
    };

    const handleClearFilter = () => {
        setFilterData({
            tanggalFreezeMulai: null,
            tanggalFreezeSelesai: null,
            nama: '',
            alasan: '',
            status: '',
            skip: 0,
            limit: 5,
        });
        setPageFilter(0);
        setIsFilter(false);
        updateSelectedFreezeFilter({});
    };

    return(
        <Grid container>
            <Dialog open={isOpenFreezeForm} maxWidth="md" onClose={()=> handleCloseDialog()} fullWidth>
                <DialogContent>
                    <FreezeForm onClose={()=> handleCloseDialog()}/>
                </DialogContent>
            </Dialog>
            <Grid container item xs={12} style={{ marginTop: '44px'}}>
                <Grid container item xs={6}>
                    <PInput
                        className={classes.search}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputAdornment>
                        }}
                        placeholder='Cari klinik disini'
                    />
                    <PBtn onClick={()=> setIsOpenFreezeForm(true)}>Tambahkan</PBtn>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.filter}>
                        <img alt='filter' src={filter} className={classes.imgFilter}/>
                        <Typography className={classes.labelFilter}>Filter by</Typography>
                        <img
                            alt='arrow'
                            src={arrowDown}
                            className={classes.arrowFilter}
                            onClick={()=> setIsOpenFilterForm(true)}
                        />
                        <Dialog open={isOpenFilterForm} maxWidth="xs" onClose={()=> setIsOpenFilterForm(false)} fullWidth>
                            <DialogContent>
                                <FreezeFilter
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    startFilter={handleFilter}
                                    clearFilter={handleClearFilter}
                                />
                            </DialogContent>
                        </Dialog>
                    </div>
                </Grid>
            </Grid>
            {
                isFilter && <Grid item xs={12} style={{ marginTop: 30, dispaly: 'flex' }}>
                    {
                        selectedFreezeFilter.tanggalFreezeMulai !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Dari Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedFreezeFilter.tanggalFreezeSelesai !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Sampai Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedFreezeFilter.nama !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Nama Klinik"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedFreezeFilter.alasan !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Alasan"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedFreezeFilter.status !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Status"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                </Grid>
            }
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <FreezeTable
                    setIsOpenFreezeForm={setIsOpenFreezeForm}
                    isFilter={isFilter}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    pageFilter={pageFilter}
                />
            </Grid>
        </Grid>
    )
};

const mapState = ({ freeze }) => ({
    selectedFreezeFilter: freeze.selectedFreezeFilter,
});

const mapDispatch = ({ freeze: { updateSelectedFreeze, updateSelectedFreezeFilter, fetchFreezeFilter } }) => ({
    updateSelectedFreeze,
    updateSelectedFreezeFilter,
    fetchFreezeFilter,
});

export default connect(mapState, mapDispatch)(Freeze);
