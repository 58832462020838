import React from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {connect} from 'react-redux';
import PBtn from "../../share/PBtn";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import {deleteActivity} from "../../../api/activity";
import {dispatch} from "../../../store";

const DeleteActivityDialog = (
    {
        openDeleteConfirmation, selectedActivity, setOpenDeleteConfirmation, fetchActivities,
    }) => {
    function handleClose() {
        setOpenDeleteConfirmation(false);
    }

    async function handleDelete() {
        try {
            await deleteActivity(selectedActivity.id);
            dispatch.snackbar.showSuccess('Berhasil menghapus activity');
            fetchActivities();
            handleClose();
        } catch (err) {
            console.error(err);
            dispatch.snackbar.showError('Gagal menghapus activity');
        }
    }

    return (
        <Dialog open={openDeleteConfirmation} onClose={handleClose}>
            <DialogContent>
                <Typography
                    style={{
                        fontFamily: "Nunito",
                        fontSize: "18px",
                        color: "#15162d",
                        fontWeight: "600",
                    }}>Apakah anda yakin ingin menghapus aktivitas ini?</Typography>
            </DialogContent>
            <DialogActions style={{marginTop: '10px', marginRight: '15px'}}>
                <PBtn onClick={handleClose} inverted dropShadow>
                    Batal
                </PBtn>

                <PBtn onClick={handleDelete}>
                    Hapus
                </PBtn>
            </DialogActions>
        </Dialog>
    );
};

const mapState = ({ activity }) => ({
    openDeleteConfirmation: activity.openDeleteConfirmation,
    selectedActivity: activity.selectedActivity,
});

const mapDispatch = ({ activity: { setOpenDeleteConfirmation, fetchActivities } }) => ({
    setOpenDeleteConfirmation,
    fetchActivities,
});

export default connect(mapState, mapDispatch)(DeleteActivityDialog);
