import React from 'react';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#2b64af',
            main: '#2b64af',
            dark: '#2b64af',
            contrastText: '#2b64af',
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                background: 'white',
            },
            notchedOutline: {
                borderRadius: 6,
            },

        },
        MuiFormControl: {
            marginDense: {
                marginTop: 0,
                marginBottom: 0,
            },
        },
    },
});

export default function ({ ...props }) {
    return (
        <MuiThemeProvider theme={theme}>
            <TextField
                margin="dense"
                variant="outlined"
                {...props}
            />
        </MuiThemeProvider>
    );
}
