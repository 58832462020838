import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer} from "recharts";

import NumberFormat from "react-number-format";

import {makeStyles} from "@material-ui/core";

import {getRevenue} from "../../api/dashboard";

const styles = makeStyles({
    wrap: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        maxHeight: 360,
    },
    labelChart: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#15162d',
    },
    subLabelChart: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#54a651',
        marginBottom: 20,
    },
});

const ChartRevenue = () => {
    const classes = styles();
    const [totalRevenue, setTotalRevenue] = useState([]);
    const [sumTotalRevenue, setSumTotalRevenue] = useState(0);

    useEffect(()=> {
        (async function () {
            const result = await getRevenue();
            setTotalRevenue(result.data);
            let sum = 0;
            for(let i = 0; i < result.data.length; i++) {
                sum += result.data[i].revenue;
            }
            setSumTotalRevenue(sum);
        })();
    }, []);

    const dataFormatter = (number) => {
        if(number > 1000000000){
            return (number/1000000000).toString() + 'B';
        }else if(number > 1000000){
            return (number/1000000).toString() + 'M';
        }else if(number > 1000){
            return (number/1000).toString() + 'K';
        }else{
            return number.toString();
        }
    };

    return (
        <Grid item xs={12} className={classes.wrap}>
            <Typography className={classes.labelChart}>
                Total Revenue
            </Typography>
            <Typography className={classes.subLabelChart}>
                <NumberFormat
                    displayType="text"
                    value={sumTotalRevenue}
                    prefix="Rp. "
                    decimalSeparator=","
                    thousandSeparator="."
                />
            </Typography>
            <ResponsiveContainer width={'100%'} height={250}>
                <LineChart data={totalRevenue} margin={{ left: 5 }}>
                    <CartesianGrid strokeDasharray="5 5"/>
                    <XAxis dataKey="month"/>
                    <YAxis tickFormatter={dataFormatter}/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="revenue" stroke="#2b64af" activeDot={{ r: 8 }}/>
                </LineChart>
            </ResponsiveContainer>
        </Grid>
    );
};

export default ChartRevenue;
