import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleRight, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {dispatch} from "../../../store";
import {connect} from "react-redux";

import format from "date-fns/format";
import localeId from "date-fns/locale/id";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import {addReferral, updateReferral} from "../../../api/prospect";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
    wrapForm: {
        marginBottom: 20,
        marginTop: 30,
    },
    wrapAction: {
        marginBottom: 10,
    },
    wrapArrow: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '30%',
    },
    wrapBox: {
        backgroundColor: '#f5f6f8',
        borderRadius: 8,
        padding: 10,
    },
});

const ReferralForm = ({ onClose, selectedReferral, currentUser, fetchReferral }) => {
    const classes = styles();
    const isEdit = typeof selectedReferral.id !== 'undefined';
    const inputtedDate = isEdit ? new Date(selectedReferral.createdAt) : new Date();
    const [usname, setUsname] = useState(selectedReferral.usname ? selectedReferral.usname : '');
    const [uscompany, setUscompany] = useState(selectedReferral.uscompany ? selectedReferral.uscompany : '');
    const [referralname, setReferralname] = useState(selectedReferral.referralname ? selectedReferral.referralname : '');
    const [referralcompany, setReferralcompany] = useState(selectedReferral.referralcompany ? selectedReferral.referralcompany : '');
    const [message, setMessage] = useState(selectedReferral.message ? selectedReferral.message : '');
    const [isSubmitting, setIsSubmitting] = useState(false);

    async function handleSubmit() {
        const { snackbar } = dispatch;

        if(usname.trim() === '') {
            snackbar.showError('Silahkan isi field nama yang mereferralkan');
        } else if(uscompany.trim() === '') {
            snackbar.showError('Silahkan isi field instansi yang mereferralkan');
        } else if(referralname.trim() === '') {
            snackbar.showError('Silahkan isi field nama yang direferralkan');
        } else if(referralcompany.trim() === '') {
            snackbar.showError('Silahkan isi field instansi yang direferralkan');
        } else {
            if(!isEdit) {
                try {
                    const url = window.location.pathname;
                    const payload = {
                        usname: usname.trim(),
                        uscompany: uscompany.trim(),
                        usemail: '',
                        usphonenumber: '',
                        referralname: referralname.trim(),
                        referralcompany: referralcompany.trim(),
                        referralemail: '',
                        referralphonenumber: '',
                        message: message.trim(),
                        url: url,
                        frombutton: 'cta panela',
                        createdAt: new Date(),
                        createdId: currentUser.id,
                    }

                    setIsSubmitting(true);
                    await addReferral(payload);
                    setIsSubmitting(false);
                    snackbar.showSuccess('Berhasil menambahkan daftar referral baru');
                    onClose();
                    fetchReferral();
                } catch (e) {
                    console.error(e);
                    setIsSubmitting(false);
                    snackbar.showError('Gagal menambahkan daftar referral baru');
                }
            } else {
                try {
                    const payload = {
                        usname: usname.trim(),
                        uscompany: uscompany.trim(),
                        referralname: referralname.trim(),
                        referralcompany: referralcompany.trim(),
                        message: message.trim(),
                        updatedAt: new Date(),
                        updatedId: currentUser.id,
                    };

                    setIsSubmitting(true);
                    await updateReferral(selectedReferral.id, payload);
                    setIsSubmitting(false);
                    snackbar.showSuccess('Berhasil mengupdate daftar referral');
                    onClose();
                    fetchReferral();
                } catch (e) {
                    console.error(e);
                    setIsSubmitting(false);
                    snackbar.showError('Gagal mengupdate daftar referral');
                }
            }
        }
    }

    return (
        <Grid container>
            <Grid container justify='flex-end' item xs={12}>
                <Typography>
                    {`Input in ${format(inputtedDate, "dd MMMM yyyy, HH:mm 'WIB'", {locale: localeId})}`}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.wrapForm}>
                <Grid container spacing={2}>
                    <Grid item md={5} style={{ paddingLeft: 15, marginBottom: 20 }}>
                        <Grid container spacing={2} className={classes.wrapBox}>
                            <Grid item md={12}>
                                <Typography className={classes.label}>
                                    Nama Yang Mereferralkan
                                </Typography>
                                <PInput
                                    value={usname}
                                    onChange={(e)=> setUsname(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Typography className={classes.label}>
                                    Instansi Yang Mereferralkan
                                </Typography>
                                <PInput
                                    value={uscompany}
                                    onChange={(e)=> setUscompany(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={2}>
                        <div className={classes.wrapArrow}>
                            <IconButton>
                                <FontAwesomeIcon
                                    icon={faArrowAltCircleRight}
                                    style={{
                                        width: 45,
                                        height: 45,
                                    }}
                                />
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item md={5} style={{ paddingRight: 15, marginBottom: 20 }}>
                        <Grid container spacing={2} className={classes.wrapBox}>
                            <Grid item md={12}>
                                <Typography className={classes.label}>
                                    Nama Yang Direferralkan
                                </Typography>
                                <PInput
                                    value={referralname}
                                    onChange={(e)=> setReferralname(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Typography className={classes.label}>
                                    Instansi Yang Direferralkan
                                </Typography>
                                <PInput
                                    value={referralcompany}
                                    onChange={(e)=> setReferralcompany(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <Typography className={classes.label}>
                            Informasi
                        </Typography>
                        <PInput
                            placeholder='Informasi...'
                            value={message}
                            onChange={(e)=> setMessage(e.target.value)}
                            multiline
                            rows={3}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify='flex-end' item xs={12} className={classes.wrapAction}>
                <PBtn
                    inverted
                    dropShadow
                    style={{
                        marginRight: 16,
                    }}
                    onClick={onClose}
                >
                    Batal
                </PBtn>
                <PBtn
                    onClick={()=> handleSubmit()}
                    disabled={isSubmitting}
                >
                    {
                        !isSubmitting ? 'Simpan' : <FontAwesomeIcon icon={faSpinner} spin/>
                    }
                </PBtn>
            </Grid>
        </Grid>
    );
};

const mapState = ({ prospect, user }) => ({
    selectedReferral: prospect.selectedReferral,
    currentUser: user.currentUser,
});

const mapDispatch = ({ prospect: { fetchReferral } }) => ({
    fetchReferral,
});

export default connect(mapState, mapDispatch)(ReferralForm);
