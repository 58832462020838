import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import format from "date-fns/format";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
        },
    },
});

const AnomaliItem = ({ anomaliPenggunaan, isFetchingAnomaliPenggunaan, page, rowsPerPage }) => {
    const classes = styles();
    const index = page * rowsPerPage;

    return (
        <React.Fragment>
            {
                isFetchingAnomaliPenggunaan === true
                    ? <TableRow>
                        <TableCell colSpan={5}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress/>
                            </div>
                        </TableCell>
                    </TableRow>
                    : anomaliPenggunaan.slice(index, index + rowsPerPage).map((item, idx) => (
                        <TableRow key={idx} className={classes.row}>
                            <TableCell className={classes.tableCellBody}>
                                {format(new Date(item.createdAt), 'dd/MM/yyyy')}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.name}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.closingProductTypePotential}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.assistPIC}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.hotButton}
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
};

const mapState = ({ laporan }) => ({
    isFetchingAnomaliPenggunaan: laporan.isFetchingAnomaliPenggunaan,
    anomaliPenggunaan: laporan.anomaliPenggunaan,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(AnomaliItem);
