import React, {useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import TicketingItem from "./TicketingItem";

const styles = makeStyles({
    tableWrapper: {
        overflow: 'auto',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
});

const TicketingTable = ({ ticketingListCounter, fetchTicketingList, filterData, setFilterData, filter, setFilter, setIsOpenDialog }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        if(filter === 0) {
            setPage(0);
            fetchTicketingList();
        } else {
            handleRenderTicketingFilter();
        }
    }, [fetchTicketingList, filter]);

    const handleRenderTicketingFilter = () => {
        fetchTicketingList(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        setFilterData({...filterData, skip: skipLimit.skip, limit: skipLimit.limit});
        setFilter(filter + 1);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        const newRowsPerPage = parseInt(e.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerPage,
        };
        setFilterData({...filterData, skip: skipLimit.skip, limit: skipLimit.limit});
        setFilter(filter + 1);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    return (
        <div>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader}>
                                Tanggal Mulai
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Tanggal Selesai
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Nama Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                PIC Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                PIC Assist.id
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Produk
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Kategori
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Modul Assist.id
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Media Komunikasi
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Keterangan Dari Customer
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TicketingItem page={page} rowsPerPage={rowsPerPage} setIsOpenDialog={setIsOpenDialog}/>
                    </TableBody>
                </Table>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={ticketingListCounter}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

const mapState = ({ ticketing }) => ({
    ticketingListCounter: ticketing.ticketingListCounter,
});

const mapDispatch = ({ ticketing: { fetchTicketingList } }) => ({
    fetchTicketingList,
});

export default connect(mapState, mapDispatch)(TicketingTable);
