import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from 'react-redux';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV, faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {deleteDictionary} from "../../../api/dictionary";
import {dispatch} from "../../../store";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import {updateRejectionCategoryProduct} from "../../../api/rejection-category";

const styles = makeStyles({
    card: {
        height: 530,
        width: '210px',
        maxHeight: 530,
        // overflowY: 'auto',
        marginRight: 20,
        marginBottom: 20,
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#000000',
    },
    subTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#54a651',
    },
    wrapper: {
        marginBottom: '10px',
    },
    wrap: {
        display: 'inline-flex',
    },
    iconCheckCircle: {
        color: '#2b64af',
        marginRight: 10,
    },
    feature: {
        fontFamily: 'Nunito',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#000000',
        marginTop: -2,
    },
    titleDialog: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#15162d',
    },
});

const ListProduk = ({ isFetchingProductList, productList, fetchProducts, currentUser }) => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteId, setDeleteId] = useState(undefined);
    const [showDialog, setShowDialog] = useState(false);
    const [data, setData] = useState('');
    const [flagData, setFlagData] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClick = produk => event => {
        setAnchorEl(event.currentTarget);
        setDeleteId(produk.id);
        setData(JSON.parse(JSON.stringify(produk)));
        setFlagData(JSON.parse(JSON.stringify(produk)));
    };

    const handleClose = () => {
        setAnchorEl(null);
        setData('');
        setFlagData('');
    };

    async function handleDelete(id) {
        const { snackbar } = dispatch;
        try {
            await deleteDictionary(id);
            snackbar.showSuccess('Berhasil menghapus produk');
            setDeleteId(undefined);
            setAnchorEl(null);
            setData('');
            setFlagData('');
            fetchProducts();
        } catch (err) {
            console.error(err);
            snackbar.showError('Gagal menghapus produk');
            setAnchorEl(null);
            setData('');
            setFlagData('');
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
        setData(flagData);
    };

    const handleChangeProduct = (e) => {
        const { name } = e.target;
        const { value } = e.target;

        if(name === "productName"){
            data.name = value;
            setData(prevState => ({
                ...prevState, name: data.name
            }));
        } else if(name === "price"){
            data.price = value;
            setData(prevState => ({
                ...prevState, price: data.price
            }));
        }
    };

    const handleChangeFitur = (idx, e) => {
        const { value } = e.target;
        data.features[idx] = value;
        setData(prevState => ({
            ...prevState, features: data.features
        }));
    };

    const handleTambahFitur = () => {
        setData(prevState => ({
            ...prevState, features: [...data.features, '']
        }));
    };

    const handleDeleteFitur = (idx) => {
        data.features.splice(idx, 1);
        setData(prevState => ({
            ...prevState, features: data.features
        }));
    };

    async function handleUpdate() {
        const { snackbar } = dispatch;
        try{
            setIsSubmitting(true);
            await updateRejectionCategoryProduct(data, data.id, currentUser.id);
            setIsSubmitting(false);
            snackbar.showSuccess('Berhasil mengupdate produk');
            setShowDialog(false);
            setAnchorEl(false);
            setData('');
            setFlagData('');
            fetchProducts();
        } catch (e) {
            console.error(e);
            snackbar.showError('Gagal mengupdate produk');
            setShowDialog(false);
            setData(flagData);
        }
    }

    return (
        <Grid item xs={12}>
            {
                isFetchingProductList
                    ? <Grid container justify="center">
                        <CircularProgress />
                    </Grid>
                    : <Grid container style={{marginTop: '40px', marginLeft: '-4px'}} spacing={8}>
                        {
                            productList.map((product, idx) => (
                                <div key={idx}>
                                    <Card className={classes.card}>
                                        <CardHeader
                                            action={
                                                <IconButton aria-label="Setting" onClick={handleClick(product)}>
                                                    <FontAwesomeIcon icon={faEllipsisV} style={{height: 15}}/>
                                                </IconButton>
                                                 }
                                            title={
                                                <Typography className={classes.title}>{product.name}</Typography>
                                            }
                                            subheader={
                                                <Typography className={classes.subTitle}>Rp.&nbsp;
                                                    <NumberFormat
                                                        displayType="text"
                                                        value={product.price}
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                    />
                                                </Typography>
                                            }
                                        />
                                        <CardContent>
                                            {product.features.map((feature, idx) => (
                                                <div className={classes.wrapper} key={idx}>
                                                    <div className={classes.wrap}>
                                                        <FontAwesomeIcon icon={faCheckCircle} className={classes.iconCheckCircle}/>
                                                        <Typography key={`${feature}-${idx}`} className={classes.feature}>
                                                            {feature}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ))}
                                        </CardContent>
                                    </Card>
                                </div>
                            ))
                        }
                    </Grid>
            }
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={()=> setShowDialog(true)}>Edit</MenuItem>
                <MenuItem onClick={()=> handleDelete(deleteId)}>Delete</MenuItem>
            </Menu>
            <Dialog maxWidth="md" open={showDialog} onClose={handleCloseDialog}>
                <DialogTitle className={classes.titleDialog}>Edit Produk</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <PInput
                                        label="Nama Produk"
                                        name="productName"
                                        value={data.name}
                                        onChange={handleChangeProduct}
                                    />
                                    <div style={{ marginTop: 16 }}>
                                        {
                                            data.features && data.features.map((fitur, idx) => (
                                                <Grid key={idx} item md={12}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <PInput
                                                            label={`Fitur-${idx + 1}`}
                                                            value={fitur}
                                                            onChange={(e) => handleChangeFitur(idx, e)}
                                                        />
                                                        <div>
                                                            <IconButton onClick={() => handleDeleteFitur(idx)}>
                                                                <FontAwesomeIcon icon={faTimesCircle} />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            ))
                                        }
                                        <Grid item xs={12} style={{ marginTop: 16 }}>
                                            <PBtn onClick={handleTambahFitur}>Tambah Fitur</PBtn>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item md={6}>
                                    <PInput
                                        label="Harga per Bulan"
                                        name="price"
                                        value={data.price}
                                        onChange={handleChangeProduct}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <PBtn disabled={isSubmitting} onClick={handleUpdate} style={{marginRight: '13px', marginTop: '15px', marginBottom: '10px'}}>Simpan</PBtn>
                </DialogActions>
            </Dialog>
        </Grid>
    )
};

const mapState = ({ product, user }) => ({
    productList: product.productList,
    isFetchingProductList: product.isFetchingProductList,
    currentUser: user.currentUser,
});

const mapDispatch = ({ product: { fetchProducts } }) => ({
    fetchProducts,
});

export default connect(mapState, mapDispatch)(ListProduk);
