import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    btn: {
        borderRadius: 6,
        border: 0,
        height: 40,
        background: '#2b64af',
        color: 'white',
        padding: '7px 20px',
        textTransform: 'capitalize',
        '&:hover': {
            background: '#2b64af',
        },
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize: '13px',
    },
    shadowBtn: {
        boxShadow: '0 2px 3px 0px rgba(0, 0, 0, .2)',
    },
    invertedBtn: {
        background: 'white',
        color: '#2b64af',
        '&:hover': {
            background: 'white',
        },
    },
});

function PBtn({ children, dropShadow, inverted, ...props }) {
    const classes = useStyles();

    return (
        <Button
            className={
                `${classes.btn} ${dropShadow ? classes.shadowBtn : ''} 
                ${inverted ? classes.invertedBtn : ''}
                `
            }
            {...props}
        >{ children }</Button>
    );
}

export default PBtn;
