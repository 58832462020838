import React, { useEffect } from 'react'
import { Router, Route } from 'react-router-dom'
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import {IconButton, Snackbar, SnackbarContent} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle, faExclamationCircle, faExclamationTriangle, faInfoCircle, faTimesCircle
} from "@fortawesome/free-solid-svg-icons"
import {amber, green} from "@material-ui/core/colors";

import Login from "./components/login/Login";
// import HomeFaskes from './components/faskes/homeFaskes/HomeFaskes';
import templateFaskes from "./components/faskes/templateFaskes/templateFaskes";
import PrivateRoute from "./components/utils/PrivateRoute";
import AuthRoute from "./components/utils/AuthRoute";
import { dispatch } from "./store";

import './App.css'
import history from "./history";

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    }
}));

const variantIcon = {
    success: faCheckCircle,
    warning: faExclamationCircle,
    error: faExclamationTriangle,
    info: faInfoCircle,
};

function App(
    {
        isAuthenticated, openSnackbar, closeSnackbar, snackbarType, snackbarMessage, handleRelogin,
        isLoaded
    }
) {
    const classes = useStyles();

    useEffect(() => {
        if (!isAuthenticated) {
            const token = localStorage.getItem('token');
            if (token) {
                handleRelogin()
            } else {
                dispatch.app.updateIsLoaded(true);
            }
        } else {
            dispatch.app.updateIsLoaded(true);
        }
    }, [isLoaded, isAuthenticated, handleRelogin]);

    return(
        <div>
            {
                isLoaded
                    ? <Router history={history}>
                        <AuthRoute
                            isAuthenticated={isAuthenticated}
                            path="/login" component={Login} exact
                        />
                        <Route path="/" render={({ location: { pathname } }) => {
                            if (pathname !== "/login") {
                                return <PrivateRoute isAuthenticated={isAuthenticated} path="/" component={templateFaskes} />
                            }
                        }} />
                    </Router>
                    : null
            }

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <SnackbarContent
                    className={classes[snackbarType]}
                    aria-describedby="client-snackbar"
                    message={
                        <span>
                            <FontAwesomeIcon style={{ marginRight: 8 }} icon={variantIcon[snackbarType]}/>
                            {snackbarMessage}
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={closeSnackbar}>
                            <FontAwesomeIcon icon={faTimesCircle}/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </div>
    )
}

const mapState = ({ user, snackbar, app }) =>({
    isAuthenticated: user.isAuthenticated,
    openSnackbar: snackbar.open,
    snackbarMessage: snackbar.message,
    snackbarType: snackbar.type,
    isLoaded: app.isLoaded,
});

const mapDispatch = ({ snackbar: { close }, auth: { handleRelogin } }) => ({
    closeSnackbar: close,
    handleRelogin,
});

export default connect(mapState, mapDispatch)(App);
