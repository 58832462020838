import {
    getTicketingList,
    countTicketingList,
} from "../../api/ticketing";

export const ticketing = {
    state: {
        isFetchingTicketingList: false,
        ticketingList: [],
        ticketingListCounter: 0,
        selectedTicketing: {},
        selectedTicketingFilter: {},
    },
    reducers: {
        updateState(state, payload) {
            const { statName, value } = payload;

            return {
                ...state,
                [statName]: value,
            };
        },
        updateTicketingList(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    ticketingList: value,
                };
            }

            const { ticketingList } = state;
            ticketingList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                ticketingList: [...ticketingList],
            };
        },
        updateSelectedTicketing(state, payload) {
            return {
                ...state,
                selectedTicketing: payload,
            };
        },
        updateSelectedTicketingFilter(state, payload) {
            return {
                ...state,
                selectedTicketingFilter: payload,
            };
        },
    },
    effects: dispatch => ({
        async fetchTicketingList(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingTicketingList',
                    value: true,
                });

                const result = await getTicketingList(skipLimit);
                const resultCounter = await countTicketingList(skipLimit);

                this.updateTicketingList({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'ticketingListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingTicketingList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan daftar ticketing');
                this.updateState({
                    statName: 'isFetchingTicketingList',
                    value: false,
                });
            }
        },
    }),
};
