import React, {useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import IklanItem from "./IklanItem";

const styles = makeStyles({
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
});

const IklanTable = ({ fetchIklanList, iklanList, setIsOpenDialog }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        fetchIklanList();
    }, [fetchIklanList]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellHeader}>Nama Iklan</TableCell>
                        <TableCell className={classes.tableCellHeader}>Letak Iklan</TableCell>
                        <TableCell className={classes.tableCellHeader} style={{ textAlign: 'center' }}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <IklanItem page={page} rowsPerPage={rowsPerPage} setIsOpenDialog={setIsOpenDialog}/>
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={iklanList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    );
};

const mapState = ({ iklan }) => ({
    iklanList: iklan.iklanList,
});

const mapDispatch = ({ iklan: { fetchIklanList } }) => ({
    fetchIklanList,
});

export default connect(mapState, mapDispatch)(IklanTable);
