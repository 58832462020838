import React, {useState} from 'react';
import Header from "../faskes/templateFaskes/header/header";
import SubMenuBtn from "../share/SubMenuBtn";
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";

import Overview from "./overview/Overview";
import Penolakan from "./penolakan/Penolakan";
import Blocked from "./blocked/Blocked";
import Trial from "./trial/Trial";
import Penawaran from "./penawaran/Penawaran";
import Freeze from "./freeze/Freeze";

import './activity.css';

const styles = makeStyles({
    menu: {
        marginRight: 64,
        marginTop: -10,
        backgroundColor: "#f5f6f8",
        '&:hover' : {
            backgroundColor: "#f5f6f8",
        },
        fontFamily: 'Nunito',
        fontSize: '18px',
        fontWeight: 600,
    }
});

const Demo = () => {
    return (
        <div>Demo</div>
    );
};

const Activity = ({ isViewedByMobile }) => {
    const classes = styles();
    const [subMenu, setSubMenu] = useState(1);

    let SubMenuContent;
    if (subMenu === 1) {
        SubMenuContent = <Overview />
    } else if (subMenu === 2) {
        SubMenuContent = <Penawaran />
    } else if (subMenu === 3) {
        SubMenuContent = <Demo />
    } else if (subMenu === 4) {
        SubMenuContent = <Trial />
    } else if (subMenu === 5) {
        SubMenuContent = <Penolakan />
    } else if (subMenu === 6) {
        SubMenuContent = <Blocked />
    } else if (subMenu === 7) {
        SubMenuContent = <Freeze />
    }

    return (
        <div className="activity-content">
            <Header title={"Activity"} />
            <div className="activity-body" style={isViewedByMobile ? {height: '95%'} : {height: '85%'}}>
                <Container className="container">
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <SubMenuBtn
                                isActive={subMenu === 1}
                                onClick={() => setSubMenu(1)}
                                className={classes.menu}
                                inverted
                            >Overview</SubMenuBtn>

                            <SubMenuBtn
                                isActive={subMenu === 2}
                                onClick={() => setSubMenu(2)}
                                className={classes.menu}
                                inverted
                            >Penawaran</SubMenuBtn>

                            <SubMenuBtn
                                isActive={subMenu === 3}
                                onClick={() => setSubMenu(3)}
                                className={classes.menu}
                                inverted
                            >Demo</SubMenuBtn>

                            <SubMenuBtn
                                isActive={subMenu === 4}
                                onClick={() => setSubMenu(4)}
                                className={classes.menu}
                                inverted
                            >Trial</SubMenuBtn>

                            <SubMenuBtn
                                isActive={subMenu === 5}
                                onClick={() => setSubMenu(5)}
                                className={classes.menu}
                                inverted
                            >Penolakan</SubMenuBtn>

                            <SubMenuBtn
                                isActive={subMenu === 6}
                                onClick={() => setSubMenu(6)}
                                className={classes.menu}
                                inverted
                            >Blocked</SubMenuBtn>

                            <SubMenuBtn
                                isActive={subMenu === 7}
                                onClick={() => setSubMenu(7)}
                                className={classes.menu}
                                inverted
                            >Freeze</SubMenuBtn>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {SubMenuContent}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

const mapState = ({ app }) => ({
    isViewedByMobile: app.isViewedByMobile,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(Activity);
