import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";

import ActionCard from "../../share/ActionCard";

import Anomali from "./anomali/Anomali";

const Others = () => {
    const [isPage, setIsPage] = useState(0);

    return (
        <Grid container>
            {
                isPage === 0
                    ? <Grid container spacing={4} item xs={12} style={{ marginTop: 50 }}>
                        <Grid item xs={6}>
                            <ActionCard
                                label='Laporan Anomali'
                                subLabel='Laporan keganjilan dalam penggunaan sistem'
                                handleClick={()=> setIsPage(1)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                    </Grid>
                    : <Anomali setIsPage={setIsPage}/>
            }
        </Grid>
    );
};

export default Others;
