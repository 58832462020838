import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, } from '@fortawesome/free-solid-svg-icons';
import PBtn from "../../share/PBtn";

import ListPICAssist from "./ListPICAssist";
import NewUserDialog from "./NewUserDialog";

function PICAssist ({ getPanelaUserList }) {
    const [isOpen, setIsOpen] = useState(false);
    const [editableData, setEditableData] = useState({});
    const [search, setSearch] = useState("");

    useEffect(() => {
        getPanelaUserList();
    });

    const handleOpenEdit = (selectedData) => {
        setEditableData(selectedData);
        setIsOpen(true)
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginTop: 44 }}>
                <TextField
                    onChange={handleSearch}
                    placeholder="Search"
                    style={{width: '33%', background: 'white', marginRight: 40, marginTop: 1}}
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <FontAwesomeIcon icon={faSearch} />
                        </InputAdornment>,
                    }}
                />
                <PBtn onClick={() => setIsOpen(true)}>Tambah PIC</PBtn>

                <NewUserDialog
                    open={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                        setEditableData({});
                    }}
                    isEdit={typeof editableData.id !== 'undefined'}
                    editableData={editableData}
                />
            </Grid>

            <Grid item xs={12} style={{marginTop: 30}}>
                <ListPICAssist handleOpenEdit={handleOpenEdit} search={search} />
            </Grid>
        </Grid>
    );
}

const mapState = null;

const mapDispatch = ({ panelaUser: { getPanelaUserList } }) => ({
    getPanelaUserList,
});

export default connect(mapState, mapDispatch)(PICAssist);
