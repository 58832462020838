import {getOfferList} from "../../api/offer";

export const offer = {
    state: {
        offerList: [],
    },
    reducers: {
        updateOfferList(state, payload) {
            return {
                ...state,
                offerList: payload,
            };
        },
    },
    effects: dispatch => ({
        async fetchOfferList() {
            try {
                const result = await getOfferList();
                this.updateOfferList(result.data);
                return true;
            } catch (err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar penawaran");
                throw err;
            }
        }
    }),
};
