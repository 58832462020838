import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

import {connect} from "react-redux";

import PBtn from "../../share/PBtn";

import BuktiBayarPrintContent from "./BuktiBayarPrintContent";

import {getInvoiceDetailByConfigId} from "../../../api/client";

const styles = makeStyles({
    '@global': {
        '@media print': {
            '.hideForPrint': {
                display: 'none',
            },
        },
    },
    popover: {
        pointerEvents: 'none',
    },
});

const BuktiBayarPrintDialog = ({ onClose, selectedReceiptsList }) => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [terminList, setTerminList] = useState([]);
    const [idxTermin, setIdxTermin] = useState(null);
    const [pembayaran, setPembayaran] = useState([]);

    useEffect(()=> {
        (async function() {
            const result = await getInvoiceDetailByConfigId(selectedReceiptsList.KConfig._id);
            setTerminList(result.data.payment);
            setIdxTermin(result.data.payment.findIndex(item => {
                return item.dueDatePayment === selectedReceiptsList.KConfig.activeBilling.Billing.payment.dueDatePayment
                    && item.value === selectedReceiptsList.KConfig.activeBilling.Billing.payment.value
                    && item.isPayed === selectedReceiptsList.KConfig.activeBilling.Billing.payment.isPayed;
            }));
            setPembayaran(result.data.payment.filter(item => item.dueDatePayment === selectedReceiptsList.KConfig.activeBilling.Billing.payment.dueDatePayment
                && item.value === selectedReceiptsList.KConfig.activeBilling.Billing.payment.value
                && item.isPayed === selectedReceiptsList.KConfig.activeBilling.Billing.payment.isPayed).map(data => data));
        })();
    }, [selectedReceiptsList]);

    const handleOpenInstruction = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseInstruction = (event) => {
        setAnchorEl(null);
    };

    return (
        <Grid container>
            <div className={'hideForPrint'} style={{ position: 'absolute', top: 10, right: 50 }}>
                <PBtn
                    onClick={()=> {
                        document.title = `Receipt ${selectedReceiptsList.nama} - ${selectedReceiptsList.KConfig.activeBilling.Billing.invoiceNo}`;
                        window.print();
                        document.title = "Admin | Panela";
                        return false;
                    }}
                >
                    Download PDF
                    <FontAwesomeIcon
                        icon={faQuestionCircle}
                        style={{ marginLeft: 8 }}
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handleOpenInstruction}
                        onMouseLeave={handleCloseInstruction}
                    />
                    <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        onClose={handleCloseInstruction}
                        disableRestoreFocus
                    >
                        <div style={{padding: '15px 15px 15px 15px'}}>
                            <Typography>Instruksi Download PDF Penawaran:</Typography>
                            <Typography>1). Klik Download PDF</Typography>
                            <Typography>2). Destinasi: Pilih Save As PDF</Typography>
                            <Typography>3). Save</Typography>
                        </div>
                    </Popover>
                </PBtn>
                <IconButton onClick={onClose}>
                    <FontAwesomeIcon icon={faTimesCircle}/>
                </IconButton>
            </div>
            <Grid container justify="center" item xs={12}>
                <div style={{ paddingTop: 40, width: '21cm' }}>
                    <BuktiBayarPrintContent
                        terminList={terminList}
                        idxTermin={idxTermin}
                        pembayaran={pembayaran}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

const mapState = ({ client }) => ({
    selectedReceiptsList: client.selectedReceiptsList,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(BuktiBayarPrintDialog);
