import React, {useState, useEffect} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Dialog,
    DialogContent,
    Grid,
    InputAdornment,
} from "@material-ui/core";

import {makeStyles} from '@material-ui/core/styles';
import {DatePicker} from "@material-ui/pickers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from 'react-redux';

import format from 'date-fns/format';
import idLocale from 'date-fns/locale/id';
import NumberFormat from "react-number-format";

import './tableLeads.css';

import PBtn from "../../share/PBtn";
import PInput from "../../share/PInput";

function numberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: "whatsapp",
                        value: values.value,
                    },
                });
            }}
            format={"####-####-####"}
        />
    );
}

const style = makeStyles({
    tableWrapper: {
        maxHeight: 440,
        overflow: 'auto',
    },
    leadsLeft: {
        marginRight: "10px",
    },
    leadsRight: {
        marginLeft: "10px",
    },
    titleDialog: {
        fontFamily: "Nunito",
        fontSize: "20px",
        color: "#15162d",
        fontWeight: "600",
        marginBottom: "-10px",
        marginTop: "20px",
        marginLeft: "0px",
    },
    wrap: {
        padding: "20px",
    },
    gridWrap: {
        marginBottom: 20,
    },
});

function TableDetailPICLeads(props) {
    const classes = style();
    const [ dataFaskes, setDataFaskes ] = useState("");
    const [ index, setIndex ] = useState("");
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(5);
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        setDataFaskes(JSON.parse(JSON.stringify(props.faskes)));
    }, [props]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function handleClickOpen(idx){
        setOpen(true);
        setIndex(idx);
        if(!dataFaskes.PIC[idx].nickname) {
            dataFaskes.PIC[idx] = {...dataFaskes.PIC[idx], nickname: ""};
            setDataFaskes(prevState => ({
                ...prevState, PIC: dataFaskes.PIC
            }));
        }
        if(!dataFaskes.PIC[idx].note) {
            dataFaskes.PIC[idx] = {...dataFaskes.PIC[idx], note: ""};
            setDataFaskes(prevState => ({
                ...prevState, PIC: dataFaskes.PIC
            }));
        }
    }

    const handleClose = () => {
        setOpen(false);
        setIndex("");
        setDataFaskes(JSON.parse(JSON.stringify(props.faskes)));
    };

    const handleChangesPIC = (e) => {
        dataFaskes.PIC[index] = {...dataFaskes.PIC[index], [e.target.name]: e.target.value};
        setDataFaskes(prevState => ({
            ...prevState, PIC: dataFaskes.PIC
        }));
    };

    const onSubmitPIC = (e) => {
        e.preventDefault();
        const f = {...dataFaskes};
        f.updatedId = props.currentUser.id;
        props.editData(f).then(()=> window.location.reload());
        setOpen(false);
    };

    const handleDeletePIC = () => {
        dataFaskes.PIC[index] = {...dataFaskes.PIC[index]};
        dataFaskes.PIC.splice(index, 1);
        setDataFaskes(prevState => ({
            ...prevState, PIC: dataFaskes.PIC
        }));
        const f = {...dataFaskes};
        f.updatedId = props.currentUser.id;
        props.editData(f).then(()=> window.location.reload());
        setOpen(false);
        setIndex("");
    };

    const handleChangeBirthdayPIC = (date) => {
        dataFaskes.PIC[index] = {...dataFaskes.PIC[index], birthday: date};
        setDataFaskes(prevState => ({
            ...prevState, PIC: dataFaskes.PIC
        }))
    };

    return(
        <div className="table-leads">
            <div className={classes.tableWrapper}>
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="Detail Data PIC"
                >
                    <form onSubmit={onSubmitPIC} className={classes.wrap}>
                        <DialogContent>
                            <Grid container className={classes.gridWrap}>
                                <Grid item xs={12} md={6}>
                                    <div className={classes.leadsLeft}>
                                        <PInput
                                            name="namepic"
                                            label="Nama Lengkap PIC"
                                            value={index !== "" ? dataFaskes.PIC[index].namepic : ""}
                                            onChange={handleChangesPIC}
                                            required
                                            fullWidth
                                        />
                                        <br/><br/>
                                        <PInput
                                            name="nickname"
                                            label="Nama Panggilan PIC"
                                            value={index !== "" ? dataFaskes.PIC[index].nickname : ""}
                                            onChange={handleChangesPIC}
                                            required
                                            fullWidth
                                        />
                                        <br/><br/>
                                        <DatePicker
                                            value={index !== "" ? dataFaskes.PIC[index].birthday : null}
                                            format="dd MMMM yyyy"
                                            label="Tanggal Lahir PIC"
                                            margin="dense"
                                            inputVariant="outlined"
                                            onChange={handleChangeBirthdayPIC}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                                </InputAdornment>
                                            }}
                                            fullWidth
                                        />
                                        <br/><br/>
                                        <PInput
                                            name="position"
                                            label="Jabatan PIC"
                                            value={index !== "" ? dataFaskes.PIC[index].position : ""}
                                            onChange={handleChangesPIC}
                                            required
                                            fullWidth
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className={classes.leadsRight}>
                                        <PInput
                                            name="emailpic"
                                            label="Email PIC"
                                            value={index !== "" ? dataFaskes.PIC[index].emailpic : ""}
                                            onChange={handleChangesPIC}
                                            fullWidth
                                        />
                                        <br/><br/>
                                        <PInput
                                            name="address"
                                            label="Alamat PIC"
                                            value={index !== "" ? dataFaskes.PIC[index].address : ""}
                                            onChange={handleChangesPIC}
                                            required
                                            fullWidth
                                        />
                                        <br/><br/>
                                        <PInput
                                            name="whatsapp"
                                            label="Whatsapp PIC"
                                            value={index !== "" ? dataFaskes.PIC[index].whatsapp : ""}
                                            onChange={handleChangesPIC}
                                            InputProps={{
                                                inputComponent: numberFormatCustom,
                                            }}
                                            required
                                            fullWidth
                                        />
                                        <br/><br/>
                                        <PInput
                                            name="note"
                                            label="Keterangan"
                                            value={index !== "" ? dataFaskes.PIC[index].note : ""}
                                            onChange={handleChangesPIC}
                                            fullWidth
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container justify='flex-end' item xs={12} md={12}>
                                <PBtn
                                    inverted
                                    dropShadow
                                    style={{
                                        marginRight: 16,
                                    }}
                                    onClick={handleDeletePIC}
                                >Hapus</PBtn>
                                <PBtn
                                    type='submit'
                                >Edit</PBtn>
                            </Grid>
                        </DialogContent>
                    </form>
                </Dialog>
                <Table aria-label="sticky label">
                    <TableHead>
                        <TableRow>
                            { props ?  props.header.map((data, idx) => (
                                <TableCell key={idx} className="table-cell-header">{data.label}</TableCell>
                            )) : "" }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.pic && props.pic.length > 0 ? props.pic.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, idx) => (
                            <TableRow className="table-row-body" key={idx} onClick={handleClickOpen.bind(this, idx)}>
                                <TableCell className="table-cell-body">{data.namepic}</TableCell>
                                <TableCell className="table-cell-body">{data.nickname || "-"}</TableCell>
                                <TableCell className="table-cell-body">{data.position}</TableCell>
                                <TableCell className="table-cell-body">{data.whatsapp}</TableCell>
                                <TableCell className="table-cell-body">{data.emailpic}</TableCell>
                                <TableCell className="table-cell-body">{data.address === "" ? "-" : data.address}</TableCell>
                                <TableCell className="table-cell-body">{data.birthday === null ? "-" : format(new Date(data.birthday), "dd MMMM yyyy", {locale: idLocale})}</TableCell>
                                <TableCell className="table-cell-body">{data.note || "-"}</TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={6} className="table-cell-body" style={{textAlign: "center"}}>Tidak Ada Data</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.pic && props.pic.length > 0 ? props.pic.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage} />
        </div>
    )
}

const mapState = ({ user }) =>({
    currentUser: user.currentUser,
});

const mapDispatch = ({ leads : { editLeads } }) => ({
    editData : (e) => editLeads(e),
});

export default connect(mapState, mapDispatch)(TableDetailPICLeads);
