import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Popover from "@material-ui/core/Popover";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";

import {DatePicker} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faGrinStars,
    faGrin,
    faFrownOpen,
    faSpinner,
    faExclamationCircle,
    faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {dispatch} from "../../../store";
import {connect} from "react-redux";

import {
    checkFaskesName,
    getFaskesByName,
    checkFaskesEmail,
    getFaskesByEmail,
    checkFaskesPhoneNumber,
    getFaskesByPhoneNumber
} from "../../../api/faskes";
import {
    getDaftarKabupaten,
    getDaftarKecamatan,
    getProducts,
} from "../../../api/client";
import {getActivityLatestLeads} from "../../../api/activity";

import NumberFormat from "react-number-format";
import addMonth from "date-fns/addMonths";
import history from "../../../history";
import format from "date-fns/format";
import localeId from "date-fns/locale/id";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

const styles = makeStyles({
    content: {
        width: '100%',
    },
    body: {
        width: '100%',
        height: '85%',
        position: 'absolute',
        marginTop: '7%',
        bottom: 0,
        backgroundColor: '#f5f6f8',
        overflowY: 'auto',
        '@media only screen and (max-width:994px)': {
            height: '90%',
            width: '1440px',
        },
    },
    container: {
        padding: '25px 20px',
        '@media only screen and (max-width:994px)': {
            padding: '4% 20px',
        },
        '@media only screen and (max-width:400px)': {
            padding: '3% 20px',
        },
    },
    labelAddWrap: {
        marginTop: 0,
        display: 'inline-flex',
    },
    labelAdd: {
        fontWeight: 'bold',
        fontSize: '23px',
        fontFamily: 'Nunito',
        marginBottom: 40,
        color: '#15162d',
    },
    labelStep: {
        fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: 'Nunito',
        marginBottom: 20,
        color: '#2b64af',
    },
    labelField: {
        color: '#15162d',
        fontSize: '15px',
        fontFamily: 'Nunito',
        marginBottom: 5,
    },
    arrowBack: {
        cursor: 'pointer',
        marginRight: '20px',
        color: 'black',
        marginTop: 10,
    },
    formLeft: {
        marginRight: 20,
    },
    formRight: {
        marginLeft: 20,
    },
    fieldWrap: {
        marginBottom: 15,
    },
    gridWrap: {
        marginBottom: 20,
    },
    hotnessLevelWrap: {
        display: 'inline-flex',
    },
    popover: {
        pointerEvents: 'none',
    },
    information: {
        color: '#15162d',
        fontFamily: 'Nunito',
        fontSize: 15,
    },
    helperText: {
        color: 'rgba(0, 0, 0, .6)',
        borderLeft: '1px solid #f5f6f8',
    },
    iconInterested: {
        color: '#008000',
    },
    iconNormal: {
        color: '#FFA500',
    },
    iconUninterested: {
        color: '#FF4500',
    },
    chip: {
        backgroundColor: '#2b64af',
        color: 'white',
    },
    labelChip: {
        width: 110,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block',
    },
    labelHotnessLevel: {
        paddingTop: 15,
        marginRight: 5,
    },
    activityPaper: {
        boxShadow: '0 40px 40px -40px #777',
        backgroundColor: 'white',
        borderRadius: 8,
        marginBottom: 60,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 30,
    },
});

function numberFormatCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            displayType='text'
            prefix="Rp. "
            decimalSeparator=","
            thousandSeparator="."
        />
    );
}

const FormAddLeadsV1 = ({ mediaList, panelaUserList, eventNameList, daftarProvinsi, iklanList, kompetitorList, isFetchingSingleFaskesList, isEdit, singleFaskesList, addLeads, editLeads, addActivity, currentUser, selectedProspect, updateSelectedProspect, rejectionCategoryList, addActivityRejection, isViewedByMobile }) => {
    const isVerifiedEdit = typeof singleFaskesList.id !== 'undefined';
    const classes = styles();
    const hotnessLevel = [
        {
            icon: faGrinStars,
            label: 'Interested',
            style: classes.iconInterested,
        },
        {
            icon: faGrin,
            label: 'Normal',
            style: classes.iconNormal,
        },
        {
            icon: faFrownOpen,
            label: 'Uninterested',
            style: classes.iconUninterested,
        },
    ];
    const duration = [
        {value: 1, label: '1 Bulan'}, {value: 2, label: '2 Bulan'}, {value: 3, label: '3 Bulan'},
        {value: 4, label: '4 Bulan'}, {value: 5, label: '5 Bulan'}, {value: 6, label: '6 Bulan'},
        {value: 7, label: '7 Bulan'}, {value: 8, label: '8 Bulan'}, {value: 9, label: '9 Bulan'},
        {value: 10, label: '10 Bulan'}, {value: 11, label: '11 Bulan'}, {value: 12, label: '12 Bulan'},
        {value: 13, label: '13 Bulan'}, {value: 14, label: '14 Bulan'}, {value: 15, label: '15 Bulan'},
        {value: 16, label: '16 Bulan'}, {value: 17, label: '17 Bulan'}, {value: 18, label: '18 Bulan'},
        {value: 19, label: '19 Bulan'}, {value: 20, label: '20 Bulan'}, {value: 21, label: '21 Bulan'},
        {value: 22, label: '22 Bulan'}, {value: 23, label: '23 Bulan'}, {value: 24, label: '24 Bulan'},
        {value: 25, label: '25 Bulan'}, {value: 26, label: '26 Bulan'}, {value: 27, label: '27 Bulan'},
        {value: 28, label: '28 Bulan'}, {value: 29, label: '29 Bulan'}, {value: 30, label: '30 Bulan'},
        {value: 31, label: '31 Bulan'}, {value: 32, label: '32 Bulan'}, {value: 33, label: '33 Bulan'},
        {value: 34, label: '34 Bulan'}, {value: 35, label: '35 Bulan'}, {value: 36, label: '36 Bulan'},
    ];
    const [leads, setLeads] = useState({
        picId: '',
        assistPIC: '',
        contactStatus: '',
        leadsContact: '',
        leadsInfo: '',
        event: '',
        isReferred: '',
        referredName: '',
        PIC: [{
            namepic : selectedProspect.faskesInfo ? selectedProspect.staffInfo[0].name : selectedProspect.data ? selectedProspect.data.usname : '',
            nickname : "",
            position : selectedProspect.faskesInfo ? selectedProspect.staffInfo[0].role : selectedProspect.data ? selectedProspect.data.jabatan : '',
            emailpic : selectedProspect.faskesInfo ? selectedProspect.staffInfo[0].Confidentials.email : selectedProspect.data ? selectedProspect.data.email : '',
            whatsapp : selectedProspect.faskesInfo ? selectedProspect.staffInfo[0].Confidentials.hp : selectedProspect.data ? selectedProspect.data.phonenumber : '',
            address: '',
            birthday: null,
            note: '',
        }],
        name: selectedProspect.faskesInfo ? selectedProspect.faskesInfo.nama : selectedProspect.data ? selectedProspect.data.medname : '',
        type: '',
        email: '',
        phoneNumber: '',
        googleMap: '',
        facebook: '',
        instagram: '',
        website: '',
        street: selectedProspect.faskesInfo ? selectedProspect.faskesInfo.alamat.jalan : '',
        province : selectedProspect.faskesInfo ? selectedProspect.faskesInfo.alamat.region : '',
        city : selectedProspect.faskesInfo ? selectedProspect.faskesInfo.alamat.city : '',
        district : selectedProspect.faskesInfo ? selectedProspect.faskesInfo.alamat.district : '',
        postCode : selectedProspect.faskesInfo ? selectedProspect.faskesInfo.alamat.postcode : '',
        operationalStatus: '',
        MCUAndLabServices: '',
        isJejaring: '',
        numberOfBranches: '',
        isCampaignRunning: '',
        campaignName: [],
        isCompetitorPotential: '',
        competitorName: '',
        competitorLevel: '',
        url: selectedProspect.data ? selectedProspect.data.url : '',
        isUnknown: '',
        isAlreadyUsingSystem: '',
        usedSystems: [],
        isOwnServerExist: '',
        isBPJS: '',
        isPharmacyExist: '',
        neededFeature: '',
        hotButton: '',
        reasonToUsingSystem: '',
        systemImplementationPlan: '',
        note: '',
        hotnessLevel: '',
        closingProductTypePotential: '',
        closingProductDurationPotential: '',
        closingProductValuePotential: '',
        isCustom: '',
        dataCustom: '',
        numberOfVisits: '',
        capitationBPJS: '',
        isSubscribed: '',
        recontact: '',
        useSystemNote: '',
        useSystemDuration: '',
        useSystemStartDate: null,
        useSystemEndDate: null,
        isAnyAnomaly: '',
        resubscribeCounter: '',
        status: '',
        rejectionCategory: '',
        lastContactDate: new Date(),
    });
    const [selectedPICAssist, setSelectedPICAssist] = useState({});
    const [isCheckingFaskesName, setIsCheckingFaskesName] = useState(false);
    const [existedFaskesName, setExistedFaskesName] = useState([]);
    const [flagFaskesName, setFlagFaskesName] = useState('');
    const [isFaskesNameExists, setIsFaskesNameExist] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const validateInt = RegExp(/^[0-9\b]+$/);
    const [isCheckingFaskesPhoneNumber, setIsCheckingFaskesPhoneNumber] = useState(false);
    const [existedFaskesPhoneNumber, setExistedFaskesPhoneNumber] = useState([]);
    const [flagFaskesPhoneNumber, setFlagFaskesPhoneNumber] = useState('');
    const [isFaskesPhoneNumberExists, setIsFaskesPhoneNumberExists] = useState(false);
    const validateEmail = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    const [isCheckingFaskesEmail, setIsCheckingFaskesEmail] = useState(false);
    const [existedFaskesEmail, setExistedFaskesEmail] = useState([]);
    const [flagFaskesEmail, setFlagFaskesEmail] = useState('');
    const [isFaskesEmailExists, setIsFaskesEmailExists] = useState(false);
    const [isValidatedFaskesEmail, setIsValidatedFaskesEmail] = useState(true);
    const [isValidatedPICEmail, setIsValidatedPICEmail] = useState(true);
    const [daftarKabupaten, setDaftarKabupaten] = useState([]);
    const [isFetchingDaftarKabupaten, setIsFetchingDaftarKabupaten] = useState(false);
    const [daftarKecamatan, setDaftarKecamatan] = useState([]);
    const [isFetchingDaftarKecamatan, setIsFetchingDaftarKecamatan] = useState(false);
    const [productList, setProductList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [isMatch, setIsMatch] = useState({
        province: false,
        city: false,
        district: false,
    });
    const [activityDate, setActivityDate] = useState(new Date());
    const [selectedActivityMedia, setSelectedActivityMedia] = useState('');
    const [selectedActivityStatus, setSelectedActivityStatus] = useState('');
    const [activityNote, setActivityNote] = useState('');
    const [selectedActivityPICFaskes, setSelectedActivityPICFaskes] = useState(0);
    const [prospectId, setProspectId] = useState('');
    const [selectedRejectionCategory, setSelectedRejectionCategory] = useState({});

    useEffect(()=> {
        if(selectedProspect.id) {
            setProspectId(selectedProspect.id);
            const timer = setTimeout(()=> updateSelectedProspect({}), 1000);
            return () => clearTimeout(timer);
        }
    }, [selectedProspect.id, updateSelectedProspect]);

    useEffect(()=> {
        if(isEdit && isVerifiedEdit) {
            const load = {
                picId: singleFaskesList.picId || '',
                assistPIC: singleFaskesList.picId || '',
                contactStatus: singleFaskesList.contactStatus || '',
                leadsContact: singleFaskesList.leadsContact || '',
                leadsInfo: singleFaskesList.leadsInfo || '',
                event: singleFaskesList.event || '',
                isReferred: typeof singleFaskesList.isReferred !== 'undefined'
                    ? singleFaskesList.isReferred
                    : '',
                referredName: singleFaskesList.referredName || '',
                PIC: singleFaskesList.PIC,
                name: singleFaskesList.name || '',
                type: singleFaskesList.type || '',
                email: singleFaskesList.email || '',
                phoneNumber: singleFaskesList.phoneNumber || '',
                googleMap: singleFaskesList.links.googleMap || '',
                facebook: singleFaskesList.links.facebook || '',
                instagram: singleFaskesList.links.instagram || '',
                website: singleFaskesList.links.website || '',
                street: singleFaskesList.address.street || '',
                province: singleFaskesList.address.province || '',
                city: singleFaskesList.address.city || '',
                district: singleFaskesList.address.district || '',
                postCode: singleFaskesList.address.postCode || '',
                operationalStatus: singleFaskesList.operationalStatus || '',
                MCUAndLabServices: singleFaskesList.MCUAndLabServices || '',
                isJejaring: singleFaskesList.isJejaring || '',
                numberOfBranches: singleFaskesList.numberOfBranches || '',
                isCampaignRunning: typeof singleFaskesList.isCampaignRunning !== 'undefined'
                    ? singleFaskesList.isCampaignRunning
                    : '',
                campaignName: singleFaskesList.campaignName || [],
                isCompetitorPotential: typeof singleFaskesList.isCompetitorPotential !== 'undefined'
                    ? singleFaskesList.isCompetitorPotential
                    : '',
                competitorName: singleFaskesList.competitorName || '',
                competitorLevel: singleFaskesList.competitorLevel || '',
                url: singleFaskesList.url || '',
                isUnknown: typeof singleFaskesList.isUnknown !== 'undefined'
                    ? singleFaskesList.isUnknown
                    : '',
                isAlreadyUsingSystem: typeof singleFaskesList.isAlreadyUsingSystem !== 'undefined'
                    ? singleFaskesList.isAlreadyUsingSystem
                    : '',
                usedSystems: singleFaskesList.usedSystems || [],
                isOwnServerExist: typeof singleFaskesList.isOwnServerExist !== 'undefined'
                    ? singleFaskesList.isOwnServerExist
                    : '',
                isBPJS: singleFaskesList.isBPJS || '',
                isPharmacyExist: singleFaskesList.isPharmacyExist || '',
                neededFeature: singleFaskesList.neededFeature || '',
                hotButton: singleFaskesList.hotButton || '',
                reasonToUsingSystem: singleFaskesList.reasonToUsingSystem || '',
                systemImplementationPlan: singleFaskesList.systemImplementationPlan || '',
                note: singleFaskesList.note || '',
                hotnessLevel: singleFaskesList.hotnessLevel || '',
                closingProductTypePotential: singleFaskesList.closingProductTypePotential || '',
                closingProductDurationPotential: singleFaskesList.closingProductDurationPotential || '',
                closingProductValuePotential: singleFaskesList.closingProductValuePotential || '',
                isCustom: typeof singleFaskesList.isCustom !== 'undefined'
                    ? singleFaskesList.isCustom
                    : '',
                dataCustom: singleFaskesList.dataCustom || '',
                numberOfVisits: singleFaskesList.numberOfVisits || '',
                capitationBPJS: singleFaskesList.capitationBPJS || '',
                isSubscribed: typeof singleFaskesList.isSubscribed !== 'undefined'
                    ? singleFaskesList.isSubscribed
                    : '',
                recontact: singleFaskesList.recontact || '',
                useSystemNote: singleFaskesList.useSystemNote || '',
                useSystemDuration: singleFaskesList.useSystemDuration || '',
                useSystemStartDate: singleFaskesList.useSystemStartDate || null,
                useSystemEndDate: singleFaskesList.useSystemEndDate || null,
                isAnyAnomaly: typeof singleFaskesList.isAnyAnomaly !== 'undefined'
                    ? singleFaskesList.isAnyAnomaly
                    : '',
                resubscribeCounter: typeof singleFaskesList.resubscribeCounter !== 'undefined'
                    ? singleFaskesList.resubscribeCounter
                    : '',
                status: singleFaskesList.status || '',
                rejectionCategory: singleFaskesList.rejectionCategory || '',
                lastContactDate: singleFaskesList.lastContactDate || new Date(),
                cycle: singleFaskesList.cycle || [],
                dataActivity: singleFaskesList.dataActivity || [],
                rejectList: singleFaskesList.rejectList || [],
            };
            daftarProvinsi.filter(async item => {
                if(item.nama === singleFaskesList.address.province) {
                    setIsFetchingDaftarKabupaten(true);
                    const resultDaftarKabupaten = await getDaftarKabupaten(item.code);
                    setDaftarKabupaten(resultDaftarKabupaten.data);
                    setIsFetchingDaftarKabupaten(false);
                    setIsMatch(prevState => ({
                        ...prevState, province: true,
                    }));
                    resultDaftarKabupaten.data.filter(async item => {
                        if(item.nama === singleFaskesList.address.city) {
                            setIsFetchingDaftarKecamatan(true);
                            const resultDaftarKecamatan = await getDaftarKecamatan(item.code);
                            setDaftarKecamatan(resultDaftarKecamatan.data);
                            setIsFetchingDaftarKecamatan(false);
                            setIsMatch(prevState => ({
                                ...prevState, city: true,
                            }));
                            resultDaftarKecamatan.data.forEach(item => {
                                if(item.nama === singleFaskesList.address.district) {
                                    setIsMatch(prevState => ({
                                        ...prevState, district: true,
                                    }));
                                }
                            });
                        } else {
                            setIsMatch(prevState => ({
                                ...prevState, city: false,
                            }));
                        }
                    })
                } else {
                    setIsMatch(prevState => ({
                        ...prevState, province: false,
                    }));
                }
            });
            if(singleFaskesList.closingProductTypePotential && singleFaskesList.closingProductTypePotential !== '') {
                const selected = productList.filter(item => !item.extra.isOld).find($product => {
                    return $product.name === singleFaskesList.closingProductTypePotential;
                });

                if(selected) {
                    load.closingProductTypePotential = selected.id;
                    setSelectedProduct(selected);
                }
            }
            if(singleFaskesList.rejectionCategory && singleFaskesList.rejectionCategory !== '') {
                const selected = rejectionCategoryList.find(item => {
                    return item.name === singleFaskesList.rejectionCategory;
                });

                if(selected) {
                    load.rejectionCategory = selected.id;
                    setSelectedRejectionCategory(selected);
                }
            }
            (async function () {
                const result = await getActivityLatestLeads(singleFaskesList.id);
                if(result.data.length !== 0) {
                    load.lastContactDate = result.data[0].activityDate;
                }
            })().then(()=> setLeads(load));
        }
    }, [isEdit, singleFaskesList, daftarProvinsi, isVerifiedEdit, productList, rejectionCategoryList]);

    useEffect(()=> {
        (function () {
            if(leads.picId){
                if(selectedPICAssist.id === undefined){
                    const selectedPanelaUser = panelaUserList.find($panelaUser => {
                        return $panelaUser.id === leads.picId;
                    });

                    if(selectedPanelaUser) {
                        setSelectedPICAssist(selectedPanelaUser);
                    }
                }
            }
        })();
    });

    useEffect(()=> {
        if(flagFaskesName !== leads.name) {
            setIsFaskesNameExist(false);
            setExistedFaskesName([]);
        }
        if(flagFaskesEmail !== leads.email) {
            setIsFaskesEmailExists(false);
            setExistedFaskesEmail([]);
        }
        if(flagFaskesPhoneNumber !== leads.phoneNumber) {
            setIsFaskesPhoneNumberExists(false);
            setExistedFaskesPhoneNumber([]);
        }
        if(productList.length === 0) {
            ( async () => {
                const result = await getProducts();
                setProductList(result.data);
            })();
        }
    }, [flagFaskesName, leads.name, flagFaskesEmail, leads.email, flagFaskesPhoneNumber, leads.phoneNumber, productList]);

    const handleChange = (e) => {
        if(e.target.name === 'assistPIC') {
            const selectedPanelaUser = panelaUserList.find(item => {
                return item.id === e.target.value;
            });

            if(selectedPanelaUser) {
                setSelectedPICAssist(selectedPanelaUser);
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                    picId: e.target.value,
                });
            }
        } else if(e.target.name === 'closingProductTypePotential') {
            const selected = productList.find(item => {
                return item.id === e.target.value;
            });

            if(selected) {
                setSelectedProduct(selected);
                setLeads({
                    ...leads,
                    closingProductTypePotential: e.target.value,
                    closingProductDurationPotential: 1,
                    closingProductValuePotential: selected.extra.value,
                });
            }
        } else if(e.target.name === 'leadsInfo' || e.target.name === 'leadsContact') {
            if(e.target.value !== 'Event') {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                    event: '',
                });
            } else {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else if(e.target.name === 'isReferred') {
            if(e.target.value === false) {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                    referredName: '',
                });
            } else {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else if(e.target.name === 'email') {
            if(e.target.value === '' || validateEmail.test(e.target.value)) {
                setIsValidatedFaskesEmail(true);
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            } else {
                setIsValidatedFaskesEmail(false);
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else if(e.target.name === 'phoneNumber' ||
            e.target.name === 'numberOfVisits' ||
            e.target.name === 'capitationBPJS' ||
            e.target.name === 'resubscribeCounter' ||
            e.target.name === 'numberOfBranches') {
            if(e.target.value === '' || validateInt.test(e.target.value)) {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else if(e.target.name === 'isCampaignRunning') {
            if(e.target.value === false) {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                    campaignName: [],
                });
            } else {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else if(e.target.name === 'isCompetitorPotential') {
            if(e.target.value === 'Tidak') {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                    competitorName: '',
                    competitorLevel: '',
                });
            } else {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else if(e.target.name === 'isAlreadyUsingSystem') {
            if(e.target.value === false) {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                    usedSystems: [],
                });
            } else {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else if(e.target.name === 'closingProductDurationPotential') {
            const parsedHarga = parseInt(selectedProduct.extra.value, 10);
            setLeads({
                ...leads,
                [e.target.name]: e.target.value,
                closingProductValuePotential: parsedHarga * e.target.value,
            });
        } else if(e.target.name === 'useSystemDuration') {
            if(leads.useSystemStartDate !== null) {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                    useSystemEndDate: addMonth(leads.useSystemStartDate, e.target.value),
                });
            } else {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else if(e.target.name === 'status') {
            if(e.target.value !== 'Rejected') {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                    rejectionCategory: '',
                })
                setSelectedRejectionCategory({});
            } else {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else if(e.target.name === 'rejectionCategory') {
            const selected = rejectionCategoryList.find(item => {
                return item.id === e.target.value;
            });

            if(selected) {
                setSelectedRejectionCategory(selected);
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else if(e.target.name === 'isJejaring') {
            if(e.target.value === 'Tidak' || e.target.value === 'Tidak Tahu') {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                    numberOfBranches: '',
                });
            } else {
                setLeads({
                    ...leads,
                    [e.target.name]: e.target.value,
                });
            }
        } else {
            setLeads({
                ...leads, [e.target.name]: e.target.value,
            });
        }
    };

    const handleChangePIC = (e) => {
        if(e.target.name === 'whatsapp') {
            if(e.target.value === '' || validateInt.test(e.target.value)) {
                leads.PIC[0] = {...leads.PIC[0], [e.target.name]: e.target.value};
                setLeads({
                    ...leads,
                    PIC: leads.PIC,
                });
            }
        } else if(e.target.name === 'emailpic') {
            if(e.target.value === '' || validateEmail.test(e.target.value)) {
                setIsValidatedPICEmail(true);
                leads.PIC[0] = {...leads.PIC[0], [e.target.name]: e.target.value};
                setLeads({
                    ...leads,
                    PIC: leads.PIC,
                });
            } else {
                setIsValidatedPICEmail(false);
                leads.PIC[0] = {...leads.PIC[0], [e.target.name]: e.target.value};
                setLeads({
                    ...leads,
                    PIC: leads.PIC,
                });
            }
        }
        else {
            leads.PIC[0] = {...leads.PIC[0], [e.target.name]: e.target.value};
            setLeads({
                ...leads,
                PIC: leads.PIC,
            });
        }
    };

    const handleCheckFaskesName = async () => {
        const { snackbar } = dispatch;
        if(isEdit && isVerifiedEdit) {
            if(leads.name !== singleFaskesList.name) {
                try {
                    setIsCheckingFaskesName(true);
                    const result = await checkFaskesName(leads.name);
                    const isExists = result.data.count > 0;

                    if(isExists) {
                        const faskesByNameResult = await getFaskesByName(leads.name);
                        setExistedFaskesName(faskesByNameResult.data);
                        setFlagFaskesName(leads.name);
                    }

                    setIsFaskesNameExist(isExists);
                    setIsCheckingFaskesName(false);
                } catch (e) {
                    console.dir(e);
                    snackbar.showError('Gagal cek nama faskes');
                    setIsCheckingFaskesName(false);
                }
            }
        } else {
            try {
                setIsCheckingFaskesName(true);
                const result = await checkFaskesName(leads.name);
                const isExists = result.data.count > 0;

                if(isExists) {
                    const faskesByNameResult = await getFaskesByName(leads.name);
                    setExistedFaskesName(faskesByNameResult.data);
                    setFlagFaskesName(leads.name);
                }

                setIsFaskesNameExist(isExists);
                setIsCheckingFaskesName(false);
            } catch (e) {
                console.dir(e);
                snackbar.showError('Gagal cek nama faskes');
                setIsCheckingFaskesName(false);
            }
        }
    };

    const handleCheckFaskesEmail = async () => {
        const { snackbar } = dispatch;
        if(isEdit && isVerifiedEdit) {
            if(leads.email !== '' && leads.email !== singleFaskesList.email) {
                try {
                    setIsCheckingFaskesEmail(true);
                    const result = await checkFaskesEmail(leads.email);
                    const isExists = result.data.count > 0;

                    if(isExists) {
                        const faskesByEmailResult = await getFaskesByEmail(leads.email);
                        setExistedFaskesEmail(faskesByEmailResult.data);
                        setFlagFaskesEmail(leads.email);
                    }

                    setIsFaskesEmailExists(isExists);
                    setIsCheckingFaskesEmail(false);
                } catch (e) {
                    console.dir(e);
                    snackbar.showError('Gagal cek email faskes');
                    setIsCheckingFaskesEmail(false);
                }
            }
        } else {
            if(leads.email !== '') {
                try {
                    setIsCheckingFaskesEmail(true);
                    const result = await checkFaskesEmail(leads.email);
                    const isExists = result.data.count > 0;

                    if(isExists) {
                        const faskesByEmailResult = await getFaskesByEmail(leads.email);
                        setExistedFaskesEmail(faskesByEmailResult.data);
                        setFlagFaskesEmail(leads.email);
                    }

                    setIsFaskesEmailExists(isExists);
                    setIsCheckingFaskesEmail(false);
                } catch (e) {
                    console.dir(e);
                    snackbar.showError('Gagal cek email faskes');
                    setIsCheckingFaskesEmail(false);
                }
            }
        }
    };

    const handleCheckFaskesPhoneNumber = async () => {
        const { snackbar } = dispatch;
        if(isEdit && isVerifiedEdit) {
            if(leads.phoneNumber !== '' && leads.phoneNumber !== singleFaskesList.phoneNumber) {
                try {
                    setIsCheckingFaskesPhoneNumber(true);
                    const result = await checkFaskesPhoneNumber(leads.phoneNumber);
                    const isExists = result.data.count > 0;

                    if(isExists) {
                        const faskesByPhoneNumberResult = await getFaskesByPhoneNumber(leads.phoneNumber);
                        setExistedFaskesPhoneNumber(faskesByPhoneNumberResult.data);
                        setFlagFaskesPhoneNumber(leads.phoneNumber);
                    }

                    setIsFaskesPhoneNumberExists(isExists);
                    setIsCheckingFaskesPhoneNumber(false);
                } catch (e) {
                    console.dir(e);
                    snackbar.showError('Gagal cek no telepon faskes');
                    setIsCheckingFaskesPhoneNumber(false);
                }
            }
        } else {
            if(leads.phoneNumber !== '') {
                try {
                    setIsCheckingFaskesPhoneNumber(true);
                    const result = await checkFaskesPhoneNumber(leads.phoneNumber);
                    const isExists = result.data.count > 0;

                    if(isExists) {
                        const faskesByPhoneNumberResult = await getFaskesByPhoneNumber(leads.phoneNumber);
                        setExistedFaskesPhoneNumber(faskesByPhoneNumberResult.data);
                        setFlagFaskesPhoneNumber(leads.phoneNumber);
                    }

                    setIsFaskesPhoneNumberExists(isExists);
                    setIsCheckingFaskesPhoneNumber(false);
                } catch (e) {
                    console.dir(e);
                    snackbar.showError('Gagal cek no telepon faskes');
                    setIsCheckingFaskesPhoneNumber(false);
                }
            }
        }
    };

    const handleChangeRegion = (e) => {
        const { name, value } = e.target;
        const { snackbar } = dispatch;
        if(name === 'province') {
            daftarProvinsi.forEach(async item => {
                if(item.nama === value) {
                    try {
                        setIsFetchingDaftarKabupaten(true);
                        const result = await getDaftarKabupaten(item.code);
                        setDaftarKabupaten(result.data);
                        setLeads({
                            ...leads,
                            province: value,
                            city: '',
                            district: '',
                        });
                        if(isEdit && isVerifiedEdit) {
                            setIsMatch({
                                province: true,
                                city: true,
                                district: true,
                            });
                        }
                        setIsFetchingDaftarKabupaten(false);
                    } catch (e) {
                        console.dir(e);
                        snackbar.showError('Gagal mendapatkan daftar kabupaten/kota');
                        setIsFetchingDaftarKabupaten(false);
                    }
                }
            });
        } else if(name === 'city') {
            daftarKabupaten.forEach(async item => {
                if(item.nama === value) {
                    try {
                        setIsFetchingDaftarKecamatan(true);
                        const result = await getDaftarKecamatan(item.code);
                        setDaftarKecamatan(result.data);
                        setLeads({
                            ...leads,
                            city: value,
                            district: '',
                        });
                        if(isEdit && isVerifiedEdit) {
                            setIsMatch({
                                ...isMatch,
                                city: true,
                                district: true,
                            });
                        }
                        setIsFetchingDaftarKecamatan(false);
                    } catch (e) {
                        console.dir(e);
                        snackbar.showError('Gagal mendapatkan daftar kecamatan');
                        setIsFetchingDaftarKecamatan(false);
                    }
                }
            });
        } else if(name === 'district') {
            setLeads({
                ...leads,
                district: value,
            });
            if(isEdit && isVerifiedEdit) {
                setIsMatch({
                    ...isMatch,
                    district: true,
                });
            }
        }
    };

    function handleChangeHotnessLevel(label) {
        setLeads({
            ...leads,
            hotnessLevel: label,
        });
    }

    function handleEditUsedSystems(idx) {
        leads.usedSystems.splice(idx, 1);
        setLeads({
            ...leads,
            usedSystems: leads.usedSystems,
        });
    }

    function handleEditCampaignName(idx) {
        leads.campaignName.splice(idx, 1);
        setLeads({
            ...leads,
            campaignName: leads.campaignName,
        });
    }

    const handleChangeUseSystemStartDate = (date) => {
        if(leads.useSystemDuration === '') {
            setLeads({
                ...leads,
                useSystemStartDate: date,
            });
        } else {
            setLeads({
                ...leads,
                useSystemStartDate: date,
                useSystemEndDate: addMonth(date, leads.useSystemDuration),
            });
        }
    };

    const handleChangeUseSystemEndDate = (date) => {
        setLeads({
            ...leads,
            useSystemEndDate: date,
        });
    };

    const handleChangeActivityDate = (date) => {
        setActivityDate(date);
    };

    const handleChangeSelectedActivityPICFaskes = (e) => {
        setSelectedActivityPICFaskes(e.target.value);
    }

    const handleChangeSelectedActivityMedia = (e) => {
        setSelectedActivityMedia(e.target.value);
    };

    const handleChangeSelectedActivityStatus = (e) => {
        setSelectedActivityStatus(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { snackbar } = dispatch;
        if(leads.assistPIC === '' || leads.picId === '') {
            snackbar.showError('Silahkan pilih pic assist.id');
        } else if(isFaskesNameExists) {
            snackbar.showError('Gagal menambahkan leads, karena nama sudah digunakan')
        } else if(leads.status === '') {
            snackbar.showError('Silahkan pilih status saat ini terlebih dahulu');
        } else if(leads.status === 'Rejected' && leads.rejectionCategory === '') {
            snackbar.showError('Silahkan pilih kategori penolakan terlebih dahulu');
        } else {
            if(isEdit && isVerifiedEdit) {
                const flag = {...leads};
                flag.id = singleFaskesList.id;
                flag.updatedId = currentUser.id;
                flag.assistPIC = `${selectedPICAssist.firstName} ${selectedPICAssist.lastName}`;
                flag.closingProductTypePotential = selectedProduct.name || '';
                flag.rejectionCategory = selectedRejectionCategory.name || '';
                if(flag.cycle.length === 0) { //kondisi ketika data lama (tidak ada property cycle)
                    // data lama tidak perlu di set cycle lagi (tidak ada aksi ke cycle) *update baru
                    flag.cycle = [];
                    // flag.cycle = [{
                    //     startDate: new Date(),
                    //     endDate: leads.status === 'Closing' || leads.status === 'Rejected'
                    //         ? new Date() : null,
                    //     assistPIC: `${selectedPICAssist.firstName} ${selectedPICAssist.lastName}`,
                    //     lastStatus: leads.status,
                    //     note: '',
                    //     status: [],
                    // }]
                    // if(leads.status === 'Closing') {
                    //     const newItem = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Closing'];
                    //     flag.dataActivity = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Closing'];
                    //     flag.cycle[0].status = newItem;
                    // } else if(leads.status === 'Rejected') {
                    //     const newItem = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Rejected'];
                    //     flag.dataActivity = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Rejected'];
                    //     flag.cycle[0].status = newItem;
                    //     flag.cycle[0].note = selectedRejectionCategory.name;
                    //     flag.rejectList.push({
                    //         createdAt: new Date(),
                    //         lastStatus: leads.status,
                    //         rejectionName: selectedRejectionCategory.name,
                    //     });
                    // } else {
                    //     flag.cycle[0].status.push(leads.status);
                    //     flag.dataActivity.push(leads.status);
                    // }
                } else {
                    const lastIndex = flag.cycle.length - 1;
                    if(leads.status !== flag.cycle[lastIndex].lastStatus) {
                        if(flag.cycle[lastIndex].lastStatus === 'Closing' || flag.cycle[lastIndex].lastStatus === 'Rejected') {
                            const newCycle = {
                                startDate: new Date(),
                                endDate: leads.status === 'Closing' || leads.status === 'Rejected'
                                    ? new Date() : null,
                                assistPIC: `${selectedPICAssist.firstName} ${selectedPICAssist.lastName}`,
                                lastStatus: leads.status,
                                note: '',
                                status: [],
                            };
                            if(leads.status === 'Closing') {
                                const newItem = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Closing'];
                                flag.dataActivity = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Closing'];
                                newCycle.status = newItem;
                            } else if(leads.status === 'Rejected') {
                                const newItem = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Rejected'];
                                flag.dataActivity = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Rejected'];
                                newCycle.status = newItem;
                                newCycle.note = selectedRejectionCategory.name;
                                flag.rejectList.push({
                                    createdAt: new Date(),
                                    lastStatus: leads.status,
                                    rejectionName: selectedRejectionCategory.name,
                                });
                            } else {
                                newCycle.status.push(leads.status);
                                flag.dataActivity = newCycle.status;
                            }
                            flag.cycle.push(newCycle);
                        } else {
                            flag.cycle[lastIndex].endDate = leads.status === 'Closing' || leads.status === 'Rejected' ? new Date() : null;
                            flag.cycle[lastIndex].assistPIC = `${selectedPICAssist.firstName} ${selectedPICAssist.lastName}`;
                            flag.cycle[lastIndex].lastStatus = leads.status;
                            if(leads.status === 'Closing') {
                                const newItem = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Closing'];
                                flag.dataActivity = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Closing'];
                                flag.cycle[lastIndex].status = newItem;
                            } else if(leads.status === 'Rejected') {
                                const newItem = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Rejected'];
                                flag.dataActivity = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Rejected'];
                                flag.cycle[lastIndex].status = newItem;
                                flag.cycle[lastIndex].note = selectedRejectionCategory.name;
                                flag.rejectList.push({
                                    createdAt: new Date(),
                                    lastStatus: leads.status,
                                    rejectionName: selectedRejectionCategory.name,
                                });
                            } else {
                                flag.cycle[lastIndex].status.push(leads.status);
                                flag.dataActivity = flag.cycle[lastIndex].status;
                            }
                        }
                    }
                }
                const selectedPICFaskes = leads.PIC[selectedActivityPICFaskes];
                const flagActivityPayload = {
                    healthFacilityId: singleFaskesList.id,
                    picId: leads.picId,
                    healthFacilityPic: selectedPICFaskes,
                    activityDate,
                    note: activityNote === '' ? 'Edit data leads' : activityNote,
                    media: selectedActivityMedia === '' ? 'Intercom' : selectedActivityMedia,
                    status: selectedActivityStatus === '' ? leads.status : selectedActivityStatus,
                    createdAt: new Date(),
                    createdId: currentUser.id,
                };
                if(singleFaskesList.status !== 'Rejected' && leads.status === 'Rejected') {
                    const flagActivityRejectedPayload = {
                        healthFacilityId: singleFaskesList.id,
                        picId: flag.picId,
                        healthFacilityPic: selectedPICFaskes,
                        rejectionDate: new Date(),
                        rejectionCategoryId: selectedRejectionCategory.id,
                        rejectionName: selectedRejectionCategory.name,
                        rejectionDetail: '',
                        createdAt: new Date(),
                        createdId: currentUser.id,
                    };
                    addActivityRejection(flagActivityRejectedPayload);
                }
                addActivity(flagActivityPayload)
                    .then(()=> editLeads(flag)
                        .then(()=> history.goBack())
                    );
            } else {
                const flag = {...leads};
                flag.createdId = currentUser.id;
                flag.assistPIC = `${selectedPICAssist.firstName} ${selectedPICAssist.lastName}`;
                flag.closingProductTypePotential = selectedProduct.name || '';
                flag.rejectionCategory = selectedRejectionCategory.name || '';
                flag.cycle = [{
                    startDate: new Date(),
                    endDate: leads.status === 'Closing' || leads.status === 'Rejected'
                        ? new Date() : null,
                    assistPIC: `${selectedPICAssist.firstName} ${selectedPICAssist.lastName}`,
                    lastStatus: leads.status,
                    note: '',
                    status: [],
                }];
                flag.dataActivity = [];
                flag.rejectList = [];
                if(leads.status === 'Closing') {
                    const newItem = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Closing'];
                    flag.dataActivity = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Closing'];
                    flag.cycle[0].status = newItem;
                } else if(leads.status === 'Rejected') {
                    const newItem = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Rejected'];
                    flag.dataActivity = ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Rejected'];
                    flag.cycle[0].status = newItem;
                    flag.cycle[0].note = selectedRejectionCategory.name;
                    flag.rejectList.push({
                        createdAt: new Date(),
                        lastStatus: leads.status,
                        rejectionName: selectedRejectionCategory.name,
                    });
                } else {
                    flag.cycle[0].status.push(leads.status);
                    flag.dataActivity.push(leads.status);
                }
                if(prospectId !== '') {
                    flag.prospectId = prospectId;
                }
                addLeads(flag).then(function (res) {
                    if(leads.status === 'Rejected') {
                        const selectedPICFaskes = leads.PIC[selectedActivityPICFaskes];
                        const flagActivityRejectedPayload = {
                            healthFacilityId: res,
                            picId: flag.picId,
                            healthFacilityPic: selectedPICFaskes,
                            rejectionDate: new Date(),
                            rejectionCategoryId: selectedRejectionCategory.id,
                            rejectionName: selectedRejectionCategory.name,
                            rejectionDetail: '',
                            createdAt: new Date(),
                            createdId: currentUser.id,
                        };
                        addActivityRejection(flagActivityRejectedPayload);
                    }
                    history.push('/leads');
                });
            }
        }
    };

    return (
        <div className={classes.content}>
            <div className={classes.body} style={isViewedByMobile ? {height: '95%'} : {height: '85%'}}>
                <Container className={classes.container}>
                    <Grid container>
                        <Grid item xs={12}>
                            {!isFetchingSingleFaskesList && <div className={classes.labelAddWrap}>
                                <Link to={isEdit && isVerifiedEdit ? "../detail/" + singleFaskesList.id : "../leads"}>
                                    <FontAwesomeIcon icon={faArrowLeft} className={classes.arrowBack}/>
                                </Link>
                                <Typography
                                    className={classes.labelAdd}>{isEdit && isVerifiedEdit ? 'Edit Leads' : 'Tambah Leads Baru'}</Typography>
                            </div>}
                            {isFetchingSingleFaskesList && <div style={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress/>
                            </div>}
                            {!isFetchingSingleFaskesList && <form onSubmit={handleSubmit}>
                                <Typography className={classes.labelStep}>Data Background</Typography>
                                <Grid container className={classes.gridWrap}>
                                    <Grid item xs={6}>
                                        <div className={classes.formLeft}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>PIC Assist.id</Typography>
                                                <PInput
                                                    name='assistPIC'
                                                    fullWidth
                                                    select
                                                    value={leads.picId}
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        required: true,
                                                    }}
                                                >
                                                    {
                                                        panelaUserList.map(user => (
                                                            <MenuItem key={user.id} value={user.id}>
                                                                {`${user.firstName} ${user.lastName}`}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Datang Sendiri / Kita
                                                    Menghubungi?</Typography>
                                                <PInput
                                                    name='contactStatus'
                                                    fullWidth
                                                    select
                                                    value={leads.contactStatus}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="datang sendiri">Datang Sendiri</MenuItem>
                                                    <MenuItem value="dihubungi">Dihubungi</MenuItem>
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>
                                                    {
                                                        leads.contactStatus === 'datang sendiri'
                                                            ? 'Menghubungi Assist Menggunakan?'
                                                            : 'Menghubungi Customer Menggunakan?'
                                                    }
                                                </Typography>
                                                <PInput
                                                    name='leadsContact'
                                                    fullWidth
                                                    select
                                                    value={leads.leadsContact}
                                                    onChange={handleChange}
                                                >
                                                    {
                                                        leads.contactStatus === 'datang sendiri'
                                                            ? mediaList.filter(item => item.name === 'Intercom' || item.name === 'Whatsapp' || item.name === 'Web Form' || item.name === 'Mobile Assist.id' || item.name === 'Event' || item.name === 'Instagram' || item.name === 'Facebook').map(media => (
                                                                <MenuItem key={media.id} value={media.name}>
                                                                    {media.name}
                                                                </MenuItem>
                                                            ))
                                                            : mediaList.filter(item => item.name === 'Whatsapp' || item.name === 'SMS' || item.name === 'Telepon' || item.name === 'Tatap Langsung' || item.name === 'Email').map(media => (
                                                                <MenuItem key={media.id} value={media.name}>
                                                                    {media.name}
                                                                </MenuItem>
                                                            ))
                                                    }
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>
                                                    {
                                                        leads.contactStatus === 'datang sendiri'
                                                            ? 'Mengetahui Assist Dari? (Jangan Asumsi)'
                                                            : 'Mendapatkan Info Customer Dari?'
                                                    }
                                                </Typography>
                                                <PInput
                                                    name='leadsInfo'
                                                    fullWidth
                                                    select
                                                    value={leads.leadsInfo}
                                                    onChange={handleChange}
                                                >
                                                    {
                                                        leads.contactStatus === 'datang sendiri'
                                                            ? mediaList.filter(item => item.name === 'Google' || item.name === 'Facebook' || item.name === 'Instagram' || item.name === 'Friend' || item.name === 'Mobile Assist.id' || item.name === 'Event').map(media => (
                                                                <MenuItem key={media.id} value={media.name}>
                                                                    {media.name}
                                                                </MenuItem>
                                                            ))
                                                            : mediaList.filter(item => item.name === 'Google' || item.name === 'Facebook' || item.name === 'Instagram' || item.name === 'Friend' || item.name === 'Referral' || item.name === 'Canvassing').map(media => (
                                                                <MenuItem key={media.id} value={media.name}>
                                                                    {media.name}
                                                                </MenuItem>
                                                            ))
                                                    }
                                                </PInput>
                                            </Grid>
                                            {(leads.leadsInfo === 'Event' || leads.leadsContact === 'Event') &&
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Nama Event</Typography>
                                                <PInput
                                                    name='event'
                                                    fullWidth
                                                    select
                                                    value={leads.event}
                                                    onChange={handleChange}
                                                >
                                                    {
                                                        eventNameList.map(event => (
                                                            <MenuItem key={event.id} value={event.name}>
                                                                {event.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </PInput>
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Ada Yang
                                                    Mereferralkan?</Typography>
                                                <PInput
                                                    name='isReferred'
                                                    fullWidth
                                                    select
                                                    value={leads.isReferred}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={true}>Ada</MenuItem>
                                                    <MenuItem value={false}>Tidak</MenuItem>
                                                </PInput>
                                            </Grid>
                                            {leads.isReferred === true &&
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Siapa Yang
                                                    Mereferralkan?</Typography>
                                                <PInput
                                                    name='referredName'
                                                    fullWidth
                                                    value={leads.referredName}
                                                    onChange={handleChange}
                                                />
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>PIC Faskes *</Typography>
                                                <PInput
                                                    name='namepic'
                                                    fullWidth
                                                    value={leads.PIC[0].namepic}
                                                    onChange={handleChangePIC}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Jabatan PIC Klinik</Typography>
                                                <PInput
                                                    name='position'
                                                    fullWidth
                                                    value={leads.PIC[0].position}
                                                    onChange={handleChangePIC}
                                                />
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} style={{marginBottom: 15}}>
                                                    <Typography className={classes.labelField}>Email PIC
                                                        Klinik</Typography>
                                                    <PInput
                                                        name='emailpic'
                                                        fullWidth
                                                        value={leads.PIC[0].emailpic}
                                                        onChange={handleChangePIC}
                                                        error={!isValidatedPICEmail}
                                                        helperText={!isValidatedPICEmail ? 'Email is not valid!' : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{marginBottom: 15}}>
                                                    <Typography className={classes.labelField}>No Telepon PIC
                                                        Klinik *</Typography>
                                                    <PInput
                                                        name='whatsapp'
                                                        fullWidth
                                                        value={leads.PIC[0].whatsapp}
                                                        onChange={handleChangePIC}
                                                        required
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Nama Faskes *</Typography>
                                                <PInput
                                                    name='name'
                                                    fullWidth
                                                    value={leads.name}
                                                    onChange={handleChange}
                                                    onBlur={handleCheckFaskesName}
                                                    InputProps={{
                                                        endAdornment: isCheckingFaskesName
                                                            ? <InputAdornment position='end'>
                                                                <FontAwesomeIcon icon={faSpinner} spin/>
                                                            </InputAdornment>
                                                            : <InputAdornment position='end'>
                                                                <FontAwesomeIcon
                                                                    icon={faExclamationCircle}
                                                                    aria-owns={open ? 'mouse-over' : undefined}
                                                                    aria-haspopup='true'
                                                                    onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                                                                    onMouseLeave={() => setAnchorEl(null)}
                                                                />
                                                            </InputAdornment>
                                                    }}
                                                    required
                                                />
                                                <Popover
                                                    id='mouse-over'
                                                    className={classes.popover}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                    onClose={() => setAnchorEl(null)}
                                                    disableRestoreFocus
                                                >
                                                    <div style={{padding: '15px 15px 15px 15px'}}>
                                                        <Typography className={classes.information}>Tata cara penulisan
                                                            nama faskes:</Typography>
                                                        <Typography className={classes.information}>1). Tidak boleh
                                                            typo.</Typography>
                                                        <Typography className={classes.information}>2). Gunakan huruf
                                                            besar setiap awal huruf</Typography>
                                                        <Typography className={classes.information}>contoh: Klinik
                                                            Pratama Mutiara.</Typography>
                                                        <Typography className={classes.information}>3). Bila nama belum
                                                            ada, silahkan menuliskan nama</Typography>
                                                        <Typography className={classes.information}>PICnya dengan dalam
                                                            kurung (Nama Klinik Belum Ada).</Typography>
                                                        <Typography className={classes.information}>Atau disesuaikan
                                                            dengan apotek dan jenis faskes lainnya</Typography>
                                                        <Typography className={classes.information}>contoh : Klinik Bu
                                                            Didi (Nama Klinik Belum Ada).</Typography>
                                                    </div>
                                                </Popover>
                                                {isFaskesNameExists && <Typography className={classes.helperText}>
                                                    Faskes sudah terdaftar di
                                                    kota {existedFaskesName.map(item => item.address.city || '').join(', ')}
                                                </Typography>}
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Jenis Faskes</Typography>
                                                <PInput
                                                    name='type'
                                                    fullWidth
                                                    select
                                                    value={leads.type}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"RS Kelas A"}>RS Kelas A</MenuItem>
                                                    <MenuItem value={"RS Kelas B"}>RS Kelas B</MenuItem>
                                                    <MenuItem value={"RS Kelas C"}>RS Kelas C</MenuItem>
                                                    <MenuItem value={"RS Kelas D"}>RS Kelas D</MenuItem>
                                                    <MenuItem value={"Puskesmas"}>Puskesmas</MenuItem>
                                                    <MenuItem value={"Klinik Pratama"}>Klinik Pratama</MenuItem>
                                                    <MenuItem value={"Klinik Utama"}>Klinik Utama</MenuItem>
                                                    <MenuItem value={"Klinik Gigi"}>Klinik Gigi</MenuItem>
                                                    <MenuItem value={"Klinik Kecantikan"}>Klinik Kecantikan</MenuItem>
                                                    <MenuItem value={"Klinik Hemodialisis / Hemodialisa"}>Klinik
                                                        Hemodialisis / Hemodialisa</MenuItem>
                                                    <MenuItem value={"Lab"}>Lab</MenuItem>
                                                    <MenuItem value={"Apotek"}>Apotek</MenuItem>
                                                    <MenuItem value={"Optik"}>Optik</MenuItem>
                                                    <MenuItem value={"Praktek Mandiri"}>Praktek Mandiri</MenuItem>
                                                    <MenuItem value={"Rumah Bersalin"}>Rumah Bersalin</MenuItem>
                                                    <MenuItem value={"Klinik Perusahaan / Instansi"}>Klinik Perusahaan /
                                                        Instansi</MenuItem>
                                                    <MenuItem value={"Bukan Faskes"}>Bukan Faskes</MenuItem>
                                                    <MenuItem value={"Tidak Tahu"}>Tidak Tahu</MenuItem>
                                                </PInput>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} style={{marginBottom: 5}}>
                                                    <Typography className={classes.labelField}>Email Klinik</Typography>
                                                    <PInput
                                                        name='email'
                                                        fullWidth
                                                        value={leads.email}
                                                        onChange={handleChange}
                                                        onBlur={handleCheckFaskesEmail}
                                                        error={!isValidatedFaskesEmail}
                                                        helperText={!isValidatedFaskesEmail ? 'Email is not valid!' : null}
                                                        InputProps={{
                                                            endAdornment: isCheckingFaskesEmail
                                                                ? <InputAdornment position='end'>
                                                                    <FontAwesomeIcon icon={faSpinner} spin/>
                                                                </InputAdornment>
                                                                : null
                                                        }}
                                                    />
                                                    {isFaskesEmailExists && <Typography className={classes.helperText}>
                                                        Email sudah terdaftar di
                                                        faskes {existedFaskesEmail.map(item => item.name || '').join(', ')}
                                                    </Typography>}
                                                </Grid>
                                                <Grid item xs={6} style={{marginBottom: 5}}>
                                                    <Typography className={classes.labelField}>No Telpon
                                                        Klinik</Typography>
                                                    <PInput
                                                        name='phoneNumber'
                                                        fullWidth
                                                        value={leads.phoneNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleCheckFaskesPhoneNumber}
                                                        InputProps={{
                                                            endAdornment: isCheckingFaskesPhoneNumber
                                                                ? <InputAdornment position='end'>
                                                                    <FontAwesomeIcon icon={faSpinner} spin/>
                                                                </InputAdornment>
                                                                : null
                                                        }}
                                                    />
                                                    {isFaskesPhoneNumberExists &&
                                                    <Typography className={classes.helperText}>
                                                        No Telepon sudah terdatar di
                                                        faskes {existedFaskesPhoneNumber.map(item => item.name || '').join(', ')}
                                                    </Typography>}
                                                </Grid>
                                                <Grid item xs={6} style={{marginBottom: 5}}>
                                                    <Typography className={classes.labelField}>Google Map</Typography>
                                                    <PInput
                                                        name='googleMap'
                                                        fullWidth
                                                        value={leads.googleMap}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{marginBottom: 5}}>
                                                    <Typography className={classes.labelField}>Instagram</Typography>
                                                    <PInput
                                                        name='instagram'
                                                        fullWidth
                                                        value={leads.instagram}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.formRight}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} style={{marginBottom: 15}}>
                                                    <Typography className={classes.labelField}>Website</Typography>
                                                    <PInput
                                                        name='website'
                                                        fullWidth
                                                        value={leads.website}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{marginBottom: 15}}>
                                                    <Typography className={classes.labelField}>Facebook</Typography>
                                                    <PInput
                                                        name='facebook'
                                                        fullWidth
                                                        value={leads.facebook}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Alamat Faskes</Typography>
                                                <PInput
                                                    name='street'
                                                    fullWidth
                                                    value={leads.street}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} style={{marginBottom: 7}}>
                                                    <Typography className={classes.labelField}>Provinsi</Typography>
                                                    <PInput
                                                        name='province'
                                                        fullWidth
                                                        select
                                                        value={leads.province}
                                                        onChange={handleChangeRegion}
                                                        InputProps={{
                                                            startAdornment: isFetchingDaftarKabupaten
                                                                ? <InputAdornment position='start'>
                                                                    <FontAwesomeIcon icon={faSpinner} spin/>
                                                                </InputAdornment>
                                                                : null
                                                        }}
                                                    >
                                                        {
                                                            daftarProvinsi.map((item, idx) => (
                                                                <MenuItem key={idx} value={item.nama}>
                                                                    {item.nama}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </PInput>
                                                    {isEdit && !isMatch.province && <Typography className={classes.helperText}>
                                                        Silahkan diupdate, {leads.province}
                                                    </Typography>}
                                                </Grid>
                                                <Grid item xs={6} style={{marginBottom: 7}}>
                                                    <Typography
                                                        className={classes.labelField}>Kabupaten/Kota</Typography>
                                                    <PInput
                                                        name='city'
                                                        fullWidth
                                                        select
                                                        value={leads.city}
                                                        onChange={handleChangeRegion}
                                                        InputProps={{
                                                            startAdornment: isFetchingDaftarKecamatan
                                                                ? <InputAdornment position='start'>
                                                                    <FontAwesomeIcon icon={faSpinner} spin/>
                                                                </InputAdornment>
                                                                : null
                                                        }}
                                                        disabled={leads.province === ''}
                                                    >
                                                        {daftarKabupaten.map((item, idx) => (
                                                            <MenuItem key={idx} value={item.nama}>
                                                                {item.nama}
                                                            </MenuItem>
                                                        ))}
                                                    </PInput>
                                                    {isEdit && !isMatch.city && <Typography className={classes.helperText}>
                                                        Silahkan diupdate, {leads.city}
                                                    </Typography>}
                                                </Grid>
                                                <Grid item xs={6} style={{marginBottom: 15}}>
                                                    <Typography className={classes.labelField}>Kecamatan</Typography>
                                                    <PInput
                                                        name='district'
                                                        fullWidth
                                                        select
                                                        value={leads.district}
                                                        onChange={handleChangeRegion}
                                                        disabled={leads.city === ''}
                                                    >
                                                        {daftarKecamatan.map((item, idx) => (
                                                            <MenuItem key={idx} value={item.nama}>
                                                                {item.nama}
                                                            </MenuItem>
                                                        ))}
                                                    </PInput>
                                                    {isEdit && !isMatch.district && <Typography className={classes.helperText}>
                                                        Silahkan diupdate, {leads.district}
                                                    </Typography>}
                                                </Grid>
                                                <Grid item xs={6} style={{marginBottom: 15}}>
                                                    <Typography className={classes.labelField}>Kode Pos</Typography>
                                                    <PInput
                                                        name='postCode'
                                                        fullWidth
                                                        value={leads.postCode}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Faskes Sudah
                                                    Beroperasional?</Typography>
                                                <PInput
                                                    name='operationalStatus'
                                                    fullWidth
                                                    select
                                                    value={leads.operationalStatus}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="Sudah Beroperasional">Sudah
                                                        Beroperasional</MenuItem>
                                                    <MenuItem value="Belum Beroperasional Sudah Ada Izin">Belum
                                                        Beroperasional Sudah Ada Izin</MenuItem>
                                                    <MenuItem value="Belum Beroperasional Belum Ada Izin">Belum
                                                        Beroperasional Belum Ada Izin</MenuItem>
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Memiliki Layanan MCU dan Lab?</Typography>
                                                <PInput
                                                    name='MCUAndLabServices'
                                                    fullWidth
                                                    select
                                                    value={leads.MCUAndLabServices}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="Tidak Tahu">Tidak Tahu</MenuItem>
                                                    <MenuItem value="Ada MCU Dan Lab">Ada MCU Dan Lab</MenuItem>
                                                    <MenuItem value="Ada MCU">Ada MCU</MenuItem>
                                                    <MenuItem value="Ada Lab">Ada Lab</MenuItem>
                                                    <MenuItem value="Tidak Ada MCU dan Lab">Tidak Ada MCU Dan Lab</MenuItem>
                                                    <MenuItem value="MCU Dan Lab Kerjasama Diluar Klinik">MCU Dan Lab Kerjasama Diluar Klinik</MenuItem>
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Klinik Jejaring?</Typography>
                                                <PInput
                                                    name='isJejaring'
                                                    fullWidth
                                                    select
                                                    value={leads.isJejaring}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value='Iya'>Iya</MenuItem>
                                                    <MenuItem value='Tidak'>Tidak</MenuItem>
                                                    <MenuItem value='Tidak Tahu'>Tidak Tahu</MenuItem>
                                                </PInput>
                                            </Grid>
                                            {leads.isJejaring === 'Iya' && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Berapa Jumlah Cabangnya?</Typography>
                                                <PInput
                                                    name='numberOfBranches'
                                                    fullWidth
                                                    value={leads.numberOfBranches}
                                                    onChange={handleChange}
                                                />
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Sedang Ada
                                                    Iklan?</Typography>
                                                <PInput
                                                    name='isCampaignRunning'
                                                    fullWidth
                                                    select
                                                    value={leads.isCampaignRunning}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={true}>Iya</MenuItem>
                                                    <MenuItem value={false}>Tidak</MenuItem>
                                                </PInput>
                                            </Grid>
                                            {leads.isCampaignRunning === true &&
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Nama Iklan</Typography>
                                                <PInput
                                                    name='campaignName'
                                                    fullWidth
                                                    select
                                                    SelectProps={{
                                                        multiple: true,
                                                        value: leads.campaignName,
                                                    }}
                                                    onChange={handleChange}
                                                >
                                                    {iklanList.map((item, idx) => (
                                                        <MenuItem key={idx} value={item.name}>
                                                            {`${item.name} (${item.adsPosition})`}
                                                        </MenuItem>
                                                    ))}
                                                </PInput>
                                            </Grid>}
                                            {leads.campaignName.length > 0 && <Grid container>
                                                {leads.campaignName.map((item, idx) => (
                                                    <Grid key={idx} item xs={4} style={{marginBottom: 5}}>
                                                        <Chip
                                                            label={
                                                                <span className={classes.labelChip}>{item}</span>
                                                            }
                                                            color='primary'
                                                            onDelete={() => handleEditCampaignName(idx)}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Potensi
                                                    Kompetitor</Typography>
                                                <PInput
                                                    name='isCompetitorPotential'
                                                    fullWidth
                                                    select
                                                    value={leads.isCompetitorPotential}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={'Iya'}>Iya</MenuItem>
                                                    <MenuItem value={'Tidak'}>Tidak</MenuItem>
                                                    <MenuItem value={'Mungkin'}>Mungkin</MenuItem>
                                                </PInput>
                                            </Grid>
                                            {leads.isCompetitorPotential === 'Iya' &&
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Nama Kompetitor</Typography>
                                                <PInput
                                                    name='competitorName'
                                                    fullWidth
                                                    select
                                                    value={leads.competitorName}
                                                    onChange={handleChange}
                                                >
                                                    {kompetitorList.map((item, idx) => (
                                                        <MenuItem key={idx} value={item.name}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </PInput>
                                            </Grid>}
                                            {leads.isCompetitorPotential === 'Iya' &&
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Level Kompetitor</Typography>
                                                <PInput
                                                    name='competitorLevel'
                                                    fullWidth
                                                    select
                                                    value={leads.competitorLevel}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={'Warning'}>Warning</MenuItem>
                                                    <MenuItem value={'Block'}>Block</MenuItem>
                                                </PInput>
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Leads Tidak Jelas?</Typography>
                                                <PInput
                                                    name='isUnknown'
                                                    fullWidth
                                                    select
                                                    value={leads.isUnknown}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={true}>Tidak Jelas</MenuItem>
                                                    <MenuItem value={false}>Jelas</MenuItem>
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Preferensi</Typography>
                                                <PInput
                                                    name='url'
                                                    fullWidth
                                                    value={leads.url}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.labelStep}>Data Fitur</Typography>
                                <Grid container spacing={1} className={classes.gridWrap}>
                                    <Grid item xs={6}>
                                        <div className={classes.formLeft}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Sudah Pakai Sistem
                                                    Sebelumnya?</Typography>
                                                <PInput
                                                    name='isAlreadyUsingSystem'
                                                    fullWidth
                                                    select
                                                    value={leads.isAlreadyUsingSystem}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={true}>Sudah</MenuItem>
                                                    <MenuItem value={false}>Belum</MenuItem>
                                                </PInput>
                                            </Grid>
                                            {leads.isAlreadyUsingSystem === true &&
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Sistem apa saja?</Typography>
                                                <PInput
                                                    name='usedSystems'
                                                    fullWidth
                                                    select
                                                    SelectProps={{
                                                        multiple: true,
                                                        value: leads.usedSystems,
                                                    }}
                                                    onChange={handleChange}
                                                >
                                                    {kompetitorList.map((item, idx) => (
                                                        <MenuItem key={idx} value={item.name}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </PInput>
                                            </Grid>}
                                            {leads.usedSystems.length > 0 && <Grid container>
                                                {leads.usedSystems.map((item, idx) => (
                                                    <Grid key={idx} item xs={4} style={{marginBottom: 5}}>
                                                        <Chip
                                                            label={
                                                                <span className={classes.labelChip}>{item}</span>
                                                            }
                                                            color='primary'
                                                            onDelete={() => handleEditUsedSystems(idx)}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah di Faskes Ada Server Sendiri?</Typography>
                                                <PInput
                                                    name='isOwnServerExist'
                                                    fullWidth
                                                    select
                                                    value={leads.isOwnServerExist}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={true}>Ada</MenuItem>
                                                    <MenuItem value={false}>Tidak</MenuItem>
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Bekerjasama Dengan
                                                    BPJS?</Typography>
                                                <PInput
                                                    name='isBPJS'
                                                    fullWidth
                                                    select
                                                    value={leads.isBPJS}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={'Iya'}>Iya</MenuItem>
                                                    <MenuItem value={'Tidak'}>Tidak</MenuItem>
                                                    <MenuItem value={'Tidak Tahu'}>Tidak Tahu</MenuItem>
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Tersedia Apotek?</Typography>
                                                <PInput
                                                    name='isPharmacyExist'
                                                    fullWidth
                                                    select
                                                    value={leads.isPharmacyExist}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={'Iya'}>Iya</MenuItem>
                                                    <MenuItem value={'Tidak'}>Tidak</MenuItem>
                                                    <MenuItem value={'Tidak Tahu'}>Tidak Tahu</MenuItem>
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Fitur Yang
                                                    Dibutuhkan?</Typography>
                                                <PInput
                                                    name='neededFeature'
                                                    fullWidth
                                                    rows={3}
                                                    multiline
                                                    value={leads.neededFeature}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.formRight}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Hot Button</Typography>
                                                <PInput
                                                    name='hotButton'
                                                    fullWidth
                                                    rows={3}
                                                    multiline
                                                    value={leads.hotButton}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Kenapa Ingin
                                                    Mengganti/Menggunakan Sistem?</Typography>
                                                <PInput
                                                    name='reasonToUsingSystem'
                                                    fullWidth
                                                    rows={3}
                                                    multiline
                                                    value={leads.reasonToUsingSystem}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Kapan Rencana Faskes Implementasi Sistem? (Tanyakan Waktunya)</Typography>
                                                <PInput
                                                    name='systemImplementationPlan'
                                                    fullWidth
                                                    value={leads.systemImplementationPlan}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Keterangan</Typography>
                                                <PInput
                                                    name='note'
                                                    fullWidth
                                                    rows={4}
                                                    multiline
                                                    value={leads.note}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.labelStep}>Data Pay</Typography>
                                <Grid container spacing={1} className={classes.gridWrap}>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} style={{marginBottom: 25}}>
                                            <Typography className={classes.labelField}>Bagaimana Perasaan Setelah
                                                Menggunakan Sistem?</Typography>
                                            <div className={classes.hotnessLevelWrap}>
                                                {
                                                    hotnessLevel.map((item, idx) => (
                                                        <React.Fragment key={idx}>
                                                            <IconButton
                                                                onClick={() => handleChangeHotnessLevel(item.label)}>
                                                                <FontAwesomeIcon
                                                                    icon={item.icon}
                                                                    className={item.style}
                                                                    style={
                                                                        leads.hotnessLevel === item.label || leads.hotnessLevel === ''
                                                                            ? {opacity: 1} : {opacity: 0.25}
                                                                    }
                                                                />
                                                            </IconButton>
                                                            <Typography
                                                                className={classes.labelHotnessLevel}
                                                                style={
                                                                    leads.hotnessLevel === item.label || leads.hotnessLevel === ''
                                                                        ? {opacity: 1} : {opacity: 0.25}
                                                                }
                                                            >{item.label}</Typography>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.formLeft}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Potensi Jenis Produk
                                                    Closing</Typography>
                                                <PInput
                                                    name='closingProductTypePotential'
                                                    fullWidth
                                                    select
                                                    value={leads.closingProductTypePotential}
                                                    onChange={handleChange}
                                                >
                                                    {productList.filter(item => !item.extra.isOld).map((item, idx) => (
                                                        <MenuItem key={idx} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Potensi Durasi Produk
                                                    Closing</Typography>
                                                <PInput
                                                    name='closingProductDurationPotential'
                                                    fullWidth
                                                    select
                                                    value={leads.closingProductDurationPotential}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        readOnly: leads.closingProductValuePotential === ''
                                                    }}
                                                >
                                                    {duration.map(item => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Potensi Nilai Produk
                                                    Closing</Typography>
                                                <PInput
                                                    name='closingProductValuePotential'
                                                    fullWidth
                                                    value={leads.closingProductValuePotential}
                                                    InputProps={{
                                                        readOnly: true,
                                                        inputComponent: numberFormatCustom,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Ada Custom?</Typography>
                                                <PInput
                                                    name='isCustom'
                                                    fullWidth
                                                    select
                                                    value={leads.isCustom}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={true}>Ada</MenuItem>
                                                    <MenuItem value={false}>Tidak</MenuItem>
                                                </PInput>
                                            </Grid>
                                            {leads.isCustom === true &&
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Data Custom</Typography>
                                                <PInput
                                                    name='dataCustom'
                                                    fullWidth
                                                    value={leads.dataCustom}
                                                    onChange={handleChange}
                                                />
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Jumlah Kunjungan
                                                    Bulanan</Typography>
                                                <PInput
                                                    name='numberOfVisits'
                                                    fullWidth
                                                    value={leads.numberOfVisits}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.formRight}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Jumlah Kapitasi
                                                    BPJS</Typography>
                                                <PInput
                                                    name='capitationBPJS'
                                                    fullWidth
                                                    value={leads.capitationBPJS}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Status
                                                    Berlangganan</Typography>
                                                <PInput
                                                    name='isSubscribed'
                                                    fullWidth
                                                    select
                                                    value={leads.isSubscribed}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={true}>Iya</MenuItem>
                                                    <MenuItem value={false}>Tidak</MenuItem>
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Hubungi Lagi</Typography>
                                                <PInput
                                                    name='recontact'
                                                    fullWidth
                                                    rows={11}
                                                    multiline
                                                    value={leads.recontact}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.labelStep}>Data Other</Typography>
                                <Grid container spacing={1} className={classes.gridWrap}>
                                    <Grid item xs={6}>
                                        <div className={classes.formLeft}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Catatan
                                                    Penggunaan</Typography>
                                                <PInput
                                                    name='useSystemNote'
                                                    fullWidth
                                                    rows={6}
                                                    multiline
                                                    value={leads.useSystemNote}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Durasi
                                                    Berlangganan</Typography>
                                                <PInput
                                                    name='useSystemDuration'
                                                    fullWidth
                                                    select
                                                    value={leads.useSystemDuration}
                                                    onChange={handleChange}
                                                >
                                                    {duration.map(item => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </PInput>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} style={{marginBottom: 5}}>
                                                    <Typography className={classes.labelField}>Tanggal Mulai
                                                        Berlangganan</Typography>
                                                    <DatePicker
                                                        value={leads.useSystemStartDate}
                                                        onChange={handleChangeUseSystemStartDate}
                                                        format='dd MMMM yyyy'
                                                        inputVariant='outlined'
                                                        margin='dense'
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position='end'>
                                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                                            </InputAdornment>
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{marginBottom: 5}}>
                                                    <Typography className={classes.labelField}>Tanggal Selesai
                                                        Berlangganan</Typography>
                                                    <DatePicker
                                                        value={leads.useSystemEndDate}
                                                        onChange={handleChangeUseSystemEndDate}
                                                        format='dd MMMM yyyy'
                                                        inputVariant='outlined'
                                                        margin='dense'
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position='end'>
                                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                                            </InputAdornment>
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.formRight}>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Apakah Ada Anomali
                                                    Penggunaan?</Typography>
                                                <PInput
                                                    name='isAnyAnomaly'
                                                    fullWidth
                                                    select
                                                    value={leads.isAnyAnomaly}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={true}>Ada</MenuItem>
                                                    <MenuItem value={false}>Tidak</MenuItem>
                                                </PInput>
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Berapa Kali Berlangganan
                                                    Ulang?</Typography>
                                                <PInput
                                                    name='resubscribeCounter'
                                                    fullWidth
                                                    value={leads.resubscribeCounter}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Status Saat Ini</Typography>
                                                <PInput
                                                    name='status'
                                                    fullWidth
                                                    select
                                                    value={leads.status}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value='Leads'>Leads</MenuItem>
                                                    <MenuItem value='Menghubungi'>Menghubungi</MenuItem>
                                                    <MenuItem value='Presentasi'>Presentasi</MenuItem>
                                                    <MenuItem value='Trial'>Trial</MenuItem>
                                                    <MenuItem value='Penawaran'>Penawaran</MenuItem>
                                                    <MenuItem value='Invoice'>Invoice</MenuItem>
                                                    <MenuItem value='Closing'>Closing</MenuItem>
                                                    <MenuItem value='Rejected'>Rejected</MenuItem>
                                                </PInput>
                                            </Grid>
                                            {leads.status === 'Rejected' && <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Kategori Penolakan</Typography>
                                                <PInput
                                                    name='rejectionCategory'
                                                    fullWidth
                                                    select
                                                    value={leads.rejectionCategory}
                                                    onChange={handleChange}
                                                >
                                                    {rejectionCategoryList.map(item => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </PInput>
                                            </Grid>}
                                            <Grid item xs={12} className={classes.fieldWrap}>
                                                <Typography className={classes.labelField}>Tanggal Terakhir
                                                    Dihubungi</Typography>
                                                <PInput
                                                    value={
                                                        format(new Date(leads.lastContactDate), 'dd MMMM yyyy', {locale: localeId})
                                                    }
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position='end'>
                                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                                        </InputAdornment>
                                                    }}
                                                    disabled
                                                    fullWidth
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                {isEdit && <Grid container spacing={1} className={classes.activityPaper}>
                                    <Typography className={classes.labelStep}>Tambah Activity</Typography>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <div className={classes.formLeft}>
                                                <Grid item xs={12} className={classes.fieldWrap}>
                                                    <Typography className={classes.labelField}>Tanggal
                                                        Aktifitas</Typography>
                                                    <DatePicker
                                                        value={activityDate}
                                                        onChange={handleChangeActivityDate}
                                                        format='dd MMMM yyyy'
                                                        inputVariant='outlined'
                                                        margin='dense'
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position='end'>
                                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                                            </InputAdornment>
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={classes.fieldWrap}>
                                                    <Typography className={classes.labelField}>Nama Klinik</Typography>
                                                    <PInput
                                                        value={leads.name}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={classes.fieldWrap}>
                                                    <Typography className={classes.labelField}>PIC Klinik</Typography>
                                                    <PInput
                                                        value={selectedActivityPICFaskes}
                                                        onChange={handleChangeSelectedActivityPICFaskes}
                                                        select
                                                        fullWidth
                                                    >
                                                        {leads.PIC.map((item, idx) => (
                                                            <MenuItem key={idx} value={idx}>
                                                                {item.namepic}
                                                            </MenuItem>
                                                        ))}
                                                    </PInput>
                                                </Grid>
                                                <Grid item xs={12} className={classes.fieldWrap}>
                                                    <Typography className={classes.labelField}>PIC
                                                        Assist.id</Typography>
                                                    <PInput
                                                        value={
                                                            typeof selectedPICAssist.id !== 'undefined'
                                                                ? `${selectedPICAssist.firstName} ${selectedPICAssist.lastName}`
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={classes.fieldWrap}>
                                                    <Typography className={classes.labelField}>Via
                                                        Komunikasi</Typography>
                                                    <PInput
                                                        value={selectedActivityMedia}
                                                        onChange={handleChangeSelectedActivityMedia}
                                                        select
                                                        fullWidth
                                                    >
                                                        <MenuItem value="Langsung di Klinik">Langsung di
                                                            Klinik</MenuItem>
                                                        <MenuItem value="Intercom">Intercom</MenuItem>
                                                        <MenuItem value="Whatsapp">Whatsapp</MenuItem>
                                                        <MenuItem value="Telegram">Telegram</MenuItem>
                                                        <MenuItem value="Email">Email</MenuItem>
                                                        <MenuItem value="SMS">SMS</MenuItem>
                                                        <MenuItem value="Telepon">Telepon</MenuItem>
                                                        <MenuItem value="Presentasi">Presentasi</MenuItem>
                                                        <MenuItem value="Training">Training</MenuItem>
                                                    </PInput>
                                                </Grid>
                                                <Grid item xs={12} className={classes.fieldWrap}>
                                                    <Typography className={classes.labelField}>Status Saat Ini?</Typography>
                                                    <PInput
                                                        fullWidth
                                                        select
                                                        value={selectedActivityStatus}
                                                        onChange={handleChangeSelectedActivityStatus}
                                                    >
                                                        <MenuItem value='Leads'>Leads</MenuItem>
                                                        <MenuItem value='Menghubungi'>Menghubungi</MenuItem>
                                                        <MenuItem value='Presentasi'>Presentasi</MenuItem>
                                                        <MenuItem value='Trial'>Trial</MenuItem>
                                                        <MenuItem value='Penawaran'>Penawaran</MenuItem>
                                                        <MenuItem value='Invoice'>Invoice</MenuItem>
                                                        <MenuItem value='Closing'>Closing</MenuItem>
                                                        <MenuItem value='Rejected'>Rejected</MenuItem>
                                                    </PInput>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className={classes.formRight}>
                                                <Grid item xs={12} className={classes.fieldWrap}>
                                                    <Typography className={classes.labelField}>Catatan</Typography>
                                                    <PInput
                                                        value={activityNote}
                                                        onChange={(e) => setActivityNote(e.target.value)}
                                                        rows={25}
                                                        multiline
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                                <Grid container justify='flex-end' item xs={12} className={classes.gridWrap}>
                                    <Link
                                        to={isEdit && isVerifiedEdit ? "../detail/" + singleFaskesList.id : "../leads"}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <PBtn
                                            inverted
                                            dropShadow
                                            style={{ marginRight: 16 }}
                                        >
                                            Batal
                                        </PBtn>
                                    </Link>
                                    <PBtn
                                        type='submit'
                                    >
                                        Simpan
                                    </PBtn>
                                </Grid>
                            </form>}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

const mapState = ({ leads, user, prospect, app }) => ({
    isFetchingSingleFaskesList: leads.isFetchingSingleFaskesList,
    singleFaskesList: leads.singleFaskesList,
    currentUser: user.currentUser,
    selectedProspect: prospect.selectedProspect,
    isViewedByMobile: app.isViewedByMobile,
});

const mapDispatch = ({ leads: { addLeads, editLeads, addActivity, addActivityRejection }, prospect: { updateSelectedProspect } }) => ({
    addLeads,
    editLeads,
    addActivity,
    addActivityRejection,
    updateSelectedProspect,
});

export default connect(mapState, mapDispatch)(FormAddLeadsV1);
