import agent from "./agent";
import format from "date-fns/format";

export const addRiwayatPenawaran = (payload) => {
    return agent.post('HealthFacilityTimelines', payload);
};

export const checkFaskesName = (name) => {
    const where = {
        name,
    };

    return agent.get('HealthFacilities/count', {
        params: {
            where,
        },
    });
};

export const checkFaskesEmail = (email) => {
    const where = {
        email,
    };

    return agent.get('HealthFacilities/count', {
        params: {
            where,
        },
    });
};

export const checkFaskesPhoneNumber = (phoneNumber) => {
    const where = {
        phoneNumber,
    };

    return agent.get('HealthFacilities/count', {
        params: {
            where,
        },
    });
};

export const getFaskesByName = (name) => {
    const filter = {
        where: {
            name,
        },
        fields: ['name', 'address']
    };

    return agent.get('HealthFacilities', {
        params: {
            filter,
        },
    });
};

export const getFaskesByEmail = (email) => {
    const filter = {
        where: {
            email,
        },
        fields: ['name', 'email'],
    };

    return agent.get('HealthFacilities', {
        params: {
            filter,
        },
    });
};

export const getFaskesByPhoneNumber = (phoneNumber) => {
    const filter = {
        where: {
            phoneNumber,
        },
        fields: ['name', 'phoneNumber'],
    };

    return agent.get('HealthFacilities', {
        params: {
            filter,
        },
    });
};

export const getRiwayatPenawaran = (faskesId) => {
    const filter = {
        include: ['Timelines']

    };
    return agent.get(`/HealthFacilities/${faskesId}`, {
        params: {
            filter,
        }
    });
};

export const searchFaskesByName = (keyword) => {
    const filter = {
        where: {
            name: {
                like: keyword,
                options: 'i',
            },
        },
        include: ['healthFacilityPICs', 'PICAssist'],
    };

    return agent.get('/HealthFacilities', {
        params: {
            filter,
        },
    });
};

export const searchFaskesByNamev2 = (keyword) => {
    const params = {
        keyword,
    };

    return agent.get('/HealthFacilities/getFaskesByKeyword', {
        params,
    });
};

export const searchAssistFaskesByName = (keyword) => {
    const params = {
        keyword,
    };

    return agent.get('/HealthFacilities/getAssistFaskesByKeyword', {
        params,
    });
};

export const searchFaskesByNameWithoutTrials = (keyword) => {
    return agent.get('/HealthFacilities/getFaskesWithoutTrials', {
        params: {
            keyword: keyword.trim() || '',
        },
    })
};

export const searchFaskesByNameWithoutTrialsWithException = (keyword, exceptionId) => {
    return agent.get('/HealthFacilities/getFaskesWithoutTrialsWithException', {
        params: {
            keyword: keyword.trim() || '',
            exceptionId,
        },
    })
};

export const getTrialsByFaskesId = (faskesId) => {
    return agent.get(`/HealthFacilities/${faskesId}/Trials`);
};

export const getBlockedFaskesList = (skipLimit) => {
    let params;
    if (skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }
    return agent.get('HealthFacilities/getBlockedFaskesList', {
        params,
    });
};

export const countBlockedFaskesList = () => {
    const params = '';

    return agent.get('HealthFacilities/countBlockedFaskesList', {
        params,
    });
};

export const getTrialListByFilter = (payload) => {
    const params = {
        namaKlinik: payload.namaKlinik,
        tanggalAwalTrialStart: payload.tanggalAwalTrialStart === null ? '' : format(new Date(payload.tanggalAwalTrialStart), "yyyy-MM-dd"),
        tanggalAwalTrialEnd: payload.tanggalAwalTrialEnd === null ? '' : format(new Date(payload.tanggalAwalTrialEnd), "yyyy-MM-dd"),
        tanggalAkhirTrialStart: payload.tanggalAkhirTrialStart === null ? '' : format(new Date(payload.tanggalAkhirTrialStart), "yyyy-MM-dd"),
        tanggalAkhirTrialEnd: payload.tanggalAkhirTrialEnd === null ? '' : format(new Date(payload.tanggalAkhirTrialEnd), "yyyy-MM-dd"),
        isTrial: payload.isTrial,
        skip: payload.skip,
        limit: payload.limit,
    };
    return agent.get('Clients/getFaskesList', {
        params,
    });
};

export const countTrialListByFilter = (payload) => {
    const params = {
        namaKlinik: payload.namaKlinik,
        tanggalAwalTrialStart: payload.tanggalAwalTrialStart === null ? '' : format(new Date(payload.tanggalAwalTrialStart), "yyyy-MM-dd"),
        tanggalAwalTrialEnd: payload.tanggalAwalTrialEnd === null ? '' : format(new Date(payload.tanggalAwalTrialEnd), "yyyy-MM-dd"),
        tanggalAkhirTrialStart: payload.tanggalAkhirTrialStart === null ? '' : format(new Date(payload.tanggalAkhirTrialStart), "yyyy-MM-dd"),
        tanggalAkhirTrialEnd: payload.tanggalAkhirTrialEnd === null ? '' : format(new Date(payload.tanggalAkhirTrialEnd), "yyyy-MM-dd"),
        isTrial: payload.isTrial,
    };
    return agent.get('Clients/countFaskesList', {
        params,
    });
};

export const getBlockedListByFilter = (payload) => {
    const params = {
        nama: payload.nama,
        tanggalMulaiBlock: payload.tanggalMulaiBlock === null ? '' : format(new Date(payload.tanggalMulaiBlock), "yyyy-MM-dd"),
        tanggalAkhirBlock: payload.tanggalAkhirBlock === null ? '' : format(new Date(payload.tanggalAkhirBlock), "yyyy-MM-dd"),
        picKlinik: payload.picKlinik,
        keteranganBlock: payload.keteranganBlock,
        statusBlock: payload.statusBlock,
        skip: payload.skip,
        limit: payload.limit,
    };
    return agent.get('HealthFacilities/getBlockedFaskesList', {
        params,
    });
};

export const countBlockedListByFilter = (payload) => {
    const params = {
        nama: payload.nama,
        tanggalMulaiBlock: payload.tanggalMulaiBlock === null ? '' : format(new Date(payload.tanggalMulaiBlock), "yyyy-MM-dd"),
        tanggalAkhirBlock: payload.tanggalAkhirBlock === null ? '' : format(new Date(payload.tanggalAkhirBlock), "yyyy-MM-dd"),
        picKlinik: payload.picKlinik,
        keteranganBlock: payload.keteranganBlock,
        statusBlock: payload.statusBlock,
    };
    return agent.get('HealthFacilities/countBlockedFaskesList', {
        params,
    });
};

export const setBlockedStatus = (data) => {
    return agent.patch('HealthFacilities/setBlockedStatus', data);
};

export const setTrialStatus = (data) => {
    return agent.patch('HealthFacilities/setTrialStatus', data);
};

export const getFaskesListBySort = (payload) => {
    const params = {
        order: payload,
    };

    return agent.get('HealthFacilities/getFaskesList', {
        params
    });
};

export const getFaskesList = (skipLimit) => {
    let params;
    if(skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 6,
        }
    }

    return agent.get('HealthFacilities/getFaskesList', {
        params
    });
};

export const countFaskesList = () => {
    return agent.get('HealthFacilities/count');
};

export const getFaskesListByFilter = (payload) => {
    const params = {
        order: payload.order,
        omnisearch: payload.omnisearch,
        nama: payload.nama,
        kabupaten: payload.kabupaten,
        jenis: payload.jenis,
        status: payload.status,
        picAssist: payload.picAssist,
        terakhirDihubungiStart: payload.terakhirDihubungiStart === null ? '' : format(new Date(payload.terakhirDihubungiStart), 'yyyy-MM-dd'),
        terakhirDihubungiEnd: payload.terakhirDihubungiEnd === null ? '' : format(new Date(payload.terakhirDihubungiEnd), 'yyyy-MM-dd'),
        hotnessLevel: payload.hotnessLevel,
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('HealthFacilities/getFaskesList', {
        params
    });
};

export const countFaskesListByFilter = (payload) => {
    const params = {
        order: payload.order,
        omnisearch: payload.omnisearch,
        nama: payload.nama,
        kabupaten: payload.kabupaten,
        jenis: payload.jenis,
        status: payload.status,
        picAssist: payload.picAssist,
        terakhirDihubungiStart: payload.terakhirDihubungiStart === null ? '' : format(new Date(payload.terakhirDihubungiStart), 'yyyy-MM-dd'),
        terakhirDihubungiEnd: payload.terakhirDihubungiEnd === null ? '' : format(new Date(payload.terakhirDihubungiEnd), 'yyyy-MM-dd'),
        hotnessLevel: payload.hotnessLevel,
    };

    return agent.get('HealthFacilities/countFaskesList', {
        params
    });
};

export const countActiveAssistHospitalByAssistHospitalId = (assistHospitalId) => {
    const where = {
        assistHospitalId,
    };

    return agent.get('HealthFacilities/count', {
        params: {
            where,
        },
    });
};

export const getSingleFaskesList = (faskesId) => {
    return agent.get(`HealthFacilities/${faskesId}`);
};

export const addNewLeads = (payload) => {
    return agent.post('HealthFacilities', payload);
};

export const updateLeads = (leadsId, payload) => {
    return agent.patch(`HealthFacilities/${leadsId}`, payload);
};

export const deleteLeads = (leadsId) => {
    return agent.delete(`HealthFacilities/${leadsId}/deleteData`);
};

export const getDataLog = (payload) => {
    const params = {
        healthFacilityId: payload.healthFacilityId,
        tanggal: payload.tanggal === null ? '' : format(new Date(payload.tanggal), "yyyy-MM-dd"),
        deskripsi: payload.deskripsi,
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('HealthFacilities/getLog', {
        params,
    });
};

export const countDataLog = (payload) => {
    const params = {
        healthFacilityId: payload.healthFacilityId,
        tanggal: payload.tanggal === null ? '' : format(new Date(payload.tanggal), "yyyy-MM-dd"),
        deskripsi: payload.deskripsi,
    };

    return agent.get('HealthFacilities/countGetLog', {
        params,
    });
};
