import {
    getKlinik,
    countKlinikList,
    getKlinikListByFilter,
    countKlinikListByFilter,
    getSubscribedFaskesList,
    countSubscribedFaskesList,
    getSubscribedFaskesListByFilter,
    countSubscribedFaskesListByFilter,
    getOverdueList,
    countOverdueList,
    getOverdueListByFilter,
    countOverdueListByFilter,
    getBuktiBayar,
    countBuktiBayar,
    getBuktiBayarByFilter,
    countBuktiBayarByFilter,
    getInvoices,
    countInvoices,
    getInvoicesByFilter,
    countInvoicesByFilter,
    getKlinikFreeList,
    countKlinikFreeList,
} from "../../api/client";

export const client = {
    state: {
        isFetchingKlinikList: false,
        isFetchingSubscribedFaskesList: false,
        isFetchingOverdueList: false,
        isFetchingReceiptsList: false,
        isFetchingInvoicesList: false,
        isFetchingKlinikFreeList: false,
        klinikList: [],
        subscribedFaskesList: [],
        overdueList: [],
        receiptsList: [],
        invoicesList: [],
        klinikFreeList: [],
        klinikListCounter: 0,
        subscribedFaskesListCounter: 0,
        overdueListCounter: 0,
        receiptsListCounter: 0,
        invoicesListCounter: 0,
        klinikFreeListCounter: 0,
        selectedKlinikFilter: {},
        selectedSubscribedFaskesFilter: {},
        selectedOverdueList: {},
        selectedInvoicesList: {},
        selectedReceiptsList: {},
        selectedKlinikFreeList: {},
        pageInvoices: 0,
        pageReceipts: 0,
    },
    reducers: {
        updateState(state, payload) {
            const { statName, value } = payload;
            return {
                ...state,
                [statName]: value,
            };
        },
        updateKlinikList(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    klinikList: value,
                };
            }

            const { klinikList } = state;
            klinikList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                klinikList: [...klinikList],
            };
        },
        updateSubscribedFaskesList(state, payload) {
            const { value, skipLimit } = payload;

            if (!skipLimit) {
                return {
                    ...state,
                    subscribedFaskesList: value,
                };
            }

            const { subscribedFaskesList } = state;
            subscribedFaskesList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                subscribedFaskesList: [...subscribedFaskesList],
            };
        },
        updateOverdueList(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    overdueList: value
                };
            }

            const { overdueList } = state;
            overdueList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                overdueList: [...overdueList],
            };
        },
        updateReceiptsList(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    receiptsList: value,
                };
            }

            const { receiptsList } = state;
            receiptsList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                receiptsList: [...receiptsList],
            };
        },
        updateInvoicesList(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    invoicesList: value,
                };
            }

            const { invoicesList } = state;
            invoicesList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                invoicesList: [...invoicesList],
            };
        },
        updateKlinikFreeList(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    klinikFreeList: value,
                };
            }

            const { klinikFreeList } = state;
            klinikFreeList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                klinikFreeList: [...klinikFreeList],
            };
        },
        updateKlinikListFilter(state, payload) {
            const { value, payloads } = payload;

            if (payloads.condition) {
                return {
                    ...state,
                    klinikList: value,
                };
            }

            const { klinikList } = state;
            klinikList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                klinikList: [...klinikList],
            };
        },
        updateSubscribedFaskesListFilter(state, payload) {
            const { value, payloads } = payload;

            if(payloads.condition) {
                return {
                    ...state,
                    subscribedFaskesList: value,
                };
            }

            const { subscribedFaskesList } = state;
            subscribedFaskesList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                subscribedFaskesList: [...subscribedFaskesList],
            };
        },
        updateOverdueListFilter(state, payload) {
            const { value, payloads } = payload;

            if(payloads.condition) {
                return {
                    ...state,
                    overdueList: value,
                };
            }

            const { overdueList } = state;
            overdueList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                overdueList: [...overdueList],
            };
        },
        updateInvoicesListFilter(state, payload) {
            const { value, payloads } = payload;

            if(payloads.condition) {
                return {
                    ...state,
                    invoicesList: value,
                };
            }

            const { invoicesList } = state;
            invoicesList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                invoicesList: [...invoicesList],
            };
        },
        updateReceiptsListFilter(state, payload) {
            const { value, payloads } = payload;

            if(payloads.condition) {
                return {
                    ...state,
                    receiptsList: value,
                };
            }

            const { receiptsList } = state;
            receiptsList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                receiptsList: [...receiptsList],
            };
        },
        updateSelectedKlinikFilter(state, payload) {
            return {
                ...state,
                selectedKlinikFilter: payload,
            };
        },
        updateSelectedSubscribedFaskesFilter(state, payload) {
            return {
                ...state,
                selectedSubscribedFaskesFilter: payload,
            };
        },
        updateSubscribedFaskesListChecked(state, payload) {
            return {
                ...state,
                subscribedFaskesList: payload,
            };
        },
        updateSelectedOverdueList(state, payload) {
            return {
                ...state,
                selectedOverdueList: payload,
            };
        },
        updateSelectedInvoicesList(state, payload) {
            return {
                ...state,
                selectedInvoicesList: payload,
            };
        },
        updateSelectedReceiptsList(state, payload) {
            return {
                ...state,
                selectedReceiptsList: payload,
            };
        },
        updateSelectedKlinikFreeList(state, payload) {
            return {
                ...state,
                selectedKlinikFreeList: payload,
            };
        },
        updatePageInvoices(state, payload) {
            return {
                ...state,
                pageInvoices: payload,
            };
        },
        updatePageReceipts(state, payload) {
            return {
                ...state,
                pageReceipts: payload,
            };
        },
    },
    effects: dispatch => ({
        async fetchKlinikList(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingKlinikList',
                    value: true,
                });

                const result = await getKlinik(skipLimit);
                const resultCounter = await countKlinikList();

                this.updateKlinikList({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'klinikListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingKlinikList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar klinik");
                this.updateState({
                    statName: 'isFetchingKlinikList',
                    value: false,
                });
            }
        },
        async fetchKlinikListFilter(payloads) {
            try {
                this.updateState({
                    statName: 'isFetchingKlinikList',
                    value: true,
                });

                const result = await getKlinikListByFilter(payloads);
                const resultCounter = await countKlinikListByFilter(payloads);

                this.updateKlinikListFilter({
                    value: result.data,
                    payloads,
                });

                this.updateState({
                    statName: 'klinikListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingKlinikList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar klinik");
                this.updateState({
                    statName: 'isFetchingKlinikList',
                    value: false,
                });
            }
        },
        async fetchSubscribedFaskesList(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingSubscribedFaskesList',
                    value: true,
                });

                const result = await getSubscribedFaskesList(skipLimit);
                const resultCounter = await countSubscribedFaskesList();

                this.updateSubscribedFaskesList({
                    value: result.data.map(item => {
                        const newItem = {...item};
                        newItem.isChecked = false;
                        return newItem;
                    }),
                    skipLimit,
                });

                this.updateState({
                    statName: 'subscribedFaskesListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingSubscribedFaskesList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar klinik berlangganan");
                this.updateState({
                    statName: 'isFetchingSubscribedFaskesList',
                    value: false,
                });
            }
        },
        async fetchSubscribedFaskesListFilter(payloads) {
            try {
                this.updateState({
                    statName: 'isFetchingSubscribedFaskesList',
                    value: true,
                });

                const result = await getSubscribedFaskesListByFilter(payloads);
                const resultCounter = await countSubscribedFaskesListByFilter(payloads);

                this.updateSubscribedFaskesListFilter({
                    value: result.data.map(item => {
                        const newItem = {...item};
                        newItem.isChecked = false;
                        return newItem;
                    }),
                    payloads,
                });

                this.updateState({
                    statName: 'subscribedFaskesListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingSubscribedFaskesList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar klinik berlangganan");
                this.updateState({
                    statName: 'isFetchingSubscribedFaskesList',
                    value: false,
                });
            }
        },
        async fetchOverdueList(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingOverdueList',
                    value: true,
                });

                const result = await getOverdueList(skipLimit);
                const resultCounter = await countOverdueList();

                this.updateOverdueList({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'overdueListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingOverdueList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar overdue");
                this.updateState({
                    statName: 'isFetchingOverdueList',
                    value: false,
                });
            }
        },
        async fetchOverdueListFilter(payloads) {
            try {
                this.updateState({
                    statName: 'isFetchingOverdueList',
                    value: true,
                });

                const result = await getOverdueListByFilter(payloads);
                const resultCounter = await countOverdueListByFilter(payloads);

                this.updateOverdueListFilter({
                    value: result.data,
                    payloads,
                });

                this.updateState({
                    statName: 'overdueListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingOverdueList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar overdue");
                this.updateState({
                    statName: 'isFetchingOverdueList',
                    value: false,
                });
            }
        },
        async fetchReceiptsList(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingReceiptsList',
                    value: true,
                });

                const result = await getBuktiBayar(skipLimit);
                const resultCounter = await countBuktiBayar();

                this.updateReceiptsList({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'receiptsListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingReceiptsList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar bukti bayar");
                this.updateState({
                    statName: 'isFetchingReceiptsList',
                    value: false,
                });
            }
        },
        async fetchReceiptsListFilter(payloads) {
            try {
                this.updateState({
                    statName: 'isFetchingReceiptsList',
                    value: true,
                });

                const result = await getBuktiBayarByFilter(payloads);
                const resultCounter = await countBuktiBayarByFilter(payloads);

                this.updateReceiptsListFilter({
                    value: result.data,
                    payloads,
                });

                this.updateState({
                    statName: 'receiptsListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingReceiptsList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar bukti bayar");
                this.updateState({
                    statName: 'isFetchingReceiptsList',
                    value: false,
                });
            }
        },
        async fetchInvoicesList(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingInvoicesList',
                    value: true,
                });

                const result = await getInvoices(skipLimit);
                const resultCounter = await countInvoices();

                this.updateInvoicesList({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'invoicesListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingInvoicesList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar invoice");
                this.updateState({
                    statName: 'isFetchingInvoicesList',
                    value: false,
                });
            }
        },
        async fetchInvoicesListFilter(payloads) {
            try {
                this.updateState({
                    statName: 'isFetchingInvoicesList',
                    value: true,
                });

                const result = await getInvoicesByFilter(payloads);
                const resultCounter = await countInvoicesByFilter(payloads);

                this.updateInvoicesListFilter({
                    value: result.data,
                    payloads,
                });

                this.updateState({
                    statName: 'invoicesListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingInvoicesList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar invoice");
                this.updateState({
                    statName: 'isFetchingInvoicesList',
                    value: false,
                });
            }
        },
        async fetchKlinikFreeList(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingKlinikFreeList',
                    value: true,
                });

                const result = await getKlinikFreeList(skipLimit);
                const resultCounter = await countKlinikFreeList();

                this.updateKlinikFreeList({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'klinikFreeListCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingKlinikFreeList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar klinik free");
                this.updateState({
                    statName: 'isFetchingKlinikFreeList',
                    value: false,
                });
            }
        },
    }),
};
