import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PDatePicker from "../../share/PDatePicker";
import PBtn from "../../share/PBtn";

import ReportChartPaper from "../../share/ReportChartPaper";

import {getConversion} from "../../../api/laporan";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
});

const ConversionItem = ({ conversionName, setIsPage }) => {
    const classes = styles();
    const [dataPie, setDataPie] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [filterData, setFilterData] = useState({
        tanggalMulai: null,
        tanggalSelesai: null,
        kontakStatus: '',
        direferalkan: '',
    });

    useEffect(()=> {
        (async function () {
            const result = await getConversion(filterData, conversionName);
            const resultData = result.data.map(item => {
                const newItem = {...item};
                newItem.percentage = parseFloat(newItem.percentage.toFixed(2));
                return newItem;
            });
            if(resultData.length > 3) {
                const highestValue = [
                    {
                        count: 0,
                    },
                    {
                        count: 0,
                    },
                    {
                        count: 0,
                    }
                ];

                for(let i = 0; i < resultData.length; i++) {
                    if(resultData[i].count > highestValue[0].count) {
                        highestValue[2] = highestValue[1];
                        highestValue[1] = highestValue[0];
                        highestValue[0] = resultData[i];
                    } else if(resultData[i].count > highestValue[1].count) {
                        highestValue[2] = highestValue[1];
                        highestValue[1] = resultData[i];
                    } else if(resultData[i].count > highestValue[2].count) {
                        highestValue[2] = resultData[i];
                    }
                }
                setDataPie(highestValue);
            } else {
                setDataPie(resultData);
            }
            setDataTable(resultData);
        })();
    }, [filterData, conversionName]);

    const COLORS = ['#00C49F', '#FFBB28', '#FF8042'];

    const handleChangeStartDate = (date) => {
        setFilterData(prevState => ({
            ...prevState, tanggalMulai: date,
        }));
    };

    const handleChangeEndDate = (date) => {
        setFilterData(prevState => ({
            ...prevState, tanggalSelesai: date,
        }));
    };

    const handleChange = (e) => {
        setFilterData(prevState => ({
            ...prevState, [e.target.name]: e.target.value,
        }));
    };

    return (
        <Grid container>
            <Grid container spacing={1} item xs={12} style={{ marginTop: '30px' }}>
                <Grid item xs={3}>
                    <Typography className={classes.label}>
                        Filter Tanggal Mulai Dari
                    </Typography>
                    <PDatePicker
                        value={filterData.tanggalMulai}
                        onChange={handleChangeStartDate}
                        format='dd/MM/yyyy'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>
                        Sampai Tanggal
                    </Typography>
                    <PDatePicker
                        value={filterData.tanggalSelesai}
                        onChange={handleChangeEndDate}
                        format='dd/MM/yyyy'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>
                        Tipe Datang
                    </Typography>
                    <PInput
                        name='kontakStatus'
                        value={filterData.kontakStatus}
                        onChange={handleChange}
                        select
                        fullWidth
                    >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value='datang sendiri'>Datang Sendiri</MenuItem>
                        <MenuItem value='dihubungi'>Dihubungi</MenuItem>
                    </PInput>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>
                        Referral
                    </Typography>
                    <PInput
                        name='direferalkan'
                        value={filterData.direferalkan}
                        onChange={handleChange}
                        select
                        fullWidth
                    >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={true}>Iya</MenuItem>
                        <MenuItem value={false}>Tidak</MenuItem>
                    </PInput>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <ReportChartPaper
                    label={conversionName}
                    dataPie={dataPie}
                    dataTable={dataTable}
                    dataKeyVal={'percentage'}
                    colors={COLORS}
                />
            </Grid>
            <Grid container justify='flex-end' item xs={12}>
                <PBtn
                    inverted
                    dropShadow
                    onClick={()=> setIsPage(0)}
                >
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{ marginRight: 3 }}/>Kembali
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default ConversionItem;
