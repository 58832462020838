import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from "@material-ui/core";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortAmountDown, faSortAmountUp} from "@fortawesome/free-solid-svg-icons";

import ItemData from "./ItemData";

const style = makeStyles({
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
    sortImg: {
        marginLeft: 7,
        opacity: 0.1,
        '&:hover': {
            opacity: 1,
            cursor: 'pointer',
        },
    },
    tableWrapper: {
        overflow: 'auto',
    },
});

function TableData(
    { fetchProspect,
        prospectListCounter,
        setIsOpenDataForm,
        setFlagSkipLimit,
        isFilter,
        filterData,
        setFilterData,
        pageFilter,
        fetchProspectByFilter,
        setIsFilter,
    }){
    const classes = style();
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(6);
    const [sortType, setSortType] = useState('DESC');

    useEffect(() => {
        if(isFilter === true){
            setPage(pageFilter);
            handleRenderProspectFilter();
        } else {
            setPage(pageFilter);
            setRowsPerPage(6);
            fetchProspect();
        }
    }, [fetchProspect, prospectListCounter, isFilter, pageFilter, filterData.order]);

    function handleRenderProspectFilter() {
        setFilterData({...filterData, condition: false});
        fetchProspectByFilter(filterData);
    }

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        if(isFilter){
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchProspectByFilter(filterData);
        } else{
            fetchProspect(skipLimit);
        }
        setFlagSkipLimit(skipLimit);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerpage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerpage,
        };
        if(isFilter){
            filterData.skip = 0;
            filterData.limit = newRowsPerpage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchProspectByFilter(filterData);
        } else{
            fetchProspect(skipLimit);
        }
        setFlagSkipLimit(skipLimit);
        setRowsPerPage(newRowsPerpage);
        setPage(0);
    };

    function handleSort(type, column) {
        setSortType(type);
        setIsFilter(true);
        setFilterData(prevState => ({
            ...prevState, order: column,
        }));
        // fetchProspectByFilter(filterData);
    }

    return(
        <div>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>
                                Tanggal
                                <FontAwesomeIcon
                                    icon={sortType === "ASC" ? faSortAmountUp : faSortAmountDown}
                                    className={classes.sortImg}
                                    onClick={sortType === "ASC"
                                        ? ()=> handleSort("DESC", "createdAt DESC")
                                        : ()=> handleSort("ASC", "createdAt ASC")}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>
                                Nama Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>
                                IP Address
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>
                                PIC Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Produk
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Kota
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Preferensi
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Referral
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Status
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ textAlign: 'center', minWidth: 100 }}>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <ItemData page={page} rowsPerPage={rowsPerPage} setIsOpenDataForm={setIsOpenDataForm} />
                    </TableBody>
                </Table>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[6, 10, 25, 100]}
                    component="div"
                    count={prospectListCounter}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    )
}

const mapState = ({ prospect }) => ({
    prospectListCounter: prospect.prospectListCounter,
});

const mapDispatch = ({prospect: { fetchProspect, fetchProspectByFilter } }) => ({
    fetchProspect,
    fetchProspectByFilter,
});

export default connect(mapState, mapDispatch)(TableData);
