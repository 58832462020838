import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {dispatch} from "../../../store";
import {connect} from "react-redux";

import {updateStatusFreeze, updateStatusUnfreeze} from "../../../api/freeze";

const statusFreeze = [
    {label: 'Freeze', value: 1},
    {label: 'Unfreeze', value: 2},
];

const StatusFreezeChanger = ({ freeze, fetchFreeze }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    async function handleUpdateStatus(stat) {
        const { snackbar } = dispatch;
        if(stat.value === 1){
            try {
                const payload = {
                    healthFacilityId: freeze.HealthFacility.id,
                    freezeId: freeze.id,
                };
                await updateStatusFreeze(payload);
                snackbar.showSuccess("Berhasil Freeze");
                setAnchorEl(null);
                fetchFreeze();
            } catch (e) {
                console.error(e);
                snackbar.showError("Gagal Freeze");
                setAnchorEl(null);
            }
        } else if(stat.value === 2) {
            try {
                const payload = {
                    healthFacilityId: freeze.HealthFacility.id,
                    freezeId: freeze.id,
                };
                await updateStatusUnfreeze(payload);
                snackbar.showSuccess("Berhasil Unfreeze");
                setAnchorEl(null);
                fetchFreeze();
            } catch (e) {
                console.error(e);
                snackbar.showError("Gagal Unfreeze");
                setAnchorEl(null);
            }
        }
    }

    return(
        <React.Fragment>
            <IconButton onClick={handleClick}>
                <FontAwesomeIcon icon={faChevronDown}/>
            </IconButton>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    freeze.status === 0 || freeze.status === 2
                        ? <MenuItem onClick={(e)=> {
                            e.stopPropagation();
                            handleUpdateStatus(statusFreeze[0]);
                        }}>
                            {statusFreeze[0].label}
                        </MenuItem>
                        : <MenuItem onClick={(e)=> {
                            e.stopPropagation();
                            handleUpdateStatus(statusFreeze[1]);
                        }}>
                            {statusFreeze[1].label}
                        </MenuItem>
                }
            </Menu>
        </React.Fragment>
    )
};

const mapState = null;

const mapDispatch = ({ freeze: { fetchFreeze } }) => ({
    fetchFreeze,
});

export default connect(mapState, mapDispatch)(StatusFreezeChanger);
