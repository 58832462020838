import React from 'react';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import { connect } from 'react-redux';
import KategoriPenolakanListItem from "../SettingListItem";
import {dispatch} from "../../../store";
import {updateRejectionCategory} from "../../../api/rejection-category";
import {deleteDictionary} from "../../../api/dictionary";

const ListMedia = ({ isFetchingMediaList, mediaList, fetchMedias }) => {
    const handleSave = async (editData, data, currentUser, setIsUpdating) => {
        const { snackbar } = dispatch;

        try {
            setIsUpdating(true);
            await updateRejectionCategory(editData, data.id, currentUser.id);
            setIsUpdating(false);
            snackbar.showSuccess('Berhasil mengupdate media');

            await fetchMedias();
        } catch (err) {
            console.error(err);
            setIsUpdating(false);
            snackbar.showError('Gagal mengupdate media');
        }
    };

    const handleDelete = async (id, setIsDeleting) => {
        const { snackbar } = dispatch;

        try {
            setIsDeleting(true);
            await deleteDictionary(id);
            setIsDeleting(false);
            snackbar.showSuccess('Berhasil menghapus media');

            await fetchMedias();
        } catch (err) {
            console.error(err);
            setIsDeleting(false);
            snackbar.showError('Gagal menghapus media');
        }
    };

    return (
        <Grid item xs={12}>
            {
                isFetchingMediaList
                    ? <Grid container justify="center">
                        <CircularProgress />
                    </Grid>
                    : <List style={{marginTop: '40px', marginLeft: '-13px'}}>
                        {
                            mediaList.map(eventName => (
                                <KategoriPenolakanListItem
                                    key={eventName.id}
                                    data={eventName}
                                    saveHandler={handleSave}
                                    deleteHandler={handleDelete}
                                />
                            ))
                        }
                    </List>
            }
        </Grid>
    )
};

const mapState = ({ media }) => ({
    mediaList: media.mediaList,
    isFetchingMediaList: media.isFetchingMediaList,
});

const mapDispatch = ({ media: { fetchMedias } }) => ({
    fetchMedias,
});

export default connect(mapState, mapDispatch)(ListMedia);
