import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import Header from './../templateFaskes/header/header';
// import FormDetailLeads from './FormDetailLeads';
import FormDetailLeadsV1 from "./FormDetailLeadsV1";

import './leads.css';

function DetailLeads({ fetchSingleFaskesList, updateSingleFaskesList, match }){

    useEffect(()=> {
        if(match.params.id) {
            fetchSingleFaskesList(match.params.id);
        } else {
            updateSingleFaskesList({});
        }
    }, [match.params.id, fetchSingleFaskesList, updateSingleFaskesList]);

    return(
        <div className="leads-content">
            <Header title={"Leads"} />
            <FormDetailLeadsV1 />
            {/*<FormDetailLeads data={data} mediaList={mediaList} isFetchingDetailLeads={isFetchingDetailLeads} />*/}
        </div>
    )
}

const mapState = null;

const mapDispatch = ({ leads: { fetchSingleFaskesList, updateSingleFaskesList } }) => ({
    fetchSingleFaskesList,
    updateSingleFaskesList,
});

export default connect(mapState, mapDispatch)(DetailLeads);
