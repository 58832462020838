import axios from 'axios';

const defaultConfig = {
    baseURL: 'https://image-upload.medikaboo.com/',
};

const instance = axios.create(defaultConfig);

export function uploadProfileImage(data) {
    const file = new FormData();
    file.append(data.name, data);
    return instance.post('file', file);
}
