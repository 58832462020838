import React, { useState } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from "@material-ui/core/TablePagination";
import {faTrash, faEdit} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from '@material-ui/core/styles';
import DeleteConfirmation from "../../share/DeleteConfirmation";

import { deleteUser } from '../../../api/panela-user';
import { dispatch } from "../../../store";
import {toTitleCase} from "../../../helpers/capitalize";

const styles = makeStyles({
   tableCellBody: {
       fontFamily: 'Nunito',
       fontSize: '14px',
       color: '#15162d',
   },
   tableCellHeader: {
       fontFamily: 'Nunito',
       fontSize: '16px',
       color: '#15162d',
       fontWeight: 'bold',
   }
});

const ListPICAssist = ({ userList, getPanelaUserList, handleOpenEdit, search }) => {
    const classes = styles();
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(undefined);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleDeletePIC = async () => {
        const { snackbar } = dispatch;
        try {
            await deleteUser(selectedUserId);
            snackbar.showSuccess('Berhasil menghapus user');
            setOpenDeleteConfirmation(false);
            getPanelaUserList();
        } catch (err) {
            console.error(err);
            snackbar.showError(err);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            <DeleteConfirmation
                contentText={"Apakah anda yakin akan menghapus user ini?"}
                open={openDeleteConfirmation}
                onClose={() => {
                    setOpenDeleteConfirmation(false);
                    setSelectedUserId(undefined);
                }}
                onSubmit={() => handleDeletePIC()}
            />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellHeader}>
                            Nama
                        </TableCell>

                        <TableCell className={classes.tableCellHeader}>
                            Email
                        </TableCell>

                        <TableCell className={classes.tableCellHeader}>
                            Jabatan
                        </TableCell>

                        <TableCell className={classes.tableCellHeader}>
                            Nomor telepon/WA
                        </TableCell>

                        <TableCell className={classes.tableCellHeader}>
                            Hak Akses
                        </TableCell>

                        <TableCell className={classes.tableCellHeader}>
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        userList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
                            <TableRow key={user.id}>
                                <TableCell className={classes.tableCellBody}>
                                    {user.firstName} {user.lastName}
                                </TableCell>

                                <TableCell className={classes.tableCellBody}>
                                    {user.email}
                                </TableCell>

                                <TableCell className={classes.tableCellBody}>
                                    {user.position || '-'}
                                </TableCell>

                                <TableCell className={classes.tableCellBody}>
                                    {user.phone}
                                </TableCell>

                                <TableCell className={classes.tableCellBody}>
                                    {
                                        user.UserRole
                                            ? toTitleCase(user.UserRole.role)
                                            : '-'
                                    }
                                </TableCell>

                                <TableCell>
                                    <IconButton onClick={() => handleOpenEdit({...user})}>
                                        <FontAwesomeIcon icon={faEdit} style={{ color: 'green', width: 18, height: 18 }} />
                                    </IconButton>

                                    <IconButton
                                        onClick={() => {
                                            setOpenDeleteConfirmation(true);
                                            setSelectedUserId(user.id);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} style={{ color: 'red', width: 18, height: 18 }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={userList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage} />
        </div>
    );
};

const mapState = ({ panelaUser }) => ({
    userList: panelaUser.userList,
});

const mapDispatch = ({ panelaUser: { getPanelaUserList } }) => ({
    getPanelaUserList,
});

export default connect(mapState, mapDispatch)(ListPICAssist);
