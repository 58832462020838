import React, {useState, useEffect} from "react";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import BerlanggananItem from "./BerlanggananItem";

const styles = makeStyles({
    tableWrapper: {
        overflow: 'auto',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
    btn: {
        borderRadius: 6,
        borderColor: 'red',
        background: 'white',
        color: 'red',
        padding: '7px 20px',
        textTransform: 'capitalize',
        '&:hover': {
            background: 'red',
            color: 'white'
        },
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize: '13px',
    },
});

const BerlanggananTable =
    ({ subscribedFaskesListCounter,
         fetchSubscribedFaskesList,
         updateSubscribedFaskesListChecked,
         subscribedFaskesList,
         isFilter,
         filterData,
         setFilterData,
         fetchSubscribedFaskesListFilter
    }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        if(isFilter === true) {
            setPage(0);
            handleRenderSubscibedFaskesFilter();
        } else {
            setPage(0);
            setRowsPerPage(5);
            fetchSubscribedFaskesList();
        }
    }, [fetchSubscribedFaskesList, fetchSubscribedFaskesListFilter, isFilter]);

    const handleRenderSubscibedFaskesFilter = () => {
        setFilterData(prevState => ({
            ...prevState, condition: false,
        }));
        fetchSubscribedFaskesListFilter(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        if(isFilter === true) {
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchSubscribedFaskesListFilter(filterData);
        } else {
            fetchSubscribedFaskesList(skipLimit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerPage,
        };
        if(isFilter === true) {
            filterData.skip = 0;
            filterData.limit = newRowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchSubscribedFaskesListFilter(filterData);
        } else {
            fetchSubscribedFaskesList(skipLimit);
        }
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const handleChangeChecked = (e, idx) => {
        subscribedFaskesList[idx].isChecked = e.target.checked;
        updateSubscribedFaskesListChecked([...subscribedFaskesList]);
    };

    return (
        <div>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>Nama Klinik</TableCell>
                            <TableCell className={classes.tableCellHeader}>Produk</TableCell>
                            <TableCell className={classes.tableCellHeader}>Durasi Berlangganan</TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>Tanggal Buat Akun</TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>Masa Aktif Awal</TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>Masa Aktif Akhir</TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100, textAlign: 'center' }}>Jatuh Tempo</TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 150, textAlign: 'center' }}>Jumlah Invoice</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <BerlanggananItem page={page} rowsPerPage={rowsPerPage} handleChangeChecked={handleChangeChecked}/>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Button variant="outlined" className={classes.btn}>
                                    Berhenti Berlangganan
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={subscribedFaskesListCounter}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

const mapState = ({ client }) => ({
    subscribedFaskesListCounter: client.subscribedFaskesListCounter,
    subscribedFaskesList: client.subscribedFaskesList,
});

const mapDispatch = ({ client: { fetchSubscribedFaskesList, updateSubscribedFaskesListChecked, fetchSubscribedFaskesListFilter } }) => ({
    fetchSubscribedFaskesList,
    updateSubscribedFaskesListChecked,
    fetchSubscribedFaskesListFilter,
});

export default connect(mapState, mapDispatch)(BerlanggananTable);
