import axios from 'axios';
import { API_URL } from "../constants";

function createInstance() {
    const config = {
        baseURL: API_URL,
    };

    const instance = axios.create(config);
    instance.interceptors.request.use((cfg) => {
        const token = window.localStorage.getItem('token');
        const newCfg = {...cfg};
        newCfg.headers.Authorization = token || '';
        return newCfg;
    });

    return instance;
}

export default createInstance();
