import React, {useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import {retensionTableHeader} from "../../../../constants";

import ProdukFreeItem from "./ProdukFreeItem";

const styles = makeStyles({
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
    tableWrapper: {
        overflow: 'auto',
    },
});

const ProdukFreeTable = ({ filterData, setFilterData, fetchRetensionFreeProduct, retensionFreeProductCounter }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        fetchRetensionFreeProduct(filterData);
    }, [fetchRetensionFreeProduct, filterData]);

    const handleChangePage = (event, newPage) => {
        filterData.skip = newPage * rowsPerPage;
        filterData.limit = rowsPerPage;
        setFilterData(prevState => ({
            ...prevState, skip: filterData.skip, limit: filterData.limit,
        }));
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        const newRowsPerPage = parseInt(e.target.value, 10);
        filterData.skip = 0;
        filterData.limit = newRowsPerPage;
        setFilterData(prevState => ({
            ...prevState, skip: filterData.skip, limit: filterData.limit,
        }));
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    return (
        <div>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                retensionTableHeader.map((item, idx) => (
                                    <TableCell key={idx} className={classes.tableCellHeader} style={{ minWidth: 120 }}>
                                        {item}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <ProdukFreeItem page={page} rowsPerPage={rowsPerPage}/>
                    </TableBody>
                </Table>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={retensionFreeProductCounter}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

const mapState = ({ laporan }) => ({
    retensionFreeProductCounter: laporan.retensionFreeProductCounter,
});

const mapDispatch = ({ laporan: { fetchRetensionFreeProduct } }) => ({
    fetchRetensionFreeProduct,
});

export default connect(mapState, mapDispatch)(ProdukFreeTable);
