import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import format from "date-fns/format";
import NumberFormat from "react-number-format";

import InvoiceStatus from "./InvoiceStatus";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
});

const InvoiceItem = ({ page, rowsPerPage, isFetchingInvoicesList, invoicesList, updateSelectedInvoicesList, updatePageInvoices, setSubMenu, updatePageReceipts, updateSelectedReceiptsList }) => {
    const index = page * rowsPerPage;
    const classes = styles();
    const [isOpenInvoiceStatus, setIsOpenInvoiceStatus] = useState(false);

    function handleEdit(data) {
        updateSelectedInvoicesList(JSON.parse(JSON.stringify(data)));
        updatePageInvoices(1);
    }

    function handleEditStatus(data) {
        setIsOpenInvoiceStatus(true);
        updateSelectedInvoicesList(JSON.parse(JSON.stringify(data)));
    }

    function handleClose() {
        setIsOpenInvoiceStatus(false);
        updateSelectedInvoicesList({});
    }

    function handleMoveToReceipt(item, payment) {
        const flag = {};
        const flagItem = JSON.parse(JSON.stringify(item));
        flag._id = flagItem._id;
        flag.nama = flagItem.nama;
        flag.alamat = flagItem.alamat;
        flag.KConfig = flagItem.KConfig;
        flag.KConfig.activeBilling.Billing.payment = payment;
        updateSelectedReceiptsList(flag);
        setSubMenu(4);
        updatePageReceipts(1);
    }

    return (
        <React.Fragment>
            <Dialog open={isOpenInvoiceStatus} maxWidth="xs" onClose={()=> handleClose()} fullWidth>
                <DialogContent>
                    <InvoiceStatus onClose={()=> handleClose()}/>
                </DialogContent>
            </Dialog>
            {
                isFetchingInvoicesList === true
                    ? <TableRow>
                        <TableCell colSpan={9}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        </TableCell>
                    </TableRow>
                    : invoicesList.slice(index, index + rowsPerPage).map((item, idx) => (
                        <TableRow key={idx} className={classes.row} onClick={()=> handleEdit(item)}>
                            <TableCell className={classes.tableCellBody}>
                                {item.KConfig.activeBilling.Billing.invoiceNo}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.nama}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {
                                    item.KConfig.activeBilling.Billing.createdAt
                                        ? format(new Date(item.KConfig.activeBilling.Billing.createdAt), "dd/MM/yyyy")
                                        : '-'
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                <ul>
                                    {item.KConfig.activeBilling.Billing.payment.map((data, idx) => (
                                        <li key={idx}>
                                            {format(new Date(data.dueDatePayment), "dd/MM/yyyy")}
                                        </li>
                                    ))}
                                </ul>
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                <ul>
                                    {item.KConfig.activeBilling.Billing.payment.map((data, idx) => (
                                        <li key={idx}>
                                            <NumberFormat
                                                displayType="text"
                                                value={data.value}
                                                prefix="Rp. "
                                                decimalSeparator=","
                                                thousandSeparator="."
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                <ul>
                                    {item.KConfig.activeBilling.Billing.payment.map((data, idx) => (
                                        <li key={idx}>
                                            {`${idx+1}/${item.KConfig.activeBilling.Billing.payment.length}`}
                                        </li>
                                    ))}
                                </ul>
                            </TableCell>
                            <TableCell className={classes.tableCellBody} onClick={(e)=> {
                                e.stopPropagation();
                                handleEditStatus(item);
                            }}>
                                <ul>
                                    {item.KConfig.activeBilling.Billing.payment.map((data, idx) => (
                                        <li key={idx}>
                                            {
                                                data.isPayed === true
                                                    ? <div style={{ display: 'flex', marginBottom: 10 }}>
                                                        <span>Dibayar</span>
                                                        {
                                                            data.buktiBayar && <Tooltip title="Lihat data bukti bayar?">
                                                                <IconButton style={{ marginTop: -15 }} onClick={(e)=> {
                                                                    e.stopPropagation();
                                                                    handleMoveToReceipt(item, data);
                                                                }}>
                                                                    <FontAwesomeIcon icon={faPaperPlane}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                    : "Menunggu"
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </TableCell>
                            <TableCell>
                                {
                                    item.KConfig.activeBilling.isActive === false
                                        ? <Tooltip title="Klinik sedang di freeze">
                                            <IconButton>
                                                <FontAwesomeIcon icon={faLock} style={{ color: "#2b64af" }}/>
                                            </IconButton>
                                        </Tooltip>
                                        : null
                                }
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
};

const mapState = ({ client }) => ({
    isFetchingInvoicesList: client.isFetchingInvoicesList,
    invoicesList: client.invoicesList,
});

const mapDispatch = ({ client: { updateSelectedInvoicesList, updatePageInvoices, updatePageReceipts, updateSelectedReceiptsList } }) => ({
    updateSelectedInvoicesList,
    updatePageInvoices,
    updatePageReceipts,
    updateSelectedReceiptsList,
});

export default connect(mapState, mapDispatch)(InvoiceItem);
