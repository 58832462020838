import agent from "./agent";
import format from "date-fns/format";

export const addNewFreeze = (data) => {
    return agent.post('Freezes', data);
};

export const updateFreeze = (freezeId, data) => {
    return agent.patch(`Freezes/${freezeId}`, data);
};

export const getFreezes = (skipLimit) => {
    let filter;
    if(skipLimit){
        filter = skipLimit;
        filter.include = ['HealthFacility'];
    } else {
        filter = {
            skip: 0,
            limit: 5,
            include: ['HealthFacility'],
        };
    }

    return agent.get('Freezes', {
        params: {
            filter,
        },
    });
};

export const countFreeze = () => {
    return agent.get('Freezes/count');
};

export const deleteFreeze = (freezeId) => {
    return agent.delete(`Freezes/${freezeId}/deleteFreeze`);
};

export const updateStatusFreeze = (payload) => {
    return agent.post('Freezes/freezeFaskes', payload);
};

export const updateStatusUnfreeze = (payload) => {
    return agent.post('Freezes/unfreezeFaskes', payload);
};

export const countFreezeByFaskesId = (faskesId) => {
    const where = {
        healthFacilityId: faskesId,
    };

    return agent.get('Freezes/count', {
        params: {
            where,
        },
    });
};

export const getFreezesByFilter = (payload) => {
    const params = {
        tanggalFreezeMulai: payload.tanggalFreezeMulai === null ? '' : format(new Date(payload.tanggalFreezeMulai), "yyyy-MM-dd"),
        tanggalFreezeSelesai: payload.tanggalFreezeSelesai === null ? '' : format(new Date(payload.tanggalFreezeSelesai), "yyyy-MM-dd"),
        nama: payload.nama,
        alasan: payload.alasan,
        status: payload.status,
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Freezes/getFreezes', {
        params,
    });
};

export const countFreezesListByFilter = (payload) => {
    const params = {
        tanggalFreezeMulai: payload.tanggalFreezeMulai === null ? '' : format(new Date(payload.tanggalFreezeMulai), "yyyy-MM-dd"),
        tanggalFreezeSelesai: payload.tanggalFreezeSelesai === null ? '' : format(new Date(payload.tanggalFreezeSelesai), "yyyy-MM-dd"),
        nama: payload.nama,
        alasan: payload.alasan,
        status: payload.status,
    };

    return agent.get('Freezes/countFreezes', {
        params,
    });
};
