import {getIklans} from "../../api/iklan";

export const iklan = {
    state: {
        isFetchingIklanList: false,
        iklanList: [],
        selectedIklanList: {},
    },
    reducers: {
        updateState(state, payload) {
            const { statName, value } = payload;

            return {
                ...state,
                [statName]: value,
            };
        },
        updateSelectedIklan(state, payload) {
            return {
                ...state,
                selectedIklanList: payload,
            };
        },
    },
    effects: dispatch => ({
        async fetchIklanList() {
            try {
                this.updateState({
                    statName: 'isFetchingIklanList',
                    value: true,
                });

                const result = await getIklans();

                this.updateState({
                    statName: 'iklanList',
                    value: result.data,
                });

                this.updateState({
                    statName: 'isFetchingIklanList',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar iklan");
                this.updateState({
                    statName: 'isFetchingIklanList',
                    value: false,
                });
            }
        },
    }),
};
