export const app = {
    state: {
        isLoaded: false,
        secondaryMenu: "/",
        isViewedByMobile: false,
    },
    reducers: {
        updateSecondaryMenu(state, payload) {
            return {
                ...state,
                secondaryMenu: payload,
            };
        },
        updateIsLoaded(state, payload) {
            return {
                ...state,
                isLoaded: payload,
            };
        },
        updateIsViewedByMobile(state, payload) {
            return {
                ...state,
                isViewedByMobile: payload,
            };
        },
    },
};
