import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faCheckCircle} from "@fortawesome/free-solid-svg-icons";

import filter from './../../../assets/img/filter-icon.png';
import arrowDown from './../../../assets/img/arrow-down-icon.png';

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import PenawaranForm from "./PenawaranForm";
import PenawaranTable from "./PenawaranTable";
import PrintDialog from "./PrintDialog";
import PenawaranFilter from "./PenawaranFilter";

const styles = makeStyles({
    chip: {
        marginRight: 10,
    },
});

const Penawaran = ({ updateSelectedOffer, fetchOffersFilter, fetchGeneralSetting, selectedOfferFilter, updateSelectedOfferFilter }) => {
    const classes = styles();
    const [isOpenAddNew, setIsOpenAddNew] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        tanggalPenawaranMulai: null,
        tanggalPenawaranSelesai: null,
        nama: '',
        picKlinik: '',
        hargaPenawaran: '',
        termin: 0,
        batasPembayaranMulai: null,
        batasPembayaranSelesai: null,
        picAssist: '',
        statusPembayaran: '',
        skip: 0,
        limit: 5,
    });
    const [pageFilter, setPageFilter] = useState(0);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isOpenFilterForm, setIsOpenFilterForm] = useState(false);

    useEffect(()=> {
        fetchGeneralSetting();
    }, [fetchGeneralSetting]);

    function handleCloseDialog() {
        setIsOpenAddNew(false);
        setIsUpdate(false);
        updateSelectedOffer({});
    }

    const handleFilter = () => {
        setIsFilter(true);
        setPageFilter(0);
        fetchOffersFilter(filterData);
        setIsOpenFilterForm(false);
        updateSelectedOfferFilter(JSON.parse(JSON.stringify(filterData)));
    };

    const handleClearFilter = () => {
        setFilterData({
            tanggalPenawaranMulai: null,
            tanggalPenawaranSelesai: null,
            nama: '',
            picKlinik: '',
            hargaPenawaran: '',
            termin: 0,
            batasPembayaranMulai: null,
            batasPembayaranSelesai: null,
            picAssist: '',
            statusPembayaran: '',
            skip: 0,
            limit: 5,
        });
        setPageFilter(0);
        setIsFilter(false);
        updateSelectedOfferFilter({});
    };

    return (
        <Grid container>
            <Dialog className={"hideForPrint"} maxWidth="lg" open={isOpenAddNew} onClose={() => handleCloseDialog()} fullWidth>
                <DialogContent>
                    {
                        isOpenAddNew && <PenawaranForm onClose={() => handleCloseDialog()} isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
                    }
                </DialogContent>
            </Dialog>

            <PrintDialog />

            <Grid container item xs={12} style={{marginTop: '44px'}}>
                <Grid container item xs={6}>
                    <PInput
                        style={{
                            marginRight: 32,
                            flex: 0.9,
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch} />
                            </InputAdornment>
                        }}
                        placeholder="Cari klinik disini"
                    />
                    <PBtn onClick={() => setIsOpenAddNew(true)}>
                        Tambahkan
                    </PBtn>
                </Grid>
                <Grid item xs={6}>
                    <div className="filter" style={{marginTop: '-8px'}}>
                        <img alt="filter" src={filter} className="img-filter" />
                        <Typography>Filter by</Typography>
                        <img
                            alt="arrow"
                            src={arrowDown}
                            className="arrow-filter"
                            onClick={()=> setIsOpenFilterForm(true)}
                        />
                        <Dialog open={isOpenFilterForm} maxWidth="xs" onClose={()=> setIsOpenFilterForm(false)} fullWidth>
                            <DialogContent>
                                <PenawaranFilter
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    startFilter={handleFilter}
                                    clearFilter={handleClearFilter}
                                />
                            </DialogContent>
                        </Dialog>
                    </div>
                </Grid>
            </Grid>
            {
                isFilter && <Grid item xs={12} style={{ marginTop: 30, dispaly: 'flex' }}>
                    {
                        selectedOfferFilter.tanggalPenawaranMulai !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Tanggal Mulai"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedOfferFilter.tanggalPenawaranSelesai !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Tanggal Selesai"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedOfferFilter.nama !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Nama Klinik"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedOfferFilter.picKlinik !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="PIC Klinik"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedOfferFilter.hargaPenawaran !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Harga Penawaran"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedOfferFilter.termin !== 0
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Termin"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedOfferFilter.batasPembayaranMulai !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Pembayaran Dari Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedOfferFilter.batasPembayaranSelesai !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Pembayaran Sampai Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedOfferFilter.picAssist !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="PIC Assist.id"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                </Grid>
            }
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <PenawaranTable
                    setIsOpenAddNew={setIsOpenAddNew}
                    isFilter={isFilter}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    pageFilter={pageFilter}
                />
            </Grid>
        </Grid>
    );
};

const mapState = ({ activity }) => ({
    selectedOfferFilter: activity.selectedOfferFilter,
});

const mapDispatch = ({ activity: { updateSelectedOffer, fetchOffersFilter, updateSelectedOfferFilter }, generalSetting: { fetchGeneralSetting } }) => ({
    updateSelectedOffer,
    fetchOffersFilter,
    updateSelectedOfferFilter,
    fetchGeneralSetting,
});

export default connect(mapState, mapDispatch)(Penawaran);
