import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AddNew from "../AddNew";
import ListMedia from "./ListMedia";
import { connect } from 'react-redux';
import {dispatch} from "../../../store";
import {addNewMedia} from "../../../api/media";

const Media = ({ fetchMedias }) => {
    useEffect(() => {
        fetchMedias();
    });

    const handleSubmit = async (media, currentUser, setIsSubmitting, setEventName) => {
        const { snackbar } = dispatch;
        const trimmedValue = media.trim();

        if (trimmedValue === '') {
            snackbar.showError('Media harus diisi');
            return;
        }

        try {
            setIsSubmitting(true);
            await addNewMedia(media, currentUser.id);
            setEventName('');
            setIsSubmitting(false);
            snackbar.showSuccess('Berhasil menambahkan media');

            await fetchMedias();
        } catch (err) {
            console.error(err);
            setIsSubmitting(false);
            snackbar.showError('Gagal menambahkan media');
        }
    };

    return (
        <Grid container>
            <AddNew handler={handleSubmit} placeholder={"Tambah media disini"} buttonLabel={"Tambah Media"} />

            <ListMedia />
        </Grid>
    );
};

const mapState = null;

const mapDispatch = ({ media: { fetchMedias } }) => ({
    fetchMedias,
});

export default connect(mapState, mapDispatch)(Media);
