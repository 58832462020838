import React, { useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import format from "date-fns/format";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TableRow from "@material-ui/core/TableRow";
import {makeStyles} from "@material-ui/core/styles";

import SquareSwitch from "../../share/SquareSwitch";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import PBtn from "../../share/PBtn";
import Dialog from "@material-ui/core/Dialog";
import {setBlockedStatus} from "../../../api/faskes";
import {dispatch} from "../../../store";

import PInput from "../../share/PInput";

const useStyles = makeStyles({
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    titleDialog: {
        fontFamily: "Nunito",
        fontSize: "20px",
        color: "#15162d",
        fontWeight: "600",
        marginLeft: "0px",
        marginBottom: "10px",
    }
});

const BlockedRow = ({ blocked, fetchBlockedList }) => {
    const classes = useStyles();

    const { blockedDate, blockedDetail, isBlocked } = blocked;
    const [currentStatus, setCurrentStatus] = React.useState(isBlocked || false);
    const [nextStatus, setNextStatus] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [keterangan, setKeterangan] = React.useState('');
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isShowDetail, setIsShowDetail] = React.useState(false);
    const [selectedBlocked, setSelectedBlocked] = React.useState(undefined);

    useEffect(() => {
        setCurrentStatus(isBlocked || false);
    }, [isBlocked]);

    function handleChangeStatus(e) {
        setNextStatus(e.target.checked);
        setOpenDialog(true);
    }

    function handleClose() {
        setOpenDialog(false);
        setIsShowDetail(false);
        setKeterangan('');
        setSelectedBlocked(undefined);
    }

    async function handleSimpan() {
        const { snackbar } = dispatch;
        try {
            let payload;
            if (nextStatus) {
                // if blocked
                payload = {
                    healthFacilityId: blocked.id,
                    isBlocked: nextStatus,
                    blockedDate: new Date(),
                    blockedDetail: keterangan.trim(),
                };

                console.log(payload);
            } else {
                payload = {
                    healthFacilityId: blocked.id,
                    isBlocked: nextStatus,
                    blockedDate: new Date(),
                    blockedDetail: keterangan.trim(),
                };

                console.log(payload);
            }

            setIsUpdating(true);
            await setBlockedStatus(payload);
            setIsUpdating(false);
            snackbar.showSuccess('Block faskes berhasil');
            setCurrentStatus(nextStatus);
            handleClose();
            fetchBlockedList();
        } catch (err) {
            console.error(err);
            setIsUpdating(false);
            snackbar.showError('Block faskes gagal');
        }
    }

    function handleShowDetail(blocked) {
        setSelectedBlocked(blocked);
        setIsShowDetail(true);
        setOpenDialog(true);
    }

    return (
        <React.Fragment>
            <Dialog open={openDialog} onClose={isUpdating ? () => {} : () => handleClose()}>
                {
                    isShowDetail
                        ? <DialogContent>
                            <Grid container>
                                <Grid item xs={12} style={{ marginBottom: 18 }}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Nama Klinik</Typography>
                                    <PInput
                                        placeholder={"Nama Klinik"}
                                        value={selectedBlocked.name}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ marginBottom: 18 }}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Tanggal Block</Typography>
                                    <PInput
                                        placeholder={"Tanggal Block"}
                                        value={
                                            selectedBlocked.blockedDate
                                                ? format(new Date(selectedBlocked.blockedDate), 'dd/MM/yyyy')
                                                : '-'
                                        }
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ marginBottom: 18 }}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>PIC Klinik</Typography>
                                    <PInput
                                        placeholder={"PIC Klinik"}
                                        value={selectedBlocked.PIC.map($item => $item.namepic).join(', ')}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ marginBottom: 32 }}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Keterangan</Typography>
                                    <PInput
                                        multiline
                                        rows={4}
                                        placeholder={"Keterangan"}
                                        value={
                                            selectedBlocked.blockedDetail
                                                ? selectedBlocked.blockedDetail
                                                : '-'
                                        }
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        : <DialogContent>
                            <Typography className={classes.titleDialog}>{`Berikan keterangan ${nextStatus ? 'blocked' : 'unblocked'}`}</Typography>
                            <PInput
                                value={keterangan}
                                onChange={(e) => setKeterangan(e.target.value)}
                                multiline
                                fullWidth
                                rows={4}
                                label="Keterangan"
                                placeholder="Keterangan"
                            />
                        </DialogContent>
                }

                {
                    !isShowDetail && <DialogActions style={{marginTop: '10px', marginRight: '16px', marginBottom: '10px'}}>
                        <PBtn
                            onClick={isUpdating ? () => {} : () => handleClose()}
                            inverted dropShadow
                            disabled={isUpdating}
                        >
                            Batal
                        </PBtn>

                        <PBtn disabled={isUpdating} onClick={() => handleSimpan()}>
                            Simpan
                        </PBtn>
                    </DialogActions>
                }
            </Dialog>

            <TableRow
                className={classes.row}
                onClick={() => handleShowDetail(blocked)}
            >
                <TableCell className={classes.tableCellBody}>
                <span>
                    {
                        blockedDate
                            ? format(new Date(blockedDate), 'dd/MM/yyyy')
                            : '-'
                    }
                </span>
                </TableCell>

                <TableCell className={classes.tableCellBody}>
                    <span>{blocked.name}</span>
                </TableCell>

                <TableCell className={classes.tableCellBody}>
                    <span>{blocked.PIC.map($item => $item.namepic).join(', ')}</span>
                </TableCell>

                <TableCell className={classes.tableCellBody}>
                <span>
                    {
                        blockedDetail
                            ? blockedDetail
                            : '-'
                    }
                </span>
                </TableCell>

                <TableCell>
                    <FormGroup onClick={(e) => { e.stopPropagation() }}>
                        <FormControlLabel
                            control={
                                <SquareSwitch
                                    checked={currentStatus}
                                    onChange={(e) => {
                                        handleChangeStatus(e)
                                    }}
                                />
                            }
                            label={currentStatus ? 'Blocked' : 'Unblocked'}
                        />
                    </FormGroup>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default BlockedRow;
