import React from 'react';
import {Route} from 'react-router-dom';
import Leads from '../leads/Leads';
import MainNavbar from './navbar/MainNavbar';
import AddLeads from "../leads/AddLeads";
import Setting from '../../setting/Setting';
import Account from "../../account/Account";
import Activity from "../../activity/Activity";
import DetailLeads from '../leads/DetailLeads';
// import EditLeads from "../leads/EditLeads";
// import Data from "../../data/Data";
import DataHome from "../../data/DataHome";
import Client from "../../client/Client";
import Dashboard from "../../dashboard/Dashboard";
import Report from "../../report/Report";
import Ticketing from "../../ticketing/Ticketing";

import {connect} from "react-redux";

import './templateFaskes.css';

function templateFaskes({ currentUser, updateIsViewedByMobile }) {
    const isManajemen = currentUser.UserRole && currentUser.UserRole.role === 'manajemen';
    const cek = window.navigator.userAgent;
    updateIsViewedByMobile(cek.indexOf('Mobile') !== -1);

    return(
        <div className="templateFaskes">
            <MainNavbar />

            <div className="content">
                <Route path="/" component={Dashboard} exact/>
                <Route path="/dashboard" component={Dashboard} exact/>
                <Route path="/leads" component={Leads} exact/>
                <Route path="/leads/add" component={AddLeads} exact/>
                <Route path="/leads/detail/:id" component={DetailLeads} exact/>
                <Route path="/leads/edit/:id" component={AddLeads} exact/>
                <Route path="/activity" component={Activity} exact/>
                {isManajemen && <Route path="/client" component={Client} exact/>}
                <Route path="/data" component={DataHome} exact/>
                {isManajemen && <Route path="/report" component={Report} exact/>}
                <Route path="/account" component={Account} exact/>
                {isManajemen && <Route path="/setting" component={Setting} exact/>}
                <Route path="/ticketing" component={Ticketing} exact/>
            </div>
        </div>
    )
}

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
});

const mapDispatch = ({ app: { updateIsViewedByMobile } }) => ({
    updateIsViewedByMobile,
});

export default connect(mapState, mapDispatch)(templateFaskes);
