import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, } from "@material-ui/core";
import format from 'date-fns/format';
import idLocale from 'date-fns/locale/id';
import {getLastActivities} from "../../../api/activity";
import {dispatch} from "../../../store";

import './tableLeads.css';

const style = makeStyles({
    tableWrapper: {
        maxHeight: 440,
        overflow: 'auto',
    }
})

function TableLastActivityLeads(props) {
    const classes = style();
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(5);
    const [ data, setData ] = useState("");

    useEffect(()=>{
        if(props.faskesId){
            async function fetchDataLastActivities(){
                try{
                    const result = await getLastActivities(props.faskesId);
                    setData(result.data);
                    return true
                } catch (e){
                    console.error(e);
                    dispatch.snackbar.showError('Gagal Mendapatkan Last Activities');
                    return false
                }
            }
            fetchDataLastActivities()
        }
    }, [props]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return(
        <div className="table-leads">
            <div className={classes.tableWrapper}>
                <Table aria-label="sticky label">
                    <TableHead>
                        <TableRow>
                            { props ?  props.header.map((data, index) => (
                                <TableCell key={index} style={{minWidth: data.minWidth}} className="table-cell-header">{data.label}</TableCell>
                            )) : "" }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, idx) => (
                            <TableRow className="table-row-body" key={idx}>
                                <TableCell className="table-cell-body">{format(new Date(data.activityDate), "dd MMMM yyyy", {locale: idLocale})}</TableCell>
                                <TableCell className="table-cell-body">{data.healthFacilityPic ? data.healthFacilityPic.namepic : "-"}</TableCell>
                                <TableCell className="table-cell-body">{data.PICAssist ? data.PICAssist.firstName+" "+data.PICAssist.lastName : '-'}</TableCell>
                                <TableCell className="table-cell-body">{data.media || ''}</TableCell>
                                <TableCell className="table-cell-body">{data.status || ''}</TableCell>
                                <TableCell className="table-cell-body">{data.note || ""}</TableCell>
                                {/*<TableCell className="table-cell-body">{data.OfferData ? "Iya" : "Tidak"}</TableCell>*/}
                                {/*<TableCell className="table-cell-body">{data.RejectionData ? "Iya" : "Tidak"}</TableCell>*/}
                                {/*<TableCell className="table-cell-body">{data.HealthFacility.status}</TableCell>*/}
                                {/*<TableCell className="table-cell-body">{data.type ? data.type : "Initiative"}</TableCell>*/}
                            </TableRow>
                        )) : <TableRow>
                                <TableCell colSpan={8} className="table-cell-body" style={{textAlign: "center"}}>Tidak Ada Data</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage} />
        </div>
    )
}

export default TableLastActivityLeads;
