import React, {useState, useEffect} from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Chip from "@material-ui/core/Chip";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import filterIcon from "../../assets/img/filter-icon.png";
import arrowDown from "../../assets/img/arrow-down-icon.png";

import Header from "../faskes/templateFaskes/header/header";

import PInput from "../share/PInput";
import PBtn from "../share/PBtn";

import TicketingTable from "./TicketingTable";
import TicketingForm from "./TicketingForm";
import TicketingFilter from "./TicketingFilter";

import debounce from "lodash/debounce";

import {getUserList} from "../../api/panela-user";

const styles = makeStyles({
    content: {
        width: '100%',
    },
    body: {
        width: '100%',
        height: '85%',
        position: 'absolute',
        marginTop: '7%',
        bottom: 0,
        backgroundColor: '#f5f6f8',
        overflowY: 'auto',
        '@media only screen and (max-width:994px)': {
            height: '90%',
            width: '1440px',
        },
    },
    container: {
        padding: '25px 20px',
        '@media only screen and (max-width:994px)': {
            padding: '4% 20px',
        },
        '@media only screen and (max-width:400px)': {
            padding: '4% 20px',
        },
    },
    filter: {
        float: 'right',
        display: 'inline-flex',
        padding: '15px 5px',
    },
    labelFilter: {
        fontWeight: 'bold',
        fontSize: '16px',
        fontFamily: 'Nunito',
        marginTop: '3px',
        marginRight: '10px',
    },
    imgFilter: {
        height: '40%',
        marginRight: '15px',
    },
    arrowFilter: {
        height: '40%',
        marginTop: '10px',
        cursor: 'pointer',
    },
    wrapChip: {
        display: 'flex',
        marginTop: 30,
    },
    chip: {
        marginRight: 10,
    },
});

const Ticketing = ({ updateSelectedTicketing, selectedTicketingFilter, updateSelectedTicketingFilter, isViewedByMobile }) => {
    const classes = styles();
    const [search, setSearch] = useState('');
    const [filterData, setFilterData] = useState({
        tanggalMulaiStart: null,
        tanggalMulaiEnd: null,
        tanggalSelesaiStart: null,
        tanggalSelesaiEnd: null,
        namaKlinik: '',
        picKlinik: '',
        namaProduk: '',
        status: '',
        kategori: '',
        pic: '',
        skip: 0,
        limit: 5,
    });
    const [filter, setFilter] = useState(0);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isOpenDialogFilter, setIsOpenDialogFilter] = useState(false);
    const [panelaUserList, setPanelaUserList] = useState([]);

    useEffect(()=> {
        (async function () {
            const result = await getUserList();
            setPanelaUserList(result.data);
        })();
    }, []);

    function handleClose() {
        setIsOpenDialog(false);
        updateSelectedTicketing({});
    }

    function handleClear() {
        setFilterData({
            tanggalMulaiStart: null,
            tanggalMulaiEnd: null,
            tanggalSelesaiStart: null,
            tanggalSelesaiEnd: null,
            namaKlinik: '',
            picKlinik: '',
            namaProduk: '',
            status: '',
            kategori: '',
            pic: '',
            skip: 0,
            limit: 5,
        });
        setFilter(0);
        updateSelectedTicketingFilter({});
    }

    function handleFilter() {
        setFilter(filter + 1);
        setIsOpenDialogFilter(false);
        updateSelectedTicketingFilter(JSON.parse(JSON.stringify(filterData)));
    }

    const getSuggestion = debounce((value) => {
        setFilterData({...filterData, namaKlinik: value});
        setFilter(filter + 1);
    }, 500);

    const handleSearch = (e) => {
        const { value } = e.target;

        if(value.length > 2) {
            setSearch(value);
            getSuggestion(value);
        } else if(value.length === 0) {
            setSearch(value);
            setFilterData({...filterData, namaKlinik: value});
            setFilter(0);
        } else {
            setSearch(value);
            setFilterData({...filterData, namaKlinik: value});
        }
    };

    return (
        <div className={classes.content}>
            <Header title={'Ticketing'}/>
            <div className={classes.body} style={isViewedByMobile ? {height: '95%'} : {height: '85%'}}>
                <Container className={classes.container}>
                    <Dialog open={isOpenDialog} maxWidth='md' onClose={()=> handleClose()} fullWidth>
                        <DialogContent>
                            <TicketingForm onClose={()=> handleClose()}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={isOpenDialogFilter} maxWidth='xs' onClose={()=> setIsOpenDialogFilter(false)} fullWidth>
                        <DialogContent>
                            <TicketingFilter
                                filterData={filterData}
                                setFilterData={setFilterData}
                                onClear={()=> handleClear()}
                                onSubmit={()=> handleFilter()}
                                panelaUserList={panelaUserList}
                            />
                        </DialogContent>
                    </Dialog>
                    <Grid container spacing={1} item xs={12}>
                        <Grid item xs={4}>
                            <PInput
                                placeholder='Cari klinik disini'
                                value={search}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </InputAdornment>
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <PBtn
                                onClick={()=> setIsOpenDialog(true)}
                            >
                                Tambahkan
                            </PBtn>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.filter}>
                                <img alt='filter' src={filterIcon} className={classes.imgFilter}/>
                                <Typography className={classes.labelFilter}>Filter by</Typography>
                                <img
                                    alt='arrow'
                                    src={arrowDown}
                                    className={classes.arrowFilter}
                                    onClick={()=> setIsOpenDialogFilter(true)}
                                />
                            </div>
                        </Grid>
                        {
                            filter > 0 && <Grid item xs={12} className={classes.wrapChip}>
                                {
                                    (selectedTicketingFilter.tanggalMulaiStart && true) || (selectedTicketingFilter.tanggalMulaiEnd && true)
                                        ? <Chip
                                            size='small'
                                            color='secondary'
                                            label='Tanggal Mulai'
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    (selectedTicketingFilter.tanggalSelesaiStart && true) || (selectedTicketingFilter.tanggalSelesaiEnd && true)
                                        ? <Chip
                                            size='small'
                                            color='secondary'
                                            label='Tanggal Selesai'
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedTicketingFilter.namaKlinik && selectedTicketingFilter.namaKlinik !== ''
                                        ? <Chip
                                            size='small'
                                            color='secondary'
                                            label='Nama Klinik'
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedTicketingFilter.picKlinik && selectedTicketingFilter.picKlinik !== ''
                                        ? <Chip
                                            size='small'
                                            color='secondary'
                                            label='PIC Klinik'
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedTicketingFilter.namaProduk && selectedTicketingFilter.namaProduk !== ''
                                        ? <Chip
                                            size='small'
                                            color='secondary'
                                            label='Nama Produk'
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedTicketingFilter.status && selectedTicketingFilter.status !== ''
                                        ? <Chip
                                            size='small'
                                            color='secondary'
                                            label='Status'
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedTicketingFilter.kategori && selectedTicketingFilter.kategori !== ''
                                        ? <Chip
                                            size='small'
                                            color='secondary'
                                            label='Kategori'
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedTicketingFilter.pic && selectedTicketingFilter.pic !== ''
                                        ? <Chip
                                            size='small'
                                            color='secondary'
                                            label='PIC Assist.id'
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                            </Grid>
                        }
                        <Grid item xs={12} style={{ marginTop: 34 }}>
                            <TicketingTable
                                filterData={filterData}
                                setFilterData={setFilterData}
                                filter={filter}
                                setFilter={setFilter}
                                setIsOpenDialog={setIsOpenDialog}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

const mapState = ({ ticketing, app }) => ({
    selectedTicketingFilter: ticketing.selectedTicketingFilter,
    isViewedByMobile: app.isViewedByMobile,
});

const mapDispatch = ({ ticketing: { updateSelectedTicketing, updateSelectedTicketingFilter } }) => ({
    updateSelectedTicketing,
    updateSelectedTicketingFilter,
});

export default connect(mapState, mapDispatch)(Ticketing);
