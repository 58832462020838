import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import PICAssistForm from "../setting/pic-assist/PICAssistForm";
import Header from '../faskes/templateFaskes/header/header';

import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    content: {
        width: '100%',
    },
    body: {
        width: '100%',
        height: '85%',
        position: 'absolute',
        marginTop: '7%',
        bottom: 0,
        backgroundColor: '#f5f6f8',
        overflowY: 'auto',
        '@media only screen and (max-width:994px)': {
            height: '90%',
            width: '1440px',
        },
    },
    container: {
        padding: '25px 20px',
        '@media only screen and (max-width:994px)': {
            padding: '4% 20px',
        },
        '@media only screen and (max-width:400px)': {
            padding: '4% 20px',
        },
    },
});

const Account = ({ currentUser, isViewedByMobile }) => {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <Header title={"Account"} />
            <div className={classes.body} style={isViewedByMobile ? {height: '95%'} : {height: '85%'}}>
                <Container className={classes.container}>
                    <Grid item xs={12}>
                        <PICAssistForm editableData={currentUser} isAccount />
                    </Grid>
                </Container>
            </div>
        </div>

    );
};

const mapState = ({ user, app }) => ({
    currentUser: user.currentUser,
    isViewedByMobile: app.isViewedByMobile,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(Account);
