import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {dispatch} from "../../../store";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import {addProspect, updateProspect} from "../../../api/prospect";

const styles = makeStyles({
    label: {
        color: 'black',
        fontWeight: 'bold',
        marginBottom: 10,
    },
});

const FormData = ({ selectedProspect, onClose, currentUser, fetchProspect, flagSkipLimit }) => {
    const classes = styles();
    const isEdit = typeof selectedProspect.id !== "undefined";
    const { faskesInfo, staffInfo, data } = selectedProspect;
    const [faskesName, setFaskesName] = useState(faskesInfo ? faskesInfo.nama : data ? data.medname : '');
    const [PICFaskes, setPICFaskes] = useState(faskesInfo ? staffInfo[0].name : data ? data.usname : '');
    const [position, setPosition] = useState(faskesInfo ? staffInfo[0].role : data ? data.jabatan : '');
    const [phoneNumber, setPhoneNumber] = useState(faskesInfo ? staffInfo[0].Confidentials.hp : data ? data.phonenumber : '');
    const [email, setEmail] = useState(faskesInfo ? staffInfo[0].Confidentials.email : data ? data.email : '');
    const [produk, setProduk] = useState(data ? data.mproduct : '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const validateInt = RegExp(/^[0-9\b]+$/);
    const validateEmail = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    const [isEmailValid, setIsEmailValid] = useState(true);

    function handleChangeWithRegEx(e) {
        if(e.target.name === 'email'){
            if(e.target.value === '' || validateEmail.test(e.target.value)){
                setIsEmailValid(true);
                setEmail(e.target.value);
            } else {
                setIsEmailValid(false);
                setEmail(e.target.value);
            }
        } else {
            if(e.target.value === '' || validateInt.test(e.target.value)){
                setPhoneNumber(e.target.value);
            }
        }
    }

    async function handleSimpan() {
        const { snackbar } = dispatch;
        if(!isEdit){
            try {
                const payload = {
                    data: {
                        medname: faskesName,
                        usname: PICFaskes,
                        jabatan: position,
                        phonenumber: phoneNumber,
                        email: email,
                        mproduct: produk,
                        url: window.location.href,

                    },
                    isFromWeb: true,
                    isGeneratedToLead: false,
                    createdAt: new Date(),
                    createdId: currentUser.id,
                };
                setIsSubmitting(true);
                await addProspect(payload);
                snackbar.showSuccess("Berhasil menambahkan data");
                setIsSubmitting(false);
                onClose();
                fetchProspect(flagSkipLimit);
            } catch (e) {
                console.error(e);
                snackbar.showError("Gagal menambahkan data");
                setIsSubmitting(false);
            }
        } else {
            if(faskesInfo){
                try {
                    const payload = {...selectedProspect};
                    payload.updatedAt = new Date();
                    payload.updatedId = currentUser.id;
                    payload.faskesInfo.nama = faskesName;
                    payload.staffInfo[0].name = PICFaskes;
                    payload.staffInfo[0].role = position;
                    payload.staffInfo[0].Confidentials.hp = phoneNumber;
                    payload.staffInfo[0].Confidentials.email = email;
                    setIsSubmitting(true);
                    await updateProspect(selectedProspect.id, payload);
                    snackbar.showSuccess("Berhasil update data");
                    setIsSubmitting(false);
                    onClose();
                    fetchProspect(flagSkipLimit);
                } catch (e) {
                    console.error(e);
                    snackbar.showError("Gagal update data");
                    setIsSubmitting(false);
                }
            } else {
                try {
                    const payload = {...selectedProspect};
                    payload.data.medname = faskesName;
                    payload.data.usname = PICFaskes;
                    payload.data.jabatan = position;
                    payload.data.phonenumber = phoneNumber;
                    payload.data.email = email;
                    payload.data.mproduct = produk;
                    payload.updatedAt = new Date();
                    payload.updatedId = currentUser.id;
                    setIsSubmitting(true);
                    await updateProspect(selectedProspect.id, payload);
                    snackbar.showSuccess("Berhasil update data");
                    setIsSubmitting(false);
                    onClose();
                    fetchProspect(flagSkipLimit);
                } catch (e) {
                    console.error(e);
                    snackbar.showError("Gagal update data");
                    setIsSubmitting(false);
                }
            }
        }
    }

    return(
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>Nama Klinik *</Typography>
                            <PInput
                                value={faskesName}
                                onChange={(e)=> setFaskesName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>PIC Klinik *</Typography>
                            <PInput
                                value={PICFaskes}
                                onChange={(e)=> setPICFaskes(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>Jabatan *</Typography>
                            <PInput
                                value={position}
                                onChange={(e)=> setPosition(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>No. Whatsapp *</Typography>
                            <PInput
                                value={phoneNumber}
                                onChange={(e)=> handleChangeWithRegEx(e)}
                                name='phoneNumber'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>Email *</Typography>
                            <PInput
                                value={email}
                                onChange={(e)=> handleChangeWithRegEx(e)}
                                name='email'
                                error={!isEmailValid}
                                helperText={!isEmailValid ? 'Email is not valid!' : ''}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>Produk</Typography>
                            <PInput
                                value={produk}
                                onChange={(e)=> setProduk(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={onClose}
                >
                    Batal
                </PBtn>
                <PBtn
                    disabled={faskesName === '' || PICFaskes === '' || position === '' || phoneNumber === '' || email === '' || isSubmitting || !isEmailValid}
                    onClick={()=> handleSimpan()}
                >
                    {!isSubmitting ? "Simpan" : <FontAwesomeIcon icon={faSpinner} spin/>}
                </PBtn>
            </Grid>
        </Grid>
    )
};

const mapState = ({ prospect, user }) => ({
    selectedProspect: prospect.selectedProspect,
    currentUser: user.currentUser,
});

const mapDispatch = ({ prospect: { fetchProspect } }) => ({
    fetchProspect,
});

export default connect(mapState, mapDispatch)(FormData);
