import { getRejectionCategories } from '../../api/rejection-category';

export const rejectionCategory = {
    state: {
        isFetchingRejectionCategoryList: false,
        rejectionCategoryList: [],
    },
    reducers: {
        updateState(state, payload) {
            const { stateName, value } = payload;
            return {
                ...state,
                [stateName]: value,
            };
        },
    },
    effects: dispatch => ({
        async fetchRejectionCategories() {
            try {
                this.updateState({
                    stateName: 'isFetchingRejectionCategoryList',
                    value: true,
                });

                const result = await getRejectionCategories();
                this.updateState({
                    stateName: 'rejectionCategoryList',
                    value: result.data,
                });
                this.updateState({
                    stateName: 'isFetchingRejectionCategoryList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar kategori penolakan");
                this.updateState({
                    stateName: 'isFetchingRejectionCategoryList',
                    value: false,
                });
            }
        },
    }),
};
