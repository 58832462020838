import React, { useEffect, useState } from 'react';
import Header from './../templateFaskes/header/header';
// import FormAddLeads from "./FormAddLeads";
import FormAddLeadsV1 from "./FormAddLeadsV1";

import './leads.css'
import {getMedias} from "../../../api/media";
import {getUserList} from "../../../api/panela-user";
import {getEventNames} from "../../../api/event-name";
import {getDaftarProvinsi} from "../../../api/client";
import {getRejectionCategories} from "../../../api/rejection-category";

import {connect} from "react-redux";

function AddLeads({ fetchIklanList, iklanList, fetchKompetitors, kompetitorList, fetchSingleFaskesList, match, updateSingleFaskesList }) {
    const [mediaList, setMediaList] = useState([]);
    const [panelaUserList, setPanelaUserList] = useState([]);
    const [eventNameList, setEventNameList] = useState([]);
    const [daftarProvinsi, setDaftarProvinsi] = useState([]);
    const [rejectionCategoryList, setRejectionCategoryList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        (async function () {
            const result = await getMedias();
            setMediaList(result.data);
            const resultPanelaList = await getUserList();
            const flag = resultPanelaList.data;
            flag[flag.length] = {id: 'm4n4j3m3n', firstName: 'Unassigned', lastName: ''};
            setPanelaUserList(flag);
            const resultEventNameList = await getEventNames();
            setEventNameList(resultEventNameList.data);
            const resultDaftarProvinsi = await getDaftarProvinsi();
            setDaftarProvinsi(resultDaftarProvinsi.data);
            const resultRejectionCategory = await getRejectionCategories();
            setRejectionCategoryList(resultRejectionCategory.data);
        })();
    }, []);

    useEffect(()=> {
        if(match.params.id) {
            fetchSingleFaskesList(match.params.id);
            setIsEdit(true);
        } else {
            updateSingleFaskesList({});
            setIsEdit(false);
        }
        fetchIklanList();
        fetchKompetitors();
    }, [fetchIklanList, fetchKompetitors, match.params.id, fetchSingleFaskesList, updateSingleFaskesList]);

    return(
        <div className="leads-content">
            <Header title={"Leads"} />
            <FormAddLeadsV1
                mediaList={mediaList}
                panelaUserList={panelaUserList}
                eventNameList={eventNameList}
                daftarProvinsi={daftarProvinsi}
                iklanList={iklanList}
                kompetitorList={kompetitorList}
                rejectionCategoryList={rejectionCategoryList}
                isEdit={isEdit}
            />
            {/*<FormAddLeads mediaList={mediaList} panelaUserList={panelaUserList} eventNameList={eventNameList} daftarProvinsi={daftarProvinsi} />*/}
        </div>
    )
}

const mapState = ({ iklan, kompetitor }) => ({
    iklanList: iklan.iklanList,
    kompetitorList: kompetitor.kompetitorList,
});

const mapDispatch = ({ iklan: { fetchIklanList }, kompetitor: { fetchKompetitors }, leads: { fetchSingleFaskesList, updateSingleFaskesList } }) => ({
    fetchIklanList,
    fetchKompetitors,
    fetchSingleFaskesList,
    updateSingleFaskesList,
});

export default connect(mapState, mapDispatch)(AddLeads);
