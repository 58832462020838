import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import {makeStyles, withStyles} from "@material-ui/core";

import format from "date-fns/format";
import localeId from "date-fns/locale/id";

const styles = makeStyles({
    container: {
        boxShadow: '0 40px 40px -40px #777',
        backgroundColor: 'white',
        borderRadius: 8,
        marginBottom: 60,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 30,
    },
    grid: {
        marginBottom: 20,
    },
    label: {
        fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: 'Nunito',
        marginBottom: 20,
        color: '#15162d',
    },
    tableWrapper: {
        overflow: 'auto',
    },
    labelStep: {
        fontSize: '14px',
    },
});

const StyleConnector = withStyles({
    active: {
        '& $line': {
            borderColor: '#3f51b5',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#3f51b5',
        },
    },
    line: {
        borderColor: '#808080',
    },
})(StepConnector);

function getSteps() {
    return ['Leads', 'Menghubungi', 'Presentasi', 'Trial', 'Penawaran', 'Invoice', 'Closing/Rejected'];
}

const CycleLeads = ({ cycle }) => {
    const classes = styles();
    const [activeStep, setActiveStep] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const steps = getSteps();
    const lastIndex = cycle.length - 1;
    const index = page * rowsPerPage;

    useEffect(()=> {
        if(cycle.length === 0) {
            setActiveStep(0);
        } else {
            if(cycle[lastIndex].lastStatus === 'Closing' || cycle[lastIndex].lastStatus === 'Rejected') {
                setActiveStep(7);
            } else if(cycle[lastIndex].lastStatus === '') {
                setActiveStep(0);
            } else if(cycle[lastIndex].lastStatus === 'Leads') {
                setActiveStep(0);
            } else if(cycle[lastIndex].lastStatus === 'Menghubungi') {
                setActiveStep(1);
            } else if(cycle[lastIndex].lastStatus === 'Presentasi') {
                setActiveStep(2);
            } else if(cycle[lastIndex].lastStatus === 'Trial') {
                setActiveStep(3);
            } else if(cycle[lastIndex].lastStatus === 'Penawaran') {
                setActiveStep(4);
            } else if(cycle[lastIndex].lastStatus === 'Invoice') {
                setActiveStep(5);
            } else {
                setActiveStep(0);
            }
        }
    }, [cycle, lastIndex]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        const newRowsPerPage = parseInt(e.target.value, 10);
        setPage(0);
        setRowsPerPage(newRowsPerPage);
    };

    return (
        <Grid container className={classes.container}>
            <Typography className={classes.label}>Cycle Progress</Typography>
            <Grid item xs={12} md={12} className={classes.grid}>
                <Stepper
                    activeStep={activeStep}
                    connector={<StyleConnector/>}
                    alternativeLabel>
                    {
                        steps.map((label, idx) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={idx} {...stepProps}>
                                    <StepLabel {...labelProps}>
                                        <Typography className={classes.labelStep}>{label}</Typography>
                                    </StepLabel>
                                </Step>
                            );
                        })
                    }
                </Stepper>
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid}>
                <div className={classes.tableWrapper}>
                    <Table>
                        <TableBody>
                            {cycle.slice(index, index + rowsPerPage).map((item, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>
                                        {
                                            item.startDate && item.startDate !== null
                                                ? item.endDate && item.endDate !== null
                                                ? `${format(new Date(item.startDate), "dd/MM/yyyy", {locale: localeId})} - ${format(new Date(item.endDate), "ddMM/yyyy", {locale: localeId})}`
                                                : `${format(new Date(item.startDate), "dd/MM/yyyy", {locale: localeId})}`
                                                : ''
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {item.assistPIC}
                                    </TableCell>
                                    <TableCell>
                                        {item.lastStatus}
                                    </TableCell>
                                    <TableCell>
                                        {item.note}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={[3, 6, 9]}
                        component="div"
                        count={cycle.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage} />
                </div>
            </Grid>
        </Grid>
    );
};

export default CycleLeads;
