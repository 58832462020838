import agent from "./agent";

export const getUserList = () => {
    return agent.get('/PanelaUsers/getUserList');
};

export const checkPassword = (password) => {
    return agent.get('/PanelaUsers/checkUserPassword', {
        params: {
            password,
        },
    });
};

export const createNewUser = (payload) => {
    return agent.post('/PanelaUsers', payload);
};

export const getUserListByKeyword = (keyword) => {
    const params = {
        keyword,
    };
    return agent.get('/PanelaUsers/getUserByKeyword', { params });
};

export const getUserDetail = (userId) => {
    const params = {
        userId,
    };
    return agent.get('/PanelaUsers/getUserDetail', { params });
};

export const updateUser = (userId, payload) => {
    return agent.patch(`/PanelaUsers/${userId}`, payload);
};

export const deleteUser = (userId) => {
    return agent.delete(`/PanelaUsers/${userId}`);
};
