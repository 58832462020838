import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import format from "date-fns/format";
import localeId from "date-fns/locale/id";

import {makeStyles} from "@material-ui/core";

import {getBirthdayPICKlinik} from "../../api/dashboard";

const styles = makeStyles({
    tableWrapper: {
        overflowY: 'auto',
        height: 300,
        width: '100%',
    },
    paper: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        borderRadius: 8,
        minHeight: 330,
        maxHeight: 330,
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#15162d',
    },
    heading: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: 'Nunito',
    },
    subHeading: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Nunito',
        color: '#6e7d91',
    },
    tableCellBody: {
        paddingLeft: 0,
        borderBottom: 'none',
    },
});

const Birthday = () => {
    const classes = styles();
    const [birthdayPICKlinik, setBirthdayPICKlinik] = useState({});

    useEffect(()=> {
        (async function () {
            const resultGetBirthdayPICKlinik = await getBirthdayPICKlinik();
            setBirthdayPICKlinik(resultGetBirthdayPICKlinik.data);
        })();
    }, []);

    return (
        <Grid container justify="center" spacing={4} style={{ marginTop: 10 }}>
            {/* ###Belum bisa di tentukan### */}
            {/*<Grid item xs={3}>*/}
            {/*    <Paper elevation={3} className={classes.paper}>*/}
            {/*        <Typography className={classes.title}>Clinic Birthday</Typography>*/}
            {/*        <Table>*/}
            {/*            <TableBody>*/}
            {/*                <TableRow>*/}
            {/*                    <TableCell className={classes.tableCellBody}>*/}
            {/*                        <Typography className={classes.heading} style={{ color: '#15162d' }}>Today</Typography>*/}
            {/*                        <Typography className={classes.heading} style={{ color: '#2b64af' }}>Klinik Silvia Ningrum</Typography>*/}
            {/*                        <Typography className={classes.subHeading}>Indah Kusuma</Typography>*/}
            {/*                    </TableCell>*/}
            {/*                </TableRow>*/}
            {/*                <TableRow>*/}
            {/*                    <TableCell className={classes.tableCellBody}>*/}
            {/*                        <Typography className={classes.heading} style={{ color: '#15162d' }}>Terdekat</Typography>*/}
            {/*                        <Typography className={classes.heading} style={{ color: '#2b64af' }}>Klinik Silvia Ningrum</Typography>*/}
            {/*                        <Typography className={classes.subHeading}>Indah Kusuma</Typography>*/}
            {/*                    </TableCell>*/}
            {/*                </TableRow>*/}
            {/*                <TableRow>*/}
            {/*                    <TableCell className={classes.tableCellBody}>*/}
            {/*                        <Typography className={classes.heading} style={{ color: '#15162d' }}>1 Mei 2020</Typography>*/}
            {/*                        <Typography className={classes.heading} style={{ color: '#2b64af' }}>Klinik Silvia Ningrum</Typography>*/}
            {/*                        <Typography className={classes.subHeading}>Indah Kusuma</Typography>*/}
            {/*                    </TableCell>*/}
            {/*                </TableRow>*/}
            {/*            </TableBody>*/}
            {/*        </Table>*/}
            {/*    </Paper>*/}
            {/*</Grid>*/}
            <Grid item xs={3}>
                <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.title}>PIC Clinic Birthday</Typography>
                    <div className={classes.tableWrapper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tableCellBody}>
                                        <Typography className={classes.heading} style={{ color: '#15162d' }}>Today</Typography>
                                        {
                                            birthdayPICKlinik.today
                                                ? birthdayPICKlinik.today.length > 0
                                                ? birthdayPICKlinik.today.map((item, idx) => (
                                                    <div key={idx}>
                                                        <Typography className={classes.heading} style={{ color: '#2b64af' }}>{item.picData.namepic}</Typography>
                                                        <Typography className={classes.subHeading}>{item.faskesName}</Typography>
                                                    </div>
                                                ))
                                                : <Typography className={classes.heading} style={{ color: '#2b64af' }}>Tidak Ada</Typography>
                                                : <Typography className={classes.heading} style={{ color: '#2b64af' }}>Tidak Ada</Typography>
                                        }

                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellBody}>
                                        <Typography className={classes.heading} style={{ color: '#15162d' }}>Terdekat</Typography>
                                        {
                                            birthdayPICKlinik.closest
                                                ? birthdayPICKlinik.closest.length > 0
                                                ? birthdayPICKlinik.closest.map((item, idx) => (
                                                    <div key={idx}>
                                                        <Typography className={classes.heading} style={{ color: '#2b64af' }}>{item.picData.namepic}</Typography>
                                                        <Typography className={classes.subHeading}>{format(new Date(item.picData.birthday), "dd MMMM yyyy", {locale: localeId})}</Typography>
                                                        <Typography className={classes.subHeading}>{item.faskesName}</Typography>
                                                    </div>
                                                ))
                                                : <Typography className={classes.heading} style={{ color: '#2b64af' }}>Tidak Ada</Typography>
                                                : <Typography className={classes.heading} style={{ color: '#2b64af' }}>Tidak Ada</Typography>
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCellBody}>
                                        {
                                            birthdayPICKlinik.next
                                                ? birthdayPICKlinik.next.length > 0
                                                ? birthdayPICKlinik.next.map((data, idx) => (
                                                    <React.Fragment key={idx}>
                                                        <Typography className={classes.heading} style={{ color: '#15162d' }}>{format(new Date(data.date), "dd MMMM yyyy", {locale: localeId})}</Typography>
                                                        {
                                                            data.picData.map((item, id) => (
                                                                <div key={id} style={{ marginBottom: 8 }}>
                                                                    <Typography className={classes.heading} style={{ color: '#2b64af' }}>{item.picData.namepic}</Typography>
                                                                    <Typography className={classes.subHeading}>{item.faskesName}</Typography>
                                                                </div>
                                                            ))
                                                        }
                                                    </React.Fragment>
                                                ))
                                                : null
                                                : null
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Birthday;
