import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import PBtn from "../../share/PBtn";

import InvoicePrintContent from "./InvoicePrintContent";

const styles = makeStyles({
    '@global': {
        '@media print': {
            '.hideForPrint': {
                display: 'none',
            },
        },
    },
    popover: {
        pointerEvents: 'none',
    },
});

const InvoicePrintDialog = ({ selectedInvoicesList, onClose }) => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const total = selectedInvoicesList._id ? selectedInvoicesList.KConfig.activeBilling.Billing.totalFee : 0;
    const terminList = selectedInvoicesList._id ? selectedInvoicesList.KConfig.activeBilling.Billing.payment : [];
    const [lunas, setLunas] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [terminListNotPayed, setTerminListNotPayed] = useState([]);

    useEffect(()=> {
        (function () {
            setTerminListNotPayed(terminList.filter(item => item.isPayed === false).map(data => data));
            let flagValueTermin = [];
            let sum = 0;
            flagValueTermin = terminList.filter(item => item.isPayed === true).map(data => parseInt(data.value, 10));
            for(let i = 0; i < flagValueTermin.length; i++) {
                sum += flagValueTermin[i];
            }
            setLunas(sum);
            setGrandTotal(total - sum);
        })();
    }, [terminList, total]);

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = (event) => {
        setAnchorEl(null);
    };

    return (
        <Grid container>
            <div className={'hideForPrint'} style={{ position: 'absolute', top: 10, right: 50 }}>
                <PBtn onClick={()=> {
                    document.title = `Invoice ${selectedInvoicesList.nama} - ${selectedInvoicesList.KConfig.activeBilling.Billing.invoiceNo}`;
                    window.print();
                    document.title = 'Admin | Panela';
                    return false;
                }}
                >
                    Download PDF
                    <FontAwesomeIcon
                        icon={faQuestionCircle}
                        style={{ marginLeft: 8 }}
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handleOpenPopover}
                        onMouseLeave={handleClosePopover}
                    />
                    <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        onClose={handleClosePopover}
                        disableRestoreFocus
                    >
                        <div style={{padding: '15px 15px 15px 15px'}}>
                            <Typography>Instruksi Download PDF Penawaran:</Typography>
                            <Typography>1). Klik Download PDF</Typography>
                            <Typography>2). Destinasi: Pilih Save As PDF</Typography>
                            <Typography>3). Save</Typography>
                        </div>
                    </Popover>
                </PBtn>
                <IconButton onClick={onClose}>
                    <FontAwesomeIcon icon={faTimesCircle}/>
                </IconButton>
            </div>
            <Grid container justify="center" item xs={12}>
                <div style={{ paddingTop: 40, width: '21cm' }}>
                    <InvoicePrintContent lunas={lunas} grandTotal={grandTotal} terminListNotPayed={terminListNotPayed}/>
                </div>
            </Grid>
        </Grid>
    );
};

const mapState = ({ client }) => ({
    selectedInvoicesList: client.selectedInvoicesList,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(InvoicePrintDialog);
