import React from 'react';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import { connect } from 'react-redux';
import KategoriPenolakanListItem from "../SettingListItem";
import {dispatch} from "../../../store";
import {updateRejectionCategory} from "../../../api/rejection-category";
import {deleteDictionary} from "../../../api/dictionary";

const ListEventName = ({ isFetchingEventNameList, eventNameList, fetchEventNames }) => {
    const handleSave = async (editData, data, currentUser, setIsUpdating) => {
        const { snackbar } = dispatch;

        try {
            setIsUpdating(true);
            await updateRejectionCategory(editData, data.id, currentUser.id);
            setIsUpdating(false);
            snackbar.showSuccess('Berhasil mengupdate nama event');

            await fetchEventNames();
        } catch (err) {
            console.error(err);
            setIsUpdating(false);
            snackbar.showError('Gagal mengupdate nama event');
        }
    };

    const handleDelete = async (id, setIsDeleting) => {
        const { snackbar } = dispatch;

        try {
            setIsDeleting(true);
            await deleteDictionary(id);
            setIsDeleting(false);
            snackbar.showSuccess('Berhasil menghapus nama event');

            await fetchEventNames();
        } catch (err) {
            console.error(err);
            setIsDeleting(false);
            snackbar.showError('Gagal menghapus nama event');
        }
    };

    return (
        <Grid item xs={12}>
            {
                isFetchingEventNameList
                    ? <Grid container justify="center">
                        <CircularProgress />
                    </Grid>
                    : <List style={{marginTop: '40px', marginLeft: '-13px'}}>
                        {
                            eventNameList.map(eventName => (
                                <KategoriPenolakanListItem
                                    key={eventName.id}
                                    data={eventName}
                                    saveHandler={handleSave}
                                    deleteHandler={handleDelete}
                                />
                            ))
                        }
                    </List>
            }
        </Grid>
    )
};

const mapState = ({ eventName }) => ({
    eventNameList: eventName.eventNameList,
    isFetchingEventNameList: eventName.isFetchingEventNameList,
});

const mapDispatch = ({ eventName: { fetchEventNames } }) => ({
    fetchEventNames,
});

export default connect(mapState, mapDispatch)(ListEventName);
