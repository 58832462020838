import agent from "./agent";

export const addNewOffer = (payload) => {
    return agent.post('/Offers', payload);
};

export const getLetterNumber = () => {
    return agent.get('OfferLetterNumbers');
};

export const countActiveOfferByFaskesId = (faskesId) => {
    const where = {
        healthFacilityId: faskesId,
        or: [
            {
                status: {
                    neq: 'closing',
                },
            },
            {
                status: {
                    neq: 'rejected',
                },
            },
        ],
    };

    return agent.get('Offers/count', {
        params: {
            where,
        },
    })
};

export const getOfferDetailById = (offerId, withOfferDetail = false) => {
    const filter = {
        include: [
            {
                relation: 'HealthFacility',
                scope: {
                    include: ['healthFacilityPICs'],
                },
            },
            'PICAssist',
            'HealthFacilityPIC',
        ],
    };

    if (withOfferDetail) {
        filter.include.push('OfferData');
    }

    return agent.get(`/Offers/${offerId}`, {
        params: {
            filter,
        },
    });
};

export const getOfferList = () => {
    const filter = {
        include: [
            {
                relation: 'HealthFacility',
                scope: {
                    include: ['Trials'],
                },
            },
            'PICAssist',
            'HealthFacilityPIC',
            'OfferData',
        ],
    };

    return agent.get('/Offers', {
        params: {
            filter,
        }
    })
};

export const updateOffer = (offerId, payload) => {
    return agent.patch(`/Offers/${offerId}`, payload);
};

export const updatePaymentStatus = (payload) => {
    return agent.patch('/Offers/updatePaymentStatus', payload);
};

export const deleteOffer = (offerId) => {
    return agent.delete(`/Offers/${offerId}`);
};

export const countOfferByFaskesId = (faskesId) => {
    const where = {
        healthFacilityId: faskesId,
    };

    return agent.get('Offers/count', {
        params: {
            where,
        },
    });
};

export const getGeneralSettings = () => {
    return agent.get('GeneralSettings');
};

export const updateGeneralSettings = (id, payload) => {
    return agent.patch(`GeneralSettings/${id}`, payload);
};

export const confirmOfferToAssist = (offerId) => {
    const data = {
        offerId: offerId,
    };

    return agent.post('Offers/confirmOfferToAssist', data);
};

export const deleteOfferToAssist = (offerId) => {
    return agent.delete(`Offers/${offerId}/deleteOfferToAssist`);
};
