import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import {DatePicker} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PBtn from "../../share/PBtn";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
});

const InvoiceFilter = ({ filterData, setFilterData, startFilter, clearFilter }) => {
    const classes = styles();

    const handleChangeStartDate = (date) => {
        filterData.jatuhTempoStart = date;
        setFilterData(prevState => ({
            ...prevState, jatuhTempoStart: filterData.jatuhTempoStart,
        }));
    };

    const handleChangeEndDate = (date) => {
        filterData.jatuhTempoEnd = date;
        setFilterData(prevState => ({
            ...prevState, jatuhTempoEnd: filterData.jatuhTempoEnd,
        }));
    };

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Dari Tanggal</Typography>
                        <DatePicker
                            value={filterData.jatuhTempoStart}
                            onChange={handleChangeStartDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Sampai Tanggal</Typography>
                        <DatePicker
                            value={filterData.jatuhTempoEnd}
                            onChange={handleChangeEndDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={clearFilter}
                >
                    Reset
                </PBtn>
                <PBtn
                    onClick={startFilter}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default InvoiceFilter;
