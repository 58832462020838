import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import format from "date-fns/format";

import OnlineAppointmentChanger from "./OnlineAppointmentChanger";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
});

const KlinikItem = ({ page, rowsPerPage, isFetchingKlinikList, klinikList }) => {
    const classes = styles();
    const index = page * rowsPerPage;

    return (
        <React.Fragment>
            {
                isFetchingKlinikList === true
                    ? <TableRow>
                        <TableCell colSpan={12}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        </TableCell>
                    </TableRow>
                    : klinikList.slice(index, index + rowsPerPage).map((klinik, idx) => (
                        <TableRow
                            key={idx}
                            className={classes.row}
                        >
                            <TableCell className={classes.tableCellBody}>
                                <span>{format(new Date(klinik.KConfig.createdAt), "dd/MM/yyyy")}</span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                <span>{klinik.nama}</span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                <span>{klinik.KConfig.activeBilling ? klinik.KConfig.activeBilling.mainProductName : '-'}</span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                <span>{klinik.KConfig.activeBilling ? klinik.KConfig.activeBilling.endSubs
                                    ? format(new Date(klinik.KConfig.activeBilling.endSubs), "dd/MM/yyyy")
                                    : '-' : '-'}</span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                <span>
                                    {
                                        klinik.KConfig.activeBilling
                                            ? klinik.KConfig.activeBilling.isActive === true
                                            && klinik.KConfig.activeBilling.mainProductName
                                            && (typeof klinik.KConfig.isTrial === 'undefined' || klinik.KConfig.isTrial === false)
                                            ? 'Iya'
                                            : 'Tidak'
                                            : 'Tidak'
                                    }
                                </span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                <span>
                                    {
                                        klinik.KConfig.activeBilling
                                            ? klinik.KConfig.activeBilling.isActive === true
                                            ? 'Iya' : 'Tidak' : 'Tidak'
                                    }
                                </span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                <span>{klinik.KConfig.isTrial === true ? 'Iya' : 'Tidak'}</span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                <span>
                                    {
                                        klinik.KConfig.activeBilling
                                            ? klinik.KConfig.activeBilling.activeInvoiceId
                                            ? 'Iya'
                                            : 'Tidak' : 'Tidak'
                                    }
                                </span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                <span>
                                    {klinik.DataAccounts ? klinik.DataAccounts[0].Accounts.Confidentials.nama : '-'}
                                </span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                <span>
                                    {klinik.DataAccounts ? klinik.DataAccounts[0].Accounts.Confidentials.hp : '-'}
                                </span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                <span>{klinik.KConfig.activeBilling ? `${klinik.KConfig.activeBilling.maxUser} Akun` : '-'}</span>
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                <span>
                                    {
                                        klinik.level_doctor ? 'Iya' : 'Tidak'
                                    }
                                </span>
                                <OnlineAppointmentChanger klinik={klinik} />
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
};

const mapState = ({ client }) => ({
    isFetchingKlinikList: client.isFetchingKlinikList,
    klinikList: client.klinikList,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(KlinikItem);
