import React from "react";
import Radio from "@material-ui/core/Radio";

import {withStyles} from "@material-ui/core";

export default function ({ color, ...props }) {
    const Custom = withStyles({
        root: {
            color: color,
            '&$checked': {
                color: color,
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    return (
        <Custom {...props}/>
    );
}
