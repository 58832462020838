import agent from "./agent";

export const getIklans = () => {
    return agent.get('Iklans/getIklan');
};

export const addNewIklan = (data) => {
    return agent.post('Iklans', data);
};

export const updateIklan = (iklanId, data) => {
    return agent.patch(`Iklans/${iklanId}`, data);
};

export const deleteIklan = (iklanId) => {
    return agent.delete(`Iklans/${iklanId}`);
};
