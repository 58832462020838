import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faCheckCircle, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";

import {connect} from "react-redux";
import history from "../../../history";
import format from "date-fns/format";
import {dispatch} from "../../../store";

import PBtn from "../../share/PBtn";
import {updateProspect} from "../../../api/prospect";

const styles = makeStyles({
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
    labelHide: {
        fontFamily: "Nunito",
        fontSize: "18px",
        color: "#15162d",
        fontWeight: "600",
    },
});

const ItemData = ({ prospectList, isFetchingProspectList, page, rowsPerPage, setIsOpenDataForm, updateSelectedProspect, fetchProspect, currentUser }) => {
    const classes = styles();
    const index = page * rowsPerPage;
    const [showHideConfirmation, setShowHideConfirmation] = useState(false)
    const [selectedProspect, setSelectedProspect] = useState({});

    function handleClick(prospect) {
        setIsOpenDataForm(true);
        updateSelectedProspect(JSON.parse(JSON.stringify(prospect)));
    }

    function handleSetLeads(prospect) {
        updateSelectedProspect(JSON.parse(JSON.stringify(prospect)));
        history.push('/leads/add');
    }

    function handleClose() {
        setShowHideConfirmation(false);
        setSelectedProspect({});
    }

    async function handleHide() {
        try {
            const payload = {
                isHide: true,
                updatedAt: new Date(),
                updatedId: currentUser.id,
            };
            await updateProspect(selectedProspect.id, payload);
            dispatch.snackbar.showSuccess("Berhasil Hide data");
            setShowHideConfirmation(false);
            fetchProspect();
        } catch (e) {
            console.error(e);
            dispatch.snackbar.showError("Gagal hide data");
            setShowHideConfirmation(false);
            fetchProspect();
        }
    }

    return(
        <React.Fragment>
            {console.log(prospectList)}
            <Dialog open={showHideConfirmation} onClose={()=> handleClose()}>
                <DialogContent>
                    <Typography className={classes.labelHide}>
                        Apakah anda yakin ingin hide data ini?
                    </Typography>
                </DialogContent>
                <DialogActions style={{ marginTop: 15, marginRight: 18 }}>
                    <PBtn
                        inverted
                        dropShadow
                        onClick={()=> handleClose()}
                        style={{ marginRight: 10 }}
                    >
                        Batal
                    </PBtn>
                    <PBtn
                        onClick={()=> handleHide()}
                    >
                        Hide
                    </PBtn>
                </DialogActions>
            </Dialog>
            {isFetchingProspectList === true
                ? <TableRow>
                    <TableCell colSpan={9}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    </TableCell>
                </TableRow>
                : prospectList.slice(index, index + rowsPerPage).map(data => (
                    <TableRow key={data.id} className={classes.row} onClick={()=> handleClick(data)}>
                        <TableCell className={classes.tableCellBody}>
                            {format(new Date(data.createdAt), "dd/MM/yyyy HH:mm:ss")}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            {data.faskesInfo ? data.faskesInfo.nama : data.data.medname}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            {data.data ? data.data.ip : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            {data.faskesInfo ? data.staffInfo[0].name : data.data.usname}
                        </TableCell>
                        {/*<TableCell className={classes.tableCellBody}>*/}
                        {/*    {data.faskesInfo ? data.staffInfo[0].role : data.data.jabatan}*/}
                        {/*</TableCell>*/}
                        {/*<TableCell className={classes.tableCellBody}>*/}
                        {/*    {data.faskesInfo ? data.staffInfo[0].Confidentials.hp : data.data.phonenumber}*/}
                        {/*</TableCell>*/}
                        {/*<TableCell className={classes.tableCellBody}>*/}
                        {/*    {data.faskesInfo ? data.staffInfo[0].Confidentials.email : data.data.email}*/}
                        {/*</TableCell>*/}
                        <TableCell className={classes.tableCellBody}>
                            {data.data ? data.data.mproduct : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            {data.data ? data.data.city : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            {data.data ? data.data.url : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            {data.data ? data.data.referral : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            {data.faskesInfo ? data.faskesInfo.isFromWeb ? 'Website' :  'Mobile' : data.isFromWeb ? 'Website' : 'Mobile'}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                            {
                                data.isGeneratedToLead
                                    ? <Tooltip title="Sudah jadi leads">
                                        <IconButton onClick={(e)=> e.stopPropagation()}>
                                            <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green', width: 23, height: 23 }}/>
                                        </IconButton>
                                    </Tooltip>
                                    : <Tooltip title="Tambah menjadi leads">
                                        <IconButton onClick={(e)=> {
                                            e.stopPropagation();
                                            handleSetLeads(data);
                                        }}>
                                            <FontAwesomeIcon icon={faPlusCircle} style={{ color: '#2b64af', width: 23, height: 23 }}/>
                                        </IconButton>
                                    </Tooltip>
                            }
                            <Tooltip title="Hide">
                                <IconButton onClick={(e)=> {
                                    e.stopPropagation();
                                    setSelectedProspect(data);
                                    setShowHideConfirmation(true);
                                }}>
                                    <FontAwesomeIcon icon={faEyeSlash} style={{ color: 'orange', width: 23, height: 23 }}/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))
            }
        </React.Fragment>
    )
};

const mapState = ({ prospect, user }) => ({
    prospectList: prospect.prospectList,
    isFetchingProspectList: prospect.isFetchingProspectList,
    currentUser: user.currentUser,
});

const mapDispatch = ({ prospect: { updateSelectedProspect, fetchProspect } }) => ({
    updateSelectedProspect,
    fetchProspect,
});

export default connect(mapState, mapDispatch)(ItemData);
