import { getUserList, createNewUser } from "../../api/panela-user";
import {getState} from "../../store";

export const panelaUser = {
    state: {
        userList: [],
        selectedUser: undefined,
    },
    reducers: {
        updateState(state, payload) {
            return {
                ...state,
                [payload.key]: payload.value,
            };
        },
        updateSelectedUser(state, payload) {
            return {
                ...state,
                selectedUser: payload,
            }
        },
        resetSelectedUser(state) {
            return {
                ...state,
                selectedUser: undefined,
            };
        }
    },
    effects: dispatch => ({
        async getPanelaUserList() {
            try {
                const { user } = getState();
                const { currentUser } = user;
                const result = await getUserList();
                let formattedData = result.data;
                if (currentUser) {
                    formattedData = formattedData.filter($item => $item.id !== currentUser.id);
                }
                this.updateState({
                    key: 'userList',
                    value: formattedData,
                });
                return true
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async postNewPanelaUser(payload) {
            try {
                const { panelaUser, snackbar } = dispatch;
                await createNewUser(payload);
                await panelaUser.getPanelaUserList();
                snackbar.showSuccess('Berhasil membuat user baru');
                return true;
            } catch (err) {
                console.log(err);
                dispatch.snackbar.showError('Gagal membuat user baru');
                throw err;
            }
        },
    }),
};
