import agent from "./agent";
import format from "date-fns/format";

export const getTicketingList = (payload) => {
    let params;
    if(payload) {
        params = {
            tanggalMulaiStart: payload.tanggalMulaiStart === null ? '' : format(new Date(payload.tanggalMulaiStart), 'yyyy-MM-dd'),
            tanggalMulaiEnd: payload.tanggalMulaiEnd === null ? '' : format(new Date(payload.tanggalMulaiEnd), 'yyyy-MM-dd'),
            tanggalSelesaiStart: payload.tanggalSelesaiStart === null ? '' : format(new Date(payload.tanggalSelesaiStart), 'yyyy-MM-dd'),
            tanggalSelesaiEnd: payload.tanggalSelesaiEnd === null ? '' : format(new Date(payload.tanggalSelesaiEnd), 'yyyy-MM-dd'),
            namaKlinik: payload.namaKlinik,
            picKlinik: payload.picKlinik,
            namaProduk: payload.namaProduk,
            status: payload.status,
            kategori: payload.kategori,
            pic: payload.pic,
            skip: payload.skip,
            limit: payload.limit,
        };
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }

    return agent.get('PTickets/getTicketList', {
        params,
    });
};

export const countTicketingList = (payload) => {
    let params;
    if(payload) {
        params = {
            tanggalMulaiStart: payload.tanggalMulaiStart === null ? '' : format(new Date(payload.tanggalMulaiStart), 'yyyy-MM-dd'),
            tanggalMulaiEnd: payload.tanggalMulaiEnd === null ? '' : format(new Date(payload.tanggalMulaiEnd), 'yyyy-MM-dd'),
            tanggalSelesaiStart: payload.tanggalSelesaiStart === null ? '' : format(new Date(payload.tanggalSelesaiStart), 'yyyy-MM-dd'),
            tanggalSelesaiEnd: payload.tanggalSelesaiEnd === null ? '' : format(new Date(payload.tanggalSelesaiEnd), 'yyyy-MM-dd'),
            namaKlinik: payload.namaKlinik,
            picKlinik: payload.picKlinik,
            namaProduk: payload.namaProduk,
            status: payload.status,
            kategori: payload.kategori,
            pic: payload.pic,
        };
    }

    return agent.get('PTickets/countTicketList', {
        params,
    });
};

export const addTicketing = (data) => {
    return agent.post('PTickets', data);
};

export const updateTicketing = (ticketingId, data) => {
    return agent.patch(`PTickets/${ticketingId}`, data);
};

export const deleteTicketing = (ticketingId) => {
    return agent.delete(`PTickets/${ticketingId}`);
};
