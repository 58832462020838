import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from '@material-ui/core/styles';

import format from 'date-fns/format';

const useStyles = makeStyles({
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
});

const ActivitesItem = (
    {
        activityList, setIsOpenAddNew, updateSelectedActivity, isFetchingActivityList, page,
        rowsPerPage, setOpenDeleteConfirmation,
    }) => {
    const classes = useStyles();
    const [list, setList] = useState([]);

    useEffect(()=> {
        setList(activityList)
    }, [activityList]);

    function handleEdit(activity) {
        setIsOpenAddNew(true);
        updateSelectedActivity({...activity});
    }

    function handleRenderNote(activity) {
        const flagNote = activity.note.split('\n');

        return (
            <React.Fragment>
                {
                    flagNote.map((item, idx) => (
                        <p key={idx}>{item}</p>
                    ))
                }
            </React.Fragment>
        );
    }

    function renderAliby(activity) {
        const { type } = activity;
        if (type && type === 'rejection') {
            const { rejectionData } = activity;
            return (
                <span style={{ fontStyle: 'italic' }}>Alasan: {rejectionData ? rejectionData.rejectionDetail : ''}</span>
            );
        }
        return null;
    }

    function showWarningDelete(selectedActivity) {
        setOpenDeleteConfirmation(true);
        updateSelectedActivity(selectedActivity);
    }

    const index = page * rowsPerPage;

    return (
        <React.Fragment>
            {
                isFetchingActivityList && typeof list[index] === 'undefined'
                    ? <TableRow>
                        <TableCell colSpan={6}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        </TableCell>
                    </TableRow>
                    : <React.Fragment>
                        {
                            list
                                .slice(index, index + rowsPerPage)
                                .map(activity => (
                                    <TableRow
                                        className={classes.row}
                                        key={activity.id}
                                        onClick={() => handleEdit(activity)}
                                    >
                                        <TableCell className={classes.tableCellBody}>
                                            <span>{format(new Date(activity.activityDate), 'dd/MM/yyyy')}</span>
                                        </TableCell>

                                        <TableCell className={classes.tableCellBody}>
                                            <span>{activity.HealthFacility.name}</span>
                                        </TableCell>

                                        <TableCell className={classes.tableCellBody}>
                                            <span>{activity.healthFacilityPic ? activity.healthFacilityPic.namepic : '-'}</span>
                                        </TableCell>

                                        <TableCell className={classes.tableCellBody}>
                                            <span>{activity.picAssistFullName}</span>
                                        </TableCell>

                                        <TableCell className={classes.tableCellBody}>
                                            <span>{activity.media || '-'}</span>
                                        </TableCell>

                                        <TableCell style={{ position: 'relative', paddingRight: 42 }} className={classes.tableCellBody}>
                                            {/*<span style={{ display: 'block' }}>{activity.note}</span>*/}
                                            {handleRenderNote(activity)}
                                            {renderAliby(activity)}
                                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                                <IconButton onClick={(e) => {
                                                    e.stopPropagation();
                                                    showWarningDelete(activity)
                                                }}>
                                                    <FontAwesomeIcon style={{ color: 'red' }} icon={faTimesCircle}/>
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                        }
                    </React.Fragment>
            }
        </React.Fragment>
    );
};

const mapState = ({ activity }) => ({
    activityList: activity.activityList,
    isFetchingActivityList: activity.isFetchingActivityList,
});

const mapDispatch = ({ activity: { updateSelectedActivity, setOpenDeleteConfirmation } }) => ({
    updateSelectedActivity,
    setOpenDeleteConfirmation,
});

export default connect(mapState, mapDispatch)(ActivitesItem);
