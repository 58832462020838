import {getProducts} from "../../api/dictionary";

export const product = {
    state: {
        isFetchingProductList: false,
        productList: [],
    },
    reducers: {
        updateState(state, payload) {
            const { stateName, value } = payload;
            return {
                ...state,
                [stateName]: value,
            };
        },
    },
    effects: dispatch => ({
        async fetchProducts() {
            try {
                this.updateState({
                    stateName: 'isFetchingProductList',
                    value: true,
                });

                const result = await getProducts();
                this.updateState({
                    stateName: 'productList',
                    value: result.data,
                });
                this.updateState({
                    stateName: 'isFetchingProductList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar produk");
                this.updateState({
                    stateName: 'isFetchingProductList',
                    value: false,
                });
            }
        },
    }),
};
