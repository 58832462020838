import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faSpinner, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {dispatch} from "../../store";

import format from "date-fns/format";
import localeId from "date-fns/locale/id";

import PInput from "../share/PInput";
import PDatePicker from "../share/PDatePicker";
import PBtn from "../share/PBtn";
import CustomRadioButton from "../share/CustomRadioButton";
import DeleteConfirmation from "../share/DeleteConfirmation";

import Autocomplete from "../utils/Autocomplete";
import {faskesFetcherByKeyword} from "../../helpers/faskesFetcherByName";
import {getPICKlinikByHospitalId, getProducts, searchFaskesByKeyword} from "../../api/client";
import {addTicketing, updateTicketing, deleteTicketing} from "../../api/ticketing";
import {getUserList} from "../../api/panela-user";

import {ticketCategory, assistModule} from "../../constants";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
    wrapForm: {
        marginBottom: 20,
        marginTop: 30,
    },
    wrapAction: {
        marginBottom: 10,
    },
});

const TicketingForm = ({ onClose, selectedTicketing, currentUser, fetchTicketingList }) => {
    const classes = styles();
    const isEdit = typeof selectedTicketing._id !== 'undefined';
    const inputtedDate = isEdit ? new Date(selectedTicketing.createdAt) : new Date();
    const [tanggal, setTanggal] = useState(selectedTicketing.tanggal ? selectedTicketing.tanggal : new Date());
    const [tanggalSelesai, setTanggalSelesai] = useState(selectedTicketing.tanggalSelesai ? selectedTicketing.tanggalSelesai : null);
    const [selectedKlinik, setSelectedKlinik] = useState(null);
    const [picKlinik, setPicKlinik] = useState([]);
    const [selectedPicKlinik, setSelectedPicKlinik] = useState('');
    const [productList, setProductList] = useState([]);
    const [selectedProduk, setSelectedProduk] = useState('');
    const [kategori, setKategori] = useState(selectedTicketing.kategori ? selectedTicketing.kategori : '');
    const [modulAssistId, setModulAssistId] = useState(selectedTicketing.module ? selectedTicketing.module : '');
    const [media, setMedia] = useState(selectedTicketing.media ? selectedTicketing.media : '');
    const [keterangan, setKeterangan] = useState(selectedTicketing.keterangan ? selectedTicketing.keterangan : '');
    const [status, setStatus] = useState(selectedTicketing.status ? selectedTicketing.status : '');
    const [stepsTaken, setStepsTaken] = useState(selectedTicketing.stepsTaken ? selectedTicketing.stepsTaken : '');
    const [solutions, setSolutions] = useState(selectedTicketing.solutions ? selectedTicketing.solutions : '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [keyKlinikAutoComplete, setKeyKlinikAutoComplete] = useState(0);
    const [isOpenDialogConfirmation, setIsOpenDialogConfirmation] = useState(false);
    const isManajemen = currentUser.UserRole && currentUser.UserRole.role === 'manajemen';
    const [panelaUserList, setPanelaUserList] = useState([]);
    const [selectedPICAssist, setSelectedPICAssist] = useState('');
    const [PICAssistId, setPICAssistId] = useState('');

    useEffect(()=> {
        if(productList.length === 0) {
            (async ()=> {
                const result = await getProducts();
                setProductList(result.data);
            })();
        }
        if(panelaUserList.length === 0) {
            (async ()=> {
                const result = await getUserList();
                setPanelaUserList(result.data);
            })();
        }
    }, [productList, panelaUserList]);

    useEffect(()=> {
        if(isEdit && productList.length !== 0 && selectedProduk === '') {
            setSelectedProduk(
                productList.findIndex(item => {
                    return item.name === selectedTicketing.namaProduk;
                })
            );
        }
    }, [isEdit, selectedTicketing, productList, selectedProduk]);

    useEffect(() => {
        if(isEdit) {
            (async function () {
                const resultSelectedKlinik = await searchFaskesByKeyword(selectedTicketing.namaKlinik);
                const result = await getPICKlinikByHospitalId(selectedTicketing.hospitalId);
                const cek = resultSelectedKlinik.data.filter(item => item.KConfig.hospitalId === selectedTicketing.hospitalId);
                setSelectedKlinik(cek[0]);
                setPicKlinik(result.data);
                setSelectedPicKlinik(
                    result.data.findIndex(item => {
                        return item.Confidentials.nama === selectedTicketing.picKlinik;
                    })
                );
            })();
            if(typeof selectedTicketing.picId === 'undefined') {
                setPICAssistId(selectedTicketing.Creator._id);
                setSelectedPICAssist(`${selectedTicketing.Creator.firstName} ${selectedTicketing.Creator.lastName}`);
            } else if(selectedTicketing.picId && !isManajemen) {
                setPICAssistId(selectedTicketing.picId);
                setSelectedPICAssist(selectedTicketing.picAssistName);
            } else {
                const selectedPanelaUser = panelaUserList.find(item => {
                    return item.id === selectedTicketing.picId;
                });

                if(selectedPanelaUser) {
                    setPICAssistId(selectedPanelaUser.id);
                    setSelectedPICAssist(`${selectedPanelaUser.firstName} ${selectedPanelaUser.lastName}`);
                }
            }
        } else {
            if(!isManajemen) {
                setPICAssistId(currentUser.id);
                setSelectedPICAssist(`${currentUser.firstName} ${currentUser.lastName}`);
            }
        }
    }, [isEdit, selectedTicketing, currentUser, isManajemen, panelaUserList]);

    async function onSelectKlinik(item) {
        const { snackbar } = dispatch;
        try {
            const result = await getPICKlinikByHospitalId(item.KConfig.hospitalId);

            setSelectedKlinik(item);
            setPicKlinik(result.data);
        } catch (e) {
            console.error(e);
            snackbar.showError('Gagal memilih klinik');
            setKeyKlinikAutoComplete(keyKlinikAutoComplete + 1);
            setSelectedKlinik(null);
            setPicKlinik([]);
        }
    }

    function handleDeleteSelectedKlinik() {
        setSelectedKlinik(null);
        setPicKlinik([]);
        setSelectedPicKlinik('');
    }

    function handleSelectPICKlinik(e) {
        setSelectedPicKlinik(e.target.value);
    }

    function handleChangePICAssist(e) {
        const selectedPanelaUser = panelaUserList.find(item => {
            return item.id === e.target.value;
        });

        if(selectedPanelaUser) {
            setSelectedPICAssist(`${selectedPanelaUser.firstName} ${selectedPanelaUser.lastName}`);
            setPICAssistId(e.target.value);
        }
    }

    function handleSelectProduk(e) {
        const { snackbar } = dispatch;

        if(productList[e.target.value].extra.isOld) {
            snackbar.showError('Gagal memilih, produk yang di pilih merupakan produk lama');
            setSelectedProduk('');
        } else {
            setSelectedProduk(e.target.value);
        }
    }

    const handleChangeDate = (date) => {
        setTanggal(date);
    };

    const handleChangeEndDate = (date) => {
        setTanggalSelesai(date);
    };

    const handleChangeStatus = (e) => {
        const { value } = e.target;

        if(value === 'Waiting') {
            setStepsTaken('');
            setSolutions('');
            setStatus(value);
        } else if(value === 'On Progress') {
            setSolutions('');
            setStatus(value);
        } else {
            setStatus(value);
        }
    };

    async function handleSimpan() {
        const { snackbar } = dispatch;

        if(selectedKlinik === null) {
            snackbar.showError('Silahkan pilih klinik terlebih dahulu');
        } else if(selectedPicKlinik === '') {
            snackbar.showError('Silahkan pilih pic klinik terlebih dahulu');
        } else if(PICAssistId === '') {
            snackbar.showError('Silahkan pilih pic assist terlebih dahulu');
        } else if(status.trim() === '') {
            snackbar.showError('Silahkan pilih status terlebih dahulu');
        } else {
            const selectedProductName = productList[selectedProduk].name;
            const selectedPICKlinikName = picKlinik[selectedPicKlinik].Confidentials.nama;
            if(!isEdit) {
                try {
                    const payload = {
                        tanggal: tanggal,
                        tanggalSelesai: tanggalSelesai,
                        hospitalId: selectedKlinik.KConfig.hospitalId,
                        namaKlinik: selectedKlinik.nama,
                        picKlinik: selectedPICKlinikName,
                        picId: PICAssistId,
                        picAssistName: selectedPICAssist,
                        namaProduk: selectedProductName,
                        kategori: kategori,
                        module: modulAssistId,
                        media: media,
                        keterangan: keterangan.trim(),
                        status: status.trim(),
                        stepsTaken: stepsTaken,
                        solutions: solutions,
                        createdAt: new Date(),
                        createdId: currentUser.id,
                    };
                    setIsSubmitting(true);
                    await addTicketing(payload);
                    setIsSubmitting(false);
                    snackbar.showSuccess('Berhasil menambahkan ticketing baru');
                    onClose();
                    fetchTicketingList();
                } catch (e) {
                    console.error(e);
                    snackbar.showError('Gagal menambahkan ticketing baru');
                    setIsSubmitting(false);
                }
            } else {
                try {
                    const payload = {
                        tanggal: tanggal,
                        tanggalSelesai: tanggalSelesai,
                        hospitalId: selectedKlinik.KConfig.hospitalId,
                        namaKlinik: selectedKlinik.nama,
                        picKlinik: selectedPICKlinikName,
                        picId: PICAssistId,
                        picAssistName: selectedPICAssist,
                        namaProduk: selectedProductName,
                        kategori: kategori,
                        module: modulAssistId,
                        media: media,
                        keterangan: keterangan.trim(),
                        status: status.trim(),
                        stepsTaken: stepsTaken,
                        solutions: solutions,
                        updatedAt: new Date(),
                        updatedId: currentUser.id,
                    };
                    setIsSubmitting(true);
                    await updateTicketing(selectedTicketing._id, payload);
                    setIsSubmitting(false);
                    snackbar.showSuccess('Berhasil mengupdate ticketing');
                    onClose();
                    fetchTicketingList();
                } catch (e) {
                    console.error(e);
                    snackbar.showError('Gagal mengupdate ticketing');
                    setIsSubmitting(false);
                }
            }
        }
    }

    async function handleDelete() {
        const { snackbar } = dispatch;
        try {
            await deleteTicketing(selectedTicketing._id);
            snackbar.showSuccess('Berhasil menghapus ticket');
            setIsOpenDialogConfirmation(false);
            onClose();
            fetchTicketingList();
        } catch (e) {
            console.error(e);
            snackbar.showError('Gagal menghapus ticket');
            setIsOpenDialogConfirmation(false);
        }
    }

    return (
        <Grid container>
            <DeleteConfirmation
                contentText='Apakah anda yakin akan menghapus ticket ini?'
                open={isOpenDialogConfirmation}
                onClose={()=> setIsOpenDialogConfirmation(false)}
                onSubmit={()=> handleDelete()}
            />
            <Grid container justify='flex-end' item xs={12}>
                <Typography>
                    {`Input in ${format(inputtedDate, "dd MMMM yyyy, HH:mm 'WIB'", {locale: localeId})}`}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.wrapForm}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography className={classes.label}>
                                            Tanggal Mulai
                                        </Typography>
                                        <PDatePicker
                                            value={tanggal}
                                            onChange={handleChangeDate}
                                            format='dd MMMM yyyy'
                                            InputProps={{
                                                endAdornment: <InputAdornment position='end'>
                                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                                </InputAdornment>
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.label}>
                                            Tanggal Selesai
                                        </Typography>
                                        <PDatePicker
                                            value={tanggalSelesai}
                                            onChange={handleChangeEndDate}
                                            format='dd MMMM yyyy'
                                            InputProps={{
                                                endAdornment: <InputAdornment position='end'>
                                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                                </InputAdornment>
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    Nama Klinik
                                </Typography>
                                {
                                    selectedKlinik
                                        ? <PInput
                                            value={selectedKlinik.nama}
                                            InputProps={{
                                                endAdornment: <InputAdornment position='end'>
                                                    <IconButton onClick={()=> handleDeleteSelectedKlinik()}>
                                                        <FontAwesomeIcon icon={faTimesCircle}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                            fullWidth
                                        />
                                        : <Autocomplete
                                            key={keyKlinikAutoComplete}
                                            fetcher={faskesFetcherByKeyword}
                                            onChange={onSelectKlinik}
                                            variant="outlined"
                                            margin="dense"
                                            itemToString="nama"
                                            fullWidth
                                        />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    PIC Klinik
                                </Typography>
                                <PInput
                                    value={selectedPicKlinik}
                                    onChange={(e)=> handleSelectPICKlinik(e)}
                                    select
                                    fullWidth
                                >
                                    {
                                        picKlinik.map((item, idx) => (
                                            <MenuItem key={idx} value={idx}>
                                                {item.Confidentials ? item.Confidentials.nama : ''}
                                            </MenuItem>
                                        ))
                                    }
                                </PInput>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    PIC Assist.id
                                </Typography>
                                <PInput
                                    value={isManajemen ? PICAssistId : selectedPICAssist}
                                    onChange={(e)=> handleChangePICAssist(e)}
                                    InputProps={{
                                        readOnly: !isManajemen,
                                    }}
                                    select={isManajemen}
                                    fullWidth
                                >
                                    {
                                        panelaUserList.map((item, idx) => (
                                            <MenuItem key={idx} value={item.id}>
                                                {`${item.firstName} ${item.lastName}`}
                                            </MenuItem>
                                        ))
                                    }
                                </PInput>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    Nama Produk
                                </Typography>
                                <PInput
                                    value={selectedProduk}
                                    onChange={(e)=> handleSelectProduk(e)}
                                    select
                                    fullWidth
                                >
                                    {
                                        productList.map((item, idx) => (
                                            <MenuItem key={idx} value={idx}>
                                                {item.name}
                                            </MenuItem>
                                        ))
                                    }
                                </PInput>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    Kategori Ticket
                                </Typography>
                                <PInput
                                    value={kategori}
                                    onChange={(e)=> setKategori(e.target.value)}
                                    select
                                    fullWidth
                                >
                                    {
                                        ticketCategory.map((item, idx) => (
                                            <MenuItem key={idx} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))
                                    }
                                </PInput>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    Modul Assist.id
                                </Typography>
                                <PInput
                                    value={modulAssistId}
                                    onChange={(e)=> setModulAssistId(e.target.value)}
                                    select
                                    fullWidth
                                >
                                    {
                                        assistModule.map((item, idx) => (
                                            <MenuItem key={idx} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))
                                    }
                                </PInput>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    PIC Klinik Pertama Kali Menghubungi Via?
                                </Typography>
                                <PInput
                                    value={media}
                                    onChange={(e)=> setMedia(e.target.value)}
                                    select
                                    fullWidth
                                >
                                    <MenuItem value='Intercom'>Intercom</MenuItem>
                                    <MenuItem value='Whatsapp Group'>Whatsapp Group</MenuItem>
                                    <MenuItem value='Whatsapp Chat Personal'>Whatsapp Chat Personal</MenuItem>
                                    <MenuItem value='Video Call'>Video Call</MenuItem>
                                    <MenuItem value='Telepon'>Telepon</MenuItem>
                                </PInput>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    Keterangan Dari Customer
                                </Typography>
                                <PInput
                                    value={keterangan}
                                    onChange={(e)=> setKeterangan(e.target.value)}
                                    multiline
                                    rows={6}
                                    fullWidth
                                />
                            </Grid>
                            {(status === 'On Progress' || status === 'Solved') && <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    Langkah Yang Dilakukan Assist.id?
                                </Typography>
                                <PInput
                                    value={stepsTaken}
                                    onChange={(e)=> setStepsTaken(e.target.value)}
                                    multiline
                                    rows={6}
                                    fullWidth
                                />
                            </Grid>}
                            {status === 'Solved' && <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    Bagaimana Cara Penyelesaiannya?
                                </Typography>
                                <PInput
                                    value={solutions}
                                    onChange={(e)=> setSolutions(e.target.value)}
                                    multiline
                                    rows={6}
                                    fullWidth
                                />
                            </Grid>}
                            <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    Status
                                </Typography>
                                <RadioGroup row value={status} onChange={handleChangeStatus}>
                                    <FormControlLabel control={<CustomRadioButton color='red'/>} label='Waiting' value='Waiting' labelPlacement='end'/>
                                    <FormControlLabel control={<CustomRadioButton color='yellow'/>} label='On Progress' value='On Progress' labelPlacement='end'/>
                                    <FormControlLabel control={<CustomRadioButton color='green'/>} label='Solved' value='Solved' labelPlacement='end'/>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.wrapAction}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Grid container justify='flex-start' item xs={12}>
                            {isEdit && <PBtn
                                onClick={() => setIsOpenDialogConfirmation(true)}
                            >
                                Hapus
                            </PBtn>}
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container justify='flex-end' item xs={12}>
                            <PBtn
                                inverted
                                dropShadow
                                style={{
                                    marginRight: 16,
                                }}
                                onClick={onClose}
                            >
                                Batal
                            </PBtn>
                            <PBtn
                                disabled={isSubmitting}
                                onClick={()=> handleSimpan()}
                            >
                                {!isSubmitting ? 'Simpan' : <FontAwesomeIcon icon={faSpinner} spin/>}
                            </PBtn>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapState = ({ user, ticketing }) => ({
    currentUser: user.currentUser,
    selectedTicketing: ticketing.selectedTicketing,
});

const mapDispatch = ({ ticketing: { fetchTicketingList } }) => ({
    fetchTicketingList,
});

export default connect(mapState, mapDispatch)(TicketingForm);
