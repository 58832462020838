import agent from "./agent";
import format from "date-fns/format";

export const addNewActivity = (data) => {
    return agent.post('Activities', data);
};

export const addNewPenolakan = (data) => {
    return agent.post('Rejections', data);
};

export const updateActivity = (activityId, data) => {
    return agent.patch(`Activities/${activityId}`, data);
};

export const updatePenolakan = (rejectionId, data) => {
    return agent.patch(`Rejections/${rejectionId}`, data);
};

export const deletePenolakan = (rejectionId) => {
    return agent.delete(`Rejections/${rejectionId}/delete`);
};

export const deleteActivity = (activityId) => {
    return agent.delete(`Activities/${activityId}/deleteActivity`);
};

export const countActivities = () => {
    return agent.get('Activities/count');
};

export const countActivity = (skipLimit) => {
    let params;
    if(skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }

    return agent.get('Activities/countActivities', {
        params,
    });
};

export const getActivities = (skipLimit) => {
    let params;
    if (skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }
    return agent.get('Activities/getActivities', {
        params,
    });
};

export const getLastActivities = (faskesId) => {
    const params = {
        healthFacilityId: faskesId,
        limit: 5,
    };

    return agent.get('HealthFacilities/getLatestActivities', {
        params,
    });
};

export const getRejections = (skipLimit) => {
    let params;
    if (skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }
    return agent.get('Rejections/getRejections', {
        params,
    });
};

export const countRejectionList = () => {
    const params = '';

    return agent.get('Rejections/countRejections', {
        params,
    });
};

export const getRejectionByFilter = (payload) => {
    const params = {
        tanggalPenolakanAwal: payload.tanggalPenolakanAwal === null ? '' : format(new Date(payload.tanggalPenolakanAwal), "yyyy-MM-dd"),
        tanggalPenolakanSelesai: payload.tanggalPenolakanSelesai === null ? '' : format(new Date(payload.tanggalPenolakanSelesai), "yyyy-MM-dd"),
        nama: payload.nama,
        picKlinik: payload.picKlinik,
        kategoriPenolakan: payload.kategoriPenolakan,
        penjelasanDetail: payload.penjelasanDetail,
        skip: payload.skip,
        limit: payload.limit,
    };
    return agent.get('Rejections/getRejections', {
        params,
    });
};

export const countRejectionByFilter = (payload) => {
    const params = {
        tanggalPenolakanAwal: payload.tanggalPenolakanAwal === null ? '' : format(new Date(payload.tanggalPenolakanAwal), "yyyy-MM-dd"),
        tanggalPenolakanSelesai: payload.tanggalPenolakanSelesai === null ? '' : format(new Date(payload.tanggalPenolakanSelesai), "yyyy-MM-dd"),
        nama: payload.nama,
        picKlinik: payload.picKlinik,
        kategoriPenolakan: payload.kategoriPenolakan,
        penjelasanDetail: payload.penjelasanDetail,
    };
    return agent.get('Rejections/countRejections', {
        params,
    });
};

export const getOffers = (skipLimit) => {
    let params;
    if (skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }
    return agent.get('Offers/getOffers', {
        params,
    });
};

export const getActivitiesByFilter = (payload) => {
    let params;
    if(payload.skip === null || payload.limit === null){
        params = {
          order: payload.order,
          tanggalAktivitasMulai: format(new Date(payload.tanggalAktivitasMulai), "yyyy-MM-dd"),
          tanggalAktivitasSelesai: format(new Date(payload.tanggalAktivitasSelesai), "yyyy-MM-dd"),
          nama: payload.nama,
          picKlinik: payload.picKlinik,
          picAssist: payload.picAssist,
          media: payload.media,
          catatan: payload.catatan,
          skip: 0,
          limit: 1000,
        };
    } else {
      params = {
          order: payload.order,
          tanggalAktivitasMulai: payload.tanggalAktivitasMulai === null ? '' : format(new Date(payload.tanggalAktivitasMulai), "yyyy-MM-dd"),
          tanggalAktivitasSelesai: payload.tanggalAktivitasSelesai === null ? '' : format(new Date(payload.tanggalAktivitasSelesai), "yyyy-MM-dd"),
          nama: payload.nama,
          picKlinik: payload.picKlinik,
          picAssist: payload.picAssist,
          media: payload.media,
          catatan: payload.catatan,
          skip: payload.skip,
          limit: payload.limit,
      };
    }
    return agent.get('Activities/getActivities', {
        params,
    });
};

export const countActivitiesByFilter = (payload) => {
    const params = {
        order: payload.order,
        tanggalAktivitasMulai: payload.tanggalAktivitasMulai === null ? '' : format(new Date(payload.tanggalAktivitasMulai), "yyyy-MM-dd"),
        tanggalAktivitasSelesai: payload.tanggalAktivitasSelesai === null ? '' : format(new Date(payload.tanggalAktivitasSelesai), "yyyy-MM-dd"),
        nama: payload.nama,
        picKlinik: payload.picKlinik,
        picAssist: payload.picAssist,
        media: payload.media,
        catatan: payload.catatan,
    };
    return agent.get('Activities/countActivities', {
        params,
    });
};

export const countOfferList = () => {
    const params = '';

     return agent.get('Offers/countOffers', {
         params,
     });
};

export const countOfferListFilter = (payload) => {
    const params = {
        tanggalPenawaranMulai: payload.tanggalPenawaranMulai === null ? '' : format(new Date(payload.tanggalPenawaranMulai), "yyyy-MM-dd"),
        tanggalPenawaranSelesai: payload.tanggalPenawaranSelesai === null ? '' : format(new Date(payload.tanggalPenawaranSelesai), "yyyy-MM-dd"),
        nama: payload.nama,
        picKlinik: payload.picKlinik,
        hargaPenawaran: payload.hargaPenawaran,
        termin: payload.termin,
        batasPembayaranMulai: payload.batasPembayaranMulai === null ? '' : format(new Date(payload.batasPembayaranMulai), "yyyy-MM-dd"),
        batasPembayaranSelesai: payload.batasPembayaranSelesai === null ? '' : format(new Date(payload.batasPembayaranSelesai), "yyyy-MM-dd"),
        picAssist: payload.picAssist,
        statusPembayaran: payload.statusPembayaran,
        };

    return agent.get('Offers/countOffers', {
        params,
    });
};

export const getOffersByFilter = (payload) => {
    const params = {
        tanggalPenawaranMulai: payload.tanggalPenawaranMulai === null ? '' : format(new Date(payload.tanggalPenawaranMulai), "yyyy-MM-dd"),
        tanggalPenawaranSelesai: payload.tanggalPenawaranSelesai === null ? '' : format(new Date(payload.tanggalPenawaranSelesai), "yyyy-MM-dd"),
        nama: payload.nama,
        picKlinik: payload.picKlinik,
        hargaPenawaran: payload.hargaPenawaran,
        termin: payload.termin,
        batasPembayaranMulai: payload.batasPembayaranMulai === null ? '' : format(new Date(payload.batasPembayaranMulai), "yyyy-MM-dd"),
        batasPembayaranSelesai: payload.batasPembayaranSelesai === null ? '' : format(new Date(payload.batasPembayaranSelesai), "yyyy-MM-dd"),
        picAssist: payload.picAssist,
        statusPembayaran: payload.statusPembayaran,
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Offers/getOffers', {
        params,
    });
};

export const getTrialList = (skipLimit) => {
    let params;
    if(skipLimit) {
        params = skipLimit;
    } else {
        params = {
            isTrial: 1,
            skip: 0,
            limit: 5,
        };
    }

    return agent.get('Clients/getFaskesList', {
        params,
    });
};

export const countTrialList = () => {
    const params = {
        isTrial: 1,
    };

    return agent.get('Clients/countFaskesList', {
        params,
    });
};

export const getActivityLatestLeads = (leadsId) => {
    const params = {
        healthFacilityId: leadsId,
        limit: 1,
    };

    return agent.get('HealthFacilities/getLatestActivities', {
        params,
    });
};
