import React from 'react';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import { connect } from 'react-redux';

import KategoriPenolakanListItem from "../SettingListItem";
import {dispatch} from "../../../store";
import {updateRejectionCategory} from "../../../api/rejection-category";
import {deleteDictionary} from "../../../api/dictionary";

const ListKategoriPenolakan = ({ isFetchingRejectionCategoryList, rejectionCategoryList, fetchRejectionCategories }) => {
    const handleSave = async (editData, data, currentUser, setIsUpdating) => {
        const { snackbar } = dispatch;

        try {
            setIsUpdating(true);
            await updateRejectionCategory(editData, data.id, currentUser.id);
            setIsUpdating(false);
            snackbar.showSuccess('Berhasil mengupdate kategori penolakan');

            await fetchRejectionCategories();
        } catch (err) {
            console.error(err);
            setIsUpdating(false);
            snackbar.showError('Gagal mengupdate kategori penolakan');
        }
    };

    const handleDelete = async (id, setIsDeleting) => {
        const { snackbar } = dispatch;

        try {
            setIsDeleting(true);
            await deleteDictionary(id);
            setIsDeleting(false);
            snackbar.showSuccess('Berhasil menghapus kategori penolakan');

            await fetchRejectionCategories();
        } catch (err) {
            console.error(err);
            setIsDeleting(false);
            snackbar.showError('Gagal menghapus kategori penolakan');
        }
    };

    return (
        <Grid item xs={12}>
            {
                isFetchingRejectionCategoryList
                    ? <Grid container justify="center">
                        <CircularProgress />
                    </Grid>
                    : <List dense  style={{marginTop: '40px', marginLeft: '-13px'}}>
                        {
                            rejectionCategoryList.map(rejectionCategory => (
                                <KategoriPenolakanListItem
                                    key={rejectionCategory.id}
                                    data={rejectionCategory}
                                    saveHandler={handleSave}
                                    deleteHandler={handleDelete}
                                />
                                // <li key={rejectionCategory.id}>{rejectionCategory.name}</li>
                            ))
                        }
                    </List>
            }
        </Grid>
    )
};

const mapState = ({ rejectionCategory }) => ({
    rejectionCategoryList: rejectionCategory.rejectionCategoryList,
    isFetchingRejectionCategoryList: rejectionCategory.isFetchingRejectionCategoryList,
});

const mapDispatch = ({ rejectionCategory: { fetchRejectionCategories } }) => ({
    fetchRejectionCategories,
});

export default connect(mapState, mapDispatch)(ListKategoriPenolakan);
