import {
    getActivities,
    getRejections,
    countRejectionList,
    getRejectionByFilter,
    countRejectionByFilter,
    // countActivities,
    countActivity,
    getOffers,
    getActivitiesByFilter,
    countActivitiesByFilter,
    countOfferList,
    getOffersByFilter,
    countOfferListFilter,
    getTrialList,
    countTrialList,
} from "../../api/activity";
import {
    getBlockedFaskesList,
    countBlockedFaskesList,
    getTrialListByFilter,
    countTrialListByFilter,
    getBlockedListByFilter,
    countBlockedListByFilter,
} from "../../api/faskes";

export const activity = {
    state: {
        openDeleteConfirmation: false,
        isFetchingActivityList: false,
        isFetchingRejectionList: false,
        isFetchingBlockedList: false,
        isFetchingTrialList: false,
        isFetchingOfferList: false,
        openPrintPenawaranDialog: false,
        activityList: [],
        activityListCounter: 0,
        rejectionList: [],
        rejectionListCounter: 0,
        blockedList: [],
        blockedListCounter: 0,
        trialList: [],
        trialListCounter: 0,
        offerList: [],
        offerListCounter: 0,
        selectedActivity: {},
        selectedRejection: {},
        selectedOffer: {},
        selectedActivitiesFilter: {},
        selectedOfferFilter: {},
        selectedTrialFilter: {},
        selectedRejectionFilter: {},
        selectedBlockedFilter: {},
    },
    reducers: {
        setOpenDeleteConfirmation(state, payload) {
            return {
                ...state,
                openDeleteConfirmation: payload,
            };
        },
        updateState(state, payload) {
            const { stateName, value } = payload;
            return {
                ...state,
                [stateName]: value,
            };
        },
        updateOpenPrintPenawaranDialog(state, payload) {
            return {
                ...state,
                openPrintPenawaranDialog: payload,
            };
        },
        updateActivityList(state, payload) {
            const { value, skipLimit } = payload;

            if (!skipLimit) {
                return {
                    ...state,
                    activityList: value,
                };
            }

            const { activityList } = state;
            activityList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                activityList: [...activityList],
            };
        },
        updateOfferList(state, payload) {
            const { value, skipLimit } = payload;

            if (!skipLimit) {
                return {
                    ...state,
                    offerList: value,
                };
            }

            const { offerList } = state;
            offerList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                offerList: [...offerList],
            };
        },
        updateTrialList(state, payload) {
            const { value, skipLimit } = payload;

            if (!skipLimit) {
                return {
                    ...state,
                    trialList: value,
                };
            }

            const { trialList } = state;
            trialList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                trialList: [...trialList],
            };
        },
        updateBlockedList(state, payload) {
            const { value, skipLimit } = payload;

            if (!skipLimit) {
                return {
                    ...state,
                    blockedList: value,
                };
            }

            const { blockedList } = state;
            blockedList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                blockedList: [...blockedList],
            };
        },
        updateRejectionList(state, payload) {
            const { value, skipLimit } = payload;

            if (!skipLimit) {
                return {
                    ...state,
                    rejectionList: value,
                };
            }

            const { rejectionList } = state;
            rejectionList.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                rejectionList: [...rejectionList],
            };
        },
        updateActivityListFilter(state, payload) {
            const { value, payloads } = payload;

            if (payloads.condition) {
                return {
                    ...state,
                    activityList: value,
                };
            }

            const { activityList } = state;
            activityList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                activityList: [...activityList],
            };
        },
        updateOfferListFilter(state, payload) {
            const { value, payloads } = payload;

            if (payloads.condition) {
                return {
                    ...state,
                    offerList: value,
                };
            }

            const { offerList } = state;
            offerList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                offerList: [...offerList],
            };
        },
        updateTrialListFilter(state, payload) {
            const { value, payloads } = payload;

            if (payloads.condition) {
                return {
                    ...state,
                    trialList: value,
                };
            }

            const { trialList } = state;
            trialList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                trialList: [...trialList],
            };
        },
        updateBlockedListFilter(state, payload) {
            const { value, payloads } = payload;

            if (payloads.condition) {
                return {
                    ...state,
                    blockedList: value,
                };
            }

            const { blockedList } = state;
            blockedList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                blockedList: [...blockedList],
            };
        },
        updateRejectionListFilter(state, payload) {
            const { value, payloads } = payload;

            if (payloads.condition) {
                return {
                    ...state,
                    rejectionList: value,
                };
            }

            const { rejectionList } = state;
            rejectionList.splice(payloads.skip, payloads.limit, ...value);
            return {
                ...state,
                rejectionList: [...rejectionList],
            };
        },
        updateSelectedActivity(state, payload) {
            return {
                ...state,
                selectedActivity: payload,
            };
        },
        updateSelectedRejection(state, payload) {
            return {
                ...state,
                selectedRejection: payload,
            };
        },
        updateSelectedOffer(state, payload) {
            return {
                ...state,
                selectedOffer: payload,
            };
        },
        updateSelectedActivitiesFilter(state, payload) {
            return {
                ...state,
                selectedActivitiesFilter: payload,
            };
        },
        updateSelectedOfferFilter(state, payload) {
            return {
                ...state,
                selectedOfferFilter: payload,
            };
        },
        updateSelectedTrialFilter(state, payload) {
            return {
                ...state,
                selectedTrialFilter: payload,
            };
        },
        updateSelectedRejectionFilter(state, payload) {
            return {
                ...state,
                selectedRejectionFilter: payload,
            };
        },
        updateSelectedBlockedFilter(state, payload) {
            return {
                ...state,
                selectedBlockedFilter: payload,
            };
        },
    },
    effects: dispatch => ({
        async fetchActivities(skipLimit) {
            try {
                this.updateState({
                    stateName: 'isFetchingActivityList',
                    value: true,
                });

                const result = await getActivities(skipLimit);
                const counterResult = await countActivity(skipLimit);

                this.updateActivityList({
                    value: result.data.map($item => {
                        const newItem = {...$item};
                        newItem.id = $item._id;
                        delete newItem._id;
                        return newItem;
                    }),
                    skipLimit,
                });
                this.updateState({
                    stateName: 'isFetchingActivityList',
                    value: false,
                });
                this.updateState({
                    stateName: 'activityListCounter',
                    value: counterResult.data,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar aktivitas");
                this.updateState({
                    stateName: 'isFetchingActivityList',
                    value: false,
                });
            }
        },

        async fetchActivitiesFilter(payloads) {
            try {
                this.updateState({
                    stateName: 'isFetchingActivityList',
                    value: true,
                });

                const result = await getActivitiesByFilter(payloads);
                const resultCounter = await countActivitiesByFilter(payloads);

                this.updateActivityListFilter({
                    value: result.data.map($item => {
                        const newItem = {...$item};
                        newItem.id = $item._id;
                        delete newItem._id;
                        return newItem;
                    }),
                    payloads,
                });
                this.updateState({
                    stateName: 'isFetchingActivityList',
                    value: false,
                });
                this.updateState({
                    stateName: 'activityListCounter',
                    value: resultCounter.data,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar aktivitas");
                this.updateState({
                    stateName: 'isFetchingActivityList',
                    value: false,
                });
            }
        },

        async fetchRejections(skipLimit) {
            try {
                this.updateState({
                    stateName: 'isFetchingRejectionList',
                    value: true,
                });

                const result = await getRejections(skipLimit);
                const resultCounter = await countRejectionList();

                this.updateRejectionList({
                    value: result.data.map($item => {
                        const newItem = $item;
                        newItem.id = $item._id;
                        delete newItem._id;
                        return newItem;
                    }),
                    skipLimit,
                });
                this.updateState({
                    stateName: 'rejectionListCounter',
                    value: resultCounter.data,
                });
                this.updateState({
                    stateName: 'isFetchingRejectionList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar penolakan");
                this.updateState({
                    stateName: 'isFetchingRejectionList',
                    value: false,
                });
            }
        },

        async fetchRejectionsFilter(payloads) {
            try {
                this.updateState({
                    stateName: 'isFetchingRejectionList',
                    value: true,
                });

                const result = await getRejectionByFilter(payloads);
                const resultCounter = await countRejectionByFilter(payloads);

                this.updateRejectionListFilter({
                    value: result.data.map($item => {
                        const newItem = $item;
                        newItem.id = $item._id;
                        delete newItem._id;
                        return newItem;
                    }),
                    payloads,
                });
                this.updateState({
                    stateName: 'rejectionListCounter',
                    value: resultCounter.data,
                });
                this.updateState({
                    stateName: 'isFetchingRejectionList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar penolakan");
                this.updateState({
                    stateName: 'isFetchingRejectionList',
                    value: false,
                });
            }
        },

        async fetchBlockedList(skipLimit) {
            try {
                this.updateState({
                    stateName: 'isFetchingBlockedList',
                    value: true,
                });

                const result = await getBlockedFaskesList(skipLimit);
                const resultCounter = await countBlockedFaskesList();

                this.updateBlockedList({
                    value: result.data.map($item => {
                        const newItem = $item;
                        newItem.id = $item._id;
                        delete newItem._id;
                        return newItem;
                    }),
                    skipLimit,
                });
                this.updateState({
                    stateName: 'blockedListCounter',
                    value: resultCounter.data,
                });
                this.updateState({
                    stateName: 'isFetchingBlockedList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar blocked");
                this.updateState({
                    stateName: 'isFetchingBlockedList',
                    value: false,
                });
            }
        },

        async fetchBlockedListFilter(payloads){
            try {
                this.updateState({
                    stateName: 'isFetchingBlockedList',
                    value: true,
                });

                const result = await getBlockedListByFilter(payloads);
                const resultCounter = await countBlockedListByFilter(payloads);

                this.updateBlockedListFilter({
                    value: result.data.map($item => {
                        const newItem = $item;
                        newItem.id = $item._id;
                        delete newItem._id;
                        return newItem;
                    }),
                    payloads,
                })
                this.updateState({
                    stateName: 'blockedListCounter',
                    value: resultCounter.data,
                })
                this.updateState({
                    stateName: 'isFetchingBlockedList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar blocked");
                this.updateState({
                    stateName: 'isFetchingBlockedList',
                    value: false,
                });
            }
        },

        async fetchTrialList(skipLimit) {
            try {
                this.updateState({
                    stateName: 'isFetchingTrialList',
                    value: true,
                });

                const result = await getTrialList(skipLimit);
                const resultCounter = await countTrialList();

                this.updateTrialList({
                    value: result.data,
                    skipLimit,
                });
                this.updateState({
                    stateName: 'trialListCounter',
                    value: resultCounter.data,
                });
                this.updateState({
                    stateName: 'isFetchingTrialList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar trial");
                this.updateState({
                    stateName: 'isFetchingTrialList',
                    value: false,
                });
            }
        },

        async fetchTrialListFilter(payloads){
            try {
                this.updateState({
                    stateName: 'isFetchingTrialList',
                    value: true,
                });

                const result = await getTrialListByFilter(payloads);
                const resultCounter = await countTrialListByFilter(payloads);

                this.updateTrialListFilter({
                    value: result.data,
                    payloads,
                });
                this.updateState({
                    stateName: 'trialListCounter',
                    value: resultCounter.data,
                });
                this.updateState({
                    stateName: 'isFetchingTrialList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar trial");
                this.updateState({
                    stateName: 'isFetchingTrialList',
                    value: false,
                });
            }
        },

        async fetchOffers(skipLimit) {
            try {
                this.updateState({
                    stateName: 'isFetchingOfferList',
                    value: true,
                });

                const result = await getOffers(skipLimit);
                const resultCounter = await countOfferList();

                this.updateOfferList({
                    value: result.data,
                    skipLimit,
                })
                this.updateState({
                    stateName: 'offerListCounter',
                    value: resultCounter.data,
                })
                this.updateState({
                    stateName: 'isFetchingOfferList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar offer");
                this.updateState({
                    stateName: 'isFetchingOfferList',
                    value: false,
                });
            }
        },

        async fetchOffersFilter(payloads){
            try {
                this.updateState({
                    stateName: 'isFetchingOfferList',
                    value: true,
                });

                const result = await getOffersByFilter(payloads);
                const resultCounter = await countOfferListFilter(payloads);

                this.updateOfferListFilter({
                    value: result.data,
                    payloads,
                })
                this.updateState({
                    stateName: 'offerListCounter',
                    value: resultCounter.data,
                })
                this.updateState({
                    stateName: 'isFetchingOfferList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar offer");
                this.updateState({
                    stateName: 'isFetchingOfferList',
                    value: false,
                });
            }
        },
    }),
};
