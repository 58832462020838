import React, { useState } from 'react';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from "@material-ui/core/Typography";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";

import PInput from "../share/PInput";
import PBtn from "../share/PBtn";
import DeleteConfirmation from "../share/DeleteConfirmation";

import Icon from '../../assets/img/leads-icon.png';

const styles = makeStyles({
    titleDialog: {
        fontFamily: "Nunito",
        fontSize: "20px",
        color: "#15162d",
        fontWeight: "600",
        marginTop: "10px",
        marginLeft: "0px",
    }
});

const SettingListItem =  ({ data, currentUser, saveHandler, deleteHandler }) => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deletedId, setDeletedId] = useState(undefined);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenEdit = (isOpen, data) => (_) => {
        if (isOpen) {
            setOpenEdit(true);
            setEditData(data.name);
        } else {
            setOpenEdit(false);
            setEditData('');
        }
    };

    const handleEdit = (e) => {
        const { value } = e.target;
        setEditData(value);
    };

    const handleSave = async () => {
        await saveHandler(editData, data, currentUser, setIsUpdating);
    };

    const handleDelete = async () => {
        await deleteHandler(deletedId, setIsDeleting);
    };

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: 'white' }}>
                    <img src={Icon} alt="icon"/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={data.name}
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" onClick={handleClick}>
                    <FontAwesomeIcon icon={faEllipsisV} />
                </IconButton>

                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={isDeleting ? () => {} : handleClose}
                >
                    <MenuItem onClick={isDeleting ? () => {} : handleOpenEdit(true, data)}>Edit</MenuItem>
                    <MenuItem onClick={() => {
                        setShowDeleteConfirmation(true);
                        setDeletedId(data.id);
                    }}>Delete</MenuItem>
                </Menu>

                <DeleteConfirmation
                    contentText={"Apakah anda yakin akan menghapus item ini?"}
                    open={showDeleteConfirmation}
                    onClose={() => {
                        setShowDeleteConfirmation(false);
                        setDeletedId(undefined);
                    }}
                    onSubmit={async () => {
                        await handleDelete();
                    }}
                />

                <Dialog
                    open={openEdit}
                    onClose={isUpdating ? () => {} : handleOpenEdit(false)}
                    onBackdropClick={isUpdating ? () => {} : handleOpenEdit(false)}
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle>
                        <Typography className={classes.titleDialog}>Edit Data</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <PInput
                            placeholder="Edit disini"
                            value={editData}
                            onChange={handleEdit}
                            style={{width: '100%'}}
                        />
                    </DialogContent>
                    <DialogActions style={{marginTop: '10px', marginRight: '16px', marginBottom: '10px'}}>
                        <PBtn inverted dropShadow disabled={isUpdating} onClick={handleOpenEdit(false)}>
                            Batal
                        </PBtn>
                        <PBtn disabled={isUpdating} onClick={handleSave}>
                            Simpan
                        </PBtn>
                    </DialogActions>
                </Dialog>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(SettingListItem);
