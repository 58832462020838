import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    notActive: {
        background: '#fafafa',
        borderRadius: 0,
        color: '#6e7d91',
        fontSize: 14,
        height: 48,
        padding: '0 0',
        borderBottom: '4px solid #f5f6f8',
    },
    notActiveInverted: {
        background: 'white',
        borderRadius: 0,
        color: '#6e7d91',
        fontSize: 14,
        height: 48,
        padding: '0 0',
        borderBottom: '4px solid #f5f6f8',
    },
    active: {
        background: '#fafafa',
        borderRadius: 0,
        color: '#2b64af',
        fontSize: 14,
        height: 48,
        padding: '0 0',
        borderBottom: '4px solid #2b64af',
    },
    activeInverted: {
        background: 'white',
        borderRadius: 0,
        color: '#2b64af',
        fontSize: 14,
        height: 48,
        padding: '0 0',
        borderBottom: '4px solid #2b64af',
    },
    label: {
        textTransform: 'capitalize',
    },
});

export default function ({ children, isActive, inverted, ...props }) {
    const classes = useStyles();

    const activeClass = inverted ? classes.activeInverted : classes.active;
    const notActiveClass = inverted ? classes.notActiveInverted : classes.notActive;

    return (
        <Button
            classes={{
                root: isActive ? activeClass : notActiveClass,
                label: classes.label,
            }}
            {...props}
        >
            {children}
        </Button>
    )
}
