import React, { useState } from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faCheckCircle} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import ActivityForm from "./ActivityForm";
import ActivitiesTable from "./ActivitiesTable";

import filter from './../../../assets/img/filter-icon.png';
import arrowDown from './../../../assets/img/arrow-down-icon.png';

import DeleteActivityDialog from "./DeleteActivityDialog";
import ActivitiesFilter from "./ActivitiesFilter";

const styles = makeStyles({
    chip: {
        marginRight: 10,
    },
});

const Overview = ({ updateSelectedActivity, fetchActivitiesFilter, updateSelectedActivitiesFilter, selectedActivitiesFilter }) => {
    const classes = styles();
    const [isOpenAddNew, setIsOpenAddNew] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        tanggalAktivitasMulai: null,
        tanggalAktivitasSelesai: null,
        nama: '',
        picKlinik: '',
        picAssist: '',
        media: '',
        catatan: '',
        skip: 0,
        limit: 5,
    });
    const [pageFilter, setPageFilter] = useState(0);
    const [isOpenFilterForm, setIsOpenFilterForm] = useState(false);

    function handleCloseDialog() {
        setIsOpenAddNew(false);
        updateSelectedActivity({});
    }

    const handleFilter = () => {
        setIsFilter(true);
        setPageFilter(0);
        fetchActivitiesFilter(filterData);
        setIsOpenFilterForm(false);
        updateSelectedActivitiesFilter(JSON.parse(JSON.stringify(filterData)));
    };

     const handleClearFilter = () => {
         setFilterData({
             tanggalAktivitasMulai: null,
             tanggalAktivitasSelesai: null,
             nama: '',
             picKlinik: '',
             picAssist: '',
             media: '',
             catatan: '',
             skip: 0,
             limit: 5,
         });
         setPageFilter(0);
         setIsFilter(false);
         updateSelectedActivitiesFilter({});
     };

    return (
        <Grid container>
            <DeleteActivityDialog />

            <Dialog open={isOpenAddNew} onClose={() => handleCloseDialog()} maxWidth="md" fullWidth>
                <DialogContent>
                    {
                        isOpenAddNew && <ActivityForm onClose={() => handleCloseDialog()} />
                    }
                </DialogContent>
            </Dialog>

            <Grid container item xs={12} style={{marginTop: '44px'}}>
                <Grid container item xs={6}>
                    <PInput
                        style={{
                            marginRight: 32,
                            flex: 0.9,
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch} />
                            </InputAdornment>
                        }}
                        placeholder="Cari klinik disini"
                    />
                    {/* <PBtn onClick={() => setIsOpenAddNew(true)}>
                        Tambahkan
                    </PBtn> */}
                </Grid>
                <Grid item xs={6}>
                    <div className="filter" style={{marginTop: '-8px'}}>
                        <img alt="filter" src={filter} className="img-filter" />
                        <Typography>Filter by</Typography>
                        <img
                            alt="arrow"
                            src={arrowDown}
                            className="arrow-filter"
                            onClick={()=> setIsOpenFilterForm(true)}
                        />
                        <Dialog open={isOpenFilterForm} maxWidth="xs" onClose={()=> setIsOpenFilterForm(false)} fullWidth>
                            <DialogContent>
                                <ActivitiesFilter
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    startFilter={handleFilter}
                                    clearFilter={handleClearFilter}
                                />
                            </DialogContent>
                        </Dialog>
                    </div>
                </Grid>
            </Grid>
            {
                isFilter && <Grid item xs={12} style={{ marginTop: 30, dispaly: 'flex' }}>
                    {
                        selectedActivitiesFilter.tanggalAktivitasMulai !== null 
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Tanggal Mulai"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            />
                            : null
                    }
                    {
                        selectedActivitiesFilter.tanggalAktivitasSelesai !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Tanggal Selesai"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            />
                            : null
                    }
                    {
                        selectedActivitiesFilter.nama !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Nama Klinik"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            />
                            : null
                    }
                    {
                        selectedActivitiesFilter.picKlinik !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="PIC Klinik"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            />
                            : null
                    }
                    {
                        selectedActivitiesFilter.picAssist !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="PIC Assist.id"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            />
                            : null
                    }
                    {
                        selectedActivitiesFilter.media !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Media"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            />
                            : null
                    }
                    {
                        selectedActivitiesFilter.catatan !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Catatan"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            />
                            : null
                    }
                </Grid>
            }
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <ActivitiesTable
                    setIsOpenAddNew={setIsOpenAddNew}
                    isFilter={isFilter}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    pageFilter={pageFilter}
                />
            </Grid>
        </Grid>
    );
};

const mapState = ({ activity }) => ({
    selectedActivitiesFilter: activity.selectedActivitiesFilter,
});

const mapDispatch = ({ activity: { updateSelectedActivity, fetchActivitiesFilter, updateSelectedActivitiesFilter} }) => ({
    updateSelectedActivity,
    fetchActivitiesFilter,
    updateSelectedActivitiesFilter,
});

export default connect(mapState, mapDispatch)(Overview);
