import agent from "./agent";

export const deleteDictionary = (id) => {
    return agent.delete(`Dictionaries/${id}`);
};

export const addNewDictionary = (payload) => {
    return agent.post(`Dictionaries`, payload);
};

export const getProducts = (payload) => {
    const filter = {
        where: {
            type: 'product',
        },
    };

    return agent.get(`Dictionaries`, {
        params: {
            filter,
        }
    });
};

export const getCategoryByKeyword = (keyword, type) => {
    const filter = {
        where: {
            type,
            name: {
                like: keyword,
                options: 'ig'
            },
        },
    };

    return agent.get('Dictionaries', {
        params: {
            filter,
        },
    });
};
