import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { faTimes, faEye, faEyeSlash, faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import {dispatch} from "../../../store";

import NoImage from '../../../assets/img/no-image-available.png';

import { getUserRoles } from '../../../api/user-roles';
import { uploadProfileImage } from '../../../api/file';
import { updateUser, checkPassword } from '../../../api/panela-user';
import { toTitleCase } from '../../../helpers/capitalize';

const useStyles = makeStyles({
    profilePhotoContainer: {
        position: 'relative',
        width: 120,
        height: 100,
    },
    profilePhoto: {
        display: 'block',
        maxWidth: 230,
        maxHeight: 100,
        width: 100,
        height: 100,
        borderRadius: '100%',
    },
    changePhotoIcon: {
        position: 'absolute',
        bottom: -17,
        right: 7,
    },
});

const PICAssistForm = ({ postNewPanelaUser, currentUser, onAbort, isEdit, isAccount, editableData, getPanelaUserList }) => {
    const inputImageRef = useRef(null);

    const [userRoles, setUserRoles] = useState([]);
    const [hidePassword, setHidePassword] = useState(true);
    const [hideNewPassword, setHideNewPassword] = useState(true);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const [profilePhoto, setProfilePhoto] = useState(editableData.profilePhoto || '');
    const [newImg, setNewImg] = useState(null);
    const [firstName, setFirstname] = useState(editableData.firstName || '');
    const [lastName, setLastname] = useState(editableData.lastName || '');
    const [username, setUsername] = useState(editableData.username || '');
    const [emailAddress, setEmailAddress] = useState(editableData.email ? `${editableData.email}` : '');
    const [position, setPosition] = useState(editableData.position || '');
    const [emailValid, setEmailValid] = useState(true);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(editableData.phone || '');
    const [accessRights, setAccessRights] = useState(editableData.roleId || '');

    const { UserRole } = currentUser;
    const isManajemen = UserRole && UserRole.role === 'manajemen';

    useEffect(() => {
        const fetchData = async () => {
            const result = await getUserRoles();
            setUserRoles(result.data);
        };
        fetchData().then();
    }, []);

    const resetFields = () => {
        setFirstname('');
        setLastname('');
        setUsername('');
        setPosition('');
        setEmailAddress('');
        setPassword('');
        setPhoneNumber('');
        setAccessRights('');
    };

    const handleSave = async () => {
        const { snackbar } = dispatch;
        try {
            const validateEmail = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (!validateEmail.test(emailAddress)) {
                setEmailValid(false);
                return;
            } else {
                setEmailValid(true);
            }

            if (!isEdit && typeof editableData.id === 'undefined') {
                const payload = {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    username: username.trim(),
                    email: emailAddress.trim(),
                    position: position.trim(),
                    password,
                    phone: phoneNumber.trim(),
                    roleId: accessRights,
                    createdAt: new Date(),
                    createdId: currentUser.id,
                };

                setIsSubmittingForm(true);
                await postNewPanelaUser(payload);
                resetFields();
                setIsSubmittingForm(false);
                onAbort();
            } else if (isEdit) {
                const payload = {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    username: username.trim(),
                    email: emailAddress.trim(),
                    position: position.trim(),
                    phone: phoneNumber.trim(),
                    roleId: accessRights,
                    updatedAt: new Date(),
                    updatedId: currentUser.id,
                };

                if (password !== '') {
                    payload.password = password;
                }

                setIsSubmittingForm(true);
                await updateUser(editableData.id, payload);
                snackbar.showSuccess('Update PIC Assist berhasil');
                getPanelaUserList();
                setIsSubmittingForm(false);
                onAbort();
            } else if (isAccount) {
                let isChangePassword;
                let isCheckPasswordValid;
                if (newPassword !== '' && password === '') {
                    snackbar.showError('Password lama harus diisi!');
                    return;
                } else if (newPassword !== '' && password !== '') {
                    isChangePassword = true;
                    console.log('check old password, before proceeding!');
                    const checkPasswordResult = await checkPassword(password);
                    isCheckPasswordValid = checkPasswordResult.data.success;
                    if (!isCheckPasswordValid) {
                        snackbar.showError('Password lama salah');
                        return
                    }
                }

                setIsSubmittingForm(true);

                let imageResult;
                if (newImg) {
                    const uploadImageResult = await uploadProfileImage(newImg);
                    imageResult = uploadImageResult.data;
                }

                const payload = {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    username: username.trim(),
                    email: emailAddress.trim(),
                    position: position.trim(),
                    phone: phoneNumber.trim(),
                    roleId: accessRights,
                    updatedAt: new Date(),
                    updatedId: currentUser.id,
                };

                if (imageResult) {
                    payload.profilePhoto = `https://s3-ap-southeast-1.amazonaws.com/kamarmedis-mr/${imageResult}`;
                }

                if (isChangePassword) {
                    payload.password = newPassword;
                }

                await updateUser(editableData.id, payload);
                snackbar.showSuccess('Update account berhasil');
                window.location.reload();
                setIsSubmittingForm(false);
                if (isChangePassword) {
                    setPassword('');
                    setNewPassword('');
                }
            }
        } catch (err) {
            console.error(err);
            snackbar.showError(err);
            setIsSubmittingForm(false);
        }
    };

    const handleImage = () => {
        const { current } = inputImageRef;
        const file = current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setProfilePhoto([reader.result]);
                setNewImg(file);
            };
        }
    };

    const classes = useStyles();

    return (
        <Grid container spacing={4} style={{overflowY: 'auto'}}>
            {
                isAccount
                    ? <Grid item xs={12}>
                        <div className={classes.profilePhotoContainer}>
                            <img
                                className={classes.profilePhoto}
                                src={profilePhoto === '' ? NoImage : profilePhoto}
                                alt="Profile"
                            />
                            <div className={classes.changePhotoIcon}>
                                <Tooltip title={"Click to change your avatar"} placement="right" style={{backgroundColor: 'rgba(0,0,255,0.1)'}}>
                                    <IconButton disabled={isSubmittingForm} onClick={() => {
                                        const { current } = inputImageRef;
                                        current.click();
                                    }}>
                                        <FontAwesomeIcon icon={faCamera} style={{color: '#2b64af', borderRadius: '100%'}} />
                                    </IconButton>
                                </Tooltip>

                                <input
                                    type="file"
                                    id="fileImg"
                                    accept="image/jpeg, image/png"
                                    ref={inputImageRef}
                                    style={{ display: 'none' }}
                                    onChange={handleImage}
                                />
                            </div>
                        </div>
                    </Grid>
                    : null
            }

            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>First Name</Typography>
                        <PInput disabled={isSubmittingForm} placeholder={"Firstname"} value={firstName} onChange={(e) => setFirstname(e.target.value)} fullWidth />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Last Name</Typography>
                        <PInput disabled={isSubmittingForm} placeholder={"Lastname"} value={lastName} onChange={(e) => setLastname(e.target.value)} fullWidth />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Username</Typography>
                        <PInput disabled={isSubmittingForm} placeholder={"Username"} value={username} onChange={(e) => setUsername(e.target.value)} fullWidth />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Email Address</Typography>
                        <PInput
                            disabled={isSubmittingForm}
                            placeholder={"Email Address"}
                            value={emailAddress}
                            onChange={(e) => setEmailAddress(e.target.value)}
                            fullWidth
                            error={!emailValid}
                            InputProps={{
                                endAdornment: !emailValid
                                    ? <InputAdornment position="end">
                                        <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
                                    </InputAdornment>
                                    : null
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {
                isAccount
                    ? <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Password</Typography>
                                    <PInput
                                        disabled={isSubmittingForm}
                                        placeholder={"Password"}
                                        type={hidePassword ? "password" : "text"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton style={{ width: 40, height: 40, padding: 0 }} onClick={() => setHidePassword(!hidePassword)}>
                                                    <FontAwesomeIcon icon={hidePassword ? faEye : faEyeSlash} style={{ width: 24, height: 24 }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>New Password</Typography>
                                    <PInput
                                        disabled={isSubmittingForm}
                                        placeholder={"New Password"}
                                        type={hideNewPassword ? "password" : "text"}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton style={{ width: 40, height: 40, padding: 0 }} onClick={() => setHideNewPassword(!hideNewPassword)}>
                                                    <FontAwesomeIcon icon={hideNewPassword ? faEye : faEyeSlash} style={{ width: 24, height: 24 }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Position</Typography>
                                    <PInput disabled={isSubmittingForm} placeholder={"Position"} value={position} onChange={(e) => setPosition(e.target.value)} fullWidth />
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Phone Number/WA</Typography>
                                    <PInput disabled={isSubmittingForm} placeholder={"Phone Number/WA"} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} fullWidth />
                                </Grid>
                            </Grid>
                        </Grid>

                        {
                            isManajemen && <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Access Rights</Typography>
                                    <PInput
                                        disabled={isSubmittingForm}
                                        placeholder={"Access Rights"}
                                        value={accessRights}
                                        onChange={(e) => setAccessRights(e.target.value)}
                                        select
                                        fullWidth
                                    >
                                        {
                                            userRoles.map(role => (
                                                <MenuItem key={role.id} value={role.id}>
                                                    {toTitleCase(role.role)}
                                                </MenuItem>
                                            ))
                                        }
                                    </PInput>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                    </React.Fragment>
                    // not is account
                    : <React.Fragment>
                        <Grid item lg={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6} md={6}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>
                                        {
                                            isEdit
                                                ? 'Ganti Password'
                                                : 'Password'
                                        }
                                    </Typography>
                                    <PInput
                                        disabled={isSubmittingForm}
                                        placeholder={isEdit ? "Ganti Password" : "Password"}
                                        type={hidePassword ? "password" : "text"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton style={{ width: 40, height: 40, padding: 0 }} onClick={() => setHidePassword(!hidePassword)}>
                                                    <FontAwesomeIcon icon={hidePassword ? faEye : faEyeSlash} style={{ width: 24, height: 24 }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6} md={6}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Phone Number/WA</Typography>
                                    <PInput disabled={isSubmittingForm} placeholder={"Phone Number/WA"} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} fullWidth />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6} md={6}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Position</Typography>
                                    <PInput disabled={isSubmittingForm} placeholder={"Position"} value={position} onChange={(e) => setPosition(e.target.value)} fullWidth />
                                </Grid>

                                <Grid item xs={6} md={6}>
                                    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Access Rights</Typography>
                                    <PInput
                                        disabled={isSubmittingForm}
                                        placeholder={"Access Rights"}
                                        value={accessRights}
                                        onChange={(e) => setAccessRights(e.target.value)}
                                        select
                                        fullWidth
                                    >
                                        {
                                            userRoles.map(role => (
                                                <MenuItem key={role.id} value={role.id}>
                                                    {toTitleCase(role.role)}
                                                </MenuItem>
                                            ))
                                        }
                                    </PInput>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
            }

            <Grid style={{ marginTop: 12, marginBottom: 20 }} container justify="flex-end" item lg={12}>
                {
                    !isAccount
                        ? <PBtn disabled={isSubmittingForm} style={{ marginRight: 8 }} dropShadow inverted onClick={onAbort}>Batalkan</PBtn>
                        : null
                }
                <PBtn disabled={isSubmittingForm} onClick={() => handleSave()}>Simpan</PBtn>
            </Grid>
        </Grid>
    );
};

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
});

const mapDispatch = ({ panelaUser: { postNewPanelaUser, getPanelaUserList } }) => ({
    postNewPanelaUser,
    getPanelaUserList,
});

export default connect(mapState, mapDispatch)(PICAssistForm);
