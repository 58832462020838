import React from "react";
import {
    Typography,
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
});

const BlockedFilter = ({ filterData, setFilterData, startFilter, clearFilter }) => {
    const classes = styles();

    function handleChangeFilter(e) {
        setFilterData({...filterData, [e.target.name]: e.target.value});
    }

    const handleChangeStartBlockDate = (date) => {
        filterData.tanggalMulaiBlock = date;
        setFilterData(prevState => ({
            ...prevState, tanggalMulaiBlock: filterData.tanggalMulaiBlock
        }));
    };

    const handleChangeEndBlockDate = (date) => {
        filterData.tanggalAkhirBlock = date;
        setFilterData(prevState => ({
            ...prevState, tanggalAkhirBlock: filterData.tanggalAkhirBlock
        }));
    };

    return(
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Dari Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalMulaiBlock}
                            onChange={handleChangeStartBlockDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Sampai Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalAkhirBlock}
                            onChange={handleChangeEndBlockDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Klinik</Typography>
                        <PInput
                            name="nama"
                            value={filterData.nama}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>PIC Klinik</Typography>
                        <PInput
                            name="picKlinik"
                            value={filterData.picKlinik}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Status</Typography>
                        <PInput
                            name="statusBlock"
                            value={filterData.statusBlock}
                            onChange={(e)=> handleChangeFilter(e)}
                            select
                            fullWidth
                        >
                            <MenuItem value={true}>Blocked</MenuItem>
                            <MenuItem value={false}>Unblocked</MenuItem>
                        </PInput>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Keterangan Block</Typography>
                        <PInput
                            name="keteranganBlock"
                            value={filterData.keteranganBlock}
                            onChange={(e)=> handleChangeFilter(e)}
                            multiline
                            rows={2}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={clearFilter}
                >
                    Reset
                </PBtn>
                <PBtn
                    onClick={startFilter}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default BlockedFilter;
