import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import format from "date-fns/format";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
        },
    },
});

const ProdukTrialItem = ({ page, rowsPerPage, isFetchingRetensionTrialProduct, retensionTrialProduct }) => {
    const classes = styles();
    const index = page * rowsPerPage;

    return (
        <React.Fragment>
            {
                isFetchingRetensionTrialProduct === true
                    ? <TableRow>
                        <TableCell colSpan={6}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress/>
                            </div>
                        </TableCell>
                    </TableRow>
                    : retensionTrialProduct.slice(index, index + rowsPerPage).map((item, idx) => (
                        <TableRow key={idx} className={classes.row}>
                            <TableCell className={classes.tableCellBody}>
                                {item.nama}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.alamat.city}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {`${item.durasi} ${item.satuanDurasi}`}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {format(new Date(item.tanggalMulai), "dd/MM/yyyy")}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {format(new Date(item.tanggalSelesai), "dd/MM/yyyy")}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.namaProduk}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.counterPraktek}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.counterStaff}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.counterMedicine}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.counterProcedure}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.counterJanji}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.counterTransaksiPasien}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.counterTransaksiApotek}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.counterTransaksiOffice}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterAkhp}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterAwalPemeriksaanKehamilan}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterDiagnosaBidan}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterDiagnosa}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterDoctorNote}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterFile}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterHasilLab}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterImunisasi}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterKegiatanKelompok}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterKesimpulan}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterMcuPlan}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterKmrProcedure}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterNurseNote}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterObgyn}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterOdontogram}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterOrder}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterOrderAssesment}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterOtherNote}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterPrescription}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterRiwayatKelahiran}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterRujukEksternalAssist}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterScourPrescription}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.EMR.counterVitalSign}
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
};

const mapState = ({ laporan }) => ({
    isFetchingRetensionTrialProduct: laporan.isFetchingRetensionTrialProduct,
    retensionTrialProduct: laporan.retensionTrialProduct,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(ProdukTrialItem);
