import React, {useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import KlinikFreeItem from "./KlinikFreeItem";

const styles = makeStyles({
    tableWrapper: {
        overflow: 'auto',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
});

const KlinikFreeTable = ({ fetchKlinikFreeList, klinikFreeListCounter }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        fetchKlinikFreeList();
    }, [fetchKlinikFreeList]);

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        fetchKlinikFreeList(skipLimit);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerPage,
        };
        fetchKlinikFreeList(skipLimit);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    return (
        <div>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader}>
                                Tanggal
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Nama Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Produk
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Aktif Hingga
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                PIC Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                No. Hp
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                SIUP
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 120, textAlign: 'center' }}>
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <KlinikFreeItem page={page} rowsPerPage={rowsPerPage}/>
                    </TableBody>
                </Table>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={klinikFreeListCounter}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

const mapState = ({ client }) => ({
    klinikFreeListCounter: client.klinikFreeListCounter,
});

const mapDispatch = ({ client: { fetchKlinikFreeList } }) => ({
    fetchKlinikFreeList,
});

export default connect(mapState, mapDispatch)(KlinikFreeTable);
