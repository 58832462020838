import * as XLSX from "xlsx";
import {dispatch} from "../../store";

export default function exportXL(fileName, data) {
    const { snackbar } = dispatch;

    if(data.length === 0) {
        snackbar.showError('Data Kosong!');
        return;
    }

    const ws = XLSX.utils.json_to_sheet(data);

    const wb = XLSX.utils.book_new();

    const wscol = new Array(18).fill({ wch: 30 }, 0, 18);

    ws['!cols'] = wscol;

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, `${fileName}.xlsx`);
}
