import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function AuthRoute({ component: Component, isAuthenticated, referrerUrl, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => {
                if (!isAuthenticated) {
                    return <Component {...props} />
                } else {
                    return <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                }
            }}
        />
    );
}

export default AuthRoute
