import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
        },
    },
});

const KlinikTerbanyakTicketItem = ({ page, rowsPerPage, isFetchingKlinikTerbanyakTicket, klinikTerbanyakTicket }) => {
    const classes = styles();
    const index = page * rowsPerPage;

    function handleRenderTicket(ticket) {
        return (
            <React.Fragment>
                {
                    ticket.data.map((item, idx) => (
                        <p key={idx}>{`${item.kategori}: ${item.count} Ticket`}</p>
                    ))
                }
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {
                isFetchingKlinikTerbanyakTicket === true
                    ? <TableRow>
                        <TableCell colSpan={3}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress/>
                            </div>
                        </TableCell>
                    </TableRow>
                    : klinikTerbanyakTicket.slice(index, index + rowsPerPage).map((item, idx) => (
                        <TableRow key={idx} className={classes.row}>
                            <TableCell className={classes.tableCellBody}>
                                {item.namaKlinik}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {`${item.countTotal} Ticket`}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {handleRenderTicket(item)}
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
}

const mapState = ({ laporan }) => ({
    isFetchingKlinikTerbanyakTicket: laporan.isFetchingKlinikTerbanyakTicket,
    klinikTerbanyakTicket: laporan.klinikTerbanyakTicket,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(KlinikTerbanyakTicketItem);
