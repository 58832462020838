import React, {useState, useEffect} from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import FreezeItem from "./FreezeItem";

const styles = makeStyles({
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
});

const FreezeTable = ({ freezeListCounter, fetchFreeze, setIsOpenFreezeForm, fetchFreezeFilter, isFilter, filterData, setFilterData, pageFilter }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        if(isFilter === true) {
            setPage(pageFilter);
            handleRenderFreezeFilter();
        } else {
            setPage(pageFilter);
            setRowsPerPage(5);
            fetchFreeze();
        }
    }, [fetchFreeze, isFilter, pageFilter]);

    const handleRenderFreezeFilter = () => {
        setFilterData(prevState => ({
            ...prevState, condition: false,
        }));
        fetchFreezeFilter(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        if(isFilter === true) {
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchFreezeFilter(filterData);
        } else {
            fetchFreeze(skipLimit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerpage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerpage,
        };
        if (isFilter === true) {
            filterData.skip = 0;
            filterData.limit = newRowsPerpage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchFreezeFilter(filterData);
        } else {
            fetchFreeze(skipLimit);
        }
        setRowsPerPage(newRowsPerpage);
        setPage(0);
    };

    return(
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableCellHeader}>
                        <span>Tanggal Freeze</span>
                    </TableCell>
                    <TableCell className={classes.tableCellHeader}>
                        <span>Nama Klinik</span>
                    </TableCell>
                    <TableCell className={classes.tableCellHeader}>
                        <span>Alasan</span>
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} style={{ minWidth: 120 }}>
                        <span>Status</span>
                    </TableCell>
                    <TableCell className={classes.tableCellHeader}>
                        Action
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <FreezeItem page={page} rowsPerPage={rowsPerPage} setIsOpenFreezeForm={setIsOpenFreezeForm}/>
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={5}
                        count={freezeListCounter}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    )
};

const mapState = ({ freeze }) => ({
    freezeListCounter: freeze.freezeListCounter,
});

const mapDispatch = ({ freeze: { fetchFreeze, fetchFreezeFilter } }) => ({
    fetchFreeze,
    fetchFreezeFilter,
});

export default connect(mapState, mapDispatch)(FreezeTable);
