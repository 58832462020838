import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import {getUserList} from "../../../api/panela-user";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        fontColor: 'black',
        marginBottom: 5,
    },
});

const PenawaranFilter = ({ filterData, setFilterData, startFilter, clearFilter }) => {
    const classes = styles();
    const [panelaUserList, setPanelaUserList] = useState([]);

    useEffect(()=> {
        (async function () {
            const result = await getUserList();
            setPanelaUserList(result.data);
        })()
    }, []);

    const handleChangeStartOfferDate = (date) => {
        filterData.tanggalPenawaranMulai = date;
        setFilterData(prevState => ({
            ...prevState, tanggalPenawaranMulai: filterData.tanggalPenawaranMulai
        }));
    };

    const handleChangeEndOfferDate = (date) => {
        filterData.tanggalPenawaranSelesai = date;
        setFilterData(prevState => ({
            ...prevState, tanggalPenawaranSelesai: filterData.tanggalPenawaranSelesai
        }));
    };

    function handleChangeFilter(e) {
        if(e.target.name === "termin"){
            if(e.target.value < 0){
                e.target.value = 0;
            }
        }
        setFilterData({...filterData, [e.target.name]: e.target.value});
    }

    const handleChangeStartPaymentDate = (date) => {
        filterData.batasPembayaranMulai = date;
        setFilterData(prevState => ({
            ...prevState, batasPembayaranMulai: filterData.batasPembayaranMulai
        }));
    };

    const handleChangeEndPaymentDate = (date) => {
        filterData.batasPembayaranSelesai = date;
        setFilterData(prevState => ({
            ...prevState, batasPembayaranSelesai: filterData.batasPembayaranSelesai
        }));
    };

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Dari Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalPenawaranMulai}
                            onChange={handleChangeStartOfferDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Sampai Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalPenawaranSelesai}
                            onChange={handleChangeEndOfferDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Klinik</Typography>
                        <PInput
                            name="nama"
                            value={filterData.nama}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>PIC Klinik</Typography>
                        <PInput
                            name="picKlinik"
                            value={filterData.picKlinik}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Harga Penawaran</Typography>
                        <PInput
                            name="hargaPenawaran"
                            value={filterData.hargaPenawaran}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Termin</Typography>
                        <PInput
                            name="termin"
                            value={filterData.termin}
                            onChange={(e)=> handleChangeFilter(e)}
                            type="number"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Pembayaran Dari Tanggal</Typography>
                        <DatePicker
                            value={filterData.batasPembayaranMulai}
                            onChange={handleChangeStartPaymentDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Pembayaran Sampai Tanggal</Typography>
                        <DatePicker
                            value={filterData.batasPembayaranSelesai}
                            onChange={handleChangeEndPaymentDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>PIC Assist.id</Typography>
                        <PInput
                            name="picAssist"
                            value={filterData.picAssist}
                            onChange={(e)=> handleChangeFilter(e)}
                            select
                            fullWidth
                        >
                            {
                                panelaUserList.map(user => (
                                    <MenuItem key={user.id} value={`${user.firstName} ${user.lastName}`}>
                                        {`${user.firstName} ${user.lastName}`}
                                    </MenuItem>
                                ))
                            }
                        </PInput>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={clearFilter}
                >
                    Reset
                </PBtn>
                <PBtn
                    onClick={startFilter}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default PenawaranFilter;




