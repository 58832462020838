import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {dispatch} from "../../../store";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import {addNewIklan, updateIklan} from "../../../api/iklan";

const styles = makeStyles({
    label: {
        color: 'black',
        fontWeight: 'bold',
        marginBottom: 10,
    },
});

const IklanForm = ({ selectedIklanList, onClose, currentUser, fetchIklanList }) => {
    const classes = styles();
    const isEdit = typeof selectedIklanList.id !== 'undefined';
    const [namaIklan, setNamaIklan] = useState(selectedIklanList.name ? selectedIklanList.name : '');
    const [letakIklan, setLetakIklan] = useState(selectedIklanList.adsPosition ? selectedIklanList.adsPosition : '');
    const [isActive, setIsActive] = useState(selectedIklanList.isActive ? selectedIklanList.isActive : true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    async function handleSimpan() {
        const { snackbar } = dispatch;
        const trimmedNama = namaIklan.trim();
        const trimmedLetak = letakIklan.trim();
        if(trimmedNama === '') {
            snackbar.showError("Nama iklan harus diisi!");
        } else if(trimmedLetak === '') {
            snackbar.showError("Letak iklan harus diisi!");
        } else {
            if(!isEdit) {
                try {
                    const payload = {
                        isActive: true,
                        name: namaIklan,
                        adsPosition: letakIklan,
                        createdAt: new Date(),
                        createdId: currentUser.id,
                    };
                    setIsSubmitting(true);
                    await addNewIklan(payload);
                    snackbar.showSuccess("Berhasil menambahkan iklan");
                    setIsSubmitting(false);
                    onClose();
                    fetchIklanList();
                } catch (e) {
                    console.error(e);
                    snackbar.showError("Gagal menambahkan iklan");
                    setIsSubmitting(false);
                }
            } else {
                try {
                    const payload = {
                        isActive: isActive,
                        name: namaIklan,
                        adsPosition: letakIklan,
                        updatedAt: new Date(),
                        updatedId: currentUser.id,
                    };
                    setIsSubmitting(true);
                    await updateIklan(selectedIklanList.id, payload);
                    snackbar.showSuccess("Berhasil update iklan");
                    setIsSubmitting(false);
                    onClose();
                    fetchIklanList();
                } catch (e) {
                    console.error(e);
                    snackbar.showError("Gagal mengupdate iklan");
                    setIsSubmitting(false);
                }
            }
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                    <Typography className={classes.label}>
                        Nama Iklan *
                    </Typography>
                    <PInput
                        value={namaIklan}
                        onChange={(e)=> setNamaIklan(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 24 }}>
                    <Typography className={classes.label}>
                        Letak Iklan *
                    </Typography>
                    <PInput
                        value={letakIklan}
                        onChange={(e)=> setLetakIklan(e.target.value)}
                        fullWidth
                    />
                </Grid>
                {isEdit && <Grid item xs={12} style={{ marginBottom: 24 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isActive}
                                onChange={(e)=> setIsActive(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Aktif"
                        labelPlacement="end"
                    />
                </Grid>}
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={onClose}
                >
                    Batal
                </PBtn>
                <PBtn
                    disabled={isSubmitting}
                    onClick={()=> handleSimpan()}
                >
                    {!isSubmitting ? 'Simpan' : <FontAwesomeIcon icon={faSpinner} spin/>}
                </PBtn>
            </Grid>
        </Grid>
    );
};

const mapState = ({ iklan, user }) => ({
    selectedIklanList: iklan.selectedIklanList,
    currentUser: user.currentUser,
});

const mapDispatch = ({ iklan: { fetchIklanList } }) => ({
    fetchIklanList,
});

export default connect(mapState, mapDispatch)(IklanForm);
