import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress  from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import format from "date-fns/format";
import localeId from "date-fns/locale/id";
import differenceInDay from "date-fns/differenceInDays";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import PBtn from "../../share/PBtn";

import OverdueFreezeForm from "./OverdueFreezeForm";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
});

const OverdueItem = ({ page, rowsPerPage, isFetchingOverdueList, overdueList, updateSelectedOverdueList }) => {
    const classes = styles();
    const index = page * rowsPerPage;
    const [isOpenOverdueFreezeForm, setIsOpenOverdueFreezeForm] = useState(false);

    function handleOpenOverdueFreezeForm(item) {
        setIsOpenOverdueFreezeForm(true);
        updateSelectedOverdueList(JSON.parse(JSON.stringify(item)));

    }

    function handleCloseOverdueFreezeForm() {
        setIsOpenOverdueFreezeForm(false);
        updateSelectedOverdueList({});
    }

    return (
        <React.Fragment>
            <Dialog open={isOpenOverdueFreezeForm} maxWidth="xs" onClose={()=> handleCloseOverdueFreezeForm()} fullWidth>
                <DialogContent>
                    <OverdueFreezeForm onClose={()=> handleCloseOverdueFreezeForm()}/>
                </DialogContent>
            </Dialog>
            {
                isFetchingOverdueList === true
                    ? <TableRow>
                        <TableCell colSpan={3}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        </TableCell>
                    </TableRow>
                    : overdueList.slice(index, index + rowsPerPage).map((item, idx) => (
                        <TableRow key={idx} className={classes.row}>
                            <TableCell className={classes.tableCellBody}>
                                {item.nama}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {
                                    item.KConfig.activeBilling.nextDueDate
                                        ? format(new Date(item.KConfig.activeBilling.nextDueDate), "dd MMMM yyyy", {locale: localeId})
                                        : '-'
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                {
                                    item.KConfig.activeBilling.isActive === false
                                        ? 'Account has been freeze'
                                        : differenceInDay(new Date(item.KConfig.activeBilling.Billing.endSubs), new Date()) < 0
                                        ? <PBtn
                                            onClick={()=> handleOpenOverdueFreezeForm(item)}
                                        >
                                            Set Freeze
                                        </PBtn>
                                        : <PBtn
                                            inverted
                                            dropShadow
                                        >
                                            {`${differenceInDay(new Date(item.KConfig.activeBilling.Billing.endSubs), new Date())} Hari`}
                                        </PBtn>
                                }
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
};

const mapState = ({ client }) => ({
    isFetchingOverdueList: client.isFetchingOverdueList,
    overdueList: client.overdueList,
});

const mapDispatch = ({ client: { updateSelectedOverdueList } }) => ({
    updateSelectedOverdueList,
});

export default connect(mapState, mapDispatch)(OverdueItem);
