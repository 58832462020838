import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import Header from "../faskes/templateFaskes/header/header";
import SubMenuBtn from "../share/SubMenuBtn";

import Data from "./sub-data/Data";
import Referral from "./referral/Referral";

const styles = makeStyles({
    content: {
        width: '100%',
    },
    body: {
        width: '100%',
        height: '85%',
        position: 'absolute',
        marginTop: '7%',
        bottom: 0,
        backgroundColor: '#f5f6f8',
        overflowY: 'auto',
        '@media only screen and (max-width:994px)': {
            height: '90%',
            width: '1440px',
        },
    },
    container: {
        padding: '25px 20px',
        '@media only screen and (max-width:994px)': {
            padding: '4% 20px',
        },
        '@media only screen and (max-width:400px)': {
            padding: '4% 20px',
        },
    },
    menu: {
        marginRight: 64,
        marginTop: -10,
        backgroundColor: "#f5f6f8",
        '&:hover' : {
            backgroundColor: "#f5f6f8",
        },
        fontFamily: 'Nunito',
        fontSize: '18px',
        fontWeight: 600,
    },
});

const DataHome = ({ isViewedByMobile }) => {
    const classes = styles();
    const [subMenu, setSubMenu] = useState(1);

    let subMenuContent;
    if(subMenu === 1) {
        subMenuContent = <Data/>
    } else if(subMenu === 2) {
        subMenuContent = <Referral/>
    }

    return (
        <div className={classes.content}>
            <Header title={'Data'}/>
            <div className={classes.body} style={isViewedByMobile ? {height: '95%'} : {height: '85%'}}>
                <Container className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <SubMenuBtn
                                isActive={subMenu === 1}
                                onClick={()=> setSubMenu(1)}
                                className={classes.menu}
                                inverted
                            >Data</SubMenuBtn>

                            <SubMenuBtn
                                isActive={subMenu === 2}
                                onClick={()=> setSubMenu(2)}
                                className={classes.menu}
                                inverted
                            >Referral</SubMenuBtn>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {subMenuContent}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

const mapState = ({ app }) => ({
    isViewedByMobile: app.isViewedByMobile,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(DataHome);
