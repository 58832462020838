import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import PICAssistForm from './PICAssistForm';

const styles = makeStyles({
    titleDialog: {
        fontFamily: "Nunito",
        fontSize: "20px",
        color: "#15162d",
        fontWeight: "600",
        marginTop: "10px",
        marginLeft: "0px",
    }
});

export default function ({ isEdit, editableData, ...props }) {
    const onAbort = props.onClose;
    const classes = styles();

    return (
        <Dialog {...props}>
            <DialogTitle>
                <Typography className={classes.titleDialog}>{isEdit ? "Edit PIC Assist.id" : "Tambah PIC Assist.id"}</Typography>
            </DialogTitle>
            <DialogContent style={{marginTop: 10}}>
                <PICAssistForm onAbort={onAbort} isEdit={isEdit} editableData={editableData} />
            </DialogContent>
        </Dialog>
    )
}
