import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    tooltip: {
        backgroundColor: 'grey',
        color: 'white',
        fontSize: '15px',
        boxShadow: '0 8px 6px -6px black',
    },
});

export default function ({ title, position, children, ...props }) {
    const classes = useStyles();

    return (
        <Tooltip title={title} placement={position} TransitionComponent={Zoom} classes={{ tooltip: classes.tooltip }} {...props}>
            {children}
        </Tooltip>
    );
}
