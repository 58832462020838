import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";

import ActionCard from "../../share/ActionCard";

import AcquisitionItem from "./AcquisitionItem";

const Acquisition = () => {
    const [isPage, setIsPage] = useState(0);
    const [acquisitionName, setAcquisitionName] = useState('');

    function handleClick(page, name) {
        setIsPage(page);
        setAcquisitionName(name);
    }

    return (
        <Grid container>
            {
                isPage === 0
                    ? <Grid container spacing={4} item xs={12} style={{ marginTop: 50 }}>
                        <Grid item xs={6}>
                            <ActionCard
                                label='Berdasarkan Ada Anomali'
                                subLabel='Laporan acquisition berdasarkan ada anomali'
                                handleClick={()=> handleClick(1, 'Ada Anomali')}
                            />
                            <ActionCard
                                label='Berdasarkan Hotness Level'
                                subLabel='Laporan acquisition berdasarkan hotness level'
                                handleClick={()=> handleClick(1, 'Hotness Level')}
                            />
                            <ActionCard
                                label='Berdasarkan Bekerjasama Dengan BPJS'
                                subLabel='Laporan acquisition berdasarkan bekerjasama dengan BPJS'
                                handleClick={()=> handleClick(1, 'Bekerjasama Dengan BPJS')}
                            />
                            <ActionCard
                                label='Berdasarkan Ada Yang Mereferralkan'
                                subLabel='Laporan acquisition berdasarkan ada yang mereferralkan'
                                handleClick={()=> handleClick(1, 'Ada Yang Mereferralkan')}
                            />
                            <ActionCard
                                label='Berdasarkan Kategori Reject'
                                subLabel='Laporan acquisition berdasarkan kategori reject'
                                handleClick={()=> handleClick(1, 'Kategori Reject')}
                            />
                            <ActionCard
                                label='Berdasarkan Leads Info'
                                subLabel='Laporan acquisition berdasarkan leads info'
                                handleClick={()=> handleClick(1, 'Leads Info')}
                            />
                            <ActionCard
                                label='Berdasarkan Nama Kompetitor'
                                subLabel='Laporan acquisition berdasarkan nama kompetitor'
                                handleClick={()=> handleClick(1, 'Nama Kompetitor')}
                            />
                            <ActionCard
                                label='Berdasarkan PIC Assist.id'
                                subLabel='Laporan acquisition berdasarkan pic assist.id'
                                handleClick={()=> handleClick(1, 'PIC Assist.id')}
                            />
                            <ActionCard
                                label='Berdasarkan Potensi Jenis Produk Closing'
                                subLabel='Laporan acquisition berdasarkan potensi jenis produk closing'
                                handleClick={()=> handleClick(1, 'Potensi Jenis Produk Closing')}
                            />
                            <ActionCard
                                label='Berdasarkan Sedang Ada Iklan'
                                subLabel='Laporan acquisition berdasarkan ada iklan atau tidak'
                                handleClick={()=> handleClick(1, 'Sedang Ada Iklan')}
                            />
                            <ActionCard
                                label='Berdasarkan Status Berlangganan'
                                subLabel='Laporan acquisition berdasarkan status berlangganan'
                                handleClick={()=> handleClick(1, 'Status Berlangganan')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ActionCard
                                label='Berdasarkan Ada Custom'
                                subLabel='Laporan acquisition berdasarkan ada custom'
                                handleClick={()=> handleClick(1, 'Ada Custom')}
                            />
                            <ActionCard
                                label='Berdasarkan Sudah Menggunakan Sistem Sebelumnya'
                                subLabel='Laporan acquisition berdasarkan sudah menggunakan sistem sebelumnya'
                                handleClick={()=> handleClick(1, 'Sudah Menggunakan Sistem Sebelumnya')}
                            />
                            <ActionCard
                                label='Berdasarkan Ada Apotek'
                                subLabel='Laporan acquisition berdasarkan ada apotek'
                                handleClick={()=> handleClick(1, 'Ada Apotek')}
                            />
                            <ActionCard
                                label='Berdasarkan Jenis Faskes'
                                subLabel='Laporan acquisition berdasarkan jenis faskes'
                                handleClick={()=> handleClick(1, 'Jenis Faskes')}
                            />
                            <ActionCard
                                label='Berdasarkan Kontak Status'
                                subLabel='Laporan acquisition berdasarkan kontak status'
                                handleClick={()=> handleClick(1, 'Kontak Status')}
                            />
                            <ActionCard
                                label='Berdasarkan Level Kompetitor'
                                subLabel='Laporan acquisition berdasarkan level kompetitor'
                                handleClick={()=> handleClick(1, 'Level Kompetitor')}
                            />
                            <ActionCard
                                label='Berdasarkan Operational Status'
                                subLabel='Laporan acquisition berdasarkan operational status'
                                handleClick={()=> handleClick(1, 'Operational Status')}
                            />
                            <ActionCard
                                label='Berdasarkan Potensi Durasi Produk Closing'
                                subLabel='Laporan acquisition berdasarkan potensi durasi produk closing'
                                handleClick={()=> handleClick(1, 'Potensi Durasi Produk Closing')}
                            />
                            <ActionCard
                                label='Berdasarkan Potensi Kompetitor'
                                subLabel='Laporan acquisition berdasarkan potensi kompetitor'
                                handleClick={()=> handleClick(1, 'Potensi Kompetitor')}
                            />
                            <ActionCard
                                label='Berdasarkan Sistem Yang Pernah Digunakan'
                                subLabel='Laporan acquisition berdasarkan sistem yang pernah digunakan'
                                handleClick={()=> handleClick(1, 'Sistem Yang Pernah Digunakan')}
                            />
                            <ActionCard
                                label='Berdasarkan Status Saat Ini'
                                subLabel='Laporan acquisition berdasarkan status saat ini'
                                handleClick={()=> handleClick(1, 'Status Saat Ini')}
                            />
                        </Grid>
                    </Grid>
                    : <AcquisitionItem acquisitionName={acquisitionName} setIsPage={setIsPage}/>
            }
        </Grid>
    );
};

export default Acquisition;
