import React, { useState } from 'react';
import {connect} from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from "react-number-format";

import format from 'date-fns/format';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faQuestionCircle, faCertificate} from "@fortawesome/free-solid-svg-icons";
import Confirmation from "../../share/Confirmation";
// import StatusPaymentChanger from "./StatusPaymentChanger";
// import {deletePenolakan} from "../../../api/activity";
// import {dispatch} from "../../../store";
import {toTitleCase} from "../../../helpers/capitalize";
import CircularProgress from "@material-ui/core/CircularProgress";
import {confirmOfferToAssist, deleteOfferToAssist} from "../../../api/offer";
import {dispatch} from "../../../store";

const useStyles = makeStyles({
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
});

const PenawaranItem = (
    { offerList,
        setIsOpenAddNew,
        updateSelectedOffer,
        isFetchingOfferList,
        page,
        rowsPerPage,
        fetchOffers,
        flagSkipLimit,
        selectedOffer,
    }) => {
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isShowConfirmation, setIsShowConfirmation] = useState(false);
    // const [selectedRejection, setSelectedRejection] = useState(undefined);
    // const [isDeleting, setIsDeleting] = useState(false);

    // function handleEdit(rejection) {
    //     setIsOpenAddNew(true);
    //     updateSelectedRejection({...rejection});
    // }

    function handleShowPrintView(offer) {
        setIsOpenAddNew(true);
        updateSelectedOffer(JSON.parse(JSON.stringify(offer)));
    }

    async function handleConfirmOfferToAssist(offer) {
        const { snackbar } = dispatch;
        if(offer.HealthFacility.assistHospitalId && offer.HealthFacility.assistHospitalId !== ""){
            if(offer.offerStatus === "closing"){
                try{
                    setIsSubmitting(true);
                    await confirmOfferToAssist(offer.id);
                    snackbar.showSuccess("Berhasil konfirmasi penawaran untuk ditampilkan di billing sistem app.assist.id");
                    setIsSubmitting(false);
                    setIsShowConfirmation(false);
                    updateSelectedOffer({});
                    fetchOffers(flagSkipLimit);
                } catch (e) {
                    console.error(e);
                    snackbar.showError("Gagal konfirmasi penawaran ditampilkan di billing sistem app.assist.id");
                    setIsSubmitting(false);
                    setIsShowConfirmation(false);
                    updateSelectedOffer({});
                    fetchOffers(flagSkipLimit);
                }
            } else {
                snackbar.showError("Status penawaran belum closing, silahkan update terlebih dahulu");
                setIsShowConfirmation(false);
                updateSelectedOffer({});
            }
        } else {
            snackbar.showError("Faskes belum terhubung dengan sistem app.assist.id");
            setIsShowConfirmation(false);
            updateSelectedOffer({});
        }
    }

    async function handleDeleteOfferToAssist(offer) {
        const { snackbar } = dispatch;
        try{
            setIsSubmitting(true);
            await deleteOfferToAssist(offer.id);
            snackbar.showSuccess("Berhasil Hapus Penawaran dari billing sistem app.assist.id");
            setIsSubmitting(false);
            setIsShowConfirmation(false);
            updateSelectedOffer({});
            fetchOffers(flagSkipLimit);
        } catch (e) {
            console.error(e);
            snackbar.showError("Gagal menghapus penawaran dari billing sistem app.assist.id");
            setIsSubmitting(false);
            setIsShowConfirmation(false);
            updateSelectedOffer({});
            fetchOffers(flagSkipLimit);
        }
    }

    // async function handleDelete() {
    //     const { snackbar } = dispatch;
    //     try {
    //         setIsDeleting(true);
    //         await deletePenolakan(selectedRejection.id);
    //         setIsDeleting(false);
    //         snackbar.showSuccess('Berhasil menghapus penolakan');
    //         fetchRejections();
    //         setShowDeleteConfirmation(false);
    //     } catch (err) {
    //         console.error(err);
    //         setIsDeleting(false);
    //         setShowDeleteConfirmation(false);
    //         snackbar.showError('Gagal menghapus penolakan');
    //     }
    // }

    const index = page * rowsPerPage;

    return (
        <React.Fragment>
            <Confirmation
                contentText={
                    selectedOffer.createdAssistOfferId
                        ? "Apakah anda yakin akan membatalkannya?"
                        : "Apakah anda yakin ingin menampilkannya?"}
                textButtonConfirmation={"Iya"}
                open={isShowConfirmation}
                onClose={() => {
                    setIsShowConfirmation(false);
                    updateSelectedOffer({});
                }}
                onSubmit={() => {
                    selectedOffer.createdAssistOfferId
                        ? handleDeleteOfferToAssist(selectedOffer)
                        : handleConfirmOfferToAssist(selectedOffer)
                }}
            />
            {
                isFetchingOfferList === true
                    ? <TableRow>
                        <TableCell colSpan={6}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        </TableCell>
                    </TableRow>
                    : <React.Fragment>
                        {
                            offerList.slice(index, index + rowsPerPage).map(offer => (
                                <TableRow
                                    className={classes.row}
                                    key={offer.id}
                                    onClick={() => handleShowPrintView(offer)}
                                >
                                    <TableCell className={classes.tableCellBody}>
                                        <span>{format(new Date(offer.offerDate), 'dd/MM/yyyy')}</span>
                                    </TableCell>

                                    <TableCell className={classes.tableCellBody}>
                                        {
                                            offer.HealthFacility.assistHospitalId && offer.HealthFacility.assistHospitalId !== ""
                                                ? <span>
                                                <Tooltip title={`Terhubung dengan akun ${offer.HealthFacility.assistHospitalName} di app.assist.id`}>
                                                    <IconButton size={"small"}>
                                                        <FontAwesomeIcon icon={faCertificate} style={{ color: "#FFD700", width: 15, height: 15 }}/>
                                                    </IconButton>
                                                </Tooltip>
                                                {offer.HealthFacility.name}
                                                </span> : <span>{offer.HealthFacility.name}</span>
                                        }
                                    </TableCell>

                                    <TableCell className={classes.tableCellBody}>
                                        <span>{offer.healthFacilityPic.namepic}</span>
                                    </TableCell>

                                    <TableCell className={classes.tableCellBody}>
                                        <NumberFormat
                                            displayType="text"
                                            value={offer.offerProductDetail.grandTotal}
                                            prefix="Rp. "
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    </TableCell>

                                    <TableCell className={classes.tableCellBody}>
                                        <span>{offer.terminList.length === 0 ? '1/1' : `${offer.terminList.length}/${offer.terminList.length}`}</span>
                                    </TableCell>

                                    <TableCell className={classes.tableCellBody}>
                            <span>
                                {
                                    offer.paymentDate
                                        ? format(new Date(offer.paymentDate), 'dd/MM/yyyy')
                                        : '-'
                                }
                            </span>
                                    </TableCell>

                                    <TableCell className={classes.tableCellBody}>
                                        <span>{offer.picAssistFullName}</span>
                                    </TableCell>

                                    <TableCell className={classes.tableCellBody} style={{minWidth: 100}}>
                                        {/*<div>*/}
                                        {/*    <span>{toTitleCase(offer.paymentStatus)}</span>*/}
                                        {/*    <StatusPaymentChanger offer={offer} />*/}
                                        {/*</div>*/}
                                        <span>{toTitleCase(offer.offerStatus)}</span>
                                    </TableCell>

                                    <TableCell>
                                        {
                                            offer.createdAssistOfferId
                                                ? <span>
                                                <Tooltip title="Penawaran sudah ditampilkan di billing sistem app.assist.id, ingin membatalkan?">
                                                    <IconButton onClick={(e)=> {
                                                        e.stopPropagation();
                                                        updateSelectedOffer(JSON.parse(JSON.stringify(offer)));
                                                        setIsShowConfirmation(true);
                                                    }} disabled={isSubmitting}>
                                                        <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#2b64af' }}/>
                                                    </IconButton>
                                                </Tooltip>
                                                </span>
                                                : <span>
                                                <Tooltip title="Tampilkan di billing sistem app.assist.id?">
                                                    <IconButton onClick={(e)=> {
                                                        e.stopPropagation();
                                                        updateSelectedOffer(JSON.parse(JSON.stringify(offer)));
                                                        setIsShowConfirmation(true);
                                                    }} disabled={isSubmitting}>
                                                        <FontAwesomeIcon icon={faQuestionCircle} style={{ color: '#2b64af' }}/>
                                                    </IconButton>
                                                </Tooltip>
                                                </span>
                                        }
                                    </TableCell>
                                    {/*<TableCell>*/}
                                    {/*    <IconButton disabled={isDeleting} onClick={() => handleEdit(offer)}>*/}
                                    {/*        <FontAwesomeIcon icon={faEdit} style={{ color: 'green', width: 24, height: 24 }} />*/}
                                    {/*    </IconButton>*/}

                                    {/*    <IconButton*/}
                                    {/*        disabled={isDeleting}*/}
                                    {/*        onClick={() => {*/}
                                    {/*            setShowDeleteConfirmation(true);*/}
                                    {/*            setSelectedRejection(offer);*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        <FontAwesomeIcon icon={faTrash} style={{ color: 'red', width: 24, height: 24 }} />*/}
                                    {/*    </IconButton>*/}
                                    {/*</TableCell>*/}
                                </TableRow>
                            ))
                        }
                    </React.Fragment>
            }
        </React.Fragment>
    );
};

const mapState = ({ activity }) => ({
    offerList: activity.offerList,
    isFetchingOfferList: activity.isFetchingOfferList,
    selectedOffer: activity.selectedOffer,
});

const mapDispatch = ({ activity: { updateSelectedOffer, fetchOffers } }) => ({
    updateSelectedOffer,
    fetchOffers,
});

export default connect(mapState, mapDispatch)(PenawaranItem);
