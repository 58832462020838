import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";

import ActionCard from "../../share/ActionCard";

import ConversionItem from "./ConversionItem";

const Conversion = () => {
    const [isPage, setIsPage] = useState(0);
    const [conversionName, setConversionName] = useState('');

    function handleClick(page, name) {
        setIsPage(page);
        setConversionName(name);
    }

    return (
        <Grid container>
            {
                isPage === 0
                    ? <Grid container spacing={4} item xs={12} style={{ marginTop: 50 }}>
                        <Grid item xs={6}>
                            <ActionCard
                                label='Berdasarkan Ada Custom'
                                subLabel='laporan conversion berdasarkan ada custom'
                                handleClick={()=> handleClick(1, 'Ada Custom')}
                            />
                            <ActionCard
                                label='Berdasarkan Ada Iklan'
                                subLabel='laporan conversion berdasarkan ada iklan'
                                handleClick={()=> handleClick(1, 'Ada Iklan')}
                            />
                            <ActionCard
                                label='Berdasarkan Kontak Status'
                                subLabel='laporan conversion berdasarkan kontak status'
                                handleClick={()=> handleClick(1, 'Kontak Status')}
                            />
                            <ActionCard
                                label='Berdasarkan Durasi Produk'
                                subLabel='laporan conversion berdasarkan durasi produk'
                                handleClick={()=> handleClick(1, 'Durasi Produk')}
                            />
                            <ActionCard
                                label='Berdasarkan Hotness Level'
                                subLabel='laporan conversion berdasarkan hotness level'
                                handleClick={()=> handleClick(1, 'Hotness Level')}
                            />
                            <ActionCard
                                label='Berdasarkan Jenis Faskes'
                                subLabel='laporan conversion berdasarkan jenis faskes'
                                handleClick={()=> handleClick(1, 'Jenis Faskes')}
                            />
                            <ActionCard
                                label='Berdasarkan Jenis Produk'
                                subLabel='laporan conversion berdasarkan jenis produk'
                                handleClick={()=> handleClick(1, 'Jenis Produk')}
                            />
                            <ActionCard
                                label='Berdasarkan Kategori Reject'
                                subLabel='laporan conversion berdasarkan kategori reject'
                                handleClick={()=> handleClick(1, 'Kategori Reject')}
                            />
                            <ActionCard
                                label='Berdasarkan Bekerjasama Dengan BPJS'
                                subLabel='laporan conversion berdasarkan bekerjasama dengan bpjs'
                                handleClick={()=> handleClick(1, 'Bekerjasama Dengan BPJS')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ActionCard
                                label='Berdasarkan Kota'
                                subLabel='laporan conversion berdasarkan kota'
                                handleClick={()=> handleClick(1, 'Kota')}
                            />
                            <ActionCard
                                label='Berdasarkan Provinsi'
                                subLabel='laporan conversion berdasarkan provinsi'
                                handleClick={()=> handleClick(1, 'Provinsi')}
                            />
                            <ActionCard
                                label='Berdasarkan Range Kapitasi'
                                subLabel='laporan conversion berdasarkan range kapitasi'
                                handleClick={()=> handleClick(1, 'Range Kapitasi')}
                            />
                            <ActionCard
                                label='Berdasarkan Range Kunjungan Bulanan'
                                subLabel='laporan conversion berdasarkan range kunjungan bulanan'
                                handleClick={()=> handleClick(1, 'Range Kunjungan Bulanan')}
                            />
                            <ActionCard
                                label='Berdasarkan Referral'
                                subLabel='laporan conversion berdasarkan referral'
                                handleClick={()=> handleClick(1, 'Referral')}
                            />
                            <ActionCard
                                label='Berdasarkan Status Operational'
                                subLabel='laporan conversion berdasarkan status operational'
                                handleClick={()=> handleClick(1, 'Status Operational')}
                            />
                            <ActionCard
                                label='Berdasarkan Sudah Menggunakan Sistem Sebelumnya'
                                subLabel='laporan conversion berdasarkan sudah menggunakan sistem sebelumnya'
                                handleClick={()=> handleClick(1, 'Sudah Menggunakan Sistem Sebelumnya')}
                            />
                            <ActionCard
                                label='Berdasarkan Tersedia Apotek'
                                subLabel='laporan conversion berdasarkan tersedia apotek'
                                handleClick={()=> handleClick(1, 'Tersedia Apotek')}
                            />
                            <ActionCard
                                label='Berdasarkan Cycle'
                                subLabel='laporan conversion berdasarkan cycle'
                                handleClick={()=> handleClick(1, 'Cycle')}
                            />
                        </Grid>
                    </Grid>
                    : <ConversionItem conversionName={conversionName} setIsPage={setIsPage}/>
            }
        </Grid>
    )
};

export default Conversion;
