import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {DatePicker} from "@material-ui/pickers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faCalendarAlt, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import Autocomplete from "../../utils/Autocomplete";
import {faskesFetcherByNamev2} from "../../../helpers/faskesFetcherByName";
import {connect} from "react-redux";

import {addNewFreeze, updateFreeze, countFreezeByFaskesId} from "../../../api/freeze";

import format from "date-fns/format";
import idLocale from "date-fns/locale/id";
import {dispatch} from "../../../store";

const styles = makeStyles({
    label: {
        color: 'black',
        fontWeight: 'bold',
        marginBottom: 10,
    },
});

const FreezeForm = ({ onClose, currentUser, selectedFreeze, fetchFreeze }) => {
    const classes = styles();
    const { HealthFacility } = selectedFreeze;
    const isEdit = typeof selectedFreeze.id !== 'undefined';
    const [selectedFaskes, setSelectedFaskes] = useState(HealthFacility ? HealthFacility : null);
    const [freezeDate, setFreezeDate] = useState(selectedFreeze.freezeDate ? new Date(selectedFreeze.freezeDate) : new Date());
    const [reason, setReason] = useState(selectedFreeze.reason ? selectedFreeze.reason : '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [keyFaskesAutoComplete, setKeyFaskesAutoComplete] = useState(0);

    function handleDeleteFaskes(){
        setSelectedFaskes(null);
    }

    async function onSelectFaskes(item){
        const { snackbar } = dispatch;
        try{
            const result = await countFreezeByFaskesId(item.id);
            if(result.data.count > 0){
                snackbar.showError("Faskes yang di pilih sudah ada");
                setSelectedFaskes(null);
                setKeyFaskesAutoComplete(keyFaskesAutoComplete + 1);
            } else if(item.assistHospitalId && item.assistHospitalId !== ""){
                setSelectedFaskes(item);
            } else if(item.picId === "m4n4j3m3n") {
                dispatch.snackbar.showError("PIC Assist.id untuk leads ini belum ditentukan");
                setKeyFaskesAutoComplete(keyFaskesAutoComplete + 1);
            } else {
                snackbar.showError("Faskes belum memiliki faskes assist.id");
                setSelectedFaskes(null);
                setKeyFaskesAutoComplete(keyFaskesAutoComplete + 1);
            }
        } catch (e) {
            console.error(e);
            snackbar.showError("Gagal cek faskes");
        }
    }

    const handleChangeFreezeDate = (date) => {
        setFreezeDate(date);
    };

    async function handleSimpan() {
        const { snackbar } = dispatch;
        if(!isEdit){
            try{
                const payload = {
                    healthFacilityId: selectedFaskes.id,
                    freezeDate: freezeDate,
                    reason: reason,
                    status: 0,
                    isRequestFreeze: false,
                    isRequestUnfreeze: false,
                    reasonUnfreeze: "",
                    createdAt: new Date(),
                    createdId: currentUser.id,
                };
                setIsSubmitting(true);
                await addNewFreeze(payload);
                snackbar.showSuccess("Berhasil menambahkan freeze baru");
                setIsSubmitting(false);
                onClose();
                fetchFreeze();
            } catch (e) {
                console.error(e);
                snackbar.showError("Gagal menambahkan freeze baru");
                setIsSubmitting(false);
            }
        } else {
            try {
                const payload = {
                    healthFacilityId: selectedFaskes.id,
                    freezeDate: freezeDate,
                    reason: reason,
                    status: selectedFreeze.status,
                    updatedAt: new Date(),
                    updatedId: currentUser.id,
                };
                setIsSubmitting(true);
                await updateFreeze(selectedFreeze.id, payload);
                snackbar.showSuccess("Berhasil update freeze");
                setIsSubmitting(false);
                onClose();
                fetchFreeze();
            } catch (e) {
                console.error(e);
                snackbar.showError("Gagal update freeze");
                setIsSubmitting(false);
            }
        }
    }

    return(
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Typography style={{ textAlign: 'right' }}>
                    Input in {format(new Date(), "dd MMMM yyyy, HH:mm 'WIB'", {locale: idLocale})}
                </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>Nama Klinik *</Typography>
                            {
                                selectedFaskes ?
                                    <PInput
                                        value={selectedFaskes.name}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={()=> handleDeleteFaskes()}>
                                                    <FontAwesomeIcon icon={faTimesCircle}/>
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        fullWidth
                                    /> :
                                    <Autocomplete
                                        key={keyFaskesAutoComplete}
                                        fetcher={faskesFetcherByNamev2}
                                        onChange={onSelectFaskes}
                                        placeholder="Nama Klinik"
                                        variant="outlined"
                                        margin="dense"
                                        itemToString="name"
                                        fullWidth
                                    />
                            }
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>Tanggal Freeze *</Typography>
                            <DatePicker
                                value={freezeDate}
                                onChange={handleChangeFreezeDate}
                                format="dd MMMM yyyy"
                                inputVariant="outlined"
                                margin="dense"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                    </InputAdornment>
                                }}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid item xs={12}>
                            <Typography className={classes.label}>Alasan *</Typography>
                            <PInput
                                value={reason}
                                onChange={(e)=> setReason(e.target.value)}
                                rows={4}
                                multiline
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10}}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={onClose}
                >
                    Batal
                </PBtn>
                <PBtn
                    disabled={!selectedFaskes || isSubmitting || reason === ''}
                    onClick={()=> handleSimpan()}
                >
                    {!isSubmitting ? 'Simpan' : <FontAwesomeIcon icon={faSpinner} spin/> }
                </PBtn>
            </Grid>
        </Grid>
    )
};

const mapState = ({ user, freeze }) => ({
    currentUser: user.currentUser,
    selectedFreeze: freeze.selectedFreeze,
});

const mapDispatch = ({ freeze: { fetchFreeze } }) => ({
    fetchFreeze,
});

export default connect(mapState, mapDispatch)(FreezeForm);
