import {getGeneralSettings, updateGeneralSettings} from "../../api/offer";

export const generalSetting = {
    state: {
        isProductActive: false,
        productId: undefined,
    },
    reducers: {
        updateState(state, payload) {
            const { stateName, value } = payload;
            return {
                ...state,
                [stateName]: value,
            };
        },
        updateSelectedProduct(state, payload) {
            return {
                ...state,
                isProductActive: payload,
            };
        },
    },
    effects: dispatch => ({
        async fetchGeneralSetting() {
            try {
                const result = await getGeneralSettings();

                this.updateState({
                    stateName: 'isProductActive',
                    value: result.data[0].generalSetting.isProductActive,
                });

                 this.updateState({
                     stateName: 'productId',
                     value: result.data[0].generalSetting.productId,
                 });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan general setting');
            }
        },
        async updateIsProductActive(id, payload) {
            try {
                await updateGeneralSettings(id, payload);

                dispatch.snackbar.showSuccess(payload.generalSetting.isProductActive === true ? 'Produk Aktif' : 'Produk Tidak Aktif');
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal update status produk");
            }
        },
    })
};
