import agent from "./agent";
import format from "date-fns/format";

export const getKlinik = (skipLimit) => {
    let params;
    if(skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }

    return agent.get('Clients/getFaskesList', {
        params,
    });
};

export const countKlinikList = () => {
    return agent.get('Clients/countFaskesList');
};

export const getKlinikListByFilter = (payload) => {
    const params = {
        tanggalTerdaftarMulai: payload.tanggalTerdaftarMulai === null ? '' : format(new Date(payload.tanggalTerdaftarMulai), "yyyy-MM-dd"),
        tanggalTerdaftarSelesai: payload.tanggalTerdaftarSelesai === null ? '' : format(new Date(payload.tanggalTerdaftarSelesai), "yyyy-MM-dd"),
        namaKlinik: payload.namaKlinik,
        namaProduk: payload.namaProduk,
        isActive: payload.isActive,
        isSubscriber: payload.isSubscriber === 'Seluruhnya'
            ? ''
            : payload.isSubscriber === 'Berlangganan'
                ? 0
                : payload.isSubscriber === 'Tidak Berlangganan'
                    ? 1
                    : payload.isSubscriber === 'Belum Berlangganan'
                        ? 2
                        : 3,
        isTrial: payload.isTrial === 'Semua Akun'
            ? ''
            : payload.isTrial === 'Trial'
                ? 1
                : 0,
        isOnlineAppointment: payload.isOnlineAppointment === 'Seluruhnya'
            ? ''
            : payload.isOnlineAppointment === 'Aktif'
                ? 1
                : 0,
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Clients/getFaskesList', {
        params,
    });
};

export const countKlinikListByFilter = (payload) => {
    const params = {
        tanggalTerdaftarMulai: payload.tanggalTerdaftarMulai === null ? '' : format(new Date(payload.tanggalTerdaftarMulai), "yyyy-MM-dd"),
        tanggalTerdaftarSelesai: payload.tanggalTerdaftarSelesai === null ? '' : format(new Date(payload.tanggalTerdaftarSelesai), "yyyy-MM-dd"),
        namaKlinik: payload.namaKlinik,
        namaProduk: payload.namaProduk,
        isActive: payload.isActive,
        isSubscriber: payload.isSubscriber === 'Seluruhnya'
            ? ''
            : payload.isSubscriber === 'Berlangganan'
                ? 0
                : payload.isSubscriber === 'Tidak Berlangganan'
                    ? 1
                    : payload.isSubscriber === 'Belum Berlangganan'
                        ? 2
                        : 3,
        isTrial: payload.isTrial === 'Semua Akun'
            ? ''
            : payload.isTrial === 'Trial'
                ? 1
                : 0,
        isOnlineAppointment: payload.isOnlineAppointment === 'Seluruhnya'
            ? ''
            : payload.isOnlineAppointment === 'Aktif'
                ? 1
                : 0,
    };

    return agent.get('Clients/countFaskesList', {
        params,
    });
};

export const getSubscribedFaskesList = (skipLimit) => {
    let params;
    if (skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }

    return agent.get('Clients/getSubscribedFaskesListForPanela', {
        params,
    });
};

export const countSubscribedFaskesList = () => {
    return agent.get('Clients/countSubscribedFaskesListForPanela');
};

export const getSubscribedFaskesListByFilter = (payload) => {
    const params = {
        namaKlinik: payload.namaKlinik,
        namaProduk: payload.namaProduk,
        masaAktifStart: payload.masaAktifStart === null ? '' : format(new Date(payload.masaAktifStart), "yyyy-MM-dd"),
        masaAktifEnd: payload.masaAktifEnd === null ? '' : format(new Date(payload.masaAktifEnd), "yyyy-MM-dd"),
        jatuhTempoStart: payload.jatuhTempoStart === null ? '' : format(new Date(payload.jatuhTempoStart), "yyyy-MM-dd"),
        jatuhTempoEnd: payload.jatuhTempoEnd === null ? '' : format(new Date(payload.jatuhTempoEnd), "yyyy-MM-dd"),
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Clients/getSubscribedFaskesListForPanela', {
        params,
    });
};

export const countSubscribedFaskesListByFilter = (payload) => {
    const params = {
        namaKlinik: payload.namaKlinik,
        namaProduk: payload.namaProduk,
        masaAktifStart: payload.masaAktifStart === null ? '' : format(new Date(payload.masaAktifStart), "yyyy-MM-dd"),
        masaAktifEnd: payload.masaAktifEnd === null ? '' : format(new Date(payload.masaAktifEnd), "yyyy-MM-dd"),
        jatuhTempoStart: payload.jatuhTempoStart === null ? '' : format(new Date(payload.jatuhTempoStart), "yyyy-MM-dd"),
        jatuhTempoEnd: payload.jatuhTempoEnd === null ? '' : format(new Date(payload.jatuhTempoEnd), "yyyy-MM-dd")
    };

    return agent.get('Clients/countSubscribedFaskesListForPanela', {
        params,
    });
};

export const getOverdueList = (skipLimit) => {
    let params;
    if(skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }

    return agent.get('Clients/getOverdue', {
        params,
    });
};

export const countOverdueList = () => {
    return agent.get('Clients/countOverdue');
};

export const getOverdueListByFilter = (payload) => {
    const params = {
        namaKlinik: payload.namaKlinik,
        hMinus7: payload.hMinus7,
        skip: payload.skip,
        limit: payload.limit,
    };

    if(params.hMinus7 === 0) {
        delete params.hMinus7;
    }

    return agent.get('Clients/getOverdue', {
        params,
    });
};

export const countOverdueListByFilter = (payload) => {
    const params = {
        namaKlinik: payload.namaKlinik,
        hMinus7: payload.hMinus7,
    };

    if(params.hMinus7 === 0) {
        delete params.hMinus7;
    }

    return agent.get('Clients/countOverdue', {
        params,
    });
};

export const setFreeze = (data) => {
    return agent.put('Clients/setFreeze', data);
};

export const getBuktiBayar = (skipLimit) => {
    let params;
    if(skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }

    return agent.get('Clients/getBuktiBayar', {
        params,
    });
};

export const countBuktiBayar = () => {
    return agent.get('Clients/countBuktiBayar');
};

export const getInvoices = (skipLimit) => {
    let params;
    if(skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }

    return agent.get('Clients/getInvoices', {
        params,
    });
};

export const countInvoices = () => {
    return agent.get('Clients/countInvoices');
};

export const getInvoicesByFilter = (payload) => {
    const params = {
        namaKlinik: payload.namaKlinik,
        jatuhTempoStart: payload.jatuhTempoStart === null ? '' : format(new Date(payload.jatuhTempoStart), "yyyy-MM-dd"),
        jatuhTempoEnd: payload.jatuhTempoEnd === null ? '' : format(new Date(payload.jatuhTempoEnd), "yyyy-MM-dd"),
        status: payload.status,
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Clients/getInvoices', {
        params,
    });
};

export const countInvoicesByFilter = (payload) => {
    const params = {
        namaKlinik: payload.namaKlinik,
        jatuhTempoStart: payload.jatuhTempoStart === null ? '' : format(new Date(payload.jatuhTempoStart), "yyyy-MM-dd"),
        jatuhTempoEnd: payload.jatuhTempoEnd === null ? '' : format(new Date(payload.jatuhTempoEnd), "yyyy-MM-dd"),
        status: payload.status,
    };

    return agent.get('Clients/countInvoices', {
        params,
    });
};

export const updateStatusInvoice = (invoiceId, data) => {
    return agent.put(`Clients/${invoiceId}/updateBuktiBayarInvoice`, data);
};

export const searchFaskesByKeyword = (keyword) => {
    const params = {
        keyword,
    };

    return agent.get('Clients/getFaskesByKeyword', {
        params,
    });
};

export const getPICKlinikByHospitalId = (hospitalId) => {
    const params = {
        hospitalId,
    };

    return agent.get('Clients/getAccountsByHospitalId', {
        params,
    });
};

export const getInvoiceNo = () => {
    return agent.get('Clients/getInvoiceNo');
};

export const getProducts = () => {
    return agent.get('Clients/getAssistProducts');
};

export const createInvoice = (data) => {
    return agent.post('Clients/createInvoice', data);
};

export const getStatusKliniikByHospitalId = (hospitalId) => {
    const params = {
        hospitalId,
    };

    return agent.get('Clients/getSubscribeStatusByHospitalId', {
        params,
    });
};

export const updateInvoice = (billingId, data) => {
    return agent.put(`Clients/${billingId}/updateInvoice`, data);
};

export const searchFaskesInvoicesByKeyword = (keyword) => {
    const params = {
        namaKlinik: keyword,
    };

    return agent.get('Clients/getInvoices', {
        params,
    });
};

export const updateReceiptsInvoice = (invoiceId, data) => {
    return agent.put(`Clients/${invoiceId}/updateBuktiBayarInvoice`, data);
};

export const getInvoiceDetailByConfigId = (configId) => {
    const params = {
        configId,
    };

    return agent.get('Clients/getInvoiceDetailByConfigId', {
        params,
    });
};

export const getBuktiBayarByFilter = (payload) => {
    const params = {
        namaKlinik: payload.namaKlinik,
        skip: payload.skip,
        limit: payload.limit,
    };

    return agent.get('Clients/getBuktiBayar', {
        params,
    });
};

export const countBuktiBayarByFilter = (payload) => {
    const params = {
        namaKlinik: payload.namaKlinik,
    };

    return agent.get('Clients/countBuktiBayar', {
        params,
    });
};

export const getDaftarProvinsi = () => {
    return agent.get('Clients/daftarProvinsi');
};

export const getDaftarKabupaten = (codeprov) => {
    const params = {
        codeprov: codeprov,
    };

    return agent.get('Clients/daftarKabupaten', {
        params,
    });
};

export const getDaftarKecamatan = (codekabupaten) => {
    const params = {
        codekabupaten: codekabupaten,
    };

    return agent.get('Clients/daftarKecamatan', {
        params,
    });
};

export const getKlinikFreeList = (skipLimit) => {
    let params;
    if(skipLimit) {
        params = skipLimit;
    } else {
        params = {
            skip: 0,
            limit: 5,
        };
    }

    return agent.get('Clients/getFaskesFreeList', {
        params,
    });
};

export const countKlinikFreeList = () => {
    return agent.get('Clients/countFaskesFreeList');
};
