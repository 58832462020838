import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import idLocale from 'date-fns/locale/id';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css'
import { store } from './store';

ReactDOM.render(<Provider store={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
        <App />
    </MuiPickersUtilsProvider>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
