import React, { useState } from "react";
import {TextField, MenuItem, Paper, InputAdornment, CircularProgress} from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import Downshift from "downshift";
import debounce from 'lodash/debounce';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    paper: {
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: 100,
        left: 0,
        right: 0,
        maxHeight: 250,
        overflowY: 'auto',
    },
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function renderInput(inputProps) {
    const { InputProps, isFetching, classes, ...other } = inputProps;

    return (
        <TextField
            InputProps={{
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                endAdornment: <InputAdornment position="end">
                    {
                        isFetching
                            ? <CircularProgress size={24} />
                            : <FontAwesomeIcon icon={faSearch}/>
                    }
                </InputAdornment>,
                ...InputProps,
            }}
            {...other}
        />
    );
}

function renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    let isSelected;

    if (selectedItem && selectedItem.constructor === Object) {
        isSelected = selectedItem[suggestion.highlighter].indexOf(suggestion.label) > -1;
    } else {
        isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;
    }

    return (
        <MenuItem
            {...itemProps}
            key={suggestion.data.id || suggestion.data._id}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 900 : 400,
            }}
        >
            {suggestion.label}
        </MenuItem>
    );
}

export default function Autocomplete({ fetcher, label, className, margin, onChange, itemToString, ...props }) {
    const [items, setItems] = useState([]);
    const [isFocus, setIsFocus] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const getSuggestions = debounce(async (value) => {
        setIsFetching(true);
        try {
            const result = await fetcher(value);
            setItems(result);
            setIsFetching(false);
        } catch (err) {
            console.error(err);
            setIsFetching(false);
        }
    }, 500);

    function handleOnInputChange(e) {
        const { value } = e.target;
        getSuggestions(value);
    }

    function handleChange(item) {
        onChange(item);
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Downshift
                onChange={handleChange}
                itemToString={item => item ? item[itemToString] : ''}
                {...props}
            >
                {
                    ({
                        getInputProps,
                        getItemProps,
                        getLabelProps,
                        getMenuProps,
                        highlightedIndex,
                        isOpen,
                        inputValue,
                        selectedItem,
                    }) => {
                        const { onBlur, onFocus, ...inputProps } = getInputProps({
                            onChange: handleOnInputChange,
                            onFocus: () => setIsFocus(true),
                            onBlur: () => setIsFocus(false),
                        });

                        return (
                            <div className={classes.container}>
                                {renderInput({
                                    classes,
                                    className,
                                    margin,
                                    label: label,
                                    InputLabelProps: getLabelProps({ shrink: isFocus || inputValue !== '' || selectedItem !== null }),
                                    InputProps: { onBlur, onFocus },
                                    inputProps,
                                    isFetching,
                                    ...props,
                                })}

                                <div {...getMenuProps()}>
                                    {
                                        isOpen
                                            ? (
                                                <Paper className={classes.paper} square>
                                                    {items.map((suggestion, index) =>
                                                        renderSuggestion({
                                                            suggestion,
                                                            index,
                                                            itemProps: getItemProps({ item: suggestion.value }),
                                                            highlightedIndex,
                                                            selectedItem,
                                                        }),
                                                    )}
                                                </Paper>
                                            )
                                            : null
                                    }
                                </div>
                            </div>
                        )
                    }
                }
            </Downshift>
        </div>
    )
}
