import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";

import PDatePicker from "../../../share/PDatePicker";
import PBtn from "../../../share/PBtn";

import AnomaliTable from "./AnomaliTable";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
});

const Anomali = ({ setIsPage }) => {
    const classes = styles();
    const [filterData, setFilterData] = useState({
        tanggalMulai: null,
        tanggalSelesai: null,
        skip: 0,
        limit: 5,
    });

    const handleChangeStartDate = (date) => {
        setFilterData(prevState => ({
            ...prevState, tanggalMulai: date,
        }));
    };

    const handleChangeEndDate = (date) => {
        setFilterData(prevState => ({
            ...prevState, tanggalSelesai: date,
        }));
    };

    return (
        <Grid container>
            <Grid container spacing={1} item xs={12} style={{ marginTop: '30px' }}>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Filter Tanggal Mulai Dari</Typography>
                    <PDatePicker
                        value={filterData.tanggalMulai}
                        onChange={handleChangeStartDate}
                        format='dd/MM/yyyy'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputAdornment>
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Sampai Tanggal</Typography>
                    <PDatePicker
                        value={filterData.tanggalSelesai}
                        onChange={handleChangeEndDate}
                        format='dd/MM/yyyy'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputAdornment>
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <AnomaliTable
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </Grid>
            <Grid container justify='flex-end' item xs={12} style={{ marginTop: 20 }}>
                <PBtn
                    inverted
                    dropShadow
                    onClick={()=> setIsPage(0)}
                >
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{ marginRight: 3 }}/>Kembali
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default Anomali;
