import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from 'react-redux';

import {addNewPenolakan, updatePenolakan} from "../../../api/activity";
import {countActiveOfferByFaskesId} from "../../../api/offer";
import Autocomplete from "../../utils/Autocomplete";
import faskesFetcherByName from "../../../helpers/faskesFetcherByName";
import {IconButton, InputAdornment} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {DatePicker} from "@material-ui/pickers";
import {getRejectionCategories} from "../../../api/rejection-category";
import format from 'date-fns/format';
import idLocale from 'date-fns/locale/id';
import {dispatch} from "../../../store";

const PenolakanForm = ({ onClose, currentUser, fetchRejections, edittedRejection }) => {
    const { HealthFacility, healthFacilityPic } = edittedRejection;
    const isEdit = typeof edittedRejection.id !== 'undefined';

    const [selectedFaskes, setSelectedFaskes] = useState(HealthFacility ? HealthFacility : null);
    const [healthFacilityPICList, setHealthFacilityPICList] = useState(HealthFacility ? HealthFacility.PIC : []);
    const [selectedHealthFacilityPIC, setSelectedHealthFacilityPIC] = useState(HealthFacility
        ? HealthFacility.PIC.findIndex($item => {
            return $item.namepic === healthFacilityPic.namepic && $item.position === healthFacilityPic.position &&
                $item.emailpic === healthFacilityPic.emailpic && $item.whatsapp === healthFacilityPic.whatsapp &&
                $item.address === healthFacilityPic.address && $item.birthday === healthFacilityPic.birthday;
        })
        : '');
    const [rejectionDate, setRejectionDate] = useState(edittedRejection.rejectionDate
        ? new Date(edittedRejection.rejectionDate)
        : new Date());
    const [categoryRejectionList, setCategoryRejectionList] = useState([]);
    const [selectedRejection, setSelectedRejection] = useState(edittedRejection.rejectionName
        ? edittedRejection.rejectionName
        : '');
    const [selectedRejectionId, setSelectedRejectionId] = useState(edittedRejection.rejectionCategoryId
        ? edittedRejection.rejectionCategoryId
        : '');
    const [rejectionDetail, setRejectionDetail] = useState(edittedRejection.rejectionDetail
        ? edittedRejection.rejectionDetail
        : '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [keyFaskesAutocomplete, setKeyFaskesAutocomplete] = useState(0);

    useEffect(() => {
        (async function () {
            const result = await getRejectionCategories();
            setCategoryRejectionList(result.data);
        })();
    }, []);

    async function onSelectFaskes(item) {
        const { snackbar } = dispatch;
        try {
            const result = await countActiveOfferByFaskesId(item.id);
            if (result.data.count > 0) {
                snackbar.showError('Faskes yang dipilih memiliki penawaran yang aktif');
                setKeyFaskesAutocomplete(keyFaskesAutocomplete + 1);
                setSelectedFaskes(null);
                setHealthFacilityPICList([]);
            } else {
                if(item.picId === "m4n4j3m3n") {
                    dispatch.snackbar.showError("PIC Assist.id untuk leads ini belum ditentukan");
                    setKeyFaskesAutocomplete(keyFaskesAutocomplete + 1);
                } else {
                    setSelectedFaskes(item);
                    setHealthFacilityPICList(item.PIC);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    function handleDeleteFaskes() {
        if (isEdit) {
            setSelectedFaskes(null);
            setHealthFacilityPICList([]);
            setSelectedHealthFacilityPIC('');
        } else {
            setSelectedFaskes(null);
            setHealthFacilityPICList([]);
            setSelectedHealthFacilityPIC('');
            setRejectionDate(new Date());
            setSelectedRejection('');
            setSelectedRejectionId('');
            setRejectionDetail('');
        }
    }

    function handleChangeHealthFacilityPIC(e) {
        const { value } = e.target;
        setSelectedHealthFacilityPIC(value);
    }

    function handleChangeSelectedMedia(e) {
        const { value } = e.target;
        const categoryData = categoryRejectionList.find($item => $item.id === value);
        setSelectedRejectionId(value);
        setSelectedRejection(categoryData.name);
    }

    const handleChangeRejectionDate = (date) => {
        setRejectionDate(date);
    };

    async function handleSimpan() {
        const {snackbar} = dispatch;

        if (!isEdit) {
            try {
                const selectedPICFaskes = healthFacilityPICList[selectedHealthFacilityPIC];

                const payload = {
                    healthFacilityId: selectedFaskes.id,
                    picId: selectedFaskes.picId,
                    healthFacilityPic: selectedPICFaskes,
                    rejectionDate,
                    rejectionCategoryId: selectedRejectionId,
                    rejectionName: selectedRejection,
                    createdAt: new Date(),
                    createdId: currentUser.id,
                    rejectionDetail,
                };

                setIsSubmitting(true);
                await addNewPenolakan(payload);
                setIsSubmitting(false);
                snackbar.showSuccess('Berhasil menambahkan penolakan baru');
                onClose();
                fetchRejections();
            } catch (err) {
                console.error(err);
                setIsSubmitting(false);
                snackbar.showError('Gagal menambahkan penolakan baru');
            }
        } else {
            try {
                const selectedPICFaskes = healthFacilityPICList[selectedHealthFacilityPIC];

                const payload = {
                    healthFacilityId: selectedFaskes.id,
                    picId: selectedFaskes.picId,
                    healthFacilityPic: selectedPICFaskes,
                    rejectionDate,
                    rejectionCategoryId: selectedRejectionId,
                    rejectionName: selectedRejection,
                    updatedAt: new Date(),
                    updatedId: currentUser.id,
                    rejectionDetail,
                };

                setIsSubmitting(true);
                await updatePenolakan(edittedRejection.id, payload);
                setIsSubmitting(false);
                snackbar.showSuccess('Berhasil mengupdate penolakan');
                onClose();
                fetchRejections();
            } catch (err) {
                console.error(err);
                setIsSubmitting(false);
                snackbar.showError('Gagal mengupdate penolakan');
            }
        }
    }

    const inputtedDate = isEdit ? new Date(edittedRejection.createdAt) : new Date();

    return (
        <Grid container>
            <Grid container>
                <Grid item xs={12} style={{ marginBottom: 16 }}>
                    <Typography style={{ textAlign: 'right' }}>Input in {format(inputtedDate, "dd MMMM yyyy, HH:mm 'WIB'", { locale: idLocale })}</Typography>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 16 }}>
                    <Grid container spacing={4}>
                        <Grid container item md={6}>
                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Nama Klinik</Typography>
                                {
                                    selectedFaskes
                                        ? <PInput
                                            value={selectedFaskes.name}
                                            InputProps={{
                                                endAdornment: isEdit
                                                    ? null
                                                    : <InputAdornment position="end">
                                                    <IconButton onClick={() => handleDeleteFaskes()}>
                                                        <FontAwesomeIcon icon={faTimesCircle}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                            fullWidth
                                        />
                                        : <Autocomplete
                                            key={keyFaskesAutocomplete}
                                            fetcher={faskesFetcherByName}
                                            onChange={onSelectFaskes}
                                            placeholder="Nama klinik"
                                            variant="outlined"
                                            margin="dense"
                                            itemToString="name"
                                            fullWidth
                                        />
                                }
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Tanggal Penolakan</Typography>
                                <DatePicker
                                    value={rejectionDate}
                                    onChange={handleChangeRejectionDate}
                                    format="dd MMMM yyyy"
                                    inputVariant="outlined"
                                    margin="dense"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </InputAdornment>
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>PIC Klinik</Typography>
                                <PInput
                                    fullWidth
                                    select
                                    value={selectedHealthFacilityPIC}
                                    onChange={(e) => handleChangeHealthFacilityPIC(e)}
                                >
                                    {
                                        healthFacilityPICList.map(($healthFacilityPic, idx) => (
                                            <MenuItem key={`${$healthFacilityPic}-${idx}`} value={idx}>
                                                {$healthFacilityPic.namepic}
                                            </MenuItem>
                                        ))
                                    }
                                </PInput>
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Kategori Penolakan</Typography>
                                <PInput
                                    fullWidth
                                    select
                                    value={selectedRejectionId}
                                    onChange={(e) => handleChangeSelectedMedia(e)}
                                >
                                    {
                                        categoryRejectionList.map($rejection => (
                                            <MenuItem key={$rejection.id} value={$rejection.id}>
                                                {$rejection.name}
                                            </MenuItem>
                                        ))
                                    }
                                </PInput>
                            </Grid>
                        </Grid>

                        <Grid item md={6}>
                            <Grid item xs={12}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Keterangan</Typography>
                                <PInput multiline rows={4} value={rejectionDetail} onChange={(e) => setRejectionDetail(e.target.value)} fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justify="flex-end" item xs={12}>
                    <PBtn
                        disabled={isSubmitting}
                        dropShadow
                        inverted
                        style={{ marginRight: 16 }}
                        onClick={onClose}
                    >Batalkan</PBtn>
                    <PBtn
                        disabled={isSubmitting || !selectedFaskes || selectedHealthFacilityPIC === ''}
                        onClick={() => handleSimpan()}
                    >Simpan</PBtn>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapState = ({ user, activity }) => ({
    currentUser: user.currentUser,
    edittedRejection: activity.selectedRejection,
});

const mapDispatch = ({ activity: { fetchRejections } }) => ({
    fetchRejections,
});

export default connect(mapState, mapDispatch)(PenolakanForm);
