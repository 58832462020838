import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {dispatch} from "../../../store";

import {deleteIklan} from "../../../api/iklan";

import DeleteConfirmation from "../../share/DeleteConfirmation";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
});

const IklanItem = ({ page, rowsPerPage, isFetchingIklanList, iklanList, setIsOpenDialog, updateSelectedIklan, fetchIklanList }) => {
    const classes = styles();
    const index = page * rowsPerPage;
    const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(false);
    const [deletedId, setDeletedId] = useState(undefined);

    function handleEdit(iklan) {
        setIsOpenDialog(true);
        updateSelectedIklan(JSON.parse(JSON.stringify(iklan)));
    }

    async function handleDelete() {
        const { snackbar } = dispatch;
        try {
            await deleteIklan(deletedId);
            snackbar.showSuccess("Berhasil menghapus iklan");
            setIsShowDeleteConfirmation(false);
            setDeletedId(undefined);
            fetchIklanList();
        } catch (e) {
            console.error(e);
            snackbar.showError("Gagal menghapus iklan");
        }
    }

    return (
        <React.Fragment>
            <DeleteConfirmation
                contentText={"Apakah anda yakin akan menghapus item ini?"}
                open={isShowDeleteConfirmation}
                onClose={()=> {
                    setDeletedId(undefined);
                    setIsShowDeleteConfirmation(false);
                }}
                onSubmit={async ()=> {
                    await handleDelete();
                }}
            />
            {
                isFetchingIklanList
                    ? <TableRow>
                        <TableCell colSpan={3}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress/>
                            </div>
                        </TableCell>
                    </TableRow>
                    : iklanList.slice(index, index + rowsPerPage).map((item, idx) => (
                        <TableRow className={classes.row} key={idx} onClick={()=> handleEdit(item)}>
                            <TableCell className={classes.tableCellBody}>
                                {item.name}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.adsPosition}
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                <IconButton
                                    onClick={(e)=> {
                                        e.stopPropagation();
                                        setIsShowDeleteConfirmation(true);
                                        setDeletedId(item.id);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} style={{ color: 'red', width: 18, height: 18 }}/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
};

const mapState = ({ iklan }) => ({
    isFetchingIklanList: iklan.isFetchingIklanList,
    iklanList: iklan.iklanList,
});

const mapDispatch = ({ iklan: { updateSelectedIklan, fetchIklanList } }) => ({
    updateSelectedIklan,
    fetchIklanList,
});

export default connect(mapState, mapDispatch)(IklanItem);
