import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Chip from "@material-ui/core/Chip";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import filterIcon from "../../../assets/img/filter-icon.png";
import arrowDown from "./../../../assets/img/arrow-down-icon.png";

import ReferralTable from "./ReferralTable";
import ReferralForm from "./ReferralForm";
import ReferralFilter from "./ReferralFilter";

import debounce from "lodash/debounce";

const styles = makeStyles({
    filter: {
        float: 'right',
        display: 'inline-flex',
        padding: '15px 5px',
    },
    labelFilter: {
        fontWeight: 'bold',
        fontSize: '16px',
        fontFamily: 'Nunito',
        marginTop: '3px',
        marginRight: '10px',
    },
    imgFilter: {
        height: '40%',
        marginRight: '15px',
    },
    arrowFilter: {
        height: '40%',
        marginTop: '10px',
        cursor: 'pointer',
    },
    wrapChip: {
        display: 'flex',
        marginTop: 30,
    },
    chip: {
        marginRight: 10,
    },
});

const Referral = ({ updateSelectedReferral, updateSelectedReferralFilter, selectedReferralFilter }) => {
    const classes = styles();
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [filterData, setFilterData] = useState({
        tanggal: null,
        namaMereferral: '',
        instansiMereferral: '',
        namaDireferral: '',
        instansiDireferral: '',
        omnisearch: '',
        skip: 0,
        limit: 5,
    });
    const [filter, setFilter] = useState(0);
    const [isOpenDialogFilter, setIsOpenDialogFilter] = useState(false);

    function handleClose() {
        setIsOpenDialog(false);
        updateSelectedReferral({});
    }

    function handleClearFilter() {
        setFilterData({
            tanggal: null,
            namaMereferral: '',
            instansiMereferral: '',
            namaDireferral: '',
            instansiDireferral: '',
            omnisearch: '',
            skip: 0,
            limit: 5,
        });
        setFilter(0);
        updateSelectedReferralFilter({});
    }
    
    function handleFilter() {
        setFilter(filter + 1);
        setIsOpenDialogFilter(false);
        updateSelectedReferralFilter(JSON.parse(JSON.stringify(filterData)));
    }

    const getSuggestion = debounce((value) => {
        setFilterData({...filterData, omnisearch: value});
        setFilter(filter + 1);
    }, 100);

    const handleSearch = (e) => {
        const { value } = e.target;

        if(value.length > 2) {
            getSuggestion(value);
        } else if(value.length === 0) {
            setFilterData({...filterData, omnisearch: value});
            setFilter(0);
        } else {
            setFilterData({...filterData, omnisearch: value});
        }
    };

    return (
        <Grid container>
            <Dialog open={isOpenDialog} maxWidth='md' onClose={()=> handleClose()} fullWidth>
                <DialogContent>
                    <ReferralForm onClose={()=> handleClose()}/>
                </DialogContent>
            </Dialog>
            <Dialog open={isOpenDialogFilter} maxWidth='xs' onClose={()=> setIsOpenDialogFilter(false)} fullWidth>
                <DialogContent>
                    <ReferralFilter
                        filterData={filterData}
                        setFilterData={setFilterData}
                        onClear={()=> handleClearFilter()}
                        onSubmit={()=> handleFilter()}
                    />
                </DialogContent>
            </Dialog>
            <Grid container spacing={1} item xs={12} style={{ marginTop: '30px' }}>
                <Grid item xs={4}>
                    <PInput
                        placeholder='Search'
                        value={filterData.omnisearch}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <PBtn
                        onClick={()=> setIsOpenDialog(true)}
                    >
                        Tambahkan
                    </PBtn>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.filter}>
                        <img alt='filter' src={filterIcon} className={classes.imgFilter}/>
                        <Typography className={classes.labelFilter}>Filter by</Typography>
                        <img
                            alt='arrow'
                            src={arrowDown}
                            className={classes.arrowFilter}
                            onClick={()=> {
                                setFilterData({...filterData, omnisearch: ''});
                                setIsOpenDialogFilter(true)
                            }}
                        />
                    </div>
                </Grid>
                {
                    filter > 0 && <Grid item xs={12} className={classes.wrapChip}>
                        {
                            selectedReferralFilter.tanggal && selectedReferralFilter.tanggal !== null
                                ? <Chip
                                    size='small'
                                    color='secondary'
                                    label='Tanggal'
                                    icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                    className={classes.chip}
                                />
                                : null
                        }
                        {
                            selectedReferralFilter.namaMereferral && selectedReferralFilter.namaMereferral !== ''
                                ? <Chip
                                    size='small'
                                    color='secondary'
                                    label='Nama Yang Mereferralkan'
                                    icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                    className={classes.chip}
                                />
                                : null
                        }
                        {
                            selectedReferralFilter.instansiMereferral && selectedReferralFilter.instansiMereferral !== ''
                                ? <Chip
                                    size='small'
                                    color='secondary'
                                    label='Instansi Yang Mereferralkan'
                                    icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                    className={classes.chip}
                                />
                                : null
                        }
                        {
                            selectedReferralFilter.namaDireferral && selectedReferralFilter.namaDireferral !== ''
                                ? <Chip
                                    size='small'
                                    color='secondary'
                                    label='Nama Yang Direferralkan'
                                    icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                    className={classes.chip}
                                />
                                : null
                        }
                        {
                            selectedReferralFilter.instansiDireferral && selectedReferralFilter.instansiDireferral !== ''
                                ? <Chip
                                    size='small'
                                    color='secondary'
                                    label='Instansi Yang Direferralkan'
                                    icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                    className={classes.chip}
                                />
                                : null
                        }
                    </Grid>
                }
                <Grid item xs={12} style={{ marginTop: 34 }}>
                    <ReferralTable
                        setIsOpenDialog={setIsOpenDialog}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        filter={filter}
                        setFilter={setFilter}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapState = ({ prospect }) => ({
    selectedReferralFilter: prospect.selectedReferralFilter,
});

const mapDispatch = ({ prospect: { updateSelectedReferral, updateSelectedReferralFilter } }) => ({
    updateSelectedReferral,
    updateSelectedReferralFilter,
});

export default connect(mapState, mapDispatch)(Referral);
