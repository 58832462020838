import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

const styles = makeStyles({
    labelTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        color: 'black',
        fontSize: '20px',
        marginBottom: 30,
    },
    imgPreview: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%',
        marginBottom: 20,
    },
    pdfPreview: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%',
        height: 600,
        overflowY: 'auto',
        marginBottom: 20,
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
});

const PreviewSIUP = ({ selectedKlinikFreeList, handleClose, extensionSIUP }) => {
    const classes = styles();

    return (
        <React.Fragment>
            <DialogTitle>
                <Typography className={classes.labelTitle}>Preview SIUP</Typography>
                <IconButton className={classes.closeButton} onClick={handleClose}>
                    <FontAwesomeIcon icon={faTimes}/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {
                    selectedKlinikFreeList._id
                        ? extensionSIUP === 'pdf'
                            ? <iframe
                                id='preview'
                                title='preview-siup'
                                src={`https://docs.google.com/gview?url=https://s3-ap-southeast-1.amazonaws.com/public-medicaboo/${selectedKlinikFreeList.siupFile}&embedded=true#toolbar=0`}
                                className={classes.pdfPreview}
                            />
                            : <img
                                alt='preview'
                                src={'https://s3-ap-southeast-1.amazonaws.com/public-medicaboo/'+selectedKlinikFreeList.siupFile}
                                className={classes.imgPreview}
                            />
                        : null
                }
            </DialogContent>
        </React.Fragment>
    );
};

const mapState = ({ client }) => ({
    selectedKlinikFreeList: client.selectedKlinikFreeList,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(PreviewSIUP);
