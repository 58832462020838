import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import NumberFormat from "react-number-format";
import format from "date-fns/format";
import idLocale from "date-fns/locale/id";

import logo from "./../../../assets/img/logo-main.png";

const styles = makeStyles({
    labelInvoice: {
        fontFamily: 'Nunito',
        fontSize: '20px',
        color: 'black',
        fontWeight: 'bold',
    },
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
    logo: {
        width: '100%',
    },
    paper: {
        textAlign: 'center',
        paddingTop: 7,
        paddingBottom: 7,
    },
});

const InvoicePrintContent = ({ selectedInvoicesList, lunas, grandTotal, terminListNotPayed }) => {
    const classes = styles();
    const jatuhTempo = terminListNotPayed.length > 0
        ? format(new Date(terminListNotPayed[0].dueDatePayment), "dd MMMM yyyy", {locale: idLocale})
        : '';
    const jumlahTagihan = terminListNotPayed.length > 0
        ? <NumberFormat
            displayType='text'
            decimalSeparator=','
            thousandSeparator='.'
            prefix='Rp. '
            value={terminListNotPayed[0].value}
        /> : <NumberFormat
            displayType='text'
            decimalSeparator=','
            thousandSeparator='.'
            prefix='Rp. '
            value={0}
        />;

    return (
        <Grid container id="pdf-container" style={{ paddingLeft: 56, paddingRight: 56 }}>
            <Grid item xs={12} style={{ borderBottom: '2px solid black' }}>
                <Grid container style={{ paddingBottom: 10 }}>
                    <Grid item xs={3}>
                        <img src={logo} alt="Logo Assist.id" className={classes.logo}/>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container justify="flex-end">
                            <Typography style={{ fontSize: 15 }}>
                                <strong>Assist Indonesia</strong> PT. Teknologi Media Medika
                            </Typography>
                            <Typography style={{ fontSize: 15 }}>
                                Jl. Palaraya No. 325, Kec. Marpoyan Damai, Pekanbaru
                            </Typography>
                            <Typography style={{ fontSize: 15 }}>
                                WA: 0813-1000-0949 | Website: https://assist.id
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 15 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.labelInvoice}>INVOICE</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 10 }}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography>{selectedInvoicesList.nama}</Typography>
                        <Typography>{selectedInvoicesList.alamat.jalan}</Typography>
                        <Typography>{`${selectedInvoicesList.alamat.city}, ${selectedInvoicesList.alamat.region}`}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify='flex-end'>
                            <Typography style={{ display: 'flex' }}>
                                <span style={{ display: 'inline-block', width: 150 }}><strong>Referensi</strong></span>
                                <span style={{ flex: 1, marginLeft: 8, width: 145, textAlign: 'right' }}>
                                    {selectedInvoicesList.KConfig.activeBilling.Billing.invoiceNo}
                                </span>
                            </Typography>
                            <Typography style={{ display: 'flex' }}>
                                <span style={{ display: 'inline-block', width: 150 }}><strong>Tanggal</strong></span>
                                <span style={{ flex: 1, marginLeft: 8, width: 145, textAlign: 'right' }}>
                                    {
                                        selectedInvoicesList.KConfig.activeBilling.Billing.createdAt
                                            ? format(new Date(selectedInvoicesList.KConfig.activeBilling.Billing.createdAt), "dd MMMM yyyy", {locale: idLocale})
                                            : ''
                                    }
                                </span>
                            </Typography>
                            <Typography style={{ display: 'flex' }}>
                                <span style={{ display: 'inline-block', width: 150 }}><strong>Batas Pembayaran</strong></span>
                                <span style={{ flex: 1, marginLeft: 8, width: 145, textAlign: 'right' }}>
                                    {jatuhTempo}
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 10 }}>
                <Grid container>
                    <div>
                        <Typography style={{ display: 'flex' }}>
                            <span style={{ display: 'inline-block', width: 100 }}>Nama</span>
                            <span style={{ marginRight: 8 }}>:</span>
                            <span style={{ flex: 1 }}>{selectedInvoicesList.KConfig.activeBilling.Billing.createdName}</span>
                        </Typography>
                        <Typography style={{ display: 'flex' }}>
                            <span style={{ display: 'inline-block', width: 100 }}>Email</span>
                            <span style={{ marginRight: 8 }}>:</span>
                            <span style={{ flex: 1 }}>{selectedInvoicesList.KConfig.activeBilling.Billing.createdEmail}</span>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 32 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader}>Produk</TableCell>
                            <TableCell className={classes.tableCellHeader}>Lama Berlangganan</TableCell>
                            <TableCell className={classes.tableCellHeader}></TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ textAlign: 'right' }}>Harga (Rp)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.tableCellBody}>
                                {selectedInvoicesList.KConfig.activeBilling.mainProductName}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {`${selectedInvoicesList.KConfig.activeBilling.Billing.duration} Bulan`}
                            </TableCell>
                            <TableCell/>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <NumberFormat
                                    displayType='text'
                                    decimalSeparator=','
                                    thousandSeparator='.'
                                    prefix='Rp. '
                                    value={selectedInvoicesList.KConfig.activeBilling.Billing.totalFee}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <strong>Total</strong>
                            </TableCell>
                            <TableCell/>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <NumberFormat
                                    displayType='text'
                                    decimalSeparator=','
                                    thousandSeparator='.'
                                    prefix='Rp. '
                                    value={selectedInvoicesList.KConfig.activeBilling.Billing.totalFee}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                {
                                    selectedInvoicesList.KConfig.activeBilling.Billing.payment.length > 1
                                        ? selectedInvoicesList.KConfig.activeBilling.Billing.payment.map((item, id) => (
                                            <div key={id}>
                                                <span><strong>{`Termin ${id+1}`}</strong></span>
                                            </div>
                                        ))
                                        : <strong>Dibayar</strong>
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'left' }}>
                                {
                                    selectedInvoicesList.KConfig.activeBilling.Billing.payment.length > 1
                                        ? selectedInvoicesList.KConfig.activeBilling.Billing.payment.map((item, id) => (
                                            <div key={id}>
                                                <span><strong>{item.isPayed === true ? 'Lunas' : 'Belum Lunas'}</strong></span>
                                            </div>
                                        ))
                                        : ''
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                {
                                    selectedInvoicesList.KConfig.activeBilling.Billing.payment.map((item, id) => (
                                        <div key={id}>
                                            <NumberFormat
                                                displayType='text'
                                                decimalSeparator=','
                                                thousandSeparator='.'
                                                prefix='Rp. '
                                                value={item.isPayed === true ? item.value : 0}
                                            />
                                        </div>
                                    ))
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <strong>Jumlah Tertagih</strong>
                            </TableCell>
                            <TableCell/>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                <NumberFormat
                                    displayType='text'
                                    decimalSeparator=','
                                    thousandSeparator='.'
                                    prefix='Rp. '
                                    value={grandTotal}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 15 }}>
                <Grid container justify="center">
                    <Typography>Informasi Cicilan</Typography>
                    {
                        selectedInvoicesList.KConfig.activeBilling.Billing.payment.map((item, idx) => (
                            <Grid container justify="center" key={idx}>
                                <div style={{ display: 'inline-flex' }}>
                                    <Typography>{`${format(new Date(item.dueDatePayment), "dd/MM/yyyy")} -`}&nbsp;</Typography>
                                    <NumberFormat
                                        displayType='text'
                                        decimalSeparator=','
                                        thousandSeparator='.'
                                        prefix='Rp. '
                                        value={item.value}
                                    />
                                </div>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 20 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>
                            Jatuh Tempo Selanjutnya tanggal <strong>{jatuhTempo}</strong> sebesar <strong>{jumlahTagihan}</strong>. Untuk pembayaran dapat di transfer melalui rekening berikut:
                        </Typography>
                        <Typography style={{ display: 'flex' }}>
                            <span style={{ width: 200 }}>Nama Rekening</span>
                            <span>: <strong>PT. Teknologi Media Medika</strong></span>
                        </Typography>
                        <Typography style={{ display: 'flex' }}>
                            <span style={{ width: 200 }}>No Rekening</span>
                            <span>: <strong>108-00-1624193-8</strong></span>
                        </Typography>
                        <Typography style={{ display: 'flex' }}>
                            <span style={{ width: 200 }}>Nama Bank</span>
                            <span>: <strong>Bank Mandiri</strong></span>
                        </Typography>
                        <Typography style={{ display: 'flex' }}>
                            <span style={{ width: 200 }}>Konfirmasi</span>
                            <span>: <strong>WA 0813-1000-0949 / Atikah@assist.id</strong></span>
                        </Typography>
                        <Typography style={{ paddingTop: 15 }}>
                            Demikian detail biaya yang kami sampaikan, Terimakasih atas perhatiannya.
                        </Typography>
                        <Typography style={{ paddingTop: 30 }}>
                            <span>Best Regards,</span>
                        </Typography>
                        <Typography>
                            Assist.id
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 25 }}>
                <Paper className={classes.paper} elevation={0}>
                    <Typography>Silahkan hubungi kami untuk informasi lebih lanjut melalui email atikah@assist.id</Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapState = ({ client }) => ({
    selectedInvoicesList: client.selectedInvoicesList,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(InvoicePrintContent);
