import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem  from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
});

const FreezeFilter = ({ filterData, setFilterData, startFilter, clearFilter }) => {
    const classes = styles();

    function handleChangeFilter(e) {
        setFilterData({...filterData, [e.target.name]: e.target.value});
    }

    const handleChangeStartFreezeDate = (date) => {
        filterData.tanggalFreezeMulai = date;
        setFilterData(prevState => ({
            ...prevState, tanggalFreezeMulai: filterData.tanggalFreezeMulai,
        }));
    };

    const handleChangeEndFreezeDate = (date) => {
        filterData.tanggalFreezeSelesai = date;
        setFilterData(prevState => ({
            ...prevState, tanggalFreezeSelesai: filterData.tanggalFreezeSelesai,
        }));
    };

    return(
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Dari Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalFreezeMulai}
                            onChange={handleChangeStartFreezeDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Sampai Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalFreezeSelesai}
                            onChange={handleChangeEndFreezeDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Klinik</Typography>
                        <PInput
                            name="nama"
                            value={filterData.nama}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Alasan</Typography>
                        <PInput
                            name="alasan"
                            value={filterData.alasan}
                            onChange={(e)=> handleChangeFilter(e)}
                            rows={2}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Status</Typography>
                        <PInput
                            name="status"
                            value={filterData.status}
                            onChange={(e)=> handleChangeFilter(e)}
                            select
                            fullWidth
                        >
                            <MenuItem value={0}>Undecided</MenuItem>
                            <MenuItem value={1}>Freeze</MenuItem>
                            <MenuItem value={2}>Unfreeze</MenuItem>
                        </PInput>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} justify="flex-end" style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={clearFilter}
                >
                    Reset
                </PBtn>
                <PBtn
                    onClick={startFilter}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default FreezeFilter;
