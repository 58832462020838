import React from 'react';
import {connect} from 'react-redux';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import TrialRow from './TrialRow';

const TrialItem = ({ trialList, setIsOpenAddNew, fetchTrialList, isFetchingTrialList, page, rowsPerPage }) => {

    return (
        <React.Fragment>
            {
                isFetchingTrialList === true
                    ? <TableRow>
                        <TableCell colSpan={4}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        </TableCell>
                    </TableRow>
                    : <React.Fragment>
                        {
                            trialList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(trial => (
                                <TrialRow key={trial._id} trial={{...trial}} fetchTrialList={fetchTrialList} />
                            ))
                        }
                    </React.Fragment>
            }
        </React.Fragment>
    );
};

const mapState = ({ activity }) => ({
    trialList: activity.trialList,
    isFetchingTrialList: activity.isFetchingTrialList,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(TrialItem);
