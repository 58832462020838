import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../share/PInput";
import PDatePicker from "../share/PDatePicker";
import PBtn from "../share/PBtn";
import CustomRadioButton from "../share/CustomRadioButton";

import {ticketCategory} from "../../constants";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
    labelPlus: {
        fontWeight: 'bold',
        color: 'black',
    },
});

const TicketingFilter = ({ filterData, setFilterData, onSubmit, onClear, panelaUserList }) => {
    const classes = styles();

    const handleChange = (e) => {
        setFilterData({...filterData, [e.target.name]: e.target.value});
    };

    const handleChangeTanggalMulaiStart = (date) => {
        setFilterData({...filterData, tanggalMulaiStart: date});
    };

    const handleChangeTanggalMulaiEnd = (date) => {
        setFilterData({...filterData, tanggalMulaiEnd: date});
    };

    const handleChangeTanggalSelesaiStart = (date) => {
        setFilterData({...filterData, tanggalSelesaiStart: date});
    };

    const handleChangeTanggalSelesaiEnd = (date) => {
        setFilterData({...filterData, tanggalSelesaiEnd: date});
    };

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography className={classes.label}>Tanggal Mulai</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.labelPlus}>Dari</Typography>
                                <PDatePicker
                                    value={filterData.tanggalMulaiStart}
                                    onChange={handleChangeTanggalMulaiStart}
                                    format='dd MMMM yyyy'
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </InputAdornment>
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.labelPlus}>Sampai</Typography>
                                <PDatePicker
                                    value={filterData.tanggalMulaiEnd}
                                    onChange={handleChangeTanggalMulaiEnd}
                                    format='dd MMMM yyyy'
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </InputAdornment>
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.label}>Tanggal Selesai</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.labelPlus}>Dari</Typography>
                                <PDatePicker
                                    value={filterData.tanggalSelesaiStart}
                                    onChange={handleChangeTanggalSelesaiStart}
                                    format='dd MMMM yyyy'
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </InputAdornment>
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.labelPlus}>Sampai</Typography>
                                <PDatePicker
                                    value={filterData.tanggalSelesaiStart}
                                    onChange={handleChangeTanggalSelesaiEnd}
                                    format='dd MMMM yyyy'
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </InputAdornment>
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Klinik</Typography>
                        <PInput
                            name='namaKlinik'
                            value={filterData.namaKlinik}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>PIC Klinik</Typography>
                        <PInput
                            name='picKlinik'
                            value={filterData.picKlinik}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Produk</Typography>
                        <PInput
                            name='namaProduk'
                            value={filterData.namaProduk}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Kategori</Typography>
                        <PInput
                            name='kategori'
                            value={filterData.kategori}
                            onChange={handleChange}
                            select
                            fullWidth
                        >
                            {
                                ticketCategory.map((item, idx) => (
                                    <MenuItem key={idx} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))
                            }
                        </PInput>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>PIC Assist.id</Typography>
                        <PInput
                            name='pic'
                            value={filterData.pic}
                            onChange={handleChange}
                            select
                            fullWidth
                        >
                            {
                                panelaUserList.map((item, idx) => (
                                    <MenuItem key={idx} value={item.id}>
                                        {`${item.firstName} ${item.lastName}`}
                                    </MenuItem>
                                ))
                            }
                        </PInput>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup row name='status' value={filterData.status} onChange={handleChange}>
                            <FormControlLabel control={<CustomRadioButton color='red'/>} label='Waiting' value='Waiting' labelPlacement='end'/>
                            <FormControlLabel control={<CustomRadioButton color='yellow'/>} label='On Progress' value='On Progress' labelPlacement='end'/>
                            <FormControlLabel control={<CustomRadioButton color='green'/>} label='Solved' value='Solved' labelPlacement='end'/>
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify='flex-end' item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{
                        marginRight: 16,
                    }}
                    onClick={onClear}
                >
                    Clear
                </PBtn>
                <PBtn
                    onClick={onSubmit}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    );
};

export default TicketingFilter;
