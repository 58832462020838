import agent from "./agent";

export const addNewMedia = (name, userId) => {
    const payload = {
        type: 'media',
        name: name,
        createdAt: new Date(),
        createdId: userId,
    };

    return agent.post('Dictionaries', payload);
};

export const getMedias = () => {
    const filter = {
        where: {
            type: 'media',
        },
    };

    return agent.get('Dictionaries', {
        params: {
            filter,
        },
    })
};
