import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import format from "date-fns/format";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
});

const BuktiBayarItem = ({ page, rowsPerPage, isFetchingReceiptsList, receiptsList, updateSelectedReceiptsList, updatePageReceipts }) => {
    const classes = styles();
    const index = page * rowsPerPage;

    function handleEdit(data) {
        updateSelectedReceiptsList(JSON.parse(JSON.stringify(data)));
        updatePageReceipts(1);
    }

    return (
        <React.Fragment>
            {
                isFetchingReceiptsList === true
                    ? <TableRow>
                        <TableCell colSpan={11}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        </TableCell>
                    </TableRow>
                    : receiptsList.slice(index, index + rowsPerPage).map((item, idx) => (
                        <TableRow className={classes.row} key={idx} onClick={()=> handleEdit(item)}>
                            <TableCell className={classes.tableCellBody}>
                                {
                                    item.KConfig.activeBilling.Billing.payment.midTrans
                                        ? item.KConfig.activeBilling.Billing.payment.midTrans.transaction_id
                                        : item.KConfig.activeBilling.Billing.payment.codeBuktiBayar
                                        ? item.KConfig.activeBilling.Billing.payment.codeBuktiBayar
                                        : '-'
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.nama}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {
                                    item.KConfig.activeBilling.Billing.payment.midTrans
                                        ? item.KConfig.activeBilling.Billing.payment.midTrans.transaction_time
                                        : item.KConfig.activeBilling.Billing.payment.buktiBayar
                                        ? item.KConfig.activeBilling.Billing.payment.buktiBayar.map((item, idx) => (
                                            <div key={idx} style={{ marginBottom: 5 }}>
                                                {item.tanggal ? format(new Date(item.tanggal), "yyyy-MM-dd HH-mm-ss") : '-'}
                                            </div>
                                        ))
                                        : '-'
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {
                                    item.KConfig.activeBilling.Billing.payment.midTrans
                                        ? item.KConfig.activeBilling.Billing.payment.midTrans.payment_type
                                        : item.KConfig.activeBilling.Billing.payment.buktiBayar
                                        ? item.KConfig.activeBilling.Billing.payment.buktiBayar.map((item, idx) => (
                                            <div key={idx} style={{ marginBottom: 5 }}>
                                                {item.metodeBayar ? item.metodeBayar : '-'}
                                            </div>
                                        ))
                                        : '-'
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.KConfig.activeBilling.Billing.createdName}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {
                                    item.KConfig.activeBilling.Billing.payment.midTrans
                                        ? item.KConfig.activeBilling.Billing.payment.midTrans.gross_amount
                                        : item.KConfig.activeBilling.Billing.payment.buktiBayar
                                        ? item.KConfig.activeBilling.Billing.payment.buktiBayar.map((item, idx) => (
                                            <div key={idx} style={{ marginBottom: 5 }}>
                                                {item.jumlah ? item.jumlah : '-'}
                                            </div>
                                        ))
                                        : '-'
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.KConfig.activeBilling.Billing.createdName}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.KConfig.activeBilling.Billing.noHp}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.KConfig.activeBilling.Billing.createdEmail}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                -
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.KConfig.activeBilling.Billing.invoiceNo}
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
};

const mapState = ({ client }) => ({
    receiptsList: client.receiptsList,
    isFetchingReceiptsList: client.isFetchingReceiptsList,
});

const mapDispatch = ({ client: { updateSelectedReceiptsList, updatePageReceipts } }) => ({
    updateSelectedReceiptsList,
    updatePageReceipts,
});

export default connect(mapState, mapDispatch)(BuktiBayarItem);
