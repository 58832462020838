import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";

import ActionCard from "../../share/ActionCard";

import ProdukTrial from "./produk-trial/ProdukTrial";
import ProdukFree from "./produk-free/ProdukFree";
import ProdukBerbayar from "./produk-berbayar/ProdukBerbayar";

const Retension = () => {
    const [isPage, setIsPage] = useState(0);

    return (
        <Grid container>
            {
                isPage === 0
                    ? <Grid container spacing={4} item xs={12} style={{ marginTop: 50 }}>
                        <Grid item xs={6}>
                            <ActionCard
                                label='Produk Trial'
                                subLabel='Laporan retension produk trial'
                                handleClick={()=> setIsPage(1)}
                            />
                            <ActionCard
                                label='Produk Berbayar'
                                subLabel='Laporan retension produk berbayar'
                                handleClick={()=> setIsPage(3)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ActionCard
                                label='Produk Free'
                                subLabel='Laporan retension produk free'
                                handleClick={()=> setIsPage(2)}
                            />
                        </Grid>
                    </Grid>
                    : isPage === 1
                    ? <ProdukTrial setIsPage={setIsPage}/>
                    : isPage === 2
                        ? <ProdukFree setIsPage={setIsPage}/>
                        : isPage === 3
                            ? <ProdukBerbayar setIsPage={setIsPage}/>
                            : null
            }
        </Grid>
    );
};

export default Retension;
