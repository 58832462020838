import { getEventNames } from '../../api/event-name';

export const eventName = {
    state: {
        isFetchingEventNameList: false,
        eventNameList: [],
    },
    reducers: {
        updateState(state, payload) {
            const { stateName, value } = payload;
            return {
                ...state,
                [stateName]: value,
            };
        },
    },
    effects: dispatch => ({
        async fetchEventNames() {
            try {
                this.updateState({
                    stateName: 'isFetchingEventNameList',
                    value: true,
                });

                const result = await getEventNames();
                this.updateState({
                    stateName: 'eventNameList',
                    value: result.data,
                });
                this.updateState({
                    stateName: 'isFetchingEventNameList',
                    value: false,
                });
            } catch(err) {
                console.error(err);
                dispatch.snackbar.showError("Gagal mendapatkan daftar nama event");
                this.updateState({
                    stateName: 'isFetchingEventNameList',
                    value: false,
                });
            }
        },
    }),
};
