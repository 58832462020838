import React, {useState} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import PBtn from "../../share/PBtn";
import PrintContent from "./PrintContent";
import {
    // CircularProgress,
    FormControlLabel, Switch} from "@material-ui/core";
// import html2canvas from "html2canvas";
// import JSPDF from "jspdf";

import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    root: {
        paddingTop: 50,
    },
    '@global': {
        '@media print': {
            '.hideForPrint': {
                display: 'none',
            },
        },
    },
    popover: {
        pointerEvents: 'none',
    },
});

const PrintDialog = ({ openPrintPenawaranDialog, updateOpenPrintPenawaranDialog, selectedOffer }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const [showTermins, setShowTermins] = useState(true);
    // const [PDFLoader, setPDFLoader] = useState(false);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event) => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    // function handleDownloadPDF() {
    //     return () => {
    //         setPDFLoader(true);
    //         const input = document.getElementById('pdf-container');
    //         html2canvas(input).then((canvas) => {
    //             const imgData = canvas.toDataURL('image/png');
    //             const pdf = new JSPDF();
    //             pdf.addImage(imgData, 'PNG', 0, 16);
    //             pdf.save(`${selectedOffer.fileName}.pdf`);
    //             setPDFLoader(false);
    //         }).catch((err) => {
    //             console.error(err);
    //             setPDFLoader(false);
    //         });
    //     }
    // }

    return (
        <React.Fragment>
            {
                openPrintPenawaranDialog && <Dialog
                    fullScreen
                    open={openPrintPenawaranDialog}
                    onClose={() => updateOpenPrintPenawaranDialog(false)}
                >
                    <DialogContent className={classes.root}>
                        <Grid container>
                            <div className={"hideForPrint"} style={{
                                position: 'absolute',
                                top: 10,
                                right: 50,
                            }}>
                                {/*{*/}
                                {/*    PDFLoader*/}
                                {/*        ? <CircularProgress color="secondary" size={24} style={{top: 10}} />*/}
                                {/*        : null*/}
                                {/*}*/}

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showTermins}
                                            onChange={(e) => setShowTermins(e.target.checked)}
                                        />
                                    }
                                    label={`${ showTermins ? 'Sembunyikan' : 'Tampilkan' } Termin`}
                                    style={{marginRight: 50}}
                                />

                                <PBtn onClick={() => {
                                    document.title = selectedOffer.fileName;
                                    window.print();
                                    document.title = 'Admin | Panela';
                                    return false;
                                }} style={{marginRight: 5}}>
                                    Download PDF
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{marginLeft: 8}}
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                    />
                                    <Popover
                                        id="mouse-over-popover"
                                        className={classes.popover}
                                        open={open}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        onClose={handlePopoverClose}
                                        disableRestoreFocus
                                    >
                                        <div style={{padding: '15px 15px 15px 15px'}}>
                                            <Typography>Instruksi Download PDF Penawaran:</Typography>
                                            <Typography>1). Klik Download PDF</Typography>
                                            <Typography>2). Destinasi: Pilih Save As PDF</Typography>
                                            <Typography>3). Save</Typography>
                                        </div>
                                    </Popover>
                                </PBtn>

                                {/*<PBtn*/}
                                {/*    disabled={PDFLoader}*/}
                                {/*    onClick={handleDownloadPDF()}*/}
                                {/*    style={{marginRight: 5}}*/}
                                {/*>*/}
                                {/*    Download PDF*/}
                                {/*</PBtn>*/}

                                <IconButton onClick={() => updateOpenPrintPenawaranDialog(false)}>
                                    <FontAwesomeIcon icon={faTimesCircle}/>
                                </IconButton>
                            </div>

                            <Grid item container justify="center" xs={12}>
                                <div style={{ paddingTop: 40, width: '21cm' }}>
                                    <PrintContent selectedOffer={selectedOffer} showTermins={showTermins} />
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
        </React.Fragment>
    );
};

const mapState = ({ activity }) => ({
    openPrintPenawaranDialog: activity.openPrintPenawaranDialog,
    selectedOffer: activity.selectedOffer,
});

const mapDispatch = ({ activity: { updateOpenPrintPenawaranDialog } }) => ({
    updateOpenPrintPenawaranDialog,
});

export default connect(mapState, mapDispatch)(PrintDialog);
