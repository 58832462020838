import React, { useState } from 'react';
import {
    Container,
    Grid,
    InputAdornment,
    Typography,
    Dialog,
    DialogContent,
    Chip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import Header from './../templateFaskes/header/header';
import TableLeads from './TableLeads';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import filter from './../../../assets/img/filter-icon.png';
import arrowDown from './../../../assets/img/arrow-down-icon.png';

import { makeStyles } from "@material-ui/core/styles";

import './leads.css';

import FilterLeads from "./FilterLeads";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

const styles = makeStyles({
    chip: {
        marginRight: 10,
    },
});

function Leads(props) {
    const classes = styles();
    const { updateSelectedFaskesFilter, selectedFaskesFilter, fetchFaskesListFilter } = props;
    const [ isFilter, setIsFilter ] = useState(false);
    const [ filterData, setFilterData ] = useState({
        order: '',
        omnisearch: '',
        nama: '',
        kabupaten: '',
        jenis: '',
        status: '',
        picAssist: '',
        terakhirDihubungiStart: null,
        terakhirDihubungiEnd: null,
        hotnessLevel: '',
        skip: 0,
        limit: 6,
    });
    const [isOpenFilterForm, setIsOpenFilterForm] = useState(false);

    const handleChangeSearch = (e) => {
        filterData.omnisearch = e.target.value;
        setFilterData(prevState => ({
            ...prevState, omnisearch: filterData.omnisearch,
        }));
        setIsFilter(true);
        fetchFaskesListFilter(filterData);
    };

    const handleFilter = () => {
        setIsFilter(true);
        fetchFaskesListFilter(filterData);
        setIsOpenFilterForm(false);
        updateSelectedFaskesFilter(JSON.parse(JSON.stringify(filterData)));
    };

    const handleClearFilter = () => {
        setFilterData({
            order: '',
            omnisearch: '',
            nama: '',
            kabupaten: '',
            jenis: '',
            status: '',
            picAssist: '',
            terakhirDihubungiStart: null,
            terakhirDihubungiEnd: null,
            hotnessLevel: '',
            skip: 0,
            limit: 6,
        });
        setIsFilter(false);
        updateSelectedFaskesFilter({});
    };

    return(
        <div className="leads-content">
            <Header title={"Leads"}/>
            <div className="body" style={props.isViewedByMobile ? {height: '95%'} : {height: '85%'}}>
                <Container className="container">
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <PInput
                                onChange={handleChangeSearch}
                                placeholder="cari klinik, nama, wa atau email pic disini..."
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputAdornment>,
                                }}
                                fullWidth
                                // onBlur={handleChangeFilterData}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Link to="./leads/add" style={{textDecoration: "none"}}>
                                <PBtn>
                                    Add Leads
                                </PBtn>
                            </Link>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="filter">
                                <img alt="filter" src={filter} className="img-filter" />
                                <Typography>Filter by</Typography>
                                <img
                                    alt="arrow"
                                    src={arrowDown}
                                    className="arrow-filter"
                                    onClick={()=> setIsOpenFilterForm(true)}
                                />
                                <Dialog open={isOpenFilterForm} maxWidth="xs" onClose={()=> setIsOpenFilterForm(false)} fullWidth>
                                    <DialogContent>
                                        <FilterLeads
                                            filterData={filterData}
                                            setFilterData={setFilterData}
                                            startFilter={handleFilter}
                                            clearFilter={handleClearFilter}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </div>
                        </Grid>
                        {
                            isFilter && <Grid item xs={12} style={{ marginTop: 30, dispaly: 'flex' }}>
                                {
                                    selectedFaskesFilter.nama && selectedFaskesFilter.nama !== ''
                                        ? <Chip
                                            size="small"
                                            color="secondary"
                                            label="Nama Klinik"
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedFaskesFilter.kabupaten && selectedFaskesFilter.kabupaten !== ''
                                        ? <Chip
                                            size="small"
                                            color="secondary"
                                            label="Kabupaten"
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedFaskesFilter.jenis && selectedFaskesFilter.jenis !== ''
                                        ? <Chip
                                            size="small"
                                            color="secondary"
                                            label="Jenis Faskes"
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedFaskesFilter.status && selectedFaskesFilter.status !== ''
                                        ? <Chip
                                            size="small"
                                            color="secondary"
                                            label="Status"
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedFaskesFilter.picAssist && selectedFaskesFilter.picAssist !== ''
                                        ? <Chip
                                            size="small"
                                            color="secondary"
                                            label="PIC Assist.id"
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    (selectedFaskesFilter.terakhirDihubungiStart && selectedFaskesFilter.terakhirDihubungiStart !== null) || (selectedFaskesFilter.terakhirDihubungiEnd && selectedFaskesFilter.terakhirDihubungiEnd !== null)
                                        ? <Chip
                                            size='small'
                                            color='secondary'
                                            label='Tanggal Terakhir Dihubungi'
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                                {
                                    selectedFaskesFilter.hotnessLevel && selectedFaskesFilter.hotnessLevel !== ''
                                        ? <Chip
                                            size='small'
                                            color='secondary'
                                            label='Hotness Level'
                                            icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                            className={classes.chip}
                                        /> : null
                                }
                            </Grid>
                        }
                        <Grid item xs={12} md={12}>
                            <TableLeads
                                filterData={filterData}
                                setFilterData={setFilterData}
                                isFilter={isFilter}
                                setIsFilter={setIsFilter}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
}

const mapState = ({ leads, app }) => ({
    selectedFaskesFilter: leads.selectedFaskesFilter,
    isViewedByMobile: app.isViewedByMobile,
});

const mapDispatch = ({ leads: {updateSelectedFaskesFilter, fetchFaskesListFilter} }) => ({
    updateSelectedFaskesFilter,
    fetchFaskesListFilter,
});

export default connect(mapState, mapDispatch)(Leads);
