import {getKompetitors} from "../../api/kompetitor";

export const kompetitor = {
    state: {
        isFetchingKompetitorList: false,
        kompetitorList: [],
    },
    reducers: {
        updateState(state, payload) {
            const { statName, value } = payload;
            return {
                ...state,
                [statName]: value,
            };
        },
    },
    effects: dispatch => ({
        async fetchKompetitors() {
            try {
                this.updateState({
                    statName: 'isFetchingKompetitorList',
                    value: true,
                });

                 const result = await getKompetitors();

                 this.updateState({
                     statName: 'kompetitorList',
                     value: result.data,
                 });

                 this.updateState({
                     statName: 'isFetchingKompetitorList',
                     value: false,
                 });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal mendapatkan daftar kompetitor");
                this.updateState({
                    statName: 'isFetchingKompetitorList',
                    value: false,
                });
            }
        },
    }),
};
