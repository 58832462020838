import agent from "./agent";

export const getMostActivePIC = () => {
    return agent.get('Dashboards/mostActivePIC');
};

export const getMostActivePICOffer = () => {
    return agent.get('Dashboards/mostActivePICOffer');
};

export const getMostClosedLeads = () => {
    return agent.get('Dashboards/mostClosedLeads');
};

export const getRegisteredFaskes = () => {
    return agent.get('Dashboards/hitungJenisFaskesYangTerdaftar');
};

export const getTotalLeads = () => {
    return agent.get('Dashboards/getTotalLeads');
};

export const getAsalLeadsByProvinsi = () => {
    return agent.get('Dashboards/asalLeadsByProvinsi');
};

export const getAsalLeadsByKota = () => {
    return agent.get('Dashboards/asalLeadsByKota');
};

export const getAsalLeadsByKecamatan = () => {
    return agent.get('Dashboards/asalLeadsByKecamatan');
};

export const getTotalLeadsBulanIni = () => {
    return agent.get('Dashboards/getTotalLeadsBulanIni');
};

export const getTotalKompetitor = () => {
    return agent.get('Dashboards/hitungKompetitor');
};

export const getTotalPindahDariKompetitor = () => {
    return agent.get('Dashboards/hitungPindahDariKompetitor');
};

export const getProdukPalingDiminati = () => {
    return agent.get('Dashboards/hitungProdukPalingDiminati');
};

export const getTopReasonRejection = () => {
    return agent.get('Dashboards/hitungAlasanRejectByKategori');
};

export const getTotalAktivitas = () => {
    return agent.get('Dashboards/hitungAktifitas');
};

export const getBirthdayPICKlinik = () => {
    return agent.get('Dashboards/hitungUlangtahunPICKlinik');
};

export const getRevenue = () => {
    const params = {
        year: new Date().getFullYear(),
    };

    return agent.get('Dashboards/totalRevenueGraph', {
        params,
    });
};

export const getTotalKlinik = () => {
    return agent.get('Dashboards/hitungLeadsByProvince');
};

export const getNearestBill = () => {
    const params = {
        skip: 0,
        limit: 15,
    };

    return agent.get('Dashboards/getTagihanTerdekat', {
        params,
    });
};
