import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AddNewProduk from "./AddNewProduk";
import ListProduk from "./ListProduk";
import { connect } from 'react-redux';
// import {dispatch} from "../../../store";
// import {addNewMedia} from "../../../api/media";

const Produk = ({ fetchProducts }) => {
    useEffect(() => {
        fetchProducts();
    });

    return (
        <Grid container>
            <AddNewProduk buttonLabel={"Tambah Produk"} />

            <ListProduk />
        </Grid>
    );
};

const mapState = null;

const mapDispatch = ({ product: { fetchProducts } }) => ({
    fetchProducts,
});

export default connect(mapState, mapDispatch)(Produk);
