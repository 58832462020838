import axios from 'axios'
import { API_URL } from "../constants";
import agent from "./agent";

export const login = (payload) => {
    return axios.post(`${API_URL}/PanelaUsers/login`, payload);
};

export const relogin = () => {
    return agent.get('/PanelaUsers/reloginByAccessToken');
};

export const logout = () => {
    return agent.post('/PanelaUsers/logout');
};
