import agent from "../../api/agent";
import { dispatch } from "../../store"
import history from "../../history"

export const pic = {
    state : [],
    reducers : {
        loadState(state, payload){
            return [...payload]
        },
        resetState(){
            return []
        }
    },
    effects : {
        async loadPICByFaskesId(payload) {
            try {
                const res = await agent.get(`/HealthFacilities/${payload.faskesId}/healthFacilityPICs`)
                this.loadState(res.data)
            } catch (err) {
                console.error(err)
                dispatch.snackbar.showError('Gagal mendapatkan PIC')
                return err
            }
        },
        async loadPIC(payload, rootState){
            try {
                const res = await agent.get("/HealthFacilityPICs")
                this.loadState(res.data)
            } catch (err) {
                console.error(err)
            }
        },
        async loadSinglePIC(payload, rootState){
            try {
                const res = await agent.get("/HealthFacilityPICs/"+payload)
                return res.data
            } catch (err) {
                console.error(err)
            }
        },
        async addPIC(payload, rootState){
            const { user } = rootState;
            try {
                const pic = {
                    healthFacilityId: payload.healthFacilityId,
                    name: payload.name,
                    position: payload.position,
                    email: payload.email,
                    whatsapp: payload.whatsapp,
                    note: payload.note,
                    createdAt: new Date(),
                    createdId: user.currentUser.id,
                }
                await agent.post('/HealthFacilityPICs', pic)
                await dispatch.faskes.loadData()
                dispatch.snackbar.showSuccess('Berhasil menambahkan PIC')
                history.goBack()
            } catch (err) {
                console.error(err)
                dispatch.snackbar.showError('Gagal menambahkan PIC')
            }
        },
        async editPIC(payload, rootState){
            const { pic, user } = rootState
            try {
                const payloadData = {
                    healthFacilityId: payload.healthFacilityId,
                    name: payload.name,
                    position: payload.position,
                    email: payload.email,
                    whatsapp: payload.whatsapp,
                    note: payload.note,
                    updatedAt: new Date(),
                    updatedId: user.currentUser.id,
                }

                await agent.patch(`/HealthFacilityPICs/${payload.id}`, payloadData)

                const selectedPicIndex = pic.findIndex($item => $item.id === payload.id)
                if (selectedPicIndex !== -1) {
                    const newData = {
                        ...pic[selectedPicIndex],
                        ...payloadData,
                    }
                    newData.updatedAt = newData.updatedAt.toISOString()
                    pic[selectedPicIndex] = newData
                }

                dispatch.snackbar.showSuccess('Berhasil mengupdate PIC')
                history.goBack()
            } catch (err) {
                console.error(err)
                dispatch.snackbar.showError('Gagal mengupdate PIC')
            }
        },
        async deletePIC(payload){
            try {
                await agent.delete(`/HealthFacilityPICs/${payload.picId}`)
                await dispatch.pic.loadPICByFaskesId(payload)
                await dispatch.faskes.loadData()
                dispatch.snackbar.showSuccess('Berhasil menghapus PIC')
            } catch (err) {
                console.error(err)
                dispatch.snackbar.showError('Gagal menghapus PIC')
            }
        }

    }
}
