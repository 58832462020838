import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { ResponsiveContainer, PieChart, Pie, Sector } from "recharts";

import {makeStyles} from "@material-ui/core";

import {getAsalLeadsByProvinsi, getAsalLeadsByKota, getAsalLeadsByKecamatan} from "../../api/dashboard";

const styles = makeStyles({
    paper: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        minHeight: 700,
        maxHeight: 700,
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#15162d',
    },
    subTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#15162d',
        paddingLeft: 20,
    },
    paperPieChart: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    label: {
        fontSize: '16px',
        textShadow: '-1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000',
    },
});

const ChartRegisteredLeads = () => {
    const classes = styles();
    const [asalLeadsByProvinsi, setAsalLeadsByProvinsi] = useState([]);
    const [activeIndexPieProvince, setActiveIndexPieProvince] = useState(0);
    const [asalLeadsByKota, setAsalLeadsByKota] = useState([]);
    const [activeIndexPieCity, setActiveIndexPieCity] = useState(0);
    const [asalLeadsByKecamatan, setAsalLeadsByKecamatan] = useState([]);
    const [activeIndexPieDistrict, setActiveIndexPieDistrict] = useState(0);

    useEffect(()=> {
        (async function () {
            const result1 = await getAsalLeadsByProvinsi();
            const output1 = result1.data.map(item => {
                const newItem = {...item};
                newItem.name = newItem._id.trim().toLowerCase();
                newItem.value = newItem.count;
                delete newItem._id;
                delete newItem.count;
                return newItem;
            });
            const fix1 = output1.reduce(function (prev, curr) {
                const found = prev.find(item => {
                    return item.name === curr.name;
                });
                if (found) {
                    found.value += curr.value;
                } else {
                    prev.push(curr);
                }
                return prev;
            }, []);
            setAsalLeadsByProvinsi(fix1);
            const result2 = await getAsalLeadsByKota();
            const output2 = result2.data.map(item => {
                const newItem = {...item};
                newItem.name = newItem._id.trim().toLowerCase();
                newItem.value = newItem.count;
                delete newItem._id;
                delete newItem.count;
                return newItem;
            });
            const fix2 = output2.reduce(function (prev, curr) {
                const found = prev.find(item => {
                    return item.name === curr.name;
                });
                if (found) {
                    found.value += curr.value;
                } else {
                    prev.push(curr);
                }
                return prev;
            }, []);
            setAsalLeadsByKota(fix2);
            const result3 = await getAsalLeadsByKecamatan();
            const output3 = result3.data.map(item => {
                const newItem = {...item};
                newItem.name = newItem._id.trim().toLowerCase();
                newItem.value = newItem.count;
                delete newItem._id;
                delete newItem.count;
                return newItem;
            });
            const fix3 = output3.reduce(function (prev, curr) {
                const found = prev.find(item => {
                    return item.name === curr.name;
                });
                if (found) {
                    found.value += curr.value;
                } else {
                    prev.push(curr);
                }
                return prev;
            }, []);
            setAsalLeadsByKecamatan(fix3);
        })();
    }, []);

    const renderActiveShape = ({ cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value }) => {
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 20) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={'white'} className={classes.label}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
            </g>
        );
    };

    const onPieProvinceEnter = (data, index) => {
        setActiveIndexPieProvince(index);
    };

    const onPieCityEnter = (data, index) => {
        setActiveIndexPieCity(index);
    };

    const onPieDistrictEnter = (data, index) => {
        setActiveIndexPieDistrict(index);
    };

    return (
        <Grid item xs={8}>
            <Paper elevation={3} className={classes.paper}>
                <Typography className={classes.title}>Leads Terdaftar</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper elevation={0} className={classes.paperPieChart}>
                            <Typography className={classes.subTitle}>Provinsi</Typography>
                            <ResponsiveContainer width={'100%'} height={280}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndexPieProvince}
                                        activeShape={renderActiveShape}
                                        data={asalLeadsByProvinsi}
                                        dataKey={"value"}
                                        cx={'50%'}
                                        cy={'50%'}
                                        innerRadius={75}
                                        outerRadius={95}
                                        fill={'#FF8042'}
                                        onMouseEnter={onPieProvinceEnter}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} className={classes.paperPieChart}>
                            <Typography className={classes.subTitle}>Kabupaten/Kota</Typography>
                            <ResponsiveContainer width={'100%'} height={280}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndexPieCity}
                                        activeShape={renderActiveShape}
                                        data={asalLeadsByKota}
                                        dataKey={"value"}
                                        cx={'50%'}
                                        cy={'50%'}
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill={'#FFBB28'}
                                        onMouseEnter={onPieCityEnter}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} className={classes.paperPieChart}>
                            <Typography className={classes.subTitle}>Kecamatan</Typography>
                            <ResponsiveContainer width={'100%'} height={280}>
                                <PieChart>
                                    <Pie
                                        activeIndex={activeIndexPieDistrict}
                                        activeShape={renderActiveShape}
                                        data={asalLeadsByKecamatan}
                                        dataKey={"value"}
                                        cx={'50%'}
                                        cy={'50%'}
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill={'#00C49F'}
                                        onMouseEnter={onPieDistrictEnter}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default ChartRegisteredLeads;
