import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import PBtn from "./PBtn";

export default function ({ contentText, open, onClose, onSubmit }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Typography
                    style={{
                        fontFamily: "Nunito",
                        fontSize: "18px",
                        color: "#15162d",
                        fontWeight: "600",
                    }}>{contentText}</Typography>
            </DialogContent>

            <DialogActions style={{marginTop: '10px', marginRight: '15px'}}>
                <PBtn onClick={onClose} inverted dropShadow>
                    Batal
                </PBtn>

                <PBtn onClick={onSubmit}>
                    Hapus
                </PBtn>
            </DialogActions>
        </Dialog>
    );
}
