import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from 'react-redux';

import {addNewOffer, updateOffer, getLetterNumber} from "../../../api/offer";
import Autocomplete from "../../utils/Autocomplete";
import {faskesFetcherByNamev2} from "../../../helpers/faskesFetcherByName";
import {IconButton, InputAdornment, makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faTimesCircle, faCheckCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {DatePicker} from "@material-ui/pickers";
import addDays from "date-fns/addDays";
// import format from 'date-fns/format';
// import idLocale from 'date-fns/locale/id';
import {dispatch} from "../../../store";
import {getProducts} from "../../../api/dictionary";

import {statPenawaran} from "../../../constants";
// import {countOfferByFaskesId} from "../../../api/offer";

const useStyles = makeStyles(theme =>({
    wrapper: {
        paddingLeft: 32,
        marginBottom: 10,
    },
    wrap: {
        display: 'inline-flex',
    },
    iconCheckCircle: {
        color: '#2b64af',
        marginRight: 10,
    },
    feature: {
        fontFamily: 'Nunito',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#000000',
        marginTop: -2,
    },
    label: {
        color: 'black',
        fontWeight: 'bold',
        marginBottom: 8,
    },
}));

const PenawaranForm = ({ onClose, currentUser, fetchOffers, edittedOffer, updateOpenPrintPenawaranDialog, isProductActive, isUpdate, setIsUpdate }) => {
    const classes = useStyles();
    const {
        HealthFacility, healthFacilityPic, letterNumber, offerDate, paymentDate,
        offerProductDetail,
    } = edittedOffer;
    const isEdit = typeof edittedOffer.id !== 'undefined';

    const [selectedFaskes, setSelectedFaskes] = useState(HealthFacility ? HealthFacility : null);
    const [healthFacilityPICList, setHealthFacilityPICList] = useState(HealthFacility ? HealthFacility.PIC : []);
    const healthFacilityPICListOld = HealthFacility ? HealthFacility.PIC : [];
    const [selectedHealthFacilityPIC, setSelectedHealthFacilityPIC] = useState(HealthFacility
        ? HealthFacility.PIC.findIndex($item => {
            return $item.namepic === healthFacilityPic.namepic && $item.position === healthFacilityPic.position &&
                $item.emailpic === healthFacilityPic.emailpic && $item.whatsapp === healthFacilityPic.whatsapp &&
                $item.address === healthFacilityPic.address && $item.birthday === healthFacilityPic.birthday;
        })
        : '');
    const selectedHealthFacilityPICOld = HealthFacility
        ? HealthFacility.PIC.findIndex($item => {
            return $item.namepic === healthFacilityPic.namepic && $item.position === healthFacilityPic.position &&
                $item.emailpic === healthFacilityPic.emailpic && $item.whatsapp === healthFacilityPic.whatsapp &&
                $item.address === healthFacilityPic.address && $item.birthday === healthFacilityPic.birthday;
        })
        : '';
    const [statusPenawaran, setStatusPenawaran] = useState(
        edittedOffer.offerStatus
            ? edittedOffer.offerStatus
            : statPenawaran[0].value
    );
    const [nomorSurat, setNomorSurat] = useState(letterNumber || '');
    const [nomorSuratUpdate, setNomorSuratUpdate] = useState('');
    const [tanggalPenawaran, setTanggalPenawaran] = useState(offerDate ? new Date(offerDate) : new Date());
    const [tanggalPembayaran, setTanggalPembayaran] = useState(
        isEdit
            ? paymentDate
                ? new Date(paymentDate)
                : null
            : addDays(new Date(), 7)
    );
    // const [tawaranProduk, setTawaranProduk] = useState(offerProductDetail ? offerProductDetail.name : '');
    // const [jumlah, setJumlah] = useState(offerProductDetail ? offerProductDetail.quantity : '');
    // const [lamaBerlangganan, setLamaBerlangganan] = useState(offerProductDetail ? offerProductDetail.subscription : '');
    // const [harga, setHarga] = useState(offerProductDetail ? offerProductDetail.total : '');
    // const [diskon, setDiskon] = useState(offerProductDetail ? offerProductDetail.discount : '');
    const [grandTotal, setGrandTotal] = useState(offerProductDetail ? offerProductDetail.grandTotal : '0');
    // const [daftarFitur, setDaftarFitur] = useState(offerProductDetail ? offerProductDetail.features : ['']);
    const [terminList, setTerminList] = useState(
        edittedOffer.terminList
            ? edittedOffer.terminList
            : [{
                tanggal: new Date(),
                jumlah: 0
            }]
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [note, setNote] = useState(edittedOffer.note ? edittedOffer.note : "");
    const [totalTermin, setTotalTermin] = useState("");
    // const [totalOffer, setTotalOffer] = useState("");
    // const [fileName, setFileName] = useState("Penawaran Default");
    const [produkList, setProdukList] = useState([]);
    const [selectedProduk, setSelectedProduk] = useState(offerProductDetail ? offerProductDetail.products : [{
        subscription: '',
        cost: '',
        discount: '',
        total: '0',
    }]);
    const [customProduk, setCustomProduk] = useState(offerProductDetail ? offerProductDetail.products : [{
        name: '',
        features: [''],
        subscription: '',
        cost: '',
        discount: '',
        total: '0',
    }]);
    const validateInt = RegExp(/^[0-9\b]+$/);
    const [keyFaskesAutoComplete, setKeyFaskesAutoComplete] = useState(0);

    useEffect(() => {
        if (produkList.length === 0) {
            (async () => {
                try {
                    const result = await getProducts();
                    setProdukList(result.data);
                } catch(err) {
                    console.error(err);
                }
            })();
        }
        if (isUpdate) {
            (async () => {
                const letterNumberResult = await getLetterNumber();
                let paddedNumber = `${letterNumberResult.data[0].number}`.padStart(3, '0');
                const year = letterNumberResult.data[0].year;
                const part1 = letterNumberResult.data[0].part1;
                const part2 = letterNumberResult.data[0].part2;
                const part3 = letterNumberResult.data[0].part3;
                setNomorSuratUpdate(`${paddedNumber}/${part1}/${part2}/${part3}/${year}`);
            })();
            setStatusPenawaran('waiting');
        }
        if (!isEdit && nomorSurat === '') {
            (async () => {
                const letterNumberResult = await getLetterNumber();
                let paddedNumber = `${letterNumberResult.data[0].number}`.padStart(3, '0');
                const year = letterNumberResult.data[0].year;
                const part1 = letterNumberResult.data[0].part1;
                const part2 = letterNumberResult.data[0].part2;
                const part3 = letterNumberResult.data[0].part3;
                setNomorSurat(`${paddedNumber}/${part1}/${part2}/${part3}/${year}`);
            })();
        }

        let flagTerminList = [];
        let sum = 0;
        flagTerminList = terminList.map(data => data.jumlah);
        for(let i = 0; i < terminList.length; i++){
            sum += parseInt(flagTerminList[i], 10)
        }
        setTotalTermin(sum.toString());

        let flagGrandTotal = [];
        let sumGrandTotal = 0;
        if(isProductActive){
            flagGrandTotal = selectedProduk.map(data => data.total);
            for(let i = 0; i < selectedProduk.length; i++){
                sumGrandTotal += parseInt(flagGrandTotal[i], 10);
            }
            setGrandTotal(sumGrandTotal.toString());
        } else {
            flagGrandTotal = customProduk.map(data => data.total);
            for(let i = 0; i < customProduk.length; i++){
                sumGrandTotal += parseInt(flagGrandTotal[i], 10);
            }
            setGrandTotal(sumGrandTotal.toString());
        }
        // if(selectedFaskes !== null){
        //     (async () => {
        //         const resultCountOffer = await countOfferByFaskesId(selectedFaskes.id);
        //         setTotalOffer(resultCountOffer.data.count);
        //         setFileName(`${selectedFaskes.name}-${format(new Date(tanggalPenawaran), "dd MMMM yyyy", {locale: idLocale})}-Penawaran ${totalOffer}`);
        //     })();
        // }
    }, [isEdit, terminList, selectedFaskes, produkList, nomorSurat, isUpdate, selectedProduk, customProduk, isProductActive]);

    function onSelectFaskes(item) {
        if(item.picId === "m4n4j3m3n") {
            dispatch.snackbar.showError("PIC Assist.id untuk leads ini belum ditentukan");
            setKeyFaskesAutoComplete(keyFaskesAutoComplete + 1);
        } else {
            setSelectedFaskes(item);
            setHealthFacilityPICList(item.PIC);
        }
    }

    function handleDeleteFaskes() {
        if (isEdit) {
            setSelectedFaskes(null);
            setHealthFacilityPICList([]);
            setSelectedHealthFacilityPIC('');
        } else {
            setSelectedFaskes(null);
            setHealthFacilityPICList([]);
            setSelectedHealthFacilityPIC('');
        }
    }

    function handleChangeHealthFacilityPIC(e) {
        const { value } = e.target;
        setSelectedHealthFacilityPIC(value);
    }

    function handleChangeStatusPenawaran(e) {
        const { value } = e.target;
        setStatusPenawaran(value);
    }

    const handleChangeTanggalPenawaran = (date) => {
        setTanggalPenawaran(date);
        setTanggalPembayaran(addDays(date, 7));
    };

    const handleChangeTanggalPembayaran = (date) => {
        setTanggalPembayaran(date);
    };

    const handleChangeTanggalTermin = idx => (date) => {
        const flagTerminList = JSON.parse(JSON.stringify(terminList));
        flagTerminList[idx].tanggal = date;
        setTerminList([...flagTerminList]);
    };

    function handleDeleteTermin(idx) {
        const flagTerminList = JSON.parse(JSON.stringify(terminList));
        flagTerminList.splice(idx, 1);
        setTerminList([...flagTerminList]);
    }

    function handleChangeJumlahTermin(e, idx) {
        const { value } = e.target;
        const flagTerminList = JSON.parse(JSON.stringify(terminList));
        const parsedGrandTotal = parseInt(grandTotal, 10);
        if(validateInt.test(value)){
            if(value >= parsedGrandTotal){
                flagTerminList[idx] = {...flagTerminList[idx], jumlah: parsedGrandTotal};
                setTerminList([...flagTerminList]);
            } else {
                flagTerminList[idx] = {...flagTerminList[idx], jumlah: value};
                setTerminList([...flagTerminList]);
            }
        } else {
            flagTerminList[idx] = {...flagTerminList[idx], jumlah: 0};
            setTerminList([...flagTerminList])
        }
    }

    function handleTambahTermin() {
        // terminList.push({
        //     tanggal: new Date(),
        //     jumlah: 0,
        // });
        // setTerminList([...terminList]);
        setTerminList([...terminList, {tanggal: new Date(), jumlah: 0}]);
    }

    function handleSelectProduct(e, i) {
        const { value } = e.target;
        const flagSelectedProduk = JSON.parse(JSON.stringify(selectedProduk));
        value.subscription = "1 Bulan";
        value.cost = value.price;
        value.total = value.price;
        flagSelectedProduk[i] = value;
        setSelectedProduk([...flagSelectedProduk]);
        // setLamaBerlangganan("1 Bulan");
        // setHarga(value.price);
        // const parsedHarga = parseInt(value.price, 10);
        // const parsedDiskon = diskon === '' ? 0 : parseInt(diskon, 10);
        // const countedGrandTotal = parsedHarga - parsedDiskon;
        // setGrandTotal(value.price);
        // setGrandTotal(countedGrandTotal.toString());
    }

    function handleTambahProduk() {
        if(isProductActive){
            setSelectedProduk([...selectedProduk, {subscription: '', cost: '', discount: '', total: '0'}]);
        } else {
            setCustomProduk([...customProduk, {name: '', features: [''], subscription: '', cost: '', discount: '', total: '0'}])
        }
    }

    function handleDeleteProduk(i) {
        if(isProductActive){
            const flagSelectedProduk = JSON.parse(JSON.stringify(selectedProduk));
            flagSelectedProduk.splice(i, 1);
            setSelectedProduk([...flagSelectedProduk]);
        } else{
            const flagCustomProduk = JSON.parse(JSON.stringify(customProduk));
            flagCustomProduk.splice(i, 1);
            setCustomProduk([...flagCustomProduk]);
        }
    }

    function handleChangeSelectProduct(i) {
        const flagSelectedProduk = JSON.parse(JSON.stringify(selectedProduk));
        flagSelectedProduk[i] = {subscription: '', cost: '', discount: '', total: '0'};
        setSelectedProduk([...flagSelectedProduk]);
        // setSelectedProduk('');
        // setLamaBerlangganan('');
        // setHarga('');
        // setDiskon('');
        // setGrandTotal('0');
        // setTerminList([{
        //     tanggal: new Date(),
        //     jumlah: 0,
        // }]);
    }

    function handleChangeLamaBerlangganan(e, i) {
        const { value } = e.target;
        if(isProductActive){
            const flagSelectedProduk = JSON.parse(JSON.stringify(selectedProduk));
            const parsedPrice = flagSelectedProduk[i].price === '' ? 0 : parseInt(flagSelectedProduk[i].price, 10);
            const parsedDiscount = flagSelectedProduk[i].discount === '' ? 0 : parseInt(flagSelectedProduk[i].discount, 10);
            flagSelectedProduk[i] = {...flagSelectedProduk[i], subscription: value};
            let countedCost = 0;
            let countedTotal = 0;
            if(value === "1 Bulan"){
                countedCost = parsedPrice * 1;
                countedTotal = countedCost - parsedDiscount;
                flagSelectedProduk[i] = {...flagSelectedProduk[i], cost: countedCost.toString(), total: countedTotal.toString()};
                setSelectedProduk([...flagSelectedProduk]);
            } else if(value === "2 Bulan"){
                countedCost = parsedPrice * 2;
                countedTotal = countedCost - parsedDiscount;
                flagSelectedProduk[i] = {...flagSelectedProduk[i], cost: countedCost.toString(), total: countedTotal.toString()};
                setSelectedProduk([...flagSelectedProduk]);
            } else if(value === "3 Bulan"){
                countedCost = parsedPrice * 3;
                countedTotal = countedCost - parsedDiscount;
                flagSelectedProduk[i] = {...flagSelectedProduk[i], cost: countedCost.toString(), total: countedTotal.toString()};
                setSelectedProduk([...flagSelectedProduk]);
            } else if(value === "6 Bulan"){
                countedCost = parsedPrice * 6;
                countedTotal = countedCost - parsedDiscount;
                flagSelectedProduk[i] = {...flagSelectedProduk[i], cost: countedCost.toString(), total: countedTotal.toString()};
                setSelectedProduk([...flagSelectedProduk]);
            } else if(value === "1 Tahun"){
                countedCost = parsedPrice * 12;
                countedTotal = countedCost - parsedDiscount;
                flagSelectedProduk[i] = {...flagSelectedProduk[i], cost: countedCost.toString(), total: countedTotal.toString()};
                setSelectedProduk([...flagSelectedProduk]);
            } else if(value === "2 Tahun"){
                countedCost = parsedPrice * 24;
                countedTotal = countedCost - parsedDiscount;
                flagSelectedProduk[i] = {...flagSelectedProduk[i], cost: countedCost.toString(), total: countedTotal.toString()};
                setSelectedProduk([...flagSelectedProduk]);
            } else if(value === "3 Tahun"){
                countedCost = parsedPrice * 36;
                countedTotal = countedCost - parsedDiscount;
                flagSelectedProduk[i] = {...flagSelectedProduk[i], cost: countedCost.toString(), total: countedTotal.toString()};
                setSelectedProduk([...flagSelectedProduk]);
            } else {
                flagSelectedProduk[i] = {...flagSelectedProduk[i], cost: '0', total: '0'};
                setSelectedProduk([...flagSelectedProduk]);
            }
        } else {
            const flagCustomProduk = JSON.parse(JSON.stringify(customProduk));
            flagCustomProduk[i] = {...flagCustomProduk[i], subscription: value};
            setCustomProduk([...flagCustomProduk]);
        }
    }

    function handleChangeDiskon(e, i) {
        const { value } = e.target;
        const flagSelectedProduk = JSON.parse(JSON.stringify(selectedProduk));
        const flagCustomProduk = JSON.parse(JSON.stringify(customProduk));
        if(validateInt.test(value)){
            if(isProductActive){
                const parsedValue = value === '' ? 0 : parseInt(value, 10);
                const parsedCost = flagSelectedProduk[i].cost === '' ? 0 : parseInt(flagSelectedProduk[i].cost, 10);
                let parsedDiscount;
                let countedTotal;
                if(parsedValue > parsedCost){
                    parsedDiscount = parsedCost;
                    countedTotal = parsedCost - parsedDiscount;
                    flagSelectedProduk[i] = {...flagSelectedProduk[i], discount: parsedCost.toString(), total: countedTotal.toString()};
                    setSelectedProduk([...flagSelectedProduk]);
                } else {
                    parsedDiscount = value === '' ? 0 : parseInt(value, 10);
                    countedTotal = parsedCost - parsedDiscount;
                    flagSelectedProduk[i] = {...flagSelectedProduk[i], discount: value, total: countedTotal.toString()};
                    setSelectedProduk([...flagSelectedProduk]);
                }
            } else {
                const parsedCost = flagCustomProduk[i].cost === '' ? 0 : parseInt(flagCustomProduk[i].cost, 10);
                const parsedValue = value === '' ? 0 : parseInt(value, 10);
                let parsedDiscount;
                let countedTotal;
                if(parsedValue > parsedCost){
                    parsedDiscount = parsedCost;
                    countedTotal = parsedCost - parsedDiscount;
                    flagCustomProduk[i] = {...flagCustomProduk[i], discount: parsedCost.toString(), total: countedTotal.toString()};
                    setCustomProduk([...flagCustomProduk]);
                } else {
                    parsedDiscount = value === '' ? 0 : parseInt(value, 10);
                    countedTotal = parsedCost - parsedDiscount;
                    flagCustomProduk[i] = {...flagCustomProduk[i], discount: value, total: countedTotal.toString()};
                    setCustomProduk([...flagCustomProduk]);
                }
            }
        } else {
            if(isProductActive){
                flagSelectedProduk[i] = {...flagSelectedProduk[i], discount: '0', total: flagSelectedProduk[i].cost === '' ? '0' : flagSelectedProduk[i].cost};
                setSelectedProduk([...flagSelectedProduk]);
            } else {
                flagCustomProduk[i] = {...flagCustomProduk[i], discount: '', total: flagCustomProduk[i].cost === '' ? '0' : flagCustomProduk[i].cost};
                setCustomProduk([...flagCustomProduk]);
            }
        }
    }

    function handleChangeCustomProduk(e, i) {
        const { value } = e.target;
        const flagCustomProduk = JSON.parse(JSON.stringify(customProduk));
        flagCustomProduk[i] = {...flagCustomProduk[i], name: value};
        setCustomProduk([...flagCustomProduk]);
    }

    function handleChangeFitur(e, idxProduk, idxFitur) {
        const { value } = e.target;
        const flagCustomProduk = JSON.parse(JSON.stringify(customProduk));
        flagCustomProduk[idxProduk].features[idxFitur] = value;
        setCustomProduk([...flagCustomProduk]);
    }

    function handleDeleteFitur(idxProduk, idxFitur) {
        const flagCustomProduk = JSON.parse(JSON.stringify(customProduk));
        flagCustomProduk[idxProduk].features.splice(idxFitur, 1);
        setCustomProduk([...flagCustomProduk])
    }

    function handleTambahFitur(i) {
        const flagCustomProduk = JSON.parse(JSON.stringify(customProduk));
        flagCustomProduk[i].features = [...flagCustomProduk[i].features, ''];
        setCustomProduk([...flagCustomProduk]);
    }

    function handleChangeHarga(e, i) {
        const { value } = e.target;
        const flagCustomProduk = JSON.parse(JSON.stringify(customProduk));
        const parsedCost = value === '' ? 0 : parseInt(value, 10);
        const parsedDiscount = flagCustomProduk[i].discount === '' ? 0 : parseInt(flagCustomProduk[i].discount, 10);
        const countedTotal = parsedCost - parsedDiscount;
        flagCustomProduk[i] = {...flagCustomProduk[i], cost: value, total: countedTotal.toString()};
        setCustomProduk([...flagCustomProduk]);
    }

    async function handleSimpan() {
        const {snackbar} = dispatch;

        if (!isEdit) {
            try {
                const selectedPICFaskes = healthFacilityPICList[selectedHealthFacilityPIC];
                const fileName = `Penawaran ${selectedFaskes.name} - ${nomorSurat}`;

                const payload = {
                    healthFacilityId: selectedFaskes.id,
                    picId: selectedFaskes.picId,
                    healthFacilityPic: selectedPICFaskes,
                    offerStatus: statusPenawaran,
                    note: note,
                    fileName: fileName,
                    letterNumber: nomorSurat,
                    offerDate: tanggalPenawaran,
                    paymentDate: tanggalPembayaran,
                    // offerProductDetail: {
                    //     name: tawaranProduk,
                    //     features: daftarFitur,
                    //     quantity: jumlah,
                    //     total: harga,
                    //     discount: diskon,
                    //     grandTotal,
                    // },
                    terminList,
                    paymentStatus: 'waiting',
                    createdAt: new Date(),
                    createdId: currentUser.id,
                };

                if(isProductActive){
                    if (selectedProduk) {
                        payload.offerProductDetail = {
                            products: [
                                ...selectedProduk,
                            ],
                            grandTotal,
                        };
                    }
                } else {
                    payload.offerProductDetail = {
                        products: [
                            ...customProduk,
                        ],
                        grandTotal,
                    };
                }

                setIsSubmitting(true);
                await addNewOffer(payload);
                setIsSubmitting(false);
                snackbar.showSuccess('Berhasil menambahkan penawaran baru');
                fetchOffers();
                onClose();
            } catch (err) {
                console.error(err);
                setIsSubmitting(false);
                snackbar.showError('Gagal menambahkan penawaran baru');
            }
        } else if (isEdit && isUpdate){
            try {
                const selectedPICFaskes = healthFacilityPICList[selectedHealthFacilityPIC];
                const selectedPICFaskesOld = healthFacilityPICListOld[selectedHealthFacilityPICOld];
                const fileName = `Penawaran ${selectedFaskes.name} - ${nomorSuratUpdate}`;
                const fileNameOld = `Penawaran ${edittedOffer.HealthFacility.name} - ${nomorSurat}`;

                const newPayload = {
                    healthFacilityId: selectedFaskes._id,
                    picId: selectedFaskes.picId,
                    healthFacilityPic: selectedPICFaskes,
                    offerStatus: statusPenawaran,
                    note: note,
                    fileName: fileName,
                    letterNumber: nomorSuratUpdate,
                    offerDate: tanggalPenawaran,
                    paymentDate: tanggalPembayaran,
                    terminList,
                    paymentStatus: 'waiting',
                    createdAt: new Date(),
                    createdId: currentUser.id,
                };

                const oldPayload = {
                    healthFacilityId: edittedOffer.HealthFacility.id,
                    picId: edittedOffer.HealthFacility.picId,
                    healthFacilityPic: selectedPICFaskesOld,
                    offerStatus: 'revised',
                    note: 'Update Penawaran',
                    fileName: fileNameOld,
                    letterNumber: nomorSurat,
                    offerDate: new Date(edittedOffer.offerDate),
                    paymentDate: new Date(edittedOffer.paymentDate),
                    terminList: edittedOffer.terminList,
                    updatedAt: new Date(),
                    updatedId: currentUser.id,
                }

                if(isProductActive){
                    if(selectedProduk){
                        newPayload.offerProductDetail = {
                            products: [
                                ...selectedProduk,
                            ],
                            grandTotal,
                        };
                        oldPayload.offerProductDetail = {
                            ...edittedOffer.offerProductDetail,
                        };
                    }
                } else {
                    newPayload.offerProductDetail = {
                        products: [
                            ...customProduk,
                        ],
                        grandTotal,
                    };
                    oldPayload.offerProductDetail = {
                        ...edittedOffer.offerProductDetail,
                    };
                }

                setIsSubmitting(true);
                await addNewOffer(newPayload);
                await updateOffer(edittedOffer.id, oldPayload);
                setIsSubmitting(false);
                snackbar.showSuccess('Berhasil mengupdate penawaran');
                fetchOffers();
                onClose();

            } catch (e) {
                console.error(e);
                setIsSubmitting(false);
                snackbar.showError('Gagal mengupdate penawaran');
            }
        } else {
            try {
                const selectedPICFaskes = healthFacilityPICList[selectedHealthFacilityPIC];
                const fileName = `Penawaran ${selectedFaskes.name} - ${nomorSurat}`;

                const payload = {
                    healthFacilityId: selectedFaskes.id,
                    picId: selectedFaskes.picId,
                    healthFacilityPic: selectedPICFaskes,
                    offerStatus: statusPenawaran,
                    note: note,
                    fileName: fileName,
                    letterNumber: nomorSurat,
                    offerDate: tanggalPenawaran,
                    paymentDate: tanggalPembayaran,
                    // offerProductDetail: {
                    //     name: tawaranProduk,
                    //     features: daftarFitur,
                    //     quantity: jumlah,
                    //     total: harga,
                    //     discount: diskon,
                    //     grandTotal,
                    // },
                    terminList,
                    updatedAt: new Date(),
                    updatedId: currentUser.id,
                };

                if(isProductActive){
                    if (selectedProduk) {
                        payload.offerProductDetail = {
                            products: [
                                ...selectedProduk,
                            ],
                            grandTotal,
                        };
                    }
                } else {
                    payload.offerProductDetail = {
                        products: [
                            ...customProduk,
                        ],
                        grandTotal,
                    };
                }

                setIsSubmitting(true);
                await updateOffer(edittedOffer.id, payload);
                setIsSubmitting(false);
                snackbar.showSuccess('Berhasil mengupdate penawaran');
                fetchOffers();
                onClose();
            } catch (err) {
                console.error(err);
                setIsSubmitting(false);
                snackbar.showError('Gagal mengupdate penawaran');
            }
        }
    }

    return (
        <Grid container>
            <Grid container>
                <Grid item xs={12} style={{ marginBottom: 16 }}>
                    {
                        isEdit && <Grid item container justify="flex-end" xs={12}>
                            <PBtn onClick={() => {
                                updateOpenPrintPenawaranDialog(true);
                            }}>Lihat PDF Penawaran</PBtn>
                        </Grid>
                    }
                    <Grid container spacing={4} style={{ borderBottom: '1px solid black', marginBottom: 24 }}>
                        <Grid container item md={6}>
                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Nama Klinik</Typography>
                                {
                                    selectedFaskes
                                        ? <PInput
                                            value={selectedFaskes.name}
                                            InputProps={{
                                                endAdornment: isEdit && !isUpdate
                                                    ? null
                                                    : <InputAdornment position="end">
                                                        <IconButton onClick={() => handleDeleteFaskes()}>
                                                            <FontAwesomeIcon icon={faTimesCircle}/>
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            fullWidth
                                            disabled={isEdit && !isUpdate}
                                        />
                                        : <Autocomplete
                                            key={keyFaskesAutoComplete}
                                            fetcher={faskesFetcherByNamev2}
                                            onChange={onSelectFaskes}
                                            placeholder="Nama klinik"
                                            variant="outlined"
                                            margin="dense"
                                            itemToString="name"
                                            fullWidth
                                        />
                                }
                            </Grid>

                            {/*<Grid item xs={12} style={{ marginBottom: 24 }}>*/}
                            {/*    <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>PIC Klinik</Typography>*/}
                            {/*    <PInput*/}
                            {/*        fullWidth*/}
                            {/*        disabled={isEdit}*/}
                            {/*        select*/}
                            {/*        value={selectedHealthFacilityPIC}*/}
                            {/*        onChange={(e) => handleChangeHealthFacilityPIC(e)}*/}
                            {/*    >*/}
                            {/*        {*/}
                            {/*            healthFacilityPICList.map(($healthFacilityPic, idx) => (*/}
                            {/*                <MenuItem key={`${$healthFacilityPic}-${idx}`} value={idx}>*/}
                            {/*                    {$healthFacilityPic.namepic}*/}
                            {/*                </MenuItem>*/}
                            {/*            ))*/}
                            {/*        }*/}
                            {/*    </PInput>*/}
                            {/*</Grid>*/}
                        </Grid>

                        <Grid item md={6}>
                            <Grid item xs={12} style={{ marginTop: 0 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>PIC Klinik</Typography>
                                <PInput
                                    fullWidth
                                    disabled={isEdit && !isUpdate}
                                    select
                                    value={selectedHealthFacilityPIC}
                                    onChange={(e) => handleChangeHealthFacilityPIC(e)}
                                >
                                    {
                                        healthFacilityPICList.map(($healthFacilityPic, idx) => (
                                            <MenuItem key={`${$healthFacilityPic}-${idx}`} value={idx}>
                                                {$healthFacilityPic.namepic}
                                            </MenuItem>
                                        ))
                                    }
                                </PInput>
                                {/*<Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Status Penawaran</Typography>*/}
                                {/*<PInput*/}
                                {/*    fullWidth*/}
                                {/*    select*/}
                                {/*    value={statusPenawaran}*/}
                                {/*    onChange={(e) => handleChangeStatusPenawaran(e)}*/}
                                {/*>*/}
                                {/*    {*/}
                                {/*        statPenawaran.map($offerStatus => (*/}
                                {/*            <MenuItem key={$offerStatus.value} value={$offerStatus.value}>*/}
                                {/*                {$offerStatus.label}*/}
                                {/*            </MenuItem>*/}
                                {/*        ))*/}
                                {/*    }*/}
                                {/*</PInput>*/}
                            </Grid>
                            {/*{statusPenawaran === "revised" || statusPenawaran === "rejected" ? (*/}
                            {/*    <Grid item xs={12} style={{ marginTop: 34 }}>*/}
                            {/*        <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Alasan</Typography>*/}
                            {/*        <PInput*/}
                            {/*            multiline*/}
                            {/*            fullWidth*/}
                            {/*            rows={3}*/}
                            {/*            value={note}*/}
                            {/*            onChange={(e) => setNote(e.target.value)}*/}
                            {/*        />*/}
                            {/*    </Grid>*/}
                            {/*) : ""}*/}
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item md={12}>
                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Nomor Surat</Typography>
                                <PInput
                                    placeholder="Nomor Surat"
                                    value={isEdit && isUpdate ? nomorSuratUpdate : nomorSurat}
                                    // onChange={(e) => setNomorSurat(e.target.value)}
                                    fullWidth
                                    disabled={isEdit && !isUpdate}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>
                                        <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Tanggal Penawaran</Typography>
                                        <DatePicker
                                            value={tanggalPenawaran}
                                            onChange={handleChangeTanggalPenawaran}
                                            format="dd MMMM yyyy"
                                            inputVariant="outlined"
                                            margin="dense"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                                </InputAdornment>
                                            }}
                                            fullWidth
                                            required
                                            disabled={isEdit && !isUpdate}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Batas Pembayaran</Typography>
                                        <DatePicker
                                            value={tanggalPembayaran}
                                            onChange={handleChangeTanggalPembayaran}
                                            format="dd MMMM yyyy"
                                            inputVariant="outlined"
                                            margin="dense"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                                </InputAdornment>
                                            }}
                                            helperText="Pastikan Tanggal Batas Pembayaran Merupakan Jam Kerja"
                                            fullWidth
                                            required
                                            disabled={isEdit && !isUpdate}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>Informasi Tawaran Produk</Typography>
                            {
                                isProductActive ? selectedProduk.map((data, idx) => (
                                    <Grid container spacing={4} key={idx}>
                                        <Grid container item sm={12} md={4}>
                                            <Grid item xs={12} style={{ marginBottom: 8}}>
                                                { isEdit === false && !data.name ? (
                                                    <PInput
                                                        fullWidth
                                                        select
                                                        label="Nama Produk"
                                                        value={data}
                                                        onChange={(e) => handleSelectProduct(e, idx)}
                                                    >
                                                        {
                                                            produkList.map((produk, $idx) => (
                                                                <MenuItem key={$idx} value={produk}>
                                                                    {produk.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </PInput>
                                                ) : isEdit === true && !data.name ? (
                                                    <PInput
                                                        fullWidth
                                                        disabled={isEdit && !isUpdate}
                                                        select
                                                        label="Nama Produk"
                                                        value={data}
                                                        onChange={(e) => handleSelectProduct(e, idx)}
                                                    >
                                                        {
                                                            produkList.map((produk, $idx) => (
                                                                <MenuItem key={$idx} value={produk}>
                                                                    {produk.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </PInput>
                                                ) : (
                                                    <PInput
                                                        fullWidth
                                                        disabled={isEdit && !isUpdate}
                                                        label="Nama Produk"
                                                        value={data.name}
                                                        InputProps={{
                                                            endAdornment: isEdit && !isUpdate ? null : <InputAdornment position="end">
                                                                <IconButton onClick={()=> handleChangeSelectProduct(idx)}>
                                                                    <FontAwesomeIcon icon={faTimesCircle}/>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    data.features && data.features.map((fitur, i) => (
                                                        <div key={i} className={classes.wrapper}>
                                                            <div className={classes.wrap}>
                                                                <FontAwesomeIcon icon={faCheckCircle} className={classes.iconCheckCircle}/>
                                                                <Typography className={classes.feature}>{fitur}</Typography>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={12} md={4}>
                                            <PInput
                                                label="Lama Berlangganan"
                                                value={data.subscription}
                                                onChange={(e)=> handleChangeLamaBerlangganan(e, idx)}
                                                fullWidth
                                                select
                                                disabled={isEdit && !isUpdate}
                                            >
                                                <MenuItem value={"1 Bulan"}>1 Bulan</MenuItem>
                                                <MenuItem value={"2 Bulan"}>2 Bulan</MenuItem>
                                                <MenuItem value={"3 Bulan"}>3 Bulan</MenuItem>
                                                <MenuItem value={"6 Bulan"}>6 Bulan</MenuItem>
                                                <MenuItem value={"1 Tahun"}>1 Tahun</MenuItem>
                                                <MenuItem value={"2 Tahun"}>2 Tahun</MenuItem>
                                                <MenuItem value={"3 Tahun"}>3 Tahun</MenuItem>
                                            </PInput>
                                        </Grid>
                                        <Grid item sm={12} md={4}>
                                            <PInput
                                                label="Harga"
                                                placeholder="Harga"
                                                value={data.cost}
                                                // onChange={(e) => handleChangeHarga(e)}
                                                fullWidth
                                                style={{ marginBottom: 8 }}
                                                disabled
                                            />
                                            <PInput
                                                label="Diskon"
                                                placeholder="Diskon"
                                                value={data.discount}
                                                onChange={(e) => handleChangeDiskon(e, idx)}
                                                fullWidth
                                                style={{ marginBottom: 8 }}
                                                disabled={isEdit && !isUpdate}
                                            />
                                            <PInput
                                                label="Total"
                                                placeholder="Total"
                                                value={data.total}
                                                fullWidth
                                                disabled={isEdit && !isUpdate}
                                            />
                                            {
                                                idx > 0 ? <PBtn style={{ marginTop: 8, float: 'right' }} onClick={()=> handleDeleteProduk(idx)}>Hapus</PBtn> : null
                                            }
                                        </Grid>
                                    </Grid>
                                )) : customProduk.map((data, idx) => (
                                    <Grid container spacing={4} key={idx}>
                                        <Grid container item sm={12} md={4}>
                                            <Grid item xs={12} style={{ marginBottom: 8}}>
                                                <PInput
                                                    fullWidth
                                                    label="Nama Produk"
                                                    placeholder="Nama Produk"
                                                    value={data.name}
                                                    onChange={(e)=> handleChangeCustomProduk(e, idx)}
                                                    disabled={isEdit && !isUpdate}
                                                />
                                            </Grid>
                                            {
                                                data.features.map((fitur, i) => (
                                                    <Grid item xs={12} key={i} style={{ paddingLeft: 32, display: 'flex'}}>
                                                        <PInput
                                                            disabled={isEdit && !isUpdate}
                                                            label={`Fitur-${i+1}`}
                                                            value={fitur}
                                                            onChange={(e)=> handleChangeFitur(e, idx, i)}
                                                            placeholder="Fitur Produk"
                                                            fullWidth
                                                        />
                                                        {
                                                            !isEdit || isUpdate ? <IconButton onClick={()=> handleDeleteFitur(idx, i)}>
                                                                <FontAwesomeIcon icon={faTimesCircle}/>
                                                            </IconButton> : null
                                                        }
                                                    </Grid>
                                                ))
                                            }
                                            <Grid item xs={12} style={{ marginTop: 8 }}>
                                                <PBtn disabled={isEdit && !isUpdate} onClick={() => handleTambahFitur(idx)} style={{ width: '100%' }}>Tambah Fitur</PBtn>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={12} md={4}>
                                            <PInput
                                                label="Lama Berlangganan"
                                                value={data.subscription}
                                                onChange={(e)=> handleChangeLamaBerlangganan(e, idx)}
                                                fullWidth
                                                select
                                                disabled={isEdit && !isUpdate}
                                            >
                                                <MenuItem value={"1 Bulan"}>1 Bulan</MenuItem>
                                                <MenuItem value={"2 Bulan"}>2 Bulan</MenuItem>
                                                <MenuItem value={"3 Bulan"}>3 Bulan</MenuItem>
                                                <MenuItem value={"6 Bulan"}>6 Bulan</MenuItem>
                                                <MenuItem value={"1 Tahun"}>1 Tahun</MenuItem>
                                                <MenuItem value={"2 Tahun"}>2 Tahun</MenuItem>
                                                <MenuItem value={"3 Tahun"}>3 Tahun</MenuItem>
                                            </PInput>
                                        </Grid>
                                        <Grid item sm={12} md={4}>
                                            <PInput
                                                label="Harga"
                                                placeholder="Harga"
                                                value={data.cost}
                                                onChange={(e) => handleChangeHarga(e, idx)}
                                                fullWidth
                                                style={{ marginBottom: 8, }}
                                                disabled={isEdit && !isUpdate}
                                            />
                                            <PInput
                                                label="Diskon"
                                                placeholder="Diskon"
                                                value={data.discount}
                                                onChange={(e) => handleChangeDiskon(e, idx)}
                                                fullWidth
                                                style={{ marginBottom: 8 }}
                                                disabled={isEdit && !isUpdate}
                                            />
                                            <PInput
                                                label="Total"
                                                placeholder="Total"
                                                value={data.total}
                                                fullWidth
                                                disabled={isEdit && !isUpdate}
                                            />
                                            {
                                                idx > 0 ? <PBtn style={{ marginTop: 8, float: 'right' }} onClick={()=> handleDeleteProduk(idx)}>Hapus</PBtn> : null
                                            }
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            {/*<Grid container spacing={4}>*/}
                                {/*<Grid container item sm={12} md={4}>*/}
                                {/*    <Grid item xs={12} style={{ marginBottom: 8 }}>*/}
                                {/*        {*/}
                                {/*            !isProductActive ? (*/}
                                {/*                <PInput*/}
                                {/*                    fullWidth*/}
                                {/*                    label="Nama Produk"*/}
                                {/*                    placeholder="Nama Produk"*/}
                                {/*                    value={customProduk}*/}
                                {/*                    onChange={(e)=> setCustomProduk(e.target.value)}*/}
                                {/*                    disabled={isEdit && !isUpdate}*/}

                                {/*                />*/}
                                {/*            ) : isEdit === false && selectedProduk === '' ? (*/}
                                {/*                <PInput*/}
                                {/*                    fullWidth*/}
                                {/*                    select*/}
                                {/*                    label="Nama Produk"*/}
                                {/*                    value={selectedProduk}*/}
                                {/*                    onChange={(e) => handleSelectProduct(e)}*/}
                                {/*                >*/}
                                {/*                    {*/}
                                {/*                        produkList.map((produk, idx) => (*/}
                                {/*                            <MenuItem key={idx} value={produk}>*/}
                                {/*                                {produk.name}*/}
                                {/*                            </MenuItem>*/}
                                {/*                        ))*/}
                                {/*                    }*/}
                                {/*                </PInput>*/}
                                {/*            ) : isEdit === true && selectedProduk === '' ? (*/}
                                {/*                <PInput*/}
                                {/*                    fullWidth*/}
                                {/*                    disabled={isEdit && !isUpdate}*/}
                                {/*                    select*/}
                                {/*                    label="Nama Produk"*/}
                                {/*                    value={selectedProduk}*/}
                                {/*                    onChange={(e) => handleSelectProduct(e)}*/}
                                {/*                >*/}
                                {/*                    {*/}
                                {/*                        produkList.map((produk, idx) => (*/}
                                {/*                            <MenuItem key={idx} value={produk}>*/}
                                {/*                                {produk.name}*/}
                                {/*                            </MenuItem>*/}
                                {/*                        ))*/}
                                {/*                    }*/}
                                {/*                </PInput>*/}
                                {/*            ) : (*/}
                                {/*                <PInput*/}
                                {/*                    fullWidth*/}
                                {/*                    disabled={isEdit && !isUpdate}*/}
                                {/*                    label="Nama Produk"*/}
                                {/*                    value={selectedProduk.name}*/}
                                {/*                    InputProps={{*/}
                                {/*                        endAdornment: isEdit && !isUpdate ? null : <InputAdornment position="end">*/}
                                {/*                            <IconButton onClick={handleChangeSelectProduct}>*/}
                                {/*                                <FontAwesomeIcon icon={faTimesCircle}/>*/}
                                {/*                            </IconButton>*/}
                                {/*                        </InputAdornment>*/}
                                {/*                    }}*/}
                                {/*                />*/}
                                {/*            )*/}
                                {/*        }*/}
                                {/*        {}*/}
                                {/*    </Grid>*/}
                                {/*    {*/}
                                {/*        !isProductActive ?*/}
                                {/*            daftarFitur.map((fitur, idx)=> (*/}
                                {/*                <Grid*/}
                                {/*                    key={idx}*/}
                                {/*                    item xs={12}*/}
                                {/*                    style={{*/}
                                {/*                        paddingLeft: 32,*/}
                                {/*                        display: 'flex',*/}
                                {/*                    }}*/}
                                {/*                >*/}
                                {/*                    <PInput*/}
                                {/*                        disabled={isEdit && !isUpdate}*/}
                                {/*                        label={`Fitur-${idx+1}`}*/}
                                {/*                        value={fitur}*/}
                                {/*                        onChange={(e)=> handleChangeFitur(e, idx)}*/}
                                {/*                        placeholder="Fitur Produk"*/}
                                {/*                        fullWidth*/}
                                {/*                    />*/}
                                {/*                    {*/}
                                {/*                        !isEdit || isUpdate ? <IconButton onClick={()=> handleDeleteFitur(idx)}>*/}
                                {/*                            <FontAwesomeIcon icon={faTimesCircle}/>*/}
                                {/*                        </IconButton> : null*/}
                                {/*                    }*/}
                                {/*                </Grid>*/}
                                {/*            ))*/}
                                {/*            : (*/}
                                {/*                <Grid item xs={12}>*/}
                                {/*                    {*/}
                                {/*                        selectedProduk.features && selectedProduk.features.map((fitur, idx) => (*/}
                                {/*                            <div key={idx} className={classes.wrapper}>*/}
                                {/*                                <div className={classes.wrap}>*/}
                                {/*                                    <FontAwesomeIcon icon={faCheckCircle} className={classes.iconCheckCircle}/>*/}
                                {/*                                    <Typography className={classes.feature}>{fitur}</Typography>*/}
                                {/*                                </div>*/}
                                {/*                            </div>*/}
                                {/*                        ))*/}
                                {/*                    }*/}
                                {/*                </Grid>*/}
                                {/*            )*/}
                                {/*    }*/}
                                {/*    {*/}
                                {/*        !isProductActive ? (*/}
                                {/*            <Grid item xs={12} style={{ marginTop: 8 }}>*/}
                                {/*                <PBtn disabled={isEdit && !isUpdate} onClick={() => handleTambahFitur()} style={{ width: '100%' }}>Tambah Fitur</PBtn>*/}
                                {/*            </Grid>*/}
                                {/*        ) : null*/}
                                {/*    }*/}
                                {/*</Grid>*/}
                                {/*<Grid item sm={12} md={4}>*/}
                                {/*    <PInput*/}
                                {/*        label="Lama Berlangganan"*/}
                                {/*        value={lamaBerlangganan}*/}
                                {/*        onChange={(e)=> handleChangeLamaBerlangganan(e)}*/}
                                {/*        fullWidth*/}
                                {/*        select*/}
                                {/*        disabled={isEdit && !isUpdate}*/}
                                {/*    >*/}
                                {/*        <MenuItem value={"1 Bulan"}>1 Bulan</MenuItem>*/}
                                {/*        <MenuItem value={"2 Bulan"}>2 Bulan</MenuItem>*/}
                                {/*        <MenuItem value={"3 Bulan"}>3 Bulan</MenuItem>*/}
                                {/*        <MenuItem value={"6 Bulan"}>6 Bulan</MenuItem>*/}
                                {/*        <MenuItem value={"1 Tahun"}>1 Tahun</MenuItem>*/}
                                {/*        <MenuItem value={"2 Tahun"}>2 Tahun</MenuItem>*/}
                                {/*        <MenuItem value={"3 Tahun"}>3 Tahun</MenuItem>*/}
                                {/*    </PInput>*/}
                                {/*</Grid>*/}
                                {/*<Grid item sm={12} md={4}>*/}
                                {/*    {*/}
                                {/*        !isProductActive ? (*/}
                                {/*            <PInput*/}
                                {/*                label="Harga"*/}
                                {/*                placeholder="Harga"*/}
                                {/*                value={harga}*/}
                                {/*                onChange={(e) => handleChangeHarga(e)}*/}
                                {/*                fullWidth*/}
                                {/*                style={{ marginBottom: 8, }}*/}
                                {/*                disabled={isEdit && !isUpdate}*/}
                                {/*            />*/}
                                {/*        ) : (*/}
                                {/*            <PInput*/}
                                {/*                label="Harga"*/}
                                {/*                placeholder="Harga"*/}
                                {/*                value={harga}*/}
                                {/*                // onChange={(e) => handleChangeHarga(e)}*/}
                                {/*                fullWidth*/}
                                {/*                style={{ marginBottom: 8, }}*/}
                                {/*                disabled*/}
                                {/*            />*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*    <PInput*/}
                                {/*        label="Diskon"*/}
                                {/*        placeholder="Diskon"*/}
                                {/*        value={diskon}*/}
                                {/*        onChange={(e) => handleChangeDiskon(e)}*/}
                                {/*        fullWidth*/}
                                {/*        style={{ marginBottom: 8 }}*/}
                                {/*        disabled={isEdit && !isUpdate}*/}
                                {/*    />*/}

                                {/*    <PInput*/}
                                {/*        label="Total"*/}
                                {/*        placeholder="Total"*/}
                                {/*        value={grandTotal}*/}
                                {/*        fullWidth*/}
                                {/*        disabled={isEdit && !isUpdate}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                            {/*</Grid>*/}

                            {/*<Grid container style={{ marginTop: 12 }} spacing={4}>*/}
                            {/*    {*/}
                            {/*        produkList.map($produk => (*/}
                            {/*            <Grid onClick={() => handleSelectProduct($produk)} key={$produk.id} item xs={4} className={`${classes.produkCard} ${selectedProduk && selectedProduk.id === $produk.id ? classes.selectedProduk : ''}`}>*/}
                            {/*                <span>Nama: {$produk.name}</span>*/}
                            {/*                {*/}
                            {/*                    $produk.features && <ul>*/}
                            {/*                        {*/}
                            {/*                            $produk.features.map(($feature, $idx) => (*/}
                            {/*                                <li key={$idx}>*/}
                            {/*                                    {$feature}*/}
                            {/*                                </li>*/}
                            {/*                            ))*/}
                            {/*                        }*/}
                            {/*                    </ul>*/}
                            {/*                }*/}
                            {/*                <span>Harga: {$produk.total}</span>*/}
                            {/*            </Grid>*/}
                            {/*        ))*/}
                            {/*    }*/}
                            {/*</Grid>*/}

                            {/*<Grid container style={{ marginTop: 18 }}>*/}
                            {/*    <Grid item xs={12}>*/}
                            {/*        <PInput label="Diskon" value={diskon} onChange={handleChangeDiskon} />*/}
                            {/*    </Grid>*/}

                            {/*    <Grid item xs={12}>*/}
                            {/*        <PInput label="Grand Total" value={grandTotal} />*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} style={{ marginBottom: 24 }}>
                        <PBtn disabled={isEdit && !isUpdate} style={{ width: '100%'}} onClick={()=> handleTambahProduk()}>Tambah Produk</PBtn>
                    </Grid>

                    <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 24 }}>
                        <PInput
                            label="Grand Total"
                            placeholder="Grand Total"
                            value={grandTotal}
                            fullWidth
                            disabled={isEdit && !isUpdate}
                        />
                    </Grid>

                    <Grid container spacing={4} style={{borderBottom: '1px solid black', marginBottom: 24}}>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography>Termin</Typography>

                            <Grid container spacing={4}>
                                {
                                    terminList.map(($termin, $idx) => (
                                        <Grid key={$idx} item xs={12}>
                                            <Grid container spacing={4} alignItems="center" style={{ position: 'relative', paddingRight: 24 }}>
                                                <Grid item xs={4}>
                                                    <Typography>
                                                        {`Termin ${$idx + 1} ${$idx === 0 ? 'DP' : ''}`}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <DatePicker
                                                        value={$termin.tanggal}
                                                        onChange={handleChangeTanggalTermin($idx)}
                                                        format="dd MMMM yyyy"
                                                        inputVariant="outlined"
                                                        margin="dense"
                                                        label="Tanggal"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                                            </InputAdornment>
                                                        }}
                                                        helperText={`Pastikan Tanggal Termin ${$idx+1} Merupakan Jam Kerja`}
                                                        fullWidth
                                                        required
                                                        disabled={isEdit && !isUpdate}
                                                    />
                                                </Grid>

                                                <Grid item xs={4} style={{marginTop: '-20px'}}>
                                                    {totalTermin !== grandTotal ? (
                                                        <PInput
                                                            label="Jumlah"
                                                            placeholder="Jumlah"
                                                            value={$termin.jumlah}
                                                            style={{marginTop: '30px'}}
                                                            onChange={(e) => handleChangeJumlahTermin(e, $idx)}
                                                            fullWidth
                                                            error
                                                            helperText="Total termin belum sesuai dengan total harga    "
                                                            disabled={isEdit && !isUpdate}
                                                        />
                                                    ): (
                                                        <PInput
                                                            label="Jumlah"
                                                            placeholder="Jumlah"
                                                            value={$termin.jumlah}
                                                            onChange={(e) => handleChangeJumlahTermin(e, $idx)}
                                                            fullWidth
                                                            disabled={isEdit && !isUpdate}
                                                        />
                                                    )}
                                                </Grid>

                                                <div style={{ position: 'absolute', top: 19, right: 0 }}>
                                                    {
                                                        !isEdit || isUpdate ? <IconButton onClick={() => handleDeleteTermin($idx)}>
                                                            <FontAwesomeIcon icon={faTimesCircle}/>
                                                        </IconButton> : null
                                                    }

                                                </div>
                                            </Grid>
                                        </Grid>
                                    ))
                                }

                                <Grid item xs={12} style={{ marginTop: 8 }}>
                                    <PBtn disabled={isEdit && !isUpdate} onClick={() => handleTambahTermin()} style={{ width: '100%' }}>Tambah Termin</PBtn>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid container item md={6}>
                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>
                                    Status Penawaran
                                </Typography>
                                <PInput
                                    fullWidth
                                    select
                                    disabled={isUpdate}
                                    value={statusPenawaran}
                                    onChange={(e) => handleChangeStatusPenawaran(e)}
                                >
                                    {
                                        statPenawaran.map($offerStatus => (
                                            <MenuItem key={$offerStatus.value} value={$offerStatus.value}>
                                                {$offerStatus.label}
                                            </MenuItem>
                                        ))
                                    }
                                </PInput>
                            </Grid>
                        </Grid>

                        <Grid container item md={6}>
                            {
                                statusPenawaran === "revised" || statusPenawaran === "rejected" ? (
                                    <Grid item xs={12} style={{ marginBottom: 24 }}>
                                        <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>
                                            Catatan
                                        </Typography>
                                        <PInput
                                            multiline
                                            fullWidth
                                            rows={3}
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                        />
                                    </Grid>
                                ) : ''
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 18 }}>
                    <PBtn
                        disabled={isSubmitting}
                        dropShadow
                        inverted
                        style={{ marginRight: 16 }}
                        onClick={onClose}
                    >Batalkan</PBtn>
                    {
                        isEdit && !isUpdate && <PBtn style={{ marginRight: 16 }} onClick={()=> setIsUpdate(true)}>Update Penawaran</PBtn>
                    }
                    <PBtn
                        disabled={isSubmitting || !selectedFaskes || selectedHealthFacilityPIC === ''}
                        onClick={() => handleSimpan()}
                    >{isSubmitting ? <FontAwesomeIcon icon={faSpinner} style={{color: 'white'}} spin/> : 'Simpan'}</PBtn>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapState = ({ user, activity, generalSetting }) => ({
    currentUser: user.currentUser,
    edittedOffer: activity.selectedOffer,
    isProductActive: generalSetting.isProductActive,
});

const mapDispatch = ({ activity: { fetchOffers, updateOpenPrintPenawaranDialog } }) => ({
    fetchOffers,
    updateOpenPrintPenawaranDialog,
});

export default connect(mapState, mapDispatch)(PenawaranForm);
