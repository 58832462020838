import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import {getUserList} from "../../../api/panela-user";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 5,
    },
});

const ActivitiesFilter = ({ filterData, setFilterData, startFilter, clearFilter }) => {
    const classes = styles();
    const [panelaUserList, setPanelaUserList] = useState([]);

    useEffect(()=> {
        (async function () {
            const resultUser = await getUserList();
            setPanelaUserList(resultUser.data);
        })()
    }, []);

    const handleChangeStartActivityDate = (date) => {
        filterData.tanggalAktivitasMulai = date;
        setFilterData(prevState => ({
            ...prevState, tanggalAktivitasMulai: filterData.tanggalAktivitasMulai,
        }));
    };

    const handleChangeEndActivityDate = (date) => {
        filterData.tanggalAktivitasSelesai = date;
        setFilterData(prevState => ({
            ...prevState, tanggalAktivitasSelesai: filterData.tanggalAktivitasSelesai,
        }));
    };

    function handleChangeFilter(e) {
        setFilterData({...filterData, [e.target.name]: e.target.value});
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Dari Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalAktivitasMulai}
                            onChange={handleChangeStartActivityDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.label}>Sampai Tanggal</Typography>
                        <DatePicker
                            value={filterData.tanggalAktivitasSelesai}
                            onChange={handleChangeEndActivityDate}
                            format="dd MMMM yyyy"
                            inputVariant="outlined"
                            margin="dense"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </InputAdornment>
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Nama Klinik</Typography>
                        <PInput
                            name="nama"
                            value={filterData.nama}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>PIC Klinik</Typography>
                        <PInput
                            name="picKlinik"
                            value={filterData.picKlinik}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>PIC Assist.id</Typography>
                        <PInput
                            name="picAssist"
                            value={filterData.picAssist}
                            onChange={(e)=> handleChangeFilter(e)}
                            select
                            fullWidth
                        >
                            {
                                panelaUserList.map(user => (
                                    <MenuItem key={user.id} value={`${user.firstName} ${user.lastName}`}>
                                        {`${user.firstName} ${user.lastName}`}
                                    </MenuItem>
                                ))
                            }
                        </PInput>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Media</Typography>
                        <PInput
                            name="media"
                            value={filterData.media}
                            onChange={(e)=> handleChangeFilter(e)}
                            select
                            fullWidth
                        >
                            <MenuItem value="Langsung di Klinik">Langsung di Klinik</MenuItem>
                            <MenuItem value="Intercom">Intercom</MenuItem>
                            <MenuItem value="Whatsapp">Whatsapp</MenuItem>
                            <MenuItem value="Email">Email</MenuItem>
                            <MenuItem value="SMS">SMS</MenuItem>
                            <MenuItem value="Telepon">Telepon</MenuItem>
                            <MenuItem value="Presentasi">Presentasi</MenuItem>
                            <MenuItem value="Training">Training</MenuItem>
                        </PInput>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Catatan</Typography>
                        <PInput
                            name="catatan"
                            value={filterData.catatan}
                            onChange={(e)=> handleChangeFilter(e)}
                            fullWidth
                            multiline
                            rows={2}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={clearFilter}
                >
                    Reset
                </PBtn>
                <PBtn
                    onClick={startFilter}
                >
                    Filter
                </PBtn>
            </Grid>
        </Grid>
    )
};

export default ActivitiesFilter;

