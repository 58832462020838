import {login, logout, relogin} from "../../api/auth";
import agent from "../../api/agent";
import history from "../../history";

const createLoginState = (state, loginResponse, userData) => {
    return {
        isAuthenticated: state,
        loginResponse: loginResponse,
        currentUser: userData,
    };
};

export const auth = {
    state: {},
    reducers: {},
    effects: dispatch => ({
        async handleLogin(payload) {
            try {
                const loginResult = await login(payload);
                const loginResponse = loginResult.data;
                const { id, userId } = loginResponse;
                localStorage.setItem('token', id);
                const userDataResult = await agent.get(`/PanelaUsers/${userId}`, {
                    params: {
                        filter: {
                            include: ['UserRole']
                        },
                    },
                });
                const userDataResponse = userDataResult.data;

                const loginState = createLoginState(true, loginResponse, userDataResponse);
                dispatch.user.updateLoginState(loginState);

                return true;
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
        async handleRelogin() {
            try {
                const result = await relogin();
                const { accessToken, userData } = result.data;
                localStorage.setItem('token', accessToken.id);

                const loginState = createLoginState(true, accessToken, userData);
                dispatch.user.updateLoginState(loginState);
                dispatch.app.updateIsLoaded(true);

                return true;
            } catch (err) {
                console.error(err);
                localStorage.removeItem('token');
                throw err;
            }
        },
        async handleLogout() {
            try {
                await logout();
                localStorage.removeItem('token');

                const loginState = createLoginState(false, undefined, undefined);
                dispatch.user.updateLoginState(loginState);
                history.replace('/login');
            } catch (err) {
                console.error(err);
                throw err;
            }
        },
    }),
};
