import React, {useRef, useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";

import {DatePicker} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload, faImage, faEye, faTimesCircle, faTimes, faSpinner, faInfoCircle, faPrint, faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {dispatch} from "../../../store";

import Autocomplete from "../../utils/Autocomplete";
import {faskesFetcherByKeyword} from "../../../helpers/faskesFetcherByName";
import {updateReceiptsInvoice, getInvoiceDetailByConfigId} from "../../../api/client";
import {uploadProfileImage} from "../../../api/file";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import debounce from "lodash/debounce";
import NumberFormat from "react-number-format";

import BuktiBayarPrintDialog from "./BuktiBayarPrintDialog";

const styles = makeStyles({
    labelTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        color: 'black',
        fontSize: '20px',
        marginBottom: 30,
    },
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
    btnUpload: {
        borderRadius: 6,
        border: 0,
        height: 30,
        background: '#228B22',
        color: 'white',
        padding: '2px 20px',
        textTransform: 'capitalize',
        '&:hover': {
            background: '#228B22',
        },
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize: '13px',
        marginTop: 10,
        marginBottom: 10,
    },
    wrapReceipts: {
        display: 'flex',
        marginBottom: 15,
        marginTop: 0,
    },
    labelFile: {
        color: '#15162d',
        fontSize: '15px',
        marginRight: 15,
        marginTop: 25,
    },
    iconReceipts: {
        marginRight: 8,
    },
    iconImage: {
        marginRight: 8,
        pointerEvents: 'none',
    },
    imagePreview: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%',
        marginBottom: 20,
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
    paper: {
        marginTop: 15,
        marginBottom: 15,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        backgroundColor: '#FFFF00',
    },
    labelPaper: {
        marginTop: -2,
        marginLeft: 7,
    },
});

function numberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: 'jumlah',
                        value: values.value,
                    },
                });
            }}
            prefix="Rp. "
            decimalSeparator=","
            thousandSeparator="."
        />
    );
}

const BuktiBayarForm = ({ updatePageReceipts, updateSelectedReceiptsList, selectedReceiptsList }) => {
    const classes = styles();
    const isEdit = typeof selectedReceiptsList._id !== "undefined";
    const inputReceiptRef = useRef(null);
    const [selectedFaskes, setSelectedFaskes] = useState(isEdit ? selectedReceiptsList : null);
    const [terminList, setTerminList] = useState([{
        buktiBayar: [{
            url: '',
            tanggal: new Date(),
            metodeBayar: '',
            jumlah: '',
        }],
        preview: [],
    }]);
    const [indexTermin, setIndexTermin] = useState(0);
    const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
    const [selectedPreview, setSelectedPreview] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isOpenReceiptPrintDialog, setIsOpenReceiptPrintDialog] = useState(false);
    const validateInt = RegExp(/^[0-9\b]+$/);

    useEffect(()=> {
        if(isEdit) {
            const flag = {...selectedReceiptsList.KConfig.activeBilling.Billing.payment};
            if(!flag.buktiBayar) {
                flag.buktiBayar = [];
                flag.preview = [];
            } else {
                flag.buktiBayar = flag.buktiBayar.map(item => {
                    if(!item.url) {
                        const newItem = {
                            url: item,
                            tanggal: null,
                            metodeBayar: '',
                            jumlah: '',
                        };
                        return newItem;
                    }
                    else {
                        return item;
                    }
                });
                flag.preview = flag.buktiBayar.map(item => item.url ? item.url : item);
            }
            setTerminList(flag);
        }
    }, [isEdit, selectedReceiptsList]);

    async function onSelectFaskes(item) {
        try {
            const result = await getInvoiceDetailByConfigId(item.KConfig.id);
            setTerminList(result.data.payment.map(item => {
                const flag = {...item};
                if(!flag.buktiBayar) {
                    flag.buktiBayar = [];
                    flag.preview = [];
                } else {
                    flag.buktiBayar = flag.buktiBayar.map(item => {
                        if(!item.url) {
                            const newItem = {
                                url: item,
                                tanggal: null,
                                metodeBayar: '',
                                jumlah: '',
                            };
                            return newItem;
                        } else {
                            return item;
                        }
                    });
                    flag.preview = flag.buktiBayar.map(item => item.url ? item.url : item);
                }
                return flag;
            }));
            const flagKlinik = {...item};
            flagKlinik.Billing = result.data;
            setSelectedFaskes(flagKlinik);
        } catch (e) {
            console.error(e);
            dispatch.snackbar.showError("Gagal memilih klinik");
            setSelectedFaskes(null);
            setTerminList([{
                buktiBayar: [],
                preview: [],
            }]);
        }
    }

    function handleDeleteSelectedFaskes() {
        setSelectedFaskes(null);
        setTerminList([{
            buktiBayar: [],
            preview: [],
        }]);
    }

    function handleChangePageReceipts() {
        updateSelectedReceiptsList({});
        updatePageReceipts(0);
    }

    function handleDeleteReceipt(idTermin, idReceipt) {
        if(isEdit) {
            terminList.buktiBayar.splice(idReceipt, 1);
            terminList.preview.splice(idReceipt, 1);
            setTerminList({...terminList});
        } else {
            terminList[idTermin].buktiBayar.splice(idReceipt, 1);
            terminList[idTermin].preview.splice(idReceipt, 1);
            setTerminList([...terminList]);
        }
    }

    function handleInputFile(e) {
        const file = e.target.files[0];

        if(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                if(isEdit) {
                    terminList.buktiBayar = [...terminList.buktiBayar, {url: file, tanggal: new Date(), metodeBayar: '', jumlah: ''}];
                    terminList.preview = [...terminList.preview, reader.result];
                    setTerminList({...terminList});
                } else {
                    terminList[indexTermin].buktiBayar = [...terminList[indexTermin].buktiBayar, {url: file, tanggal: new Date(), metodeBayar: '', jumlah: ''}];
                    terminList[indexTermin].preview = [...terminList[indexTermin].preview, reader.result];
                    setTerminList([...terminList]);
                }
            }
        }
    }

    function handleOpenPreviewDialog(idTermin, idReceipt) {
        setIsOpenPreviewDialog(true);
        if(isEdit) {
            setSelectedPreview(terminList.preview[idReceipt]);
        } else {
            setSelectedPreview(terminList[idTermin].preview[idReceipt]);
        }
    }

    function handleClosePreviewDialog() {
        setIsOpenPreviewDialog(false);
        setSelectedPreview('');
    }

    const handleChangeReceiptDate = (idTermin, idReceipt) => (date) => {
        if(isEdit) {
            terminList.buktiBayar[idReceipt].tanggal = date;
            setTerminList({...terminList});
        } else {
            terminList[idTermin].buktiBayar[idReceipt].tanggal = date;
            setTerminList([...terminList]);
        }
    };

    function handleChange(idTermin, idReceipt, e) {
        const { name, value } = e.target;
        if(name === 'metodeBayar') {
            if(isEdit) {
                terminList.buktiBayar[idReceipt].metodeBayar = value;
                setTerminList({...terminList});
            } else {
                terminList[idTermin].buktiBayar[idReceipt].metodeBayar = value;
                setTerminList([...terminList]);
            }
        } else if(name === 'jumlah') {
            if(value === '' || validateInt.test(value)) {
                if(isEdit) {
                    terminList.buktiBayar[idReceipt].jumlah = value;
                    setTerminList({...terminList});
                } else {
                    terminList[idTermin].buktiBayar[idReceipt].jumlah = value;
                    setTerminList([...terminList]);
                }
            }
        }
    }

    async function handleUpdateReceipt() {
        if(isEdit) {
            try {
                setIsSubmitting(true);
                const flag = {...terminList};
                flag.buktiBayar = terminList.buktiBayar;
                terminList.buktiBayar.map(async (receipt, id) => {
                    if(typeof receipt.url !== "string") {
                        const uploadImageResult = await uploadProfileImage(receipt.url);
                        const response = await uploadImageResult.data;
                        if(response) {
                            flag.buktiBayar[id].url = `https://s3-ap-southeast-1.amazonaws.com/kamarmedis-mr/${response}`;
                        }
                    }
                });
                delete flag.preview;
                const payload = {
                    isUpdate: true,
                    payment : [flag],
                };
                const waiting = debounce(async () => {
                    try {
                        await updateReceiptsInvoice(selectedFaskes.KConfig.activeBilling.Billing._id, payload);
                        dispatch.snackbar.showSuccess("Berhasil update bukti bayar");
                        setIsSubmitting(false);
                        updatePageReceipts(0);
                        updateSelectedReceiptsList({});
                    } catch (e) {
                        console.error(e);
                        dispatch.snackbar.showError("Gagal update bukti bayar");
                        setIsSubmitting(false);
                    }
                }, 7000);
                waiting();
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal update bukti bayar");
                setIsSubmitting(false);
            }
        } else {
            try {
                setIsSubmitting(true);
                const payload = {
                    payment: terminList.map(item => {
                        const flag = {...item};
                        flag.preview = item.preview;
                        flag.buktiBayar = item.buktiBayar;
                        item.buktiBayar.map(async (receipt, idx) => {
                            if(typeof receipt.url !== "string") {
                                const uploadImageResult = await uploadProfileImage(receipt.url);
                                const response = await uploadImageResult.data;
                                if(response) {
                                    flag.buktiBayar[idx].url = `https://s3-ap-southeast-1.amazonaws.com/kamarmedis-mr/${response}`;
                                }
                            }
                        });
                        if(flag.buktiBayar.length === 0) {
                            delete flag.buktiBayar;
                        }
                        delete flag.preview;
                        return flag;
                    })
                };
                const waiting = debounce(async () => {
                    try {
                        await updateReceiptsInvoice(selectedFaskes.Billing.id, payload);
                        dispatch.snackbar.showSuccess("Berhasil update bukti bayar");
                        setIsSubmitting(false);
                        updatePageReceipts(0);
                    } catch (e) {
                        console.error(e);
                        dispatch.snackbar.showError("Gagal update bukti bayar");
                        setIsSubmitting(false);
                    }
                }, 7000);
                waiting();
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError("Gagal update bukti bayar");
                setIsSubmitting(false);
            }
        }
    }

    return (
        <Grid container>
            <Dialog open={isOpenReceiptPrintDialog} onClose={()=> setIsOpenReceiptPrintDialog(false)} fullScreen>
                <DialogContent style={{ paddingTop: 50 }}>
                    <BuktiBayarPrintDialog onClose={()=> setIsOpenReceiptPrintDialog(false)}/>
                </DialogContent>
            </Dialog>
            <Dialog open={isOpenPreviewDialog} maxWidth="md" onClose={()=> handleClosePreviewDialog()} fullWidth>
                <DialogTitle>
                    <Typography className={classes.labelTitle}>Preview Bukti Bayar</Typography>
                    <IconButton
                        className={classes.closeButton}
                        onClick={()=> handleClosePreviewDialog()}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <img
                        src={selectedPreview}
                        alt="preview-receipt"
                        className={classes.imagePreview}
                    />
                </DialogContent>
            </Dialog>
            <Grid container item xs={12} style={{ marginTop: '44px' }}>
                <Grid item xs={12}>
                    <Typography className={classes.labelTitle}>{isEdit ? `Edit ` : `Tambah `}Bukti Bayar</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {
                            selectedFaskes
                                ? <PInput
                                    label="Nama Klinik"
                                    value={selectedFaskes.nama}
                                    style={{ marginBottom: 26 }}
                                    InputProps={{
                                        endAdornment: !isEdit
                                            ? <InputAdornment position="end">
                                                <IconButton onClick={()=> handleDeleteSelectedFaskes()} disabled={isSubmitting}>
                                                    <FontAwesomeIcon icon={faTimesCircle}/>
                                                </IconButton>
                                            </InputAdornment>
                                            : null
                                    }}
                                    fullWidth
                                />
                                : <Autocomplete
                                    fetcher={faskesFetcherByKeyword}
                                    onChange={onSelectFaskes}
                                    placeholder="Nama klinik"
                                    variant="outlined"
                                    margin="dense"
                                    itemToString="nama"
                                    fullWidth
                                />
                        }

                        <br/>
                        <PInput
                            label="PIC Klinik"
                            value={selectedFaskes
                                ? !isEdit
                                    ? selectedFaskes.Billing.createdName
                                        ? selectedFaskes.Billing.createdName
                                        : ''
                                    : selectedFaskes.KConfig.activeBilling.Billing.createdName
                                        ? selectedFaskes.KConfig.activeBilling.Billing.createdName
                                        : ''
                                : ''
                            }
                            style={{ marginBottom: 13 }}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PInput
                            label="No. Hp PIC Klinik"
                            value={selectedFaskes
                                ? !isEdit
                                    ? selectedFaskes.Billing.noHp
                                        ? selectedFaskes.Billing.noHp
                                        : ''
                                    : selectedFaskes.KConfig.activeBilling.Billing.noHp
                                        ? selectedFaskes.KConfig.activeBilling.Billing.noHp
                                        : ''
                                : ''
                            }
                            style={{ marginBottom: 26 }}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                        <PInput
                            label="Email PIC Klinik"
                            value={selectedFaskes
                                ? !isEdit
                                    ? selectedFaskes.Billing.createdEmail
                                        ? selectedFaskes.Billing.createdEmail
                                        : ''
                                    : selectedFaskes.KConfig.activeBilling.Billing.createdEmail
                                        ? selectedFaskes.KConfig.activeBilling.Billing.createdEmail
                                        : ''
                                : ''
                            }
                            style={{ marginBottom: 13 }}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            {
                selectedFaskes
                    ? isEdit
                    ? <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography className={classes.label}>
                            {`Termin `}(<NumberFormat displayType='text' decimalSeparator=',' thousandSeparator='.' prefix='Rp. ' value={terminList.value}/>){selectedFaskes.KConfig.activeBilling.Billing.payment.midTrans ? ' Midtrans' : ' Panela'}
                        </Typography>
                        {
                            terminList.buktiBayar ? terminList.buktiBayar.map((receipts, idReceipt) => (
                                <div key={idReceipt}>
                                    {
                                        terminList.buktiBayar.length === 0
                                            ? null
                                            : <Grid container spacing={2}>
                                                {!selectedFaskes.KConfig.activeBilling.Billing.payment.midTrans && <Grid item xs={2}>
                                                    <DatePicker
                                                        value={receipts.tanggal}
                                                        onChange={handleChangeReceiptDate(undefined, idReceipt)}
                                                        label="Tanggal"
                                                        format="dd MMMM yyyy"
                                                        inputVariant="outlined"
                                                        margin="dense"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                                            </InputAdornment>
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>}
                                                {!selectedFaskes.KConfig.activeBilling.Billing.payment.midTrans && <Grid item xs={3}>
                                                    <PInput
                                                        name="metodeBayar"
                                                        label="Metode Bayar"
                                                        value={receipts.metodeBayar}
                                                        onChange={(e)=> handleChange(undefined, idReceipt, e)}
                                                        fullWidth
                                                    />
                                                </Grid>}
                                                {!selectedFaskes.KConfig.activeBilling.Billing.payment.midTrans && <Grid item xs={3}>
                                                    <PInput
                                                        name="jumlah"
                                                        label="Jumlah"
                                                        value={receipts.jumlah}
                                                        onChange={(e)=> handleChange(undefined, idReceipt, e)}
                                                        InputProps={{
                                                            inputComponent: numberFormatCustom,
                                                        }}
                                                        helperText="Pastikan total jumlah sesuai dengan total biaya"
                                                        fullWidth
                                                    />
                                                </Grid>}
                                                <Grid item xs={4} className={classes.wrapReceipts}>
                                                    <IconButton className={classes.iconImage}>
                                                        <FontAwesomeIcon icon={faImage}/>
                                                    </IconButton>
                                                    <Typography
                                                        className={classes.labelFile}
                                                        style={selectedFaskes.KConfig.activeBilling.Billing.payment.midTrans ? {marginTop: 15} : {marginTop: 25}}
                                                        noWrap
                                                    >
                                                        {
                                                            typeof receipts.url !== "string"
                                                                ? receipts.url.name
                                                                : receipts.url.split('/').pop() || receipts.url.split('/').pop()
                                                        }
                                                    </Typography>
                                                    <IconButton
                                                        className={classes.iconReceipts}
                                                        onClick={()=> handleOpenPreviewDialog(null, idReceipt)}
                                                    >
                                                        <FontAwesomeIcon icon={faEye}/>
                                                    </IconButton>
                                                    <IconButton
                                                        className={classes.iconReceipts}
                                                        onClick={()=> handleDeleteReceipt(null, idReceipt)}
                                                        disabled={isSubmitting}
                                                    >
                                                        <FontAwesomeIcon icon={faTimesCircle}/>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                    }
                                </div>
                            )) : null
                        }
                        <input
                            type="file"
                            name="file"
                            accept="image/*"
                            id={`upload-receipt`}
                            style={{ display: 'none' }}
                            ref={inputReceiptRef}
                            onChange={(e)=> handleInputFile(e)}
                        />
                        {
                            terminList.isPayed === true
                                ? terminList.buktiBayar.length > 2
                                ? null
                                : <Button
                                    className={classes.btnUpload}
                                    onClick={()=> {
                                        const { current } = inputReceiptRef;
                                        current.click();
                                        setIndexTermin(null);
                                    }}
                                    disabled={isSubmitting}
                                >
                                    <FontAwesomeIcon icon={faUpload} style={{ marginRight: 5 }}/>{`Upload`}
                                </Button>
                                : <Paper className={classes.paper}>
                                    <div style={{ display: "inline-flex"}}>
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                        <Typography className={classes.labelPaper}>
                                            Silahkan update status invoice terlebih dahulu
                                        </Typography>
                                    </div>
                                </Paper>
                        }
                    </Grid>
                    : terminList.map((data, idTermin) => (
                        <Grid item xs={12} key={idTermin} style={{ marginTop: 20 }}>
                            <Typography className={classes.label}>
                                {`Termin ${idTermin+1} `}(<NumberFormat displayType='text' decimalSeparator=',' thousandSeparator='.' prefix='Rp. ' value={data.value}/>){data.midTrans ? ' Midtrans' : ' Panela'}
                            </Typography>
                            {
                                data.buktiBayar ? data.buktiBayar.map((receipts, idReceipt) => (
                                    <div key={idReceipt}>
                                        {
                                            data.buktiBayar.length === 0
                                                ? null
                                                : <Grid container spacing={2}>
                                                    {!data.midTrans && <Grid item xs={2}>
                                                        <DatePicker
                                                            value={receipts.tanggal}
                                                            onChange={handleChangeReceiptDate(idTermin, idReceipt)}
                                                            label="Tanggal"
                                                            format="dd MMMM yyyy"
                                                            inputVariant="outlined"
                                                            margin="dense"
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">
                                                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                                                </InputAdornment>
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>}
                                                    {!data.midTrans && <Grid item xs={3}>
                                                        <PInput
                                                            name="metodeBayar"
                                                            label="Metode Bayar"
                                                            value={receipts.metodeBayar}
                                                            onChange={(e)=> handleChange(idTermin, idReceipt, e)}
                                                            fullWidth
                                                        />
                                                    </Grid>}
                                                    {!data.midTrans && <Grid item xs={3}>
                                                        <PInput
                                                            name="jumlah"
                                                            label="Jumlah"
                                                            value={receipts.jumlah}
                                                            onChange={(e)=> handleChange(idTermin, idReceipt, e)}
                                                            InputProps={{
                                                                inputComponent: numberFormatCustom,
                                                            }}
                                                            helperText="Pastikan total jumlah sesuai dengan total biaya"
                                                            fullWidth
                                                        />
                                                    </Grid>}
                                                    <Grid item xs={4} className={classes.wrapReceipts}>
                                                        <IconButton className={classes.iconImage}>
                                                            <FontAwesomeIcon icon={faImage}/>
                                                        </IconButton>
                                                        <Typography
                                                            className={classes.labelFile}
                                                            style={data.midTrans ? {marginTop: 15} : {marginTop: 25}}
                                                            noWrap
                                                        >
                                                            {typeof receipts.url !== "string" ? receipts.url.name : receipts.url.split('/').pop() || receipts.url.split('/').pop()}
                                                        </Typography>
                                                        <IconButton
                                                            className={classes.iconReceipts}
                                                            onClick={()=> handleOpenPreviewDialog(idTermin, idReceipt)}
                                                        >
                                                            <FontAwesomeIcon icon={faEye}/>
                                                        </IconButton>
                                                        <IconButton
                                                            className={classes.iconReceipts}
                                                            onClick={()=> handleDeleteReceipt(idTermin, idReceipt)}
                                                            disabled={isSubmitting}
                                                        >
                                                            <FontAwesomeIcon icon={faTimesCircle}/>
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </div>
                                )) : null
                            }
                            <input
                                type="file"
                                name="file"
                                accept="image/*"
                                id={`upload-receipt${idTermin}`}
                                style={{ display: 'none' }}
                                ref={inputReceiptRef}
                                onChange={(e)=> handleInputFile(e)}
                            />
                            {
                                data.isPayed === true
                                    ? data.buktiBayar.length > 2
                                    ? null
                                    : <Button
                                        className={classes.btnUpload}
                                        onClick={()=> {
                                            const { current } = inputReceiptRef;
                                            current.click();
                                            setIndexTermin(idTermin);
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        <FontAwesomeIcon icon={faUpload} style={{ marginRight: 5 }}/>{`Upload`}
                                    </Button>
                                    : <Paper className={classes.paper}>
                                        <div style={{ display: "inline-flex"}}>
                                            <FontAwesomeIcon icon={faInfoCircle}/>
                                            <Typography className={classes.labelPaper}>
                                                Silahkan update status invoice terlebih dahulu
                                            </Typography>
                                        </div>
                                    </Paper>
                            }
                        </Grid>
                    ))
                    : null
            }
            <Grid container justify="flex-end" item xs={12} style={{ marginTop: 30 }}>
                {
                    isEdit
                        ? <PBtn
                            inverted
                            dropShadow
                            style={{ marginRight: 16 }}
                            onClick={()=> setIsOpenReceiptPrintDialog(true)}
                        >
                            <FontAwesomeIcon icon={faPrint} style={{ marginRight: 5 }}/>Cetak Bukti Bayar
                        </PBtn>
                        : null
                }
                <PBtn
                    inverted
                    dropShadow
                    style={{ marginRight: 16 }}
                    onClick={()=> handleChangePageReceipts()}
                    disabled={isSubmitting}
                >
                    Batal
                </PBtn>
                <PBtn
                    disabled={!selectedFaskes || isSubmitting}
                    onClick={()=> handleUpdateReceipt()}
                >
                    {!isSubmitting ? 'Simpan' : <FontAwesomeIcon icon={faSpinner} spin/>}
                </PBtn>
            </Grid>
        </Grid>
    );
};

const mapState = ({ client}) => ({
    selectedReceiptsList: client.selectedReceiptsList,
});

const mapDispatch = ({ client: { updatePageReceipts, updateSelectedReceiptsList } }) => ({
    updatePageReceipts,
    updateSelectedReceiptsList,
});

export default connect(mapState, mapDispatch)(BuktiBayarForm);
