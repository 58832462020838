import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from 'react-redux';

import {addNewActivity, updateActivity} from "../../../api/activity";
import Autocomplete from "../../utils/Autocomplete";
import {faskesFetcherByNamev2} from "../../../helpers/faskesFetcherByName";
import {IconButton, InputAdornment} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {DatePicker} from "@material-ui/pickers";
import format from 'date-fns/format';
import idLocale from 'date-fns/locale/id';
import {dispatch} from "../../../store";

const ActivityForm = ({ onClose, currentUser, fetchActivities, selectedActivity }) => {
    const { HealthFacility, healthFacilityPic } = selectedActivity;
    const isEdit = typeof selectedActivity.id !== 'undefined';

    const [selectedFaskes, setSelectedFaskes] = useState(HealthFacility ? HealthFacility : null);
    const [picAssist, setPicAssist] = useState(HealthFacility ? HealthFacility.assistPIC : '');
    const [healthFacilityPICList, setHealthFacilityPICList] = useState(HealthFacility ? HealthFacility.PIC : []);
    const [selectedHealthFacilityPIC, setSelectedHealthFacilityPIC] = useState(healthFacilityPic ? HealthFacility
        ? HealthFacility.PIC.findIndex($item => {
            return $item.namepic === healthFacilityPic.namepic && $item.position === healthFacilityPic.position &&
                $item.emailpic === healthFacilityPic.emailpic && $item.whatsapp === healthFacilityPic.whatsapp &&
                $item.address === healthFacilityPic.address && $item.birthday === healthFacilityPic.birthday;
        })
        : '' : '');
    const [activityDate, setActivityDate] = useState(selectedActivity.activityDate
        ? new Date(selectedActivity.activityDate)
        : new Date());
    const [selectedMedia, setSelectedMedia] = useState(selectedActivity.media
        ? selectedActivity.media
        : '');
    const [note, setNote] = useState(selectedActivity.note
        ? selectedActivity.note
        : '');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [keyFaskesAutoComplete, setKeyFaskesAutoComplete] = useState(0);

    function onSelectFaskes(item) {
        if(item.picId === "m4n4j3m3n") {
            dispatch.snackbar.showError("PIC Assist.id untuk leads ini belum ditentukan");
            setKeyFaskesAutoComplete(keyFaskesAutoComplete + 1);
        } else {
            setSelectedFaskes(item);
            setPicAssist(item.assistPIC);
            setHealthFacilityPICList(item.PIC);
        }
    }

    function handleDeleteFaskes() {
        if (isEdit) {
            setSelectedFaskes(null);
            setPicAssist('');
            setHealthFacilityPICList([]);
            setSelectedHealthFacilityPIC('');
        } else {
            setSelectedFaskes(null);
            setPicAssist('');
            setHealthFacilityPICList([]);
            setSelectedHealthFacilityPIC('');
            setActivityDate(new Date());
            setSelectedMedia('');
            setNote('');
        }
    }

    function handleChangeHealthFacilityPIC(e) {
        const { value } = e.target;
        setSelectedHealthFacilityPIC(value);
    }

    function handleChangeSelectedMedia(e) {
        const { value } = e.target;
        setSelectedMedia(value);
    }

    const handleChangeActivityDate = (date) => {
        setActivityDate(date);
    };

    async function handleSimpan() {
        const {snackbar} = dispatch;

        if (!isEdit) {
            try {
                const selectedPICFaskes = healthFacilityPICList[selectedHealthFacilityPIC];

                const payload = {
                    healthFacilityId: selectedFaskes.id,
                    picId: selectedFaskes.picId,
                    healthFacilityPic: selectedPICFaskes,
                    activityDate,
                    createdAt: new Date(),
                    createdId: currentUser.id,
                    note,
                    media: selectedMedia,
                };

                setIsSubmitting(true);
                await addNewActivity(payload);
                setIsSubmitting(false);
                snackbar.showSuccess('Berhasil menambahkan aktivitas baru');
                onClose();
                fetchActivities();
            } catch (err) {
                console.error(err);
                setIsSubmitting(false);
                snackbar.showError('Gagal menambahkan aktivitas baru');
            }
        } else {
            try {
                const selectedPICFaskes = healthFacilityPICList[selectedHealthFacilityPIC];

                const payload = {
                    healthFacilityId: selectedFaskes.id,
                    picId: selectedFaskes.picId,
                    healthFacilityPic: selectedPICFaskes,
                    activityDate,
                    updatedAt: new Date(),
                    updatedId: currentUser.id,
                    note,
                    media: selectedMedia,
                };

                setIsSubmitting(true);
                await updateActivity(selectedActivity.id, payload);
                setIsSubmitting(false);
                snackbar.showSuccess('Berhasil mengupdate aktivitas');
                onClose();
                fetchActivities();
            } catch (err) {
                console.error(err);
                setIsSubmitting(false);
                snackbar.showError('Gagal mengupdate aktivitas');
            }
        }
    }

    const inputtedDate = isEdit ? new Date(selectedActivity.createdAt) : new Date();

    return (
        <Grid container>
            <Grid container>
                <Grid item xs={12} style={{ marginBottom: 16 }}>
                    <Typography style={{ textAlign: 'right' }}>Input in {format(inputtedDate, "dd MMMM yyyy, HH:mm 'WIB'", { locale: idLocale })}</Typography>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 16 }}>
                    <Grid container spacing={4}>
                        <Grid container item md={6}>
                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Nama Klinik</Typography>
                                {
                                    selectedFaskes
                                        ? <PInput
                                            value={selectedFaskes.name}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={() => handleDeleteFaskes()}>
                                                        <FontAwesomeIcon icon={faTimesCircle}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                            fullWidth
                                        />
                                        : <Autocomplete
                                            key={keyFaskesAutoComplete}
                                            fetcher={faskesFetcherByNamev2}
                                            onChange={onSelectFaskes}
                                            placeholder="Nama klinik"
                                            variant="outlined"
                                            margin="dense"
                                            itemToString="name"
                                            fullWidth
                                        />
                                }
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Tanggal Aktifitas</Typography>
                                <DatePicker
                                    value={activityDate}
                                    onChange={handleChangeActivityDate}
                                    format="dd MMMM yyyy"
                                    inputVariant="outlined"
                                    margin="dense"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </InputAdornment>
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>PIC Klinik</Typography>
                                <PInput
                                    fullWidth
                                    select
                                    value={selectedHealthFacilityPIC}
                                    onChange={(e) => handleChangeHealthFacilityPIC(e)}
                                >
                                    {
                                        healthFacilityPICList.map(($healthFacilityPic, idx) => (
                                            <MenuItem key={`${$healthFacilityPic}-${idx}`} value={idx}>
                                                {$healthFacilityPic.namepic}
                                            </MenuItem>
                                        ))
                                    }
                                </PInput>
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>PIC Assist.id</Typography>
                                <PInput value={picAssist} fullWidth />
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: 24 }}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Via Komunikasi</Typography>
                                <PInput
                                    fullWidth
                                    select
                                    value={selectedMedia}
                                    onChange={(e) => handleChangeSelectedMedia(e)}
                                >
                                    <MenuItem value="Langsung di Klinik">Langsung di Klinik</MenuItem>
                                    <MenuItem value="Intercom">Intercom</MenuItem>
                                    <MenuItem value="Whatsapp">Whatsapp</MenuItem>
                                    <MenuItem value="Telegram">Telegram</MenuItem>
                                    <MenuItem value="Email">Email</MenuItem>
                                    <MenuItem value="SMS">SMS</MenuItem>
                                    <MenuItem value="Telepon">Telepon</MenuItem>
                                    <MenuItem value="Presentasi">Presentasi</MenuItem>
                                    <MenuItem value="Training">Training</MenuItem>
                                </PInput>
                            </Grid>
                        </Grid>

                        <Grid item md={6}>
                            <Grid item xs={12}>
                                <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Catatan</Typography>
                                <PInput multiline rows={24} value={note} onChange={(e) => setNote(e.target.value)} fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justify="flex-end" item xs={12} style={{marginBottom: '10px'}}>
                    <PBtn
                        disabled={isSubmitting}
                        dropShadow
                        inverted
                        style={{ marginRight: 16 }}
                        onClick={onClose}
                    >Batalkan</PBtn>
                    <PBtn
                        disabled={isSubmitting || !selectedFaskes || selectedHealthFacilityPIC === ''}
                        onClick={() => handleSimpan()}
                    >Simpan</PBtn>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapState = ({ user, activity }) => ({
    currentUser: user.currentUser,
    selectedActivity: activity.selectedActivity,
});

const mapDispatch = ({ activity: { fetchActivities } }) => ({
    fetchActivities,
});

export default connect(mapState, mapDispatch)(ActivityForm);
