import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AddNew from "../AddNew";
import ListEventName from "./ListEventName";
import { connect } from 'react-redux';
import {dispatch} from "../../../store";
import {addNewEventName} from "../../../api/event-name";

const KategoriPenolakan = ({ fetchEventNames }) => {
    useEffect(() => {
        fetchEventNames();
    });

    const handleSubmit = async (eventName, currentUser, setIsSubmitting, setEventName) => {
        const { snackbar } = dispatch;
        const trimmedValue = eventName.trim();

        if (trimmedValue === '') {
            snackbar.showError('Nama event harus diisi');
            return;
        }

        try {
            setIsSubmitting(true);
            await addNewEventName(eventName, currentUser.id);
            setEventName('');
            setIsSubmitting(false);
            snackbar.showSuccess('Berhasil menambahkan nama event');

            await fetchEventNames();
        } catch (err) {
            console.error(err);
            setIsSubmitting(false);
            snackbar.showError('Gagal menambahkan nama event');
        }
    };

    return (
        <Grid container>
            <AddNew handler={handleSubmit} placeholder={"Tambah event disini"} buttonLabel={"Tambah Event"} />

            <ListEventName />
        </Grid>
    );
};

const mapState = null;

const mapDispatch = ({ eventName: { fetchEventNames } }) => ({
    fetchEventNames,
});

export default connect(mapState, mapDispatch)(KategoriPenolakan);
