import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import format from "date-fns/format";
// import Typography from "@material-ui/core/Typography";
// import FormGroup from "@material-ui/core/FormGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import TableRow from "@material-ui/core/TableRow";
import {makeStyles} from "@material-ui/core/styles";
// import {DatePicker} from "@material-ui/pickers";

// import SquareSwitchBlue from "../../share/SquareSwitchBlue";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogActions from "@material-ui/core/DialogActions";
// import PBtn from "../../share/PBtn";
// import Dialog from "@material-ui/core/Dialog";
// import {setTrialStatus} from "../../../api/faskes";
// import {dispatch} from "../../../store";
//
// import {InputAdornment} from "@material-ui/core";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
// import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
            cursor: 'pointer',
        },
    },
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    titleDialog: {
        fontFamily: "Nunito",
        fontSize: "20px",
        color: "#15162d",
        fontWeight: "600",
        marginTop: "10px",
        marginLeft: "0px",
    }
});

const TrialRow = ({ trial, fetchTrialList }) => {
    const classes = useStyles();

    // const { trialStart, trialEnd, KConfig } = trial;
    // const [currentStatus, setCurrentStatus] = React.useState(KConfig.isTrial || false);
    // const [newTrialStart, setNewTrialStart] = React.useState(trialStart ? new Date(trialStart) : new Date());
    // const [newTrialEnd, setNewTrialEnd] = React.useState(trialEnd ? new Date(trialEnd) : new Date());
    // const [nextStatus, setNextStatus] = React.useState(false);
    // const [openDialog, setOpenDialog] = React.useState(false);
    // const [isUpdating, setIsUpdating] = React.useState(false);

    // useEffect(() => {
    //     setCurrentStatus(KConfig.isTrial || false);
    // }, [KConfig.isTrial]);

    // const handleChangeTrialStart = (date) => {
    //     setNewTrialStart(date);
    // };

    // const handleChangeTrialEnd = (date) => {
    //     setNewTrialEnd(date);
    // };

    // function handleChangeStatus(e) {
    //     setNextStatus(e.target.checked);
    //     setOpenDialog(true);
    //     setNewTrialStart(trialStart ? new Date(trialStart) : new Date());
    //     setNewTrialEnd(trialEnd ? new Date(trialEnd) : new Date());
    // }

    // function handleClose() {
    //     setOpenDialog(false);
    //     setNewTrialStart(trialStart ? new Date(trialStart) : new Date());
    //     setNewTrialEnd(trialEnd ? new Date(trialEnd) : new Date());
    // }

    // async function handleSimpan() {
    //     const { snackbar } = dispatch;
    //     try {
    //         let payload;
    //         if (nextStatus) {
    //             // if trial
    //
    //             if (newTrialStart === null || newTrialEnd === null) {
    //                 snackbar.showError('Tanggal mulai dan akhir harus diisi');
    //                 return;
    //             }
    //
    //             payload = {
    //                 healthFacilityId: trial.id,
    //                 isTrial: nextStatus,
    //                 trialStart: newTrialStart,
    //                 trialEnd: newTrialEnd,
    //             };
    //         } else {
    //             payload = {
    //                 healthFacilityId: trial.id,
    //                 isTrial: nextStatus,
    //                 trialStart: newTrialStart,
    //                 trialEnd: newTrialEnd,
    //             };
    //         }
    //
    //         setIsUpdating(true);
    //         await setTrialStatus(payload);
    //         setIsUpdating(false);
    //         snackbar.showSuccess(`${nextStatus ? 'Aktifkan' : 'Nonaktifkan'} trial faskes berhasil`);
    //         setCurrentStatus(nextStatus);
    //         handleClose();
    //         fetchTrialList();
    //     } catch (err) {
    //         console.error(err);
    //         setIsUpdating(false);
    //         snackbar.showError('Trial faskes gagal');
    //     }
    // }

    return (
        <React.Fragment>
            {/*<Dialog open={openDialog} onClose={isUpdating ? () => {} : () => handleClose()}>*/}
            {/*    <DialogTitle>*/}
            {/*        <Typography className={classes.titleDialog}>*/}
            {/*        {*/}
            {/*            nextStatus*/}
            {/*                ? 'Aktifkan Trial'*/}
            {/*                : 'Nonaktifkan Trial'*/}
            {/*        }*/}
            {/*        </Typography>*/}
            {/*    </DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        {*/}
            {/*            nextStatus*/}
            {/*                ? <Grid container>*/}
            {/*                    <Grid item xs={12} style={{ marginBottom: 24 }}>*/}
            {/*                        <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Tanggal Mulai</Typography>*/}
            {/*                        <DatePicker*/}
            {/*                            value={newTrialStart}*/}
            {/*                            onChange={handleChangeTrialStart}*/}
            {/*                            format="dd MMMM yyyy"*/}
            {/*                            inputVariant="outlined"*/}
            {/*                            margin="dense"*/}
            {/*                            InputProps={{*/}
            {/*                                endAdornment: <InputAdornment position="end">*/}
            {/*                                    <FontAwesomeIcon icon={faCalendarAlt}/>*/}
            {/*                                </InputAdornment>*/}
            {/*                            }}*/}
            {/*                            fullWidth*/}
            {/*                            required*/}
            {/*                        />*/}
            {/*                    </Grid>*/}

            {/*                    <Grid item xs={12} style={{ marginBottom: 24 }}>*/}
            {/*                        <Typography style={{ borderLeft: '1px solid white', color: 'black', fontWeight: 'bold', marginBottom: 10 }}>Tanggal Akhir</Typography>*/}
            {/*                        <DatePicker*/}
            {/*                            value={newTrialEnd}*/}
            {/*                            onChange={handleChangeTrialEnd}*/}
            {/*                            format="dd MMMM yyyy"*/}
            {/*                            inputVariant="outlined"*/}
            {/*                            margin="dense"*/}
            {/*                            InputProps={{*/}
            {/*                                endAdornment: <InputAdornment position="end">*/}
            {/*                                    <FontAwesomeIcon icon={faCalendarAlt}/>*/}
            {/*                                </InputAdornment>*/}
            {/*                            }}*/}
            {/*                            fullWidth*/}
            {/*                            required*/}
            {/*                        />*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*                : <Grid container>*/}
            {/*                    <Grid item xs={12} style={{ marginBottom: 24 }}>*/}
            {/*                        <Typography>*/}
            {/*                            Apakah anda yakin akan menghentikan masa trial faskes ini?*/}
            {/*                        </Typography>*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*        }*/}
            {/*    </DialogContent>*/}

            {/*    <DialogActions style={{marginTop: '10px', marginRight: '16px', marginBottom: '10px'}}>*/}
            {/*        <PBtn*/}
            {/*            onClick={isUpdating ? () => {} : () => handleClose()}*/}
            {/*            inverted dropShadow*/}
            {/*            disabled={isUpdating}*/}
            {/*        >*/}
            {/*            Batal*/}
            {/*        </PBtn>*/}

            {/*        <PBtn disabled={isUpdating} onClick={() => handleSimpan()}>*/}
            {/*            {*/}
            {/*                nextStatus*/}
            {/*                    ? 'Simpan'*/}
            {/*                    : 'Yakin'*/}
            {/*            }*/}
            {/*        </PBtn>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}

            <TableRow
                className={classes.row}
            >
                <TableCell className={classes.tableCellBody}>
                <span>
                    {trial.nama}
                </span>
                </TableCell>

                <TableCell className={classes.tableCellBody}>
                    <span>
                        {format(new Date(trial.KConfig.activeBilling.createdAt), "dd/MM/yyyy")}
                        {/*{*/}
                        {/*    trialStart*/}
                        {/*        ? format(new Date(trialStart), 'dd/MM/yyyy')*/}
                        {/*        : '-'*/}
                        {/*}*/}
                    </span>
                </TableCell>

                <TableCell className={classes.tableCellBody}>
                    <span>
                        {format(new Date(trial.KConfig.activeBilling.nextDueDate), "dd/MM/yyyy")}
                        {/*{*/}
                        {/*    trialEnd*/}
                        {/*        ? format(new Date(trialEnd), 'dd/MM/yyyy')*/}
                        {/*        : '-'*/}
                        {/*}*/}
                    </span>
                </TableCell>

                {/*<TableCell className={classes.tableCellBody}>*/}
                {/*    <span>{trial.PIC.map($item => $item.namepic).join(', ')}</span>*/}
                {/*</TableCell>*/}

                {/*<TableCell className={classes.tableCellBody}>*/}
                {/*    <span>*/}
                {/*        {*/}
                {/*            trial.PICAssist*/}
                {/*                ? `${trial.PICAssist.firstName} ${trial.PICAssist.lastName}`*/}
                {/*                : trial.assistPIC*/}
                {/*        }*/}
                {/*    </span>*/}
                {/*</TableCell>*/}

                <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                    {/*<FormGroup onClick={(e) => { e.stopPropagation() }}>*/}
                    {/*    <FormControlLabel*/}
                    {/*        control={*/}
                    {/*            <SquareSwitchBlue*/}
                    {/*                checked={trial.KConfig.isTrial}*/}
                    {/*                // onChange={(e) => {*/}
                    {/*                //     handleChangeStatus(e)*/}
                    {/*                // }}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        label={trial.KConfig.isTrial ? 'Enabled' : 'Disabled'}*/}
                    {/*    />*/}
                    {/*</FormGroup>*/}
                    <span>{trial.KConfig.isTrial && trial.KConfig.activeBilling.isActive ? 'Sedang Trial' : 'Trial Habis'}</span>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default TrialRow;
