import React, {useState} from 'react';
import {Link} from 'react-router-dom';
// import { Typography } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import logo from './../../../../assets/img/Logo.png';
import lead from './../../../../assets/img/leads-icon.png';
import dashboard from './../../../../assets/img/dashboard-icon.png';
import activity from './../../../../assets/img/activity-icon.png';
// import payment from './../../../../assets/img/payment-icon.png';
import data from './../../../../assets/img/data-icon.png';
import account from './../../../../assets/img/account-icon.png';
import setting from './../../../../assets/img/setting-icon.png';
import client from './../../../../assets/img/client-icon.png';
import report from './../../../../assets/img/report-icon.png';
import ticket from './../../../../assets/img/ticket-icon.png';
import {connect} from 'react-redux';

import PTooltip from "../../../share/PTooltip";

import './mainNavbar.css';

function MainNavbar({ currentUser }) {
    const { UserRole } = currentUser;
    const isManajemen = UserRole && UserRole.role === 'manajemen';
    const [currentUrl, setCurrentUrl] = useState("");

    const handleClick = (name) => {
        setCurrentUrl(name);
    };

    return(
        <div className="mainNavbar">
            <Grid container justify='center'>
                <Grid item xs={12}>
                    <img alt="logo" src={logo} className="logo-main" />
                </Grid>
                <div className="navbar">
                    <Grid item xs={12}>
                        <Link to="/dashboard" style={{textDecoration: 'none'}} onClick={()=> handleClick("dashboard")}>
                            <div className={`link ${currentUrl === 'dashboard' ? 'active' : ''}`}>
                                <PTooltip title='Dashboard' position='right-start'>
                                    <div className="icon-wrap">
                                        <img alt="icon-dashboard" src={dashboard} className="navbar-icon" />
                                    </div>
                                </PTooltip>
                                {/*<Typography>Dashboard</Typography>*/}
                            </div>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Link to="/leads" style={{textDecoration: 'none'}} onClick={()=> handleClick("leads")}>
                            <div className={`link ${currentUrl === 'leads' ? 'active' : ''}`}>
                                <PTooltip title='Leads' position='right-start'>
                                    <div className="icon-wrap">
                                        <img alt="icon-leads" src={lead} className="navbar-icon" />
                                    </div>
                                </PTooltip>
                                {/*<Typography>Leads</Typography>*/}
                            </div>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Link to="/activity" style={{textDecoration: 'none'}} onClick={()=> handleClick("activity")}>
                            <div className={`link ${currentUrl === 'activity' ? 'active' : ''}`}>
                                <PTooltip title='Activity' position='right-start'>
                                    <div className="icon-wrap">
                                        <img alt="icon-activity" src={activity} className="navbar-icon" />
                                    </div>
                                </PTooltip>
                                {/*<Typography>Activity</Typography>*/}
                            </div>
                        </Link>
                    </Grid>
                    {
                        isManajemen && <Grid item xs={12}>
                            <Link to="/client" style={{textDecoration: 'none'}} onClick={()=> handleClick("client")}>
                                <div className={`link ${currentUrl === 'client' ? 'active' : ''}`}>
                                    <PTooltip title='Client' position='right-start'>
                                        <div className="icon-wrap">
                                            <img alt="icon-client" src={client} className="navbar-icon" />
                                        </div>
                                    </PTooltip>
                                    {/*<Typography>Client</Typography>*/}
                                </div>
                            </Link>
                        </Grid>
                    }
                    {/*{*/}
                    {/*    isManajemen && <Link to="/payment" style={{textDecoration: 'none'}} onClick={()=> handleClick("payment")}>*/}
                    {/*        <div className={`link ${currentUrl === 'payment' ? 'active' : ''}`}>*/}
                    {/*            <div className="icon-wrap">*/}
                    {/*                <img alt="icon-payment" src={payment} className="navbar-icon" />*/}
                    {/*            </div>*/}
                    {/*            <Typography>Payment</Typography>*/}
                    {/*        </div>*/}
                    {/*    </Link>*/}
                    {/*}*/}
                    <Grid item xs={12}>
                        <Link to="/data" style={{textDecoration: 'none'}} onClick={()=> handleClick("data")}>
                            <div className={`link ${currentUrl === 'data' ? 'active' : ''}`}>
                                <PTooltip title='Data' position='right-start'>
                                    <div className="icon-wrap">
                                        <img alt="icon-data" src={data} className="navbar-icon" />
                                    </div>
                                </PTooltip>
                                {/*<Typography>Data</Typography>*/}
                            </div>
                        </Link>
                    </Grid>
                    {
                        isManajemen && <Grid item xs={12}>
                            <Link to="/report" style={{textDecoration: 'none'}} onClick={() => handleClick("report")}>
                                <div className={`link ${currentUrl === 'report' ? 'active' : ''}`}>
                                    <PTooltip title='Report' position='right-start'>
                                        <div className="icon-wrap">
                                            <img alt="icon-data" src={report} className="navbar-icon"/>
                                        </div>
                                    </PTooltip>
                                    {/*<Typography>Report</Typography>*/}
                                </div>
                            </Link>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Link to="/ticketing" style={{textDecoration: 'none'}} onClick={()=> handleClick("ticketing")}>
                            <div className={`link ${currentUrl === 'ticketing' ? 'active' : ''}`}>
                                <PTooltip title='Ticketing' position='right-start'>
                                    <div className="icon-wrap">
                                        <img alt="icon-data" src={ticket} className="navbar-icon"/>
                                    </div>
                                </PTooltip>
                                {/*<Typography>Ticketing</Typography>*/}
                            </div>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Link to="/account" style={{textDecoration: 'none'}} onClick={()=> handleClick("account")}>
                            <div className={`link ${currentUrl === 'account' ? 'active' : ''}`}>
                                <PTooltip title='Account' position='right-start'>
                                    <div className="icon-wrap">
                                        <img alt="icon-account" src={account} className="navbar-icon" />
                                    </div>
                                </PTooltip>
                                {/*<Typography>Account</Typography>*/}
                            </div>
                        </Link>
                    </Grid>
                    {
                        isManajemen && <Grid item xs={12}>
                            <Link to="/setting" style={{textDecoration: 'none'}} onClick={()=> handleClick("setting")}>
                                <div className={`link ${currentUrl === 'setting' ? 'active' : ''}`}>
                                    <PTooltip title='Setting' position='right-start'>
                                        <div className="icon-wrap">
                                            <img alt="icon-setting" src={setting} className="navbar-icon" />
                                        </div>
                                    </PTooltip>
                                    {/*<Typography>Setting</Typography>*/}
                                </div>
                            </Link>
                        </Grid>
                    }
                </div>
            </Grid>
        </div>
    )
}

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(MainNavbar);
