import React, {useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import ReferralItem from "./ReferralItem";

const styles = makeStyles({
    tableWrapper: {
        overflow: 'auto',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
});

const ReferralTable = ({ referralListCounter, fetchReferral, setIsOpenDialog, filterData, setFilterData, filter, setFilter }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        if(filter === 0) {
            setPage(0);
            fetchReferral();
        } else {
            handleRenderReferralFilter();
        }
    }, [fetchReferral, filter]);

    const handleRenderReferralFilter = () => {
        fetchReferral(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        setFilterData(prevState => ({
            ...prevState, skip: skipLimit.skip, limit: skipLimit.limit,
        }));
        setFilter(filter + 1);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        const newRowsPerPage = parseInt(e.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerPage,
        };
        setFilterData(prevState => ({
            ...prevState, skip: skipLimit.skip, limit: skipLimit.limit,
        }));
        setFilter(filter + 1);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    return (
        <div>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader}>
                                Tanggal
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Nama Yang Mereferralkan
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>
                                IP Address
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Instansi Yang Mereferralkan
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Nama Yang Direferralkan
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Instansi Yang Direferralkan
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Informasi
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <ReferralItem page={page} rowsPerPage={rowsPerPage} setIsOpenDialog={setIsOpenDialog}/>
                    </TableBody>
                </Table>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={referralListCounter}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

const mapState = ({ prospect }) => ({
    referralListCounter: prospect.referralListCounter,
});

const mapDispatch = ({ prospect: { fetchReferral } }) => ({
    fetchReferral,
});

export default connect(mapState, mapDispatch)(ReferralTable);
