import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem  from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import PInput from "../../share/PInput";

import debounce from "lodash/debounce";

import OverdueTable from "./OverdueTable";

const styles = makeStyles({
    search: {
        flex: 0.9,
    },
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
});

const Overdue = ({ fetchOverdueListFilter }) => {
    const classes = styles();
    const [isFilter, setIsFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        namaKlinik: '',
        hMinus7: 0,
        skip: 0,
        limit: 5,
    });
    const [search, setSearch] = useState('');

    const getSuggestion = debounce(async (value) => {
        filterData.namaKlinik = value;
        setFilterData({...filterData, namaKlinik: filterData.namaKlinik});
        setIsFilter(true);
        fetchOverdueListFilter(filterData);
    }, 500);

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        if(value.length > 2) {
            setSearch(value);
            getSuggestion(value);
        } else if(value.length === 0) {
            setSearch(value);
            setIsFilter(false);
        } else {
            setSearch(value);
        }
    };

    const handleChangeStatus = (e) => {
        filterData.hMinus7 = e.target.value;
        setFilterData({...filterData, hMinus7: filterData.hMinus7});
        setIsFilter(true);
        fetchOverdueListFilter(filterData);
    };

    return (
        <Grid container>
            <Grid container item xs={12} style={{ marginTop: '20px' }}>
                <Grid item xs={4}>
                    <Typography style={{ visibility: 'hidden' }}>Cari Klinik</Typography>
                    <PInput
                        className={classes.search}
                        placeholder="Cari klinik disini"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputAdornment>
                        }}
                        value={search}
                        onChange={handleChangeSearch}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Status</Typography>
                    <PInput
                        value={filterData.hMinus7}
                        onChange={handleChangeStatus}
                        select
                        fullWidth
                    >
                        <MenuItem value={0}>Overdue</MenuItem>
                        <MenuItem value={1}>7 Hari</MenuItem>
                    </PInput>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <OverdueTable
                    isFilter={isFilter}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </Grid>
        </Grid>
    );
};

const mapState = null;

const mapDispatch = ({ client: { fetchOverdueListFilter } }) => ({
    fetchOverdueListFilter,
});

export default connect(mapState, mapDispatch)(Overdue);
