import React, {useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import OverdueItem from "./OverdueItem";

const styles = makeStyles({
    tableWrapper: {
        overflow: 'auto',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
});

const OverdueTable = ({ fetchOverdueList, overdueListCounter, fetchOverdueListFilter, isFilter, filterData, setFilterData }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        if(isFilter === true) {
            setPage(0);
            handleRenderOverdueListFilter();
        } else {
            setPage(0);
            setRowsPerPage(5);
            fetchOverdueList();
        }
    }, [fetchOverdueList, fetchOverdueListFilter, isFilter]);

    const handleRenderOverdueListFilter = () => {
        setFilterData(prevState => ({
            ...prevState, condition: false,
        }));
        fetchOverdueListFilter(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        if(isFilter === true) {
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchOverdueListFilter(filterData);
        } else {
            fetchOverdueList(skipLimit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerPage,
        };
        if(isFilter === true) {
            filterData.skip = 0;
            filterData.limit = newRowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchOverdueListFilter(filterData);
        } else {
            fetchOverdueList(skipLimit);
        }
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    return (
        <div className={classes.tableWrapper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellHeader}>
                            Nama Klinik
                        </TableCell>
                        <TableCell className={classes.tableCellHeader}>
                            Tanggal Jatuh Tempo
                        </TableCell>
                        <TableCell className={classes.tableCellHeader} style={{ textAlign: 'center' }}>
                            Status
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <OverdueItem page={page} rowsPerPage={rowsPerPage}/>
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            colSpan={3}
                            count={overdueListCounter}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    );
};

const mapState = ({ client }) => ({
    overdueListCounter: client.overdueListCounter,
});

const mapDispatch = ({ client: { fetchOverdueList, fetchOverdueListFilter } }) => ({
    fetchOverdueList,
    fetchOverdueListFilter,
});

export default connect(mapState, mapDispatch)(OverdueTable);
