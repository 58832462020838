import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import format from "date-fns/format";
import PBtn from "../../share/PBtn";

import PreviewSIUP from "./PreviewSIUP";
import OverdueFreezeForm from "../overdue/OverdueFreezeForm";

const styles = makeStyles({
    tableCellBody: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#15162d',
    },
    row: {
        '&:hover': {
            background: 'rgba(51, 102, 153, .2)',
        },
    },
});

const KlinikFreeItem = ({ isFetchingKlinikFreeList, klinikFreeList, page, rowsPerPage, updateSelectedKlinikFreeList }) => {
    const classes = styles();
    const index = page * rowsPerPage;
    const [isOpenPreviewSIUP, setIsOpenPreviewSIUP] = useState(false);
    const [isOpenFreeze, setIsOpenFreeze] = useState(false);
    const [extensionSIUP, setExtensionSIUP] = useState('');

    function handleOpenPreview(klinikFree) {
        setExtensionSIUP(klinikFree.siupFile.split('.').pop());
        updateSelectedKlinikFreeList(JSON.parse(JSON.stringify(klinikFree)));
        setIsOpenPreviewSIUP(true);
    }

    function handleClosePreview() {
        setExtensionSIUP('');
        updateSelectedKlinikFreeList({});
        setIsOpenPreviewSIUP(false);
    }

    function handleOpenFreeze(klinikFree) {
        updateSelectedKlinikFreeList(JSON.parse(JSON.stringify(klinikFree)));
        setIsOpenFreeze(true);
    }

    function handleCloseFreeze() {
        updateSelectedKlinikFreeList({});
        setIsOpenFreeze(false);
    }

    return (
        <React.Fragment>
            <Dialog open={isOpenPreviewSIUP} maxWidth="md" onClose={()=> handleClosePreview()} fullWidth>
                <PreviewSIUP handleClose={()=> handleClosePreview()} extensionSIUP={extensionSIUP}/>
            </Dialog>
            <Dialog open={isOpenFreeze} maxWidth="xs" onClose={()=> handleCloseFreeze()} fullWidth>
                <DialogContent>
                    <OverdueFreezeForm onClose={()=> handleCloseFreeze()}/>
                </DialogContent>
            </Dialog>
            {
                isFetchingKlinikFreeList === true
                    ? <TableRow>
                        <TableCell colSpan={8}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress/>
                            </div>
                        </TableCell>
                    </TableRow>
                    : klinikFreeList.slice(index, index + rowsPerPage).map((item, idx) => (
                        <TableRow key={idx} className={classes.row}>
                            <TableCell className={classes.tableCellBody}>
                                {format(new Date(item.KConfig.createdAt), "dd/MM/yyyy")}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.nama}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.KConfig.activeBilling.mainProductName}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {
                                    item.KConfig.activeBilling.endSubs
                                        ? format(new Date(item.KConfig.activeBilling.endSubs), "dd/MM/yyyy")
                                        : ''
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.Accounts.name}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {item.Accounts.hp}
                            </TableCell>
                            <TableCell className={classes.tableCellBody}>
                                {
                                    item.siupFile
                                        ? <Tooltip title='Preview'>
                                            <IconButton onClick={()=> handleOpenPreview(item)}>
                                                <FontAwesomeIcon icon={faFileAlt}/>
                                            </IconButton>
                                        </Tooltip>
                                        : null
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                {
                                    item.KConfig.activeBilling.isActive === true
                                        ? <PBtn onClick={()=> handleOpenFreeze(item)}>
                                            Set Freeze
                                        </PBtn>
                                        : 'Freeze'
                                }
                            </TableCell>
                        </TableRow>
                    ))
            }
        </React.Fragment>
    );
};

const mapState = ({ client }) => ({
    isFetchingKlinikFreeList: client.isFetchingKlinikFreeList,
    klinikFreeList: client.klinikFreeList,
});

const mapDispatch = ({ client: { updateSelectedKlinikFreeList } }) => ({
    updateSelectedKlinikFreeList,
});

export default connect(mapState, mapDispatch)(KlinikFreeItem);
