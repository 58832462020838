import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

import {makeStyles} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faSearch} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";

import TableDataLogLeads from "./TableDataLogLeads";

const styles = makeStyles({
    wrap: {
        marginRight: 20,
    },
    grid: {
        marginTop: 30,
    },
});

const DataLogLeads = ({ healthFacilityId }) => {
    const classes = styles();
    const [filterData, setFilterData] = useState({
        healthFacilityId: healthFacilityId,
        tanggal: null,
        deskripsi: '',
        skip: 0,
        limit: 5,
    });

    const handleChangeSearch = (e) => {
        filterData.deskripsi = e.target.value;
        setFilterData(prevState => ({
            ...prevState, deskripsi: filterData.deskripsi,
        }));
    };

    const handleChangeDate = (date) => {
        filterData.tanggal = date;
        setFilterData(prevState => ({
            ...prevState, tanggal: filterData.tanggal,
        }));
    };

    return (
        <Grid item xs={12} md={12}>
            <Grid item xs={6}>
                <div className={classes.wrap}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <PInput
                                value={filterData.deskripsi}
                                onChange={handleChangeSearch}
                                placeholder='Cari nama activity'
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </InputAdornment>
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                value={filterData.tanggal}
                                onChange={handleChangeDate}
                                format='dd MMMM yyyy'
                                inputVariant='outlined'
                                margin='dense'
                                placeholder='Cari tanggal activity'
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>
                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                    </InputAdornment>
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} className={classes.grid}>
                <TableDataLogLeads
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </Grid>
        </Grid>
    );
};

export default DataLogLeads;
