

export const toggler = ({
    state : true,
    reducers : {
        setToggler(state, payload){
            // console.log(payload)
            return payload
        }
    }
})