import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

const styles = makeStyles({
    wrap: {
        padding: 30,
    },
    gridWrap: {
        marginBottom: 20,
    },
    formLeft: {
        marginRight: 10,
    },
    formRight: {
        marginLeft: 10,
    },
    labelField: {
        color: '#15162d',
        fontSize: '15px',
        fontFamily: 'Nunito',
        marginBottom: 5,
    },
    fieldWrap: {
        marginBottom: 15,
    },
});

const FormPICLeads = ({ flagPIC, onClose, setFlagPIC, onSubmit, isCustom, setIsCustom }) => {
    const classes = styles();
    const idx = flagPIC.PIC.length - 1;
    const validateInt = RegExp(/^[0-9\b]+$/);
    const validateEmail = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    const [isValidatedEmail, setIsValidatedEmail] = useState(true);

    const handleChange = (e) => {
        if(e.target.name === 'whatsapp') {
            if(e.target.value === '' || validateInt.test(e.target.value)) {
                flagPIC.PIC[idx] = {...flagPIC.PIC[idx], [e.target.name]: e.target.value};
                setFlagPIC(prevState => ({
                    ...prevState, PIC: flagPIC.PIC,
                }));
            }
        } else if(e.target.name === 'emailpic') {
            if(e.target.value === '' || validateEmail.test(e.target.value)) {
                setIsValidatedEmail(true);
                flagPIC.PIC[idx] = {...flagPIC.PIC[idx], [e.target.name]: e.target.value};
                setFlagPIC(prevState => ({
                    ...prevState, PIC: flagPIC.PIC,
                }));
            } else {
                setIsValidatedEmail(false);
                flagPIC.PIC[idx] = {...flagPIC.PIC[idx], [e.target.name]: e.target.value};
                setFlagPIC(prevState => ({
                    ...prevState, PIC: flagPIC.PIC,
                }));
            }
        } else if(e.target.name === 'position') {
            if(e.target.value === 'Custom') {
                setIsCustom(true);
                flagPIC.PIC[idx] = {...flagPIC.PIC[idx], [e.target.name]: ''};
                setFlagPIC(prevState => ({
                    ...prevState, PIC: flagPIC.PIC,
                }));
            } else {
                flagPIC.PIC[idx] = {...flagPIC.PIC[idx], [e.target.name]: e.target.value};
                setFlagPIC(prevState => ({
                    ...prevState, PIC: flagPIC.PIC,
                }));
            }
        } else {
            flagPIC.PIC[idx] = {...flagPIC.PIC[idx], [e.target.name]: e.target.value};
            setFlagPIC(prevState => ({
                ...prevState, PIC: flagPIC.PIC,
            }));
        }
    };

    const handleChangeBirthday = (date) => {
        flagPIC.PIC[idx] = {...flagPIC.PIC[idx], birthday: date};
        setFlagPIC(prevState => ({
            ...prevState, PIC: flagPIC.PIC,
        }));
    };

    return (
        <DialogContent>
            <form onSubmit={onSubmit} className={classes.wrap}>
                <Grid container className={classes.gridWrap}>
                    <Grid item xs={12} md={6}>
                        <div className={classes.formLeft}>
                            <Grid item xs={12} className={classes.fieldWrap}>
                                <Typography className={classes.labelField}>Nama Lengkap PIC *</Typography>
                                <PInput
                                    name='namepic'
                                    value={flagPIC.PIC[idx].namepic}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.fieldWrap}>
                                <Typography className={classes.labelField}>Nama Panggilan PIC</Typography>
                                <PInput
                                    name='nickname'
                                    value={flagPIC.PIC[idx].nickname}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.fieldWrap}>
                                <Typography className={classes.labelField}>Tanggal Lahir PIC</Typography>
                                <DatePicker
                                    value={flagPIC.PIC[idx].birthday}
                                    onChange={handleChangeBirthday}
                                    format="dd MMMM yyyy"
                                    margin="dense"
                                    inputVariant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </InputAdornment>
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.fieldWrap}>
                                <Typography className={classes.labelField}>Jabatan PIC</Typography>
                                {!isCustom && <PInput
                                    name='position'
                                    value={flagPIC.PIC[idx].position}
                                    onChange={handleChange}
                                    select
                                    fullWidth
                                >
                                    <MenuItem value="Pemilik">Pemilik</MenuItem>
                                    <MenuItem value="Manajemen">Manajemen</MenuItem>
                                    <MenuItem value="Penanggung Jawab">Penanggung Jawab</MenuItem>
                                    <MenuItem value="Dokter">Dokter</MenuItem>
                                    <MenuItem value="IT">IT</MenuItem>
                                    <MenuItem value="Custom">Tambah Baru</MenuItem>
                                </PInput>}
                                {isCustom && <PInput
                                    name='position'
                                    value={flagPIC.PIC[idx].position}
                                    onChange={handleChange}
                                    fullWidth
                                />}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.formRight}>
                            <Grid item xs={12} className={classes.fieldWrap}>
                                <Typography className={classes.labelField}>Email PIC</Typography>
                                <PInput
                                    name='emailpic'
                                    value={flagPIC.PIC[idx].emailpic}
                                    onChange={handleChange}
                                    helperText={!isValidatedEmail ? 'Email is not valid!' : null}
                                    error={!isValidatedEmail}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.fieldWrap}>
                                <Typography className={classes.labelField}>Alamat PIC</Typography>
                                <PInput
                                    name='address'
                                    value={flagPIC.PIC[idx].address}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.fieldWrap}>
                                <Typography className={classes.labelField}>Whatsapp PIC *</Typography>
                                <PInput
                                    name='whatsapp'
                                    value={flagPIC.PIC[idx].whatsapp}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.fieldWrap}>
                                <Typography className={classes.labelField}>Keterangan</Typography>
                                <PInput
                                    name='note'
                                    value={flagPIC.PIC[idx].note}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <Grid container justify='flex-end' item xs={12} md={12}>
                    <PBtn
                        inverted
                        dropShadow
                        style={{ marginRight: 16 }}
                        onClick={onClose}
                    >Batal</PBtn>
                    <PBtn
                        type='submit'
                    >
                        Tambah
                    </PBtn>
                </Grid>
            </form>
        </DialogContent>
    );
};

export default FormPICLeads;
