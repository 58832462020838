import agent from "./agent";

export const addNewRejectionCategory = (name, userId) => {
    const payload = {
        type: 'rejectionCategory',
        name: name,
        createdAt: new Date(),
        createdId: userId,
    };

    return agent.post('Dictionaries', payload);
};

export const updateRejectionCategory = (name, rejectionCategoryId, userId) => {
    const payload = {
        name: name,
        updatedAt: new Date(),
        updatedId: userId,
    };

    return agent.patch(`Dictionaries/${rejectionCategoryId}`, payload);
};

export const getRejectionCategories = () => {
    const filter = {
        where: {
            type: 'rejectionCategory',
        },
    };

    return agent.get('Dictionaries', {
        params: {
            filter,
        },
    })
};

export const updateRejectionCategoryProduct = (data, rejectionCategoryId, userId) => {
    const payload = {
        name: data.name,
        features: data.features,
        price: data.price,
        updatedAt: new Date(),
        updatedId: userId,
    };

    return agent.patch(`Dictionaries/${rejectionCategoryId}`, payload);
};
