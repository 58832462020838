import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import {makeStyles} from "@material-ui/core";

import {getTotalAktivitas, getTotalKlinik} from "../../api/dashboard";

import NearestBill from "./NearestBill";

const styles = makeStyles({
    wrap: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        maxHeight: 360,
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#15162d',
    },
    borderTitle: {
        width: 40,
        float: 'left',
        background: '#2b64af',
        border: '2px solid #2b64af',
    },
    tableWrapper: {
        overflowY: 'auto',
        height: 275,
        width: '100%',
    },
    tableCellBody: {
        paddingLeft: 0,
        fontFamily: 'Nunito',
        fontSize: '16px',
        borderBottom: 'none',
    },
    labelLeft: {
        color: '#6e7d91',
        fontWeight: 'bold',
    },
    labelRight: {
        color: '#54a651',
        fontWeight: 'bold',
        textAlign: 'right',
        marginRight: 10,
    },
});

const Total = () => {
    const classes = styles();
    const [totalAktivitas, setTotalAktivitas] = useState({});
    const [totalKlinik, setTotalKlinik] = useState([]);

    useEffect(()=> {
        (async function () {
            const resultGetTotalAktivitas = await getTotalAktivitas();
            const resultGetTotalKlinik = await getTotalKlinik();
            setTotalAktivitas(resultGetTotalAktivitas.data);
            setTotalKlinik(resultGetTotalKlinik.data);
        })();
    }, []);

    return (
        <Grid container spacing={4}>
            <Grid item xs={4} className={classes.wrap}>
                <Typography className={classes.title}>Total Klinik</Typography>
                <hr className={classes.borderTitle}/>
                <div className={classes.tableWrapper}>
                    <Table>
                        <TableBody>
                            {
                                totalKlinik.length === 0
                                    ? <TableRow>
                                        <TableCell className={classes.tableCellBody} style={{ textAlign: 'center' }}>
                                            <Typography>Tidak Ada</Typography>
                                        </TableCell>
                                    </TableRow>
                                    : totalKlinik.map((item, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell className={classes.tableCellBody}>
                                                <Typography className={classes.labelLeft}>{item._id.province}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellBody} style={{ minWidth: 100 }}>
                                                <Typography className={classes.labelRight}>{`${item.count} Users`}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                </div>
            </Grid>
            <Grid item xs={4} className={classes.wrap}>
                <Typography className={classes.title}>Total Activity Sales</Typography>
                <hr className={classes.borderTitle}/>
                <div className={classes.tableWrapper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableCellBody}>
                                    <Typography className={classes.labelLeft}>Today</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                    <Typography className={classes.labelRight}>
                                        {totalAktivitas.today ? `${totalAktivitas.today} ` : `0 `}Activity
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellBody}>
                                    <Typography className={classes.labelLeft}>Yesterday</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                    <Typography className={classes.labelRight}>
                                        {totalAktivitas.yesterday ? `${totalAktivitas.yesterday} ` : `0 `}Activity
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellBody}>
                                    <Typography className={classes.labelLeft}>This Week</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                    <Typography className={classes.labelRight}>
                                        {totalAktivitas.thisWeek ? `${totalAktivitas.thisWeek} ` : `0 `}Activity
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellBody}>
                                    <Typography className={classes.labelLeft}>Last Week</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCellBody} style={{ textAlign: 'right' }}>
                                    <Typography className={classes.labelRight}>
                                        {totalAktivitas.lastWeek ? `${totalAktivitas.lastWeek} ` : `0 `}Activity
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </Grid>
            <NearestBill/>
        </Grid>
    );
};

export default Total;
