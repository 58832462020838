import {withStyles} from "@material-ui/core/styles";
import {green, red} from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";

const SquareSwitch = withStyles({
    root: {
        display: 'inline-flex',
        width: 40 + 12 * 2,
        height: 20 + 12 * 2,
        overflow: 'hidden',
        padding: 12,
        paddingRight: 7,
        marginRight: 8,
        boxSizing: 'border-box',
        position: 'relative',
        flexShrink: 0,
        zIndex: 0, // Reset the stacking context.
        verticalAlign: 'middle', // For correct alignment with the text.
    },
    edgeEnd: {
        marginRight: -2,
    },
    switchBase: {
        top: 5,
        left: 5,
        color: 'white',
        '&$checked': {
            color: 'white',
        },
        '&$checked + $track': {
            opacity: 1,
            backgroundColor: red[500],
        },
    },
    thumb: {
        borderRadius: 0,
        width: 16,
        height: 16,
        boxShadow: 'none',
    },
    checked: {},
    track: {
        borderRadius: 0,
        backgroundColor: green[500],
        opacity: 1,
    },
})(Switch);

export default SquareSwitch;
