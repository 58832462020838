import React, { useState } from 'react';
import SubMenuBtn from "../share/SubMenuBtn";
import KategoriPenolakan from "./kategori-penolakan/KategoriPenolakan";
import NamaEvent from "./nama-event/NamaEvent";
import Media from "./media/Media";
import PICAssist from "./pic-assist/PICAssist";
import Produk from "./produk/Produk";
import Kompetitor from "./kompetitor/Kompetitor";
import Iklan from "./iklan/Iklan";
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';

import Header from '../faskes/templateFaskes/header/header';
import './setting.css';

const styles = makeStyles({
    menu: {
        marginRight: 44,
        marginTop: -10,
        backgroundColor: "#f5f6f8",
        '&:hover' : {
            backgroundColor: "#f5f6f8",
        },
        fontFamily: 'Nunito',
        fontSize: '18px',
        fontWeight: 600,
    }
});

function Setting({ currentUser, isViewedByMobile }) {
    const classes = styles();
    const { UserRole } = currentUser;
    const isManajemen = UserRole && UserRole.role === 'manajemen';
    const [subMenu, setSubMenu] = useState(
        isManajemen
            ? 4
            : 1
    );

    let SubMenuContent;
    if (subMenu === 1) {
        SubMenuContent = <KategoriPenolakan />
    } else if (subMenu === 2) {
        SubMenuContent = <NamaEvent />
    } else if (subMenu === 3) {
        SubMenuContent = <Media />
    } else if (subMenu === 4) {
        SubMenuContent = <PICAssist />
    } else if (subMenu === 5) {
        SubMenuContent = <Kompetitor />
    } else if (subMenu === 6) {
        SubMenuContent = <Iklan />
    } else if (subMenu === 7) {
        SubMenuContent = <Produk />
    }

    return (
        <div className="setting-content">
            <Header title={"Setting"} />
            <div className="setting-body" style={isViewedByMobile ? {height: '95%'} : {height: '85%'}}>
                <Container className="container">
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            {
                                isManajemen && <SubMenuBtn
                                    isActive={subMenu === 4}
                                    onClick={() => setSubMenu(4)}
                                    className={classes.menu}
                                >
                                    PIC Assist.id
                                </SubMenuBtn>
                            }

                            <SubMenuBtn
                                isActive={subMenu === 1}
                                onClick={() => setSubMenu(1)}
                                className={classes.menu}
                            >
                                Kategori Penolakan
                            </SubMenuBtn>

                            <SubMenuBtn
                                isActive={subMenu === 2}
                                onClick={() => setSubMenu(2)}
                                className={classes.menu}
                            >
                                Nama Event
                            </SubMenuBtn>

                            <SubMenuBtn
                                isActive={subMenu === 3}
                                onClick={() => setSubMenu(3)}
                                className={classes.menu}
                            >
                                Media
                            </SubMenuBtn>

                            <SubMenuBtn
                                isActive={subMenu === 5}
                                onClick={() => setSubMenu(5)}
                                className={classes.menu}
                            >
                                Kompetitor
                            </SubMenuBtn>
                            <SubMenuBtn
                                isActive={subMenu === 6}
                                onClick={() => setSubMenu(6)}
                                className={classes.menu}
                            >
                                Iklan
                            </SubMenuBtn>
                            <SubMenuBtn
                                isActive={subMenu === 7}
                                onClick={() => setSubMenu(7)}
                                className={classes.menu}
                            >
                                Produk
                            </SubMenuBtn>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {SubMenuContent}
                        </Grid>
                    </Grid>
                </Container>
            </div>


        </div>
    );
}

const mapState = ({ user, app }) => ({
    currentUser: user.currentUser,
    isViewedByMobile: app.isViewedByMobile,
});

const mapDispatch = null;

export default connect(mapState, mapDispatch)(Setting);
