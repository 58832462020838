import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";
import PDatePicker from "../../share/PDatePicker";

import {getUserList} from "../../../api/panela-user";

const styles = makeStyles({
     label: {
         fontWeight: 'bold',
         color: 'black',
         marginBottom: 5,
     },
 });

 const FilterLeads = ({ filterData, setFilterData, startFilter, clearFilter }) => {
     const classes = styles();
     const [panelaUserList, setPanelaUserList] = useState([]);

     useEffect(()=> {
         (async function () {
             const result = await getUserList();
             const flag = result.data;
             flag[flag.length] = {id: 'm4n4j3m3n', firstName: 'Unassigned', lastName: ''};
             setPanelaUserList(flag);
         })();
     }, []);

     function handleChangeFilter(e) {
         setFilterData({...filterData, [e.target.name]: e.target.value});
     }

     const handleChangeStartDate = (date) => {
         setFilterData({...filterData, terakhirDihubungiStart: date});
     };

     const handleChangeEndDate = (date) => {
         setFilterData({...filterData, terakhirDihubungiEnd: date});
     };

     return(
         <Grid container>
             <Grid item xs={12} style={{ marginBottom: 20 }}>
                 <Grid container spacing={2}>
                     <Grid item xs={12}>
                         <Typography className={classes.label}>Nama Klinik</Typography>
                         <PInput
                             name="nama"
                             value={filterData.nama}
                             onChange={(e)=> handleChangeFilter(e)}
                             fullWidth
                         />
                     </Grid>
                     <Grid item xs={12}>
                         <Typography className={classes.label}>Kabupaten</Typography>
                         <PInput
                             name="kabupaten"
                             value={filterData.kabupaten}
                             onChange={(e)=> handleChangeFilter(e)}
                             fullWidth
                         />
                     </Grid>
                     <Grid item xs={12}>
                         <Typography className={classes.label}>Jenis Faskes</Typography>
                         <PInput
                             name="jenis"
                             value={filterData.jenis}
                             onChange={(e)=> handleChangeFilter(e)}
                             select
                             fullWidth
                         >
                             <MenuItem value={"RS Kelas A"}>RS Kelas A</MenuItem>
                             <MenuItem value={"RS Kelas B"}>RS Kelas B</MenuItem>
                             <MenuItem value={"RS Kelas C"}>RS Kelas C</MenuItem>
                             <MenuItem value={"RS Kelas D"}>RS Kelas D</MenuItem>
                             <MenuItem value={"Puskesmas"}>Puskesmas</MenuItem>
                             <MenuItem value={"Klinik Pratama"}>Klinik Pratama</MenuItem>
                             <MenuItem value={"Klinik Utama"}>Klinik Utama</MenuItem>
                             <MenuItem value={"Klinik Gigi"}>Klinik Gigi</MenuItem>
                             <MenuItem value={"Klinik Kecantikan"}>Klinik Kecantikan</MenuItem>
                             <MenuItem value={"Klinik Hemodialisis / Hemodialisa"}>Klinik Hemodialisis / Hemodialisa</MenuItem>
                             <MenuItem value={"Lab"}>Lab</MenuItem>
                             <MenuItem value={"Apotek"}>Apotek</MenuItem>
                             <MenuItem value={"Optik"}>Optik</MenuItem>
                             <MenuItem value={"Praktek Mandiri"}>Praktek Mandiri</MenuItem>
                             <MenuItem value={"Rumah Bersalin"}>Rumah Bersalin</MenuItem>
                             <MenuItem value={"Klinik Perusahaan / Instansi"}>Klinik Perusahaan / Instansi</MenuItem>
                             <MenuItem value={"Bukan Faskes"}>Bukan Faskes</MenuItem>
                             <MenuItem value={"Tidak Tahu"}>Tidak Tahu</MenuItem>
                         </PInput>
                     </Grid>
                     <Grid item xs={12}>
                         <Typography className={classes.label}>Status</Typography>
                         <PInput
                             name="status"
                             value={filterData.status}
                             onChange={(e)=> handleChangeFilter(e)}
                             select
                             fullWidth
                         >
                             <MenuItem value='Leads'>Leads</MenuItem>
                             <MenuItem value='Menghubungi'>Menghubungi</MenuItem>
                             <MenuItem value='Presentasi'>Presentasi</MenuItem>
                             <MenuItem value='Trial'>Trial</MenuItem>
                             <MenuItem value='Penawaran'>Penawaran</MenuItem>
                             <MenuItem value='Invoice'>Invoice</MenuItem>
                             <MenuItem value='Closing'>Closing</MenuItem>
                             <MenuItem value='Rejected'>Rejected</MenuItem>
                         </PInput>
                     </Grid>
                     <Grid item xs={12}>
                         <Typography className={classes.label}>PIC Assist.id</Typography>
                         <PInput
                             name="picAssist"
                             value={filterData.picAssist}
                             onChange={(e)=> handleChangeFilter(e)}
                             select
                             fullWidth
                         >
                             {
                                 panelaUserList.map(user => (
                                     <MenuItem key={user.id} value={`${user.firstName} ${user.lastName}`}>
                                         {`${user.firstName} ${user.lastName}`}
                                     </MenuItem>
                                 ))
                             }
                         </PInput>
                     </Grid>
                     <Grid item xs={12}>
                         <Typography className={classes.label} style={{ marginBottom: 0 }}>
                             Tanggal Terakhir Dihubungi
                         </Typography>
                     </Grid>
                     <Grid item md={6}>
                         <Typography className={classes.label}>Dari Tanggal</Typography>
                         <PDatePicker
                             value={filterData.terakhirDihubungiStart}
                             onChange={handleChangeStartDate}
                             format='dd/MM/yyyy'
                             InputProps={{
                                 endAdornment: <InputAdornment position='end'>
                                     <FontAwesomeIcon icon={faCalendarAlt}/>
                                 </InputAdornment>
                             }}
                             fullWidth
                         />
                     </Grid>
                     <Grid item md={6}>
                         <Typography className={classes.label}>Sampai Tanggal</Typography>
                         <PDatePicker
                             value={filterData.terakhirDihubungiEnd}
                             onChange={handleChangeEndDate}
                             format='dd/MM/yyyy'
                             InputProps={{
                                 endAdornment: <InputAdornment position='end'>
                                     <FontAwesomeIcon icon={faCalendarAlt}/>
                                 </InputAdornment>
                             }}
                             fullWidth
                         />
                     </Grid>
                     <Grid item xs={12}>
                         <Typography className={classes.label}>Hotness Level</Typography>
                         <PInput
                             name='hotnessLevel'
                             value={filterData.hotnessLevel}
                             onChange={(e)=> handleChangeFilter(e)}
                             select
                             fullWidth
                         >
                             <MenuItem value='Interested'>Interested</MenuItem>
                             <MenuItem value='Normal'>Normal</MenuItem>
                             <MenuItem value='Uninterested'>Uninterested</MenuItem>
                         </PInput>
                     </Grid>
                 </Grid>
             </Grid>
             <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                 <PBtn
                     inverted
                     dropShadow
                     style={{ marginRight: 16 }}
                     onClick={clearFilter}
                 >
                     Reset
                 </PBtn>
                 <PBtn
                     onClick={startFilter}
                 >
                     Filter
                 </PBtn>
             </Grid>
         </Grid>
     );
 };

 export default FilterLeads;







