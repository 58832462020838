import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft, faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../../share/PInput";
import PDatePicker from "../../../share/PDatePicker";
import PBtn from "../../../share/PBtn";

import {getUserList} from "../../../../api/panela-user";

import KlinikTerbanyakTicketTable from "./KlinikTerbanyakTicketTable";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
});

const KlinikTerbanyakTicket = ({ setIsPage }) => {
    const classes = styles();
    const [filterData, setFilterData] = useState({
        tanggalMulai: null,
        tanggalSelesai: null,
        status: '',
        namaKlinik: '',
        pic: '',
        skip: 0,
        limit: 5,
    });
    const [panelaUserList, setPanelaUserList] = useState([]);

    useEffect(()=> {
        (async function () {
            const result = await getUserList();
            setPanelaUserList(result.data);
        })();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilterData({...filterData, [name]: value});
    };

    const handleChangeTanggalMulai = (date) => {
        setFilterData({...filterData, tanggalMulai: date});
    };

    const handleChangeTanggalSelesai = (date) => {
        setFilterData({...filterData, tanggalSelesai: date});
    };

    return (
        <Grid container>
            <Grid container spacing={1} item xs={12} style={{ marginTop: '30px' }}>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Tanggal Mulai</Typography>
                    <PDatePicker
                        value={filterData.tanggalMulai}
                        onChange={handleChangeTanggalMulai}
                        format='dd MMMM yyyy'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Tanggal Selesai</Typography>
                    <PDatePicker
                        value={filterData.tanggalSelesai}
                        onChange={handleChangeTanggalSelesai}
                        format='dd MMMM yyyy'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.label}>Status</Typography>
                    <PInput
                        name='status'
                        value={filterData.status}
                        onChange={handleChange}
                        select
                        fullWidth
                    >
                        <MenuItem value='Waiting'>Waiting</MenuItem>
                        <MenuItem value='On Progress'>On Progress</MenuItem>
                        <MenuItem value='Solved'>Solved</MenuItem>
                    </PInput>
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.label}>Nama Klinik</Typography>
                    <PInput
                        name='namaKlinik'
                        value={filterData.namaKlinik}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.label}>PIC Assist.id</Typography>
                    <PInput
                        name='pic'
                        value={filterData.pic}
                        onChange={handleChange}
                        select
                        fullWidth
                    >
                        {
                            panelaUserList.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {`${item.firstName} ${item.lastName}`}
                                </MenuItem>
                            ))
                        }
                    </PInput>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <KlinikTerbanyakTicketTable
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </Grid>
            <Grid container justify='flex-end' item xs={12} style={{ marginTop: 20 }}>
                <PBtn
                    inverted
                    dropShadow
                    onClick={()=> setIsPage(0)}
                >
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{marginRight: 3}}/>Kembali
                </PBtn>
            </Grid>
        </Grid>
    );
}

export default KlinikTerbanyakTicket;
