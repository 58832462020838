import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {dispatch} from "../../../store";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import {updateFreeze} from "../../../api/freeze";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
        marginBottom: 10,
    },
});

const FreezeRequestForm = ({ currentUser, selectedFreeze, fetchFreeze, onClose }) => {
    const classes = styles();
    const [reason, setReason] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    async function handleRequestFreeze() {
        const {snackbar} = dispatch;
        try{
            setIsSubmitting(true);
            const payload = {...selectedFreeze};
            payload.updatedAt = new Date();
            payload.updatedId = currentUser.id;
            payload.isRequestFreeze = true;
            payload.reason = reason;
            await updateFreeze(selectedFreeze.id, payload);
            snackbar.showSuccess("Berhasil request freeze");
            setIsSubmitting(false);
            onClose();
            fetchFreeze();
        } catch (e) {
            console.error(e);
            snackbar.showError("Gagal request freeze");
            setIsSubmitting(false);
        }
    }

    return(
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Grid item xs={12} style={{ marginBottom: 24 }}>
                            <Typography className={classes.label}>Alasan Freeze *</Typography>
                            <PInput
                                value={reason}
                                onChange={(e)=> setReason(e.target.value)}
                                rows={4}
                                multiline
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" item xs={12} style={{ marginBottom: 10 }}>
                <PBtn
                    inverted
                    dropShadow
                    onClick={onClose}
                    style={{ marginRight: 16 }}
                    disabled={isSubmitting}
                >
                    Batal
                </PBtn>
                <PBtn
                    onClick={()=> handleRequestFreeze()}
                    disabled={reason === "" || isSubmitting}
                >
                    {!isSubmitting ? "Request" : <FontAwesomeIcon icon={faSpinner} spin/>}
                </PBtn>
            </Grid>
        </Grid>
    )
};

const mapState = ({ user, freeze }) => ({
    currentUser: user.currentUser,
    selectedFreeze: freeze.selectedFreeze,
});

const mapDispatch = ({ freeze: { fetchFreeze } }) => ({
    fetchFreeze,
});

export default connect(mapState, mapDispatch)(FreezeRequestForm);
