import {
    getAnomaliPenggunaan,
    countAnomaliPenggunaan,
    getRetensionFreeProduct,
    countRetensionFreeProduct,
    getRetensionPaidProduct,
    countRetensionPaidProduct,
    getRetensionTrialProduct,
    countRetensionTrialProduct,
    getKlinikTerbanyakTicket,
    countKlinikTerbanyakTicket,
    getWaktuDurasiSolvedTicket,
} from "../../api/laporan";

export const laporan = {
    state: {
        isFetchingAnomaliPenggunaan: false,
        isFetchingRetensionFreeProduct: false,
        isFetchingRetensionPaidProduct: false,
        isFetchingRetensionTrialProduct: false,
        isFetchingKlinikTerbanyakTicket: false,
        isFetchingWaktuDurasiSolvedTicket: false,
        anomaliPenggunaan: [],
        retensionFreeProduct: [],
        retensionPaidProduct: [],
        retensionTrialProduct: [],
        klinikTerbanyakTicket: [],
        waktuDurasiSolvedTicket: [],
        anomaliPenggunaanCounter: 0,
        retensionFreeProductCounter: 0,
        retensionPaidProductCounter: 0,
        retensionTrialProductCounter: 0,
        klinikTerbanyakTicketCounter: 0,
        waktuDurasiSolvedTicketCounter: 0,
    },
    reducers: {
        updateState(state, payload) {
            const { statName, value} = payload;

            return {
                ...state,
                [statName]: value,
            };
        },
        updateAnomaliPenggunaan(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit){
                return {
                    ...state,
                    anomaliPenggunaan: value,
                };
            }

            const { anomaliPenggunaan } = state;
            anomaliPenggunaan.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                anomaliPenggunaan: [...anomaliPenggunaan],
            };
        },
        updateRetensionFreeProduct(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    retensionFreeProduct: value,
                };
            }

            const { retensionFreeProduct } = state;
            retensionFreeProduct.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                retensionFreeProduct: [...retensionFreeProduct],
            };
        },
        updateRetensionPaidProduct(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    retensionPaidProduct: value,
                };
            }

            const { retensionPaidProduct } = state;
            retensionPaidProduct.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                retensionPaidProduct: [...retensionPaidProduct],
            };
        },
        updateRetensionTrialProduct(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    retensionTrialProduct: value,
                };
            }

            const { retensionTrialProduct } = state;
            retensionTrialProduct.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                retensionTrialProduct: [...retensionTrialProduct],
            };
        },
        updateKlinikTerbanyakTicket(state, payload) {
            const { value, skipLimit } = payload;

            if(!skipLimit) {
                return {
                    ...state,
                    klinikTerbanyakTicket: value,
                };
            }

            const { klinikTerbanyakTicket } = state;
            klinikTerbanyakTicket.splice(skipLimit.skip, skipLimit.limit, ...value);
            return {
                ...state,
                klinikTerbanyakTicket: [...klinikTerbanyakTicket],
            };
        },
    },
    effects: dispatch => ({
        async fetchAnomaliPenggunaan(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingAnomaliPenggunaan',
                    value: true,
                });

                const result = await getAnomaliPenggunaan(skipLimit);
                const resultCounter = await countAnomaliPenggunaan(skipLimit);

                this.updateAnomaliPenggunaan({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'anomaliPenggunaanCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingAnomaliPenggunaan',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan daftar anomali penggunaan');
                this.updateState({
                    statName: 'isFetchingAnomaliPenggunaan',
                    value: false,
                });
            }
        },
        async fetchRetensionFreeProduct(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingRetensionFreeProduct',
                    value: true,
                });

                const result = await getRetensionFreeProduct(skipLimit);
                const resultCounter = await countRetensionFreeProduct(skipLimit);

                this.updateRetensionFreeProduct({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'retensionFreeProductCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingRetensionFreeProduct',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan laporan retension produk gratis');
                this.updateState({
                    statName: 'isFetchingRetensionFreeProduct',
                    value: false,
                });
            }
        },
        async fetchRetensionPaidProduct(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingRetensionPaidProduct',
                    value: true,
                });

                const result = await getRetensionPaidProduct(skipLimit);
                const resultCounter = await countRetensionPaidProduct(skipLimit);

                this.updateRetensionPaidProduct({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'retensionPaidProductCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingRetensionPaidProduct',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan laporan retension produk berbayar');
                this.updateState({
                    statName: 'isFetchingRetensionPaidProduct',
                    value: false,
                });
            }
        },
        async fetchRetensionTrialProduct(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingRetensionTrialProduct',
                    value: true,
                });

                const result = await getRetensionTrialProduct(skipLimit);
                const resultCounter = await countRetensionTrialProduct(skipLimit);

                this.updateRetensionTrialProduct({
                    value: result.data,
                    skipLimit,
                });

                this.updateState({
                    statName: 'retensionTrialProductCounter',
                    value: resultCounter.data,
                });

                this.updateState({
                    statName: 'isFetchingRetensionTrialProduct',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan laporan retension produk trial');
                this.updateState({
                    statName: 'isFetchingRetensionTrialProduct',
                    value: false,
                });
            }
        },
        async fetchKlinikTerbanyakTicket(skipLimit) {
            try {
                this.updateState({
                    statName: 'isFetchingKlinikTerbanyakTicket',
                    value: true,
                });

                const result = await getKlinikTerbanyakTicket(skipLimit);
                const resultCounter = await countKlinikTerbanyakTicket(skipLimit);

                this.updateKlinikTerbanyakTicket({
                    value: result.data.result,
                    skipLimit,
                });

                this.updateState({
                    statName: 'klinikTerbanyakTicketCounter',
                    value: resultCounter.data.result.length,
                });

                this.updateState({
                    statName: 'isFetchingKlinikTerbanyakTicket',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan data klinik terbanyak ticket');
                this.updateState({
                    statName: 'isFetchingKlinikTerbanyakTicket',
                    value: false,
                });
            }
        },
        async fetchWaktuDurasiSolvedTicket(payload) {
            try {
                this.updateState({
                    statName: 'isFetchingWaktuDurasiSolvedTicket',
                    value: true,
                });

                const result = await getWaktuDurasiSolvedTicket(payload);

                this.updateState({
                    statName: 'waktuDurasiSolvedTicket',
                    value: result.data.result,
                });

                this.updateState({
                    statName: 'waktuDurasiSolvedTicketCounter',
                    value: result.data.result.length,
                });

                this.updateState({
                    statName: 'isFetchingWaktuDurasiSolvedTicket',
                    value: false,
                });
            } catch (e) {
                console.error(e);
                dispatch.snackbar.showError('Gagal mendapatkan data durasi solved ticket');
                this.updateState({
                    statName: 'isFetchingWaktuDurasiSolvedTicket',
                    value: false,
                });
            }
        },
    }),
};
