import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import {DatePicker} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faTimesCircle, faCheckCircle, faSpinner, faInfoCircle, faPrint} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {dispatch} from "../../../store";

import Autocomplete from "../../utils/Autocomplete";
import {faskesFetcherByKeyword} from "../../../helpers/faskesFetcherByName";
import {getPICKlinikByHospitalId, getInvoiceNo, getProducts, getStatusKliniikByHospitalId, createInvoice, updateInvoice} from "../../../api/client";

import format from "date-fns/format";
import addMonth from "date-fns/addMonths";
import NumberFormat from "react-number-format";

import PInput from "../../share/PInput";
import PBtn from "../../share/PBtn";

import InvoicePrintDialog from "./InvoicePrintDialog";

const styles = makeStyles({
    labelTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        color: 'black',
        fontSize: '20px',
    },
    labelStep: {
        fontWeight: 'bold',
    },
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
    wrap: {
        display: 'inline-flex',
        marginBottom: 10,
    },
    wrapper: {
        paddingLeft: 12,
        marginTop: 5,
    },
    iconCheckCircle: {
        color: 'green',
        marginRight: 10,
    },
    feature: {
        fontFamily: 'Nunito',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#000000',
        marginTop: -2,
    },
    info: {
        marginTop: -2,
        marginLeft: 7,
        fontWeight: 'bold',
    },
});

function getSteps() {
    return ['Atur Produk', 'Atur Termin'];
}

const fiturList = [
    {
        name: 'pharma',
        list : [
            'Support Basic - Email, Live Chat',
            'Onboarding Assistance (Creating Account)',
            'Self Data Migration (From Excell)',
            'Training Online + 100rb - 2 kali',
            'Training On the Spot + 400rb / 2 jam - 1 kali',
            'Jumlah user 20',
            'Opsional Penambahan Jumlah User - Free',
            'Jenis Hak Akses - Unlimited',
            'Jumlah Tenaga Medis - Unlimited',
            'Integrasi antar bagian internal',
            'Realtime notification antar bagian internal'],
    },
    {
        name: 'privataPro',
        list: [
            'Support Basic - Email, Live Chat',
            'Onboarding Assistance (Creating Account)',
            'Self Data Migration (From Excell)',
            'Training Online + 100rb - 1 kali',
            'Jumlah user 10',
            'Jenis Hak Akses - 3',
            'Jumlah Tenaga Medis - 1',
            'Integrasi antar bagian internal',
            'Realtime notification antar bagian internal',
        ],
    },
    {
        name: 'privataPro+',
        list: [
            'Support Basic - Email, Live Chat',
            'Onboarding Assistance (Creating Account)',
            'Self Data Migration (From Excell)',
            'Training Online + 100rb - 1 kali',
            'Jumlah user 20',
            'Jenis Hak Akses - Unlimited',
            'Jumlah Tenaga Medis - 2',
            'Integrasi antar bagian internal',
            'Realtime notification antar bagian internal',
        ],
    },
    {
        name: 'clinica',
        list: [
            'Support Basic - Email, Live Chat',
            'Onboarding Assistance (Creating Account)',
            'Self Data Migration (From Excell)',
            'Training Online + 100rb - 2 kali',
            'Integrasi dengan BPJS P - Care',
            'Jumlah user 20',
            'Opsional Penambahan Jumlah User - Free',
            'Jenis Hak Akses - Unlimited',
            'Jumlah Tenaga Medis - Unlimited',
            'Integrasi antar bagian internal',
            'Realtime notification antar bagian internal',
        ],
    },
    {
        name: 'clinicaPro',
        list: [
            'Support Basic - Email, Live Chat',
            'Onboarding Assistance (Creating Account)',
            'Self Data Migration (From Excell)',
            'Training Online + 100rb - 3 kali',
            'Training On the Spot + 400rb / 2 jam - 1 kali',
            'Integrasi dengan BPJS P - Care',
            'Jumlah user 20',
            'Opsional Penambahan Jumlah User - Free',
            'Jenis Hak Akses - Unlimited',
            'Jumlah Tenaga Medis - Unlimited',
            'Integrasi antar bagian internal',
            'Realtime notification antar bagian internal',
        ],
    },
    {
        name: 'schedula',
        list: [
            'Aplikasi Mobile',
        ],
    },
];

function numberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            prefix="Rp. "
            decimalSeparator=","
            thousandSeparator="."
        />
    );
}

const InvoiceForm = ({ updatePageInvoices, selectedInvoicesList, updateSelectedInvoicesList, currentUser }) => {
    const classes = styles();
    const { KConfig } = selectedInvoicesList;
    const isEdit = typeof selectedInvoicesList._id !== "undefined";
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [terminList, setTerminList] = useState(KConfig ? KConfig.activeBilling.Billing.payment : [{
        dueDatePayment: null,
        value: '0',
    }]);
    const [selectedFaskes, setSelectedFaskes] = useState(selectedInvoicesList._id ? selectedInvoicesList : null);
    const [PICFaskes, setPICFaskes] = useState([]);
    const [selectedPICFaskes, setSelectedPICFaskes] = useState('');
    const [invoiceNo, setInvoiceNo] = useState(KConfig ? KConfig.activeBilling.Billing.invoiceNo : '');
    const [produkList, setProdukList] = useState([]);
    const [selectedProduk, setSelectedProduk] = useState('');
    const [lamaBerlangganan, setLamaBerlangganan] = useState('');
    const [harga, setHarga] = useState('');
    const [diskon, setDiskon] = useState('');
    const [grandTotal, setGrandTotal] = useState('');
    const [invoiceDate, setInvoiceDate] = useState(KConfig ? new Date(KConfig.activeBilling.createdAt) : new Date());
    const [keyFaskesAutocomplete, setKeyFaskesAutocomplete] = useState(0);
    const validateInt = RegExp(/^[0-9\b]+$/);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [totalTermin, setTotalTermin] = useState('');
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [statusKlinik, setStatusKlinik] = useState('');
    const [isOpenInvoicePrintDialog, setIsOpenInvoicePrintDialog] = useState(false);

    useEffect(()=> {
        if(!isEdit && invoiceNo === '') {
            (async ()=> {
                const result = await getInvoiceNo();
                setInvoiceNo(result.data);
            })();
        }
        if(produkList.length === 0) {
            (async ()=> {
                const result = await getProducts();
                setProdukList(result.data);
            })();
        }
        let flagJumlahTerminList = [];
        let sum = 0;
        flagJumlahTerminList = terminList.map(data => data.value);
        for (let i = 0; i < terminList.length; i++) {
            sum += parseInt(flagJumlahTerminList[i], 10);
        }
        setTotalTermin(sum.toString());
    }, [invoiceNo, isEdit, produkList, terminList, PICFaskes]);

    useEffect(()=> {
        if(isEdit && PICFaskes.length === 0) {
            (async ()=> {
                const result = await getPICKlinikByHospitalId(KConfig.hospitalId);
                setPICFaskes(result.data);
            })();
        }
        if(isEdit && PICFaskes.length !== 0) {
            if(KConfig) {
                setSelectedPICFaskes(
                    PICFaskes.findIndex(item => {
                        return item.accountId === KConfig.activeBilling.createdId
                            && item.Confidentials.nama === KConfig.activeBilling.createdName;
                    })
                );
            }
        }
        if(isEdit && produkList.length !== 0 && selectedProduk === '') {
            if(KConfig) {
                setSelectedProduk(
                    produkList.findIndex(item => {
                        return item.name === KConfig.activeBilling.Billing.productName;
                    })
                );
            }
        }
        if(isEdit && selectedProduk !== '') {
            if(KConfig) {
                // const parsedHarga = produkList[selectedProduk].extra.value === '' ? 0 : parseInt(produkList[selectedProduk].extra.value, 10);
                setLamaBerlangganan(KConfig.activeBilling.Billing.duration);
                setHarga(KConfig.activeBilling.Billing.item[0].value);
                setDiskon(KConfig.activeBilling.Billing.item[0].value - KConfig.activeBilling.Billing.totalFee);
                setGrandTotal(KConfig.activeBilling.Billing.totalFee.toString());
            }
        }
    }, [isEdit, PICFaskes, KConfig, produkList]);

    async function onSelectFaskes(item) {
        try {
            const result = await getPICKlinikByHospitalId(item.KConfig.hospitalId);
            const status = await getStatusKliniikByHospitalId(item.KConfig.hospitalId);
            setStatusKlinik(status.data.status);
            if(status.data.status === "trial"){
                dispatch.snackbar.showInfo("Klinik sedang trial");
            } else if(status.data.status === "subscribe") {
                dispatch.snackbar.showInfo("Klinik sedang berlangganan");
                setIsOpenDialog(true);
            } else if(status.data.status === "not subscribe") {
                dispatch.snackbar.showInfo("Klinik sedang tidak berlangganan");
            }
            setSelectedFaskes(item);
            setPICFaskes(result.data);
        } catch (e) {
            console.error(e);
            dispatch.snackbar.showError("Gagal memilih klinik");
            setKeyFaskesAutocomplete(keyFaskesAutocomplete + 1);
            setSelectedFaskes(null);
            setPICFaskes([]);
        }
    }

    function handleDeleteSelectedFaskes() {
        setSelectedFaskes(null);
        setPICFaskes([]);
        setSelectedPICFaskes('');
    }

    function handleSelectPICFaskes(e) {
        setSelectedPICFaskes(e.target.value);
    }

    const handleChangeInvoiceDate = (date) => {
        setInvoiceDate(date);
    };

    function handleSelectProduk(e) {
        if(produkList[e.target.value].extra.isOld) {
            dispatch.snackbar.showError("Gagal memilih, produk yang dipilih merupakan produk lama");
            setSelectedProduk('');
            setLamaBerlangganan('');
            setHarga('');
            setDiskon('');
            setGrandTotal('');
        } else {
            setSelectedProduk(e.target.value);
            setLamaBerlangganan(1);
            setHarga(produkList[e.target.value].extra.value);
            setDiskon('0');
            setGrandTotal(`${produkList[e.target.value].extra.value}`);
        }
    }

    function handleChangeLamaBerlangganan(e) {
        const { value } = e.target;
        const parsedHarga = produkList[selectedProduk].extra.value === '' ? 0 : parseInt(produkList[selectedProduk].extra.value, 10);
        const parsedDiskon = produkList[selectedProduk].extra.yearlyDisc.value === '' ? 0 : parseInt(produkList[selectedProduk].extra.yearlyDisc.value, 10);
        let countedHarga = 0;
        let countedDiskon = 0;
        let countedGrandTotal = 0;
        if(value === 1) {
            countedHarga = parsedHarga;
            countedGrandTotal = countedHarga;
            setHarga(countedHarga.toString());
            setDiskon('0');
            setGrandTotal(countedGrandTotal.toString());
            setLamaBerlangganan(value);
        } else if(value === 2) {
            countedHarga = parsedHarga * 2;
            countedGrandTotal = countedHarga;
            setHarga(countedHarga.toString());
            setDiskon('0');
            setGrandTotal(countedGrandTotal.toString());
            setLamaBerlangganan(value);
        } else if(value === 3) {
            countedHarga = parsedHarga * 3;
            countedGrandTotal = countedHarga;
            setHarga(countedHarga.toString());
            setDiskon('0');
            setGrandTotal(countedGrandTotal.toString());
            setLamaBerlangganan(value);
        } else if(value === 6) {
            countedHarga = parsedHarga * 6;
            countedGrandTotal = countedHarga;
            setHarga(countedHarga.toString());
            setDiskon('0');
            setGrandTotal(countedGrandTotal.toString());
            setLamaBerlangganan(value);
        } else if(value === 12) {
            countedHarga = parsedHarga * 12;
            countedDiskon = (parsedHarga * 12) * (parsedDiskon / 100);
            countedGrandTotal = countedHarga - countedDiskon;
            setHarga(countedHarga.toString());
            setDiskon(countedDiskon.toString());
            setGrandTotal(countedGrandTotal.toString());
            setLamaBerlangganan(value);
        } else if(value === 24) {
            countedHarga = parsedHarga * 24;
            countedDiskon = (parsedHarga * 24) * (parsedDiskon / 100);
            countedGrandTotal = countedHarga - countedDiskon;
            setHarga(countedHarga.toString());
            setDiskon(countedDiskon.toString());
            setGrandTotal(countedGrandTotal.toString());
            setLamaBerlangganan(value);
        } else if(value === 36) {
            countedHarga = parsedHarga * 36;
            countedDiskon = (parsedHarga * 36) * (parsedDiskon / 100);
            countedGrandTotal = countedHarga - countedDiskon;
            setHarga(countedHarga.toString());
            setDiskon(countedDiskon.toString());
            setGrandTotal(countedGrandTotal.toString());
            setLamaBerlangganan(value);
        }
    }

    function handleChangeHarga(e) {
        const { value } = e.target;
        const parsedValue = value === '' ? 0 : parseInt(value, 10);
        const parsedDiskon = diskon === '' ? 0 : parseInt(diskon, 10);
        let parsedHarga = 0;
        let countedTotal = 0;
        if(validateInt.test(value)) {
            parsedHarga = parsedValue;
            countedTotal = parsedHarga - parsedDiskon;
            setHarga(parsedHarga.toString());
            setGrandTotal(countedTotal.toString());
        } else {
            setHarga('0');
        }
    }

    function handleChangeDiscount(e) {
        const { value } = e.target;
        const parsedValue = value === '' ? 0 : parseInt(value, 10);
        const parsedHarga = harga === '' ? 0 : parseInt(harga, 10);
        let parsedDiscount = 0;
        let countedTotal = 0;
        if(validateInt.test(value)) {
            if(parsedValue > parsedHarga) {
                parsedDiscount = parsedHarga;
                countedTotal = parsedHarga - parsedDiscount;
                setDiskon(parsedDiscount.toString());
                setGrandTotal(countedTotal.toString());
            } else {
                parsedDiscount = parsedValue;
                countedTotal = parsedHarga - parsedDiscount;
                setDiskon(parsedDiscount.toString());
                setGrandTotal(countedTotal.toString());
            }
        } else {
            setDiskon('0');
            setGrandTotal(parsedHarga.toString());
        }
    }

    const handleChangeTerminDate = (idx) => (date) => {
        if(idx === 0) {
            if(terminList.length > 1) {
                for(let i = 1; i < terminList.length; i++) {
                    terminList[i].dueDatePayment = addMonth(date, i);
                    setTerminList([...terminList]);
                }
            }
            terminList[idx].dueDatePayment = date;
            setTerminList([...terminList]);
        } else {
            terminList[idx].dueDatePayment = date;
            setTerminList([...terminList]);
        }
    };

    const handleChangeJumlahTermin = (idx) => (e) => {
        if(e.target.value === "" || validateInt.test(e.target.value)) {
            terminList[idx].value = e.target.value;
            setTerminList([...terminList]);
        }
    };

    function handleDeleteTermin(idx) {
        terminList.splice(idx, 1);
        setTerminList([...terminList]);
    }

    function handleTambahTermin() {
        if(terminList[0].dueDatePayment !== null) {
            setTerminList([...terminList, {dueDatePayment: addMonth(new Date(terminList[0].dueDatePayment), terminList.length), value: '0'}]);
        } else {
            setTerminList([...terminList, {dueDatePayment: null, value: '0'}]);
        }
    }

    function getFiturList() {
        if(selectedProduk !== '') {
            const selectedFiturList = fiturList.findIndex(item => {
                if(produkList[selectedProduk].name === 'clinicaGrow') {
                    return item.name === 'clinicaPro';
                } else if(produkList[selectedProduk].name === 'privata') {
                    return item.name === 'privataPro+';
                } else if(produkList[selectedProduk].name === 'pharmaOld') {
                    return item.name === 'pharma';
                } else if(produkList[selectedProduk].name === 'clinicaLite') {
                    return item.name === 'clinicaPro';
                } else if(produkList[selectedProduk].name === 'schedulaLite') {
                    return item.name === 'schedula';
                } else {
                    return item.name === produkList[selectedProduk].name;
                }
            });
            return (
                <div>
                    {
                        fiturList[selectedFiturList].list.map((item, idx) => (
                            <div key={idx}>
                                <div className={classes.wrap}>
                                    <FontAwesomeIcon icon={faCheckCircle} className={classes.iconCheckCircle}/>
                                    <Typography className={classes.feature}>
                                        {item}
                                    </Typography>
                                </div>
                            </div>
                        ))
                    }
                </div>
            )
        } else {
            return null;
        }
    }

    async function handleSimpan() {
        if(totalTermin !== grandTotal) {
            dispatch.snackbar.showError("Total termin belum sesuai dengan grand total");
        } else {
            const selectedProductName = produkList[selectedProduk].name;
            const selectedIdPICKlinik = PICFaskes[selectedPICFaskes].accountId;
            const customDate = terminList.map(data => {
                const newItem = {...data};
                newItem.dueDatePayment = format(new Date(data.dueDatePayment), "yyyy-MM-dd");
                return newItem;
            });
            if(!isEdit) {
                try {
                    const payload = {
                        productName: selectedProductName,
                        duration: lamaBerlangganan,
                        diskon: diskon,
                        harga: harga,
                        isMultiTermin: terminList.length > 1,
                        hospitalId: selectedFaskes.KConfig.hospitalId,
                        isReqParty: true,
                        picPanelaId: currentUser.id,
                        picPanelaName: `${currentUser.firstName} ${currentUser.lastName}`,
                        createdAt: invoiceDate,
                        invoiceNo: invoiceNo,
                        createdId: selectedIdPICKlinik,
                        payment: customDate,
                    };
                    if(statusKlinik === 'subscribe') {
                        payload.overwrite = true;
                    }
                    setIsSubmitting(true);
                    await createInvoice(payload);
                    dispatch.snackbar.showSuccess("Berhasil membuat invoice baru");
                    setIsSubmitting(false);
                    updateSelectedInvoicesList({});
                    updatePageInvoices(0);
                } catch (e) {
                    console.error(e);
                    dispatch.snackbar.showError("Gagal membuat invoice baru");
                    setIsSubmitting(false);
                }
            } else {
                try {
                    const payload = {
                        productName: selectedProductName,
                        duration: lamaBerlangganan,
                        diskon: diskon,
                        harga: harga,
                        isMultiTermin: terminList.length > 1,
                        isReqParty: true,
                        picPanelaId: currentUser.id,
                        picPanelaName: `${currentUser.firstName} ${currentUser.lastName}`,
                        createdAt: invoiceDate,
                        invoiceNo: invoiceNo,
                        createdId: selectedIdPICKlinik,
                        payment: customDate,
                    };
                    setIsSubmitting(true);
                    await updateInvoice(KConfig.activeBilling.Billing._id, payload);
                    dispatch.snackbar.showSuccess("Berhasil edit invoice");
                    setIsSubmitting(false);
                    updateSelectedInvoicesList({});
                    updatePageInvoices(0);
                } catch (e) {
                    console.error(e);
                    dispatch.snackbar.showError("Gagal edit invoice");
                    setIsSubmitting(false);
                }
            }
        }
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {
                                selectedFaskes
                                    ? <PInput
                                        label="Nama Klinik"
                                        value={selectedFaskes.nama}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={()=> handleDeleteSelectedFaskes()}>
                                                    <FontAwesomeIcon icon={faTimesCircle}/>
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        fullWidth
                                    />
                                    : <Autocomplete
                                        key={keyFaskesAutocomplete}
                                        fetcher={faskesFetcherByKeyword}
                                        onChange={onSelectFaskes}
                                        placeholder="Nama klinik"
                                        variant="outlined"
                                        margin="dense"
                                        itemToString="nama"
                                        fullWidth
                                    />
                            }
                            <PInput
                                label="Nomor Invoice"
                                value={invoiceNo}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PInput
                                label="PIC Klinik"
                                value={selectedPICFaskes}
                                onChange={(e)=> handleSelectPICFaskes(e)}
                                select
                                fullWidth
                            >
                                {
                                    PICFaskes.map((item, idx) => (
                                        <MenuItem key={`PIC-${idx+1}`} value={idx}>
                                            {item.Confidentials.nama}
                                        </MenuItem>
                                    ))
                                }
                            </PInput>
                            <DatePicker
                                label="Tanggal Invoice"
                                format="dd MMMM yyyy"
                                inputVariant="outlined"
                                margin="dense"
                                value={invoiceDate}
                                onChange={handleChangeInvoiceDate}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                    </InputAdornment>
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.label}>Informasi Tawaran Produk</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <PInput
                                label="Nama Produk"
                                value={selectedProduk}
                                onChange={(e)=> handleSelectProduk(e)}
                                select
                                fullWidth
                            >
                                {
                                    produkList.map((item, idx) => (
                                        <MenuItem key={`Produk-${idx+1}`} value={idx}>
                                            {item.name}
                                        </MenuItem>
                                    ))
                                }
                            </PInput>
                            <PInput
                                label="Lama Berlangganan"
                                value={lamaBerlangganan}
                                onChange={(e)=> handleChangeLamaBerlangganan(e)}
                                disabled={selectedProduk === ''}
                                select
                                fullWidth
                            >
                                <MenuItem value={1}>1 Bulan</MenuItem>
                                <MenuItem value={2}>2 Bulan</MenuItem>
                                <MenuItem value={3}>3 Bulan</MenuItem>
                                <MenuItem value={6}>6 Bulan</MenuItem>
                                <MenuItem value={12}>1 Tahun</MenuItem>
                                <MenuItem value={24}>2 Tahun</MenuItem>
                                <MenuItem value={36}>3 Tahun</MenuItem>
                            </PInput>
                            <PInput
                                label="Harga"
                                value={harga}
                                onChange={(e)=> handleChangeHarga(e)}
                                InputProps={{
                                    inputComponent: numberFormatCustom,
                                }}
                                fullWidth
                            />
                            <PInput
                                label="Diskon"
                                value={diskon}
                                onChange={(e)=> handleChangeDiscount(e)}
                                InputProps={{
                                    inputComponent: numberFormatCustom,
                                }}
                                fullWidth
                            />
                            <PInput
                                label="Total"
                                value={grandTotal}
                                InputProps={{
                                    readOnly: true,
                                    inputComponent: numberFormatCustom,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ marginTop: 7 }}>
                                <Typography className={classes.label}>Fitur:</Typography>
                                <div className={classes.wrapper}>
                                    {
                                        selectedProduk !== ''
                                            ? getFiturList()
                                            : null
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Grand Total"
                                variant="filled"
                                value={grandTotal}
                                InputProps={{
                                    readOnly: true,
                                    inputComponent: numberFormatCustom,
                                }}
                                fullWidth
                            />
                        </Grid>
                        {
                            terminList.map((item, idx) => (
                                <Grid key={idx} item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.label}>
                                                {item.isPayed ? `Termin ${idx+1} (Sudah Dibayar)` : `Termin ${idx+1}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                value={item.dueDatePayment}
                                                onChange={handleChangeTerminDate(idx)}
                                                label="Tanggal"
                                                format="dd MMMM yyyy"
                                                inputVariant="outlined"
                                                margin="dense"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                                    </InputAdornment>
                                                }}
                                                disabled={item.isPayed}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ display: 'inline-flex' }}>
                                            <PInput
                                                label="Jumlah"
                                                value={item.value}
                                                onChange={handleChangeJumlahTermin(idx)}
                                                InputProps={{
                                                    inputComponent: numberFormatCustom,
                                                }}
                                                disabled={item.isPayed}
                                                fullWidth
                                            />
                                            {
                                                terminList.length > 1
                                                    ? <IconButton disabled={item.isPayed} onClick={()=> handleDeleteTermin(idx)}>
                                                        <FontAwesomeIcon icon={faTimesCircle}/>
                                                    </IconButton>
                                                    : null
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                        {
                            terminList.length !== 4
                                ? lamaBerlangganan >= 12
                                ? <Grid item xs={12}>
                                    <PBtn onClick={()=> handleTambahTermin()}>Tambah Termin</PBtn>
                                </Grid>
                                : null
                                : null
                        }

                    </Grid>
                );
            default:
                return 'Unknown';
        }
    }

    function handleChangePageInvoices() {
        updateSelectedInvoicesList({});
        updatePageInvoices(0);
    }

    const handleNext = () => {
        const parsedGrandTotal = parseInt(grandTotal, 10);
        if(selectedFaskes === null) {
            dispatch.snackbar.showError("Silahkan pilih klinik terlebih dahulu");
        } else if(selectedPICFaskes === '') {
            dispatch.snackbar.showError("Silahkan pilih PIC klinik terlebih dahulu");
        } else if(selectedProduk === '') {
            dispatch.snackbar.showError("Silahkan pilih produk terlebih dahulu");
        } else if(parsedGrandTotal < 0) {
            dispatch.snackbar.showError("Total tidak valid, silahkan dicek kembali");
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <Grid container>
            <Dialog open={isOpenInvoicePrintDialog} onClose={()=> setIsOpenInvoicePrintDialog(false)} fullScreen>
                <DialogContent style={{ paddingTop: 50 }}>
                    <InvoicePrintDialog onClose={()=> setIsOpenInvoicePrintDialog(false)}/>
                </DialogContent>
            </Dialog>
            <Dialog open={isOpenDialog} maxWidth="xs" onClose={()=> setIsOpenDialog(false)} fullWidth>
                <DialogTitle>
                    <div style={{ display: 'inline-flex' }}>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                        <Typography className={classes.info}>Informasi</Typography>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container justify="center" item xs={12} style={{ marginBottom: 20 }}>
                        <Typography>
                            Klinik memiliki invoice aktif, dan invoice tersebut akan di gantikan dengan invoice yang akan dibuat.
                        </Typography>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Grid container item xs={12} style={{ marginTop: '44px' }}>
                <Grid item xs={12}>
                    <Typography className={classes.labelTitle}>
                        {isEdit ? `Edit Invoice ${selectedInvoicesList.nama}` : "Tambah Invoice Baru"}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Stepper activeStep={activeStep} style={{ backgroundColor: '#f5f6f8', marginLeft: -30 }}>
                        {
                            steps.map((label, idx) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={idx} {...stepProps}>
                                        <StepLabel {...labelProps} completed={false}>
                                            <Typography className={classes.labelStep}>{label}</Typography>
                                        </StepLabel>
                                    </Step>
                                );
                            })
                        }
                    </Stepper>
                </Grid>
                <Grid item xs={6}>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {getStepContent(activeStep)}
            </Grid>
            <Grid container justify="flex-start" item xs={6} style={{ marginTop: 30 }}>
                <PBtn
                    inverted
                    dropShadow
                    onClick={()=> handleChangePageInvoices()}
                >Batal</PBtn>
            </Grid>
            <Grid container justify="flex-end" item xs={6} style={{ marginTop: 30 }}>
                {
                    isEdit
                        ? <PBtn
                            inverted
                            dropShadow
                            style={{ marginRight: 16 }}
                            onClick={()=> setIsOpenInvoicePrintDialog(true)}
                        >
                            <FontAwesomeIcon icon={faPrint} style={{ marginRight: 5 }}/>Cetak Invoice
                        </PBtn>
                        : null
                }
                <PBtn
                    inverted
                    dropShadow
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    style={{ marginRight: 16 }}
                >Kembali</PBtn>
                <PBtn
                    disabled={isSubmitting}
                    onClick={activeStep === 0 ? handleNext : ()=> handleSimpan()}
                >
                    {!isSubmitting
                        ? activeStep === 1
                            ? 'Simpan'
                            : 'Langkah Selanjutnya'
                        : <FontAwesomeIcon icon={faSpinner} spin/>
                    }
                </PBtn>
            </Grid>
        </Grid>
    );
};

const mapState = ({ client, user }) => ({
    selectedInvoicesList: client.selectedInvoicesList,
    currentUser: user.currentUser,
});

const mapDispatch = ({ client: { updatePageInvoices, updateSelectedInvoicesList } }) => ({
    updatePageInvoices,
    updateSelectedInvoicesList,
});

export default connect(mapState, mapDispatch)(InvoiceForm);
