import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Chip from "@material-ui/core/Chip";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";

import filter from "./../../../assets/img/filter-icon.png"
import arrowDown from "./../../../assets/img/arrow-down-icon.png";

import PInput from "../../share/PInput";

import BerlanggananTable from "./BerlanggananTable";
import BerlanggananFilter from "./BerlanggananFilter";

import debounce from "lodash/debounce";

const styles = makeStyles({
    search: {
        flex: 0.9,
    },
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
    filter: {
        float: 'right',
        display: 'inline-flex',
        padding: '15px 5px',
        marginTop: '-8px',
    },
    labelFilter: {
        fontWeight: 'bold',
        fontSize: '16px',
        fontFamily: 'Nunito',
        marginTop: '3px',
        marginRight: '10px',
    },
    imgFilter: {
        height: '40%',
        marginRight: '15px',
    },
    arrowFilter: {
        height: '40%',
        marginTop: '10px',
        cursor: 'pointer',
    },
    chip: {
        marginRight: 10,
    },
});

const Berlangganan = ({ selectedSubscribedFaskesFilter, updateSelectedSubscribedFaskesFilter, fetchSubscribedFaskesListFilter  }) => {
    const classes = styles();
    const [isFilter, setIsFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        namaKlinik: '',
        namaProduk: '',
        masaAktifStart: null,
        masaAktifEnd: null,
        jatuhTempoStart: null,
        jatuhTempoEnd: null,
        skip: 0,
        limit: 5,
    });
    const [isOpenFilterForm, setIsOpenFilterForm] = useState(false);
    const [search, setSearch] = useState('');

    const handleFilter = () => {
        setIsFilter(true);
        fetchSubscribedFaskesListFilter(filterData);
        setIsOpenFilterForm(false);
        updateSelectedSubscribedFaskesFilter(JSON.parse(JSON.stringify(filterData)));
    };

    const handleClearFilter = () => {
        setFilterData({
            namaKlinik: '',
            namaProduk: '',
            masaAktifStart: null,
            masaAktifEnd: null,
            jatuhTempoStart: null,
            jatuhTempoEnd: null,
            skip: 0,
            limit: 5,
        });
        setIsFilter(false);
        updateSelectedSubscribedFaskesFilter({});
    };

    const getSuggestion = debounce(async (value) => {
        filterData.namaKlinik = value;
        setFilterData({...filterData, namaKlinik: filterData.namaKlinik});
        setIsFilter(true);
        await fetchSubscribedFaskesListFilter(filterData);
    }, 500);

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        if(value.length > 2) {
            setSearch(value);
            getSuggestion(value);
        } else if(value.length === 0) {
            setSearch(value);
            setIsFilter(false);
        } else {
            setSearch(value);
        }
    };

    return (
        <Grid container>
            <Grid container item xs={12} style={{ marginTop: '44px' }}>
                <Grid item xs={4}>
                    <PInput
                        placeholder="Cari klinik disini"
                        className={classes.search}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputAdornment>
                        }}
                        value={search}
                        onChange={handleChangeSearch}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <div className={classes.filter}>
                        <img alt='filter' src={filter} className={classes.imgFilter}/>
                        <Typography className={classes.labelFilter}>Filter by</Typography>
                        <img
                            alt='arrow'
                            src={arrowDown}
                            className={classes.arrowFilter}
                            onClick={()=> setIsOpenFilterForm(true)}
                        />
                        <Dialog open={isOpenFilterForm} maxWidth="xs" onClose={()=> setIsOpenFilterForm(false)} fullWidth>
                            <DialogContent>
                                <BerlanggananFilter
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    clearFilter={handleClearFilter}
                                    startFilter={handleFilter}
                                />
                            </DialogContent>
                        </Dialog>
                    </div>
                </Grid>
            </Grid>
            {
                isFilter && <Grid item xs={12} style={{ marginTop: 30, dispaly: 'flex' }}>
                    {
                        selectedSubscribedFaskesFilter.masaAktifStart && selectedSubscribedFaskesFilter.masaAktifStart !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Masa Aktif Dari Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedSubscribedFaskesFilter.masaAktifEnd && selectedSubscribedFaskesFilter.masaAktifEnd !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Masa Aktif Sampai Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedSubscribedFaskesFilter.jatuhTempoStart && selectedSubscribedFaskesFilter.jatuhTempoStart !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Jatuh Tempo Dari Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedSubscribedFaskesFilter.jatuhTempoEnd && selectedSubscribedFaskesFilter.jatuhTempoEnd !== null
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Jatuh Tempo Sampai Tanggal"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedSubscribedFaskesFilter.namaKlinik && selectedSubscribedFaskesFilter.namaKlinik !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Nama Klinik"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                    {
                        selectedSubscribedFaskesFilter.namaProduk && selectedSubscribedFaskesFilter.namaProduk !== ''
                            ? <Chip
                                size="small"
                                color="secondary"
                                label="Nama Produk"
                                icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                className={classes.chip}
                            /> : null
                    }
                </Grid>
            }
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <BerlanggananTable
                    isFilter={isFilter}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </Grid>
        </Grid>
    );
};

const mapState = ({ client }) => ({
    selectedSubscribedFaskesFilter: client.selectedSubscribedFaskesFilter,
});

const mapDispatch = ({ client: { updateSelectedSubscribedFaskesFilter, fetchSubscribedFaskesListFilter } }) => ({
    updateSelectedSubscribedFaskesFilter,
    fetchSubscribedFaskesListFilter,
});

export default connect(mapState, mapDispatch)(Berlangganan);
