import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from '@material-ui/core/styles';

import PenawaranItem from "./PenawaranItem";

const styles = makeStyles({
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    }
});

const PenawaranTable = (
    { fetchOffers,
        offerListCounter,
        setIsOpenAddNew,
        fetchOffersFilter,
        isFilter,
        filterData,
        setFilterData,
        pageFilter,
        currentUser,
    }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { UserRole } = currentUser;
    const isManajemen = UserRole && UserRole.role === "manajemen";
    const [flagSkipLimit, setFlagSkipLimit] = useState({
        skip: 0,
        limit: 5,
    });

    useEffect(() => {
        if(isFilter === true){
            setPage(pageFilter);
            handleRenderOfferFilter();
        } else {
            setPage(pageFilter);
            fetchOffers();
        }
    }, [fetchOffers, offerListCounter, fetchOffersFilter, isFilter, pageFilter]);

    const handleRenderOfferFilter = () => {
        setFilterData(filterData => ({
            ...filterData, condition: false
        }));
        fetchOffersFilter(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        setFlagSkipLimit({
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        });
        if(isFilter === true){
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit
            }));
            fetchOffersFilter(filterData);
        } else {
            fetchOffers(skipLimit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        const newRowsPerpage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerpage,
        };
        setFlagSkipLimit({
            skip: 0,
            limit: newRowsPerpage,
        });
        if(isFilter === true){
            filterData.skip = 0;
            filterData.limit = newRowsPerpage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit
            }));
            fetchOffersFilter(filterData);
        } else{
            fetchOffers(skipLimit);
        }
        setRowsPerPage(newRowsPerpage);
        setPage(0);
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableCellHeader}>
                        <span>Tanggal Penawaran</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>
                        <span>Nama Klinik</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>PIC Klinik</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Harga Penawaran</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Termin</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Batas Pembayaran</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>PIC Assist.id</span>
                    </TableCell>

                    <TableCell className={classes.tableCellHeader}>
                        <span>Status Penawaran</span>
                    </TableCell>

                    {isManajemen && <TableCell className={classes.tableCellHeader}>
                        <span>Action</span>
                    </TableCell>}
                </TableRow>
            </TableHead>

            <TableBody>
                <PenawaranItem setIsOpenAddNew={setIsOpenAddNew} page={page} rowsPerPage={rowsPerPage} flagSkipLimit={flagSkipLimit} />
            </TableBody>

            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={isManajemen ? 9 : 8}
                        count={offerListCounter}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
};

const mapState = ({ activity, user }) => ({
    offerListCounter: activity.offerListCounter,
    currentUser: user.currentUser,
});

const mapDispatch = ({ activity: { fetchOffers, fetchOffersFilter } }) => ({
    fetchOffers,
    fetchOffersFilter,
});

export default connect(mapState, mapDispatch)(PenawaranTable);
