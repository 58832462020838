import React, {useState} from 'react';
import {
    Grid,
    InputAdornment,
    Typography,
    Dialog,
    DialogContent,
    Chip,
} from '@material-ui/core';

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {connect} from "react-redux";

import filter from '../../../assets/img/filter-icon.png';
import arrowDown from '../../../assets/img/arrow-down-icon.png';

import PBtn from "../../share/PBtn";
import PInput from "../../share/PInput";

import TableData from "./TableData";
import FormData from "./FormData";
import FilterData from "./FilterData";

import './data.css';

const styles = makeStyles({
    chip: {
        marginRight: 10,
    },
});

function Data({ updateSelectedProspect, fetchProspectByFilter, selectedProspectFilter, updateSelectedProspectFilter }) {
    const [search, setSearch] = useState("");
    const [isOpenDataForm, setIsOpenDataForm] = useState(false);
    const [flagSkipLimit, setFlagSkipLimit] = useState({
        skip: 0,
        limit: 6,
    });
    const classes = styles();
    const [filterData, setFilterData] = useState({
        tanggalMulai: null,
        tanggalSelesai: null,
        faskesName: '',
        PICFaskes: '',
        position: '',
        skip: 0,
        limit: 6,
    });
    const [isFilter, setIsFilter] = useState(false);
    const [pageFilter, setPageFilter] = useState(0);
    const [isOpenFilterForm, setIsOpenFilterForm] = useState(false);

    const handleChangeSearch = (e) => {
        setSearch(e.target.value);
        console.log(search);
    };

    function handleCloseDialog() {
        setIsOpenDataForm(false);
        updateSelectedProspect({});
    }

    function handleFilter() {
        setIsFilter(true);
        setPageFilter(0);
        fetchProspectByFilter(filterData);
        setIsOpenFilterForm(false);
        updateSelectedProspectFilter(JSON.parse(JSON.stringify(filterData)));
    }

    function handleClearFilter() {
        setFilterData({
            tanggalMulai: null,
            tanggalSelesai: null,
            faskesName: '',
            PICFaskes: '',
            position: '',
            skip: 0,
            limit: 6,
        });
        setPageFilter(0);
        setIsFilter(false);
        updateSelectedProspectFilter({});
    }

    return (
        <Grid container>
            <Dialog open={isOpenDataForm} maxWidth="md" onClose={()=> handleCloseDialog()} fullWidth>
                <DialogContent>
                    <FormData onClose={()=> handleCloseDialog()} flagSkipLimit={flagSkipLimit}/>
                </DialogContent>
            </Dialog>
             <Grid container spacing={1} item xs={12} style={{ marginTop: '30px' }}>
                 <Grid item xs={4}>
                     <PInput
                         onChange={handleChangeSearch}
                         placeholder="Search"
                         InputProps={{
                             startAdornment: <InputAdornment position="start">
                                 <FontAwesomeIcon icon={faSearch} />
                             </InputAdornment>,
                         }}
                         fullWidth
                     />
                 </Grid>
                 <Grid item xs={2}>
                     <PBtn
                         onClick={()=> setIsOpenDataForm(true)}
                     >Tambahkan</PBtn>
                 </Grid>
                 {/*<Grid item xs={12} md={3}>*/}
                 {/*    <div className="sort">*/}
                 {/*        <img alt="sort" src={sort} className="img-sort" />*/}
                 {/*        <Typography>Sort by</Typography>*/}
                 {/*        <img alt="arrow" src={arrowDown} className="arrow-sort" />*/}
                 {/*    </div>*/}
                 {/*</Grid>*/}
                 <Grid item xs={6}>
                     <div className="filter">
                         <img alt="filter" src={filter} className="img-filter" />
                         <Typography>Filter by</Typography>
                         <img
                             alt="arrow"
                             src={arrowDown}
                             onClick={()=> setIsOpenFilterForm(true)}
                             className="arrow-filter"
                         />
                         <Dialog open={isOpenFilterForm} maxWidth="xs" onClose={()=> setIsOpenFilterForm(false)} fullWidth>
                             <DialogContent>
                                 <FilterData
                                     payloadFilter={filterData}
                                     setPayloadFilter={setFilterData}
                                     startFilter={()=> handleFilter()}
                                     clearFilter={()=> handleClearFilter()}
                                 />
                             </DialogContent>
                         </Dialog>
                     </div>
                 </Grid>
                 {
                     isFilter && <Grid item xs={12} style={{ marginTop: 30, display: 'flex' }}>
                         {
                             selectedProspectFilter.tanggalMulai && selectedProspectFilter.tanggalMulai !== null
                                 ? <Chip
                                    size='small'
                                    color='secondary'
                                    label='Tanggal Mulai'
                                    icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                    className={classes.chip}
                                 /> : null
                         }
                         {
                             selectedProspectFilter.tanggalSelesai && selectedProspectFilter.tanggalSelesai !== null
                                 ? <Chip
                                     size='small'
                                     color='secondary'
                                     label='Tanggal Selesai'
                                     icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                     className={classes.chip}
                                 /> : null
                         }
                         {
                             selectedProspectFilter.faskesName && selectedProspectFilter.faskesName !== ''
                                 ? <Chip
                                    size="small"
                                    color="secondary"
                                    label="Nama Klinik"
                                    icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                    className={classes.chip}
                                 /> : null
                         }
                         {
                             selectedProspectFilter.PICFaskes && selectedProspectFilter.PICFaskes !== ''
                                 ? <Chip
                                    size="small"
                                    color="secondary"
                                    label="PIC Klinik"
                                    icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                    className={classes.chip}
                                 /> : null
                         }
                         {
                             selectedProspectFilter.position && selectedProspectFilter.position !== ''
                                 ? <Chip
                                    size="small"
                                    color="secondary"
                                    label="Jabatan"
                                    icon={<FontAwesomeIcon icon={faCheckCircle}/>}
                                    className={classes.chip}
                                 /> : null
                         }
                     </Grid>
                 }
                 <Grid item xs={12} style={{ marginTop: 34 }}>
                     <TableData
                         setIsOpenDataForm={setIsOpenDataForm}
                         setFlagSkipLimit={setFlagSkipLimit}
                         isFilter={isFilter}
                         filterData={filterData}
                         setFilterData={setFilterData}
                         pageFilter={pageFilter}
                         setIsFilter={setIsFilter}
                     />
                 </Grid>
             </Grid>
        </Grid>
    );
};

const mapState = ({ prospect }) => ({
    selectedProspectFilter: prospect.selectedProspectFilter,
});

const mapDispatch = ({ prospect: { updateSelectedProspect, fetchProspectByFilter, updateSelectedProspectFilter } }) => ({
    updateSelectedProspect,
    fetchProspectByFilter,
    updateSelectedProspectFilter,
});

export default connect(mapState, mapDispatch)(Data);
