import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";

import {connect} from "react-redux";
import {dispatch} from "../../../store";

import {updateKompetitor, deleteKompetitor} from "../../../api/kompetitor";

import KompetitorItemList from "./../SettingListItem";

const KompetitorItem = ({ fetchKompetitors, isFetchingKompetitorList, kompetitorList }) => {

    const handleSave = async (editData, data, currentUser, setIsUpdating) => {
        try {
            setIsUpdating(true);
            await updateKompetitor(data.id, editData, currentUser.id);
            setIsUpdating(false);
            dispatch.snackbar.showSuccess("Berhasil mengupdate kompetitor");
            fetchKompetitors();
        } catch (e) {
            console.error(e);
            setIsUpdating(false);
            dispatch.snackbar.showError("Gagal mengupdate kompetitor");
        }
    };

     const handleDelete = async (id, setIsDeleting) => {
         try {
             setIsDeleting(true);
             await deleteKompetitor(id);
             setIsDeleting(false);
             dispatch.snackbar.showSuccess("Berhasil menghapus kompetitor");
             fetchKompetitors();
         } catch (e) {
             console.error(e);
             setIsDeleting(false);
             dispatch.snackbar.showError("Gagal menghapus kompetitor");
         }
     };

     return (
         <Grid item xs={12}>
             {
                 isFetchingKompetitorList
                     ? <Grid container justify="center">
                        <CircularProgress/>
                     </Grid>
                     : <List style={{marginTop: '40px', marginLeft: '-13px'}}>
                         {
                             kompetitorList.map(kompetitor => (
                                 <KompetitorItemList
                                     key={kompetitor.id}
                                     data={kompetitor}
                                     saveHandler={handleSave}
                                     deleteHandler={handleDelete}
                                 />
                             ))
                         }
                     </List>
             }
         </Grid>
     );
};

const mapState = ({ kompetitor }) => ({
    isFetchingKompetitorList: kompetitor.isFetchingKompetitorList,
    kompetitorList: kompetitor.kompetitorList,
});

const mapDispatch = ({ kompetitor: { fetchKompetitors } }) => ({
    fetchKompetitors,
});

export default connect(mapState, mapDispatch)(KompetitorItem);
