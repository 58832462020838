import React, {useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";

import {makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";

import PInput from "../../share/PInput";
import PDatePicker from "../../share/PDatePicker";
import ReportChartPaper from "../../share/ReportChartPaper";
import PBtn from "../../share/PBtn";

import {getTicketReport} from "../../../api/laporan";
import {getUserList} from "../../../api/panela-user";

const styles = makeStyles({
    label: {
        fontWeight: 'bold',
        color: 'black',
    },
});

const TicketItem = ({ setIsPage, ticketName }) => {
    const classes = styles();
    const [dataPie, setDataPie] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [filterData, setFilterData] = useState({
        tanggalMulai: null,
        tanggalSelesai: null,
        status: '',
        namaKlinik: '',
        pic: '',
    });
    const [panelaUserList, setPanelaUserList] = useState([]);

    useEffect(() => {
        (async function () {
            const result = await getUserList();
            setPanelaUserList(result.data);
        })();
    }, []);

    useEffect(() => {
        (async function () {
            const result = await getTicketReport(filterData, ticketName);
            const resultData = result.data.result.map(item => {
                const newItem = {...item};
                newItem.percentage = parseFloat(newItem.percentage.toFixed(2));
                return newItem;
            });

            if(resultData.length > 3) {
                const highestValue = [
                    {count: 0},
                    {count: 0},
                    {count: 0},
                ];
                for(let i = 0; i < resultData.length; i++) {
                    if(resultData[i].count > highestValue[0].count) {
                        highestValue[2] = highestValue[1];
                        highestValue[1] = highestValue[0];
                        highestValue[0] = resultData[i];
                    } else if(resultData[i].count > highestValue[1].count) {
                        highestValue[2] = highestValue[1];
                        highestValue[1] = resultData[i];
                    } else if(resultData[i].count > highestValue[2].count) {
                        highestValue[2] = resultData[i];
                    }
                }
                setDataPie(highestValue);
            } else {
                setDataPie(resultData);
            }
            setDataTable(resultData);
        })();
    }, [ticketName, filterData]);

    const COLORS = ['#0088FE', '#FFBB28', '#FF8042'];

    const handleChangeTanggalMulai = (date) => {
        setFilterData({...filterData, tanggalMulai: date});
    };

    const handleChangeTanggalSelesai = (date) => {
        setFilterData({...filterData, tanggalSelesai: date});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFilterData({...filterData, [name]: value});
    };

    return (
        <Grid container>
            <Grid container spacing={1} item xs={12} style={{ marginTop: '30px' }}>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Filter Tanggal Mulai</Typography>
                    <PDatePicker
                        value={filterData.tanggalMulai}
                        onChange={handleChangeTanggalMulai}
                        format='dd/MM/yyyy'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label}>Sampai Tanggal</Typography>
                    <PDatePicker
                        value={filterData.tanggalSelesai}
                        onChange={handleChangeTanggalSelesai}
                        format='dd/MM/yyyy'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </InputAdornment>
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.label}>Status</Typography>
                    <PInput
                        name='status'
                        value={filterData.status}
                        onChange={handleChange}
                        select
                        fullWidth
                    >
                        <MenuItem value='Waiting'>Waiting</MenuItem>
                        <MenuItem value='On Progress'>On Progress</MenuItem>
                        <MenuItem value='Solved'>Solved</MenuItem>
                    </PInput>
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.label}>Nama Klinik</Typography>
                    <PInput
                        name='namaKlinik'
                        value={filterData.namaKlinik}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.label}>PIC Assist.id</Typography>
                    <PInput
                        name='pic'
                        value={filterData.pic}
                        onChange={handleChange}
                        select
                        fullWidth
                    >
                        {
                            panelaUserList.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {`${item.firstName} ${item.lastName}`}
                                </MenuItem>
                            ))
                        }
                    </PInput>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 34 }}>
                <ReportChartPaper
                    label={ticketName}
                    dataPie={dataPie}
                    dataTable={dataTable}
                    colors={COLORS}
                    dataKeyVal={'percentage'}
                />
            </Grid>
            <Grid container justify='flex-end' item xs={12}>
                <PBtn
                    inverted
                    dropShadow
                    onClick={()=> setIsPage(0)}
                >
                    <FontAwesomeIcon icon={faArrowCircleLeft} style={{marginRight: 3}}/>Kembali
                </PBtn>
            </Grid>
        </Grid>
    );
}

export default TicketItem;
