import React, {useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import KlinikItem from "./KlinikItem";

const styles = makeStyles({
    tableWrapper: {
        overflow: 'auto',
    },
    tableCellHeader: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#15162d',
        fontWeight: 'bold',
    },
});

const KlinikTable = ({ klinikListCounter, fetchKlinikList, isFilter, filterData, setFilterData, fetchKlinikListFilter }) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=> {
        if(isFilter === true) {
            setPage(0);
            handleRenderKlinikListFilter();
        } else {
            setPage(0);
            setRowsPerPage(5);
            fetchKlinikList();
        }
    }, [fetchKlinikList, fetchKlinikListFilter, isFilter]);

    const handleRenderKlinikListFilter = () => {
        setFilterData(prevState => ({
            ...prevState, condition: false,
        }));
        fetchKlinikListFilter(filterData);
    };

    const handleChangePage = (event, newPage) => {
        const skipLimit = {
            skip: newPage * rowsPerPage,
            limit: rowsPerPage,
        };
        if(isFilter === true) {
            filterData.skip = newPage * rowsPerPage;
            filterData.limit = rowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchKlinikListFilter(filterData);
        } else {
            fetchKlinikList(skipLimit);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const skipLimit = {
            skip: 0,
            limit: newRowsPerPage,
        };
        if(isFilter === true) {
            filterData.skip = 0;
            filterData.limit = newRowsPerPage;
            setFilterData(prevState => ({
                ...prevState, skip: filterData.skip, limit: filterData.limit,
            }));
            fetchKlinikListFilter(filterData);
        } else {
            fetchKlinikList(skipLimit);
        }
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    return (
        <div>
            <div className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCellHeader}>
                                Tanggal
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>
                                Nama Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                Produk
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>
                                Aktif Hingga
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ textAlign: 'center' }}>
                                Berlanggan
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100, textAlign: 'center' }}>
                                Akun Aktif
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100, textAlign: 'center' }}>
                                Akun Trial
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100, textAlign: 'center' }}>
                                Invoice Aktif
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100 }}>
                                PIC Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 100, textAlign: 'center' }}>
                                No HP
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 150, textAlign: 'center' }}>
                                Total Akun Klinik
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} style={{ minWidth: 150, textAlign: 'center' }}>
                                Online Appointment
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <KlinikItem page={page} rowsPerPage={rowsPerPage} />
                    </TableBody>
                </Table>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={klinikListCounter}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

const mapState = ({ client }) => ({
    klinikListCounter: client.klinikListCounter,
});

const mapDispatch = ({ client: { fetchKlinikList, fetchKlinikListFilter } }) => ({
    fetchKlinikList,
    fetchKlinikListFilter,
});

export default connect(mapState, mapDispatch)(KlinikTable);
