import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";

import ActionCard from "../../share/ActionCard";

import TicketItem from "./TicketItem";
import KlinikTerbanyakTicket from "./klinik-terbanyak-ticket/KlinikTerbanyakTicket";
import DurasiSolved from "./durasi-solved/DurasiSolved";

const Ticket = () => {
    const [isPage, setIsPage] = useState(0);
    const [ticketName, setTicketName] = useState('');

    function handleClick(page, name) {
        setIsPage(page);
        setTicketName(name);
    }

    return (
        <Grid container>
            {
                isPage === 0
                    ? <Grid container item xs={12} style={{ marginTop: 50 }}>
                        <Grid item xs={6}>
                            <ActionCard
                                label='Berdasarkan Kategori Ticket'
                                subLabel='Laporan ticketing berdasarkan jenis kategori ticket'
                                handleClick={()=> handleClick(1, 'Kategori Ticket')}
                            />
                            <ActionCard
                                label='Berdasarkan Modul Yang Sering Dikomunikasikan'
                                subLabel='Laporan ticketing berdasarkan jenis kategori ticket'
                                handleClick={()=> handleClick(1, 'Modul Yang Sering Dikomunikasikan')}
                            />
                            <ActionCard
                                label='Berdasarkan Durasi Solved Ticket'
                                subLabel='Laporan ticketing berdasarkan durasi solved ticket'
                                handleClick={()=> handleClick(3, '')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ActionCard
                                label='Berdasarkan Status Ticket'
                                subLabel='Laporan ticketing berdasarkan status ticket'
                                handleClick={()=> handleClick(1, 'Status Ticket')}
                            />
                            <ActionCard
                                label='Berdasarkan Klinik Terbanyak Ticket'
                                subLabel='Laporan ticketing berdasarkan klinik terbanyak ticket'
                                handleClick={()=> handleClick(2, '')}
                            />
                        </Grid>
                    </Grid>
                    : isPage === 1
                    ? <TicketItem setIsPage={setIsPage} ticketName={ticketName}/>
                    : isPage === 2
                        ? <KlinikTerbanyakTicket setIsPage={setIsPage}/>
                        : isPage === 3
                            ? <DurasiSolved setIsPage={setIsPage}/>
                            : null
            }
        </Grid>
    );
}

export default Ticket;
